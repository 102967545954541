import cx from 'classnames';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Text, Box } from '@components';
import { useOutsider } from '@libs/utils/outsider';
import { getFacebookShareUrl, getTwitterShareUrl, copyToClipboard } from '@libs/utils/share';
import { ReactComponent as LinkIcon } from '@assets/svg/patrons/link.svg';
import { ReactComponent as FacebookIcon } from '@assets/svg/patrons/facebook.svg';
import { ReactComponent as TwitterIcon } from '@assets/svg/patrons/twitter.svg';
import { ReactComponent as AltShareIcon } from '@assets/svg/share.svg';
import useToast from '@libs/utils/toast';

const ShareLink = ({ className, children, right = false, ...props }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const facebookUrl = getFacebookShareUrl(window.location.href);
  const twitterUrl = getTwitterShareUrl(window.location.href);
  const copyClipboard = () => copyToClipboard(window.location.href);

  const [isOpen, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const { ref } = useOutsider(handleClose);

  return (
    <div className={cx('relative', className)}>
      <button onClick={() => setOpen(!isOpen)}>
        <Text className="flex justify-center items-center">
          <AltShareIcon className="w-5 h-5" />
          <Text className="text-primary text-base font-medium  ml-3">Share</Text>
        </Text>
      </button>
      {isOpen && (
        <ul className={`absolute ${right ? 'right-0' : 'left-0'} bg-white p-5 w-60 md:w-64 `}>
          <li
            ref={ref}
            onClick={() => {
              toast('link copied', 'success');
              copyClipboard();
            }}
            className="pointer-events-none"
          >
            <Box className="text-gray-darker hover:text-primary inline-flex justify-center items-center pointer-events-auto cursor-pointer">
              <LinkIcon />
              <Text className="ml-3">{t('components.shareLink.copy')} link</Text>
            </Box>
          </li>

          <li className="mt-4">
            <Link
              target="_blank"
              to={facebookUrl}
              className="text-gray-darker hover:text-primary inline-flex justify-center items-center cursor-pointer"
            >
              <FacebookIcon />
              <Text className="ml-3">{t('components.shareLink.shareOnFacebook')}</Text>
            </Link>
          </li>
          <li className="mt-4">
            <Link
              target="_blank"
              to={twitterUrl}
              className="text-gray-darker hover:text-primary inline-flex justify-center items-center cursor-pointer"
            >
              <TwitterIcon />
              <Text className="ml-3"> {t('components.shareLink.shareOnTwitter')}</Text>
            </Link>
          </li>
        </ul>
      )}
    </div>
  );
};

export default ShareLink;
