import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import {
  Box,
  Button,
  ConnectWalletButton,
  Heading,
  Link,
  MintNFTButton,
  Separator,
  Text,
  CollectibleCard
} from '@components';
import { useCollectibleById } from '@libs/hooks/collectible';
import Marquee from './marquee';
import { exchangePropType } from '@libs/prop-types';
import { ReactComponent as SpinnerIcon } from '@assets/svg/spinner.svg';
import { ReactComponent as OpenSeaIcon } from '@assets/svg/opensea.svg';
import ErrorIcon from '@assets/img/mint-it/mintError.png';
import SuccessIcon from '@assets/img/mint-it/mintSuccess.png';
import { useOpenSeaUrl } from './hooks';
import MintImage from './mint-image';

import '@pages/mint/patrons/mint-form/style.css';
import { STATUS_TYPES } from '@libs/utils/exchange';

const MintLoading = () => {
  const { t } = useTranslation();
  return (
    <Box className="patrons-mint-status">
      <SpinnerIcon
        style={{ width: '50px', height: '50px' }}
        className="patrons-mint-status__icon"
      />
      <Box className="patrons-mint-status__title">{t('mint.form.loading.title')}</Box>
      <Text as="p" className="patrons-mint-status__description">
        {t('p.pages.mint.loading.description')}
      </Text>
    </Box>
  );
};

const MintSuccess = ({ openSeaUrl }) => {
  const { t } = useTranslation();
  return (
    <>
      <Box className="patrons-mint-status patrons-mint-status-success">
        <img src={SuccessIcon} className="patrons-mint-status__icon" alt="sell it" />
        <Box className="patrons-mint-status__title">{t('mint.form.success.title')}</Box>
        <Text as="p" className="patrons-mint-status__description">
          <Trans i18nKey="mint.form.success.description"></Trans>
        </Text>
      </Box>
      <Link to={openSeaUrl} target="_blank">
        <Button className="flex items-center justify-center mt-7" fullWidth darkOutline>
          <Box className="uppercase">{t('mint.form.success.viewOnOpenSea')}</Box>{' '}
          <OpenSeaIcon className="ml-3" />
        </Button>
      </Link>
    </>
  );
};

const MintError = () => {
  const { t } = useTranslation();
  return (
    <Box className="patrons-mint-status patrons-mint-status-error">
      <img src={ErrorIcon} className="patrons-mint-status__icon" alt="sell it" />
      <Box className="patrons-mint-status__title">{t('mint.form.error.title')}</Box>
      <Text as="p" className="patrons-mint-status__description">
        <Trans i18nKey="p.pages.mint.errorDescription">
          <a className="font-bold" href={`mailto:support@patrons.art`}>
            {{ email: 'support@patrons.art' }}
          </a>
        </Trans>
      </Text>
    </Box>
  );
};

const MintForm = ({ exchange, isNftImageCardReady }) => {
  const { t } = useTranslation();
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [walletAddress, setWalletAddress] = useState('');
  const [mintingFunctionStatus, setMintingFunctionStatus] = useState('');
  const [hasWalletError, setHasWalletError] = useState(false);
  const openSeaUrl = useOpenSeaUrl({
    exchange,
    mintingFunctionStatus
  });

  const mediaRef = useRef();

  const setWalletAddressCallback = useCallback(setWalletAddress, [setWalletAddress]);
  const setIsSubmittingFormCallback = useCallback(setIsSubmittingForm, [setIsSubmittingForm]);
  const setHasWalletErrorCallback = useCallback(setHasWalletError, [setHasWalletError]);

  const validateForm = useCallback(() => {
    const validationConditions = [isNftImageCardReady];
    return validationConditions.every((x) => x);
  }, [isNftImageCardReady]);

  return (
    <Box className="patrons-mint__top">
      <Box className="patrons-mint__top__left">
        <Box className="patrons-mint__top__left__content">
          <Box className="patrons-mint__top__left__content__title">
            {t('p.pages.mint.title')}
            <br />
            {t('p.pages.mint.titleBottom')}
          </Box>
          {openSeaUrl ? (
            <MintSuccess openSeaUrl={openSeaUrl} />
          ) : exchange.status === STATUS_TYPES.MINTING ||
            mintingFunctionStatus === 'success' ||
            mintingFunctionStatus === 'loading' ? (
            <MintLoading />
          ) : mintingFunctionStatus === 'error' ? (
            <MintError />
          ) : (
            <>
              <Box className="patrons-mint__top__left__content__description">
                {t('p.pages.mint.description')}
              </Box>
              <Box className="patrons-mint__top__left__content__button">
                <ConnectWalletButton
                  walletAddress={walletAddress}
                  setWalletAddress={setWalletAddressCallback}
                  setHasError={setHasWalletErrorCallback}
                  onUnitPage
                  disableSubmit={
                    (process.env.REACT_APP_AMPLIFY_ENV === 'sandbox' &&
                      exchange?.creator?.username !== 'colincolorful') ||
                    ['staging', 'prod'].includes(process.env.REACT_APP_AMPLIFY_ENV)
                  }
                />
              </Box>
              <Box className="patrons-mint-form__submit">
                <MintNFTButton
                  mediaRef={mediaRef}
                  exchange={exchange}
                  walletAddress={walletAddress}
                  validation={validateForm}
                  setIsMinting={setIsSubmittingFormCallback}
                  hasWalletError={hasWalletError}
                  className="mt-4"
                  setStatus={setMintingFunctionStatus}
                  isReady={isNftImageCardReady}
                  disableSubmit={
                    (process.env.REACT_APP_AMPLIFY_ENV === 'sandbox' &&
                      exchange?.creator?.username !== 'colincolorful') ||
                    ['staging', 'prod'].includes(process.env.REACT_APP_AMPLIFY_ENV)
                  }
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
      <Box className="patrons-mint__top__right">
        <div className="patrons-mint__top__right__marquee absolute w-full -top-12">
          <Marquee title={t('p.pages.mint.mintIt')} />
        </div>
        <Box className="patrons-mint__top__right__content">
          <Box id="mint-it-main-image" className="patrons-mint__top__right__content__wrapper">
            <MintImage exchange={exchange} loading={!exchange?.id} ref={mediaRef} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

MintForm.propTypes = {
  exchange: exchangePropType,
  isNftImageCardReady: PropTypes.bool
};

export default MintForm;
