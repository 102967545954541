import cx from 'classnames';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowRightIcon } from '@assets/svg/arrow-right-black.svg';
import { ReactComponent as UserIcon } from '@assets/svg/user-icon.svg';
import { ReactComponent as SettingsIcon } from '@assets/svg/settings-icon.svg';
import { MdOutlineLogout } from 'react-icons/md';
import { AiOutlineCamera } from 'react-icons/ai';
import { useAuth } from '@libs/contexts/auth';
import { motion } from 'framer-motion/dist/framer-motion';
import { useUploader, useImageResizer } from '@libs/utils/uploader';
import { useUser } from '@libs/hooks/user';
import {
  Avatar,
  Box,
  ConnectWalletButton,
  Link,
  Modal,
  NFTAddress,
  Price,
  Separator,
  SkeletonLoading,
  Text,
  Button,
  Cropper,
  ArtistProfile
} from '@components';
import { useOutsider } from '@libs/utils/outsider';

const UserMenuLoading = () => (
  <>
    <SkeletonLoading className=" w-12 h-12 rounded-full" />
  </>
);

const subMenuAnimate = {
  enter: {
    opacity: 1,
    rotateX: 0,
    transition: {
      duration: 0.4
    },
    display: 'block'
  },
  exit: {
    opacity: 0,
    rotateX: 50,
    transition: {
      duration: 0.4
    },
    transitionEnd: {
      display: 'none'
    }
  }
};

const UserMenu = ({ size, className, ...props }) => {
  const { t } = useTranslation();
  const { user, wallet, logout, getCurrentUser } = useAuth();
  const [walletAddress, setWalletAddress] = useState('');
  const [isHoverAvatar, setIsHoverAvatar] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const handleClose = () => setOpen(false);
  const { ref } = useOutsider(handleClose);

  const handleOpen = useCallback(
    (e) => {
      e.preventDefault();
      setOpen(!isOpen);
    },
    [isOpen]
  );

  //update avatar
  const { updateAvatarLoading, onUpdateAvatar, onRemoveAvatar } = useUser();
  const [uploadAvatarLoading, setUploadAvatarLoading] = useState(false);
  const {
    inputRef: avatarRef,
    result: avatarResult,
    loading: onLoadingUploderAvatar,
    onClick: onClickUploadAvatar,
    onUpload: onUploadAvatar
  } = useUploader();
  const { loading: imageResizeLoading, onResize } = useImageResizer();
  const [modalAvatar, setModalAvatar] = useState(false);

  const handleOpenModalAvatar = () => {
    setModalAvatar(true);
  };

  const handleCloseModalAvatar = () => {
    if (
      !(onLoadingUploderAvatar || imageResizeLoading || updateAvatarLoading || uploadAvatarLoading)
    ) {
      setModalAvatar(false);
    }
  };

  //update avatar

  const subAvatarAnimate = {
    enter: {
      opacity: 1,
      rotateX: 0,
      transition: {
        duration: 0.4
      },
      display: 'block'
    },
    exit: {
      opacity: 0,
      rotateX: 0,
      transition: {
        duration: 0.4
      },
      transitionEnd: {
        display: 'none'
      }
    }
  };

  if (user == null) {
    return <UserMenuLoading />;
  }

  const { firstName, lastName } = user;

  return (
    <div ref={ref} id="logged-in" className={cx('user-menu', className)} {...props}>
      <div className="user-menu__dropdown-toggle cursor-pointer" onClick={handleOpen}>
        <Avatar user={user} size="xs" />
      </div>
      <motion.div initial="exit" animate={isOpen ? 'enter' : 'exit'} variants={subMenuAnimate}>
        <ul className="user-menu__dropdown">
          <li className="user-menu__dropdown__user">
            <Box className="artist-profile">
              <motion.div
                onHoverStart={() => setIsHoverAvatar(!isHoverAvatar)}
                onHoverEnd={() => setIsHoverAvatar(!isHoverAvatar)}
                className="user-menu__dropdown__user__avatar"
              >
                <Avatar user={user} size="xs" />
                <motion.div
                  initial="enter"
                  variants={subAvatarAnimate}
                  animate={isHoverAvatar ? 'enter' : 'exit'}
                  className="user-menu__dropdown__user__avatar__hover"
                  onClick={handleOpenModalAvatar}
                >
                  <AiOutlineCamera size={20} />
                </motion.div>
              </motion.div>
              <Box className="artist-profile__detail">
                <Box className={cx('artist-profile__detail__name')}>
                  {user?.displayName || user?.firstName + ' ' + user?.lastName}
                </Box>
                <Box className={cx('artist-profile__detail__description')}>{user?.email}</Box>
              </Box>
            </Box>
          </li>
          <Separator />
          <li className="user-menu__dropdown__wallet">
            <Link onClick={handleClose} to={`/settings/transactions`}>
              <Box>
                <Box className="user-menu__dropdown__wallet__title">
                  {t('p.components.userMenu.walletBalance')}
                </Box>
                <span>
                  <Price amount={wallet?.amount} />
                </span>
              </Box>
              <ArrowRightIcon className="user-menu__dropdown__wallet__arrow" />
            </Link>
          </li>
          <Separator />
          <li>
            <Box className="user-menu__dropdown__connect-wallet">
              <ConnectWalletButton
                walletAddress={walletAddress}
                setWalletAddress={setWalletAddress}
                setHasError={() => {}}
                inUserMenu
                disableSubmit={['sandbox', 'staging', 'prod'].includes(
                  process.env.REACT_APP_AMPLIFY_ENV
                )}
              />
            </Box>
          </li>
          <Separator />
          <li>
            <Link onClick={handleClose} to={`/${user?.username}`}>
              <UserIcon />
              <span className="user-menu__dropdown__submenu">
                {t('p.components.userMenu.profile')}
              </span>
            </Link>
          </li>
          <Separator />
          <li>
            <Link onClick={handleClose} to="/settings">
              <SettingsIcon />
              <span className="user-menu__dropdown__submenu">
                {t('p.components.userMenu.settings')}
              </span>
            </Link>
          </li>
          <Separator />
          <li>
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                logout();
                handleClose();
              }}
            >
              <MdOutlineLogout size={22} />
              <span className="user-menu__dropdown__submenu">
                {t('p.components.userMenu.logout')}
              </span>
            </Link>
          </li>
        </ul>
      </motion.div>
      <Modal
        verticalCenter
        showCloseIcon
        className="modal-avatar"
        isOpen={modalAvatar}
        onClose={handleCloseModalAvatar}
        heading={t('p.pages.settings.profile.fields.avatar.uploadImage')}
        size="profile"
      >
        <Box className="modal-avatar__upload">
          <Text className="text-gray-400">
            {t('p.pages.settings.profile.fields.avatar.placeholder')}
          </Text>
          <Button
            onClick={onClickUploadAvatar}
            disabled={
              onLoadingUploderAvatar ||
              imageResizeLoading ||
              updateAvatarLoading ||
              uploadAvatarLoading
            }
            loading={onLoadingUploderAvatar}
            className="modal-avatar__upload__button"
          >
            {t('p.pages.settings.profile.fields.avatar.chooseFile')}
          </Button>
          <input
            ref={avatarRef}
            accept="image/jpeg, image/png, image/webp"
            className="invisible w-0"
            type="file"
            onChange={(e) => onUploadAvatar(e, 'avatar')}
          />
        </Box>

        {avatarRef?.current?.value && avatarResult.fileUrl ? (
          <Box className="modal-avatar__cropper">
            <Cropper
              loading={imageResizeLoading || updateAvatarLoading || uploadAvatarLoading}
              image={avatarResult.fileUrl}
              onComplete={async (croppedArea) => {
                setUploadAvatarLoading(true);
                const resized = await onResize({
                  key: avatarResult.s3?.key,
                  folder: 'avatars',
                  ...croppedArea
                });

                if (resized) {
                  await onRemoveAvatar();
                  await onUpdateAvatar(resized?.key);
                  await getCurrentUser();
                  setUploadAvatarLoading(false);
                }

                avatarRef.current.value = '';
                setUploadAvatarLoading(false);
                setModalAvatar(false);
              }}
              onCancel={() => {
                setModalAvatar(false);
                avatarRef.current.value = '';
              }}
            />
          </Box>
        ) : null}
      </Modal>
    </div>
  );
};

export default UserMenu;
