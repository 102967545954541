import React from 'react';

import { Box } from '@components';
import useCheckoutScript from '@pages/checkout/components/fansinc/checkout-frame/useCheckoutScript';

import PrintCheckoutFrameForm from '@pages/print-collectible/patrons/print-checkout/print-checkout-frame/print-checkout-frame-form';

function PrintCheckoutFrame({ selectedShipping, submitForm, setSubmitForm, inputData }) {
  const isCheckoutScriptLoaded = useCheckoutScript();
  return (
    <Box className="checkout_frame">
      {isCheckoutScriptLoaded && (
        <PrintCheckoutFrameForm
          className="checkout-frame"
          submitForm={submitForm}
          setSubmitForm={setSubmitForm}
          selectedShipping={selectedShipping}
          {...inputData}
        />
      )}
    </Box>
  );
}

export default PrintCheckoutFrame;
