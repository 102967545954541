import { React } from 'react';
import FansincLanding from './fansinc';
import PatronsLanding from './patrons';

function LandingWrapper() {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsLanding />;
  } else {
    return <FansincLanding />;
  }
}
export default LandingWrapper;
