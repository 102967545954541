import cx from 'classnames';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoNotifications, IoCheckmarkDoneOutline, IoArrowForward } from 'react-icons/io5';

import { Box, Link, NotificationMenuItem, Text, Button } from '@components';
import { useOutsider } from '@libs/utils/outsider';
import { useUpdateReadNotification } from '@libs/hooks/notification';
import { useNotifications } from '@libs/contexts/notification';
import { MdOutlineNotificationsActive } from 'react-icons/md';
import { motion } from 'framer-motion/dist/framer-motion';
const LIMIT_NOTIF = 5;

const NotificationMenu = ({ className, ...props }) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { notifications, unread, readAllNotifications } = useNotifications(); // TODO: implement these

  const handleClose = () => {
    setOpen(false);
    setMenuOpen(false);
  };
  const handleMenuClose = () => setMenuOpen(false);

  const { ref } = useOutsider(handleClose);
  const { ref: refMenu } = useOutsider(handleMenuClose);

  const handleOpen = useCallback(
    (e) => {
      e.preventDefault();
      setOpen(!isOpen);
    },
    [isOpen]
  );

  const handleMenuOpen = useCallback(
    (e) => {
      e.preventDefault();
      setMenuOpen(!isMenuOpen);
    },
    [isMenuOpen]
  );
  const { onUpdateNotification, updateLoading } = useUpdateReadNotification();
  const onUpdate = async (e) => {
    e.preventDefault();
    handleMenuClose();
    readAllNotifications();
    const data = {
      itemType: 'user'
    };
    await onUpdateNotification(data);
  };

  const subMenuAnimate = {
    enter: {
      opacity: 1,
      rotateX: 0,
      transition: {
        duration: 0.4
      },
      display: 'block'
    },
    exit: {
      opacity: 0,
      rotateX: 50,
      transition: {
        duration: 0.4
      },
      transitionEnd: {
        display: 'none'
      }
    }
  };

  return (
    <Box ref={ref} className={cx('block md:relative cursor-pointer', className)} {...props}>
      <Box
        className="relative flex items-center justify-center w-10 ml-3 text-22"
        onClick={handleOpen}
      >
        {process.env.REACT_APP_NAME === 'patrons' ? (
          <MdOutlineNotificationsActive size={25} color="rgba(43, 43, 43, 1)" />
        ) : (
          <IoNotifications />
        )}
        {unread > 0 && (
          <span className="text-white bg-red-500 text-8 absolute top-0 text-center right-0 h-3 w-3 flex justify-center items-center rounded-full">
            {unread > 99 ? '99+' : unread}
          </span>
        )}
      </Box>
      <motion.div initial="exit" animate={isOpen ? 'enter' : 'exit'} variants={subMenuAnimate}>
        <Box className="notification-menu-patrons__box">
          <Box
            ref={refMenu}
            className="relative flex justify-between pb-4 border-b px-5 border-white border-opacity-10 text-primary text-xl leading-5 font-bold"
          >
            <Text className="capitalize">{t('components.notificationMenu.title')}</Text>
            <Button text onClick={onUpdate} as="button" size="text" loading={updateLoading}>
              <Box className="inline-flex justify-end items-center text-base capitalize text-red-500 hover:text-red-400">
                <IoCheckmarkDoneOutline size={18} className="mr-2" /> Mark all as read
              </Box>
            </Button>
          </Box>
          <Box>
            {notifications.slice(0, LIMIT_NOTIF).map(function (notification, i) {
              return (
                <NotificationMenuItem
                  key={i}
                  index={i}
                  notification={notification}
                  dropdown={true}
                />
              );
            })}
          </Box>
          {notifications.length === 0 && (
            <Box className="flex flex-col py-5 justify-center items-center border-t">
              <Box className="text-primary">
                <MdOutlineNotificationsActive size={50} color="rgba(43, 43, 43, 1)" />
              </Box>
              <Text className="text-primary text-lg font-bold mt-6">
                {t('components.notificationMenu.empty.title')}
              </Text>
              <Text className="text-secondary text-base mt-1">
                {t('components.notificationMenu.empty.body')}
              </Text>
            </Box>
          )}
          <Box className="w-full border-t">
            <Link
              to="/notifications"
              onClick={handleClose}
              className="w-full flex justify-center items-center pt-8 pb-3 text-base font-medium text-red-500 capitalize flex-row"
            >
              <span className="mr-2"> View All Notifications</span>
              <IoArrowForward size={16} />
            </Link>
          </Box>
        </Box>
      </motion.div>
    </Box>
  );
};

NotificationMenu.defaultProps = {
  unread: 0
};

export default NotificationMenu;
