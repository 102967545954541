import cx from 'classnames';
import { SkeletonLoading } from '@components';

const Heading = ({ as, children, className, loading, skeletonStyle, ...props }) => {
  const validHeadingLevels = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
  const safeHeading = as ? as.toLowerCase() : '';
  const Tag = validHeadingLevels.includes(safeHeading) ? safeHeading : 'p';

  return (
    <Tag className={cx('heading', className)} {...props}>
      {loading ? <SkeletonLoading className={cx('heading__skeleton', skeletonStyle)} /> : children}
    </Tag>
  );
};

export default Heading;
