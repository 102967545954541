import { useTranslation } from 'react-i18next';
import GoogleIcon from '@assets/svg/google-original.svg';

import { Button } from '@components';
import { useAuth } from '@libs/contexts/auth';

const GoogleLogin = () => {
  const { t } = useTranslation();
  const { federatedSignIn } = useAuth();

  return (
    <Button darkOutline fullWidth onClick={() => federatedSignIn('Google')}>
      <img className="pr-3" src={GoogleIcon} />
      {t('components.socialLogin.google')}
    </Button>
  );
};

export default GoogleLogin;
