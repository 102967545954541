import { forwardRef } from 'react';
import cx from 'classnames';

const SelectGroup = forwardRef(function SelectGroupPlain(
  {
    label,
    labelClassName,
    placeholder,
    children,
    disabled,
    errorMessage,
    fullWidth,
    helpMessage,
    options,
    size,
    value,
    onChange,
    ...props
  },
  ref
) {
  const defaultStyle = {
    'w-full': fullWidth,
    'select-outlined': true,
    'border-gray-200': true,
    'text-gray-400': true,
    'border-red-700': !!errorMessage
  };

  const sizeMapper = {
    lg: 'select-lg',
    md: 'select-md',
    sm: 'select-sm'
  };

  return (
    <>
      {label && (
        <label
          className={cx('block mb-1 text-gray-700', labelClassName)}
          htmlFor="forms-validationInputCode_error"
        >
          {label}
        </label>
      )}
      <select
        ref={ref}
        disabled={disabled}
        onChange={onChange}
        className={cx(defaultStyle, sizeMapper[size])}
        {...props}
      >
        {placeholder && (
          <option disabled value="">
            {placeholder}
          </option>
        )}
        {options &&
          options.map((group, i) => (
            <optgroup key={group + i} label={group.name}>
              {group.subcategories.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.name}
                </option>
              ))}
            </optgroup>
          ))}
      </select>

      {errorMessage && <span className="field-help field-help--error">{errorMessage}</span>}
      {helpMessage && <span className="field-help">{helpMessage}</span>}
    </>
  );
});

SelectGroup.defaultProps = {
  size: 'md'
};

export default SelectGroup;
