import { React } from 'react';
import FansincPrivacyPolicy from './fansinc';
import PatronsPrivacyPolicy from './patrons';

function TermsAndConditions() {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsPrivacyPolicy />;
  } else {
    return <FansincPrivacyPolicy />;
  }
}
export default TermsAndConditions;
