import cx from 'classnames';
import { SkeletonLoading } from '@components';

const Text = ({
  as,
  center,
  right,
  justify,
  children,
  className,
  loading,
  skeletonStyle,
  ...props
}) => {
  const validText = [
    'p',
    'span',
    'i',
    'u',
    'abbr',
    'cite',
    'del',
    'em',
    'ins',
    'kbd',
    'mark',
    's',
    'samp',
    'sub',
    'sup'
  ];
  const safeText = as ? as.toLowerCase() : '';
  const Tag = validText.includes(safeText) ? safeText : 'p';

  const alignMapper = {
    'text-center': center,
    'text-right': right,
    'text-justify': justify
  };

  return (
    <Tag className={cx(alignMapper, className)} {...props}>
      {loading ? <SkeletonLoading className={cx('text__skeleton', skeletonStyle)} /> : children}
    </Tag>
  );
};

Text.defaultProps = {
  size: 'base'
};

export default Text;
