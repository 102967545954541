import cx from 'classnames';
import { Box } from '@components';

const VerticalTable = ({ cols, rows, className, highlightRow, children }) => {
  return (
    <Box className="table-responsive table-responsive-mobile-only">
      <table className={cx('table vertical-table table-border', className)}>
        <tbody>
          {rows.map((items, i) => (
            <tr key={i} className={highlightRow.includes(i) ? 'active' : null}>
              {items.map((item, i) => (
                <td key={i}>{item}</td>
              ))}
            </tr>
          ))}
        </tbody>
        {children}
      </table>
    </Box>
  );
};

VerticalTable.defaultProps = {
  highlightRow: []
};

export default VerticalTable;
