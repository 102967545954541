import FansincRegister from './fansinc';
import PatronsRegister from './patrons';
function Signup({ showSocialLogin = true, loginUrl }) {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsRegister showSocialLogin={showSocialLogin} loginUrl={loginUrl} />;
  } else {
    return <FansincRegister showSocialLogin={showSocialLogin} loginUrl={loginUrl} />;
  }
}
export default Signup;
