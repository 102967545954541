import cx from 'classnames';
import { Avatar, Box, Text, Link } from '@components';
import { ReactComponent as VerifiedCreator } from '@assets/svg/verified_creator.svg';
import { getUserName } from '@libs/utils/user';

const CollectibleCardUser = ({ user, type = 'CREATOR' }) => {
  return (
    <Link to={`/${user?.username}`}>
      <Box className="collectible-card-user">
        <Avatar
          size="xs-40"
          user={user}
          className={cx(
            'collectible-card-user-avatar',
            type === 'FAN' && 'collectible-card-user-avatar-fan'
          )}
        />
        <Text>{user?.displayName || getUserName(user?.firstName) + ' ' + user?.lastName}</Text>
        {type === 'CREATOR' && <VerifiedCreator className="collectible-card-user-verified" />}
      </Box>
    </Link>
  );
};

export default CollectibleCardUser;
