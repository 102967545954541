import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box, Button, Link, Text } from '@components';
import Lottie from 'react-lottie';
import emptyBoxData from '@lotties/Empty-box';

const EmptyBox = ({ title, description }) => {
  const { t } = useTranslation();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: emptyBoxData
  };
  return (
    <Box className="py-12 px-5 md:px-0 text-center flex  justify-center items-center flex-col">
      <Box className="w-48 h-48">
        <Lottie options={defaultOptions} />
      </Box>
      <Text className="text-center text-xl md:text-4xl capitalize mt-4 font-bold text-primary">
        {title}
      </Text>
      <Text className="text-sm md:text-lg text-primary font-normal mt-4">{description}</Text>
    </Box>
  );
};

export default EmptyBox;
