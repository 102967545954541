import { useTranslation } from 'react-i18next';
import { Box, Button, Cropper, Text, Modal } from '@components';

import { useUser } from '@libs/hooks/user';
import { useUploader, useImageResizer } from '@libs/utils/uploader';
import { useState } from 'react';
import { useAuth } from '@libs/contexts/auth';

function UpdateAvatar({ refetch, open, setOpen }) {
  const { t } = useTranslation();
  const {
    inputRef: avatarRef,
    result: avatarResult,
    loading: onLoadingUploderAvatar,
    onClick: onClickUploadAvatar,
    onUpload: onUploadAvatar
  } = useUploader();

  const { onResize } = useImageResizer();

  const { onUpdateAvatar, onRemoveAvatar } = useUser();
  const { getCurrentUser } = useAuth();

  const [loading, setLoading] = useState();

  const handleCloseModalAvatar = () => {
    setOpen(false);
  };

  const handleChangeAvatar = async (resized) => {
    await onRemoveAvatar();
    await onUpdateAvatar(resized?.key);
  };

  return (
    <Modal
      verticalCenter
      showCloseIcon
      className="modal-avatar"
      isOpen={open}
      onClose={handleCloseModalAvatar}
      heading={t('settings.profile.fields.avatar.uploadImage')}
      size="profile"
    >
      <Box className="modal-avatar__upload">
        <Text className="text-gray-400">{t('settings.profile.fields.avatar.placeholder')}</Text>
        <Button
          onClick={onClickUploadAvatar}
          loading={onLoadingUploderAvatar}
          className="modal-avatar__upload__button"
        >
          {t('settings.profile.fields.avatar.chooseFile')}
        </Button>
        <input
          ref={avatarRef}
          accept="image/jpeg, image/png, image/webp"
          className="invisible w-0"
          type="file"
          onChange={(e) => onUploadAvatar(e, 'avatar')}
        />
      </Box>

      {avatarRef?.current?.value && avatarResult.fileUrl ? (
        <Box className="modal-avatar__cropper">
          <Cropper
            loading={loading}
            image={avatarResult.fileUrl}
            onComplete={async (croppedArea) => {
              setLoading(true);
              const resized = await onResize({
                key: avatarResult.s3?.key,
                folder: 'avatars',
                ...croppedArea
              });

              if (resized) {
                await handleChangeAvatar(resized);
              }

              setLoading(false);
              setOpen(false);

              refetch && (await refetch());
              await getCurrentUser();

              //   avatarRef.current.value = '';
            }}
            onCancel={() => {
              setOpen(false);
              avatarRef.current.value = '';
            }}
          />
        </Box>
      ) : null}
    </Modal>
  );
}

export default UpdateAvatar;
