import { Box } from '@components';
import { useEffect, useState } from 'react';
import cx from 'classnames';

const Modal = ({ isOpen, onClose, children, ...props }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [show, setShow] = useState('responsive-modal-body-content-hide');

  const closeModal = () => {
    setShow('responsive-modal-body-content-hide');
    setTimeout(() => {
      onClose();
      setIsModalOpen(false);
    }, 200);
  };

  useEffect(() => {
    if (isOpen) {
      setIsModalOpen(true);
      setTimeout(() => {
        setShow('responsive-modal-body-content');
      }, 50);
    } else {
      closeModal();
    }
  }, [isOpen]); // eslint-disable-line
  return (
    isModalOpen && (
      <Box className="responsive-modal">
        <Box className="responsive-modal-body">
          <Box className="responsive-modal-body-overlay" onClick={() => closeModal()}></Box>
          <Box className={cx(show)}>{children}</Box>
        </Box>
      </Box>
    )
  );
};

export default Modal;
