import cx from 'classnames';
import { useEffect, useState } from 'react';
import { Box, SkeletonLoading } from '@components';
import { fetchImage } from './hooks';

const MediaImage = ({ collectible, full, size, thumbnail }) => {
  const [image, setImage] = useState();

  useEffect(() => {
    fetchImage({
      image: thumbnail ? collectible?.mediaThumbnail : collectible?.assetUrl,
      setImage,
      size
    });
  }, [collectible?.assetUrl, collectible?.mediaThumbnail, size, thumbnail]);

  const mediaStyle = full ? {} : { backgroundImage: `url(${image || collectible?.assetUrl})` };
  const defaultStyle = {
    'media-photograph': collectible?.category === 'PHOTOGRAPH' && full
  };

  return (
    <Box className={cx('media__image')} style={mediaStyle}>
      {collectible?.assetUrl || image ? (
        <>
          {full ? (
            <img
              className={cx(defaultStyle)}
              src={image || collectible?.assetUrl}
              alt={collectible?.title}
            />
          ) : null}
        </>
      ) : (
        <SkeletonLoading className="aspect-w-6 aspect-h-6" />
      )}
    </Box>
  );
};

MediaImage.defaultProps = {
  full: false,
  thumbnail: false,
  size: 600
};

export default MediaImage;
