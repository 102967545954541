import { React } from 'react';
import FansincTermsAndConditions from './fansinc';
import PatronsTermsAndConditions from './patrons';

function TermsAndConditions() {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsTermsAndConditions />;
  } else {
    return <FansincTermsAndConditions />;
  }
}
export default TermsAndConditions;
