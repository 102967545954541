import { useState, useEffect } from 'react';
import { Link } from '@components';
import { useTranslation, Trans } from 'react-i18next';

import gql from '@libs/utils/gql';
import { syncWalletAddressWithUser } from '@graphql/mutations';
import handleGraphQLErrors from '@libs/utils/errors/graphql';
import {
  formatWalletAddress,
  getCurrentWalletConnected
} from '@components/connect-wallet-button/patrons/utils';

export const useWalletConnected = ({ setWalletAddress, setStatus, className }) => {
  const { t } = useTranslation();

  useEffect(() => {
    const getCurrentWalletConnectedAsync = async () => {
      const { address, status } = await getCurrentWalletConnected(t, className);
      setWalletAddress(address);
      setStatus(status);
    };
    getCurrentWalletConnectedAsync();
  }, [setStatus, setWalletAddress, t]);
};

export const useWalletChanged = ({ setWalletAddress, setStatus }) => {
  const { t } = useTranslation();

  useEffect(() => {
    const addWalletListener = () => {
      if (window.ethereum) {
        window.ethereum.on('accountsChanged', (accounts) => {
          if (accounts?.length > 0) {
            setWalletAddress(accounts[0]);
            setStatus(
              <Trans
                i18nKey="components.connectWalletButton.statusHelper.successfullyConnected"
                values={{ address: formatWalletAddress(accounts[0]) }}
              />
            );
          } else {
            setWalletAddress('');
            setStatus(t('components.connectWalletButton.statusHelper.needsConnection'));
          }
        });
      } else {
        setStatus(
          <Trans i18nKey="components.connectWalletButton.statusHelper.installMetamask">
            <Link to="https://metamask.io/download.html" target="_blank" />
          </Trans>
        );
      }
    };

    addWalletListener();
  }, [setStatus, setWalletAddress, t]);
};

export const useSyncWalletAddress = ({ walletAddress, setHasError }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const syncWalletAddressWithUserAsync = async (walletAddress) => {
      try {
        setIsLoading(true);
        setError(null);
        const {
          data: { syncWalletAddressWithUser: response }
        } = await gql(
          syncWalletAddressWithUser,
          {
            input: { walletAddress }
          },
          {
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          }
        );

        const { statusCode, message } = JSON.parse(response);
        if (statusCode === 200) {
          setHasError(false);
          return;
        }
        throw new Error(message ?? 'Unknown Error');
      } catch (e) {
        console.error(e);
        const handledError = handleGraphQLErrors(e);
        setError(handledError?.messages[0] ?? 'Something has gone wrong.');
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    };
    if (walletAddress) {
      syncWalletAddressWithUserAsync(walletAddress);
    } else {
      setError(null);
    }
  }, [setHasError, walletAddress]);

  return { isLoading, error };
};
