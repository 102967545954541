import cx from 'classnames';
import { useState, useCallback, useEffect } from 'react';
import { Box, Separator, Text, Input, ResponsiveModal, CustomDropdown, Button } from '@components';
import { ReactComponent as Search } from '@assets/svg/search-artists.svg';
import { useOutsider } from '@libs/utils/outsider';
import { ReactComponent as ArrowDownIcon } from '@assets/svg/arrow-down.svg';
import { useHistory } from 'react-router-dom';
import useQuery from '@libs/utils/query';
import { genres } from '@libs/utils/options';
import { useTranslation } from 'react-i18next';
import { useFeaturedArtist } from '@libs/hooks/featured-artist';

function FilterCollectibles({ onChangeFilter, children, setOpenModal, openModal }) {
  const { t } = useTranslation();
  const { activeOptions: artists } = useFeaturedArtist();
  const [queryCount, setQueryCount] = useState(0);
  const [count, setCount] = useState(0);
  const history = useHistory();
  const query = useQuery();

  //SORTING
  const [isOpenSorting, setOpenSorting] = useState(false);
  const handleCloseSort = () => setOpenSorting(false);
  const { ref: refSort } = useOutsider(handleCloseSort);
  const [sort, setSort] = useState('TITLE_ASC');
  const handleOpenSorting = useCallback(
    (e) => {
      e.preventDefault();
      setOpenSorting(!isOpenSorting);
    },
    [isOpenSorting]
  );
  const getSortLabel = () => {
    const filter = listSort.filter((list) => {
      return list.value === sort;
    });
    return filter[0].label;
  };
  const listSort = [
    {
      label: 'Name (A - Z)',
      value: 'TITLE_ASC'
    },
    {
      label: 'Name (Z - A)',
      value: 'TITLE_DESC'
    },
    {
      label: 'Recently Listed',
      value: 'CREATED_AT_DESC'
    }
  ];

  //FILTER ARTIST
  const listArtist = [
    {
      label: 'ALL',
      value: ''
    },
    ...artists
  ];
  const [isOpenArtist, setOpenArtist] = useState(false);
  const [artistName, setArtistName] = useState('All');
  const handleCloseArtist = () => setOpenArtist(false);
  const { ref: refArtist } = useOutsider(handleCloseArtist);
  const [artist, setArtist] = useState('');
  const handleOpenArtist = useCallback(
    (e) => {
      e.preventDefault();
      setOpenArtist(!isOpenArtist);
    },
    [isOpenArtist]
  );

  //FILTER STYLE
  const listStyle = [
    {
      label: 'ALL',
      value: ''
    },
    ...genres
  ];
  const [isOpenStyle, setOpenStyle] = useState(false);
  const [styleName, setStyleName] = useState('All');
  const handleCloseStyle = () => setOpenStyle(false);
  const { ref: refStyle } = useOutsider(handleCloseStyle);
  const [style, setStyle] = useState('');
  const handleOpenStyle = useCallback(
    (e) => {
      e.preventDefault();
      setOpenStyle(!isOpenStyle);
    },
    [isOpenStyle]
  );

  //FILTER TITLE
  const [title, setTitle] = useState('');

  useEffect(() => {
    if (count !== 0) {
      history.replace(
        `/marketplace?title=${
          title?.length > 0 ? title : ''
        }&sort=${sort}&artist=${artist}&style=${style}`
      );
    } else {
      setCount(count + 1);
    }
  }, [sort, artist, style, title]);

  const resetFilter = () => {
    setArtist('');
    setTimeout(() => {
      setStyle('');
    }, 50);
    setTimeout(() => {
      setSort('TITLE_ASC');
    }, 100);
  };

  useEffect(() => {
    const title = query.get('title');
    const category = query.get('category');
    const genre = query.get('style');
    const era = query.get('era');
    const artist = query.get('artist');
    const sort = query.get('sort');
    const price_from = query.get('price_from');
    const price_to = query.get('price_to');

    const currentCategories = category ? category.split(',') : [];
    const currentGenre = genre ? genre.split(',') : [];
    const currentEra = era ? era.split(',') : [];
    const currentArtist = artist ? artist.split(',') : [];

    if (
      queryCount > 0 ||
      (title && title.length > 0) ||
      currentCategories.length > 0 ||
      currentGenre.length > 0 ||
      currentEra.length > 0 ||
      (sort && sort.length > 0)
    ) {
      onChangeFilter(
        title,
        currentCategories,
        currentGenre,
        currentEra,
        currentArtist,
        sort,
        price_from,
        price_to
      );
    } else {
      setQueryCount(queryCount + 1);
    }
  }, [query]);

  return (
    <>
      <Box className="marketplace-page__body__filter">
        <Box className="marketplace-page__body__filter__input">
          <Search />
          <Box className="marketplace-page__body__filter__input__wrapper">
            <Input
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  setTitle(e.target.value);
                }
              }}
              placeholder={t('p.pages.marketplace.search')}
            />
          </Box>
        </Box>
        <Box className="marketplace-page_search">
          <Box className="marketplace-page__search-sorting">
            <Text className="marketplace-page__search-sorting-header" onClick={handleOpenStyle}>
              <Text as="span" className="marketplace-page__search-sorting-header__type">
                {t('p.pages.marketplace.style')}
              </Text>
              <Text as="span" className="marketplace-page__search-sorting-header__item">
                {styleName}
                <ArrowDownIcon />
              </Text>
            </Text>
            {isOpenStyle && (
              <Box className="select-multiple__dropdown overflow-hidden" ref={refStyle}>
                <ul>
                  {genres &&
                    listStyle &&
                    listStyle.map((s) => (
                      <>
                        <li
                          className={cx(style === s.value && 'active')}
                          key={s.value}
                          value={s.value}
                          onClick={() => {
                            setStyle(s.value);
                            setStyleName(s.label);
                            handleCloseStyle();
                          }}
                        >
                          {s.label}
                        </li>
                        <Separator />
                      </>
                    ))}
                </ul>
              </Box>
            )}
          </Box>
        </Box>
        <Box className="marketplace-page_search">
          <Box className="marketplace-page__search-sorting">
            <Text className="marketplace-page__search-sorting-header" onClick={handleOpenArtist}>
              <Text as="span" className="marketplace-page__search-sorting-header__type">
                {t('p.pages.marketplace.artist')}
              </Text>
              <Text as="span" className="marketplace-page__search-sorting-header__item">
                {artistName}
                <ArrowDownIcon />
              </Text>
            </Text>
            {isOpenArtist && (
              <Box className="select-multiple__dropdown overflow-hidden" ref={refArtist}>
                <ul>
                  {artists &&
                    listArtist &&
                    listArtist.map((a) => (
                      <>
                        <li
                          className={cx(artist === a.value && 'active')}
                          key={a.value}
                          value={a.value}
                          onClick={() => {
                            setArtist(a.value);
                            setArtistName(a.label);
                            handleCloseArtist();
                          }}
                        >
                          {a.label}
                        </li>
                        <Separator />
                      </>
                    ))}
                </ul>
              </Box>
            )}
          </Box>
        </Box>
        <Box className="marketplace-page_search">
          <Box className="marketplace-page__search-sorting">
            <Text className="marketplace-page__search-sorting-header" onClick={handleOpenSorting}>
              <Text as="span" className="marketplace-page__search-sorting-header__type">
                {t('p.pages.marketplace.sort')}
              </Text>
              <Text as="span" className="marketplace-page__search-sorting-header__item">
                {getSortLabel()}
                <ArrowDownIcon />
              </Text>
            </Text>
            {isOpenSorting && (
              <Box className="select-multiple__dropdown overflow-hidden" ref={refSort}>
                <ul>
                  {listSort &&
                    listSort.map((o) => (
                      <>
                        <li
                          className={cx(sort === o.value && 'active')}
                          key={o.value}
                          value={o.value}
                          onClick={() => {
                            setSort(o.value);
                            handleCloseSort();
                          }}
                        >
                          {o.label}
                        </li>
                        <Separator />
                      </>
                    ))}
                </ul>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <ResponsiveModal isOpen={openModal} onClose={() => setOpenModal(false)}>
        <Box className="flex items-center justify-between mb-2">
          <Box className="text-lg font-bold">{t('p.pages.marketplace.filterArtworks')}</Box>
          <Box
            onClick={() => resetFilter()}
            className="text-sm text-secondary underline font-medium"
          >
            {t('p.pages.marketplace.reset')}
          </Box>
        </Box>
        <CustomDropdown value={style} list={listStyle} setValue={setStyle} label="Style" />
        <CustomDropdown value={artist} list={listArtist} setValue={setArtist} label="Artist" />
        <CustomDropdown value={sort} list={listSort} setValue={setSort} label="Sort" />
        <Button className="mt-5" onClick={() => setOpenModal(false)} fullWidth>
          {t('p.pages.marketplace.close')}
        </Button>
      </ResponsiveModal>
      <Separator className="marketplace-page-separator my-4" />
      {children}
    </>
  );
}

export default FilterCollectibles;
