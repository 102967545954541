import React, { useState } from 'react';
// import Lightbox from 'react-image-lightbox';
import { FORMAT_TYPE, mediaChecker } from '@libs/utils/media';
import VideoPlayer from './video-player';
import Lightbox from './lightbox';
import { useHistory } from 'react-router-dom';
import { createAssetUrl } from '@libs/utils/media';

const max = 4;

const imageSliceClassName = {
  1: 'image-collage-container-1',
  2: 'image-collage-container-2',
  3: 'image-collage-container-3'
};

function ImageCollage({ images, ...props }) {
  const [lightboxState, setLightboxState] = useState({ open: false, imageIndex: 0 });
  const history = useHistory();
  return (
    <>
      {images.length > 0 && (
        <div
          className={`image-collage-container ${
            images.slice(0, max).length - 1 > 0
              ? `${imageSliceClassName[images.slice(0, max).length - 1]}`
              : ''
          }`}
          {...props}
        >
          {images.slice(0, max).map((img, index) =>
            process.env.REACT_APP_NAME === 'patrons' ? (
              <img
                key={`img-${new Date().getTime()}-${index}`}
                src={img.src}
                srcSet={img.srcSet}
                alt={`img-${index}`}
                onClick={() => history.replace(img.url)}
              ></img>
            ) : mediaChecker(img?.src) === FORMAT_TYPE.VIDEO ? (
              <VideoPlayer
                key={`img-${new Date().getTime()}-${index}`}
                url={img?.src}
                full={true}
                controls={false}
                onClick={(e) => {
                  e.preventDefault();
                  setLightboxState({ open: true, imageIndex: index });
                }}
                fallbackSrc={createAssetUrl(
                  `fanclub_uploads/${img.src.split('/').pop().split('.')[0]}.mp4`,
                  'full'
                )}
              />
            ) : (
              <img
                key={`img-${new Date().getTime()}-${index}`}
                src={img.src}
                srcSet={img.srcSet}
                alt={`img-${index}`}
                onClick={(e) => {
                  setLightboxState({ open: true, imageIndex: index });
                }}
              ></img>
            )
          )}
          {images.length - max > 0 && (
            <div
              className="image-collage-overlay"
              onClick={() => {
                setLightboxState({ open: true, imageIndex: max - 1 });
              }}
            >
              +{images.length - max}
            </div>
          )}
        </div>
      )}
      {lightboxState.open && (
        <Lightbox
          show={lightboxState.open}
          onClose={() => {
            setLightboxState({ imageIndex: 0, open: !lightboxState.open });
          }}
          index={lightboxState.imageIndex}
          setIndex={(idx) => setLightboxState({ ...lightboxState, imageIndex: idx })}
          sources={images}
        />
      )}
    </>
  );
}

export default ImageCollage;
