import { Box, Separator, TextArea, Button, SkeletonLoading } from '@components';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import PatronsCollectibleArtist from './PatronsCollectibleArtist';
import useToast from '@libs/utils/toast';
import { HiReply } from 'react-icons/hi';
import { useAuth } from '@libs/contexts/auth';
import { useCommentCollectible, useReplyCommentCollectible } from '@libs/hooks/discourse';
import { ReactComponent as CommentaryIcon } from '@assets/svg/commentary.svg';

export default function PatronsCollectibleCommentary({ collectible }) {
  const toast = useToast();
  const { t } = useTranslation();
  const { user, isAuthenticated } = useAuth();
  const [listComment, setListComment] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField
  } = useForm({
    mode: 'onBlur'
  });
  const { createComment, data, getData, loading } = useCommentCollectible({
    collectibleID: collectible?.id
  });
  const handleSubmitComment = async (values) => {
    if (!isAuthenticated) {
      toast(t('p.pages.collectible.unauthorizeComment'), 'error');
      return;
    }
    const payload = {
      collectibleID: collectible?.id,
      raw: values.comment
    };
    const newComment = {
      channelID: collectible?.id,
      user: user,
      content: values.comment,
      createdAt: new Date()
    };
    resetField('comment');
    setListComment(listComment.concat(newComment));
    setTotalComment(totalComment + 1);
    await createComment(payload);
    setTimeout(function () {
      getData();
    }, 2000);
  };
  const [totalComment, setTotalComment] = useState(0);
  const countComment = async () => {
    let count = 0;
    await data?.map((x) => {
      count += x?.replies?.items?.length;
    });
    setTotalComment(count + data?.length);
  };
  useEffect(() => {
    countComment();
    setListComment(data);
  }, [data]); // eslint-disable-line

  return (
    <div>
      <Box className="collectible__tab__commentary">
        {loading ? (
          <SkeletonLoading className="h-36 w-full mb-6" />
        ) : (
          <>
            {listComment?.length > 0 ? (
              <>
                <Box className="collectible__tab__commentary__count flex items-center gap-3">
                  {loading ? (
                    <SkeletonLoading className="w-7 h-9" />
                  ) : totalComment > 100 ? (
                    <Box>100+</Box>
                  ) : (
                    <Box>{totalComment}</Box>
                  )}
                  <Box>{t('p.pages.collectible.comment.title')}</Box>
                </Box>
                <Box className="collectible__tab__commentary__comment">
                  {loading ? (
                    <>
                      <Separator className="mt-7 mb-3" />
                      <SkeletonLoading className="w-11/12 h-28 mx-5" />
                    </>
                  ) : (
                    <>
                      {listComment?.map((x) => (
                        <>
                          <Separator className="mt-7 mb-3" />
                          <Comment
                            user={x.user}
                            value={x}
                            setTotalComment={setTotalComment}
                            totalComment={totalComment}
                          />
                        </>
                      ))}
                    </>
                  )}
                </Box>
              </>
            ) : (
              <Box className="collectible__tab__commentary__empty">
                <Box className="collectible__tab__commentary__empty__wrapper">
                  <Box className="collectible__tab__commentary__empty__wrapper__icon">
                    <CommentaryIcon />
                  </Box>
                  <Box className="collectible__tab__commentary__empty__wrapper__title">
                    {t('p.pages.collectible.comment.empty.title')}
                  </Box>
                  <Box className="collectible__tab__commentary__empty__wrapper__description">
                    {t('p.pages.collectible.comment.empty.description')}
                  </Box>
                </Box>
              </Box>
            )}
          </>
        )}
        <Box className="collectible__tab__commentary__input">
          <form onSubmit={handleSubmit(handleSubmitComment)}>
            <Box className="collectible__tab__commentary__input__value">
              <TextArea
                as="comment"
                errorMessage={errors.comment?.message}
                {...register('comment', {
                  required: {
                    value: true,
                    message: t('p.pages.collectible.commentRequired')
                  }
                })}
                fullWidth
                placeholder={t('p.pages.collectible.commentPlaceholder')}
              />
            </Box>
            <Box className="pt-6">
              <Button type="submit">{t('p.pages.collectible.postComment')}</Button>
            </Box>
          </form>
        </Box>
      </Box>
    </div>
  );
}

const Comment = ({ user, value, setTotalComment, totalComment }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const [showInput, setShowInput] = useState(false);
  const { user: author, isAuthenticated } = useAuth();
  const [liked, setLiked] = useState(value?.likedBy?.includes(author?.id));
  const [listComment, setListComment] = useState(value?.replies?.items.map((x) => x.post));
  const { createReplyComment, data, likeComment, likeLoading, unlikeComment, getData } =
    useReplyCommentCollectible({
      commentID: value?.id,
      collectibleID: value?.channelID
    });
  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField
  } = useForm({
    mode: 'onBlur'
  });
  const handleSubmitReplyComment = async (values) => {
    if (!isAuthenticated) {
      toast(t('p.pages.collectible.unauthorizeComment'), 'error');
      return;
    }
    const payload = {
      raw: values.comment
    };
    const newComment = {
      channelID: value?.channelID,
      user: author,
      content: values.comment,
      createdAt: new Date()
    };
    resetField('comment');
    setListComment(listComment.concat(newComment));
    setTotalComment(totalComment + 1);
    setShowInput(false);
    await createReplyComment(payload);
    setTimeout(function () {
      getData();
    }, 2000);
  };
  const onLikeComment = async () => {
    if (!isAuthenticated) {
      toast(t('p.pages.collectible.unauthorizeLike'), 'error');
      return;
    }
    if (!likeLoading) {
      setLiked(true);
      await likeComment(value?.id);
    }
  };
  const onUnlikeComment = async () => {
    if (!likeLoading) {
      setLiked(false);
      await unlikeComment(value?.id);
    }
  };
  useEffect(() => {
    setListComment(data);
  }, [data]); // eslint-disable-line
  return (
    <Box className="collectible-comment">
      <Box className="collectible-comment__top">
        <PatronsCollectibleArtist
          owner={user}
          title={user?.displayName || user?.firstName + ' ' + user?.lastName}
          time={value?.createdAt}
        />
        <Box className="collectible-comment__top__icon">
          {value?.id && (
            <>
              {liked ? (
                <AiFillHeart
                  onClick={() => onUnlikeComment()}
                  className="text-red-300 cursor-pointer"
                  size="20"
                />
              ) : (
                <AiOutlineHeart
                  className="cursor-pointer"
                  onClick={() => onLikeComment()}
                  size={20}
                />
              )}
            </>
          )}
        </Box>
      </Box>
      <Box className="collectible-comment__value">{value?.content}</Box>
      {showInput ? (
        <>
          <form onSubmit={handleSubmit(handleSubmitReplyComment)}>
            <Box className="collectible__tab__commentary__input__value">
              <TextArea
                as="comment"
                errorMessage={errors.comment?.message}
                {...register('comment', {
                  required: {
                    value: true,
                    message: t('p.pages.collectible.commentRequired')
                  }
                })}
                fullWidth
                placeholder="Type here..."
              />
            </Box>
            <Box className="pb-6">
              <Button type="submit">{t('p.pages.collectible.buttonLeaveComment')}</Button>
            </Box>
          </form>
        </>
      ) : (
        <>
          {value?.id && (
            <Box onClick={() => setShowInput(true)} className="collectible-comment__bottom">
              <HiReply size={20} /> <Box className="ml-2">{t('p.pages.collectible.reply')}</Box>
            </Box>
          )}
        </>
      )}
      {listComment?.map((comment) => (
        <ChildComment key={comment.id} comment={comment} />
      ))}
    </Box>
  );
};

const ChildComment = ({ comment }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { user: author, isAuthenticated } = useAuth();
  const [liked, setLiked] = useState(comment?.likedBy?.includes(author?.id));
  const { likeComment, likeLoading, unlikeComment } = useReplyCommentCollectible({
    commentID: comment?.id,
    collectibleID: comment?.channelID
  });
  const onLikeComment = async () => {
    if (!isAuthenticated) {
      toast(t('p.pages.collectible.unauthorizeLike'), 'error');
      return;
    }
    if (!likeLoading) {
      setLiked(true);
      await likeComment(comment?.id);
    }
  };
  const onUnlikeComment = async () => {
    if (!likeLoading) {
      setLiked(false);
      await unlikeComment(comment?.id);
    }
  };
  return (
    <>
      <Box className="collectible-comment__childComment">
        <Box className="collectible-comment__top">
          <PatronsCollectibleArtist
            owner={comment?.user}
            title={
              comment?.user?.displayName || comment?.user?.firstName + ' ' + comment?.user?.lastName
            }
            time={comment?.createdAt}
          />
          <Box className="collectible-comment__top__icon">
            {comment?.id && (
              <>
                {liked ? (
                  <AiFillHeart
                    onClick={() => onUnlikeComment()}
                    className="text-red-300 cursor-pointer"
                    size="20"
                  />
                ) : (
                  <AiOutlineHeart
                    className="cursor-pointer"
                    onClick={() => onLikeComment()}
                    size={20}
                  />
                )}
              </>
            )}
          </Box>
        </Box>
        <Box className="collectible-comment__value">{comment?.content}</Box>
      </Box>
    </>
  );
};
