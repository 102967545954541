import cx from 'classnames';
import { useState, useEffect } from 'react';
import {
  Box,
  CollectibleLike,
  CollectibleTier,
  Media,
  Link,
  Text,
  Separator,
  Button
} from '@components';
import { useTranslation, Trans } from 'react-i18next';
import { getUserName } from '@libs/utils/user';
import { getNearestReleaseDate } from '@libs/utils/collectible';
import { urlParser, collectibleParser } from '@pages/profile/utils';
import { getButton, getPriceRelease } from '../utils';
import { ReactComponent as VIP } from '@assets/svg/vip-gold.svg';
import { ReactComponent as VIPBadge } from '@assets/svg/vip-badge.svg';
import ReactTooltip from 'react-tooltip';
import { useTierAvailability } from '@libs/hooks/exchange';
import { motion } from 'framer-motion/dist/framer-motion';

const PatronsCollectibleCardV2 = ({
  className,
  collectible,
  isPreview,
  type,
  hasCollectibleTimer,
  showManageListing,
  updateExchangeData,
  showOwnerInUrl,
  showReleaseAsCollectible,
  showButton,
  onVisit,
  showTopNavigation,
  showPrice,
  collectibleUrl,
  creatorUrl,
  ...props
}) => {
  const [url, setUrl] = useState(null);
  const data = collectibleParser(collectible, type);
  const { t } = useTranslation();
  const [isHovered, setHovered] = useState(false);

  const releaseDate = getNearestReleaseDate(
    collectible?.releases || collectible?.collectible?.releases
  );
  data?.releases?.sort((a, b) =>
    a.tierType === 'DIGITAL_ORIGINAL' ? -1 : a.releaseDate > b.releaseDate ? 1 : -1
  );

  useEffect(() => {
    let url_ = urlParser(collectible, type, showOwnerInUrl ?? true);
    if (type === 'release' && showReleaseAsCollectible) {
      url_ = urlParser(collectible?.collectible, 'collectible', showOwnerInUrl ?? true);
    }
    setUrl(url_);
  }, [collectible, collectible?.currentOwner?.id, type, showOwnerInUrl, showReleaseAsCollectible]);

  const [currentTime, setCurrentTime] = useState(new Date().getTime());
  const releaseDateSeconds = new Date(releaseDate).getTime();
  const now = new Date().toISOString();
  const { data: totalRelease } = useTierAvailability({
    collectibleID: collectible?.id,
    creatorID: collectible?.userID
  });

  const numberedEdition = collectible?.releases?.find((x) => x.tierType === 'NUMBERED_EDITION');
  const auctionEndDate =
    type === 'collectible' &&
    data?.releases[0]?.saleEndDate &&
    data?.releases[0]?.listingType === 'AUCTION'
      ? new Date(data?.releases[0]?.saleEndDate)
      : null;

  useEffect(() => {
    let intervalId = null;
    if (releaseDate) {
      intervalId = setInterval(() => {
        const now = new Date().getTime();
        setCurrentTime(now);
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [releaseDate]);

  const childMenuAnimate = {
    enter: {
      opacity: 1,
      transition: {
        duration: 0.8
      },
      display: 'block'
    },
    exit: {
      opacity: 0,
      transitionEnd: {
        display: 'none'
      }
    }
  };

  return data?.category === 'VIP_PASS' ? (
    <Box className="collectible-card-vip">
      <VIP className={cx('collectible-card-vip__bg')} />
      <Box className="collectible-card-vip__content">
        {isPreview ? (
          <Media collectible={data} />
        ) : (
          <Link to={url}>
            <Media collectible={data} showPlayer thumbnail />
            <Box>
              <Text className="collectible-card-vip__title">{data?.title}</Text>
              <VIPBadge className="w-full" />
            </Box>
          </Link>
        )}
      </Box>
    </Box>
  ) : (
    <Box
      className={cx(
        'collectible-card-new',
        className,
        onVisit && 'collectible-card-new-has-button'
      )}
    >
      <>
        {onVisit && (
          <>
            <Box
              data-tip
              data-for={`minimumTooltipCollected${data?.id}`}
              className="collectible-card-new__collected"
            >
              <Box className="collectible-card-new__collected__wrapper">
                {t('p.components.card.visit')}
              </Box>
            </Box>
            <ReactTooltip id={`minimumTooltipCollected${data?.id}`} place="bottom" type="dark">
              <Box className="collectible-card-new__collected__tooltip">
                {t('p.components.card.collected')}
              </Box>
            </ReactTooltip>
          </>
        )}
        {isPreview ? (
          <Media collectible={data} data-tip data-for={`minimumTooltip${data?.id}`} />
        ) : (
          <Link to={url} data-tip data-for={`minimumTooltip${data?.id}`}>
            <Media collectible={data} showPlayer thumbnail />
          </Link>
        )}
        <ReactTooltip id={`minimumTooltip${data?.id}`} place="right" type="dark">
          <Box className="normal-case max-w-xs">
            <Trans i18nKey="p.components.card.collectibleTooltip">
              {{ collectibleTitle: data?.title }}
            </Trans>
          </Box>
        </ReactTooltip>
        <motion.div
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          className="collectible-card-new__content"
        >
          <Box className="collectible-card-new__body">
            <Box className="collectible-card-new__body__title">
              <Link to={`/${data?.creator?.username}/${data?.slug}`}>
                <Text className="collectible-card-new__title">{data?.title}</Text>
              </Link>
              {/* <CollectibleLike collectibleID={data?.id} /> */}
            </Box>
            {data && (
              <Box className="collectible-card-new__body__creator">
                <Trans i18nKey="p.components.card.creatorName">
                  <Link
                    className="collectible-card-new__body__creator__name"
                    to={`/${data?.creator?.username}`}
                  >
                    <span>
                      {{
                        creatorName:
                          data?.creator?.displayName ||
                          getUserName(data?.creator?.firstName) + ' ' + data?.creator?.lastName
                      }}
                    </span>
                  </Link>
                </Trans>
              </Box>
            )}
            {showPrice && (
              <>
                <Separator
                  dark
                  className="collectible-card-new__separator collectible-card-new__separator-top"
                />
                {getPriceRelease(
                  type,
                  data,
                  collectible,
                  auctionEndDate,
                  releaseDate,
                  releaseDateSeconds,
                  currentTime,
                  collectible?.lastSoldAt
                )}
              </>
            )}
            <motion.div
              initial="exit"
              animate={isHovered ? 'enter' : 'exit'}
              variants={childMenuAnimate}
            >
              <>
                {numberedEdition?.releaseDate < now ? (
                  <>
                    {totalRelease === 0 ? (
                      <Button
                        disabled={true}
                        fullWidth
                        darkOutline
                        className="collectible-card-new__body__button"
                      >
                        {t('p.pages.collectible.soldOut')}
                      </Button>
                    ) : (
                      <Link
                        className="collectible-card-new__body__link"
                        to={`/${collectible?.creator?.username}/${collectible?.slug}/checkout/ne`}
                      >
                        <Button
                          fullWidth
                          darkOutline
                          className="collectible-card-new__body__button"
                        >
                          {t('p.components.card.button')}
                        </Button>
                      </Link>
                    )}
                  </>
                ) : (
                  <Button fullWidth darkOutline className="collectible-card-new__body__button">
                    {t('p.pages.collectible.tba')}
                  </Button>
                )}
              </>
            </motion.div>
          </Box>
        </motion.div>
      </>
    </Box>
  );
};

export default PatronsCollectibleCardV2;
