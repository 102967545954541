import { useState } from 'react';
import gql from '@libs/utils/gql';
import { createInbox } from '@graphql/mutations';
import useToast from '@libs/utils/toast';

export const useInbox = () => {
  const [createLoading, setCreateLoading] = useState(false);
  const toast = useToast();

  const onCreate = async (payload) => {
    setCreateLoading(true);

    try {
      await gql(createInbox, {
        input: payload
      });
      return true;
    } catch (error) {
      const errorMessage = error?.errors[0]?.message;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setCreateLoading(false);
    }
  };

  return {
    createLoading,
    onCreate
  };
};
