import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box, Avatar } from '@components';

function ArtistProfile({ size, topDescription, bottomDescription, user }) {
  const { t } = useTranslation();

  const avatarClass = {
    sm: 'xs',
    md: 'xs-40'
  };

  const leadingNameClass = {
    sm: 'leading-5',
    md: 'leading-5'
  };

  const leadingTitleClass = {
    sm: 'leading-4',
    md: 'leading-4'
  };

  return (
    <Box className="artist-profile">
      <Box>
        <Avatar user={user} size={avatarClass[size]} />
      </Box>
      <Box className="artist-profile__detail">
        <Box className={cx(leadingNameClass[size], 'artist-profile__detail__name')}>
          {topDescription || user?.displayName || user?.firstName + ' ' + user?.lastName}
        </Box>
        {bottomDescription && (
          <Box className={cx(leadingTitleClass[size], 'artist-profile__detail__description')}>
            {bottomDescription}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default ArtistProfile;
