import FansincAuth from './fansinc';
import PatronsAuth from './patrons';

function Auth() {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsAuth />;
  } else {
    return <FansincAuth />;
  }
}

export default Auth;
