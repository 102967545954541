import FansincRelease from './fansinc';
import PatronsRelease from './patrons';

function Release({ setGalleryLogo }) {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsRelease setGalleryLogo={setGalleryLogo} />;
  } else {
    return <FansincRelease />;
  }
}

export default Release;
