import FansincCollectibleLike from './fansinc';
import PatronsCollectibleLike from './patrons';

const CollectibleLike = ({ collectibleID, ...props }) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsCollectibleLike collectibleID={collectibleID} {...props} />;
  } else {
    return <FansincCollectibleLike collectibleID={collectibleID} {...props} />;
  }
};

export default CollectibleLike;
