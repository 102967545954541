import { useEffect, useState } from 'react';

const createSorter = (sortBy) => {
  switch (sortBy) {
    case 'PRICE_LOWEST':
      return { direction: 'asc', field: 'searchableLowestPrice' };
    case 'PRICE_HIGHEST':
      return { direction: 'desc', field: 'searchableLowestPrice' };
    case 'RELEASED_AT_ASC':
      return { direction: 'asc', field: 'releasedAt' };
    case 'RELEASED_AT_DESC':
      return { direction: 'desc', field: 'releasedAt' };
    case 'CREATED_AT_ASC':
      return { direction: 'asc', field: 'createdAt' };
    case 'YEAR':
      return { direction: 'asc', field: 'yearOfCreation' };
    case 'TITLE_ASC':
      return { direction: 'asc', field: 'title' };
    case 'TITLE_DESC':
      return { direction: 'desc', field: 'title' };
    case 'CREATED_AT_DESC':
    default:
      return { direction: 'desc', field: 'createdAt' };
  }
};

const eras = {
  '1970s': { min: 1970, max: 1979 },
  '1980s': { min: 1980, max: 1989 },
  '1990s': { min: 1990, max: 1999 },
  '2000s': { min: 2000, max: 2009 },
  '2010s': { min: 2010, max: 2019 },
  '2020s': { min: 2020, max: 2029 }
};

export const listEras = [
  { name: '1970s', min: 1970, max: 1979 },
  { name: '1980s', min: 1980, max: 1989 },
  { name: '1990s', min: 1990, max: 1999 },
  { name: '2000s', min: 2000, max: 2009 },
  { name: '2010s', min: 2010, max: 2019 },
  { name: '2020s', min: 2020, max: 2029 }
];

export const listGenres = [
  { name: 'Rock', value: 'ROCK' },
  { name: 'Classic Rock', value: 'CLASSIC_ROCK' },
  { name: 'Pop-Rock', value: 'POP_ROCK' },
  { name: 'Alt-Rock', value: 'ALT_ROCK' },
  { name: 'Pop', value: 'POP' },
  { name: 'Alt-Pop', value: 'ALT_POP' },
  { name: 'Urban', value: 'URBAN' },
  { name: 'Rap', value: 'RAP' },
  { name: 'Hip Hop', value: 'HIPHOP' },
  { name: 'Reggae', value: 'REGGAE' },
  { name: 'Dance', value: 'DANCE' },
  { name: 'Alternative', value: 'ALTERNATIVE' },
  { name: 'Country', value: 'COUNTRY' },
  { name: 'Blues', value: 'BLUES' },
  { name: 'Jazz', value: 'JAZZ' },
  { name: 'Adult Contemporary', value: 'ADULT_CONTEMPORARY' },
  { name: 'New Age', value: 'NEW_AGE' },
  { name: 'Spiritual', value: 'SPIRITUAL' },
  { name: 'Electronic', value: 'ELECTRONIC' },
  { name: 'Folk', value: 'FOLK' },
  { name: 'Punk Rock', value: 'PUNK_ROCK' },
  { name: 'Heavy Metal', value: 'HEAVY_METAL' },
  { name: 'Soul', value: 'SOUL' },
  { name: 'EDM', value: 'EDM' },
  { name: 'Other', value: 'OTHER' }
];

export const useCollectibleParams = (limit = 6) => {
  const [query, setQuery] = useState({
    filter: {
      searchableStatus: { eq: 'PUBLISHED' },
      searchableCategory: { ne: 'VIP_PASS' }
    },
    limit,
    sort:
      process.env.REACT_APP_NAME === 'patrons'
        ? { direction: 'asc', field: 'title' }
        : { direction: 'desc', field: 'createdAt' }
  });

  const onUpdateCategory = (categories) => {
    if (categories.length > 0) {
      setQuery({
        filter: {
          ...query.filter,
          or: categories.map((c) => ({ searchableCategory: { eq: c } }))
        },
        sort: { direction: 'desc', field: 'createdAt' },
        nextToken: null
      });
    } else {
      setQuery({ ...query, nextToken: null, filter: { searchableStatus: { eq: 'PUBLISHED' } } });
    }
  };

  const onFilter = (title, categories, genres, eras, artists, sort, price_from, price_to) => {
    const filter = [];
    let arr = [];

    if (categories.length > 0) {
      arr = [];
      categories.map((category) => arr.push({ searchableCategory: { eq: category } }));
      filter.push({
        or: arr
      });
    }

    if (genres.length > 0) {
      arr = [];
      genres.map((genre) => arr.push({ searchableGenre: { matchPhrase: genre } }));
      filter.push({
        or: arr
      });
    }

    if (eras.length > 0) {
      arr = [];
      eras.map((e) => arr.push({ yearOfCreation: { gte: e.min, lte: e.max } }));
      filter.push({
        or: arr
      });
    }

    if (artists.length > 0) {
      arr = [];
      artists.map((artist) => arr.push({ searchableFeaturedArtist: { matchPhrase: artist } }));
      filter.push({
        or: arr
      });
    }

    if (price_from && price_to) {
      filter.push({
        and: [
          {
            searchableLowestPrice: {
              gte: price_from
            }
          },
          {
            searchableLowestPrice: {
              lte: price_to
            }
          }
        ]
      });
    }

    setQuery({
      filter: {
        searchableStatus: { eq: 'PUBLISHED' },
        searchableCategory: { ne: 'VIP_PASS' },
        ...(title && { title: { matchPhrasePrefix: title } }),
        ...(filter?.length > 0 && { and: filter })
      },
      limit: limit,
      sort: createSorter(sort),
      nextToken: null
    });
  };

  const onSortBy = (sortBy) => {
    const sort = createSorter(sortBy);
    setQuery({ ...query, nextToken: null, sort });
  };

  const onPaginate = (nextToken) => {
    setQuery({ ...query, nextToken });
  };

  const eraParser = (era) => {
    return eras[era];
  };

  return {
    query,
    onFilter,
    onUpdateCategory,
    onSortBy,
    onPaginate,
    eraParser
  };
};

export const useCollectibleParamsByOwner = (userID, limit, sortBy) => {
  const [query, setQuery] = useState({
    filter: {
      searchableStatus: { eq: 'PUBLISHED' }
    },
    limit: limit ? 6 : undefined,
    sort: createSorter(sortBy)
  });

  const onUpdateCategory = (categories) => {
    if (categories.length > 0) {
      const filter = {
        ...query.filter,
        or: categories.map((c) => ({ searchableCategory: { eq: c } }))
      };
      setQuery({ ...query, nextToken: null, filter });
    } else {
      const filter = {
        ...query.filter,
        or: undefined
      };
      setQuery({ ...query, filter, nextToken: null });
    }
  };

  const onChangeTitle = (title) => {
    if (title) {
      const filter = {
        ...query.filter,
        title: { matchPhrasePrefix: title }
      };
      setQuery({ ...query, nextToken: null, filter });
    } else {
      const filter = {
        ...query.filter,
        title: undefined
      };
      setQuery({ ...query, filter, nextToken: null });
    }
  };

  const onChangeUser = (userID) => {
    if (userID) {
      const filter = {
        ...query.filter,
        searchableFeaturedArtist: { matchPhrase: userID }
      };
      setQuery({ ...query, nextToken: null, filter });
    } else {
      const filter = {
        ...query.filter,
        searchableFeaturedArtist: undefined
      };
      setQuery({ ...query, filter, nextToken: null });
    }
  };

  const onUpdateGenre = (genre) => {
    if (genre) {
      const filter = {
        ...query.filter,
        searchableGenre: { eq: genre }
      };
      setQuery({ ...query, nextToken: null, filter });
    } else {
      const filter = {
        ...query.filter,
        searchableGenre: undefined
      };
      setQuery({ ...query, filter, nextToken: null });
    }
  };

  const onUpdateYearRange = (minYear, maxYear) => {
    if (minYear && maxYear) {
      setQuery({
        filter: {
          searchableStatus: { eq: 'PUBLISHED' },
          yearOfCreation: { gte: minYear, lte: maxYear }
        },
        sort: createSorter(sortBy),
        nextToken: null
      });
    } else {
      setQuery({ ...query, nextToken: null, filter: { searchableStatus: { eq: 'PUBLISHED' } } });
    }
  };

  const onUpdateMultipleCaseYearRange = (minYear, maxYear) => {
    if (minYear && maxYear) {
      const filter = {
        ...query.filter,
        yearOfCreation: { gte: minYear, lte: maxYear }
      };
      setQuery({ ...query, filter, nextToken: null });
    } else {
      const filter = {
        ...query.filter,
        yearOfCreation: undefined
      };
      setQuery({ ...query, nextToken: null, filter });
    }
  };

  const onUpdateFeaturedArtist = async (collectible = []) => {
    if (collectible.length > 0) {
      setQuery({
        filter: {
          searchableStatus: { eq: 'PUBLISHED' },
          or: collectible.map((fa) => ({ id: { eq: fa.collectibleID } }))
        },
        sort: createSorter(sortBy),
        nextToken: null
      });
    } else {
      setQuery({ ...query, nextToken: null, filter: { searchableStatus: { eq: 'PUBLISHED' } } });
    }
  };

  const onUpdateMultipleCaseFeaturedArtist = async (collectible = []) => {
    if (collectible.length > 0) {
      const filter = {
        ...query.filter,
        and: { or: collectible.map((fa) => ({ id: { eq: fa.collectibleID } })) }
      };
      setQuery({ ...query, filter, nextToken: null });
    } else {
      const filter = {
        ...query.filter,
        and: undefined
      };
      setQuery({ ...query, filter, nextToken: null });
    }
  };

  const onSortBy = (sortBy) => {
    const sort = createSorter(sortBy);
    setQuery({ ...query, nextToken: null, sort });
  };

  const onPaginate = (nextToken) => {
    setQuery({ ...query, nextToken });
  };

  const eraParser = (era) => {
    return eras[era];
  };

  useEffect(() => {
    setQuery({
      filter: {
        searchableStatus: { eq: 'PUBLISHED' }
      },
      limit: limit ? 6 : undefined,
      sort: createSorter(sortBy)
    });
  }, [userID]); // eslint-disable-line

  return {
    query,
    onUpdateCategory,
    onUpdateGenre,
    onUpdateYearRange,
    onUpdateFeaturedArtist,
    onSortBy,
    onPaginate,
    eraParser,
    onChangeUser,
    onChangeTitle,
    onUpdateMultipleCaseYearRange,
    onUpdateMultipleCaseFeaturedArtist
  };
};
