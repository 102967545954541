import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, Button, FollowButton, Grid, Link, Text, Modal } from '@components';
import { IoCloseOutline } from 'react-icons/io5';

import { useAuth } from '@libs/contexts/auth';
import Login from '@pages/auth/components/login';

function RelatedProfileCard({ user }) {
  const { t } = useTranslation();
  const { user: auth } = useAuth();
  const [authModal, setAuthModal] = useState(false);
  return (
    <Box className="related-profile-card">
      <Box>
        <Link to={`/${user?.username}`}>
          <Box className="related-profile-card__media">
            <Avatar user={user} size="lg" />
            <Box className="related-profile-card__media__information">
              <Box className="related-profile-card__media__information-wrapper">
                <Text className="related-profile-card__media__information__title">
                  {user?.displayName || user?.firstName + ' ' + user?.lastName}
                </Text>
                <Text className="related-profile-card__media__information__link">
                  fans.inc/{user?.username}
                </Text>
              </Box>
            </Box>
          </Box>
        </Link>
        <Grid className="related-profile-card__button">
          {auth ? (
            <FollowButton
              user={user}
              subscribeLabel={t('components.relatedProfileCard.subscribe')}
              unsubscribeLabel={t('components.relatedProfileCard.unsubscribe')}
            />
          ) : (
            <Button
              onClick={() => {
                setAuthModal(true);
              }}
            >
              {t('components.relatedProfileCard.subscribe')}
            </Button>
          )}
          <Link to={`/${user?.username}`}>
            <Button className="px-2" fullWidth whiteOutline>
              {t('components.relatedProfileCard.viewProfile')}
            </Button>
          </Link>
        </Grid>
        <Modal
          className="auth-modal"
          size="md"
          isOpen={authModal}
          onClose={() => setAuthModal(false)}
          verticalCenter
        >
          <Box className="auth-modal-container">
            <Box className="auth-modal-container-close">
              <IoCloseOutline onClick={() => setAuthModal(false)} size={32} color="gray" />
            </Box>
            <Box className="auth-modal-box">
              <Login onSuccess={() => setAuthModal(false)} />
            </Box>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
}

export default RelatedProfileCard;
