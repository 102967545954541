import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoCheckmarkSharp } from 'react-icons/io5';
import { useAuth } from '@libs/contexts/auth';
import useQuery from '@libs/utils/query';
import { useUser } from '@libs/hooks/user';
import { Box, Heading, Separator, Table, ToggleButton } from '@components';
import ReactTooltip from 'react-tooltip';

function SettingsNotifications() {
  const { t } = useTranslation();
  const query = useQuery();
  const notificationType = query.get('ntype');
  const { user, getCurrentUser } = useAuth();

  const { onUpdateShowNotifications, notificationLoading } = useUser();
  const [checkmark, setCheckmark] = useState(false);
  const handleChangeNotification = async (notification, field, key) => {
    setCheckmark(key);
    const payload = {
      notification,
      field
    };
    await onUpdateShowNotifications(payload);
    getCurrentUser();
  };

  useEffect(async () => {
    if (notificationType) {
      const payload = {
        notification: notificationType,
        field: 'email'
      };
      await onUpdateShowNotifications(payload);
      getCurrentUser();
    }
  }, [notificationType]);

  const tableBuying = [
    t('p.notificationSettings.table.buying.label'),
    t('p.notificationSettings.inApp'),
    t('p.notificationSettings.email')
  ];
  const buyingRows = [
    [
      t('p.notificationSettings.table.buying.row.outbid'),
      <ToggleButton
        key="checkmark1"
        disabled={notificationLoading && checkmark === 'checkmark1'}
        status={user?.notifications?.outbid?.inApp === 'TRUE'}
        onClick={() => handleChangeNotification('outbid', 'inApp', 'checkmark1')}
      />,
      <>
        <ToggleButton
          key="checkmark2"
          info={user?.notifications?.outbid?.inApp === 'TRUE' ? '' : 'Active in-App required'}
          disabled={
            user?.notifications?.outbid?.inApp === 'TRUE'
              ? notificationLoading && checkmark === 'checkmark2'
              : true
          }
          status={user?.notifications?.outbid?.email === 'TRUE'}
          onClick={() => handleChangeNotification('outbid', 'email', 'checkmark2')}
        />
      </>
    ],
    [
      t('p.notificationSettings.table.buying.row.bidResponses'),
      <ToggleButton
        key="checkmark3"
        disabled={notificationLoading && checkmark === 'checkmark3'}
        status={user?.notifications?.bidResponses?.inApp === 'TRUE'}
        onClick={() => handleChangeNotification('bidResponses', 'inApp', 'checkmark3')}
      />,
      <>
        <ToggleButton
          key="checkmark4"
          info={user?.notifications?.bidResponses?.inApp === 'TRUE' ? '' : 'Active in-App required'}
          disabled={
            user?.notifications?.bidResponses?.inApp === 'TRUE'
              ? notificationLoading && checkmark === 'checkmark4'
              : true
          }
          status={user?.notifications?.bidResponses?.email === 'TRUE'}
          onClick={() => handleChangeNotification('bidResponses', 'email', 'checkmark4')}
        />
      </>
    ],
    [
      t('p.notificationSettings.table.buying.row.offerResponses'),
      <ToggleButton
        key="checkmark5"
        disabled={notificationLoading && checkmark === 'checkmark5'}
        status={user?.notifications?.offerResponses?.inApp === 'TRUE'}
        onClick={() => handleChangeNotification('offerResponses', 'inApp', 'checkmark5')}
      />,
      <>
        <ToggleButton
          key="checkmark6"
          info={
            user?.notifications?.offerResponses?.inApp === 'TRUE' ? '' : 'Active in-App required'
          }
          disabled={
            user?.notifications?.offerResponses?.inApp === 'TRUE'
              ? notificationLoading && checkmark === 'checkmark6'
              : true
          }
          status={user?.notifications?.offerResponses?.email === 'TRUE'}
          onClick={() => handleChangeNotification('offerResponses', 'email', 'checkmark6')}
        />
      </>
    ],
    [
      t('p.notificationSettings.table.buying.row.successfulPurchase'),
      <ToggleButton
        key="checkmark7"
        disabled={notificationLoading && checkmark === 'checkmark7'}
        status={user?.notifications?.successfulPurchase?.inApp === 'TRUE'}
        onClick={() => handleChangeNotification('successfulPurchase', 'inApp', 'checkmark7')}
      />,
      <>
        <ToggleButton
          key="checkmark8"
          info={
            user?.notifications?.successfulPurchase?.inApp === 'TRUE'
              ? ''
              : 'Active in-App required'
          }
          disabled={
            user?.notifications?.successfulPurchase?.inApp === 'TRUE'
              ? notificationLoading && checkmark === 'checkmark8'
              : true
          }
          status={user?.notifications?.successfulPurchase?.email === 'TRUE'}
          onClick={() => handleChangeNotification('successfulPurchase', 'email', 'checkmark8')}
        />
      </>
    ]
  ];

  const tableSelling = [
    t('p.notificationSettings.table.selling.label'),
    t('p.notificationSettings.inApp'),
    t('p.notificationSettings.email')
  ];
  const sellingRows = [
    [
      t('p.notificationSettings.table.selling.row.collectibleSold'),
      <ToggleButton
        key="checkmark9"
        disabled={notificationLoading && checkmark === 'checkmark9'}
        status={user?.notifications?.collectibleSold?.inApp === 'TRUE'}
        onClick={() => handleChangeNotification('collectibleSold', 'inApp', 'checkmark9')}
      />,
      <>
        <ToggleButton
          key="checkmark10"
          info={
            user?.notifications?.collectibleSold?.inApp === 'TRUE' ? '' : 'Active in-App required'
          }
          disabled={
            user?.notifications?.collectibleSold?.inApp === 'TRUE'
              ? notificationLoading && checkmark === 'checkmark10'
              : true
          }
          status={user?.notifications?.collectibleSold?.email === 'TRUE'}
          onClick={() => handleChangeNotification('collectibleSold', 'email', 'checkmark10')}
        />
      </>
    ],
    [
      t('p.notificationSettings.table.selling.row.bidReceived'),
      <ToggleButton
        key="checkmark11"
        disabled={notificationLoading && checkmark === 'checkmark11'}
        status={user?.notifications?.bidReceived?.inApp === 'TRUE'}
        onClick={() => handleChangeNotification('bidReceived', 'inApp', 'checkmark11')}
      />,
      <>
        <ToggleButton
          key="checkmark12"
          info={user?.notifications?.bidReceived?.inApp === 'TRUE' ? '' : 'Active in-App required'}
          disabled={
            user?.notifications?.bidReceived?.inApp === 'TRUE'
              ? notificationLoading && checkmark === 'checkmark12'
              : true
          }
          status={user?.notifications?.bidReceived?.email === 'TRUE'}
          onClick={() => handleChangeNotification('bidReceived', 'email', 'checkmark12')}
        />
      </>
    ],
    [
      t('p.notificationSettings.table.selling.row.offerReceived'),
      <ToggleButton
        key="checkmark13"
        disabled={notificationLoading && checkmark === 'checkmark13'}
        status={user?.notifications?.offerReceived?.inApp === 'TRUE'}
        onClick={() => handleChangeNotification('offerReceived', 'inApp', 'checkmark13')}
      />,
      <>
        <ToggleButton
          key="checkmark14"
          info={
            user?.notifications?.offerReceived?.inApp === 'TRUE' ? '' : 'Active in-App required'
          }
          disabled={
            user?.notifications?.offerReceived?.inApp === 'TRUE'
              ? notificationLoading && checkmark === 'checkmark14'
              : true
          }
          status={user?.notifications?.offerReceived?.email === 'TRUE'}
          onClick={() => handleChangeNotification('offerReceived', 'email', 'checkmark14')}
        />
      </>
    ]
  ];

  const tableMinting = [
    t('p.notificationSettings.table.minting.label'),
    t('p.notificationSettings.inApp'),
    t('p.notificationSettings.email')
  ];
  const mintingRows = [
    [
      t('p.notificationSettings.table.minting.row.collectibleMinted'),
      <ToggleButton
        key="checkmark15"
        disabled={notificationLoading && checkmark === 'checkmark15'}
        status={user?.notifications?.collectibleMinted?.inApp === 'TRUE'}
        onClick={() => handleChangeNotification('collectibleMinted', 'inApp', 'checkmark15')}
      />,
      <>
        <ToggleButton
          key="checkmark16"
          info={
            user?.notifications?.collectibleMinted?.inApp === 'TRUE' ? '' : 'Active in-App required'
          }
          disabled={
            user?.notifications?.collectibleMinted?.inApp === 'TRUE'
              ? notificationLoading && checkmark === 'checkmark16'
              : true
          }
          status={user?.notifications?.collectibleMinted?.email === 'TRUE'}
          onClick={() => handleChangeNotification('collectibleMinted', 'email', 'checkmark16')}
        />
      </>
    ]
  ];

  const tablePrinting = [
    t('p.notificationSettings.table.printing.label'),
    t('p.notificationSettings.inApp'),
    t('p.notificationSettings.email')
  ];
  const printingRows = [
    [
      t('p.notificationSettings.table.printing.row.collectiblePrinted'),
      <IoCheckmarkSharp
        key="checkmark17"
        onClick={() => handleChangeNotification('collectiblePrinted', 'inApp')}
        className={`notification-toggle ${
          user?.notifications?.collectiblePrinted?.inApp === 'TRUE' && 'active'
        }`}
      />,
      <IoCheckmarkSharp
        key="checkmark18"
        onClick={() => handleChangeNotification('collectiblePrinted', 'email')}
        className={`notification-toggle ${
          user?.notifications?.collectiblePrinted?.email === 'TRUE' && 'active'
        }`}
      />
    ]
  ];

  const tableCreator = [
    t('p.notificationSettings.table.creatorUpdate.label'),
    t('p.notificationSettings.inApp'),
    t('p.notificationSettings.email')
  ];
  const creatorRows = [
    [
      t('p.notificationSettings.table.creatorUpdate.row.newCollectibles'),
      <ToggleButton
        key="checkmark19"
        disabled={notificationLoading && checkmark === 'checkmark19'}
        status={user?.notifications?.newCollectibles?.inApp === 'TRUE'}
        onClick={() => handleChangeNotification('newCollectibles', 'inApp', 'checkmark19')}
      />,
      <>
        <ToggleButton
          key="checkmark20"
          info={
            user?.notifications?.newCollectibles?.inApp === 'TRUE' ? '' : 'Active in-App required'
          }
          disabled={
            user?.notifications?.newCollectibles?.inApp === 'TRUE'
              ? notificationLoading && checkmark === 'checkmark20'
              : true
          }
          status={user?.notifications?.newCollectibles?.email === 'TRUE'}
          onClick={() => handleChangeNotification('newCollectibles', 'email', 'checkmark20')}
        />
      </>
    ],
    [
      t('p.notificationSettings.table.creatorUpdate.row.collectibleRelease'),
      <ToggleButton
        key="checkmark21"
        disabled={notificationLoading && checkmark === 'checkmark21'}
        status={user?.notifications?.collectibleRelease?.inApp === 'TRUE'}
        onClick={() => handleChangeNotification('collectibleRelease', 'inApp', 'checkmark21')}
      />,
      <>
        <ToggleButton
          key="checkmark22"
          info={
            user?.notifications?.collectibleRelease?.inApp === 'TRUE'
              ? ''
              : 'Active in-App required'
          }
          disabled={
            user?.notifications?.collectibleRelease?.inApp === 'TRUE'
              ? notificationLoading && checkmark === 'checkmark22'
              : true
          }
          status={user?.notifications?.collectibleRelease?.email === 'TRUE'}
          onClick={() => handleChangeNotification('collectibleRelease', 'email', 'checkmark22')}
        />
      </>
    ]
  ];

  const tablefans = [
    t('p.notificationSettings.table.fansincUpdate.label'),
    t('p.notificationSettings.inApp'),
    t('p.notificationSettings.email')
  ];
  const fansRows = [
    [
      t('p.notificationSettings.table.fansincUpdate.row.fansincNewsletter'),
      <ToggleButton
        key="checkmark23"
        disabled={notificationLoading && checkmark === 'checkmark23'}
        status={user?.notifications?.fansincNewsletter?.inApp === 'TRUE'}
        onClick={() => handleChangeNotification('fansincNewsletter', 'inApp', 'checkmark23')}
      />,
      <ToggleButton
        key="checkmark24"
        info={
          user?.notifications?.fansincNewsletter?.inApp === 'TRUE' ? '' : 'Active in-App required'
        }
        disabled={
          user?.notifications?.fansincNewsletter?.inApp === 'TRUE'
            ? notificationLoading && checkmark === 'checkmark24'
            : true
        }
        status={user?.notifications?.fansincNewsletter?.email === 'TRUE'}
        onClick={() => handleChangeNotification('fansincNewsletter', 'email', 'checkmark24')}
      />
    ]
  ];
  return (
    <>
      <Box className="notifications-settings pb-10 lg:pb-0">
        <Heading as="h3" className="notifications-settings__heading">
          {t('settings.notifications.heading')}
        </Heading>
        <Box className="notifications-settings__group">
          <Table cols={tableBuying} rows={buyingRows} border={false} />
        </Box>
        <Separator />
        <Box className="notifications-settings__group">
          <Table cols={tableSelling} rows={sellingRows} border={false} />
        </Box>
        <Separator />
        <Box className="notifications-settings__group">
          <Table cols={tableMinting} rows={mintingRows} border={false} />
        </Box>
        <Separator />
        <Box className="notifications-settings__group">
          <Table cols={tableCreator} rows={creatorRows} border={false} />
        </Box>
        <Separator />
        <Box className="notifications-settings__group">
          <Table cols={tablefans} rows={fansRows} border={false} />
        </Box>
      </Box>
      <Helmet>
        <title>{t('settings.notifications.pageTitle')}</title>
        <meta property="og:image" content="https://cdn.fans.inc/public/webapp/og-image.png" />
      </Helmet>
    </>
  );
}

export default SettingsNotifications;
