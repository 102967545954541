import { Container, Link, Logo } from '@components';

function PatronsHeader() {
  return (
    <header className="header-coming-soon-patrons">
      <Container>
        <div className="flex items-center justify-center">
          <Link to="/">
            <Logo width="152" height="35" />
          </Link>
        </div>
      </Container>
    </header>
  );
}

export default PatronsHeader;
