import cx from 'classnames';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IoEllipsisVerticalSharp } from 'react-icons/io5';
import { InfiniteScroll } from 'react-simple-infinite-scroll-patched';
import debounce from 'lodash.debounce';

import { Box, Button, Grid, Input, Price, Table, Text, UserLink, Countdays } from '@components';
import { LISTING_TYPES, TIER_TYPES } from '@libs/utils/exchange';
import EditionColumn from './edition-column';
import './style.css';

const ListingTableInfiniteScroll = ({
  data,
  user,
  onBuy,
  loading,
  title,
  sortable,
  searchable,
  className,
  onNextPage,
  hasMore,
  onSearch,
  show,
  ...props
}) => {
  const { t } = useTranslation();
  const listingBuyButton = {
    FIXED_PRICE: 'components.listingItem.buyNow',
    MAKE_AN_OFFER: 'components.listingItem.makeOffer',
    AUCTION: 'components.listingItem.makeBid',
    VIEW: 'components.listingItem.view'
  };

  const [highlightRow, setHighlightRow] = useState([]);

  const unitCols = [
    t('components.listingTable.edition'),
    t('components.listingTable.owner'),
    t('components.listingTable.lastPurchased'),
    t('components.listingTable.lastPrice'),
    t('components.listingTable.purchasePrice'),
    ''
  ];

  const scrollableTableRef = useRef(null);

  useEffect(() => {
    if (data.length > 0) {
      setHighlightRow(data.filter((x) => x.tierType === TIER_TYPES.DIGITAL_ORIGINAL));
    }
  }, [data]);

  const unitRows = data.map((unit) => {
    return [
      <EditionColumn key={`release-${unit.id}`} unit={unit} />,
      <UserLink
        user={unit.currentOwner}
        key="user-link"
        avatarSize="xs-40"
        exchange={unit}
        showAddress={false}
      />,
      unit.lastPurchasedAt ? (
        <Countdays time={unit.lastPurchasedAt} showHour showMinute showSecond />
      ) : (
        '-'
      ),
      <Price key={unit.id} amount={unit.previousPrice || unit.currentPrice} exchange={unit} />,
      [LISTING_TYPES.MAKE_AN_OFFER, LISTING_TYPES.NFT].includes(unit.currentListingType) ? (
        '-'
      ) : (
        <Price amount={unit.currentPrice} exchange={unit} />
      ),
      unit.currentListingType !== LISTING_TYPES.NFT ? (
        <Button
          size="sm"
          className="w-24"
          key="button"
          disabled={loading === unit.id}
          onClick={() => onBuy(unit)}
        >
          {loading === unit.id
            ? 'Loading...'
            : t(
                listingBuyButton[
                  unit.currentOwnerID === user?.id ? 'VIEW' : unit.currentListingType
                ]
              )}
        </Button>
      ) : null
    ];
  });

  return (
    <>
      {show ? (
        <Box className={cx('listing-table-infinite-scroll', className)} {...props}>
          <Box className="listing-table-infinite-scroll__scrollable" ref={scrollableTableRef}>
            <Grid className="listing-table-infinite-scroll__header grid-cols-12 md:grid-cols-6">
              <Box className="listing-table-infinite-scroll__header__name">
                {title && (
                  <Text className="listing-table-infinite-scroll__header__name__title text-default">
                    {title}
                  </Text>
                )}
                <Box className="listing-table-infinite-scroll__header__name__display">
                  {sortable && (
                    <Text className="listing-table-infinite-scroll__header__name__display__sort text-default text-gray-600">
                      <IoEllipsisVerticalSharp size="18px" /> {t('components.listingTable.sortBy')}
                    </Text>
                  )}
                </Box>
              </Box>
              <Box>
                {searchable && (
                  <Input
                    as="number"
                    className="search-form"
                    placeholder={t('components.listingTable.searchUnitNumber')}
                    onChange={debounce((e) => {
                      onSearch(e.target.value);
                    }, 1000)}
                  />
                )}
              </Box>
            </Grid>
            <InfiniteScroll
              throttle={1500}
              isLoading={loading}
              hasMore={hasMore}
              onLoadMore={onNextPage}
            >
              <Box className="listing-table-infinite-scroll__group">
                <Table
                  overflow={false}
                  cols={unitCols}
                  rows={unitRows}
                  highlightRow={highlightRow}
                />
                <Box className="listing-table-infinite-scroll__group__loading">
                  {!unitRows?.length && !loading && (
                    <Text className="listing-table-infinite-scroll__group__empty">
                      {t('components.listingTable.empty')}
                    </Text>
                  )}
                  {loading && (
                    <Text className="listing-table-infinite-scroll__group__empty">
                      {t('components.listingTable.loading')}
                    </Text>
                  )}
                </Box>
              </Box>
            </InfiniteScroll>
          </Box>
        </Box>
      ) : null}
    </>
  );
};

export default ListingTableInfiniteScroll;
