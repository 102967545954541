import FansincSellingHistory from './fansinc';
import PatronsSellingHistory from './patrons';

const PurchaseHistoryTable = ({ histories, loading, handleNextPage, nextPage }) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return (
      <PatronsSellingHistory
        histories={histories}
        loading={loading}
        handleNextPage={handleNextPage}
        nextPage={nextPage}
      />
    );
  } else {
    return (
      <FansincSellingHistory
        histories={histories}
        loading={loading}
        handleNextPage={handleNextPage}
        nextPage={nextPage}
      />
    );
  }
};

export default PurchaseHistoryTable;
