import cx from 'classnames';
import { Avatar, Link, Text } from '@components';

const CreatorCard = ({ user, className, children, ...props }) => {
  return (
    <div className={cx('creator-card', className)}>
      <Link to={`/${user?.username}`}>
        <Avatar user={user} size="full" className="creator-card__image" />
        <Text as="span" className="creator-card__title">
          {user?.displayName || user?.firstName + ' ' + user?.lastName}
        </Text>
        <Text className="creator-card__link">fans.inc/{user?.username}</Text>
      </Link>
    </div>
  );
};

export default CreatorCard;
