import React from 'react';
import { Box } from '@components';

import useCheckoutScript from './useCheckoutScript';
import CheckoutFrameForm from './checkout-frame-form';

import './style.css';

function CheckoutFrame({ exchange, exchangeIntent, submitForm, setSubmitForm, inputData }) {
  const isCheckoutScriptLoaded = useCheckoutScript();
  const { customPrice, email, firstName, lastName } = inputData;

  return (
    <Box className="checkout_frame">
      {isCheckoutScriptLoaded ? (
        <CheckoutFrameForm
          exchange={exchange}
          exchangeIntent={exchangeIntent}
          submitForm={submitForm}
          setSubmitForm={setSubmitForm}
          customPrice={customPrice ?? 0}
          email={email ?? ''}
          firstName={firstName ?? ''}
          lastName={lastName ?? ''}
        />
      ) : (
        <></>
      )}
    </Box>
  );
}

export default CheckoutFrame;
