/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const publishNotification = /* GraphQL */ `
  mutation PublishNotification($input: PublishNotificationInput!) {
    publishNotification(input: $input) {
      id
      owner
      type
      event
      read
      __typename
    }
  }
`;
export const unsubscribeCreator = /* GraphQL */ `
  mutation UnsubscribeCreator($input: UnsubscribeCreatorInput!) {
    unsubscribeCreator(input: $input)
  }
`;
export const facetedMarketplace = /* GraphQL */ `
  mutation FacetedMarketplace($input: FacetedMarketplaceInput!) {
    facetedMarketplace(input: $input)
  }
`;
export const viewCollectible = /* GraphQL */ `
  mutation ViewCollectible($input: ViewCollectibleInput!) {
    viewCollectible(input: $input)
  }
`;
export const createWaitlist = /* GraphQL */ `
  mutation CreateWaitlist($input: CreateWaitlistInput!) {
    createWaitlist(input: $input)
  }
`;
export const updateVerifiedWaitlist = /* GraphQL */ `
  mutation UpdateVerifiedWaitlist($input: UpdateVerifiedWaitlistInput!) {
    updateVerifiedWaitlist(input: $input)
  }
`;
export const createInbox = /* GraphQL */ `
  mutation CreateInbox($input: InboxObjectInput!) {
    createInbox(input: $input)
  }
`;
export const collectVisitCollectible = /* GraphQL */ `
  mutation CollectVisitCollectible($input: CollectVisitCollectibleInput!) {
    collectVisitCollectible(input: $input)
  }
`;
export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      id
      name
      legalName
      address
      country
      state
      city
      postcode
      phone
      website
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      members {
        nextToken
        startedAt
        __typename
      }
      currentOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        type
        bio
        style
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        totalSales
        totalSalesVolume
        totalPurchase
        totalCollectionValues
        totalLikeCollectible
        isHidden
        profileTemplate
        walletAddresses
        status
        createdAt
        updatedAt
        searchableVerified
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      name
      legalName
      address
      country
      state
      city
      postcode
      phone
      website
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      members {
        nextToken
        startedAt
        __typename
      }
      currentOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        type
        bio
        style
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        totalSales
        totalSalesVolume
        totalPurchase
        totalCollectionValues
        totalLikeCollectible
        isHidden
        profileTemplate
        walletAddresses
        status
        createdAt
        updatedAt
        searchableVerified
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount(
    $input: DeleteAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    deleteAccount(input: $input, condition: $condition) {
      id
      name
      legalName
      address
      country
      state
      city
      postcode
      phone
      website
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      members {
        nextToken
        startedAt
        __typename
      }
      currentOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        type
        bio
        style
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        totalSales
        totalSalesVolume
        totalPurchase
        totalCollectionValues
        totalLikeCollectible
        isHidden
        profileTemplate
        walletAddresses
        status
        createdAt
        updatedAt
        searchableVerified
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      __typename
    }
  }
`;
export const createFeaturedArtist = /* GraphQL */ `
  mutation CreateFeaturedArtist(
    $input: CreateFeaturedArtistInput!
    $condition: ModelFeaturedArtistConditionInput
  ) {
    createFeaturedArtist(input: $input, condition: $condition) {
      id
      artistName
      collectibleCount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateFeaturedArtist = /* GraphQL */ `
  mutation UpdateFeaturedArtist(
    $input: UpdateFeaturedArtistInput!
    $condition: ModelFeaturedArtistConditionInput
  ) {
    updateFeaturedArtist(input: $input, condition: $condition) {
      id
      artistName
      collectibleCount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteFeaturedArtist = /* GraphQL */ `
  mutation DeleteFeaturedArtist(
    $input: DeleteFeaturedArtistInput!
    $condition: ModelFeaturedArtistConditionInput
  ) {
    deleteFeaturedArtist(input: $input, condition: $condition) {
      id
      artistName
      collectibleCount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const acceptOffer = /* GraphQL */ `
  mutation AcceptOffer($input: AcceptOfferInput!) {
    acceptOffer(input: $input)
  }
`;
export const cancelOffer = /* GraphQL */ `
  mutation CancelOffer($input: CancelOfferInput!) {
    cancelOffer(input: $input)
  }
`;
export const customCreateCollectible = /* GraphQL */ `
  mutation CustomCreateCollectible($input: CustomCreateCollectibleInput!) {
    customCreateCollectible(input: $input)
  }
`;
export const updateCollectibleDraft = /* GraphQL */ `
  mutation UpdateCollectibleDraft($input: CustomUpdateCollectibleInput!) {
    updateCollectibleDraft(input: $input)
  }
`;
export const bulkUpsertCollectible = /* GraphQL */ `
  mutation BulkUpsertCollectible($input: BulkUpsertInput!) {
    bulkUpsertCollectible(input: $input)
  }
`;
export const customCreateAccount = /* GraphQL */ `
  mutation CustomCreateAccount($input: CustomCreateAccountInput!) {
    customCreateAccount(input: $input)
  }
`;
export const updateGalleryExperience = /* GraphQL */ `
  mutation UpdateGalleryExperience($input: UpdateGalleryExperienceInput!) {
    updateGalleryExperience(input: $input)
  }
`;
export const customUpdateAccount = /* GraphQL */ `
  mutation CustomUpdateAccount($input: CustomUpdateAccountInput!) {
    customUpdateAccount(input: $input)
  }
`;
export const customCreateUser = /* GraphQL */ `
  mutation CustomCreateUser($input: CustomCreateVerifiedProfileInput!) {
    customCreateUser(input: $input)
  }
`;
export const bulkUpsertCreator = /* GraphQL */ `
  mutation BulkUpsertCreator($input: BulkUpsertInput!) {
    bulkUpsertCreator(input: $input)
  }
`;
export const customUpdateVerifiedProfile = /* GraphQL */ `
  mutation CustomUpdateVerifiedProfile(
    $input: CustomUpdateVerifiedProfileInput!
  ) {
    customUpdateVerifiedProfile(input: $input)
  }
`;
export const createFollower = /* GraphQL */ `
  mutation CreateFollower($input: CreateFollowerInput!) {
    createFollower(input: $input)
  }
`;
export const deleteFollower = /* GraphQL */ `
  mutation DeleteFollower($input: DeleteFollowerInput!) {
    deleteFollower(input: $input)
  }
`;
export const fixedPricePurchase = /* GraphQL */ `
  mutation FixedPricePurchase($input: FixedPricePurchaseInput!) {
    fixedPricePurchase(input: $input)
  }
`;
export const fixedPricePurchaseV2 = /* GraphQL */ `
  mutation FixedPricePurchaseV2($input: FixedPricePurchaseInputV2!) {
    fixedPricePurchaseV2(input: $input)
  }
`;
export const generateNFTImageCard = /* GraphQL */ `
  mutation GenerateNFTImageCard($input: GenerateNFTImageCardInput!) {
    generateNFTImageCard(input: $input)
  }
`;
export const resizeImage = /* GraphQL */ `
  mutation ResizeImage($input: ResizeImageInput!) {
    resizeImage(input: $input) {
      key
      __typename
    }
  }
`;
export const removeAvatarImage = /* GraphQL */ `
  mutation RemoveAvatarImage($input: removeUserImageInput!) {
    removeAvatarImage(input: $input)
  }
`;
export const removeCoverImage = /* GraphQL */ `
  mutation RemoveCoverImage($input: removeUserImageInput!) {
    removeCoverImage(input: $input)
  }
`;
export const removeFanclubLogo = /* GraphQL */ `
  mutation RemoveFanclubLogo($input: removeUserImageInput!) {
    removeFanclubLogo(input: $input)
  }
`;
export const removeAvatarVideo = /* GraphQL */ `
  mutation RemoveAvatarVideo($input: removeUserImageInput!) {
    removeAvatarVideo(input: $input)
  }
`;
export const updateEmail = /* GraphQL */ `
  mutation UpdateEmail($input: UpdateEmailInput!) {
    updateEmail(input: $input)
  }
`;
export const updateUsername = /* GraphQL */ `
  mutation UpdateUsername($input: UpdateUsernameInput!) {
    updateUsername(input: $input)
  }
`;
export const verifyEmail = /* GraphQL */ `
  mutation VerifyEmail($input: VerifyEmailInput!) {
    verifyEmail(input: $input)
  }
`;
export const makeOffer = /* GraphQL */ `
  mutation MakeOffer($input: MakeOfferInput!) {
    makeOffer(input: $input)
  }
`;
export const makeOfferV2 = /* GraphQL */ `
  mutation MakeOfferV2($input: MakeOfferInputV2!) {
    makeOfferV2(input: $input)
  }
`;
export const placeBid = /* GraphQL */ `
  mutation PlaceBid($input: PlaceBidInput!) {
    placeBid(input: $input)
  }
`;
export const placeBidV2 = /* GraphQL */ `
  mutation PlaceBidV2($input: PlaceBidInputV2!) {
    placeBidV2(input: $input)
  }
`;
export const passwordVerifier = /* GraphQL */ `
  mutation PasswordVerifier($input: PasswordVerifierInput!) {
    passwordVerifier(input: $input)
  }
`;
export const publishCollectible = /* GraphQL */ `
  mutation PublishCollectible($input: PublishCollectibleInput!) {
    publishCollectible(input: $input)
  }
`;
export const requestRevisionCollectible = /* GraphQL */ `
  mutation RequestRevisionCollectible(
    $input: RequestRevisionCollectibleInput!
  ) {
    requestRevisionCollectible(input: $input)
  }
`;
export const inviteMember = /* GraphQL */ `
  mutation InviteMember($input: InviteMemberInput!) {
    inviteMember(input: $input)
  }
`;
export const acceptInvitation = /* GraphQL */ `
  mutation AcceptInvitation($input: AcceptInvitationInput!) {
    acceptInvitation(input: $input)
  }
`;
export const revokeMembership = /* GraphQL */ `
  mutation RevokeMembership($input: RevokeMembershipInput!) {
    revokeMembership(input: $input)
  }
`;
export const cancelMemberInvitation = /* GraphQL */ `
  mutation CancelMemberInvitation($input: CancelMemberInvitationInput!) {
    cancelMemberInvitation(input: $input)
  }
`;
export const removeData = /* GraphQL */ `
  mutation RemoveData($input: RemoveDataInput!) {
    removeData(input: $input)
  }
`;
export const customUpdateListing = /* GraphQL */ `
  mutation CustomUpdateListing($input: UpdateListingInput!) {
    customUpdateListing(input: $input)
  }
`;
export const updateReadNotification = /* GraphQL */ `
  mutation UpdateReadNotification($input: UpdateReadNotificationInput!) {
    updateReadNotification(input: $input)
  }
`;
export const mintNFT = /* GraphQL */ `
  mutation MintNFT($input: MintNFTInput!) {
    mintNFT(input: $input)
  }
`;
export const seederAutomation = /* GraphQL */ `
  mutation SeederAutomation {
    seederAutomation
  }
`;
export const switchOrganisation = /* GraphQL */ `
  mutation SwitchOrganisation($input: switchOrganisationInput!) {
    switchOrganisation(input: $input)
  }
`;
export const syncWalletAddressWithUser = /* GraphQL */ `
  mutation SyncWalletAddressWithUser($input: SyncWalletAddressWithUserInput!) {
    syncWalletAddressWithUser(input: $input)
  }
`;
export const updateShowNotifications = /* GraphQL */ `
  mutation UpdateShowNotifications($input: UpdateShowNotificationsInput!) {
    updateShowNotifications(input: $input)
  }
`;
export const requestWithdrawal = /* GraphQL */ `
  mutation RequestWithdrawal($input: RequestWithdrawalInput!) {
    requestWithdrawal(input: $input)
  }
`;
export const acceptWithdrawal = /* GraphQL */ `
  mutation AcceptWithdrawal($input: AcceptWithdrawalInput!) {
    acceptWithdrawal(input: $input)
  }
`;
export const verifyWithdrawal = /* GraphQL */ `
  mutation VerifyWithdrawal($input: VerifyWithdrawalInput!) {
    verifyWithdrawal(input: $input)
  }
`;
export const requestWithdrawalCode = /* GraphQL */ `
  mutation RequestWithdrawalCode($input: AcceptWithdrawalInput!) {
    requestWithdrawalCode(input: $input)
  }
`;
export const rejectWithdrawal = /* GraphQL */ `
  mutation RejectWithdrawal($input: RejectWithdrawalInput!) {
    rejectWithdrawal(input: $input)
  }
`;
export const purchasePrintedCollectible = /* GraphQL */ `
  mutation PurchasePrintedCollectible(
    $input: PurchasePrintedCollectibleInput!
  ) {
    purchasePrintedCollectible(input: $input)
  }
`;
export const updatePrintMetadata = /* GraphQL */ `
  mutation UpdatePrintMetadata($input: UpdatePrintMetadataInput!) {
    updatePrintMetadata(input: $input)
  }
`;
export const cancelPrintedCollectible = /* GraphQL */ `
  mutation CancelPrintedCollectible($input: CancelPrintedCollectibleInput!) {
    cancelPrintedCollectible(input: $input)
  }
`;
export const updateAccountUser = /* GraphQL */ `
  mutation UpdateAccountUser($input: UpdateAccountUserInput!) {
    updateAccountUser(input: $input)
  }
`;
export const likeCollectible = /* GraphQL */ `
  mutation LikeCollectible($input: LikeCollectibleInput!) {
    likeCollectible(input: $input)
  }
`;
export const discourseCreatePost = /* GraphQL */ `
  mutation DiscourseCreatePost($input: DiscoursePostInput!) {
    discourseCreatePost(input: $input)
  }
`;
export const collectibleCreateComment = /* GraphQL */ `
  mutation CollectibleCreateComment($input: DiscoursePostInput!) {
    collectibleCreateComment(input: $input)
  }
`;
export const discourseReplyPost = /* GraphQL */ `
  mutation DiscourseReplyPost($input: DiscoursePostInput!) {
    discourseReplyPost(input: $input)
  }
`;
export const collectibleReplyComment = /* GraphQL */ `
  mutation CollectibleReplyComment($input: DiscoursePostInput!) {
    collectibleReplyComment(input: $input)
  }
`;
export const discourseLikePost = /* GraphQL */ `
  mutation DiscourseLikePost($input: DiscourseActionInput!) {
    discourseLikePost(input: $input)
  }
`;
export const discourseUnlikePost = /* GraphQL */ `
  mutation DiscourseUnlikePost($input: DiscourseActionInput!) {
    discourseUnlikePost(input: $input)
  }
`;
export const collectibleLikeComment = /* GraphQL */ `
  mutation CollectibleLikeComment($input: DiscourseActionInput!) {
    collectibleLikeComment(input: $input)
  }
`;
export const collectibleUnlikeComment = /* GraphQL */ `
  mutation CollectibleUnlikeComment($input: DiscourseActionInput!) {
    collectibleUnlikeComment(input: $input)
  }
`;
export const discourseCreateChannel = /* GraphQL */ `
  mutation DiscourseCreateChannel($input: DiscourseChannelInput!) {
    discourseCreateChannel(input: $input)
  }
`;
export const discourseUploadImage = /* GraphQL */ `
  mutation DiscourseUploadImage($input: DiscourseUploadInput!) {
    discourseUploadImage(input: $input)
  }
`;
export const discourseDelete = /* GraphQL */ `
  mutation DiscourseDelete($input: DiscourseDeleteInput!) {
    discourseDelete(input: $input)
  }
`;
export const collectibleDeleteComment = /* GraphQL */ `
  mutation CollectibleDeleteComment($input: DiscourseDeleteInput!) {
    collectibleDeleteComment(input: $input)
  }
`;
export const discourseUpdate = /* GraphQL */ `
  mutation DiscourseUpdate($input: DiscourseUpdateInput!) {
    discourseUpdate(input: $input)
  }
`;
export const discourseFeaturedPost = /* GraphQL */ `
  mutation DiscourseFeaturedPost($input: DiscourseFeaturedInput!) {
    discourseFeaturedPost(input: $input)
  }
`;
export const discourseReportPost = /* GraphQL */ `
  mutation DiscourseReportPost($input: DiscourseReportInput!) {
    discourseReportPost(input: $input)
  }
`;
export const claimCollectible = /* GraphQL */ `
  mutation ClaimCollectible($input: ClaimCollectibleInput!) {
    claimCollectible(input: $input)
  }
`;
export const createCollections = /* GraphQL */ `
  mutation CreateCollections($input: CreateCollectionsInput!) {
    createCollections(input: $input)
  }
`;
export const updateCollections = /* GraphQL */ `
  mutation UpdateCollections($input: UpdateCollectionsInput!) {
    updateCollections(input: $input)
  }
`;
export const deleteCollections = /* GraphQL */ `
  mutation DeleteCollections($input: UpdateCollectionsInput!) {
    deleteCollections(input: $input)
  }
`;
export const updateStatusWaitlist = /* GraphQL */ `
  mutation UpdateStatusWaitlist($input: UpdateStatusWaitlistInput!) {
    updateStatusWaitlist(input: $input)
  }
`;
export const deleteInbox = /* GraphQL */ `
  mutation DeleteInbox($input: DeleteInboxInput!) {
    deleteInbox(input: $input)
  }
`;
export const readInbox = /* GraphQL */ `
  mutation ReadInbox($input: DeleteInboxInput!) {
    readInbox(input: $input)
  }
`;
export const readAllInbox = /* GraphQL */ `
  mutation ReadAllInbox($input: DeleteInboxInput!) {
    readAllInbox(input: $input)
  }
`;
export const createUserMedia = /* GraphQL */ `
  mutation CreateUserMedia(
    $input: CreateUserMediaInput!
    $condition: ModelUserMediaConditionInput
  ) {
    createUserMedia(input: $input, condition: $condition) {
      id
      avatarUrl
      avatarVideoUrl
      coverUrl
      fanclubLogoUrl
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateUserMedia = /* GraphQL */ `
  mutation UpdateUserMedia(
    $input: UpdateUserMediaInput!
    $condition: ModelUserMediaConditionInput
  ) {
    updateUserMedia(input: $input, condition: $condition) {
      id
      avatarUrl
      avatarVideoUrl
      coverUrl
      fanclubLogoUrl
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteUserMedia = /* GraphQL */ `
  mutation DeleteUserMedia(
    $input: DeleteUserMediaInput!
    $condition: ModelUserMediaConditionInput
  ) {
    deleteUserMedia(input: $input, condition: $condition) {
      id
      avatarUrl
      avatarVideoUrl
      coverUrl
      fanclubLogoUrl
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
