import {
  UserLink,
  Link,
  SerialItem,
  SkeletonLoading,
  Price,
  Text,
  Countdays,
  Button
} from '@components';
import { Trans } from 'react-i18next';
import { createExchangeURL } from '@libs/utils/release';
import { useState, useEffect } from 'react';
import { getHighestBid } from '@graphql/queries';
import { EXCHANGE_TYPES } from '@libs/utils/exchange';
import gql from '@libs/utils/gql';
import { ReactComponent as CornerLeftArrow } from '@assets/svg/patrons/corner-left-arrow.svg';

export const getFromRow = (exchange, tableType) => {
  switch (tableType) {
    case 'saleHistory':
      return (
        <UserLink
          user={exchange?.previousExchange?.currentOwner}
          key="user-link-from"
          exchange={exchange}
          avatarSize="xs-40"
          showAddress
          showTooltip={false}
          showName={true}
        />
      );
    case 'offerReceived':
      return (
        <UserLink
          user={exchange?.patron}
          key="user-link-from"
          exchange={exchange}
          avatarSize="xs-40"
          showAddress
          showName={false}
        />
      );
    default:
      break;
  }

  return '';
};

export const getToRow = (exchange, tableType) => {
  switch (tableType) {
    case 'saleHistory':
      return (
        <UserLink
          user={exchange?.currentOwner}
          key="user-link-to"
          exchange={exchange}
          showTooltip={false}
          avatarSize="xs-40"
          showAddress
        />
      );
    case 'offerMade':
      return (
        <UserLink
          user={exchange?.currentOwner}
          key="user-link-from"
          exchange={exchange}
          avatarSize="xs-40"
          showAddress
          showName={false}
        />
      );
    default:
      break;
  }

  return '';
};

const editionParser = (serialIndex) => {
  return serialIndex > 1 ? (
    <Trans i18nKey="components.exchangesTable.numberedEdition" />
  ) : (
    <Trans i18nKey="components.exchangesTable.digitalOriginal" />
  );
};

const tierMapper = {
  NUMBERED_EDITION: 'Limited Edition',
  DIGITAL_ORIGINAL: 'Digital Original'
};

export const getCollectibleNameRow = (exchange) => {
  return (
    <Link to={createExchangeURL(exchange)}>
      <div className="flex items-center justify-between">
        <div className="action-countdays">
          <Text className="pr-3">
            <Trans i18nKey="components.exchangesTable.collectible">
              {{ collectibleName: exchange?.collectible?.title }}
              {{ tierType: tierMapper[exchange?.tierType] }}
              {{ serialIndex: exchange?.serialIndex }}
            </Trans>
          </Text>
          <Countdays
            className="text-gray-400"
            time={exchange?.createdAt}
            showHour
            showMinute
            showSecond
          />
        </div>
      </div>
    </Link>
  );
};

export const getActionsRow = ({
  exchange,
  actions,
  exchangeIndex,
  isLoadingActions,
  setIsLoadingActions
}) => {
  return (
    <div className="flex flex-col justify-center items-start gap-y-2">
      {actions.map((action, actionIndex) => (
        <button
          className={`inline-flex justify-center items-center text-lg  font-Satoshi font-medium ${action?.className}`}
          key="button"
          onClick={async () => {
            setIsLoadingActions((prev) => ({
              ...prev,
              [`action-${exchangeIndex}-${actionIndex}`]: true
            }));
            await action.onClick(exchange);
            setIsLoadingActions((prev) => ({
              ...prev,
              [`action-${exchangeIndex}-${actionIndex}`]: false
            }));
          }}
          loading={isLoadingActions[`action-${exchangeIndex}-${actionIndex}`]}
          whiteOutline={action.whiteOutline}
        >
          {action.icon}
          <span className="ml-1">{action.name}</span>
        </button>
      ))}
    </div>
  );
};
