import { useState, useEffect } from 'react';

import { LISTING_TYPES } from '@libs/utils/exchange';

export const useWalletAddress = ({ exchange, address }) => {
  const [walletAddress, setWalletAddress] = useState(null);

  useEffect(() => {
    if (address) {
      setWalletAddress(address);
    } else if (exchange) {
      const { nftOwnerAddress, listingType } = exchange;
      if (listingType === LISTING_TYPES.NFT) {
        setWalletAddress(nftOwnerAddress);
      }
    }
  }, [exchange, address]);

  return walletAddress;
};
