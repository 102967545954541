import { useState, useEffect } from 'react';
import { Box } from '@components';
import WithdrawalAmount from './modal/amount';
import WithdrawalVerify from './modal/verify';
import WithdrawalSuccess from './modal/success';

const WithdrawalModal = ({ balance, visible, onCancel, refetch }) => {
  const [modal, setModal] = useState({
    amount: false,
    paypal: false,
    cryptocurrency: false,
    verify: false,
    success: false,
    withdrawalID: ''
  });

  const [form, setForm] = useState({
    amount: '',
    payoutMethod: '',
    target: ''
  });

  const setModalCancel = (type) => {
    onCancel();
    setForm({
      amount: '',
      payoutMethod: '',
      target: ''
    });
    setModal({
      ...modal,
      [type]: false
    });
  };

  const submitModal = (type, withdrawID) => {
    if (type === 'amount') {
      onCancel();
      let payoutMethod = 'paypal';

      if (form.payoutMethod === 'CRYPTOCURRENCY') {
        payoutMethod = 'cryptocurrency';
      }

      setModal({
        ...modal,
        withdrawalID: withdrawID,
        verify: true,
        [type]: false
      });
    }
    if (type === 'verify') {
      onCancel();
      setModal({
        ...modal,
        success: true,
        [type]: false
      });
    }
  };

  useEffect(() => {
    if (visible === true)
      setModal({
        ...modal,
        amount: true
      });
    // eslint-disable-next-line
  }, [visible]);

  return (
    <Box>
      <WithdrawalAmount
        isOpen={modal.amount}
        form={form}
        setForm={setForm}
        onCancel={() => setModalCancel('amount')}
        action={submitModal}
        balance={balance}
      />
      <WithdrawalVerify
        isOpen={modal.verify}
        form={form}
        setForm={setForm}
        onCancel={() => setModalCancel('verify')}
        action={() => submitModal('verify')}
        withdrawalID={modal.withdrawalID}
      />
      <WithdrawalSuccess
        isOpen={modal.success}
        onCancel={() => {
          refetch();
          setModalCancel('success');
        }}
      />
    </Box>
  );
};

export default WithdrawalModal;
