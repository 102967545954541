import { toast } from 'react-toastify';

const useToast = () => {
  return (message, severity = 'default', autoClose = null) => {
    const availableSeverity = ['success', 'error', 'warn', 'info', 'default'];
    if (!availableSeverity.includes(severity)) {
      throw Error('Toast severity is not supported.');
    }

    const notify = severity === 'default' ? toast : toast[severity];

    notify(message, {
      position:
        process.env.REACT_APP_NAME === 'patrons'
          ? toast.POSITION.TOP_RIGHT
          : toast.POSITION.TOP_CENTER,
      theme: 'colored',
      className: '',
      autoClose: autoClose
    });
  };
};

export default useToast;
