import {
  Box,
  Input,
  Separator,
  Table,
  Countdays,
  Link,
  Avatar,
  SkeletonLoading,
  Text
} from '@components';
import React, { useEffect, useState } from 'react';
import { ReactComponent as Search } from '@assets/svg/search-artists.svg';
import PatronsCollectibleArtist from './PatronsCollectibleArtist';
import { ReactComponent as ArrowRightIcon } from '@assets/svg/arrow-right-black.svg';
import { useSecondaryMarketplace } from '@libs/hooks/exchange';
import { InfiniteScroll } from 'react-simple-infinite-scroll-patched';
import { LISTING_TYPES } from '@libs/utils/exchange';
import { useLocale } from '@libs/contexts/locale';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as OpenSeaIcon } from '@assets/simple-icons_opensea.svg';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';
import SecondaryMarketplaceIcon from '@assets/img/patrons/secondary-marketplace.png';
import { ReactComponent as ArrowUpRight } from '@assets/svg/arrow-up-right.svg';
import { ReactComponent as NotFoundIcon } from '@assets/svg/patrons/not-found.svg';

export default function PatronsCollectibleMarketplace({ collectible }) {
  const { t } = useTranslation();
  const { getCurrencyFormatter } = useLocale();
  const currencyFormatter = getCurrencyFormatter();
  const [openFilter, setOpenFilter] = useState(false);
  const [isSearch, setIsSearch] = useState();
  const {
    data: secondaryExchanges,
    loading: isLoading2ndExchange,
    onSearchSerialIndex,
    hasMore,
    total,
    onNextPage: onLoadMore
  } = useSecondaryMarketplace({ collectibleID: collectible?.id, authorID: collectible?.userID });
  useEffect(() => {
    if (secondaryExchanges?.length > 0) {
      setIsSearch(true);
    }
  }, [secondaryExchanges]);
  const exchangeCols = [['Edition'], ['Owners'], ['Purchased'], ['Price'], []];
  useEffect(() => {
    if (total > 10) {
      setOpenFilter(true);
    }
  }, [total]);
  const exchangeRows = secondaryExchanges?.map((item, index) => [
    [
      <Box className="font-bold flex items-center gap-2" key={index}>
        <Link
          to={`/${item?.creator?.username}/${item?.slug}/${item?.serialIndex}`}
        >{`#${item?.serialIndex}`}</Link>
        {item.currentListingType === LISTING_TYPES.NFT && (
          <OpenSeaIcon
            data-tip
            data-for={`minimumTooltip${item?.id}`}
            key={item.id}
            width={16}
            height={16}
          />
        )}
        <ReactTooltip
          className="listing-table__opensea-tooltip"
          id={`minimumTooltip${item?.id}`}
          place="right"
          type="dark"
        >
          <Box className="capitalize">{t('components.listingTable.minted')}</Box>
        </ReactTooltip>
      </Box>
    ],
    [
      <Link to={`/${item?.currentOwner.username}`} key={index}>
        <PatronsCollectibleArtist
          owner={item?.currentOwner}
          title={
            item?.currentOwner.displayName ||
            item?.currentOwner?.firstName + ' ' + item?.currentOwner.lastName
          }
        />
      </Link>
    ],
    [
      <Box key={index}>
        {item.lastPurchasedAt ? (
          <Countdays time={item.lastPurchasedAt} showHour showMinute showSecond />
        ) : (
          '-'
        )}
      </Box>
    ],
    [
      <Box key={index}>
        <Box className="text-base">
          {item?.listingType === 'MAKE_AN_OFFER'
            ? currencyFormatter.format(Number(item?.previousPrice))
            : currencyFormatter.format(Number(item?.currentPrice))}
        </Box>
      </Box>
    ],
    [
      <Box key={index}>
        <Box className="text-base flex items-center">
          {item?.listingType === 'MAKE_AN_OFFER' ? (
            <Link
              to={`/${item?.creator?.username}/${item?.slug}/checkout/${item?.id}`}
              className="inline-flex justify-center items-center"
            >
              Make Offer <ArrowRightIcon className="ml-2" />
            </Link>
          ) : (
            <Link
              to={`/${item?.creator?.username}/${item?.slug}/checkout/${item?.id}`}
              className="inline-flex justify-center items-center"
            >
              Buy Now <ArrowRightIcon className="ml-2" />
            </Link>
          )}
        </Box>
      </Box>
    ]
  ]);

  const loadingRows = [...Array(3)].map((item, index) => [
    [<SkeletonLoading key={index} className="h-16 w-8" />],
    [<SkeletonLoading key={index} className="h-16 w-56" />],
    [<SkeletonLoading key={index} className="h-16 w-16" />],
    [<SkeletonLoading key={index} className="h-16 w-16" />],
    [<SkeletonLoading key={index} className="h-16 w-16" />]
  ]);

  const ResponsiveComponent = ({ data }) => {
    return (
      <Link to={`/${data?.creator?.username}/${data?.slug}/${data?.serialIndex}`}>
        <Box className="collectible__tab__marketplace__responsive">
          <Box className="flex items-center">
            <Box className="collectible__tab__marketplace__responsive__number">
              #{data?.serialIndex}
            </Box>
            <Box className="collectible__tab__marketplace__responsive__avatar">
              <Box className="collectible__tab__marketplace__responsive__avatar__image">
                <Avatar size="xs" user={data?.currentOwner} />
                {data.currentListingType === LISTING_TYPES.NFT && (
                  <Box className="collectible__tab__marketplace__responsive__avatar__image__opensea">
                    <OpenSeaIcon
                      data-tip
                      data-for={`minimumTooltip${data?.id}`}
                      key={data.id}
                      width={14}
                      height={14}
                    />
                    <ReactTooltip
                      className="listing-table__opensea-tooltip"
                      id={`minimumTooltip${data?.id}`}
                      place="right"
                      type="dark"
                    >
                      <Box className="capitalize">{t('components.listingTable.minted')}</Box>
                    </ReactTooltip>
                  </Box>
                )}
              </Box>
              <Box className="collectible__tab__marketplace__responsive__avatar__text">
                <Box className="collectible__tab__marketplace__responsive__avatar__text__time">
                  {data.lastPurchasedAt && (
                    <>
                      Purchase
                      <Countdays time={data.lastPurchasedAt} showHour showMinute showSecond />
                      By
                    </>
                  )}
                </Box>
                <Box className="collectible__tab__marketplace__responsive__avatar__text__name">
                  {data?.currentOwner?.firstName + ' ' + data?.currentOwner?.lastName}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="collectible__tab__marketplace__responsive__price">
            <Box className="collectible__tab__marketplace__responsive__price__label">
              {t('p.pages.collectible.price')}
            </Box>
            <Box className="collectible__tab__marketplace__responsive__price__value">
              {data?.listingType === 'MAKE_AN_OFFER'
                ? currencyFormatter.format(Number(data?.previousPrice))
                : currencyFormatter.format(Number(data?.currentPrice))}
            </Box>
          </Box>
          <Box className="collectible__tab__marketplace__responsive__icon">
            <ArrowUpRight />
          </Box>
        </Box>
      </Link>
    );
  };

  return (
    <div>
      <Box className="collectible__tab__marketplace">
        {(secondaryExchanges.length !== 0 || isSearch) && openFilter && (
          <>
            <Box className="collectible__tab__marketplace__top">
              <Box className="collectible__tab__marketplace__top__search">
                <Search />
                <Input
                  as="number"
                  className="search-form"
                  placeholder={t('components.listingTable.searchUnitNumber')}
                  onChange={debounce((e) => {
                    onSearchSerialIndex(e.target.value);
                  }, 1000)}
                />
              </Box>
            </Box>
            <Separator className="mt-6" />
          </>
        )}
        <Box className="collectible__tab__marketplace__table">
          {secondaryExchanges.length !== 0 ? (
            <>
              <InfiniteScroll
                throttle={1500}
                isLoading={isLoading2ndExchange}
                hasMore={hasMore}
                onLoadMore={onLoadMore}
              >
                <Table
                  className="collectible__tab__marketplace__table__data"
                  cols={exchangeCols}
                  rows={exchangeRows}
                />
                <Box className="collectible__tab__marketplace__table__responsive">
                  {secondaryExchanges?.map((x, i) => (
                    <ResponsiveComponent key={i} data={x} />
                  ))}
                </Box>
                {isLoading2ndExchange && (
                  <Box className="listing-table-infinite-scroll__group__loading">
                    <Text className="listing-table-infinite-scroll__group__empty">
                      {t('components.listingTable.loading')}
                    </Text>
                  </Box>
                )}
              </InfiniteScroll>
            </>
          ) : (
            <>
              {isLoading2ndExchange ? (
                <Table cols={exchangeCols} rows={loadingRows} />
              ) : (
                <>
                  {isSearch ? (
                    <Box className="collectible__tab__marketplace__empty-search">
                      <Box className="collectible__tab__marketplace__empty-search__wrapper">
                        <Box className="collectible__tab__marketplace__empty-search__wrapper__icon">
                          <NotFoundIcon />
                        </Box>
                        <Box className="collectible__tab__marketplace__empty-search__wrapper__title">
                          {t('p.pages.collectible.notFound.title')}
                        </Box>
                        <Box className="collectible__tab__marketplace__empty-search__wrapper__description">
                          {t('p.pages.collectible.notFound.description')}
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <Box className="collectible__tab__marketplace__empty">
                      <Box className="collectible__tab__marketplace__empty__wrapper">
                        <Box className="collectible__tab__marketplace__empty__wrapper__icon">
                          <img src={SecondaryMarketplaceIcon} alt="" />
                        </Box>
                        <Box className="collectible__tab__marketplace__empty__wrapper__title">
                          {t('p.pages.collectible.secondaryMarketplace.title')}
                        </Box>
                        <Box className="collectible__tab__marketplace__empty__wrapper__description">
                          {t('p.pages.collectible.secondaryMarketplace.description')}
                        </Box>
                      </Box>
                    </Box>
                  )}
                </>
              )}
            </>
          )}
        </Box>
      </Box>
    </div>
  );
}
