import gql from '@libs/utils/gql';
import { acceptOffer, cancelOffer } from '@graphql/mutations';
import { exchangeByID } from '@libs/custom-queries/exchange';

const pollIsSold = async (exchangeID, interval = 500, timeout = 20000) => {
  let exchange = null;
  let totalPollingTime = 0;
  let hasError = false;

  const getExchangeData = async (id) => {
    try {
      const { data: res } = await gql(exchangeByID, { id });
      exchange = res?.getExchange;
      totalPollingTime = totalPollingTime + interval;
    } catch (error) {
      console.error(error);
    }
  };

  const runPolling = new Promise((resolve, reject) => {
    let intervalID = null;
    if (exchangeID) {
      intervalID = setInterval(() => {
        if (exchange?.isSold === 'TRUE') {
          resolve();
          clearInterval(intervalID);
        } else {
          if (totalPollingTime > timeout) {
            reject('Timeout');
            clearInterval(intervalID);
          }
          getExchangeData(exchangeID);
        }
      }, interval);
    } else {
      reject('Exchange ID not found');
    }
  });

  await runPolling;

  return { exchange, hasError };
};

export const acceptOfferGql = async (exchangeID, paymentProvider) => {
  const res = await gql(
    acceptOffer,
    {
      input: { exchangeID }
    },
    {
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    }
  );
  if (paymentProvider === 'CHECKOUT') {
    await pollIsSold(exchangeID);
  }
  return res;
};

export const cancelOfferGql = async (exchangeID) => {
  const res = await gql(
    cancelOffer,
    {
      input: { exchangeID }
    },
    {
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    }
  );
  await pollIsSold(exchangeID);
  return res;
};
