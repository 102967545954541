import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useUser } from '@libs/hooks/user';
import { useAuth } from '@libs/contexts/auth';
import { useEffect, useRef, useState } from 'react';
import { FaCheck, FaClock, FaTimes } from 'react-icons/fa';
import { FiEdit } from 'react-icons/fi';

import {
  Box,
  Grid,
  FormField,
  Text,
  Input,
  Col,
  PasswordVerifier,
  Modal,
  Button,
  SkeletonLoading
} from '@components';

function SettingsAccount() {
  const { t } = useTranslation();
  const {
    register: registerUsername,
    handleSubmit: handleSubmitUsername,
    formState: { errors: errorsUsername },
    setValue: setValueUsername,
    clearErrors: clearErrorsUsername
  } = useForm({
    mode: 'onChange'
  });

  const { onChange, ...rest } = registerUsername('username', {
    required: { value: true, message: t('editProfile.minCharacterValidation') },
    maxLength: { value: 100, message: t('editProfile.maxCharacterValidation') },
    pattern: {
      value: /^[a-zA-Z0-9 .]+$/i,
      message: t('editProfile.userNamePattern')
    }
  });

  const {
    watch,
    register: registerEmail,
    handleSubmit: handleSubmitEmail,
    formState: { errors: errorsEmail },
    reset: resetEmail
  } = useForm();

  const {
    register: registerVerifyEmail,
    handleSubmit: handleVerifyEmail,
    reset: resetVerifyEmail
  } = useForm();

  const {
    register: registerPassword,
    handleSubmit: handleSubmitPassword,
    formState: { errors: errorsPassword },
    reset: resetPassword,
    watch: watchPassword
  } = useForm({
    mode: 'onChange'
  });

  const onSubmitPassword = async (props) => {
    try {
      const { current: oldPassword, new: newPassword } = props;
      setPasswordLoading(true);
      await changePass({ oldPassword, newPassword });
      setPasswordLoading(false);
      resetPassword({
        confirm: '',
        current: '',
        new: ''
      });
    } catch (e) {
      console.error('error submit new password', e);
    }
  };

  const passwordRef = useRef({});
  passwordRef.current = watchPassword('new', '');

  const { user, loading, getCurrentUser, refreshSession } = useAuth();
  const {
    onCheckUsername,
    availabilityLoading,
    usernameAvailability,
    usernameLoading,
    emailLoading,
    onUpdateUsername,
    onUpdateEmail,
    onVerifyEmail,
    changePass
  } = useUser();

  const [modalEmail, setModalEmail] = useState(false);
  const [modalUndo, setModalUndo] = useState(false);
  const [verifyStatus, setVerifyStatus] = useState(false);
  const [usernameEdit, setUsernameEdit] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [emailEdit, setEmailEdit] = useState(false);
  const [username, setUsername] = useState();

  useEffect(() => {
    setUsername(user?.username);
  }, [user?.username]); // eslint-disable-line

  const newEmail = watch('email');

  const handleUsernameEditOpen = () => {
    setValueUsername('username', user?.username);
    clearErrorsUsername('username');
    setUsername(user?.username);
    setUsernameEdit(true);
  };

  const handleUsernameEditClose = () => {
    setValueUsername('username', user?.username);
    clearErrorsUsername('username');
    setUsername(user?.username);
    setUsernameEdit(false);
  };

  const handleUsernameUpdate = async (data) => {
    await onUpdateUsername(data.username);
    await getCurrentUser();
    setUsernameEdit(false);
  };

  const handleEmailEditOpen = () => {
    setEmailEdit(true);
  };

  const handleEmailEditClose = async (data) => {
    resetEmail({ email: data.email });
    await onUpdateEmail(data.email);
    await refreshSession();
    setVerifyStatus(false);
  };

  const handleChangeUsername = async (value) => {
    if (value.length > 0 && value.length < 101) {
      await onCheckUsername(value);
    }
  };

  const onVerifyEmailCode = async (data) => {
    resetVerifyEmail(data);
    await onVerifyEmail(data?.code);
    await refreshSession();
    setModalEmail(false);
  };

  const handleChangePassword = async () => {
    await handleSubmitPassword(onSubmitPassword)();
  };

  const onUndoEmail = async () => {
    await onUpdateEmail('');
    await refreshSession();
    setModalUndo(false);
  };

  return (
    <>
      <Box className="account-setting">
        <Box className="account-setting__account">
          <Box className="account-setting__account__heading">
            {t('p.pages.settings.account.account.title')}
          </Box>
          {loading || !user ? (
            <SkeletonLoading className="h-6 mt-5" />
          ) : (
            <>
              <Box className="account-setting__account__username">
                <FormField>
                  <label className="form-label">
                    {t('p.pages.settings.profile.fields.username.label')}
                  </label>
                  <Box className="flex items-center">
                    <Box className="w-48">
                      <Input
                        defaultValue={t('p.pages.settings.account.account.patronsUrl')}
                      ></Input>
                    </Box>
                    <Box className="relative w-full">
                      <Input
                        placeholder={t('p.pages.settings.profile.fields.username.placeholder')}
                        defaultValue={user?.username}
                        disabled={!usernameEdit}
                        {...rest}
                        onChange={(e) => {
                          onChange(e);
                          setUsername(e.target.value);
                          handleChangeUsername(e.target.value);
                        }}
                      />
                      <Box className="absolute right-3 top-4 cursor-pointer">
                        {!usernameEdit && <FiEdit onClick={handleUsernameEditOpen} />}
                        {usernameEdit && (
                          <>
                            {!availabilityLoading && !usernameLoading ? (
                              <Box className="flex">
                                {!usernameAvailability &&
                                !errorsUsername.username?.message &&
                                username.toString() !== user?.username.toString() ? (
                                  <FaCheck
                                    className="mr-2"
                                    color="#51DC6B"
                                    onClick={handleSubmitUsername(handleUsernameUpdate)}
                                  />
                                ) : null}
                                <FaTimes color="#FB5758" onClick={handleUsernameEditClose} />
                              </Box>
                            ) : (
                              <FaClock />
                            )}
                          </>
                        )}
                      </Box>
                    </Box>
                  </Box>
                  {usernameEdit && errorsUsername.username?.message && (
                    <Box as="span" className="account-setting__account__username__error">
                      {errorsUsername.username.message}
                    </Box>
                  )}
                  {usernameAvailability &&
                    usernameEdit &&
                    (username !== user?.username && username !== null ? (
                      <Box as="span" className="account-setting__account__username__error">
                        {t('editProfile.usernameIsAlreadyTaken')}
                      </Box>
                    ) : null)}
                </FormField>
              </Box>
              <Box className="account-setting__account__email">
                <Grid column="1">
                  <Col>
                    <PasswordVerifier
                      isOpen={emailEdit}
                      onClose={() => {
                        resetEmail({ email: user?.email });
                        setEmailEdit(false);
                      }}
                      onVerified={(isVerified) => setVerifyStatus(isVerified)}
                    />
                    <FormField>
                      <label className="form-label">
                        {t('p.pages.settings.profile.fields.email.label')}
                      </label>
                      <Box className="relative">
                        <Input
                          as="email"
                          fullwidth
                          placeholder={t('p.pages.settings.profile.fields.email.placeholder')}
                          defaultValue={user?.email}
                          {...registerEmail('email', {
                            required: { value: true, message: t('editProfile.emailRequired') },
                            pattern: { value: /^\S+@\S+$/i, message: t('editProfile.emailInvalid') }
                          })}
                          disabled={!verifyStatus}
                        />
                        <Box className="absolute right-3 top-4 cursor-pointer">
                          {!verifyStatus && <FiEdit onClick={handleEmailEditOpen} />}
                          {verifyStatus && !errorsEmail.email?.message && (
                            <Box className="flex items-center">
                              {newEmail !== user?.email && (
                                <Button
                                  className="p-0"
                                  link
                                  size="sm"
                                  disabled={newEmail === user?.email}
                                  onClick={handleSubmitEmail(handleEmailEditClose)}
                                >
                                  {emailLoading ? <FaClock /> : <FaCheck color="#51DC6B" />}
                                </Button>
                              )}

                              <FaTimes
                                color="#FB5758"
                                onClick={() => setVerifyStatus(false)}
                                className="ml-2"
                              />
                            </Box>
                          )}
                        </Box>
                      </Box>

                      {!verifyStatus ? (
                        <Text as="span" className="email-field__input__prefix__action">
                          {user?.newEmail && user?.newEmail !== '' && (
                            <>
                              <Text
                                onClick={() => setModalEmail(true)}
                                as="span"
                                className="email-field__input__prefix__action underline cursor-pointer"
                              >
                                {t('p.pages.settings.profile.fields.verifyEmail.label')}
                              </Text>
                              <Text
                                onClick={() => setModalUndo(true)}
                                as="span"
                                className="email-field__input__prefix__action ml-4 underline cursor-pointer"
                              >
                                {t('p.pages.settings.profile.fields.undoChangeEmail.label')}
                              </Text>
                            </>
                          )}
                          <Modal
                            verticalCenter
                            className="modal-cover"
                            isOpen={modalEmail}
                            onClose={() => setModalEmail(false)}
                            heading={t('p.pages.settings.profile.fields.verifyEmail.modal.label')}
                          >
                            <Text className="mb-4">
                              {t('p.pages.settings.profile.fields.verifyEmail.modal.description')}
                            </Text>
                            <Box className="mb-4">
                              <Input
                                className="w-full"
                                placeholder={t(
                                  'p.pages.settings.profile.fields.verifyEmail.placeholder'
                                )}
                                {...registerVerifyEmail('code', {
                                  required: {
                                    value: true,
                                    message: t('editProfile.verificationCodeRequired')
                                  }
                                })}
                              />
                            </Box>
                            <Button
                              loading={emailLoading}
                              onClick={handleVerifyEmail(onVerifyEmailCode)}
                            >
                              {t('p.pages.settings.account.verifyEmail')}
                            </Button>
                          </Modal>
                          <Modal
                            verticalCenter
                            className="modal-cover"
                            isOpen={modalUndo}
                            onClose={() => setModalUndo(false)}
                            heading={t(
                              'p.pages.settings.profile.fields.undoChangeEmail.modal.label'
                            )}
                          >
                            <Text className="mb-4">
                              {t(
                                'p.pages.settings.profile.fields.undoChangeEmail.modal.description'
                              )}
                            </Text>
                            <Button loading={emailLoading} onClick={onUndoEmail}>
                              {t('p.pages.settings.account.undoEmail')}
                            </Button>
                          </Modal>
                        </Text>
                      ) : (
                        <>
                          <Box className="email-field__input">
                            {user?.newEmail && user?.newEmail !== '' ? (
                              <Text as="span" className="email-field__input__prefix__action">
                                {t('p.pages.settings.account.verify')}
                              </Text>
                            ) : null}
                          </Box>
                        </>
                      )}
                    </FormField>
                    {errorsEmail.email?.message && (
                      <Box as="span" className="account-setting__account__username__error">
                        {errorsEmail.email.message}
                      </Box>
                    )}
                  </Col>
                </Grid>
              </Box>
            </>
          )}
        </Box>
        <Box className="account-setting__password">
          <Box className="account-setting__password__heading">
            {t('p.pages.settings.account.passwordSettings')}
          </Box>
          {loading || !user ? (
            <SkeletonLoading className="h-6 mt-5" />
          ) : (
            <>
              <FormField className="password-field mt-5">
                <FormField>
                  <label className="form-label">
                    {t('p.pages.settings.profile.fields.password.current.label')}
                  </label>
                  <Input
                    fullWidth
                    placeholder={t('p.pages.settings.profile.fields.password.current.placeholder')}
                    as="password"
                    errorMessage={errorsPassword.current?.message}
                    {...registerPassword('current', {
                      required: { value: true, message: t('auth.fields.password.required') }
                    })}
                  />
                </FormField>
                <FormField>
                  <label className="form-label">
                    {t('p.pages.settings.profile.fields.password.new.label')}
                  </label>
                  <Input
                    fullWidth
                    placeholder={t('p.pages.settings.profile.fields.password.new.placeholder')}
                    as="password"
                    errorMessage={errorsPassword.new?.message}
                    {...registerPassword('new', {
                      required: {
                        value: true,
                        message: t('p.pages.settings.profile.fields.password.new.required')
                      },
                      pattern: {
                        value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                        message: t('auth.fields.password.strength')
                      },
                      minLength: { value: 8, message: t('auth.fields.password.minLength') },
                      maxLength: { value: 128, message: t('auth.fields.password.maxLength') }
                    })}
                  />
                </FormField>
                <FormField>
                  <label className="form-label">
                    {t('p.pages.settings.profile.fields.password.confirm.label')}
                  </label>
                  <Input
                    fullWidth
                    placeholder={t('p.pages.settings.profile.fields.password.confirm.placeholder')}
                    as="password"
                    errorMessage={errorsPassword.confirm?.message}
                    {...registerPassword('confirm', {
                      required: {
                        value: true,
                        message: t('auth.fields.confirmPassword.required')
                      },
                      validate: (value) =>
                        value === passwordRef.current || t('auth.fields.confirmPassword.match')
                    })}
                  />
                </FormField>

                <Box className="mt-2">
                  <Button
                    onClick={() => handleChangePassword()}
                    disabled={passwordLoading}
                    loading={passwordLoading}
                  >
                    {t('p.pages.settings.profile.fields.password.change')}
                  </Button>
                </Box>
              </FormField>
            </>
          )}
        </Box>
      </Box>
      <Helmet>
        <title>{t('p.pages.settings.account.pageTitle')}</title>
        <meta property="og:image" content="https://cdn.fans.inc/public/webapp/og-image.png" />
      </Helmet>
    </>
  );
}

export default SettingsAccount;
