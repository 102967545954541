import { Box, Media, Link } from '@components';

function CollectibleCard({ collectible }) {
  return (
    <Link to={`/${collectible?.creator?.username}/${collectible?.slug}`}>
      <Box className="gallery-card-collectible-card">
        <Box className="gallery-card-collectible-card__image">
          <Media collectible={collectible} thumbnail />
        </Box>
      </Box>
    </Link>
  );
}

export default CollectibleCard;
