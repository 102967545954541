import cx from 'classnames';
import { Box, Media, Text } from '@components';

const tierMapper = {
  NUMBERED_EDITION: 'Limited Edition',
  DIGITAL_ORIGINAL: 'Digital Original'
};

const ReleaseCheckout = ({
  exchange,
  className,
  editionBadgeProps,
  headingTop = false,
  headingRight = false,
  ...props
}) => {
  let currentOwner = exchange?.currentOwner;
  const creator =
    exchange?.creator?.displayName ||
    exchange?.creator.firstName + ' ' + exchange?.creator.lastName;
  const ownerName =
    currentOwner?.displayName || currentOwner?.firstName + ' ' + currentOwner?.lastName;
  const primaryMarket = exchange?.collectible?.creator?.id === currentOwner?.id;
  const numberedEdition = exchange?.tierType === 'NUMBERED_EDITION';
  // let url = urlParser(exchange, 'exchange', false, primaryMarket && numberedEdition);

  const Heading = () => (
    <Box className="ml-0 md:ml-3">
      <Box className="flex justify-start flex-col items-start">
        <Text className="release-checkout__title">{exchange?.collectible?.title}</Text>
        <Text className="release-checkout__tier">
          {tierMapper[exchange?.tierType]} #{exchange?.serialIndex} by{' '}
          <span className="font-bold">{creator}</span>
        </Text>
        {exchange?.creator?.id !== currentOwner?.id && (
          <Text className="release-checkout__owner">
            Owned by <span className="release-checkout__owner__bold">{ownerName}</span>
          </Text>
        )}
      </Box>
    </Box>
  );

  return (
    <Box
      className={cx('release-checkout', headingRight && 'release-checkout-right', className)}
      {...props}
    >
      {headingTop && <Heading />}
      {!headingRight ? (
        <Box className={`release-checkout__media ${headingTop ? 'mt-5' : 'mb-5'}`}>
          <Media
            className="shadow-default"
            collectible={exchange?.collectible}
            thumbnail
            showPlayer={false}
          />
        </Box>
      ) : (
        <Box className={`release-checkout__thumbnail`}>
          <Media collectible={exchange?.collectible} thumbnail showPlayer={false} />
        </Box>
      )}

      {!headingTop || headingRight ? <Heading /> : null}
    </Box>
  );
};

export default ReleaseCheckout;
