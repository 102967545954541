import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import {
  Box,
  CollectibleCard,
  Container,
  Heading,
  Text,
  NotFound,
  SkeletonLoading
} from '@components';
import { urlParser } from '@pages/profile/utils';
import { EXCHANGE_INTENTS } from '@libs/utils/exchange';
import { useExchangeByID, usePollExchange } from '@libs/hooks/exchange';
import { useConfirmationQuery, useRefreshSession } from '@pages/confirmation/fansinc/hooks';
import { getTitleAndDescription } from '@pages/confirmation/fansinc/utils';
import { useLocale } from '@libs/contexts/locale';

const ErrorConfirmationContent = ({ title, description }) => (
  <Box className="confirmation">
    <Container className="confirmation__container">
      <Box className="confirmation__header">
        <Heading className="confirmation__header__title" as="h3">
          {title}
        </Heading>
        <Text as="p" className="confirmation__header__description">
          {description}
        </Text>
      </Box>
    </Container>
  </Box>
);

const LoadingConfirmationContent = ({ title }) => {
  const centerColClasses = 'flex w-full items-center';
  return (
    <>
      <Heading as={'h4'} className="mb-6 text-center">
        {title}
      </Heading>
      <Box className={`${centerColClasses} flex-col sm:flex-row-reverse`}>
        <SkeletonLoading className="h-96 w-full max-w-9/12 ml-4 mb-5" />
        <Box className={`confirmation__header ${centerColClasses} flex-col`}>
          <Box className={`confirmation__header__description ${centerColClasses} flex-col`}>
            <SkeletonLoading className="my-1 h-8 w-10/12" key={`skel-1`} />
          </Box>
          <Box className={`confirmation__header__description ${centerColClasses} flex-col`}>
            {[...Array(3)].map((_, i) => (
              <SkeletonLoading className="my-1 h-3 w-10/12" key={`skel-${i}`} />
            ))}
          </Box>
          <Box className={`confirmation__header__description ${centerColClasses} flex-col`}>
            {[...Array(2)].map((_, i) => (
              <SkeletonLoading className="my-1 h-3 w-10/12" key={`skel-${i}`} />
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};

const PageNotFound = () => (
  <Box className="my-24 mx-12">
    <NotFound />
  </Box>
);

function Confirmation() {
  const { t } = useTranslation();
  const { prevExchange: prevExchangeID } = useParams();
  const history = useHistory();
  const { status, referenceID, exchangeIntent, imageUrl, color } = useConfirmationQuery();
  const {
    exchange,
    timeoutExceeded,
    loading: currentExchangeLoading
  } = usePollExchange(referenceID, 2000);
  useRefreshSession();

  const { data: prevExchange, loading: loadingPrevEx } = useExchangeByID(prevExchangeID);
  const isFixedPrice = exchangeIntent === EXCHANGE_INTENTS.FIXED_PRICE_PURCHASE;

  useEffect(() => {
    if (exchange && exchangeIntent === EXCHANGE_INTENTS.FIXED_PRICE_PURCHASE) {
      history.push(`/congratulation/?eid=${exchange.id}`);
    }
  }, [exchange]); // eslint-disable-line

  const exchangeURL = window.location.hostname + urlParser(prevExchange, 'release', isFixedPrice);
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${exchangeURL}`;
  const { currencyCode, currencyFormatter } = useLocale();

  let { title, description, additionalDesc } = getTitleAndDescription({
    exchangeIntent,
    exchange: prevExchange,
    status,
    timeoutExceeded: timeoutExceeded,
    t,
    currencyCode,
    currencyFormatter,
    currentExchange: exchange,
    currentExchangeLoading
  });
  const selectedFrame = color === 'WHITE' ? '7px solid #E0E0E0' : '7px solid black';

  const hasError = status === 'fail';
  const exchangeNotFound = !referenceID;

  if (hasError) {
    return <ErrorConfirmationContent title={title} description={description} />;
  }

  if (exchangeNotFound) {
    return <PageNotFound />;
  }

  const ConfirmationContainerInner = () => (
    <div className="confirmation__content-wrapper confirmation__content-wrapper--reverse">
      <Box className="confirmation__left confirmation__left--reverse">
        {prevExchange && (
          <CollectibleCard
            collectible={prevExchange}
            type="release"
            showButton={false}
            hasCollectibleTimer={false}
            showOwnerInUrl={isFixedPrice}
            showReleaseAsCollectible={
              [EXCHANGE_INTENTS.MAKE_OFFER, EXCHANGE_INTENTS.OFFER_BITPAY_PAYMENT_MADE].includes(
                exchangeIntent
              ) && exchange?.creatorID === exchange?.currentOwnerID
            }
          ></CollectibleCard>
        )}
      </Box>
      <Box className="confirmation__right">
        <Box>
          <Heading className="confirmation__header__title" as="h3">
            {title}
          </Heading>
          <Text as="p" className="confirmation__header__description">
            {description}
          </Text>
          {additionalDesc && (
            <Text as="p" className="confirmation__header__description">
              {additionalDesc}
            </Text>
          )}
        </Box>
      </Box>
    </div>
  );

  return (
    <Box className="confirmation">
      <Container className={`confirmation__container confirmation__container--landscape`}>
        {prevExchange && !loadingPrevEx ? (
          <ConfirmationContainerInner />
        ) : (
          <LoadingConfirmationContent title={t('confirmation.loading')} />
        )}
      </Container>
    </Box>
  );
}

export default Confirmation;
