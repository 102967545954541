import FansincOrderHistoryTable from './fansinc';
import PatronsOrderHistoryTable from './patrons';

const Header = () => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsOrderHistoryTable />;
  } else {
    return <FansincOrderHistoryTable />;
  }
};

export default Header;
