import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Box, Container, Heading } from '@components';

function NotFound() {
  const { t } = useTranslation();
  return (
    <>
      <Box className="not-found">
        <Container size="sm" className="not-found__container">
          <Heading as="h2" className="not-found__title">
            Print it
          </Heading>
        </Container>
      </Box>
      <Helmet>
        <title>{t('notFound.title')}</title>
        <meta property="og:image" content="https://cdn.fans.inc/public/webapp/og-image.png" />
      </Helmet>
    </>
  );
}

export default NotFound;
