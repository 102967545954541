import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
  Box,
  Button,
  CollectibleCard,
  CollectibleSkeleton,
  SkeletonLoading,
  Link,
  Separator
} from '@components';

export default function PatronsCollectibleMore({ collectibles, loading, creator }) {
  const { t } = useTranslation();
  return (
    <div>
      <Separator className="my-6 md:my-12" />
      <Box className="collectible__more">
        <Box className="collectible__more__top">
          {creator ? (
            <Box className="collectible__more__top__text">
              <Trans i18nKey="p.pages.collectible.otherCollectible.creator">
                {creator?.displayName || creator?.firstName + ' ' + creator?.lastName}
              </Trans>
            </Box>
          ) : (
            <SkeletonLoading className="h-14 w-96 mr-auto" />
          )}
          <Link to={`/${creator?.username}`}>
            <Button className="collectible__more__top__button">
              {t('p.pages.collectible.otherCollectible.viewCollectible')}
            </Button>
          </Link>
        </Box>
        <Box className="collectible__more__collectible">
          {!loading ? (
            <>
              {collectibles?.map((collectible) => (
                <CollectibleCard
                  key={collectible?.id}
                  collectible={collectible}
                  showPrice={false}
                ></CollectibleCard>
              ))}
            </>
          ) : (
            <>
              <CollectibleSkeleton />
              <CollectibleSkeleton />
              <CollectibleSkeleton />
            </>
          )}
        </Box>
        <Link className="flex justify-center" to={`/${creator?.username}`}>
          <Button className="collectible__more__bottom__button">
            {t('p.pages.collectible.otherCollectible.viewCollectible')}
          </Button>
        </Link>
      </Box>
    </div>
  );
}
