import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { useAuth } from '@libs/contexts/auth';
import {
  useExchangeByPatronAndExpiryDate,
  useExchangeByOwnerAndExpiryDate
} from '@libs/hooks/exchange';
import useToast from '@libs/utils/toast';
import { EXCHANGE_TYPES, LISTING_TYPES, EXCHANGE_INTENTS } from '@libs/utils/exchange';
import { Tabs, TabItem, OfferModal, Button, ExchangeTable } from '@components';
import useQuery from '@libs/utils/query';
function OfferBids() {
  const { t } = useTranslation();
  const query = useQuery();
  const { user } = useAuth();
  const now = new Date().toISOString();
  const toast = useToast();
  const [isOfferModalOpen, setIsOfferModalOpen] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [selectedExchangeIntent, setSelectedExchangeIntent] = useState(null);
  const getActiveTab = (tab) => {
    switch (tab) {
      case 'OffersReceived':
        return t('settings.offers.offersReceived');
      case 'Bid':
        return t('settings.bids.bidsMade');
      default:
        return t('settings.offers.offersMade');
    }
  };
  const [activeTab, setActiveTab] = useState(getActiveTab(query.get('t')));

  const {
    data: offersByMe,
    getData: refreshOffersByMe,
    loading: isLoadingOffersByMe,
    pageToken: offersByMeToken
  } = useExchangeByPatronAndExpiryDate(
    user?.id,
    now,
    EXCHANGE_TYPES.OFFER,
    'FALSE',
    10,
    'createdAt'
  );

  const {
    data: exchangesForMe,
    loading: isLoadingOffersForMe,
    getData: fetchOffersReceived,
    pageToken: offersReceivedToken
  } = useExchangeByOwnerAndExpiryDate(user?.id, now, EXCHANGE_TYPES.OFFER, 'FALSE', 10);

  const {
    data: bidsByMe,
    loading: isLoadingBidsByMe,
    getData: fetchBidsMade,
    pageToken: bidsMadeToken
  } = useExchangeByPatronAndExpiryDate(user?.id, now, EXCHANGE_TYPES.BID, null, 10, 'maxBidPrice');

  const actionsForOffersByMe = [
    {
      name: t('components.exchangesTable.cancelOffer'),
      onClick: (exchange) => {
        setSelectedOffer(exchange);
        setSelectedExchangeIntent(EXCHANGE_INTENTS.CANCEL_OFFER);
        setIsOfferModalOpen(true);
      }
    }
  ];

  const actionsForOffersForMe = [
    {
      name: t('components.exchangesTable.acceptOffer'),
      onClick: (exchange) => {
        setSelectedOffer(exchange);
        setSelectedExchangeIntent(EXCHANGE_INTENTS.ACCEPT_OFFER);
        setIsOfferModalOpen(true);
      },
      className: 'mr-3'
    },
    {
      name: t('components.exchangesTable.rejectOffer'),
      onClick: (exchange) => {
        setSelectedOffer(exchange);
        setSelectedExchangeIntent(EXCHANGE_INTENTS.REJECT_OFFER);
        setIsOfferModalOpen(true);
      },
      whiteOutline: true
    }
  ];

  const onOfferActionFinish = async () => {
    switch (selectedExchangeIntent) {
      case EXCHANGE_INTENTS.CANCEL_OFFER:
        await refreshOffersByMe();
        break;

      case EXCHANGE_INTENTS.ACCEPT_OFFER:
        const urlData = {
          eid: selectedOffer.id,
          eint: EXCHANGE_INTENTS.ACCEPT_OFFER
        };
        const searchParams = new URLSearchParams(urlData);
        if (selectedOffer.paymentProvider === 'CHECKOUT') {
          window.location.assign(
            `/confirmation/${selectedOffer.slug}/${selectedOffer.id}/?${searchParams.toString()}`
          );
        } else {
          toast(t('components.exchangesTable.acceptOfferSuccess'), 'success');
        }
        break;

      default:
        break;
    }
  };

  return (
    <>
      <Tabs className="-mt-2" classNameChild="py-8" directTo={activeTab} full>
        <TabItem label={t('settings.offers.offersMade')}>
          <ExchangeTable
            exchanges={offersByMe}
            isLoading={isLoadingOffersByMe}
            listingType={LISTING_TYPES.MAKE_AN_OFFER}
            actions={actionsForOffersByMe}
            tableType="offerMade"
          />
          {offersByMeToken && (
            <Button
              className="mt-2"
              disabled={isLoadingOffersByMe}
              onClick={() => {
                refreshOffersByMe(offersByMeToken);
                setActiveTab(t('settings.offers.offersMade'));
              }}
            >
              {t('components.loadMoreButton.title')}
            </Button>
          )}
        </TabItem>
        <TabItem label={t('settings.offers.offersReceived')}>
          <ExchangeTable
            exchanges={exchangesForMe}
            isLoading={isLoadingOffersForMe}
            listingType={LISTING_TYPES.MAKE_AN_OFFER}
            actions={actionsForOffersForMe}
            tableType="offerReceived"
          />
          {offersReceivedToken && (
            <Button
              className="mt-2"
              disabled={isLoadingOffersForMe}
              onClick={() => {
                fetchOffersReceived(offersReceivedToken);
                setActiveTab(t('settings.offers.offersReceived'));
              }}
            >
              {t('components.loadMoreButton.title')}
            </Button>
          )}
        </TabItem>
        <TabItem label={t('settings.bids.bidsMade')}>
          <ExchangeTable
            exchanges={bidsByMe}
            isLoading={isLoadingBidsByMe}
            listingType={LISTING_TYPES.AUCTION}
            tableType="bidMade"
          />
          {bidsMadeToken && (
            <Button
              className="mt-2"
              disabled={isLoadingBidsByMe}
              onClick={() => {
                fetchBidsMade(bidsMadeToken);
                setActiveTab(t('settings.bids.bidsMade'));
              }}
            >
              {t('components.loadMoreButton.title')}
            </Button>
          )}
        </TabItem>
      </Tabs>
      {selectedOffer && (
        <OfferModal
          exchangeOffer={selectedOffer}
          exchangeIntent={selectedExchangeIntent}
          isOpen={isOfferModalOpen}
          setIsOpen={setIsOfferModalOpen}
          onFinish={onOfferActionFinish}
        />
      )}
      <Helmet>
        <title>{t('settings.offers.title')}</title>
        <meta property="og:image" content="https://cdn.fans.inc/public/webapp/og-image.png" />
      </Helmet>
    </>
  );
}

OfferBids.propTypes = {};

export default OfferBids;
