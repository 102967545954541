import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Signup } from '@components';

function AuthRegister() {
  const { t } = useTranslation();
  return (
    <>
      <Signup />
      <Helmet>
        <title>{t('auth.register.pageTitle')}</title>
        <meta property="og:image" content="https://cdn.fans.inc/public/webapp/og-image.png" />
      </Helmet>
    </>
  );
}

export default AuthRegister;
