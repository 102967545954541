import FansincOrderSummary from './fansinc';
import PatronsOrderSummary from './patrons';

const OrderSummary = ({ showVAT, price, marginSize = 'sm' }) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsOrderSummary showVAT={showVAT} price={price} marginSize={marginSize} />;
  } else {
    return <FansincOrderSummary showVAT={showVAT} price={price} marginSize={marginSize} />;
  }
};

export default OrderSummary;
