import { useState, useCallback, useEffect } from 'react';
import { Box, Avatar, RichTextEditor, Modal, Button } from '@components';
import { formatDistanceToNowStrict } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { FiMoreVertical } from 'react-icons/fi';
import ChildComment from './childComment';
import cx from 'classnames';
import { useOutsider } from '@libs/utils/outsider';
import { useReplyPost } from '@libs/hooks/discourse';
import { useAuth } from '@libs/contexts/auth';

const Comment = ({ user, comment, fetchPost, setTotalComment, totalComment }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { user: userLogin } = useAuth();
  const [listComment, setListComment] = useState([]);
  const [hidden, setHidden] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [commentFieldOpen, setCommentFieldOpen] = useState(false);
  const { data, deleteLoading, replyLoading, getData, replyPost, deleteComment } = useReplyPost(
    comment?.id
  );
  const commentAuthor = comment?.user;

  const handleClose = () => setIsOpen(false);
  const { ref } = useOutsider(handleClose);
  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const handledSendComment = useCallback(
    async (commentInput) => {
      if (!replyLoading) {
        const commentRaw = commentInput.slice(0, -11);
        const payload = {
          raw: commentRaw,
          topicID: comment?.topicID,
          userID: userLogin?.id,
          category: comment?.channelID,
          parentID: comment?.id
        };
        const newComment = {
          user: userLogin,
          content: commentRaw,
          createdAt: new Date()
        };
        if (commentRaw.length > 20) {
          setListComment(listComment.concat(newComment));
          setTotalComment(totalComment + 1);
        }
        setCommentFieldOpen(false);
        await replyPost(payload);
        setTimeout(function () {
          getData();
        }, 2000);
      }
    },
    [listComment, replyLoading]
  ); // eslint-disable-line

  const handleDeleteComment = async () => {
    setShowDeleteModal(false);
    setTotalComment(totalComment - 1);
    setHidden(true);
    await deleteComment(userLogin?.id);
  };
  const deleteCountComment = () => {
    setTotalComment(totalComment - 1);
  };

  const fetchChildComment = async () => {
    await fetchPost();
    await getData();
  };

  useEffect(() => {
    if (data?.length > 0) {
      setListComment(data);
    }
  }, [data]);

  useEffect(() => {
    if (comment) {
      setListComment(
        comment?.replies?.items
          ?.map((childComment) => childComment.post)
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      );
    }
  }, [comment]);
  return (
    <>
      {comment && (
        <Box className={cx(hidden && 'hidden', 'comment')}>
          <Box className="comment-left">
            <Avatar user={commentAuthor} size="xs-40" className="post-header-avatar" />
          </Box>
          <Box className="comment-right">
            <Box className="comment-right-body">
              <Box className="comment-right-body-header">
                <Box className="comment-right-body-header-name">
                  {commentAuthor?.displayName ||
                    commentAuthor?.firstName + ' ' + commentAuthor?.lastName}
                </Box>
                <Box className="comment-right-body-header-time">
                  {comment &&
                    formatDistanceToNowStrict(new Date(comment?.createdAt), { addSuffix: true })}
                </Box>
                {comment?.id && (
                  <Box ref={ref} className="comment-right-body-header-tools">
                    <Box className="comment-right-body-header-tools-icon">
                      <FiMoreVertical
                        onClick={() => setIsOpen(!isOpen)}
                        style={{ cursor: 'pointer' }}
                      />
                    </Box>
                    {isOpen && (
                      <>
                        <ul className="comment-right-body-header-tools-dropdown">
                          <li
                            onClick={() => {
                              setCommentFieldOpen(!commentFieldOpen);
                              setIsOpen(false);
                            }}
                          >
                            {t('components.post.comment.reply')}
                          </li>
                          {userLogin?.id === comment?.userID && (
                            <li
                              onClick={() => {
                                setShowDeleteModal(true);
                                setIsOpen(false);
                              }}
                            >
                              {t('components.post.comment.delete')}
                            </li>
                          )}
                        </ul>
                      </>
                    )}
                  </Box>
                )}
              </Box>
              <Box
                dangerouslySetInnerHTML={{
                  __html: comment?.content
                }}
                className="comment-right-body-content"
              ></Box>
            </Box>
            {listComment?.map((comment) => (
              <ChildComment
                user={user}
                deleteCountComment={deleteCountComment}
                comment={comment}
                setCommentFieldOpen={setCommentFieldOpen}
                commentFieldOpen={commentFieldOpen}
                fetchData={fetchChildComment}
                key={`childcomment-${comment.id}`}
              />
            ))}
            {commentFieldOpen && (
              <Box className="comment-right-create-comment">
                <Avatar user={userLogin} size="xs-40" className="comment-right-header-avatar" />
                <Box className="comment-right-create-comment-input">
                  <RichTextEditor
                    placeholder={t('components.post.comment.placeholder')}
                    onEnter={handledSendComment}
                  />
                </Box>
              </Box>
            )}
          </Box>
          <Modal
            heading={t(['components.post.comment.deleteComment'])}
            isOpen={showDeleteModal}
            onClose={() => handleCloseDeleteModal()}
          >
            {t('components.post.comment.deleteCommentDescription')}
            <Box className="post-modal-delete-button">
              <Button
                onClick={() => handleCloseDeleteModal()}
                className="post-modal-delete-button-cancel"
                outline
              >
                {t('components.post.comment.cancel')}
              </Button>
              <Button loading={deleteLoading} onClick={() => handleDeleteComment()}>
                {t('components.post.comment.delete')}
              </Button>
            </Box>
          </Modal>
        </Box>
      )}
    </>
  );
};

export default Comment;
