import { useState } from 'react';
import { Avatar, Box, Link, Text, NFTAddress } from '@components';
import { getUserName } from '@libs/utils/user';
import ReactTooltip from 'react-tooltip';

const UserLink = ({
  user,
  children,
  showName,
  showTooltip,
  exchange,
  avatarSize = 'xs',
  showAddress,
  ...props
}) => {
  const [tooltip, setTooltip] = useState(false);
  if (user == null) {
    return <Avatar size={avatarSize} />;
  }
  const { username, firstName, lastName } = user;
  const userFullName = firstName + ' ' + lastName;
  return (
    <div>
      <Link
        className="user-link"
        to={`/${username}`}
        {...props}
        data-tip={userFullName || username}
      >
        <Avatar
          size={avatarSize}
          user={user}
          onMouseEnter={() => showTooltip && setTooltip(true)}
          onMouseLeave={() => setTooltip(false)}
        />
        {showName && (
          <Box className="user-link__name">
            <Text as="span">
              {getUserName(firstName)} {lastName}
            </Text>
            {showAddress && <NFTAddress exchange={exchange} className="mt-px" />}
          </Box>
        )}

        {/* {showTooltip && (
        <CollectibleTooltip capitalize onShow={tooltip} className="user-link__tooltip">
          {firstName} {lastName}
        </CollectibleTooltip>
      )} */}
      </Link>
      <ReactTooltip place="right" type="dark" effect="solid" />
    </div>
  );
};

UserLink.defaultProps = {
  showName: true,
  showTooltip: true
};

export default UserLink;
