import { Box, Link, Avatar } from '@components';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { ReactComponent as ArrowRightIcon } from '@assets/svg/arrow-right.svg';
import { ReactComponent as ArrowRightBlackIcon } from '@assets/svg/arrow-right-black.svg';

export default function PatronsCollectibleTheArtist({ collectible }) {
  const { t } = useTranslation();
  return (
    <div>
      <Box className="collectible__tab__artist">
        <Box className="collectible__tab__artist__name">
          <Trans i18nKey="p.pages.collectible.about">
            {{
              creatorName:
                collectible?.creator?.displayName ||
                collectible?.creator?.firstName + ' ' + collectible?.creator?.lastName
            }}
          </Trans>
        </Box>
        <Box className="collectible__tab__artist__description">{collectible?.creator?.bio}</Box>
        {collectible?.creator?.media?.avatarUrl && (
          <Box className="collectible__tab__artist__image grid grid-cols-2">
            <Avatar size="full" user={collectible?.creator} />
          </Box>
        )}
        <Link
          to={`/${collectible?.creator?.username}`}
          className="collectible__tab__artist__bottom"
        >
          <Trans i18nKey="p.pages.collectible.artistCollectible">
            {{
              name:
                collectible?.creator?.displayName ||
                collectible?.creator?.firstName + ' ' + collectible?.creator?.lastName
            }}
          </Trans>
          <ArrowRightBlackIcon />
        </Link>
        {/* <Link target="_blank" to={`${collectible?.creator?.links?.website}`}>
          <Button className="collectible__tab__artist__button mt-6">
            <Box className="flex items-center uppercase">
              {t('p.pages.collectible.knowMore')}
              <ArrowRightIcon
                className="white"
                style={{ marginLeft: '10px', width: '20px', height: '20px' }}
              />
              <ArrowRightBlackIcon
                className="black"
                style={{ marginLeft: '10px', width: '20px', height: '20px' }}
              />
            </Box>
          </Button>
        </Link> */}
      </Box>
    </div>
  );
}
