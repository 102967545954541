import { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { MdOutlineNotificationsActive } from 'react-icons/md';

import { Box, Container, Button, Link, NotificationMenuItem, Text } from '@components';
import { useOutsider } from '@libs/utils/outsider';
import { ReactComponent as NotificationIcon } from '@assets/svg/notification.svg';
import { useUpdateReadNotification } from '@libs/hooks/notification';
import { useNotifications } from '@libs/contexts/notification';

function Notifications() {
  const { t } = useTranslation();
  const { notifications, readAllNotifications } = useNotifications();

  const todayNotifications = [];
  const olderNotifications = []; // notifications.filter((notification) => ;

  function getDifferenceInDays(date1, date2) {
    const diffInMs = Math.abs(date2 - date1);
    return diffInMs / (1000 * 60 * 60 * 24);
  }

  const now = new Date();
  notifications?.forEach((notification) => {
    const notificationDate = new Date(notification?.createdAt);
    if (getDifferenceInDays(now, notificationDate) >= 1) {
      olderNotifications.push(notification);
    } else {
      todayNotifications.push(notification);
    }
  });
  const { onUpdateNotification, updateLoading } = useUpdateReadNotification();
  const onUpdate = async (e) => {
    e.preventDefault();
    readAllNotifications();
    const data = {
      itemType: 'user'
    };
    await onUpdateNotification(data);
  };

  return (
    <>
      <Container className="pt-10">
        <Box className="block sm:flex items-center mb-10 justify-between">
          <Box className="text-2xl sm:text-4xl font-bold text-primary">
            {t('p.allNotifications.title')}
          </Box>
          <Box>
            <Box className="notification-button mt-4 sm:mt-0">
              <Button onClick={onUpdate} loading={updateLoading} darkOutline>
                Mark all as read
              </Button>
            </Box>
          </Box>
        </Box>
        {todayNotifications.length > 0 && (
          <Box className="">
            <Box className="">
              {todayNotifications?.map(function (notification, i) {
                return (
                  <NotificationMenuItem
                    key={i}
                    index={i}
                    notification={notification}
                    className=""
                  />
                );
              })}
            </Box>
          </Box>
        )}

        {olderNotifications.length > 0 && (
          <Box className="notifications-group">
            <Box className="notifications-group__items">
              {olderNotifications.map(function (notification, i) {
                return (
                  <NotificationMenuItem
                    key={i}
                    index={i}
                    notification={notification}
                    className="notifications-group-item"
                  />
                );
              })}
            </Box>
          </Box>
        )}
        {notifications?.length === 0 && (
          <Box className="notification-empty">
            <Box className="notification-empty__icon patrons-icon">
              <MdOutlineNotificationsActive size={50} color="rgba(43, 43, 43, 1)" />
            </Box>
            <Text className="notification-empty__title text-primary">
              {t('components.notificationMenu.empty.title')}
            </Text>
            <Text className="notification-empty__body text-primary">
              {t('components.notificationMenu.empty.body')}
            </Text>
          </Box>
        )}
      </Container>
      <Helmet>
        <title>{t('allNotifications.title')}</title>
        <meta property="og:image" content="https://cdn.fans.inc/public/webapp/og-image.png" />
      </Helmet>
    </>
  );
}

export default Notifications;
