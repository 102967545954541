import { userPublicFragment } from '@libs/fragments/user';
import { exchangePublicFragment } from '@libs/fragments/exchange';

export const findUnitExchange = /* GraphQL */ `
  query FindUnitExchange(
    $filter: SearchableExchangeFilterInput
    $sort: SearchableExchangeSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchExchanges(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        ...ExchangePublic
        nftContractAddress
        nftTokenIDString
        collectibleID
        collectible {
          id
          accountID
          userID
          type
          collectionID
          category
          genre
          title
          slug
          printable
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          streamExternal
          streamUrl {
            spotify
            appleMusic
            youtube
          }
          perksOriginal {
            fanClubAccess
            limitedPerks
            lifetimePerks
          }
          perksNumbered {
            fanClubAccess
            limitedPerks
            lifetimePerks
          }
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableLowestPrice
          searchableHighestPrice
          searchableHighestBidPrice
          width
          height
          smallWidth
          smallHeight
          largeWidth
          largeHeight
          originalFrame
          smallFrame
          smallPrint
          creator {
            ...UserPublicProfile
          }
        }
        previousExchange {
          ...ExchangePublic
          currentOwner {
            ...UserPublicProfile
          }
        }
        parentExchange {
          ...ExchangePublic
        }
        creator {
          ...UserPublicProfile
        }
        currentOwner {
          ...UserPublicProfile
        }
      }
    }
  }

  ${userPublicFragment}
  ${exchangePublicFragment}
`;
