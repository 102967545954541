export default function JumbotronCard({ number, title, description }) {
  return (
    <div className="relative about__jumbotron__container mt-10 md:mt-20">
      <div className="z-50 relative">
        <div className="px-10 py-10 z-50">
          <p
            style={{ fontSize: '26px', lineHeight: '39px' }}
            className="font-bold font-Satoshi capitalize"
          >
            {title}
          </p>
          <p className="about-page-jumboton font-normal text-lg mt-4">{description}</p>
        </div>
      </div>
      <div className="absolute left-0 top-10 z-0">
        <span className="font-bold font-Satoshi text-transparent about__jumbotron__number">
          {number}
        </span>
      </div>
    </div>
  );
}
