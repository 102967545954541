import cx from 'classnames';
import { useTranslation, Trans } from 'react-i18next';
import { ReactComponent as SpinnerIcon } from '@assets/svg/spinner.svg';
import { useTransformTierTilesData } from '@components/edition-card/hooks';
import { useCollectibleAvailability } from '@libs/hooks/exchange';
import { Box, Button, Text, Price } from '@components';

const EditionSecondary = ({ loading, collectible, tierTilesData, onClick, tier }) => {
  const { t } = useTranslation();
  // these contain exchange for primary & secondary market eg exchangesData.secondaryMarket
  const { hasExchanges: hasExchangesNE, exchangesData: exchangesDataNE } =
    useTransformTierTilesData({
      collectible,
      tierTilesData,
      tier: tier[1]
    });
  const { hasExchanges: hasExchangesDO, exchangesData: exchangesDataDO } =
    useTransformTierTilesData({
      collectible,
      tierTilesData,
      tier: tier[0]
    });
  const { data: totalRelease, loading: releaseLoading } = useCollectibleAvailability({
    collectibleID: collectible?.id,
    creatorID: collectible?.userID
  });

  return (
    <Box
      className={cx(
        'edition-card edition-card__secondary',
        (loading || releaseLoading) && 'edition-card-loading',
        !loading &&
          !releaseLoading &&
          !hasExchangesNE?.secondaryMarket &&
          !hasExchangesDO?.secondaryMarket &&
          'hidden'
      )}
    >
      {loading || releaseLoading ? (
        <SpinnerIcon
          style={{
            fontSize: 30
          }}
        />
      ) : (
        !loading &&
        !releaseLoading &&
        (hasExchangesNE?.secondaryMarket || hasExchangesDO?.secondaryMarket) && (
          <Box
            className="edition-card__content"
            onClick={() => onClick('SCROLL_TO_COLLECTIBLES_TABLE')}
          >
            <>
              <Text className="text-default edition-card__content__secondary__title">
                {t('components.editionCard.secondaryMarketplace')}
              </Text>
              <p className="edition-card__content__secondary__available">
                {totalRelease} {t(`components.editionCard.edition${totalRelease !== 1 ? 's' : ''}`)}{' '}
                <br />
                {t('components.editionCard.available')}
              </p>
              <Box className="box edition-card__content__secondary__information">
                <p className="edition-card__content__secondary__desc">
                  {t('components.editionCard.availableFrom')}
                </p>
                <Box className="box edition-card__content__secondary__value">
                  <Price
                    amount={
                      exchangesDataNE?.secondaryMarket?.lowestPrice?.currentPrice ||
                      exchangesDataDO?.secondaryMarket?.lowestPrice?.currentPrice
                    }
                  />
                </Box>
              </Box>
            </>

            <Box className="box edition-card__button">
              <Button onClick={() => onClick('SCROLL_TO_COLLECTIBLES_TABLE')}>
                <Trans i18nKey="components.editionCard.button.secondary.getItNow" />
              </Button>
            </Box>
          </Box>
        )
      )}
    </Box>
  );
};

export default EditionSecondary;
