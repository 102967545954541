import { Helmet } from 'react-helmet';
import cx from 'classnames';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BiPlus, BiMinus } from 'react-icons/bi';
import { Box, Container } from '@components';
import { works } from '../../utils';
import Fade from '../../components/fade';
import Hero from '../../components/hero';

function HowitWorks() {
  const { t } = useTranslation();
  const [index, setIndex] = useState(1);

  return (
    <Box className="overflow-hidden relative">
      <Fade className="-right-20 -top-40" />
      <Fade className="-left-80 bottom-0" />
      <Hero
        title={
          <Trans i18nKey="howitworks.title">
            <br />
          </Trans>
        }
      />
      <Container size="lg">
        <Box className="faq">
          {works.map((work, idx) => (
            <Box
              className={cx('faq-item', index === idx ? 'faq-item-active' : '')}
              id={work.alias}
              key={work.alias}
            >
              <h3
                className="font-semibold faq-heading"
                onClick={() => setIndex(index === idx ? null : idx)}
              >
                {work.title}{' '}
                <span className="faq-icon">{index === idx ? <BiMinus /> : <BiPlus />}</span>
              </h3>

              <div
                className="faq-content"
                dangerouslySetInnerHTML={{ __html: work.description }}
              ></div>
            </Box>
          ))}
        </Box>
      </Container>
      <Helmet>
        <title>{t('howitworks.title')}</title>
        <meta property="og:image" content="https://cdn.fans.inc/public/webapp/og-image.png" />
      </Helmet>
    </Box>
  );
}

export default HowitWorks;
