import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Login } from '@components';
import useQuery from '@libs/utils/query';
import { useAuth } from '@libs/contexts/auth';

function AuthLogin() {
  const { t } = useTranslation();
  const query = useQuery();
  const { isAuthenticated } = useAuth();
  const history = useHistory();

  if (isAuthenticated) {
    if (!localStorage.getItem('collectibleCollected')) {
      history.push('/marketplace');
    }
  }

  return (
    <>
      <Login
        defaultEmail={query.get('email')}
        redirect={
          localStorage.getItem('collectibleCollected')
            ? `/collect-collectible/${localStorage.getItem('collectibleCollected')}`
            : '/marketplace'
        }
      />
      <Helmet>
        <title>{t('auth.login.pageTitle')}</title>
        <meta property="og:image" content="https://cdn.fans.inc/public/webapp/og-image.png" />
      </Helmet>
    </>
  );
}

export default AuthLogin;
