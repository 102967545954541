import FansincPrintPage from './fansinc';
import PatronsPrintPage from './patrons';

const MintPage = () => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsPrintPage />;
  } else {
    return <FansincPrintPage />;
  }
};

export default MintPage;
