import { Box, ImageCollage, Avatar, Separator, Link } from '@components';
import { useTranslation } from 'react-i18next';
import { useExchangeByUser } from '@libs/hooks/exchange/useExchangeByUser';
import { useUserCollectibleLike } from '@libs/hooks/collectible-like';
import { createAssetUrl } from '@libs/utils/media';
import { useLocale } from '@libs/contexts/locale';
function Collector({ collector }) {
  const { data: exchanges } = useExchangeByUser(collector?.id, '', 3);
  const { data: collectibles } = useUserCollectibleLike(collector?.id);
  const { t } = useTranslation();
  const { getCurrencyFormatter } = useLocale();
  const currencyFormatter = getCurrencyFormatter();
  const listImage = exchanges?.map((x) => {
    return {
      src: createAssetUrl(x?.collectible?.mediaThumbnail, 600),
      url: `/${x?.creator?.username}/${x?.slug}/${x?.serialIndex}`
    };
  });
  const listLovedImage = collectibles?.map((x) => {
    return {
      src: createAssetUrl(x?.collectible?.mediaThumbnail, 600),
      url: `/${x?.collectible?.creator?.username}/${x?.collectible?.slug}`
    };
  });

  const allImage = listImage?.concat(listLovedImage);
  const emptyImage = [
    {
      src: 'https://cdn.patrons.art/public/webapp/collector-default-image.png'
    }
  ];
  return (
    <Box className="collector-card">
      <Box className="collector-card__image">
        {allImage?.length > 0 ? (
          <ImageCollage images={allImage?.slice(0, 3)} />
        ) : (
          <ImageCollage images={emptyImage} />
        )}
      </Box>
      <Box className="collector-card__profile">
        <Link to={`/${collector?.username}`}>
          <Box className="collector-card__profile__user">
            <Avatar user={collector} size="xs" />
            <Box className="collector-card__profile__user__name">
              {collector?.firstName + ' ' + collector?.lastName}
            </Box>
          </Box>
        </Link>
        <Separator />
        <Box className="collector-card__profile__info">
          <Box className="collector-card__profile__info__detail">
            <Box className="collector-card__profile__info__detail__field">
              {t('p.pages.landing.artworksPurchased')}
            </Box>
            <Box className="collector-card__profile__info__detail__value">
              {collector?.totalPurchase}
            </Box>
          </Box>
          <Box className="collector-card__profile__info__detail">
            <Box className="collector-card__profile__info__detail__field">
              {t('p.pages.landing.collectionValue')}
            </Box>
            <Box className="collector-card__profile__info__detail__value">
              {currencyFormatter.format(Number(collector?.totalCollectionValues))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Collector;
