import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import ClampLines from 'react-clamp-lines';
import {
  Box,
  Button,
  CollectibleTimer,
  Heading,
  Link,
  Media,
  Text,
  OfficialBadge,
  CollectibleOwnerActions
} from '@components';
import { getNearestReleaseDate, isNewCollectibleSlider } from '@libs/utils/collectible';
import { collectibleTypeParser } from '@pages/collectible/fansinc/utils';
import { useAuth } from '@libs/contexts/auth';
import CollectibleSliderV2 from '../collectible-slider-item.v2';

const CollectibleSliderItem = ({
  collectible,
  isRelease,
  release,
  className,
  showButton,
  ...props
}) => {
  const { t } = useTranslation();
  const collectibleLink = isRelease
    ? `${release?.creator?.username}/${collectible?.slug}/${release?.serialIndex}/${release?.currentOwner?.username}`
    : `${collectible?.creator?.username}/${collectible?.slug}`;
  const releaseDate = getNearestReleaseDate(collectible?.releases || []);
  const { user } = useAuth();
  const newSlider = isNewCollectibleSlider();

  return (
    <>
      {newSlider ? (
        <CollectibleSliderV2
          collectible={collectible}
          isRelease={isRelease}
          release={release}
          className={className}
          showButton={showButton}
          {...props}
        />
      ) : (
        <Box className={cx('collectible-slider__item', className)} {...props}>
          <Box className="collectible-slide">
            <Box className="collectible-slide__media">
              <Link to={collectibleLink}>
                <Media collectible={collectible} showPlayer thumbnail />
              </Link>
            </Box>
            <Box className="collectible-slide__content">
              <Box className="collectible-slide__content__top">
                <OfficialBadge isImage className="mb-5" />
                <Link to={collectibleLink}>
                  <Heading as="h3" className="collectible-slide__content__name">
                    {collectible?.title}
                  </Heading>
                </Link>
                <Link to={collectibleLink}>
                  <Text className="collectible-slide__content__top__link">
                    fans.inc/{collectibleLink}
                  </Text>
                </Link>
                <Link to={collectibleLink}>
                  <Box className="collectible-slide__content__tag">
                    {collectible?.yearOfCreation && (
                      <Text className="collectible-slide__content__tag__item">
                        {collectible?.yearOfCreation}
                      </Text>
                    )}
                    <Text className="collectible-slide__content__tag__item">
                      {collectibleTypeParser[collectible?.category]}
                    </Text>
                  </Box>

                  <Box className="collectible-slide__content__description">
                    <ClampLines
                      moreText=""
                      text={collectible?.description}
                      lines={4}
                      ellipsis="..."
                    />
                  </Box>
                </Link>
              </Box>

              {showButton && (
                <Box className="collectible-slide__content__bottom">
                  <CollectibleTimer releaseDate={releaseDate} />
                  <Link to={collectibleLink}>
                    <Button className="collectible-slide__content__bottom__button">
                      {t('components.collectibleSlider.item.getItNow')}
                    </Button>
                  </Link>
                </Box>
              )}
              {!showButton && isRelease && release && (
                <Box className="collectible-slide__content__owner">
                  <CollectibleOwnerActions
                    collectible={collectible}
                    exchange={release}
                    isOwner={release?.currentOwner?.id === user?.id}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

CollectibleSliderItem.defaultProps = {
  isRelease: false
};

export default CollectibleSliderItem;
