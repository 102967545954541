import { useCallback, useState } from 'react';
import { currencyList } from './utlis';
import { Box, Text } from '@components';
import { useOutsider } from '@libs/utils/outsider';
import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5';

function Currency({ children, ...props }) {
  const [isOpen, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const { ref } = useOutsider(handleClose);
  const handleOpen = useCallback(
    (e) => {
      e.preventDefault();
      setOpen(!isOpen);
    },
    [isOpen]
  );
  const [selectedCurrency, setSelectedCurrency] = useState(currencyList[0]);
  const onChangeCurrency = (code) => {
    var data = currencyList.filter((currency) => {
      return currency.countryCode === code;
    });
    setSelectedCurrency(data[0]);
    handleClose();
  };

  return (
    <Box className="currency">
      <Box className="currency__selected" onClick={handleOpen}>
        <Box className="currency__selected-flag">{selectedCurrency.icon}</Box>
        <Text className="currency__selected-label">{selectedCurrency.label}</Text>
        <Box className="currency__selected-icon">
          {isOpen === true ? <IoChevronUpOutline /> : <IoChevronDownOutline />}
        </Box>
      </Box>
      {isOpen && (
        <Box className="currency__list">
          <ul ref={ref}>
            {currencyList.map((currency) => (
              <li
                key={currency.countryCode}
                onClick={(e) => {
                  onChangeCurrency(currency?.countryCode);
                }}
              >
                <Box className="currency__list__item">
                  <Box className="currency__list__item-icon">{currency?.icon}</Box>
                  {currency?.label}
                </Box>
              </li>
            ))}
          </ul>
        </Box>
      )}
    </Box>
  );
}

export default Currency;
