import { useCallback, useState } from 'react';

const createSorter = (sortBy) => {
  switch (sortBy) {
    case 'TITLE_ASC':
      return { direction: 'asc', field: 'name' };
    case 'TITLE_DESC':
      return { direction: 'desc', field: 'name' };
    default:
      return { direction: 'asc', field: 'name' };
  }
};

export const useCollectionParams = () => {
  const [name, setName] = useState();
  const [query, setQuery] = useState({
    filter: { id: { exists: true } },
    limit: 12,
    sort: createSorter('TITLE_ASC')
  });

  const onPaginate = (nextToken) => {
    setQuery({ ...query, nextToken });
  };

  const onChangeName = (data) => {
    if (data) {
      if (data === name) {
        setQuery({ ...query, filter: { ...query.filter, name: { matchPhrasePrefix: data } } });
      } else {
        setName(data);
        setQuery({
          ...query,
          nextToken: undefined,
          filter: { ...query.filter, name: { matchPhrasePrefix: data } }
        });
      }
    } else {
      setQuery({ ...query, nextToken: undefined, filter: undefined });
    }
  };

  const onChangeType = useCallback(
    (data) => {
      if (data) {
        setQuery({
          ...query,
          nextToken: undefined,
          filter: { ...query.filter, searchableCollectionType: { eq: data } }
        });
      } else {
        setQuery({
          ...query,
          nextToken: undefined,
          filter: { ...query.filter, searchableCollectionType: undefined }
        });
      }
    },
    [query]
  );

  const onSortBy = (sortBy) => {
    const sort = createSorter(sortBy);
    setQuery({ ...query, nextToken: null, sort });
  };

  const onReset = () => {
    setQuery({
      ...query,
      nextToken: undefined,
      sort: createSorter('TITLE_ASC'),
      filter: { ...query.filter, searchableCollectionType: undefined }
    });
  };

  return {
    query,
    onPaginate,
    onChangeName,
    onChangeType,
    onSortBy,
    onReset
  };
};
