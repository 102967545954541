import { useCookies } from 'react-cookie';

import config from '@/config';

export const useTokenManager = () => {
  const [cookies, setCookie, removeCookie] = useCookies([config.cookie.USER_ACTIVATION]);
  const tokenPrefix = cookies[config.cookie.TOKEN_PREFIX] || null;
  const tokenMaxAge = cookies[config.cookie.TOKEN_MAX_AGE] || null;

  const setTokenPrefix = (keyPrefix, username, rememberMe) => {
    const nextMonth = 3600 * 24 * 30;
    setCookie(config.cookie.TOKEN_PREFIX, `${keyPrefix}.${username}.accessToken`, {
      maxAge: nextMonth
    });

    const maxDays = rememberMe ? 30 : 1;
    const date = new Date();
    date.setDate(date.getDate() + maxDays);
    setCookie(config.cookie.TOKEN_MAX_AGE, date.getTime(), { maxAge: nextMonth });
  };

  const removeTokenPrefix = () => {
    removeCookie(config.cookie.TOKEN_PREFIX);
    removeCookie(config.cookie.TOKEN_MAX_AGE);
    removeCookie(config.cookie.SANDBOX_NOTIFICATION);
  };

  return {
    tokenPrefix,
    tokenMaxAge,
    setTokenPrefix,
    removeTokenPrefix
  };
};
