import { Box, Avatar, Link } from '@components';
import React from 'react';
import { formatDistanceToNowStrict } from 'date-fns';

export default function PatronsCollectibleArtist({ owner, title, time }) {
  return (
    <div>
      <Link to={`/${owner?.username}`}>
        <Box className="collectible__tab__marketplace__table__artists">
          <Box>
            <Avatar user={owner} size="xs-40" />
          </Box>
          <Box className="collectible__tab__marketplace__table__artists__detail">
            <Box className="collectible__tab__marketplace__table__artists__detail__name">
              {title}
            </Box>
            {time && (
              <Box className="text-sm text-secondary font-medium mt-1">
                {formatDistanceToNowStrict(new Date(time), { addSuffix: true })}
              </Box>
            )}
          </Box>
        </Box>
      </Link>
    </div>
  );
}
