import FansincUserLink from './fansinc';
import PatronsUserLink from './patrons';

const UserLink = ({
  user,
  children,
  showName,
  showTooltip,
  exchange,
  avatarSize = 'xs',
  showAddress,
  ...props
}) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return (
      <PatronsUserLink
        user={user}
        showName={showName}
        showTooltip={showTooltip}
        exchange={exchange}
        avatarSize={avatarSize}
        showAddress={showAddress}
        {...props}
      />
    );
  } else {
    return (
      <FansincUserLink
        user={user}
        showName={showName}
        showTooltip={showTooltip}
        exchange={exchange}
        avatarSize={avatarSize}
        showAddress={showAddress}
        {...props}
      />
    );
  }
};

export default UserLink;
