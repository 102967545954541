import React from 'react';

import { Box } from '@components';

import '@components/landing-gallery/style.css';

function LandingGallery({ children }) {
  return <Box className="landing-gallery">{children}</Box>;
}

LandingGallery.propTypes = {};

export default LandingGallery;
