import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import cx from 'classnames';
import {
  Box,
  Button,
  CollectibleSlider,
  Container,
  CreatorCard,
  CreatorCardGrid,
  Header,
  Footer,
  Link,
  ProfileVideo,
  ProfileHeader,
  Text,
  UserCard
} from '@components';

import { useExchangesByCurrentOwner } from '@libs/hooks/exchange';
import { useCollectiblesByOwnerFeatured } from '@libs/hooks/collectible';
import { useRelatedCreators } from '@libs/hooks/user';
import { useTranslation } from 'react-i18next';
import { useVideoCookies } from '@libs/utils/cookie';
import { createAssetUrl } from '@libs/utils/media';
import Release from '../components/release';
import CollectibleGroup from '../components/collectible-group';
import CollectibleCategory from '../components/collectible-category';

function ProfileMain({ profile, profileLoafing, title, description, refetchData, ...props }) {
  const { t } = useTranslation();
  const params = useParams();
  const username = params.username.toLowerCase();
  const { data: featuredCollectibles, loading: featuredCollectiblesLoading } =
    useCollectiblesByOwnerFeatured(profile, 5);
  const { data: featuredRelease, loading: featuredReleaseLoading } = useExchangesByCurrentOwner({
    ownerID: profile?.id
  });
  const { data: relatedCreators, loading: relatedCreatorsLoading } = useRelatedCreators(
    profile?.username
  );
  const [openVideo, setOpenVideo] = useState(false);
  const { video } = useVideoCookies(profile);

  useEffect(() => {
    if (video === false) {
      setOpenVideo(true);
    }
  }, [video]); // eslint-disable-line

  let collectible = <CollectibleGroup username={username} user={profile} />;
  if (profile) {
    if (profile?.verified !== 'TRUE') {
      collectible = <Release userID={profile?.id} />;
    } else {
      const kevinJamesUI = ['kevinjames', 'kevinjames2', 'jameskevin'].includes(username);
      if (kevinJamesUI) {
        collectible = (
          <>
            <CollectibleCategory username={username} category="SONG" />
            <CollectibleCategory username={username} category="SONG_LYRICS" />
            <CollectibleCategory username={username} category="PHOTOGRAPH" />
          </>
        );
      }
    }
  }

  const isVerified = profile?.verified === 'TRUE';

  return (
    <>
      <Header />
      <main className="main">
        <ProfileHeader user={profile} refetchData={refetchData} />
        {profile && (
          <ProfileVideo
            user={profile}
            autoplay="false"
            isOpen={openVideo}
            onClose={() => setOpenVideo(false)}
          />
        )}
        <Box>
          {isVerified && (
            <Container className={cx('overflow-hidden pt-14')}>
              <Box className="pb-4">
                <CollectibleSlider
                  collectibles={isVerified ? featuredCollectibles : featuredRelease}
                  loading={isVerified ? featuredCollectiblesLoading : featuredReleaseLoading}
                  isRelease={!isVerified}
                  showEmpty
                  title={isVerified ? t('profile.featuredCollectibles') : null}
                  showButton={isVerified ? true : false}
                />
              </Box>
            </Container>
          )}

          <Container size="xl" className="pt-14">
            {collectible}
          </Container>
        </Box>
        <Container className="pb-16">
          <UserCard user={profile} loading={profileLoafing} />
        </Container>
        <Box
          className={cx(
            'shadow-inner-section bg-gray-800',
            relatedCreators === false && !relatedCreatorsLoading ? 'hidden' : null
          )}
        >
          <Container className="profile__related">
            <div className="flex flex-row justify-between items-center mb-5">
              <Text className="text-default text-lg font-semibold">
                {t('profile.fansAlsoViewed')}
              </Text>
              <Link to={'/' + username + '/related'}>
                <Button outline>{t('viewAll')}</Button>
              </Link>
            </div>
            <CreatorCardGrid loading={relatedCreatorsLoading}>
              {relatedCreators &&
                relatedCreators?.map((creator) => (
                  <CreatorCard
                    key={creator.id}
                    user={creator}
                    className="creator-card--item"
                    loading={relatedCreatorsLoading.toString()}
                  />
                ))}
            </CreatorCardGrid>
          </Container>
        </Box>
      </main>
      <Footer />
      <Helmet>
        <link rel="canonical" href={'https://fans.inc' + window.location.pathname} />
        <title>{title()}</title>
        <meta name="URL" content={'https://fans.inc' + window.location.pathname} />
        <meta name="description" content={description()} />
        <meta property="og:title" content={title()} />
        <meta property="og:description" content={description()} />
        <meta property="og:url" content={'https://fans.inc' + window.location.pathname} />
        <meta
          property="og:image"
          content={
            profile?.media?.avatarUrl
              ? createAssetUrl(profile?.media?.avatarUrl, 400)
              : 'https://cdn.fans.inc/public/webapp/og-image.png'
          }
        />
      </Helmet>
    </>
  );
}

export default ProfileMain;
