import { Trans } from 'react-i18next';
import { format } from 'date-fns';

import { Price, SkeletonLoading, Button, Link } from '@components';
import { EXCHANGE_INTENTS } from '@libs/utils/exchange';

const tierMapper = {
  NUMBERED_EDITION: 'Limited Edition',
  DIGITAL_ORIGINAL: 'Digital Original'
};

export const getTitleAndDescription = ({
  exchangeIntent,
  exchange,
  status,
  timeoutExceeded,
  t,
  currencyCode,
  currencyFormatter,
  currentExchange,
  currentExchangeLoading
}) => {
  let title = t('congratulation.label') + ' ' + exchange?.currentOwner?.firstName;
  let description = '';
  let additionalDesc = '';
  const isPrimaryMarket = exchange?.creatorID === exchange?.currentOwnerID;
  const redirectUrl = isPrimaryMarket
    ? `/${exchange?.collectible?.creator?.username}/${exchange?.slug}`
    : `/${exchange?.collectible?.creator?.username}/${exchange?.slug}/${exchange?.serialIndex}`;
  const redirectText = isPrimaryMarket
    ? t('confirmation.backToEditions')
    : t('confirmation.backToUnit');

  if (status === 'fail') {
    title = t('confirmation.errors.title');
    description = t('confirmation.errors.paymentFailed');
    return { title, description, additionalDesc };
  }

  if (timeoutExceeded) {
    if (exchangeIntent === EXCHANGE_INTENTS.PRINT_ORDER_PURCHASE) {
      title = t('confirmation.errors.title');
      description = t('confirmation.errors.timeoutExceededOrder');
      return { title, description, additionalDesc };
    } else {
      title = t('confirmation.errors.title');
      description = t('confirmation.errors.timeoutExceeded');
      return { title, description, additionalDesc };
    }
  }

  if (!exchange) {
    return false;
  }

  switch (exchangeIntent) {
    case EXCHANGE_INTENTS.FIXED_PRICE_PURCHASE:
      title = t('confirmation.purchaseProcessed');
      description = (
        <Trans i18nKey="confirmation.fixedPriceDescription">
          {exchange?.collectible?.creator.firstName + ' ' + exchange?.collectible?.creator.lastName}
          {exchange?.collectible?.title}
          {`${tierMapper[exchange?.tierType]} #${exchange?.serialIndex}`}
        </Trans>
      );
      additionalDesc = t('confirmation.fixedPriceAdditionalDescription');
      break;

    case EXCHANGE_INTENTS.MAKE_OFFER: {
      title = t('confirmation.offerSubmitted');
      const offerPrice = currentExchangeLoading ? (
        <SkeletonLoading className="w-5 h-2 inline-block" />
      ) : (
        <Price amount={currentExchange?.offerPrice} showCurrency inline />
      );
      description = (
        <Trans i18nKey="confirmation.makeAnOfferDescription">
          {offerPrice}
          {exchange?.collectible?.title}
          {`${tierMapper[exchange?.tierType]} #${exchange?.serialIndex}`}
        </Trans>
      );
      additionalDesc = currentExchangeLoading ? null : (
        <>
          <Trans i18nKey="confirmation.makeAnOfferAdditionalDescription">
            {format(new Date(currentExchange?.expiryDate), 'MMMM d, yyyy')}
          </Trans>
          <br />
          <Link style={{ textDecoration: 'none' }} to={redirectUrl}>
            <Button className="w-full" style={{ marginTop: 22 }}>
              {redirectText}
            </Button>
          </Link>
        </>
      );
      break;
    }

    case EXCHANGE_INTENTS.OFFER_BITPAY_PAYMENT_MADE: {
      title = t('confirmation.offerPayment');
      const offerPaymentPrice = <Price amount={exchange?.offerPrice} showCurrency inline />;
      description = (
        <Trans i18nKey="confirmation.makeAnOfferDescription">
          {offerPaymentPrice}
          {exchange?.collectible?.title}
          {`${tierMapper[exchange?.tierType]} #${exchange?.serialIndex}`}
        </Trans>
      );
      additionalDesc = t('confirmation.fixedPriceAdditionalDescription');
      break;
    }

    case EXCHANGE_INTENTS.PLACE_BID: {
      title = t('confirmation.bidConfirmed');
      const bidPrice = currentExchangeLoading ? (
        <SkeletonLoading className="w-5 h-2 inline-block" />
      ) : (
        <Price amount={currentExchange?.bidPrice} showCurrency inline />
      );
      description = (
        <Trans i18nKey="confirmation.auctionDescription">
          {bidPrice}
          {exchange?.collectible?.title}
          {`${tierMapper[exchange?.tierType]} #${exchange?.serialIndex}`}
        </Trans>
      );

      const auctionEndDate = exchange?.parentExchange?.expiryDate;
      if (auctionEndDate) {
        additionalDesc = (
          <Trans i18nKey="confirmation.auctionAdditionalDescription">
            {format(new Date(auctionEndDate), 'MMMM d, yyyy')}
          </Trans>
        );
      }

      break;
    }

    case EXCHANGE_INTENTS.PRINT_ORDER_PURCHASE: {
      title = t('confirmation.paymentSuccess');
      description = (
        <Trans i18nKey="confirmation.orderPrintDescription">
          {exchange?.collectible?.creator?.displayName ||
            exchange?.collectible?.creator?.firstName +
              ' ' +
              exchange?.collectible?.creator?.lastName}
          {exchange?.collectible?.title}
          {`${tierMapper[exchange?.tierType]}`}
          {exchange?.serialIndex?.toString()}
        </Trans>
      );
      additionalDesc = t('confirmation.orderPrintAdditionalDescription');
      break;
    }

    case EXCHANGE_INTENTS.ACCEPT_OFFER:
      title = t('confirmation.acceptOffer.title');
      description = (
        <Trans i18nKey="confirmation.acceptOffer.description">
          {exchange?.collectible?.title}
          {tierMapper[exchange?.tierType]}
          {exchange?.serialIndex?.toString()}
          {<Price amount={exchange?.offerPrice} inline />}
        </Trans>
      );
      break;

    default:
      description = (
        <Trans i18nKey="confirmation.fixedPriceDescription">
          {exchange?.collectible?.creator.firstName}
          {exchange?.collectible?.title}
          {`${tierMapper[exchange?.tierType]} #${exchange?.serialIndex}`}
        </Trans>
      );
      break;
  }

  return { title, description, additionalDesc };
};
