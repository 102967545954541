import { userPublicFragment } from '@libs/fragments/user';
import { exchangePublicFragment } from '@libs/fragments/exchange';

export const searchExchanges = /* GraphQL */ `
  query SearchExchanges(
    $filter: SearchableExchangeFilterInput
    $sort: SearchableExchangeSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchExchanges(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        ...ExchangePublic
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          streamExternal
          streamUrl {
            spotify
            appleMusic
            youtube
          }
          releasedAt
          createdAt
          updatedAt
          lastSoldAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableHighestBidPrice
          searchableGenre
          owner
        }
        previousExchange {
          ...ExchangePublic
        }
        parentExchange {
          ...ExchangePublic
        }
        creator {
          ...UserPublicProfile
          creatorCategory
          fee {
            salesFee
            royaltyFee
          }
        }
        currentOwner {
          ...UserPublicProfile
        }
        patron {
          ...UserPublicProfile
        }
      }
      total
    }
  }

  ${userPublicFragment}
  ${exchangePublicFragment}
`;

export const searchExchangesLite = /* GraphQL */ `
  query SearchExchangesLite(
    $filter: SearchableExchangeFilterInput
    $sort: SearchableExchangeSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchExchanges(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        ...ExchangePublic
        collectibleID
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          streamExternal
          streamUrl {
            spotify
            appleMusic
            youtube
          }
          releasedAt
          createdAt
          updatedAt
          lastSoldAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableHighestBidPrice
          searchableGenre
          owner
          creator {
            ...UserPublicProfile
          }
        }
        owner
        creator {
          ...UserPublicProfile
          creatorCategory
          fee {
            salesFee
            royaltyFee
          }
        }
        currentOwner {
          ...UserPublicProfile
        }
      }
      total
      nextToken
    }
  }

  ${userPublicFragment}
  ${exchangePublicFragment}
`;

export const searchExchangesCheckout = /* GraphQL */ `
  query SearchExchangesCheckout(
    $filter: SearchableExchangeFilterInput
    $sort: SearchableExchangeSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchExchanges(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        ...ExchangePublic
        collectible {
          id
          accountID
          userID
          type
          collectionID
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          streamExternal
          streamUrl {
            spotify
            appleMusic
            youtube
          }
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableHighestBidPrice
          searchableGenre
          owner
          creator {
            username
          }
        }
        previousExchange {
          ...ExchangePublic
        }
        parentExchange {
          ...ExchangePublic
        }
        creator {
          ...UserPublicProfile
        }
        currentOwner {
          ...UserPublicProfile
        }
      }
      total
    }
  }

  ${userPublicFragment}
  ${exchangePublicFragment}
`;

export const searchExchangesCount = /* GraphQL */ `
  query SearchExchangesLite(
    $filter: SearchableExchangeFilterInput
    $sort: SearchableExchangeSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchExchanges(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      total
    }
  }
`;

export const searchExchangesHistory = /* GraphQL */ `
  query SearchExchangesLite(
    $filter: SearchableExchangeFilterInput
    $sort: SearchableExchangeSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchExchanges(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        ...ExchangePublic
        creator {
          fee {
            salesFee
            royaltyFee
          }
        }
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          status
          yearOfCreation
          perksOriginal {
            fanClubAccess
            limitedPerks
            lifetimePerks
          }
          perksNumbered {
            fanClubAccess
            limitedPerks
            lifetimePerks
          }
          streamExternal
          streamUrl {
            spotify
            appleMusic
            youtube
          }
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          owner
        }
        patron {
          ...UserPublicProfile
        }
      }
    }
  }

  ${userPublicFragment}
  ${exchangePublicFragment}
`;
