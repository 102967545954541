import stylesA4Landscape from './a4';
import stylesA2Landscape from './a2';
import stylesA1Landscape from './a1';

const stylesLandscape = {
  A_FOUR: stylesA4Landscape,
  A_TWO: stylesA2Landscape,
  A_ONE: stylesA1Landscape
};

export default stylesLandscape;
