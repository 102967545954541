import { Helmet } from 'react-helmet';

const CustomHelmet = () => {
  return (
    <Helmet encodeSpecialCharacters={true} titleTemplate="%s - Patrons.Art">
      <meta
        name="description"
        content="The worlds' greatest art from leading artists and galleries becoming available to collect in limited edition digital format..."
      />
      <meta property="og:type" content="article" />
      <link rel="apple-touch-icon" href="https://cdn.patrons.art/public/webapp/favicon.png" />
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href="https://cdn.patrons.art/public/webapp/favicon.png"
      />

      <script type="application/ld+json">{`
        {
            "@context": "http://schema.org"
        }
    `}</script>
    </Helmet>
  );
};

export default CustomHelmet;
