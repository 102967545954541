import { useState, useEffect } from 'react';

import gql from '@libs/utils/gql';
import { exchangeByID } from '@libs/custom-queries/exchange';

export const usePollExchange = (exchangeID, interval = 5000, timeout = 20000) => {
  const [loading, setLoading] = useState(true);
  const [exchange, setExchange] = useState(null);
  const [totalPollingTime, setTotalPollingTime] = useState(0);
  const [timeoutExceeded, setTimeoutExceeded] = useState(false);

  const getExchangeData = async (id) => {
    try {
      setLoading(true);
      const { data: res } = await gql(exchangeByID, { id });
      if (res?.getExchange) {
        setExchange(res.getExchange);
        setLoading(false);
      } else {
        setExchange(null);
        setTotalPollingTime((prev) => prev + interval);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    let intervalID = null;
    if (exchangeID) {
      intervalID = setInterval(() => {
        if (exchange) {
          clearInterval(intervalID);
        } else {
          if (totalPollingTime > timeout) {
            clearInterval(intervalID);
            setTimeoutExceeded(true);
            return;
          }
          getExchangeData(exchangeID);
        }
      }, interval);
    }
    return () => {
      intervalID && clearInterval(intervalID);
    };
    // eslint-disable-next-line
  }, [exchangeID, exchange, totalPollingTime]);

  return { exchange, timeoutExceeded, loading, getExchangeData };
};
