const TableCell = ({ as, className, children, ...props }) => {
  const Tag = as === 'header' ? 'th' : 'td';

  return (
    <Tag className={className} {...props}>
      {children}
    </Tag>
  );
};

TableCell.defaultProps = {
  as: 'data'
};

export default TableCell;
