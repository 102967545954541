export const isObjectNull = (obj) =>
  Object.values(obj).every((value) => {
    if (value === null || value === undefined || value === '') {
      return true;
    }
    return false;
  });

export const checkCollectibleObjectType = (data) => {
  if ('releases' in data) {
    return 'COLLECTIBLE';
  }

  if ('isSold' in data) {
    return 'EXCHANGE';
  }

  return 'RELEASE';
};
