export const EXCHANGE_INTENTS = {
  FIXED_PRICE_PURCHASE: 'FIXED_PRICE_PURCHASE',
  MAKE_OFFER: 'MAKE_OFFER',
  OFFER_BITPAY_PAYMENT_MADE: 'OFFER_BITPAY_PAYMENT_MADE',
  PLACE_BID: 'PLACE_BID',
  ACCEPT_OFFER: 'ACCEPT_OFFER',
  CANCEL_OFFER: 'CANCEL_OFFER',
  REJECT_OFFER: 'REJECT_OFFER',
  ACCEPT_BID: 'ACCEPT_BID',
  CANCEL_BID: 'CANCEL_BID',
  PRINT_ORDER_PURCHASE: 'PRINT_ORDER_PURCHASE'
};

export const EXCHANGE_TYPES = {
  LISTING: 'LISTING',
  LISTING_IN_PROCESS: 'LISTING_IN_PROCESS',
  OFFER: 'OFFER',
  OFFER_IN_PROCESS: 'OFFER_IN_PROCESS',
  BID: 'BID'
};

export const LISTING_TYPES = {
  FIXED_PRICE: 'FIXED_PRICE',
  AUCTION: 'AUCTION',
  MAKE_AN_OFFER: 'MAKE_AN_OFFER',
  NFT: 'NFT',
  NOT_A_LISTING: 'NOT_A_LISTING'
};

export const STATUS_TYPES = {
  SOLD: 'SOLD',
  MINTING: 'MINTING',
  TRANSFERRED_TO_NFT: 'TRANSFERRED_TO_NFT',
  VOIDED: 'VOIDED',
  ACTIVE: 'ACTIVE',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED',
  EXPIRED: 'EXPIRED',
  NFT_TRANSFERRED: 'NFT_TRANSFERRED',
  NFT_SOLD: 'NFT_SOLD'
};

export const TIER_TYPES = {
  DIGITAL_ORIGINAL: 'DIGITAL_ORIGINAL',
  NUMBERED_EDITION: 'NUMBERED_EDITION'
};

export const LISTING_TYPES_PURCHASE = {
  FIXED_PRICE: 'purchaseFixedPrice',
  AUCTION: 'purchaseAuction',
  MAKE_AN_OFFER: 'purchaseOffer'
};

export const LISTING_TYPES_PARSER = {
  FIXED_PRICE: 'fixedPrice',
  AUCTION: 'auction',
  MAKE_AN_OFFER: 'makeAnOffer',
  NOT_A_LISTING: 'notListing'
};

export const mapListingTypeToExchangeIntent = {
  [LISTING_TYPES.FIXED_PRICE]: EXCHANGE_INTENTS.FIXED_PRICE_PURCHASE,
  [LISTING_TYPES.MAKE_AN_OFFER]: EXCHANGE_INTENTS.MAKE_OFFER,
  [LISTING_TYPES.AUCTION]: EXCHANGE_INTENTS.PLACE_BID
};

export const NFT_MARKETPLACES = {
  OPENSEA: 'OPENSEA',
  OPENSEA_TESTNETS_MUBAI: 'OPENSEA_TESTNETS_MUBAI'
};

export const getNftUrl = ({ exchange, marketplace }) => {
  const { listingType, nftContractAddress, nftTokenIDString } = exchange;
  if (listingType !== LISTING_TYPES.NFT || !Object.keys(NFT_MARKETPLACES).includes(marketplace)) {
    return null;
  }
  const marketPlaceUrlMapper = {
    [NFT_MARKETPLACES.OPENSEA_TESTNETS_MUBAI]: `https://testnets.opensea.io/assets/mumbai/${nftContractAddress}/${nftTokenIDString}`,
    [NFT_MARKETPLACES.OPENSEA]: `https://opensea.io/assets/${nftContractAddress}/${nftTokenIDString}`
  };

  return marketPlaceUrlMapper[marketplace];
};

export const generateNFTTokenID = ({ exchange }) => {
  const { collectibleID, serialIndex } = exchange;

  // eslint-disable-next-line no-undef
  const collectibleIDPosition = BigInt(2 ** (256 - 128));
  // eslint-disable-next-line no-undef
  const collectibleIDInt = BigInt('0x' + collectibleID.split('-').join(''));
  // eslint-disable-next-line no-undef
  const tokenID = collectibleIDInt * collectibleIDPosition + BigInt(serialIndex);

  return tokenID.toString();
};
