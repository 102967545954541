import cx from 'classnames';
import { useState, useEffect, useMemo } from 'react';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import { Box, Text } from '@components';

const Countdown = ({
  time,
  afterSaleEnd,
  className,
  showSeconds,
  showDay = true,
  showOnlyDay = false,
  showOnlyDayOffset = 0,
  showIndicator = true,
  showThreeDigits = false,
  showSeparator = false,
  refetchTierTiles,
  label,
  ...props
}) => {
  const ONE_DAY = 60 * 60 * 24;
  const ONE_HOUR = 60 * 60;
  const ONE_MINUTE = 60;
  const [currentTime, setCurrentTime] = useState(new Date().getTime());
  const [currentInterval, setCurrentInterval] = useState();

  const diffInSeconds = afterSaleEnd
    ? differenceInSeconds(currentTime, new Date(time))
    : differenceInSeconds(new Date(time), currentTime);
  const getCoundown = () => {
    if (diffInSeconds < 1) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      };
    }
    const days = Math.floor(diffInSeconds / ONE_DAY);
    const hours = Math.floor((diffInSeconds - days * ONE_DAY) / ONE_HOUR);
    const minutes = Math.floor((diffInSeconds - days * ONE_DAY - hours * ONE_HOUR) / ONE_MINUTE);
    const seconds = diffInSeconds - days * ONE_DAY - hours * ONE_HOUR - minutes * ONE_MINUTE;
    return {
      days,
      hours,
      minutes,
      seconds
    };
  };

  const countdown = useMemo(getCoundown, [currentTime]); // eslint-disable-line

  useEffect(() => {
    const diffInSeconds = differenceInSeconds(new Date(time), currentTime);
    if (refetchTierTiles && currentInterval && diffInSeconds < 1) {
      setCurrentInterval();
      refetchTierTiles();
      clearInterval(currentInterval);
    }
  }, [currentTime, currentInterval, time, refetchTierTiles]);

  useEffect(() => {
    let intervalID = setInterval(() => {
      const now = new Date().getTime();
      setCurrentTime(now);
    }, 1000);

    setCurrentInterval(intervalID);

    return () => clearInterval(intervalID);
  }, []);

  const minTwoDigits = (n) => {
    return (n < 10 ? '0' : '') + n;
  };

  return (
    <>
      {afterSaleEnd ? (
        <Box className={cx('countdown', className)} {...props}>
          <Text as="span" className="days">
            {countdown.days < 1
              ? `${countdown.hours} Hour${countdown.hours > 1 && 's'} ago`
              : `${countdown.days} Day${countdown.days !== 1 && 's'} ago`}
          </Text>
        </Box>
      ) : (
        <Box className={cx('countdown', className)} {...props}>
          {showOnlyDay && countdown.days > showOnlyDayOffset ? (
            <Text as="span" className="days">
              {countdown.days} Day{countdown.days !== 1 && 's'} left
            </Text>
          ) : (
            <>
              {label && label}
              {label && showSeparator && <Text as="span" className="countdown-separator"></Text>}
              {showDay && (
                <Text as="span" className="days">
                  {countdown.days}
                  {showIndicator && (showThreeDigits ? ' Day' : 'd')}
                </Text>
              )}
              {showDay && showSeparator && <Text as="span" className="countdown-separator"></Text>}
              <Text as="span" className="hours">
                {minTwoDigits(countdown.hours)}
                {showIndicator && (showThreeDigits ? ' Hour' : 'h')}
              </Text>
              {showSeparator && <Text as="span" className="countdown-separator"></Text>}
              <Text as="span" className="minutes">
                {minTwoDigits(countdown.minutes)}
                {showIndicator && (showThreeDigits ? ' Min' : 'm')}
              </Text>
              {showSeconds && (
                <>
                  {showSeparator && <Text as="span" className="countdown-separator"></Text>}
                  <Text as="span" className="seconds">
                    {minTwoDigits(countdown.seconds)}
                    {showIndicator && (showThreeDigits ? ' Sec' : 's')}
                  </Text>
                </>
              )}
            </>
          )}
        </Box>
      )}
    </>
  );
};

Countdown.defaultProps = {
  showSeconds: true
};

export default Countdown;
