import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import {
  Box,
  Table,
  Text,
  SkeletonLoading,
  Countdays,
  Price,
  Link,
  SerialItem,
  UserLink,
  AuctionCountdown,
  Button,
  OfferModal,
  EmptyBox
} from '@components';
import ExchangesStats from '@components/exchange-table/patrons/exchanges-stats';
import GetHighestBid from '@components/exchange-table/patrons/getHighestBid';
import { LISTING_TYPES, STATUS_TYPES, EXCHANGE_INTENTS } from '@libs/utils/exchange';
import { createExchangeURL } from '@libs/utils/release';
import useToast from '@libs/utils/toast';

import {
  getFromRow,
  getToRow,
  getCollectibleNameRow,
  getActionsRow
} from '@components/exchange-table/patrons/utils';

const EmptyExchangesTable = ({ listingTranslationKey, isUserPage }) => {
  const { t } = useTranslation();
  return (
    <div className="empty-exchanges-table">
      <div className="flex flex-col justify-center items-center">
        <EmptyBox
          title={t(`p.components.exchangesTable.no${listingTranslationKey}User`)}
          description={
            !isUserPage && t(`p.components.exchangesTable.no${listingTranslationKey}Desc`)
          }
        />
      </div>
    </div>
  );
};

const LoadingExchangesTable = () => (
  <div className="my-5">
    <SkeletonLoading />
    <div className="my-2" />
    <SkeletonLoading />
  </div>
);

const formatDate = (date) => {
  const inputDate = new Date(date);
  const yyyy = inputDate.getFullYear();
  let mm = inputDate.getMonth() + 1;
  let dd = inputDate.getDate();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  const formattedDate = dd + '/' + mm + '/' + yyyy;
  return formattedDate;
};

function ExchangesTable({
  exchanges,
  listingType,
  isLoading,
  tableType,
  className,
  isActionTable,
  actions,
  isExchangeStatsVisible,
  isUserPage = false,
  refetch,
  isOwner,
  ...props
}) {
  const toast = useToast();
  const { t } = useTranslation();
  const [isLoadingActions, setIsLoadingActions] = useState({});
  const [offerModalData, setOfferModalData] = useState({
    open: false,
    exchange: null,
    intent: null
  });

  let exchangeCols = [];
  let listExchange = [];
  let exchangeRows = [];
  let disabledRows = [];

  const onOfferActionFinish = async () => {
    const selectedOffer = offerModalData.exchange;
    switch (offerModalData.intent) {
      case EXCHANGE_INTENTS.REJECT_OFFER:
        refetch && refetch();
        break;

      case EXCHANGE_INTENTS.ACCEPT_OFFER:
        const urlData = {
          eid: selectedOffer.id,
          eint: EXCHANGE_INTENTS.ACCEPT_OFFER
        };
        const searchParams = new URLSearchParams(urlData);
        if (selectedOffer.paymentProvider === 'CHECKOUT') {
          window.location.assign(
            `/confirmation/${selectedOffer.slug}/${selectedOffer.id}/?${searchParams.toString()}`
          );
        } else {
          toast(t('p.components.exchangesTable.acceptOfferSuccess'), 'success');
        }
        break;

      default:
        break;
    }
  };

  switch (tableType) {
    case 'saleHistory':
      {
        listExchange = exchanges?.filter((x) => x?.creator?.id !== x?.currentOwner?.id);
        exchangeCols = [
          [t('p.components.exchangesTable.from')],
          [t('p.components.exchangesTable.to')],
          [t('p.components.exchangesTable.date')],
          [t(`p.components.exchangesTable.amount`)]
        ];
        exchangeRows = listExchange?.map((exchange, exchangeIndex) => [
          [getFromRow(exchange, tableType)],
          [getToRow(exchange, tableType)],
          [
            <Text key={exchange.id + exchangeIndex}>
              <Countdays time={exchange?.createdAt} showHour showMinute showSecond />
            </Text>
          ],
          [
            <Price
              key={exchange.id + exchangeIndex}
              amount={exchange.startingPrice}
              exchange={exchange}
            />
          ]
        ]);
      }
      break;
    case 'offerHistory':
      {
        exchangeCols = [
          [t('p.components.exchangesTable.from')],
          [t('p.components.exchangesTable.date')],
          [t('p.components.exchangesTable.expiration')],
          [t(`p.components.exchangesTable.amount`)],
          [t(`p.components.exchangesTable.action`)]
        ];
        listExchange = exchanges;
        exchangeRows = listExchange?.map((exchange, exchangeIndex) => {
          if (exchange.status !== STATUS_TYPES.ACTIVE) {
            disabledRows.push(exchangeIndex);
          }
          return [
            [
              <div key="user-link-patron">
                <UserLink
                  user={exchange?.patron}
                  showTooltip={false}
                  avatarSize="xs-40"
                  exchange={exchange}
                />
              </div>
            ],
            [
              <Box key={exchange.id + exchangeIndex}>
                <Countdays time={exchange?.createdAt} showHour showMinute showSecond />
              </Box>
            ],
            [
              <Countdays
                key={exchange.id + exchangeIndex}
                isRevert
                time={exchange?.expiryDate}
                showHour
                showMinute
                showSecond
                showExpired
              />
            ],
            [
              <Price
                key={exchange.id + exchangeIndex}
                amount={exchange.offerPrice}
                exchange={exchange}
              />
            ],
            [
              <>
                {exchange.status === STATUS_TYPES.REJECTED &&
                  t('p.components.exchangesTable.rejected')}
                {exchange.status === STATUS_TYPES.ACTIVE && isOwner && (
                  <>
                    <Button
                      key={exchange.id + exchangeIndex}
                      className="mr-3"
                      onClick={() =>
                        setOfferModalData({
                          open: true,
                          exchange,
                          intent: EXCHANGE_INTENTS.ACCEPT_OFFER
                        })
                      }
                    >
                      {t('p.components.exchangesTable.buttons.accept')}
                    </Button>
                    <Button
                      key={exchange.id + exchangeIndex}
                      whiteOutline={true}
                      onClick={() =>
                        setOfferModalData({
                          open: true,
                          exchange,
                          intent: EXCHANGE_INTENTS.REJECT_OFFER
                        })
                      }
                    >
                      {t('p.components.exchangesTable.buttons.reject')}
                    </Button>
                  </>
                )}
                {exchange.status === STATUS_TYPES.CANCELLED &&
                  t('p.components.exchangesTable.cancelled')}
              </>
            ]
          ];
        });
      }
      break;
    case 'bidHistory':
      {
        exchangeCols = [
          [t('p.components.exchangesTable.edition')],
          [t('p.components.exchangesTable.date')],
          [t('p.components.exchangesTable.from')],
          [t(`p.components.exchangesTable.amount`)]
        ];
        listExchange = exchanges;
        exchangeRows = listExchange?.map((exchange, exchangeIndex) => [
          [
            <Link key={exchange.id + exchangeIndex} to={createExchangeURL(exchange)}>
              <div className="flex items-center justify-between">
                <SerialItem
                  badge={exchange?.tierType === 'NUMBERED_EDITION' ? false : true}
                  listing={exchange}
                  key={exchange?.id}
                />
              </div>
            </Link>
          ],
          [
            <Box key={exchange.id + exchangeIndex}>
              <Countdays time={exchange?.createdAt} showHour showMinute showSecond />
            </Box>
          ],
          [
            <div key="user-link-patron">
              <UserLink user={exchange?.patron} showTooltip={false} exchange={exchange} />
            </div>
          ],
          [
            <Price
              key={exchange.id + exchangeIndex}
              amount={exchange.bidPrice}
              exchange={exchange}
            />
          ]
        ]);
      }
      break;
    case 'bidMade':
      {
        exchangeCols = [
          [t('p.components.exchangesTable.collectibleName')],
          [t('p.components.exchangesTable.auctionEndDate')],
          [t('p.components.exchangesTable.currentHighestBidder')],
          [t('p.components.exchangesTable.currentHighestBid')],
          [t('p.components.exchangesTable.maximumBid')]
        ];
        listExchange = exchanges;
        exchangeRows = listExchange?.map((exchange, exchangeIndex) => [
          [[getCollectibleNameRow(exchange)]],
          [<AuctionCountdown key={exchange.id + exchangeIndex} exchange={exchange} />],
          [
            <GetHighestBid
              key={exchange.id + exchangeIndex}
              parentExchangeID={exchange?.parentExchangeID}
              bidder
            />
          ],
          [
            <GetHighestBid
              key={exchange.id + exchangeIndex}
              parentExchangeID={exchange?.parentExchangeID}
            />
          ],
          [<Price key={exchange.id + exchangeIndex} amount={exchange?.maxBidPrice} />]
        ]);
      }
      break;
    case 'offerReceived':
      {
        exchangeCols = [
          [t('p.components.exchangesTable.collectibleName')],
          [t('p.components.exchangesTable.validTo')],
          [t('p.components.exchangesTable.from')],
          [t('p.components.exchangesTable.authorizationStatus')],
          [t(`p.components.exchangesTable.amount`)],
          [t(`p.components.exchangesTable.action`)]
        ];
        listExchange = exchanges;
        exchangeRows = listExchange?.map((exchange, exchangeIndex) => [
          [[getCollectibleNameRow(exchange)]],
          <Text key={exchange.id + exchangeIndex}>{formatDate(exchange?.expiryDate)}</Text>,
          [getFromRow(exchange, tableType)],
          [
            exchange?.paymentProvider !== 'BITPAY' ? (
              <Text>{t('p.components.exchangesTable.authorized')}</Text>
            ) : (
              <Text>{t('p.components.exchangesTable.unauthorized')}</Text>
            )
          ],
          [<Price key={exchange.id + exchangeIndex} amount={exchange?.offerPrice} />],
          getActionsRow({
            exchange,
            actions,
            exchangeIndex,
            isLoadingActions,
            setIsLoadingActions
          })
        ]);
      }
      break;
    case 'offerMade':
      {
        exchangeCols = [
          [t('p.components.exchangesTable.collectibleName')],
          [t('p.components.exchangesTable.validTo')],
          [t('p.components.exchangesTable.to')],
          [t('p.components.exchangesTable.authorizationStatus')],
          [t(`p.components.exchangesTable.amount`)],
          [t(`p.components.exchangesTable.action`)]
        ];
        listExchange = exchanges;
        exchangeRows = listExchange?.map((exchange, exchangeIndex) => [
          [[getCollectibleNameRow(exchange)]],
          <Text key={exchange.id + exchangeIndex}>{formatDate(exchange?.expiryDate)}</Text>,
          [getToRow(exchange, tableType)],
          [
            exchange?.paymentProvider !== 'BITPAY' ? (
              <Text>{t('p.components.exchangesTable.authorized')}</Text>
            ) : (
              <Text>{t('p.components.exchangesTable.unauthorized')}</Text>
            )
          ],
          [<Price key={exchange.id + exchangeIndex} amount={exchange?.offerPrice} />],
          getActionsRow({
            exchange,
            actions,
            exchangeIndex,
            isLoadingActions,
            setIsLoadingActions
          })
        ]);
      }
      break;
    default:
      break;
  }

  const listingTranslationKey = {
    [LISTING_TYPES.MAKE_AN_OFFER]: 'Offer',
    [LISTING_TYPES.AUCTION]: 'Bid',
    SALE: 'Sale',
    PURCHASE: 'Purchase',
    SELLING: 'Selling',
    PENDING: 'Pending'
  };

  return (
    <Box className={cx('exchange exchange-history-table text-secondary', className)} {...props}>
      {exchanges.length > 1 && isExchangeStatsVisible ? (
        <ExchangesStats
          listExchange={listExchange}
          listingTranslationKey={listingTranslationKey[listingType]}
          tableType={tableType}
        />
      ) : null}

      <Box className="exchange-history-table__group">
        {exchangeRows &&
          !isLoading &&
          (exchangeRows.length === 0 ? (
            <EmptyExchangesTable
              listingTranslationKey={listingTranslationKey[listingType]}
              isUserPage={isUserPage}
            />
          ) : (
            <Table cols={exchangeCols} rows={exchangeRows} disabledRows={disabledRows} />
          ))}
        {isLoading && <LoadingExchangesTable />}
      </Box>

      {offerModalData.exchange && tableType === 'offerHistory' && (
        <OfferModal
          exchangeOffer={offerModalData.exchange}
          exchangeIntent={offerModalData.intent}
          isOpen={offerModalData.open}
          setIsOpen={(e) => setOfferModalData({ ...offerModalData, open: e })}
          onFinish={() => {
            onOfferActionFinish();
            setOfferModalData({ ...offerModalData, open: false });
          }}
        />
      )}
    </Box>
  );
}

export default ExchangesTable;
