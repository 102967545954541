import { StyleSheet } from '@react-pdf/renderer';

const stylesA4Landscape = StyleSheet.create({
  pageWrapper: {
    color: '#333333',
    backgroundColor: 'white'
  },
  page: {
    width: '100%',
    height: '100%'
  },
  pageContent: {
    paddingTop: 40,
    paddingHorizontal: '50px',
    backgroundColor: 'white',
    height: '100%',
    width: '100%'
  },
  contentWrapper: {
    opacity: '1'
  },
  imageWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 17
  },
  firstCol: {
    // flexGrow: '2'
    flex: 1
  },
  secondCol: {
    // flexGrow: '1'
    display: 'flex',
    marginLeft: '50px',
    alignItems: 'center'
  },
  logo: {
    width: '49.13px',
    height: '7.61px',
    textAlign: 'center'
  },
  tier: {
    textTransform: 'uppercase',
    fontSize: 10,
    fontWeight: 500
  },
  image: {
    maxWidth: 692,
    maxHeight: 475.37,
    objectFit: 'contain'
  },
  title: {
    fontSize: 22,
    textTransform: 'uppercase',
    fontWeight: 700,
    fontFamily: 'Avenir'
  },
  sub: {
    textTransform: 'uppercase',
    fontSize: 10,
    fontWeight: 500,
    fontFamily: 'AvenirMedium'
  },
  edition: {
    fontSize: 25,
    position: 'absolute',
    top: '25',
    left: '23'
  },
  qrCode: {
    width: 50,
    height: 50,
    objectFit: 'contain',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    marginBottom: 5
  },
  url: {
    position: 'absolute',
    textTransform: 'uppercase',
    fontSize: 9,
    fontWeight: 400,
    transform: 'rotate(90deg)',
    transformOrigin: '0 0',
    left: 0,
    top: 0,
    paddingTop: 5,
    fontFamily: 'Avenir'
  }
});

export default stylesA4Landscape;
