import { useState, useEffect, useCallback } from 'react';

import gql from '@libs/utils/gql';
import useToast from '@libs/utils/toast';
import { searchExchangesLite } from '@libs/custom-queries/exchange';
import { EXCHANGE_TYPES, STATUS_TYPES } from '@libs/utils/exchange';

export const useSecondaryMarketplace = ({ collectibleID, authorID }) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [nextToken, setNextToken] = useState();
  const [total, setTotal] = useState();
  const [serialIndex, setSerialIndex] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [lastCollectibleID, setLastCollectibleID] = useState();

  const fetchData = useCallback(
    async (collectibleID, authorID, serialIndex) => {
      setLoading(true);
      if (lastCollectibleID !== collectibleID) {
        setSerialIndex(null);
      }

      try {
        const params = {
          filter: {
            collectibleID: { eq: collectibleID },
            currentOwnerID: { ne: authorID },
            searchableIsSold: { eq: 'FALSE' },
            searchableStatus: { ne: STATUS_TYPES.TRANSFERRED_TO_NFT },
            searchableExchangeType: { eq: EXCHANGE_TYPES.LISTING }
          },
          limit: 10,
          sort: {
            field: 'serialIndex',
            direction: 'asc'
          }
        };

        if (serialIndex) {
          params['filter']['serialIndex'] = { eq: `${serialIndex}` };
        }

        if (nextToken) {
          params['nextToken'] = currentPage;
        }

        const { data: res } = await gql(searchExchangesLite, params);
        if (res?.searchExchanges?.items?.length > 0) {
          if (params['nextToken'] && !serialIndex) {
            setData(data.concat(res?.searchExchanges.items));
          } else {
            setData(res?.searchExchanges.items);
          }
        }
        setTotal(res?.searchExchanges?.total);

        if (res?.searchExchanges?.nextToken) {
          setNextToken(res?.searchExchanges.nextToken);
        }
      } catch (error) {
        console.error(error);
        const errorMessage = error?.errors[0]?.message;
        toast(errorMessage, 'error');
      } finally {
        setLastCollectibleID(collectibleID);
        setLoading(false);
      }
    },
    [serialIndex, currentPage]
  );

  const onNextPage = useCallback(() => {
    if (!loading) {
      setCurrentPage(nextToken);
    }
  }, [loading, nextToken]);

  const onReset = () => {
    setData([]);
    setNextToken(null);
    setCurrentPage(null);
  };

  const onSearchSerialIndex = (value) => {
    setSerialIndex(value);
  };

  useEffect(() => {
    if (collectibleID && authorID) {
      onReset();
      fetchData(collectibleID, authorID);
    }
  }, [collectibleID, authorID]);

  useEffect(() => {
    onReset();
    fetchData(collectibleID, authorID, serialIndex);
  }, [serialIndex]);

  useEffect(() => {
    fetchData(collectibleID, authorID, serialIndex);
  }, [currentPage]);

  return {
    loading,
    data,
    total,
    hasMore: !!nextToken,
    onNextPage: onNextPage,
    onSearchSerialIndex,
    counter: data.length
  };
};
