import showDevelopmentToast from '@libs/utils/showDevelopmentToast';
import handleNonNullableTypeError from '@libs/utils/errors/graphql/handleNonNullableTypeError';

const errorTypesMessageMap = {
  nonNullableTypeError: 'Cannot return null for non-nullable type'
};

const errorTypesHandlerMap = {
  nonNullableTypeError: handleNonNullableTypeError
};

const showAndLogNonFatalError = (queryName, message) => {
  showDevelopmentToast('Non-Fatal GraphQL Error', queryName, message, 'warn');
  console.warn(`Non-fatal GraphQL error in [${queryName}]\n ${message}`);
};

const showAndLogFatalError = (queryName, message) => {
  showDevelopmentToast('Fatal GraphQL Error', queryName, message, 'error', 'colored');
  console.error(`Fatal GraphQL error in [${queryName}]\n ${message}`);
};

const showAndLogUnknownError = (queryName, message) => {
  showDevelopmentToast('Unknown GraphQL Error', queryName, message, 'error', 'colored');
  console.error(`Unknown GraphQL error in [${queryName}]\n ${message}`);
};

const matchErrorTypeByMessage = (errorMessage) => {
  const errorTypes = Object.keys(errorTypesMessageMap);
  return errorTypes.find((errorType) => errorMessage.includes(errorTypesMessageMap[errorType]));
};

const parseError = (error) => {
  let { message, path } = error;
  const queryName = path[0];

  let errorType,
    statusCode = null;

  try {
    const messageParsed = JSON.parse(message);
    ({ statusCode, message, name: errorType } = messageParsed);
  } catch (e) {
    errorType = matchErrorTypeByMessage(message);
  }

  const handlingStatus = errorTypesHandlerMap[errorType]?.(error);

  if (handlingStatus) {
    if (handlingStatus.isFatal) {
      showAndLogFatalError(queryName, handlingStatus.message);
    } else {
      showAndLogNonFatalError(queryName, handlingStatus.message);
    }

    return { isHandled: true, errorType, message, statusCode, ...handlingStatus };
  }

  showAndLogUnknownError(queryName, message);
  return { isHandled: false, errorType, message, statusCode };
};

function handleGraphQLErrors(response) {
  if (!response) {
    return [];
  }

  const isGqlError = Array.isArray(response?.errors);
  let handlingStatusAll = [];

  if (isGqlError) {
    handlingStatusAll = response.errors.map(parseError);
  } else {
    handlingStatusAll = [
      {
        isHandled: false,
        errorType: null,
        message: response.message ?? response.toString(),
        statusCode: null
      }
    ];
  }

  return {
    allHandled: handlingStatusAll.every((handlingStatus) => handlingStatus.isHandled),
    someHandled: handlingStatusAll.some((handlingStatus) => handlingStatus.isHandled),
    allFatal: handlingStatusAll.every((handlingStatus) => handlingStatus.isFatal),
    someFatal: handlingStatusAll.some((handlingStatus) => handlingStatus.isFatal),
    errorTypes: handlingStatusAll.map((handlingStatus) => handlingStatus.errorType),
    messages: handlingStatusAll.map((handlingStatus) => handlingStatus.message),
    statusCodes: handlingStatusAll.map((handlingStatus) => handlingStatus.statusCode),
    isGqlError
  };
}

export default handleGraphQLErrors;
