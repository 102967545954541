/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDataForTierTiles = /* GraphQL */ `
  query GetDataForTierTiles($input: getDataForTierTilesInput!) {
    getDataForTierTiles(input: $input)
  }
`;
export const getHighestBid = /* GraphQL */ `
  query GetHighestBid($input: getHighestBidInput!) {
    getHighestBid(input: $input)
  }
`;
export const getFeaturedArtistByCreator = /* GraphQL */ `
  query GetFeaturedArtistByCreator($input: getFeaturedArtistByCreatorInput!) {
    getFeaturedArtistByCreator(input: $input)
  }
`;
export const getCollectibleDetail = /* GraphQL */ `
  query GetCollectibleDetail($input: getCollectibleDetailInput!) {
    getCollectibleDetail(input: $input)
  }
`;
export const quotesProdigi = /* GraphQL */ `
  query QuotesProdigi($input: quotesProdigiInput!) {
    quotesProdigi(input: $input)
  }
`;
export const getCollectionStash = /* GraphQL */ `
  query GetCollectionStash($input: collectionStashInput!) {
    getCollectionStash(input: $input)
  }
`;
export const collectibleLikeByUserAndCollectible = /* GraphQL */ `
  query CollectibleLikeByUserAndCollectible(
    $userID: ID
    $collectibleID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCollectibleLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    collectibleLikeByUserAndCollectible(
      userID: $userID
      collectibleID: $collectibleID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        collectibleID
        onVisit
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const collectibleLikeByCollectible = /* GraphQL */ `
  query CollectibleLikeByCollectible(
    $collectibleID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCollectibleLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    collectibleLikeByCollectible(
      collectibleID: $collectibleID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        collectibleID
        onVisit
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const collectibleViewByCollectibleAndCreatedAt = /* GraphQL */ `
  query CollectibleViewByCollectibleAndCreatedAt(
    $collectibleID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCollectibleViewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    collectibleViewByCollectibleAndCreatedAt(
      collectibleID: $collectibleID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        userIP
        collectibleID
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const channelByOwnerAndCreatedAt = /* GraphQL */ `
  query ChannelByOwnerAndCreatedAt(
    $owner: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDiscourseChannelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    channelByOwnerAndCreatedAt(
      owner: $owner
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        owner
        removable
        collectibles
        lastActivityAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const discoursePostByFeaturedAndChannel = /* GraphQL */ `
  query DiscoursePostByFeaturedAndChannel(
    $featured: CustomBooleanType
    $channelID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDiscoursePostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    discoursePostByFeaturedAndChannel(
      featured: $featured
      channelID: $channelID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentID
        topicID
        channelID
        userID
        content
        likedBy
        featured
        reportCount
        createdAt
        updatedAt
        searchableFeatured
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const discoursePostByParentPost = /* GraphQL */ `
  query DiscoursePostByParentPost(
    $parentPostID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelDiscoursePostReplyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    discoursePostByParentPost(
      parentPostID: $parentPostID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postID
        parentPostID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const notificationByRead = /* GraphQL */ `
  query NotificationByRead(
    $read: CustomBooleanType
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationByRead(
      read: $read
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        type
        event
        read
        category
        activityID
        lambdaEventID
        lambdaEventName
        createdAt
        updatedAt
        searchableType
        searchableRead
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const notificationByLambdaEventName = /* GraphQL */ `
  query NotificationByLambdaEventName(
    $lambdaEventName: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationByLambdaEventName(
      lambdaEventName: $lambdaEventName
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        type
        event
        read
        category
        activityID
        lambdaEventID
        lambdaEventName
        createdAt
        updatedAt
        searchableType
        searchableRead
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const notificationByUserAndRead = /* GraphQL */ `
  query NotificationByUserAndRead(
    $userID: ID
    $read: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationByUserAndRead(
      userID: $userID
      read: $read
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        type
        event
        read
        category
        activityID
        lambdaEventID
        lambdaEventName
        createdAt
        updatedAt
        searchableType
        searchableRead
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const notificationByUserAndTypeAndRead = /* GraphQL */ `
  query NotificationByUserAndTypeAndRead(
    $userID: ID
    $typeRead: ModelNotificationByUserAndTypeAndReadCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationByUserAndTypeAndRead(
      userID: $userID
      typeRead: $typeRead
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        type
        event
        read
        category
        activityID
        lambdaEventID
        lambdaEventName
        createdAt
        updatedAt
        searchableType
        searchableRead
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const orderByProdigiOrderID = /* GraphQL */ `
  query OrderByProdigiOrderID(
    $prodigiOrderID: String
    $sortDirection: ModelSortDirection
    $filter: ModelOrderPrintFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderByProdigiOrderID(
      prodigiOrderID: $prodigiOrderID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        exchangeID
        userID
        creatorID
        checkoutReceiptID
        prodigiOrderID
        prodigiResponse
        shipmentMethod
        currencyCode
        totalPrice
        createdAt
        updatedAt
        orderStatus
        paymentStatus
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const profileManagerByAccountAndManager = /* GraphQL */ `
  query ProfileManagerByAccountAndManager(
    $accountID: ID
    $managerID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProfileManagerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    profileManagerByAccountAndManager(
      accountID: $accountID
      managerID: $managerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        profileID
        managerID
        accountID
        firstName
        lastName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        editors
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const emailWaitlistByEmailAndVerified = /* GraphQL */ `
  query EmailWaitlistByEmailAndVerified(
    $email: String
    $verified: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmailWaitlistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    emailWaitlistByEmailAndVerified(
      email: $email
      verified: $verified
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        firstName
        surName
        phone
        status
        interestedAs
        verified
        verifiedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const walletHistoryByReference = /* GraphQL */ `
  query WalletHistoryByReference(
    $referenceID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelWalletHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    walletHistoryByReference(
      referenceID: $referenceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        senderID
        receiverID
        paymentProvider
        debit
        credit
        invoiceID
        referenceID
        exchangeID
        balance
        transactionType
        status
        description
        currency
        owner
        createdAt
        updatedAt
        searchableTransactionType
        searchableStatus
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      name
      legalName
      address
      country
      state
      city
      postcode
      phone
      website
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      members {
        nextToken
        startedAt
        __typename
      }
      currentOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        type
        bio
        style
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        totalSales
        totalSalesVolume
        totalPurchase
        totalCollectionValues
        totalLikeCollectible
        isHidden
        profileTemplate
        walletAddresses
        status
        createdAt
        updatedAt
        searchableVerified
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      __typename
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        legalName
        address
        country
        state
        city
        postcode
        phone
        website
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchAccounts = /* GraphQL */ `
  query SearchAccounts(
    $filter: SearchableAccountFilterInput
    $sort: SearchableAccountSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchAccounts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        legalName
        address
        country
        state
        city
        postcode
        phone
        website
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
export const syncAccounts = /* GraphQL */ `
  query SyncAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        legalName
        address
        country
        state
        city
        postcode
        phone
        website
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAccountStatistic = /* GraphQL */ `
  query GetAccountStatistic($id: ID!) {
    getAccountStatistic(id: $id) {
      id
      collectibleCount
      verifiedProfileCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listAccountStatistics = /* GraphQL */ `
  query ListAccountStatistics(
    $filter: ModelAccountStatisticFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccountStatistics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        collectibleCount
        verifiedProfileCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAccountStatistics = /* GraphQL */ `
  query SyncAccountStatistics(
    $filter: ModelAccountStatisticFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAccountStatistics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        collectibleCount
        verifiedProfileCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAccountUser = /* GraphQL */ `
  query GetAccountUser($id: ID!) {
    getAccountUser(id: $id) {
      id
      accountID
      userID
      firstName
      lastName
      email
      roleType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      account {
        id
        name
        legalName
        address
        country
        state
        city
        postcode
        phone
        website
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      owner
      user {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        type
        bio
        style
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        totalSales
        totalSalesVolume
        totalPurchase
        totalCollectionValues
        totalLikeCollectible
        isHidden
        profileTemplate
        walletAddresses
        status
        createdAt
        updatedAt
        searchableVerified
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      __typename
    }
  }
`;
export const listAccountUsers = /* GraphQL */ `
  query ListAccountUsers(
    $filter: ModelAccountUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccountUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        userID
        firstName
        lastName
        email
        roleType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const accountUserByAccountRole = /* GraphQL */ `
  query AccountUserByAccountRole(
    $accountID: ID
    $roleType: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccountUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountUserByAccountRole(
      accountID: $accountID
      roleType: $roleType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        userID
        firstName
        lastName
        email
        roleType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const accountUserByAccount = /* GraphQL */ `
  query AccountUserByAccount(
    $accountID: ID
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccountUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountUserByAccount(
      accountID: $accountID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        userID
        firstName
        lastName
        email
        roleType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const accountUserByUser = /* GraphQL */ `
  query AccountUserByUser(
    $userID: ID
    $accountID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccountUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountUserByUser(
      userID: $userID
      accountID: $accountID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        userID
        firstName
        lastName
        email
        roleType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const accountUserByEmailAccount = /* GraphQL */ `
  query AccountUserByEmailAccount(
    $email: String
    $accountID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccountUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountUserByEmailAccount(
      email: $email
      accountID: $accountID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        userID
        firstName
        lastName
        email
        roleType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAccountUsers = /* GraphQL */ `
  query SyncAccountUsers(
    $filter: ModelAccountUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAccountUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountID
        userID
        firstName
        lastName
        email
        roleType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getGalleryExperience = /* GraphQL */ `
  query GetGalleryExperience($id: ID!) {
    getGalleryExperience(id: $id) {
      id
      accountID
      orientation
      heading
      description
      qrPosition
      qrText
      logoPosition
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listGalleryExperiences = /* GraphQL */ `
  query ListGalleryExperiences(
    $filter: ModelGalleryExperienceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGalleryExperiences(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        orientation
        heading
        description
        qrPosition
        qrText
        logoPosition
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncGalleryExperiences = /* GraphQL */ `
  query SyncGalleryExperiences(
    $filter: ModelGalleryExperienceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGalleryExperiences(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountID
        orientation
        heading
        description
        qrPosition
        qrText
        logoPosition
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getActivity = /* GraphQL */ `
  query GetActivity($id: ID!) {
    getActivity(id: $id) {
      id
      userID
      type
      event
      lambdaEventID
      lambdaEventName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listActivities = /* GraphQL */ `
  query ListActivities(
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        type
        event
        lambdaEventID
        lambdaEventName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const activityByTypeAndCreatedAt = /* GraphQL */ `
  query ActivityByTypeAndCreatedAt(
    $type: ActivityType
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activityByTypeAndCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        type
        event
        lambdaEventID
        lambdaEventName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const activityByUser = /* GraphQL */ `
  query ActivityByUser(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activityByUser(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        type
        event
        lambdaEventID
        lambdaEventName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncActivities = /* GraphQL */ `
  query SyncActivities(
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncActivities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        type
        event
        lambdaEventID
        lambdaEventName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCollectible = /* GraphQL */ `
  query GetCollectible($id: ID!) {
    getCollectible(id: $id) {
      id
      accountID
      userID
      type
      category
      genre
      streamExternal
      streamUrl {
        spotify
        appleMusic
        youtube
        __typename
      }
      title
      slug
      description
      insideStory
      assetUrl
      mediaThumbnail
      marketingText
      whatsIncludedOriginal
      whatsIncludedNumbered
      featured
      featuredInProfile
      status
      yearOfCreation
      releasedAt
      createdAt
      updatedAt
      releases {
        tierType
        releaseDate
        releaseCount
        soldCount
        listingType
        startingPrice
        finalPrice
        saleEndDate
        preReleaseAvailability
        availableForPreRelease
        setStartingPrice
        setFinalPrice
        isReleased
        __typename
      }
      maxNumberedEditions
      metaTitle
      metaDescription
      perksOriginal {
        fanClubAccess
        limitedPerks
        lifetimePerks
        __typename
      }
      perksNumbered {
        fanClubAccess
        limitedPerks
        lifetimePerks
        __typename
      }
      history {
        status
        value
        date
        __typename
      }
      lastSoldAt
      faq {
        key
        title
        content
        __typename
      }
      promoEnabled
      printable
      promoTitle
      promoDescription
      viewCount
      collectionID
      width
      height
      smallWidth
      smallHeight
      largeWidth
      largeHeight
      originalFrame
      smallFrame
      smallPrint
      searchableType
      searchableFeatured
      searchablefeaturedInProfile
      searchableCategory
      searchableStatus
      searchableLowestPrice
      searchableHighestPrice
      searchableHighestBidPrice
      searchableGenre
      searchableFeaturedArtist
      _version
      _deleted
      _lastChangedAt
      owner
      creator {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        type
        bio
        style
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        totalSales
        totalSalesVolume
        totalPurchase
        totalCollectionValues
        totalLikeCollectible
        isHidden
        profileTemplate
        walletAddresses
        status
        createdAt
        updatedAt
        searchableVerified
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      __typename
    }
  }
`;
export const listCollectibles = /* GraphQL */ `
  query ListCollectibles(
    $filter: ModelCollectibleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCollectibles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        userID
        type
        category
        genre
        streamExternal
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        maxNumberedEditions
        metaTitle
        metaDescription
        lastSoldAt
        promoEnabled
        printable
        promoTitle
        promoDescription
        viewCount
        collectionID
        width
        height
        smallWidth
        smallHeight
        largeWidth
        largeHeight
        originalFrame
        smallFrame
        smallPrint
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableHighestBidPrice
        searchableGenre
        searchableFeaturedArtist
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const collectibleByCategoryAndCreatedAt = /* GraphQL */ `
  query CollectibleByCategoryAndCreatedAt(
    $category: CollectibleCategory
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCollectibleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    collectibleByCategoryAndCreatedAt(
      category: $category
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        userID
        type
        category
        genre
        streamExternal
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        maxNumberedEditions
        metaTitle
        metaDescription
        lastSoldAt
        promoEnabled
        printable
        promoTitle
        promoDescription
        viewCount
        collectionID
        width
        height
        smallWidth
        smallHeight
        largeWidth
        largeHeight
        originalFrame
        smallFrame
        smallPrint
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableHighestBidPrice
        searchableGenre
        searchableFeaturedArtist
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const collectibleByUser = /* GraphQL */ `
  query CollectibleByUser(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCollectibleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    collectibleByUser(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        userID
        type
        category
        genre
        streamExternal
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        maxNumberedEditions
        metaTitle
        metaDescription
        lastSoldAt
        promoEnabled
        printable
        promoTitle
        promoDescription
        viewCount
        collectionID
        width
        height
        smallWidth
        smallHeight
        largeWidth
        largeHeight
        originalFrame
        smallFrame
        smallPrint
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableHighestBidPrice
        searchableGenre
        searchableFeaturedArtist
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const collectibleBySlug = /* GraphQL */ `
  query CollectibleBySlug(
    $slug: String
    $sortDirection: ModelSortDirection
    $filter: ModelCollectibleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    collectibleBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        userID
        type
        category
        genre
        streamExternal
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        maxNumberedEditions
        metaTitle
        metaDescription
        lastSoldAt
        promoEnabled
        printable
        promoTitle
        promoDescription
        viewCount
        collectionID
        width
        height
        smallWidth
        smallHeight
        largeWidth
        largeHeight
        originalFrame
        smallFrame
        smallPrint
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableHighestBidPrice
        searchableGenre
        searchableFeaturedArtist
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchCollectibles = /* GraphQL */ `
  query SearchCollectibles(
    $filter: SearchableCollectibleFilterInput
    $sort: SearchableCollectibleSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCollectibles(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        accountID
        userID
        type
        category
        genre
        streamExternal
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        maxNumberedEditions
        metaTitle
        metaDescription
        lastSoldAt
        promoEnabled
        printable
        promoTitle
        promoDescription
        viewCount
        collectionID
        width
        height
        smallWidth
        smallHeight
        largeWidth
        largeHeight
        originalFrame
        smallFrame
        smallPrint
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableHighestBidPrice
        searchableGenre
        searchableFeaturedArtist
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
export const syncCollectibles = /* GraphQL */ `
  query SyncCollectibles(
    $filter: ModelCollectibleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCollectibles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountID
        userID
        type
        category
        genre
        streamExternal
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        maxNumberedEditions
        metaTitle
        metaDescription
        lastSoldAt
        promoEnabled
        printable
        promoTitle
        promoDescription
        viewCount
        collectionID
        width
        height
        smallWidth
        smallHeight
        largeWidth
        largeHeight
        originalFrame
        smallFrame
        smallPrint
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableHighestBidPrice
        searchableGenre
        searchableFeaturedArtist
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getFeaturedArtist = /* GraphQL */ `
  query GetFeaturedArtist($id: ID!) {
    getFeaturedArtist(id: $id) {
      id
      artistName
      collectibleCount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listFeaturedArtists = /* GraphQL */ `
  query ListFeaturedArtists(
    $filter: ModelFeaturedArtistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeaturedArtists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        artistName
        collectibleCount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFeaturedArtists = /* GraphQL */ `
  query SyncFeaturedArtists(
    $filter: ModelFeaturedArtistFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFeaturedArtists(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        artistName
        collectibleCount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getFeaturedArtistCollectible = /* GraphQL */ `
  query GetFeaturedArtistCollectible($id: ID!) {
    getFeaturedArtistCollectible(id: $id) {
      id
      collectibleID
      featuredArtistID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      collectible {
        id
        accountID
        userID
        type
        category
        genre
        streamExternal
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        maxNumberedEditions
        metaTitle
        metaDescription
        lastSoldAt
        promoEnabled
        printable
        promoTitle
        promoDescription
        viewCount
        collectionID
        width
        height
        smallWidth
        smallHeight
        largeWidth
        largeHeight
        originalFrame
        smallFrame
        smallPrint
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableHighestBidPrice
        searchableGenre
        searchableFeaturedArtist
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      featuredArtist {
        id
        artistName
        collectibleCount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listFeaturedArtistCollectibles = /* GraphQL */ `
  query ListFeaturedArtistCollectibles(
    $filter: ModelFeaturedArtistCollectibleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeaturedArtistCollectibles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        collectibleID
        featuredArtistID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const featuredArtistsCollectibleByfeaturedArtist = /* GraphQL */ `
  query FeaturedArtistsCollectibleByfeaturedArtist(
    $featuredArtistID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelFeaturedArtistCollectibleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    featuredArtistsCollectibleByfeaturedArtist(
      featuredArtistID: $featuredArtistID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        collectibleID
        featuredArtistID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const featuredArtistsCollectibleByCollectibleAndArtists = /* GraphQL */ `
  query FeaturedArtistsCollectibleByCollectibleAndArtists(
    $collectibleID: ID
    $featuredArtistID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFeaturedArtistCollectibleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    featuredArtistsCollectibleByCollectibleAndArtists(
      collectibleID: $collectibleID
      featuredArtistID: $featuredArtistID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        collectibleID
        featuredArtistID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFeaturedArtistCollectibles = /* GraphQL */ `
  query SyncFeaturedArtistCollectibles(
    $filter: ModelFeaturedArtistCollectibleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFeaturedArtistCollectibles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        collectibleID
        featuredArtistID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCollectibleLikes = /* GraphQL */ `
  query SyncCollectibleLikes(
    $filter: ModelCollectibleLikeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCollectibleLikes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        collectibleID
        onVisit
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCollectibleViews = /* GraphQL */ `
  query SyncCollectibleViews(
    $filter: ModelCollectibleViewFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCollectibleViews(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        userIP
        collectibleID
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchCollections = /* GraphQL */ `
  query SearchCollections(
    $filter: SearchableCollectionsFilterInput
    $sort: SearchableCollectionsSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCollections(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        accountID
        name
        collectionType
        avatarUrl
        coverUrl
        logoUrl
        slug
        collectionUrl
        isShowLogo
        shortName
        createdAt
        updatedAt
        searchableCollectionType
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
export const syncCollections = /* GraphQL */ `
  query SyncCollections(
    $filter: ModelCollectionsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCollections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountID
        name
        collectionType
        avatarUrl
        coverUrl
        logoUrl
        slug
        collectionUrl
        isShowLogo
        shortName
        createdAt
        updatedAt
        searchableCollectionType
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDiscourseChannels = /* GraphQL */ `
  query SyncDiscourseChannels(
    $filter: ModelDiscourseChannelFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDiscourseChannels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        owner
        removable
        collectibles
        lastActivityAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchDiscoursePosts = /* GraphQL */ `
  query SearchDiscoursePosts(
    $filter: SearchableDiscoursePostFilterInput
    $sort: SearchableDiscoursePostSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchDiscoursePosts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        parentID
        topicID
        channelID
        userID
        content
        likedBy
        featured
        reportCount
        createdAt
        updatedAt
        searchableFeatured
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
export const syncDiscoursePosts = /* GraphQL */ `
  query SyncDiscoursePosts(
    $filter: ModelDiscoursePostFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDiscoursePosts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        parentID
        topicID
        channelID
        userID
        content
        likedBy
        featured
        reportCount
        createdAt
        updatedAt
        searchableFeatured
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDiscoursePostReplies = /* GraphQL */ `
  query SyncDiscoursePostReplies(
    $filter: ModelDiscoursePostReplyFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDiscoursePostReplies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        postID
        parentPostID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchDiscoursePostReports = /* GraphQL */ `
  query SearchDiscoursePostReports(
    $filter: SearchableDiscoursePostReportFilterInput
    $sort: SearchableDiscoursePostReportSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchDiscoursePostReports(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        postID
        userID
        reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
export const syncDiscoursePostReports = /* GraphQL */ `
  query SyncDiscoursePostReports(
    $filter: ModelDiscoursePostReportFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDiscoursePostReports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        postID
        userID
        reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getExchange = /* GraphQL */ `
  query GetExchange($id: ID!) {
    getExchange(id: $id) {
      id
      exchangeType
      creatorID
      currentOwnerID
      collectibleID
      collectibleReleaseIndex
      serialIndex
      listingType
      releaseDate
      isReleased
      sellingPrice
      offerPrice
      startingPrice
      lastPrice
      auctionMinimumAllowedBidPrice
      isMinimumOfferVisible
      isAutoAcceptActive
      bidPrice
      maxBidPrice
      expiryDate
      isSold
      status
      patronID
      fiatCurrencyCode
      paymentReferenceID
      paymentReferenceIDInProcess
      paymentProvider
      paymentProviderInProcess
      bitpayPaymentRequested
      paymentRequestExpiredAt
      checkoutReceiptID
      checkoutReceiptIDInProcess
      createdAt
      updatedAt
      nftTransactionHash
      nftOwnerAddress
      nftContractAddress
      nftTokenID
      nftTokenIDString
      nftERC20PaymentTokenAddress
      nftERC20PaymentTokenSymbol
      nftERC20PaymentTokenDecimals
      nftERC20PaymentAmount
      nftERC20FeeTokenAddress
      nftERC20FeeTokenSymbol
      nftERC20FeeTokenDecimals
      nftERC20FeeAmount
      userID
      currentListingType
      lastPurchasedAt
      slug
      tierType
      releaseCount
      previousOwnerID
      previousPrice
      finalPrice
      currentPrice
      previousExchangeID
      parentExchangeID
      bitpayReceiptID
      order {
        tax
        platformFee
        salesFee
        royalty
        royaltyFee
        invoiceID
        checkoutReceiptID
        bitpayReceiptID
        __typename
      }
      print {
        __typename
      }
      searchableExchangeType
      searchableIsSold
      searchableStatus
      searchableHighestPrice
      searchableTitle
      searchableCategory
      searchableGenre
      searchableYearOfCreation
      searchableFeaturedArtists
      _version
      _deleted
      _lastChangedAt
      collectible {
        id
        accountID
        userID
        type
        category
        genre
        streamExternal
        title
        slug
        description
        insideStory
        assetUrl
        mediaThumbnail
        marketingText
        whatsIncludedOriginal
        whatsIncludedNumbered
        featured
        featuredInProfile
        status
        yearOfCreation
        releasedAt
        createdAt
        updatedAt
        maxNumberedEditions
        metaTitle
        metaDescription
        lastSoldAt
        promoEnabled
        printable
        promoTitle
        promoDescription
        viewCount
        collectionID
        width
        height
        smallWidth
        smallHeight
        largeWidth
        largeHeight
        originalFrame
        smallFrame
        smallPrint
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableHighestBidPrice
        searchableGenre
        searchableFeaturedArtist
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      owner
      previousExchange {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        status
        patronID
        fiatCurrencyCode
        paymentReferenceID
        paymentReferenceIDInProcess
        paymentProvider
        paymentProviderInProcess
        bitpayPaymentRequested
        paymentRequestExpiredAt
        checkoutReceiptID
        checkoutReceiptIDInProcess
        createdAt
        updatedAt
        nftTransactionHash
        nftOwnerAddress
        nftContractAddress
        nftTokenID
        nftTokenIDString
        nftERC20PaymentTokenAddress
        nftERC20PaymentTokenSymbol
        nftERC20PaymentTokenDecimals
        nftERC20PaymentAmount
        nftERC20FeeTokenAddress
        nftERC20FeeTokenSymbol
        nftERC20FeeTokenDecimals
        nftERC20FeeAmount
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        searchableExchangeType
        searchableIsSold
        searchableStatus
        searchableHighestPrice
        searchableTitle
        searchableCategory
        searchableGenre
        searchableYearOfCreation
        searchableFeaturedArtists
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      parentExchange {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        status
        patronID
        fiatCurrencyCode
        paymentReferenceID
        paymentReferenceIDInProcess
        paymentProvider
        paymentProviderInProcess
        bitpayPaymentRequested
        paymentRequestExpiredAt
        checkoutReceiptID
        checkoutReceiptIDInProcess
        createdAt
        updatedAt
        nftTransactionHash
        nftOwnerAddress
        nftContractAddress
        nftTokenID
        nftTokenIDString
        nftERC20PaymentTokenAddress
        nftERC20PaymentTokenSymbol
        nftERC20PaymentTokenDecimals
        nftERC20PaymentAmount
        nftERC20FeeTokenAddress
        nftERC20FeeTokenSymbol
        nftERC20FeeTokenDecimals
        nftERC20FeeAmount
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        searchableExchangeType
        searchableIsSold
        searchableStatus
        searchableHighestPrice
        searchableTitle
        searchableCategory
        searchableGenre
        searchableYearOfCreation
        searchableFeaturedArtists
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      creator {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        type
        bio
        style
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        totalSales
        totalSalesVolume
        totalPurchase
        totalCollectionValues
        totalLikeCollectible
        isHidden
        profileTemplate
        walletAddresses
        status
        createdAt
        updatedAt
        searchableVerified
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        type
        bio
        style
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        totalSales
        totalSalesVolume
        totalPurchase
        totalCollectionValues
        totalLikeCollectible
        isHidden
        profileTemplate
        walletAddresses
        status
        createdAt
        updatedAt
        searchableVerified
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      patron {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        type
        bio
        style
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        totalSales
        totalSalesVolume
        totalPurchase
        totalCollectionValues
        totalLikeCollectible
        isHidden
        profileTemplate
        walletAddresses
        status
        createdAt
        updatedAt
        searchableVerified
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      previousOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        type
        bio
        style
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        totalSales
        totalSalesVolume
        totalPurchase
        totalCollectionValues
        totalLikeCollectible
        isHidden
        profileTemplate
        walletAddresses
        status
        createdAt
        updatedAt
        searchableVerified
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      __typename
    }
  }
`;
export const listExchanges = /* GraphQL */ `
  query ListExchanges(
    $filter: ModelExchangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExchanges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        status
        patronID
        fiatCurrencyCode
        paymentReferenceID
        paymentReferenceIDInProcess
        paymentProvider
        paymentProviderInProcess
        bitpayPaymentRequested
        paymentRequestExpiredAt
        checkoutReceiptID
        checkoutReceiptIDInProcess
        createdAt
        updatedAt
        nftTransactionHash
        nftOwnerAddress
        nftContractAddress
        nftTokenID
        nftTokenIDString
        nftERC20PaymentTokenAddress
        nftERC20PaymentTokenSymbol
        nftERC20PaymentTokenDecimals
        nftERC20PaymentAmount
        nftERC20FeeTokenAddress
        nftERC20FeeTokenSymbol
        nftERC20FeeTokenDecimals
        nftERC20FeeAmount
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        searchableExchangeType
        searchableIsSold
        searchableStatus
        searchableHighestPrice
        searchableTitle
        searchableCategory
        searchableGenre
        searchableYearOfCreation
        searchableFeaturedArtists
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listingTypeByIsSold = /* GraphQL */ `
  query ListingTypeByIsSold(
    $listingType: ListingType
    $isSold: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExchangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listingTypeByIsSold(
      listingType: $listingType
      isSold: $isSold
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        status
        patronID
        fiatCurrencyCode
        paymentReferenceID
        paymentReferenceIDInProcess
        paymentProvider
        paymentProviderInProcess
        bitpayPaymentRequested
        paymentRequestExpiredAt
        checkoutReceiptID
        checkoutReceiptIDInProcess
        createdAt
        updatedAt
        nftTransactionHash
        nftOwnerAddress
        nftContractAddress
        nftTokenID
        nftTokenIDString
        nftERC20PaymentTokenAddress
        nftERC20PaymentTokenSymbol
        nftERC20PaymentTokenDecimals
        nftERC20PaymentAmount
        nftERC20FeeTokenAddress
        nftERC20FeeTokenSymbol
        nftERC20FeeTokenDecimals
        nftERC20FeeAmount
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        searchableExchangeType
        searchableIsSold
        searchableStatus
        searchableHighestPrice
        searchableTitle
        searchableCategory
        searchableGenre
        searchableYearOfCreation
        searchableFeaturedArtists
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const exchangeByCollectibleAndStartingPrice = /* GraphQL */ `
  query ExchangeByCollectibleAndStartingPrice(
    $collectibleID: ID
    $startingPrice: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExchangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    exchangeByCollectibleAndStartingPrice(
      collectibleID: $collectibleID
      startingPrice: $startingPrice
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        status
        patronID
        fiatCurrencyCode
        paymentReferenceID
        paymentReferenceIDInProcess
        paymentProvider
        paymentProviderInProcess
        bitpayPaymentRequested
        paymentRequestExpiredAt
        checkoutReceiptID
        checkoutReceiptIDInProcess
        createdAt
        updatedAt
        nftTransactionHash
        nftOwnerAddress
        nftContractAddress
        nftTokenID
        nftTokenIDString
        nftERC20PaymentTokenAddress
        nftERC20PaymentTokenSymbol
        nftERC20PaymentTokenDecimals
        nftERC20PaymentAmount
        nftERC20FeeTokenAddress
        nftERC20FeeTokenSymbol
        nftERC20FeeTokenDecimals
        nftERC20FeeAmount
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        searchableExchangeType
        searchableIsSold
        searchableStatus
        searchableHighestPrice
        searchableTitle
        searchableCategory
        searchableGenre
        searchableYearOfCreation
        searchableFeaturedArtists
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const exchangeByCollectibleAndCurrentOwner = /* GraphQL */ `
  query ExchangeByCollectibleAndCurrentOwner(
    $collectibleID: ID
    $currentOwnerID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExchangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    exchangeByCollectibleAndCurrentOwner(
      collectibleID: $collectibleID
      currentOwnerID: $currentOwnerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        status
        patronID
        fiatCurrencyCode
        paymentReferenceID
        paymentReferenceIDInProcess
        paymentProvider
        paymentProviderInProcess
        bitpayPaymentRequested
        paymentRequestExpiredAt
        checkoutReceiptID
        checkoutReceiptIDInProcess
        createdAt
        updatedAt
        nftTransactionHash
        nftOwnerAddress
        nftContractAddress
        nftTokenID
        nftTokenIDString
        nftERC20PaymentTokenAddress
        nftERC20PaymentTokenSymbol
        nftERC20PaymentTokenDecimals
        nftERC20PaymentAmount
        nftERC20FeeTokenAddress
        nftERC20FeeTokenSymbol
        nftERC20FeeTokenDecimals
        nftERC20FeeAmount
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        searchableExchangeType
        searchableIsSold
        searchableStatus
        searchableHighestPrice
        searchableTitle
        searchableCategory
        searchableGenre
        searchableYearOfCreation
        searchableFeaturedArtists
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const exchangeByExchangeTypeAndIsReleasedAndReleaseDate = /* GraphQL */ `
  query ExchangeByExchangeTypeAndIsReleasedAndReleaseDate(
    $exchangeType: ExchangeType
    $isReleasedReleaseDate: ModelExchangeByExchangeTypeAndIsReleasedAndReleaseDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExchangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    exchangeByExchangeTypeAndIsReleasedAndReleaseDate(
      exchangeType: $exchangeType
      isReleasedReleaseDate: $isReleasedReleaseDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        status
        patronID
        fiatCurrencyCode
        paymentReferenceID
        paymentReferenceIDInProcess
        paymentProvider
        paymentProviderInProcess
        bitpayPaymentRequested
        paymentRequestExpiredAt
        checkoutReceiptID
        checkoutReceiptIDInProcess
        createdAt
        updatedAt
        nftTransactionHash
        nftOwnerAddress
        nftContractAddress
        nftTokenID
        nftTokenIDString
        nftERC20PaymentTokenAddress
        nftERC20PaymentTokenSymbol
        nftERC20PaymentTokenDecimals
        nftERC20PaymentAmount
        nftERC20FeeTokenAddress
        nftERC20FeeTokenSymbol
        nftERC20FeeTokenDecimals
        nftERC20FeeAmount
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        searchableExchangeType
        searchableIsSold
        searchableStatus
        searchableHighestPrice
        searchableTitle
        searchableCategory
        searchableGenre
        searchableYearOfCreation
        searchableFeaturedArtists
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const exchangeByOwnerAndExchangeType = /* GraphQL */ `
  query ExchangeByOwnerAndExchangeType(
    $currentOwnerID: ID
    $exchangeType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExchangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    exchangeByOwnerAndExchangeType(
      currentOwnerID: $currentOwnerID
      exchangeType: $exchangeType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        status
        patronID
        fiatCurrencyCode
        paymentReferenceID
        paymentReferenceIDInProcess
        paymentProvider
        paymentProviderInProcess
        bitpayPaymentRequested
        paymentRequestExpiredAt
        checkoutReceiptID
        checkoutReceiptIDInProcess
        createdAt
        updatedAt
        nftTransactionHash
        nftOwnerAddress
        nftContractAddress
        nftTokenID
        nftTokenIDString
        nftERC20PaymentTokenAddress
        nftERC20PaymentTokenSymbol
        nftERC20PaymentTokenDecimals
        nftERC20PaymentAmount
        nftERC20FeeTokenAddress
        nftERC20FeeTokenSymbol
        nftERC20FeeTokenDecimals
        nftERC20FeeAmount
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        searchableExchangeType
        searchableIsSold
        searchableStatus
        searchableHighestPrice
        searchableTitle
        searchableCategory
        searchableGenre
        searchableYearOfCreation
        searchableFeaturedArtists
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const exchangeByPreviousOwnerAndExchangeType = /* GraphQL */ `
  query ExchangeByPreviousOwnerAndExchangeType(
    $previousOwnerID: ID
    $exchangeType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExchangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    exchangeByPreviousOwnerAndExchangeType(
      previousOwnerID: $previousOwnerID
      exchangeType: $exchangeType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        status
        patronID
        fiatCurrencyCode
        paymentReferenceID
        paymentReferenceIDInProcess
        paymentProvider
        paymentProviderInProcess
        bitpayPaymentRequested
        paymentRequestExpiredAt
        checkoutReceiptID
        checkoutReceiptIDInProcess
        createdAt
        updatedAt
        nftTransactionHash
        nftOwnerAddress
        nftContractAddress
        nftTokenID
        nftTokenIDString
        nftERC20PaymentTokenAddress
        nftERC20PaymentTokenSymbol
        nftERC20PaymentTokenDecimals
        nftERC20PaymentAmount
        nftERC20FeeTokenAddress
        nftERC20FeeTokenSymbol
        nftERC20FeeTokenDecimals
        nftERC20FeeAmount
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        searchableExchangeType
        searchableIsSold
        searchableStatus
        searchableHighestPrice
        searchableTitle
        searchableCategory
        searchableGenre
        searchableYearOfCreation
        searchableFeaturedArtists
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const exchangeByParentExchangeAndExchangeType = /* GraphQL */ `
  query ExchangeByParentExchangeAndExchangeType(
    $parentExchangeID: ID
    $exchangeType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExchangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    exchangeByParentExchangeAndExchangeType(
      parentExchangeID: $parentExchangeID
      exchangeType: $exchangeType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        status
        patronID
        fiatCurrencyCode
        paymentReferenceID
        paymentReferenceIDInProcess
        paymentProvider
        paymentProviderInProcess
        bitpayPaymentRequested
        paymentRequestExpiredAt
        checkoutReceiptID
        checkoutReceiptIDInProcess
        createdAt
        updatedAt
        nftTransactionHash
        nftOwnerAddress
        nftContractAddress
        nftTokenID
        nftTokenIDString
        nftERC20PaymentTokenAddress
        nftERC20PaymentTokenSymbol
        nftERC20PaymentTokenDecimals
        nftERC20PaymentAmount
        nftERC20FeeTokenAddress
        nftERC20FeeTokenSymbol
        nftERC20FeeTokenDecimals
        nftERC20FeeAmount
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        searchableExchangeType
        searchableIsSold
        searchableStatus
        searchableHighestPrice
        searchableTitle
        searchableCategory
        searchableGenre
        searchableYearOfCreation
        searchableFeaturedArtists
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const exchangeByPatronAndExchangeType = /* GraphQL */ `
  query ExchangeByPatronAndExchangeType(
    $patronID: ID
    $exchangeType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExchangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    exchangeByPatronAndExchangeType(
      patronID: $patronID
      exchangeType: $exchangeType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        status
        patronID
        fiatCurrencyCode
        paymentReferenceID
        paymentReferenceIDInProcess
        paymentProvider
        paymentProviderInProcess
        bitpayPaymentRequested
        paymentRequestExpiredAt
        checkoutReceiptID
        checkoutReceiptIDInProcess
        createdAt
        updatedAt
        nftTransactionHash
        nftOwnerAddress
        nftContractAddress
        nftTokenID
        nftTokenIDString
        nftERC20PaymentTokenAddress
        nftERC20PaymentTokenSymbol
        nftERC20PaymentTokenDecimals
        nftERC20PaymentAmount
        nftERC20FeeTokenAddress
        nftERC20FeeTokenSymbol
        nftERC20FeeTokenDecimals
        nftERC20FeeAmount
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        searchableExchangeType
        searchableIsSold
        searchableStatus
        searchableHighestPrice
        searchableTitle
        searchableCategory
        searchableGenre
        searchableYearOfCreation
        searchableFeaturedArtists
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const exchangeByNftOwnerAddress = /* GraphQL */ `
  query ExchangeByNftOwnerAddress(
    $nftOwnerAddress: String
    $sortDirection: ModelSortDirection
    $filter: ModelExchangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    exchangeByNftOwnerAddress(
      nftOwnerAddress: $nftOwnerAddress
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        status
        patronID
        fiatCurrencyCode
        paymentReferenceID
        paymentReferenceIDInProcess
        paymentProvider
        paymentProviderInProcess
        bitpayPaymentRequested
        paymentRequestExpiredAt
        checkoutReceiptID
        checkoutReceiptIDInProcess
        createdAt
        updatedAt
        nftTransactionHash
        nftOwnerAddress
        nftContractAddress
        nftTokenID
        nftTokenIDString
        nftERC20PaymentTokenAddress
        nftERC20PaymentTokenSymbol
        nftERC20PaymentTokenDecimals
        nftERC20PaymentAmount
        nftERC20FeeTokenAddress
        nftERC20FeeTokenSymbol
        nftERC20FeeTokenDecimals
        nftERC20FeeAmount
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        searchableExchangeType
        searchableIsSold
        searchableStatus
        searchableHighestPrice
        searchableTitle
        searchableCategory
        searchableGenre
        searchableYearOfCreation
        searchableFeaturedArtists
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const exchangeByNftToken = /* GraphQL */ `
  query ExchangeByNftToken(
    $nftTokenID: Int
    $sortDirection: ModelSortDirection
    $filter: ModelExchangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    exchangeByNftToken(
      nftTokenID: $nftTokenID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        status
        patronID
        fiatCurrencyCode
        paymentReferenceID
        paymentReferenceIDInProcess
        paymentProvider
        paymentProviderInProcess
        bitpayPaymentRequested
        paymentRequestExpiredAt
        checkoutReceiptID
        checkoutReceiptIDInProcess
        createdAt
        updatedAt
        nftTransactionHash
        nftOwnerAddress
        nftContractAddress
        nftTokenID
        nftTokenIDString
        nftERC20PaymentTokenAddress
        nftERC20PaymentTokenSymbol
        nftERC20PaymentTokenDecimals
        nftERC20PaymentAmount
        nftERC20FeeTokenAddress
        nftERC20FeeTokenSymbol
        nftERC20FeeTokenDecimals
        nftERC20FeeAmount
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        searchableExchangeType
        searchableIsSold
        searchableStatus
        searchableHighestPrice
        searchableTitle
        searchableCategory
        searchableGenre
        searchableYearOfCreation
        searchableFeaturedArtists
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const exchangeByNftTokenString = /* GraphQL */ `
  query ExchangeByNftTokenString(
    $nftTokenIDString: String
    $sortDirection: ModelSortDirection
    $filter: ModelExchangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    exchangeByNftTokenString(
      nftTokenIDString: $nftTokenIDString
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        status
        patronID
        fiatCurrencyCode
        paymentReferenceID
        paymentReferenceIDInProcess
        paymentProvider
        paymentProviderInProcess
        bitpayPaymentRequested
        paymentRequestExpiredAt
        checkoutReceiptID
        checkoutReceiptIDInProcess
        createdAt
        updatedAt
        nftTransactionHash
        nftOwnerAddress
        nftContractAddress
        nftTokenID
        nftTokenIDString
        nftERC20PaymentTokenAddress
        nftERC20PaymentTokenSymbol
        nftERC20PaymentTokenDecimals
        nftERC20PaymentAmount
        nftERC20FeeTokenAddress
        nftERC20FeeTokenSymbol
        nftERC20FeeTokenDecimals
        nftERC20FeeAmount
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        searchableExchangeType
        searchableIsSold
        searchableStatus
        searchableHighestPrice
        searchableTitle
        searchableCategory
        searchableGenre
        searchableYearOfCreation
        searchableFeaturedArtists
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const exchangeByExchangeType = /* GraphQL */ `
  query ExchangeByExchangeType(
    $exchangeType: ExchangeType
    $sortDirection: ModelSortDirection
    $filter: ModelExchangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    exchangeByExchangeType(
      exchangeType: $exchangeType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        status
        patronID
        fiatCurrencyCode
        paymentReferenceID
        paymentReferenceIDInProcess
        paymentProvider
        paymentProviderInProcess
        bitpayPaymentRequested
        paymentRequestExpiredAt
        checkoutReceiptID
        checkoutReceiptIDInProcess
        createdAt
        updatedAt
        nftTransactionHash
        nftOwnerAddress
        nftContractAddress
        nftTokenID
        nftTokenIDString
        nftERC20PaymentTokenAddress
        nftERC20PaymentTokenSymbol
        nftERC20PaymentTokenDecimals
        nftERC20PaymentAmount
        nftERC20FeeTokenAddress
        nftERC20FeeTokenSymbol
        nftERC20FeeTokenDecimals
        nftERC20FeeAmount
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        searchableExchangeType
        searchableIsSold
        searchableStatus
        searchableHighestPrice
        searchableTitle
        searchableCategory
        searchableGenre
        searchableYearOfCreation
        searchableFeaturedArtists
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchExchanges = /* GraphQL */ `
  query SearchExchanges(
    $filter: SearchableExchangeFilterInput
    $sort: SearchableExchangeSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchExchanges(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        status
        patronID
        fiatCurrencyCode
        paymentReferenceID
        paymentReferenceIDInProcess
        paymentProvider
        paymentProviderInProcess
        bitpayPaymentRequested
        paymentRequestExpiredAt
        checkoutReceiptID
        checkoutReceiptIDInProcess
        createdAt
        updatedAt
        nftTransactionHash
        nftOwnerAddress
        nftContractAddress
        nftTokenID
        nftTokenIDString
        nftERC20PaymentTokenAddress
        nftERC20PaymentTokenSymbol
        nftERC20PaymentTokenDecimals
        nftERC20PaymentAmount
        nftERC20FeeTokenAddress
        nftERC20FeeTokenSymbol
        nftERC20FeeTokenDecimals
        nftERC20FeeAmount
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        searchableExchangeType
        searchableIsSold
        searchableStatus
        searchableHighestPrice
        searchableTitle
        searchableCategory
        searchableGenre
        searchableYearOfCreation
        searchableFeaturedArtists
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
export const syncExchanges = /* GraphQL */ `
  query SyncExchanges(
    $filter: ModelExchangeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncExchanges(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        status
        patronID
        fiatCurrencyCode
        paymentReferenceID
        paymentReferenceIDInProcess
        paymentProvider
        paymentProviderInProcess
        bitpayPaymentRequested
        paymentRequestExpiredAt
        checkoutReceiptID
        checkoutReceiptIDInProcess
        createdAt
        updatedAt
        nftTransactionHash
        nftOwnerAddress
        nftContractAddress
        nftTokenID
        nftTokenIDString
        nftERC20PaymentTokenAddress
        nftERC20PaymentTokenSymbol
        nftERC20PaymentTokenDecimals
        nftERC20PaymentAmount
        nftERC20FeeTokenAddress
        nftERC20FeeTokenSymbol
        nftERC20FeeTokenDecimals
        nftERC20FeeAmount
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        searchableExchangeType
        searchableIsSold
        searchableStatus
        searchableHighestPrice
        searchableTitle
        searchableCategory
        searchableGenre
        searchableYearOfCreation
        searchableFeaturedArtists
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getFollower = /* GraphQL */ `
  query GetFollower($followeeID: ID!, $followerID: ID!) {
    getFollower(followeeID: $followeeID, followerID: $followerID) {
      followeeID
      followerID
      appNotification
      emailNotification
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listFollowers = /* GraphQL */ `
  query ListFollowers(
    $followeeID: ID
    $followerID: ModelIDKeyConditionInput
    $filter: ModelFollowerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFollowers(
      followeeID: $followeeID
      followerID: $followerID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        followeeID
        followerID
        appNotification
        emailNotification
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const followerByFolloweeID = /* GraphQL */ `
  query FollowerByFolloweeID(
    $followeeID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelFollowerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    followerByFolloweeID(
      followeeID: $followeeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        followeeID
        followerID
        appNotification
        emailNotification
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFollowers = /* GraphQL */ `
  query SyncFollowers(
    $filter: ModelFollowerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFollowers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        followeeID
        followerID
        appNotification
        emailNotification
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchInboxes = /* GraphQL */ `
  query SearchInboxes(
    $filter: SearchableInboxFilterInput
    $sort: SearchableInboxSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchInboxes(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        inboxID
        fullName
        email
        message
        status
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
export const syncInboxes = /* GraphQL */ `
  query SyncInboxes(
    $filter: ModelInboxFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInboxes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        inboxID
        fullName
        email
        message
        status
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchNotifications = /* GraphQL */ `
  query SearchNotifications(
    $filter: SearchableNotificationFilterInput
    $sort: SearchableNotificationSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchNotifications(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        userID
        type
        event
        read
        category
        activityID
        lambdaEventID
        lambdaEventName
        createdAt
        updatedAt
        searchableType
        searchableRead
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
export const syncNotifications = /* GraphQL */ `
  query SyncNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        type
        event
        read
        category
        activityID
        lambdaEventID
        lambdaEventName
        createdAt
        updatedAt
        searchableType
        searchableRead
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchOrderPrints = /* GraphQL */ `
  query SearchOrderPrints(
    $filter: SearchableOrderPrintFilterInput
    $sort: SearchableOrderPrintSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchOrderPrints(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        exchangeID
        userID
        creatorID
        checkoutReceiptID
        prodigiOrderID
        prodigiResponse
        shipmentMethod
        currencyCode
        totalPrice
        createdAt
        updatedAt
        orderStatus
        paymentStatus
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
export const syncOrderPrints = /* GraphQL */ `
  query SyncOrderPrints(
    $filter: ModelOrderPrintFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrderPrints(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        exchangeID
        userID
        creatorID
        checkoutReceiptID
        prodigiOrderID
        prodigiResponse
        shipmentMethod
        currencyCode
        totalPrice
        createdAt
        updatedAt
        orderStatus
        paymentStatus
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const discourseGetPosts = /* GraphQL */ `
  query DiscourseGetPosts($input: discourseInput!) {
    discourseGetPosts(input: $input)
  }
`;
export const discourseGetPost = /* GraphQL */ `
  query DiscourseGetPost($input: discoursePostInput!) {
    discourseGetPost(input: $input)
  }
`;
export const discourseGetReplies = /* GraphQL */ `
  query DiscourseGetReplies($input: discourseGetRepliesInput!) {
    discourseGetReplies(input: $input)
  }
`;
export const getSettings = /* GraphQL */ `
  query GetSettings($id: ID!) {
    getSettings(id: $id) {
      id
      whitelisted_email
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listSettings = /* GraphQL */ `
  query ListSettings(
    $filter: ModelSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        whitelisted_email
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSettings = /* GraphQL */ `
  query SyncSettings(
    $filter: ModelSettingsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        whitelisted_email
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserMedia = /* GraphQL */ `
  query GetUserMedia($id: ID!) {
    getUserMedia(id: $id) {
      id
      avatarUrl
      avatarVideoUrl
      coverUrl
      fanclubLogoUrl
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listUserMedias = /* GraphQL */ `
  query ListUserMedias(
    $filter: ModelUserMediaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMedias(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        avatarUrl
        avatarVideoUrl
        coverUrl
        fanclubLogoUrl
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserMedias = /* GraphQL */ `
  query SyncUserMedias(
    $filter: ModelUserMediaFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserMedias(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        avatarUrl
        avatarVideoUrl
        coverUrl
        fanclubLogoUrl
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserStatistics = /* GraphQL */ `
  query SyncUserStatistics(
    $filter: ModelUserStatisticsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserStatistics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        followerCount
        followingCount
        collectibleCreatedCount
        collectibleCollectedCount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      owner
      accountID
      editors
      email
      username
      firstName
      lastName
      displayName
      type
      bio
      style
      links {
        facebook
        youtube
        instagram
        twitter
        spotify
        soundcloud
        email
        website
        tiktok
        snapchat
        appleMusic
        clubhouse
        __typename
      }
      notifications {
        __typename
      }
      tags
      verified
      fanProfile
      creatorCategory
      emailVerified
      joinDate
      totalSales
      totalSalesVolume
      totalPurchase
      totalCollectionValues
      totalLikeCollectible
      isHidden
      profileTemplate
      address {
        recipientName
        line1
        country
        stateName
        city
        postCode
        phone
        __typename
      }
      shippingAddress {
        recipientName
        line1
        country
        stateName
        city
        postCode
        phone
        __typename
      }
      walletAddresses
      fee {
        salesFee
        royaltyFee
        __typename
      }
      discourseData {
        userID
        username
        category
        channels
        __typename
      }
      status
      createdAt
      updatedAt
      searchableVerified
      _version
      _deleted
      _lastChangedAt
      media {
        id
        avatarUrl
        avatarVideoUrl
        coverUrl
        fanclubLogoUrl
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      statistics {
        id
        followerCount
        followingCount
        collectibleCreatedCount
        collectibleCollectedCount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        __typename
      }
      currentOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        type
        bio
        style
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        totalSales
        totalSalesVolume
        totalPurchase
        totalCollectionValues
        totalLikeCollectible
        isHidden
        profileTemplate
        walletAddresses
        status
        createdAt
        updatedAt
        searchableVerified
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      managers {
        nextToken
        startedAt
        __typename
      }
      profileManaged {
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        type
        bio
        style
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        totalSales
        totalSalesVolume
        totalPurchase
        totalCollectionValues
        totalLikeCollectible
        isHidden
        profileTemplate
        walletAddresses
        status
        createdAt
        updatedAt
        searchableVerified
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userByUsername = /* GraphQL */ `
  query UserByUsername(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        type
        bio
        style
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        totalSales
        totalSalesVolume
        totalPurchase
        totalCollectionValues
        totalLikeCollectible
        isHidden
        profileTemplate
        walletAddresses
        status
        createdAt
        updatedAt
        searchableVerified
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userByAccountAndVerified = /* GraphQL */ `
  query UserByAccountAndVerified(
    $accountID: ID
    $verified: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByAccountAndVerified(
      accountID: $accountID
      verified: $verified
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        type
        bio
        style
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        totalSales
        totalSalesVolume
        totalPurchase
        totalCollectionValues
        totalLikeCollectible
        isHidden
        profileTemplate
        walletAddresses
        status
        createdAt
        updatedAt
        searchableVerified
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userByOwner = /* GraphQL */ `
  query UserByOwner(
    $owner: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        type
        bio
        style
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        totalSales
        totalSalesVolume
        totalPurchase
        totalCollectionValues
        totalLikeCollectible
        isHidden
        profileTemplate
        walletAddresses
        status
        createdAt
        updatedAt
        searchableVerified
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        type
        bio
        style
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        totalSales
        totalSalesVolume
        totalPurchase
        totalCollectionValues
        totalLikeCollectible
        isHidden
        profileTemplate
        walletAddresses
        status
        createdAt
        updatedAt
        searchableVerified
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userByVerifiedJoinDate = /* GraphQL */ `
  query UserByVerifiedJoinDate(
    $verified: CustomBooleanType
    $joinDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByVerifiedJoinDate(
      verified: $verified
      joinDate: $joinDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        type
        bio
        style
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        totalSales
        totalSalesVolume
        totalPurchase
        totalCollectionValues
        totalLikeCollectible
        isHidden
        profileTemplate
        walletAddresses
        status
        createdAt
        updatedAt
        searchableVerified
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userByFanprofileUsername = /* GraphQL */ `
  query UserByFanprofileUsername(
    $fanProfile: CustomBooleanType
    $username: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByFanprofileUsername(
      fanProfile: $fanProfile
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        type
        bio
        style
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        totalSales
        totalSalesVolume
        totalPurchase
        totalCollectionValues
        totalLikeCollectible
        isHidden
        profileTemplate
        walletAddresses
        status
        createdAt
        updatedAt
        searchableVerified
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: SearchableUserSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        type
        bio
        style
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        totalSales
        totalSalesVolume
        totalPurchase
        totalCollectionValues
        totalLikeCollectible
        isHidden
        profileTemplate
        walletAddresses
        status
        createdAt
        updatedAt
        searchableVerified
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        type
        bio
        style
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        totalSales
        totalSalesVolume
        totalPurchase
        totalCollectionValues
        totalLikeCollectible
        isHidden
        profileTemplate
        walletAddresses
        status
        createdAt
        updatedAt
        searchableVerified
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncProfileManagers = /* GraphQL */ `
  query SyncProfileManagers(
    $filter: ModelProfileManagerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProfileManagers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        profileID
        managerID
        accountID
        firstName
        lastName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        editors
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchEmailWaitlists = /* GraphQL */ `
  query SearchEmailWaitlists(
    $filter: SearchableEmailWaitlistFilterInput
    $sort: SearchableEmailWaitlistSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchEmailWaitlists(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        email
        firstName
        surName
        phone
        status
        interestedAs
        verified
        verifiedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
export const syncEmailWaitlists = /* GraphQL */ `
  query SyncEmailWaitlists(
    $filter: ModelEmailWaitlistFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEmailWaitlists(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        email
        firstName
        surName
        phone
        status
        interestedAs
        verified
        verifiedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getWallet = /* GraphQL */ `
  query GetWallet($id: ID!) {
    getWallet(id: $id) {
      id
      amount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      walletOwner {
        id
        owner
        accountID
        editors
        email
        username
        firstName
        lastName
        displayName
        type
        bio
        style
        tags
        verified
        fanProfile
        creatorCategory
        emailVerified
        joinDate
        totalSales
        totalSalesVolume
        totalPurchase
        totalCollectionValues
        totalLikeCollectible
        isHidden
        profileTemplate
        walletAddresses
        status
        createdAt
        updatedAt
        searchableVerified
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listWallets = /* GraphQL */ `
  query ListWallets(
    $filter: ModelWalletFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWallets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        amount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncWallets = /* GraphQL */ `
  query SyncWallets(
    $filter: ModelWalletFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWallets(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        amount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchWalletHistories = /* GraphQL */ `
  query SearchWalletHistories(
    $filter: SearchableWalletHistoryFilterInput
    $sort: SearchableWalletHistorySortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchWalletHistories(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        senderID
        receiverID
        paymentProvider
        debit
        credit
        invoiceID
        referenceID
        exchangeID
        balance
        transactionType
        status
        description
        currency
        owner
        createdAt
        updatedAt
        searchableTransactionType
        searchableStatus
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
export const syncWalletHistories = /* GraphQL */ `
  query SyncWalletHistories(
    $filter: ModelWalletHistoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWalletHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        senderID
        receiverID
        paymentProvider
        debit
        credit
        invoiceID
        referenceID
        exchangeID
        balance
        transactionType
        status
        description
        currency
        owner
        createdAt
        updatedAt
        searchableTransactionType
        searchableStatus
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
