import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Box, FormField, Input, Modal, Button, Text } from '@components';
import { useWithdrawal } from '@libs/hooks/withdrawal';

const WithdrawalVerify = ({ isOpen, form, setForm, onCancel, action, withdrawalID }) => {
  const { t } = useTranslation();
  const { verify: verifyWithdrawal, resend, loading } = useWithdrawal();
  const [timer, setTimer] = useState(60);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({
    mode: 'onBlur',
    shouldFocusError: false
  });
  const submitModalActionClose = () => {
    onCancel();
    reset();
  };
  const onSubmit = async (values) => {
    await verifyWithdrawal(withdrawalID, values.code);
    action();
    reset();
  };

  const onResend = async () => {
    if (timer < 1) {
      await resend(withdrawalID);
      setTimer(60);
    }
  };

  const supportEmail = 'support@patrons.art';

  useEffect(() => {
    let interval;
    if (isOpen) {
      interval = setInterval(() => {
        if (timer > 0) setTimer(timer - 1);
      }, 1000);

      if (timer < 1) {
        clearInterval(interval);
      }
    }

    return () => clearInterval(interval);
  }, [timer, isOpen]);

  useEffect(() => {
    setTimer(60);
  }, [withdrawalID]);

  return (
    <Modal
      showCloseIcon
      heading={t('p.components.withdrawalModal.verify.title')}
      isOpen={isOpen}
      onClose={() => submitModalActionClose()}
    >
      <Box className="withdraw-verify-modal text-base lg:text-lg">
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Text className="pb-5 text-base lg:text-lg" as="p">
            {t('p.components.withdrawalModal.verify.information')}
          </Text>
          <FormField>
            <Input
              size="lg"
              required
              label={t('p.components.withdrawalModal.verify.fields.code.label')}
              placeholder={t('p.components.withdrawalModal.verify.fields.code.placeholder')}
              {...register('code', {
                required: {
                  value: true,
                  message: t('p.components.withdrawalModal.verify.fields.code.required')
                }
              })}
              errorMessage={errors.code?.message}
            />
          </FormField>
          <Text className="pb-1 withdrawal-modal-description mb-6" as="p">
            <Trans i18nKey="p.components.withdrawalModal.verify.dontReceiveCode">
              <span className="font-medium text-secondary cursor-pointer">
                {{ resendCode: 'Resend Code' }}
              </span>
            </Trans>
          </Text>
          <Text className="pb-1 text-base lg:text-lg" as="p">
            {t('p.components.withdrawalModal.verify.cantAccess')}
          </Text>
          <Text className="withdrawal-modal-description text-base lg:text-lg" as="p">
            <Trans i18nKey="p.components.withdrawalModal.verify.emailSupport">
              <span
                className="font-medium text-secondary"
                onClick={onResend}
                href={`mailto:support@patrons.art`}
              >
                {{ supportEmail }}
              </span>
            </Trans>
          </Text>

          <Box className="withdrawal-modal-button">
            <Button className="mr-2" fullWidth darkOutline onClick={() => submitModalActionClose()}>
              {t('p.components.withdrawalModal.button.cancel')}
            </Button>
            <Button as="submit" fullWidth loading={loading}>
              {t('p.components.withdrawalModal.button.submit')}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default WithdrawalVerify;
