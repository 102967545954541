import { useState } from 'react';
import cx from 'classnames';
import { Box } from '@components';
import { ReactComponent as AccordionUp } from '@assets/svg/patrons/accordionUp.svg';
import { useTranslation, Trans } from 'react-i18next';
import { ReactComponent as AccordionDown } from '@assets/svg/patrons/accordionDown.svg';
import { ReactComponent as Search } from '@assets/svg/search-artists.svg';
import { ReactComponent as Share } from '@assets/svg/share-it.svg';
import { ReactComponent as MintIcon } from '@assets/svg/patrons/mint.svg';
import { ReactComponent as TradeIcon } from '@assets/svg/patrons/trade.svg';
import { useAuth } from '@libs/contexts/auth';

function CollectibleVTMS({ collectible, collection }) {
  const [faqIndex, setFaqIndex] = useState(99);
  const { t } = useTranslation();
  const { user } = useAuth();
  return (
    <Box style={{ marginTop: '0px' }} className="collectible-accordion">
      <Box
        className={cx(
          'collectible-accordion-item',
          faqIndex === 0 ? 'collectible-accordion-item-active' : ''
        )}
      >
        <Box
          className="collectible-accordion-heading"
          onClick={() => setFaqIndex(faqIndex === 0 ? null : 0)}
        >
          <Box className="flex items-center text-base md:text-xl mr-auto">
            <Search className="mr-2" />
            {t('p.congratulation.view.label')}
          </Box>
          <span className="collectible-accordion-icon">
            {faqIndex === 0 ? <AccordionUp /> : <AccordionDown />}
          </span>
        </Box>

        <div className="collectible-accordion-content">
          <Box className="collectible-accordion-content-title">
            {t('p.congratulation.view.profile')}
          </Box>
          <Box className="collectible-accordion-content-link">
            <Trans i18nKey="p.congratulation.view.profileLink">
              {{ username: user?.username || 'username' }}
            </Trans>
          </Box>
          <Box className="collectible-accordion-content-title mt-2">
            {t('p.congratulation.view.artist')}
          </Box>
          <Box className="collectible-accordion-content-link">
            <Trans i18nKey="p.congratulation.view.artistLink">
              {{ username: collectible?.creator?.username }}
            </Trans>
          </Box>
          {collection?.slug && (
            <>
              <Box className="collectible-accordion-content-title mt-2">
                {t('p.congratulation.view.gallery')}
              </Box>
              <Box className="collectible-accordion-content-link">
                <Trans i18nKey="p.congratulation.view.galleryLink">
                  {{ galleryName: collection?.slug }}
                </Trans>
              </Box>
            </>
          )}
        </div>
      </Box>
      <Box
        className={cx(
          'collectible-accordion-item',
          faqIndex === 1 ? 'collectible-accordion-item-active' : ''
        )}
      >
        <Box
          className="collectible-accordion-heading"
          onClick={() => setFaqIndex(faqIndex === 1 ? null : 1)}
        >
          <Box className="flex items-center text-base md:text-xl mr-auto">
            <TradeIcon className="mr-2" />
            {t('p.congratulation.tradeIt.label')}
          </Box>
          <span className="collectible-accordion-icon">
            {faqIndex === 1 ? <AccordionUp /> : <AccordionDown />}
          </span>
        </Box>

        <div className="collectible-accordion-content">
          <Box className="collectible-accordion-content-description text-left">
            {t('p.pages.checkout.tradeIt.description')}
          </Box>
        </div>
      </Box>
      <Box
        className={cx(
          'collectible-accordion-item',
          faqIndex === 2 ? 'collectible-accordion-item-active' : ''
        )}
      >
        <Box
          className="collectible-accordion-heading"
          onClick={() => setFaqIndex(faqIndex === 2 ? null : 2)}
        >
          <Box className="flex items-center text-base md:text-xl mr-auto">
            <MintIcon className="mr-2" />
            {t('p.congratulation.mintIt.label')}
          </Box>
          <span className="collectible-accordion-icon">
            {faqIndex === 2 ? <AccordionUp /> : <AccordionDown />}
          </span>
        </Box>

        <div className="collectible-accordion-content">
          <Box className="collectible-accordion-content-description text-left">
            {t('p.pages.checkout.mintIt.description')}
          </Box>
        </div>
      </Box>
      <Box
        className={cx(
          'collectible-accordion-item',
          faqIndex === 3 ? 'collectible-accordion-item-active' : ''
        )}
      >
        <Box
          className="collectible-accordion-heading"
          onClick={() => setFaqIndex(faqIndex === 3 ? null : 3)}
        >
          <Box className="flex items-center text-base md:text-xl mr-auto">
            <Share className="mr-2" />
            {t('p.pages.checkout.shareIt.label')}
          </Box>
          <span className="collectible-accordion-icon">
            {faqIndex === 3 ? <AccordionUp /> : <AccordionDown />}
          </span>
        </Box>

        <div className="collectible-accordion-content">
          <Box className="collectible-accordion-content-description text-left">
            {t('p.pages.checkout.shareIt.description')}
          </Box>
        </div>
      </Box>
    </Box>
  );
}

export default CollectibleVTMS;
