import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { useForm } from 'react-hook-form';

import {
  Modal,
  Box,
  Grid,
  Button,
  Text,
  Input,
  OrderSummary,
  FormField,
  Select,
  Container,
  RadioButton,
  Checkbox,
  Link
} from '@components';

import { useAuth } from '@libs/contexts/auth';
import { useUser } from '@libs/hooks/user';
import { ReactComponent as CreditCard } from '@assets/svg/credit-card.svg';
import CCAcceptedImage from '@assets/img/cc-accepted.png';
import config from '@/config';
import { usePurchasePrinted } from '@libs/hooks/prodigi';
import {
  colorProdigiKeys,
  itemTypes,
  shippingKeys,
  skuFrame
} from '@pages/print-collectible/fansinc/utils';
import PrintCheckoutFrame from '@pages/print-collectible/patrons/print-checkout/print-checkout-frame';
import PrintCollectibleSummary from '@pages/print-collectible/patrons/print-checkout/print-collectible-summary';
import { ReactComponent as Truck } from '@assets/svg/truck.svg';

function PrintCollectibleCheckoutModal({
  isOpen,
  setIsOpen,
  item,
  itemType,
  selectedSize,
  selectedFrame,
  quantity,
  previewImage,
  price,
  selectedCountry,
  setSelectedCountry,
  setShowCheckout,
  topRef,
  countries,
  exchangeID,
  loading,
  size
}) {
  const { cdnUrl } = config;
  const { t } = useTranslation();
  const { user } = useAuth();
  const { updateLoading, onUpdate } = useUser();
  const [selectedShipping, setSelectedShipping] = useState(shippingKeys.REGULAR);
  const [checked, setChecked] = useState(false);
  const [showError, setShowError] = useState(false);
  const { onUpdatePrintMetadata, loading: updatePrintMetadataLoading } = usePurchasePrinted();
  const setSelectedShippingCallback = useCallback(setSelectedShipping, [setSelectedShipping]);
  const [paymentMethod, setPaymentMethod] = useState(
    process.env.REACT_APP_AMPLIFY_ENV !== 'prod' ? 'CREDIT_CARD' : null
  );
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const onClose = () => {
    setIsOpen(false);
  };

  if (itemType !== itemTypes.EXCHANGE) {
    return null;
  }

  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
    setFocus,
    setValue
  } = useForm({
    mode: 'onChange'
  });

  const onSubmitForm = useCallback(
    async (data) => {
      const payload = {
        firstName: user?.firstName,
        lastName: user?.lastName,
        bio: user?.bio,
        links: {
          facebook: user?.links?.facebook,
          instagram: user?.links?.instagram,
          twitter: user?.links?.twitter,
          email: user?.links?.email,
          website: user?.links?.website
        },
        address: {
          line1: user?.address?.line1,
          country: user?.address?.country,
          stateName: user?.address?.stateName,
          city: user?.address?.city,
          postCode: user?.address?.postCode,
          phone: user?.address?.phone,
          recipientName: user?.shippingAddress?.recipientName
        },
        shippingAddress: data.address,
        addressForm: true
      };
      await onUpdate(payload);
      if (checked) {
        setShowError(false);
      } else {
        setShowError(true);
        return;
      }
      setIsSubmittingForm(true);
    },
    [checked]
  );

  // console.log(watch('agree'));

  const inputData = {
    sku: selectedSize,
    copies: quantity,
    color: 'White',
    exchangeID: exchangeID
  };
  const onUpdateUser = async (data) => {
    const payload = {
      firstName: user?.firstName,
      lastName: user?.lastName,
      bio: user?.bio,
      links: {
        facebook: user?.links?.facebook,
        instagram: user?.links?.instagram,
        twitter: user?.links?.twitter,
        email: user?.links?.email,
        website: user?.links?.website
      },
      address: {
        line1: user?.address?.line1,
        country: user?.address?.country,
        stateName: user?.address?.stateName,
        city: user?.address?.city,
        postCode: user?.address?.postCode,
        phone: user?.address?.phone,
        recipientName: user?.shippingAddress?.recipientName
      },
      shippingAddress: data.address,
      addressForm: true
    };
    onUpdate(payload);
  };

  useEffect(async () => {
    setValue('address.country', selectedCountry);
  }, [selectedCountry]);

  useEffect(async () => {
    await onUpdatePrintMetadata({
      exchangeID,
      frame: selectedSize,
      cost: price,
      url: `${cdnUrl}/public/collectibles/printed/${exchangeID}${size}${selectedSize}.png`,
      certificateUrl: `${cdnUrl}/public/collectibles/printed/certificate${exchangeID}${size}${selectedSize}.png`
    });
  }, [price]);

  return (
    <Container className="print-collectible-checkout-modal-patrons">
      <form>
        <Grid className="print-collectible-checkout-modal-patrons__grid">
          <Box className="print-collectible-checkout-modal-patrons__grid__left">
            <Box className="print-collectible-checkout-modal-patrons__grid__subheading">
              <Text as="span">
                {t('printCollectiblePage.checkoutModal.headings.shippingDetails')}
              </Text>
            </Box>
            <Box className="print-collectible-checkout-modal-patrons__grid__left__item">
              <FormField>
                <Input
                  placeholder={t(
                    'printCollectiblePage.checkoutModal.fields.recipientName.placeholder'
                  )}
                  label={t('printCollectiblePage.checkoutModal.fields.recipientName.label')}
                  defaultValue={user?.shippingAddress?.recipientName}
                  errorMessage={errors.address?.recipientName?.message}
                  {...register('address.recipientName', {
                    required: {
                      value: true,
                      message: t('printCollectiblePage.checkoutModal.fields.recipientName.required')
                    }
                  })}
                  onBlur={handleSubmit(onUpdateUser)}
                />
              </FormField>
            </Box>
            <Box className="print-collectible-checkout-modal-patrons__grid__left__item">
              <FormField>
                <Input
                  placeholder={t(
                    'printCollectiblePage.checkoutModal.fields.streetAddress.placeholder'
                  )}
                  label={t('printCollectiblePage.checkoutModal.fields.streetAddress.label')}
                  defaultValue={user?.shippingAddress?.line1}
                  errorMessage={errors.address?.line1?.message}
                  {...register('address.line1', {
                    required: {
                      value: true,
                      message: t('printCollectiblePage.checkoutModal.fields.streetAddress.required')
                    }
                  })}
                  onBlur={handleSubmit(onUpdateUser)}
                />
              </FormField>
            </Box>
            <Box className="print-collectible-checkout-modal-patrons__grid__left__item">
              <FormField>
                <Input
                  placeholder={t('printCollectiblePage.checkoutModal.fields.city.placeholder')}
                  label={t('printCollectiblePage.checkoutModal.fields.city.label')}
                  errorMessage={errors.address?.city?.message}
                  defaultValue={user?.shippingAddress?.city}
                  {...register('address.city', {
                    required: {
                      value: true,
                      message: t('printCollectiblePage.checkoutModal.fields.city.required')
                    }
                  })}
                  onBlur={handleSubmit(onUpdateUser)}
                />
              </FormField>
            </Box>
            <Box className="print-collectible-checkout-modal-patrons__grid__left__item">
              <Box className="print-collectible-checkout-modal-patrons__grid__left__item__flex">
                <Box>
                  <FormField>
                    <Input
                      placeholder={t('printCollectiblePage.checkoutModal.fields.state.placeholder')}
                      label={t('printCollectiblePage.checkoutModal.fields.state.label')}
                      errorMessage={errors?.address?.stateName?.message}
                      defaultValue={user?.shippingAddress?.stateName}
                      {...register('address.stateName', {
                        required: {
                          value: true,
                          message: t('printCollectiblePage.checkoutModal.fields.state.required')
                        }
                      })}
                      onBlur={handleSubmit(onUpdateUser)}
                    />
                  </FormField>
                </Box>
                <Box>
                  <FormField>
                    <Input
                      placeholder={t(
                        'printCollectiblePage.checkoutModal.fields.postcode.placeholder'
                      )}
                      label={t('printCollectiblePage.checkoutModal.fields.postcode.label')}
                      errorMessage={errors?.address?.postCode?.message}
                      defaultValue={user?.shippingAddress?.postCode}
                      {...register('address.postCode', {
                        required: {
                          value: true,
                          message: t('printCollectiblePage.checkoutModal.fields.postcode.required')
                        }
                      })}
                      onBlur={handleSubmit(onUpdateUser)}
                    />
                  </FormField>
                </Box>
              </Box>
            </Box>
            <Box className="print-collectible-checkout-modal-patrons__grid__left__item">
              <FormField>
                <Select
                  fullWidth
                  errorMessage={errors.address?.country?.message}
                  options={countries}
                  {...register('address.country', {
                    required: {
                      value: true,
                      message: t('printCollectiblePage.checkoutModal.fields.country.required')
                    }
                  })}
                  label={t('printCollectiblePage.checkoutModal.fields.country.label')}
                  onChange={(e) => {
                    handleSubmit(onUpdateUser);
                    setSelectedCountry(e.target.value);
                  }}
                />
              </FormField>
            </Box>
            <Box className="print-collectible-checkout-modal-patrons__grid__left__item">
              <FormField>
                <Input
                  placeholder={t('printCollectiblePage.checkoutModal.fields.phone.placeholder')}
                  label={t('printCollectiblePage.checkoutModal.fields.phone.label')}
                  errorMessage={errors.address?.phone?.message}
                  defaultValue={user?.shippingAddress?.phone}
                  {...register('address.phone', {
                    required: {
                      value: true,
                      message: t('printCollectiblePage.checkoutModal.fields.phone.required')
                    },
                    pattern: {
                      value: /^\d+$/,
                      message: t('printCollectiblePage.checkoutModal.fields.phone.invalid')
                    }
                  })}
                  onBlur={handleSubmit(onUpdateUser)}
                />
              </FormField>
            </Box>
            <Box className="print-collectible-checkout-modal-patrons__grid__subheading">
              <Text as="span">
                {t('printCollectiblePage.checkoutModal.headings.paymentMethod')}
              </Text>
            </Box>
            <Box className="checkout__method-active checkout__method">
              <FormField className="print-collectible-checkout-modal-patrons__grid__checkout__label">
                <Box className="checkout__radio-icon">
                  <Box className="checkout__radio_label font-bold text-lg">Credit Card</Box>
                  <Box>
                    <img src={CCAcceptedImage} alt="Credit Card Accepted" />
                  </Box>
                </Box>
              </FormField>
              <Box className="checkout_card">
                <PrintCheckoutFrame
                  selectedShipping={selectedShipping}
                  submitForm={isSubmittingForm}
                  setSubmitForm={setIsSubmittingForm}
                  inputData={inputData}
                />
              </Box>
            </Box>
          </Box>
          <Box className="print-collectible-checkout-modal-patrons__grid__right">
            <Box className="ml-10">
              {item && (
                <PrintCollectibleSummary
                  exchange={item}
                  selectedSize={selectedSize}
                  selectedFrame={selectedFrame}
                  quantity={quantity}
                  size={size}
                />
              )}
              <Box className={`print-collectible-checkout-modal-patrons__grid__right__preview`}>
                <img src={previewImage} />
              </Box>
            </Box>
            <hr />
            <Box className="ml-10 print-collectible-checkout-modal-patrons__button">
              <OrderSummary showVAT={true} price={price} marginSize="md" />
              <Box className="pt-5 text-primary items-center flex">
                <Checkbox type="checkbox" onClick={(e) => setChecked(e.target.checked)}></Checkbox>
                <Trans key="fixedPricePurchase" i18nKey="p.pages.checkout.checkoutTerms">
                  <Link
                    className="underline ml-1"
                    target="_blank"
                    to="/terms-and-conditions"
                  ></Link>
                </Trans>
              </Box>
              {showError && (
                <Box style={{ color: '#B91C1C' }} className="text-sm mt-2">
                  {t('p.pages.checkout.check')}
                </Box>
              )}
              <Button
                fullWidth
                onClick={handleSubmit(onSubmitForm)}
                loading={isSubmittingForm || loading || updateLoading}
                disabled={isSubmittingForm || loading}
                className="mt-8 uppercase"
              >
                {t('printCollectiblePage.checkoutModal.submit')}
              </Button>
              <Button
                onClick={() => {
                  setShowCheckout(false);
                  topRef.current.scrollIntoView();
                }}
                darkOutline
                fullWidth
                className="mt-2"
              >
                {t('printCollectiblePage.checkoutModal.back')}
              </Button>
            </Box>
          </Box>
        </Grid>
      </form>
    </Container>
  );
}

PrintCollectibleCheckoutModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func
};

export default PrintCollectibleCheckoutModal;
