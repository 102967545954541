import { Box, Button, CollectibleCard, CollectibleSkeleton, EmptyBox } from '@components';
import { useUserCollectibleLike } from '@libs/hooks/collectible-like';
import { useTranslation } from 'react-i18next';

function Loved({ userID }) {
  const { t } = useTranslation();
  const {
    data: collectibles,
    pageToken: nextCollectibleToken,
    loading,
    getData: fetchCollectibles
  } = useUserCollectibleLike(userID);

  const nextData = async () => {
    await fetchCollectibles(userID, nextCollectibleToken, false);
  };

  return (
    <>
      {collectibles?.length > 0 ? (
        <Box className="profile-fans__collectible__grid">
          <Box className="profile-fans__collectible__grid__wrapper">
            <>
              {collectibles?.map((collectible) => (
                <CollectibleCard
                  key={collectible.id}
                  collectible={collectible?.collectible}
                  showPrice={false}
                  onVisit={collectible?.onVisit === 'TRUE'}
                ></CollectibleCard>
              ))}
            </>
          </Box>
          {nextCollectibleToken && (
            <Button loading={loading} onClick={() => nextData()} className="mt-10">
              {t('p.pages.profile.artist.loadMore')}
            </Button>
          )}
        </Box>
      ) : (
        <>
          {loading ? (
            <>
              <Box className="profile-fans__collectible__grid">
                <Box className="profile-fans__collectible__grid__wrapper">
                  <CollectibleSkeleton />
                  <CollectibleSkeleton />
                  <CollectibleSkeleton />
                </Box>
              </Box>
            </>
          ) : (
            <EmptyBox title="No Artworks Found" />
          )}
        </>
      )}
    </>
  );
}

export default Loved;
