import { orientations } from '../utils';
import stylesPortrait from './portrait';
import stylesLandscape from './landscape';

const documentStyles = ({ size, orientation }) => {
  switch (orientation) {
    case orientations.PORTRAIT:
      return stylesPortrait[size];
    case orientations.LANDSCAPE:
      return stylesLandscape[size];
    default:
      return stylesPortrait[size];
  }
};

export default documentStyles;
