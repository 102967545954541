import { fansEmailFragment } from '@libs/fragments/user';

const findByEmail = /* GraphQL */ `
  query FindByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...FansEmailOnly
      }
    }
  }

  ${fansEmailFragment}
`;

export default findByEmail;
