import cx from 'classnames';
import { useState, useCallback, useEffect } from 'react';
import {
  CollectibleCard,
  Box,
  Text,
  Separator,
  Button,
  CollectibleSkeleton,
  EmptyBox,
  ResponsiveModal,
  CustomDropdown
} from '@components';
import { ReactComponent as ArrowDownIcon } from '@assets/svg/arrow-down.svg';
import { useOutsider } from '@libs/utils/outsider';
import { useTranslation } from 'react-i18next';
import { genres } from '@libs/utils/options';
import { ReactComponent as FilterIcon } from '@assets/svg/patrons/sliders-horizontal.svg';
import { useSearchCollectiblesByOwner } from '@libs/hooks/collectible';

function Collectible({ profile }) {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const {
    data: latestCollectibles,
    pageToken,
    loading,
    total,
    onChangeGenre,
    onSort,
    onPaginate
  } = useSearchCollectiblesByOwner(profile?.username, true, 'TITLE_ASC');

  useEffect(() => {
    if (total > 6) {
      setOpenFilter(true);
    }
  }, [total]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: EmptyBox
  };

  const nextPage = () => {
    onPaginate(pageToken);
  };

  //SORTING
  const [isOpenSorting, setOpenSorting] = useState(false);
  const handleCloseSort = () => setOpenSorting(false);
  const { ref: refSort } = useOutsider(handleCloseSort);
  const [sort, setSort] = useState('TITLE_ASC');
  const handleOpenSorting = useCallback(
    (e) => {
      e.preventDefault();
      setOpenSorting(!isOpenSorting);
    },
    [isOpenSorting]
  );

  const getSortLabel = () => {
    const filter = listSort.filter((list) => {
      return list.value === sort;
    });
    return filter[0].label;
  };

  const listSort = [
    {
      label: 'Name (A - Z)',
      value: 'TITLE_ASC'
    },
    {
      label: 'Name (Z - A)',
      value: 'TITLE_DESC'
    },
    {
      label: 'Recently Listed',
      value: 'CREATED_AT_DESC'
    }
  ];

  useEffect(() => {
    onSort(sort);
  }, [sort]);

  const listStyle = [
    {
      label: 'ALL',
      value: ''
    },
    ...genres
  ];
  const [isOpenStyle, setOpenStyle] = useState(false);
  const [styleName, setStyleName] = useState('All');
  const handleCloseStyle = () => setOpenStyle(false);
  const { ref: refStyle } = useOutsider(handleCloseStyle);
  const [style, setStyle] = useState('');
  const handleOpenStyle = useCallback(
    (e) => {
      e.preventDefault();
      setOpenStyle(!isOpenStyle);
    },
    [isOpenStyle]
  );

  useEffect(() => {
    onChangeGenre(style);
  }, [style]);

  const resetFilter = () => {
    setStyle('');
    setTimeout(() => {
      setSort('TITLE_ASC');
    }, 50);
  };

  return (
    <>
      {openFilter && (
        <Box className="profile-artist__collectible__filter">
          <Box className="marketplace-page_search mr-auto">
            <Box className="marketplace-page__search-sorting">
              <Text className="marketplace-page__search-sorting-header" onClick={handleOpenStyle}>
                <Text as="span" className="marketplace-page__search-sorting-header__type">
                  {t('p.pages.profile.artist.style')}
                </Text>
                <Text as="span" className="marketplace-page__search-sorting-header__item">
                  {styleName}
                  <ArrowDownIcon />
                </Text>
              </Text>
              {isOpenStyle && (
                <Box className="select-multiple__dropdown overflow-hidden" ref={refStyle}>
                  <ul>
                    {genres &&
                      listStyle &&
                      listStyle.map((s) => (
                        <>
                          <li
                            className={cx(style === s.value && 'active')}
                            key={s.value}
                            value={s.value}
                            onClick={() => {
                              setStyle(s.value);
                              setStyleName(s.label);
                              handleCloseStyle();
                            }}
                          >
                            {s.label}
                          </li>
                          <Separator />
                        </>
                      ))}
                  </ul>
                </Box>
              )}
            </Box>
          </Box>
          <Box className="marketplace-page_search">
            <Box className="marketplace-page__search-sorting">
              <Text className="marketplace-page__search-sorting-header" onClick={handleOpenSorting}>
                <Text as="span" className="marketplace-page__search-sorting-header__type">
                  {t('p.pages.profile.artist.sort')}
                </Text>
                <Text as="span" className="marketplace-page__search-sorting-header__item">
                  {getSortLabel()}
                  <ArrowDownIcon />
                </Text>
              </Text>
              {isOpenSorting && (
                <Box className="select-multiple__dropdown overflow-hidden" ref={refSort}>
                  <ul>
                    {listSort &&
                      listSort.map((o) => (
                        <>
                          <li
                            className={cx(sort === o.value && 'active')}
                            key={o.value}
                            value={o.value}
                            onClick={() => {
                              setSort(o.value);
                              handleCloseSort();
                            }}
                          >
                            {o.label}
                          </li>
                          <Separator />
                        </>
                      ))}
                  </ul>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
      <Box className="profile-artist__responsive">
        <Box className="profile-artist__responsive__title">
          {t('p.pages.profile.artist.artworks')}
        </Box>
        <Box className="responsive-filter" onClick={() => setOpenModal(true)}>
          <FilterIcon />
        </Box>
      </Box>
      <Box className="profile-artist__collectible__grid">
        <Box className="profile-artist__collectible__grid__wrapper">
          {latestCollectibles?.map((collectible) => (
            <CollectibleCard
              key={collectible.id}
              collectible={collectible}
              showPrice={false}
            ></CollectibleCard>
          ))}
          {loading && latestCollectibles?.length < 1 && (
            <>
              <CollectibleSkeleton />
              <CollectibleSkeleton />
              <CollectibleSkeleton />
            </>
          )}
        </Box>
        {pageToken && (
          <Button loading={loading} onClick={() => nextPage()} className="mt-6">
            {t('p.pages.profile.artist.loadMore')}
          </Button>
        )}
        {!loading && latestCollectibles?.length < 1 && (
          <EmptyBox
            title={t('p.pages.profile.artist.empty.title')}
            description={t('p.pages.profile.artist.empty.description')}
          />
        )}
      </Box>
      <ResponsiveModal isOpen={openModal} onClose={() => setOpenModal(false)}>
        <Box className="flex items-center justify-between mb-2">
          <Box className="text-lg font-bold">{t('p.pages.marketplace.filterArtworks')}</Box>
          <Box
            onClick={() => resetFilter()}
            className="text-sm text-secondary underline font-medium"
          >
            {t('p.pages.marketplace.reset')}
          </Box>
        </Box>
        <CustomDropdown value={style} list={listStyle} setValue={setStyle} label="Style" />
        <CustomDropdown value={sort} list={listSort} setValue={setSort} label="Sort" />
        <Button className="mt-5" onClick={() => setOpenModal(false)} fullWidth>
          {t('p.pages.marketplace.close')}
        </Button>
      </ResponsiveModal>
    </>
  );
}

export default Collectible;
