import { Switch, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IoMenu } from 'react-icons/io5';
import { useCallback, useState } from 'react';

import SettingsProfile from '@pages/settings/fansinc/profile';
import SettingsNotifications from '@pages/settings/fansinc/notifications';
import SettingsAccount from '@pages/settings/fansinc/account';
import SettingsSubscriptions from '@pages/settings/fansinc/notifications';
import SettingsOffersBids from '@pages/settings/fansinc/offers-bids';
import SettingsTransaction from '@pages/settings/fansinc/transactions';
import { useOutsider } from '@libs/utils/outsider';
import { Box, Button, Col, Container, Grid, Link, ProtectedRoute } from '@components';

function Settings() {
  const { path } = useRouteMatch();
  const { t } = useTranslation();
  const [isOpenMenu, setOpenMenu] = useState(false);

  const handleClose = () => setOpenMenu(false);
  const { ref } = useOutsider(handleClose);

  const isActive = (menuPath) => {
    return menuPath === path ? 'active' : '';
  };

  const handleOpenSidebarMenu = useCallback(
    (e) => {
      e.preventDefault();
      setOpenMenu(!isOpenMenu);
    },
    [isOpenMenu]
  );

  return (
    <>
      <Container className="page-settings">
        <Grid className="page-settings__wrapper">
          <Col span="2">
            <Box className="settings-sidebar">
              <Link to="/settings" className={isActive('/settings')}>
                {t('settings.menu.profile')}
              </Link>
              <Link to="/settings/account" className={isActive('/settings/account')}>
                {t('settings.menu.account')}
              </Link>
              <Link to="/settings/transactions" className={isActive('/settings/transactions')}>
                {t('settings.menu.transactions')}
              </Link>
              <Link to="/settings/offers-bids" className={isActive('/settings/offers-bids')}>
                {t('settings.menu.offersBids')}
              </Link>
              <Link to="/settings/notifications" className={isActive('/settings/notifications')}>
                {t('settings.menu.notifications')}
              </Link>
            </Box>
            <Box className="settings-sidebar__mobile" ref={ref}>
              <Button onClick={handleOpenSidebarMenu} className="settings-sidebar__mobile__button">
                <IoMenu />
              </Button>
              {isOpenMenu && (
                <Box className="settings-sidebar__mobile__menu" onClick={handleClose}>
                  <Link to="/settings" className={isActive('/settings')}>
                    {t('settings.menu.profile')}
                  </Link>
                  <Link to="/settings/account" className={isActive('/settings/account')}>
                    {t('settings.menu.account')}
                  </Link>
                  <Link to="/settings/transactions" className={isActive('/settings/transactions')}>
                    {t('settings.menu.transactions')}
                  </Link>
                  <Link to="/settings/offers-bids" className={isActive('/settings/offers-bids')}>
                    {t('settings.menu.offersBids')}
                  </Link>
                  <Link
                    to="/settings/notifications"
                    className={isActive('/settings/notifications')}
                  >
                    {t('settings.menu.notifications')}
                  </Link>
                </Box>
              )}
            </Box>
          </Col>
          <Col span="5">
            <Box className="settings-content">
              <Switch>
                <ProtectedRoute exact path="/settings" component={SettingsProfile} />
                <ProtectedRoute
                  exact
                  path="/settings/notifications"
                  component={SettingsNotifications}
                />
                <ProtectedRoute
                  exact
                  path="/settings/subscriptions"
                  component={SettingsSubscriptions}
                />
                <ProtectedRoute exact path="/settings/account" component={SettingsAccount} />
                <ProtectedRoute exact path="/settings/offers-bids" component={SettingsOffersBids} />
                <ProtectedRoute
                  exact
                  path="/settings/transactions"
                  component={SettingsTransaction}
                />
              </Switch>
            </Box>
          </Col>
        </Grid>
      </Container>
    </>
  );
}

export default Settings;
