import { SkeletonLoading } from '@components';
import { isNewCollectibleCard } from '@libs/utils/collectible';

const CollectibleSkeleton = () => {
  const newCard = isNewCollectibleCard();
  return newCard ? (
    <div className="collectible-skeleton">
      <SkeletonLoading className="collectible-skeleton__new" />
    </div>
  ) : (
    <div className="collectible-skeleton">
      <SkeletonLoading className="collectible-skeleton__image" />
      <SkeletonLoading className="collectible-skeleton__title" />
      <div className="collectible-skeleton__button-group">
        <SkeletonLoading className="collectible-skeleton__button" />
      </div>
    </div>
  );
};

export default CollectibleSkeleton;
