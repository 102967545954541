import { StyleSheet } from '@react-pdf/renderer';

import stylesA4Portrait from './a4';

const stylesA2Portrait = StyleSheet.create({
  ...stylesA4Portrait,
  pageContent: {
    ...stylesA4Portrait.pageContent,
    padding: '125px'
  },
  secondCol: {
    ...stylesA4Portrait.secondCol,
    marginLeft: '125px'
  },
  logo: {
    ...stylesA4Portrait.logo,
    width: '112.83px',
    height: '19.03px'
  },
  tier: {
    ...stylesA4Portrait.tier,
    fontSize: 24
  },
  image: {
    ...stylesA4Portrait.image,
    maxWidth: '100%',
    maxHeight: '100%',
    width: '100%',
    height: '100%'
  },
  title: {
    ...stylesA4Portrait.title,
    fontSize: 48
  },
  sub: {
    ...stylesA4Portrait.sub,
    fontSize: 24
  },
  edition: {
    ...stylesA4Portrait.edition,
    fontSize: 50
  },
  qrCode: {
    ...stylesA4Portrait.qrCode,
    width: 100,
    height: 100
  },
  url: {
    ...stylesA4Portrait.url,
    fontSize: 24
  }
});

export default stylesA2Portrait;
