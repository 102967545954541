import cx from 'classnames';
import { Grid, SkeletonLoading } from '@components';

const CreatorCardGrid = ({ loading, children, className, ...props }) => {
  return (
    <Grid className={cx('creator-card-grid', className)}>
      {loading && (
        <>
          <SkeletonLoading className="creator-card-grid__skeleton" />
          <SkeletonLoading className="creator-card-grid__skeleton" />
          <SkeletonLoading className="creator-card-grid__skeleton" />
          <SkeletonLoading className="creator-card-grid__skeleton" />
          <SkeletonLoading className="creator-card-grid__skeleton" />
          <SkeletonLoading className="creator-card-grid__skeleton" />
        </>
      )}
      {children}
    </Grid>
  );
};

export default CreatorCardGrid;
