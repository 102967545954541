import cx from 'classnames';
import { useState, useCallback, useEffect } from 'react';
import {
  CollectibleCard,
  Box,
  Text,
  Separator,
  Button,
  CollectibleSkeleton,
  EmptyBox,
  Input,
  ResponsiveModal,
  CustomDropdown
} from '@components';
import { ReactComponent as ArrowDownIcon } from '@assets/svg/arrow-down.svg';
import { ReactComponent as Search } from '@assets/svg/search-artists.svg';
import { useOutsider } from '@libs/utils/outsider';
import { useTranslation, Trans } from 'react-i18next';
import { InfiniteScroll } from 'react-simple-infinite-scroll-patched';
import { genres } from '@libs/utils/options';
import { ReactComponent as FilterIcon } from '@assets/svg/patrons/sliders-horizontal.svg';
import { useSearchCollectiblesByCollection } from '@libs/hooks/collectible';
import { useFeaturedArtist } from '@libs/hooks/featured-artist';

function Collectible({ collection }) {
  const { activeOptions: artists } = useFeaturedArtist();
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const {
    data: latestCollectibles,
    pageToken,
    loading,
    total,
    onChangeGenre,
    onSort,
    onUpdateTitle,
    onUpdateUser,
    onPaginate
  } = useSearchCollectiblesByCollection(collection?.id, true, 'TITLE_ASC');

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: EmptyBox
  };

  const nextPage = () => {
    onPaginate(pageToken);
  };

  // const [title, setTitle] = useState('');

  //SORTING
  const [isOpenSorting, setOpenSorting] = useState(false);
  const handleCloseSort = () => setOpenSorting(false);
  const { ref: refSort } = useOutsider(handleCloseSort);
  const [sort, setSort] = useState('TITLE_ASC');
  const handleOpenSorting = useCallback(
    (e) => {
      e.preventDefault();
      setOpenSorting(!isOpenSorting);
    },
    [isOpenSorting]
  );

  const getSortLabel = () => {
    const filter = listSort.filter((list) => {
      return list.value === sort;
    });
    return filter[0].label;
  };

  const listSort = [
    {
      label: 'Name (A - Z)',
      value: 'TITLE_ASC'
    },
    {
      label: 'Name (Z - A)',
      value: 'TITLE_DESC'
    },
    {
      label: 'Recently Listed',
      value: 'CREATED_AT_DESC'
    }
  ];

  useEffect(() => {
    onSort(sort);
  }, [sort]);

  //GENRES

  const listStyle = [
    {
      label: 'ALL',
      value: ''
    },
    ...genres
  ];
  const [isOpenStyle, setOpenStyle] = useState(false);
  const [styleName, setStyleName] = useState('All');
  const handleCloseStyle = () => setOpenStyle(false);
  const { ref: refStyle } = useOutsider(handleCloseStyle);
  const [style, setStyle] = useState('');
  const handleOpenStyle = useCallback(
    (e) => {
      e.preventDefault();
      setOpenStyle(!isOpenStyle);
    },
    [isOpenStyle]
  );

  useEffect(() => {
    onChangeGenre(style);
  }, [style]);

  //Artist

  const listArtist = [
    {
      label: 'ALL',
      value: ''
    },
    ...artists
  ];
  const [isOpenArtist, setOpenArtist] = useState(false);
  const [artistName, setArtistName] = useState('All');
  const handleCloseArtist = () => setOpenArtist(false);
  const { ref: refArtist } = useOutsider(handleCloseArtist);
  const [artist, setArtist] = useState('');
  const handleOpenArtist = useCallback(
    (e) => {
      e.preventDefault();
      setOpenArtist(!isOpenArtist);
    },
    [isOpenArtist]
  );

  useEffect(() => {
    onUpdateUser(artist);
  }, [artist]);

  const resetFilter = () => {
    setArtist('');
    setTimeout(() => {
      setStyle('');
    }, 50);
    setTimeout(() => {
      setSort('TITLE_ASC');
    }, 100);
  };

  const [colsActive, setColsActive] = useState(3);

  return (
    <>
      <Box className="profile-artist__collectible__filter">
        <Box className="profile-museum__filter__input">
          <Search />
          <Box className="profile-museum__filter__input__wrapper">
            <Input
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  onUpdateTitle(e.target.value);
                }
              }}
              placeholder={t('p.pages.marketplace.search')}
            />
          </Box>
        </Box>
        <Box className="marketplace-page_search">
          <Box className="marketplace-page__search-sorting">
            <Text className="marketplace-page__search-sorting-header" onClick={handleOpenStyle}>
              <Text as="span" className="marketplace-page__search-sorting-header__type">
                {t('p.pages.profile.artist.style')}
              </Text>
              <Text as="span" className="marketplace-page__search-sorting-header__item">
                {styleName}
                <ArrowDownIcon />
              </Text>
            </Text>
            {isOpenStyle && (
              <Box className="select-multiple__dropdown overflow-hidden" ref={refStyle}>
                <ul>
                  {genres &&
                    listStyle &&
                    listStyle.map((s) => (
                      <>
                        <li
                          className={cx(style === s.value && 'active')}
                          key={s.value}
                          value={s.value}
                          onClick={() => {
                            setStyle(s.value);
                            setStyleName(s.label);
                            handleCloseStyle();
                          }}
                        >
                          {s.label}
                        </li>
                        <Separator />
                      </>
                    ))}
                </ul>
              </Box>
            )}
          </Box>
        </Box>
        <Box className="marketplace-page_search">
          <Box className="marketplace-page__search-sorting">
            <Text className="marketplace-page__search-sorting-header" onClick={handleOpenArtist}>
              <Text as="span" className="marketplace-page__search-sorting-header__type">
                {t('p.pages.marketplace.artist')}
              </Text>
              <Text as="span" className="marketplace-page__search-sorting-header__item">
                {artistName}
                <ArrowDownIcon />
              </Text>
            </Text>
            {isOpenArtist && (
              <Box className="select-multiple__dropdown overflow-hidden" ref={refArtist}>
                <ul>
                  {artists &&
                    listArtist &&
                    listArtist.map((a) => (
                      <>
                        <li
                          className={cx(artist === a.value && 'active')}
                          key={a.value}
                          value={a.value}
                          onClick={() => {
                            setArtist(a.value);
                            setArtistName(a.label);
                            handleCloseArtist();
                          }}
                        >
                          {a.label}
                        </li>
                        <Separator />
                      </>
                    ))}
                </ul>
              </Box>
            )}
          </Box>
        </Box>
        <Box className="marketplace-page_search">
          <Box className="marketplace-page__search-sorting">
            <Text className="marketplace-page__search-sorting-header" onClick={handleOpenSorting}>
              <Text as="span" className="marketplace-page__search-sorting-header__type">
                {t('p.pages.profile.artist.sortBy')}
              </Text>
              <Text as="span" className="marketplace-page__search-sorting-header__item">
                {getSortLabel()}
                <ArrowDownIcon />
              </Text>
            </Text>
            {isOpenSorting && (
              <Box className="select-multiple__dropdown overflow-hidden" ref={refSort}>
                <ul>
                  {listSort &&
                    listSort.map((o) => (
                      <>
                        <li
                          className={cx(sort === o.value && 'active')}
                          key={o.value}
                          value={o.value}
                          onClick={() => {
                            setSort(o.value);
                            handleCloseSort();
                          }}
                        >
                          {o.label}
                        </li>
                        <Separator />
                      </>
                    ))}
                </ul>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Separator className="mt-5 mb-9" />
      <Box className="marketplace-page__body__result">
        <Box className="marketplace-page__body__result__count">
          <Trans i18nKey="p.pages.marketplace.result">{{ total: total }}</Trans>
        </Box>
        <Box className="marketplace-page__body__result__show">
          <Box onClick={() => setColsActive(2)} className="grid grid-cols-2 cursor-pointer">
            {[1, 2, 3, 4].map((x) => (
              <Box
                key={x}
                className={cx(colsActive === 2 ? 'point-filter-active' : 'point-filter')}
              ></Box>
            ))}
          </Box>
          <Box onClick={() => setColsActive(3)} className="grid grid-cols-3 cursor-pointer">
            {[1, 2, 3, 4, 5, 6].map((x) => (
              <Box
                key={x}
                className={cx(colsActive === 3 ? 'point-filter-active' : 'point-filter')}
              ></Box>
            ))}
          </Box>
          <Box onClick={() => setColsActive(4)} className="grid grid-cols-4 cursor-pointer">
            {[1, 2, 3, 4, 5, 6, 7, 8].map((x) => (
              <Box
                key={x}
                className={cx(colsActive === 4 ? 'point-filter-active' : 'point-filter')}
              ></Box>
            ))}
          </Box>
          <Box className="responsive-filter" onClick={() => setOpenModal(true)}>
            <FilterIcon />
          </Box>
        </Box>
      </Box>
      <Box className="profile-museum__collectible__grid">
        <InfiniteScroll
          throttle={1400 / colsActive}
          isLoading={loading}
          hasMore={pageToken}
          onLoadMore={nextPage}
        >
          <Box
            className={cx(
              'profile-museum__collectible__grid__wrapper',
              `grid grid-cols-${colsActive}`
            )}
          >
            {latestCollectibles?.map((collectible) => (
              <CollectibleCard
                key={collectible.id}
                collectible={collectible}
                showPrice={false}
              ></CollectibleCard>
            ))}
          </Box>
        </InfiniteScroll>
        {loading && (
          <Box
            className={cx(
              'profile-museum__collectible__grid__wrapper',
              `grid grid-cols-${colsActive}`
            )}
          >
            {[...Array(colsActive * 2)].map((x, index) => (
              <CollectibleSkeleton key={index} style={{ height: '640px' }} />
            ))}
          </Box>
        )}
        {!loading && latestCollectibles?.length < 1 && (
          <EmptyBox
            title={t('p.pages.profile.artist.empty.title')}
            description={t('p.pages.profile.artist.empty.description')}
          />
        )}
      </Box>
      <ResponsiveModal isOpen={openModal} onClose={() => setOpenModal(false)}>
        <Box className="flex items-center justify-between mb-2">
          <Box className="text-lg font-bold">{t('p.pages.marketplace.filterArtworks')}</Box>
          <Box
            onClick={() => resetFilter()}
            className="text-sm text-secondary underline font-medium"
          >
            {t('p.pages.marketplace.reset')}
          </Box>
        </Box>
        <CustomDropdown value={style} list={listStyle} setValue={setStyle} label="Style" />
        <CustomDropdown value={artist} list={listArtist} setValue={setArtist} label="Artist" />
        <CustomDropdown value={sort} list={listSort} setValue={setSort} label="Sort" />
        <Button className="mt-5" onClick={() => setOpenModal(false)} fullWidth>
          {t('p.pages.marketplace.close')}
        </Button>
      </ResponsiveModal>
    </>
  );
}

export default Collectible;
