import cx from 'classnames';
import { Helmet } from 'react-helmet';
import { useState } from 'react';
import { Box, Container, Button, CollectibleCard, CollectibleSkeleton } from '@components';
import { useTranslation, Trans } from 'react-i18next';
import { useMarketplace } from '@libs/hooks/marketplace';
import { ReactComponent as FilterIcon } from '@assets/svg/patrons/sliders-horizontal.svg';
import MarketplaceCollectibles from '@pages/marketplace/patrons/components/filter-collectibles';
import { EmptyBox } from '@components/index';

function Marketplace() {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const {
    data: collectibles,
    loading,
    onPaginate,
    onChangeFilter,
    pageToken: nextCollectibleToken,
    total
  } = useMarketplace(12);
  const nextData = async () => {
    await onPaginate(nextCollectibleToken);
  };
  const [colsActive, setColsActive] = useState(3);

  return (
    <Box className="marketplace-page">
      <Container className="marketplace-page__body">
        <Box className="marketplace-page__body__title">{t('p.pages.marketplace.title')}</Box>
        <MarketplaceCollectibles
          onChangeFilter={onChangeFilter}
          openModal={openModal}
          setOpenModal={setOpenModal}
        >
          <Box className="marketplace-page__body__result">
            <Box className="marketplace-page__body__result__count">
              {total === 1 ? (
                <Trans i18nKey="p.pages.marketplace.result">{{ total: total || 0 }}</Trans>
              ) : (
                <Trans i18nKey="p.pages.marketplace.results">{{ total: total || 0 }}</Trans>
              )}
            </Box>
            <Box className="marketplace-page__body__result__show">
              <Box onClick={() => setColsActive(2)} className="grid grid-cols-2 cursor-pointer">
                {[1, 2, 3, 4].map((x) => (
                  <Box
                    key={x}
                    className={cx(colsActive === 2 ? 'point-filter-active' : 'point-filter')}
                  ></Box>
                ))}
              </Box>
              <Box onClick={() => setColsActive(3)} className="grid grid-cols-3 cursor-pointer">
                {[1, 2, 3, 4, 5, 6].map((x) => (
                  <Box
                    key={x}
                    className={cx(colsActive === 3 ? 'point-filter-active' : 'point-filter')}
                  ></Box>
                ))}
              </Box>
              <Box onClick={() => setColsActive(4)} className="grid grid-cols-4 cursor-pointer">
                {[1, 2, 3, 4, 5, 6, 7, 8].map((x) => (
                  <Box
                    key={x}
                    className={cx(colsActive === 4 ? 'point-filter-active' : 'point-filter')}
                  ></Box>
                ))}
              </Box>
              <Box className="responsive-filter" onClick={() => setOpenModal(true)}>
                <FilterIcon />
              </Box>
            </Box>
          </Box>
          {!loading && total === 0 && (
            <Box className="flex justify-center items-center w-full">
              <EmptyBox title="No artworks found" />
            </Box>
          )}
          <Box className={cx('marketplace-page__body__cards', `grid grid-cols-${colsActive}`)}>
            <>
              {collectibles?.map((collectible) => (
                <CollectibleCard
                  key={collectible.id}
                  collectible={collectible}
                  showPrice={false}
                ></CollectibleCard>
              ))}
              {loading && collectibles?.length < 1 && (
                <>
                  {[...Array(colsActive * 2)].map((x, index) => (
                    <CollectibleSkeleton key={index} />
                  ))}
                </>
              )}
            </>
          </Box>
          {nextCollectibleToken && (
            <Button className="mt-2" loading={loading} onClick={() => nextData()}>
              {t('p.pages.artists.loadMore')}
            </Button>
          )}
        </MarketplaceCollectibles>
      </Container>
      <Helmet>
        <title>{t('marketplace.meta.title')}</title>
        <meta property="og:title" content={`${t('marketplace.meta.title')} - Fans.Inc`} />
        <meta property="og:description" content={t('marketplace.meta.description')} />
        <meta property="og:image" content="https://cdn.fans.inc/public/webapp/og-image.png" />
      </Helmet>
    </Box>
  );
}

export default Marketplace;
