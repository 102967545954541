import cx from 'classnames';

import GoogleLogin from './google';
import FacebookLogin from './facebook';

const SocialLogin = ({ className }) => {
  return (
    <div className={cx('social-login', className)}>
      <FacebookLogin />
      <GoogleLogin />
    </div>
  );
};

export default SocialLogin;
