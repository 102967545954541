import ReactCanvasConfetti from 'react-canvas-confetti';
// import { useTranslation } from 'react-i18next';
import { useCallback, useState, useRef, useEffect } from 'react';

import { Box, Container, Heading, Text, Modal } from '@components';
import useQuery from '@libs/utils/query';

const canvasStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0
};

function BitPayConfirmation() {
  // const { t } = useTranslation();
  const qs = useQuery();

  const refAnimationInstance = useRef(null);
  const [isOpenModal, setIsOpenModal] = useState(true);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio)
      });
  }, []);

  const exchangeIntent = qs.get('eint');
  const exchangeNotFound = !qs.get('eid');

  useEffect(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55
    });
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55
    });

    makeShot(0.2, {
      spread: 60
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45
    });
  }, [makeShot]);

  const BitPayConfirmationContent = ({ trxIntent }) => {
    switch (trxIntent) {
      case 'MAKE_OFFER':
        return (
          <Box className="confirmation__header">
            <Heading className="confirmation__header__title" as="h3">
              OFFER IS MADE
            </Heading>
            <Text as="p" className="confirmation__header__description">
              Thanks for your offer. Once your offer is accepted by seller, we will send the invoice
              to your email and in-app notification to submit your payment.
            </Text>
          </Box>
        );

      case 'OFFER_BITPAY_PAYMENT_MADE':
      case 'FIXED_PRICE_PURCHASE':
      default:
        return (
          <Box className="confirmation__header">
            <Heading className="confirmation__header__title" as="h3">
              PAYMENT SUCCESS
            </Heading>
            <Text as="p" className="confirmation__header__description">
              Typically, a transaction with cryptocurrency takes between five minutes and three
              hours to get confirmed. We will transfer the collectible ownership and notify you
              through email once your transaction is confirmed.
            </Text>
          </Box>
        );
    }
  };

  return (
    !exchangeNotFound && (
      <Modal isOpen={isOpenModal} onClose={setIsOpenModal} customWrapperStyle="confirmation__modal">
        <Container className="confirmation__container">
          <BitPayConfirmationContent trxIntent={exchangeIntent} />
        </Container>
        <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
      </Modal>
    )
  );
}

export default BitPayConfirmation;
