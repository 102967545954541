import { useTranslation } from 'react-i18next';
import FacebookIcon from '@assets/svg/facebook-original.svg';

import { Button } from '@components';
import { useAuth } from '@libs/contexts/auth';

const FacebookLogin = () => {
  const { t } = useTranslation();
  const { federatedSignIn } = useAuth();

  return (
    <Button darkOutline fullWidth onClick={() => federatedSignIn('Facebook')}>
      <img className="pr-3" src={FacebookIcon} />
      {t('components.socialLogin.facebook')}
    </Button>
  );
};

export default FacebookLogin;
