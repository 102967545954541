import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Frames, CardNumber, ExpiryDate, Cvv } from 'frames-react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { IoInformationCircleSharp } from 'react-icons/io5';

import config from '@/config';
import gql from '@libs/utils/gql';
import { useLocale } from '@libs/contexts/locale';
import { makeOfferV2, placeBidV2, fixedPricePurchaseV2 } from '@graphql/mutations';
import { Box, Text, Grid, Col } from '@components';
import { exchangePropType } from '@libs/prop-types';
import { processPaymentResponse } from '@libs/utils/checkout';
import { EXCHANGE_INTENTS } from '@libs/utils/exchange';
import handleGraphQLErrors from '@libs/utils/errors/graphql';

const CARD_NUMBER_CHECKOUT_KEY = 'card-number';
const EXPIRY_DATE_CHECKOUT_KEY = 'expiry-date';
const CVV_CHECKOUT_KEY = 'cvv';
const INITIAL_FIELD_STATUS = {
  [CARD_NUMBER_CHECKOUT_KEY]: {
    isValid: true,
    isFocused: false,
    isEmpty: true,
    name: 'Card number'
  },
  [EXPIRY_DATE_CHECKOUT_KEY]: {
    isValid: true,
    isFocused: false,
    isEmpty: true,
    name: 'Expiry Date'
  },
  [CVV_CHECKOUT_KEY]: { isValid: true, isFocused: false, isEmpty: true, name: 'CVV' }
};

const checkoutConfig = {
  debug: false,
  publicKey: config.checkoutPublicKey,
  localization: {
    cardNumberPlaceholder: 'Card number',
    expiryMonthPlaceholder: 'MM',
    expiryYearPlaceholder: 'YY',
    cvvPlaceholder: '3 Digits'
  },
  style: {
    base: {
      marginBottom: '10px',
      color: 'black',
      border: '1px solid #e0e0e0',
      fontSize: '18px',
      padding: '8px 12px',
      borderRadius: '4px'
    },
    invalid: {
      color: 'red'
    },
    focus: {
      background: '#F5F5F5',
      border: '1px solid rgba(255, 67, 7, 0.4)'
    },
    placeholder: {
      base: {
        fontSize: '16px',
        color: '#A3A3A3'
      }
    }
  }
};

function CheckoutFrameForm({
  exchange,
  exchangeIntent,
  customPrice,
  submitForm,
  setSubmitForm,
  email,
  firstName,
  lastName
}) {
  const { t } = useTranslation();
  const [fieldStatus, setFieldStatus] = useState(INITIAL_FIELD_STATUS);
  const [showValidationMessages, setShowValidationMessages] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [iframeUrl, setIframeUrl] = useState(null);
  const [error, setError] = useState('');
  const [consoleError, setConsoleError] = useState('');
  const { currencyCode } = useLocale();
  const checkoutForm = useRef();
  const checkoutIframe = useRef();

  const exchangeID = exchange?.id;

  useEffect(() => {
    const reportError = (message) => console.error(message);
    if (consoleError) {
      reportError(consoleError);
    }
  }, [consoleError]);

  useEffect(() => {
    if (redirectUrl) {
      window.location.assign(redirectUrl);
    }
  }, [redirectUrl]);
  const scrollToFrame = () => {
    const checkoutModal = document.querySelector('#checkout-modal');
    checkoutModal.scroll({ behavior: 'smooth', top: checkoutForm.current.offsetTop });
  };

  useEffect(() => {
    const handleSubmit = () => {
      if (!Frames.isCardValid()) {
        // show validation errors
        setShowValidationMessages(true);
        setSubmitForm(false);
        scrollToFrame();
        return;
      }
      try {
        Frames.submitCard();
      } catch (error) {
        setError(error);
        Frames.init();
        setSubmitForm(false);
      }
    };

    if (submitForm) {
      handleSubmit();
    }
  }, [setSubmitForm, submitForm]);

  const fixedPriceGql = async (input) => {
    const { token, exchangeID, currencyCode, email, firstName, lastName } = input;
    const gqlRes = await gql(
      fixedPricePurchaseV2,
      {
        input: { token, exchangeID, currencyCode, email, firstName, lastName }
      },
      {
        authMode: email ? 'API_KEY' : 'AMAZON_COGNITO_USER_POOLS'
      }
    );
    const res = gqlRes.data.fixedPricePurchaseV2;
    return res;
  };

  const makeOfferGql = async (input) => {
    const { token, exchangeID, customPrice, currencyCode, email, firstName, lastName } = input;
    const gqlRes = await gql(
      makeOfferV2,
      {
        input: { token, exchangeID, customPrice, currencyCode, email, firstName, lastName }
      },
      {
        authMode: email ? 'API_KEY' : 'AMAZON_COGNITO_USER_POOLS'
      }
    );
    const res = gqlRes.data.makeOfferV2;
    return res;
  };

  const placeBidGql = async (input) => {
    const { token, exchangeID, customPrice, currencyCode, email, firstName, lastName } = input;
    const gqlRes = await gql(
      placeBidV2,
      {
        input: { token, exchangeID, customPrice, currencyCode, email, firstName, lastName }
      },
      {
        authMode: email ? 'API_KEY' : 'AMAZON_COGNITO_USER_POOLS'
      }
    );
    const res = gqlRes.data.placeBidV2;
    return res;
  };

  const handleCardTokenized = async (event, data) => {
    const { token } = event;
    if (token) {
      try {
        let response = null;
        switch (data.exchangeIntent) {
          case EXCHANGE_INTENTS.FIXED_PRICE_PURCHASE:
            response = await fixedPriceGql({
              ...data,
              token
            });
            break;
          case EXCHANGE_INTENTS.MAKE_OFFER:
            response = await makeOfferGql({
              ...data,
              token
            });
            break;
          case EXCHANGE_INTENTS.PLACE_BID:
            response = await placeBidGql({
              ...data,
              token
            });
            break;
          default:
            scrollToFrame();
            throw new Error('There is a problem with payments. Please try again later.');
        }
        const paymentResponseParsed = response ? JSON.parse(response) : null;
        const onSuccess = (response) => {
          setRedirectUrl(response?.successUrl);
        };
        const onFail = (e) => {
          throw e;
        };
        // not using iframe implementation due to fingerprinting issues with newer browsers
        const onRedirect = (redirectUrl) => setRedirectUrl(redirectUrl);
        processPaymentResponse({
          paymentResponse: paymentResponseParsed,
          exchangeIntent: data.exchangeIntent,
          onSuccess,
          onFail,
          onRedirect
        });
      } catch (error) {
        const handledError = handleGraphQLErrors(error);
        setConsoleError(handledError);
        setError(
          handledError?.messages[0] ?? 'There is a problem with payments. Please try again later.'
        );
        scrollToFrame();
        Frames.init();
      }
    }
    setSubmitForm(false);
  };

  const onFrameValidationChanged = (event) => {
    const { element, isValid, isEmpty } = event;
    setFieldStatus((prev) => ({ ...prev, [element]: { ...prev[element], isValid, isEmpty } }));
  };

  const onFrameFocus = (event) => {
    const { element } = event;
    setFieldStatus((prev) => ({ ...prev, [element]: { ...prev[element], isFocused: true } }));
  };

  const onFrameBlur = (event) => {
    const { element } = event;
    setFieldStatus((prev) => ({ ...prev, [element]: { ...prev[element], isFocused: false } }));
  };

  const ValidationError = ({ fieldKey }) => {
    const { isValid, isFocused, isEmpty, name } = fieldStatus[fieldKey];
    const getEmptyFieldMessage = (fieldName) => `${fieldName} cannot be empty`;
    const getValidationErrorMessage = (fieldName) => `${fieldName} is invalid`;

    let message = '';

    if (showValidationMessages && isEmpty && !isFocused) {
      message = getEmptyFieldMessage(name);
    } else if (showValidationMessages && !isValid && !isFocused) {
      message = getValidationErrorMessage(name);
    }

    return (
      <div className={`checkout_frame__validation_error ${message ? 'visible' : ''}`}>
        {message}
      </div>
    );
  };

  return (
    <>
      {iframeUrl ? (
        <iframe
          src={iframeUrl}
          title="checkout-3ds-iframe"
          sandbox="allow-top-navigation allow-scripts allow-same-origin allow-forms"
          style={{
            minHeight: '100vh',
            width: '100vw',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 1000,
            backgroundColor: 'rgba(255,255,255,0.5)'
          }}
          ref={checkoutIframe}
          onLoad={(e) => {
            try {
              e.target.contentWindow?.location?.href &&
                setRedirectUrl(e.target.contentWindow.location.href);
            } catch (e) {
              console.warn(e);
            }
          }}
        />
      ) : (
        <Box className="mt-4 pt-4" ref={checkoutForm}>
          {checkoutConfig.publicKey ? (
            <Frames
              config={checkoutConfig}
              cardTokenized={(e) =>
                handleCardTokenized(e, {
                  exchangeIntent,
                  exchangeID,
                  currencyCode,
                  customPrice,
                  email,
                  firstName,
                  lastName
                })
              }
              ready={() => {}}
              frameActivated={(e) => {}}
              frameFocus={onFrameFocus}
              frameBlur={onFrameBlur}
              frameValidationChanged={onFrameValidationChanged}
              paymentMethodChanged={(e) => {}}
              cardValidationChanged={(e) => {}}
              cardSubmitted={() => {}}
              cardTokenizationFailed={(e) => {
                setError(e);
                setSubmitForm(false);
              }}
              key={`checkout-frame-${customPrice}-${email}-${firstName}-${lastName}`}
            >
              <div className="capitalize text-base  md:text-lg font-medium">
                {t('checkout.fields.cardNumber.label')}
              </div>
              <CardNumber />
              {process.env.REACT_APP_AMPLIFY_ENV !== 'prod' && (
                <label style={{ color: 'gray', display: 'flex', alignItems: 'center' }}>
                  Dummy Card Number: 4485 0403 7153 6584
                  <a className="cursor-pointer" data-tip data-for="minimumTooltip">
                    <IoInformationCircleSharp className="ml-2 text-gray-400 hover:text-white" />
                  </a>
                  <ReactTooltip id="minimumTooltip" place="right" type="dark">
                    <Box className="normal-case max-w-xs">{t('testLabel')}</Box>
                  </ReactTooltip>
                </label>
              )}
              <ValidationError fieldKey={CARD_NUMBER_CHECKOUT_KEY} />
              <Grid className="checkout__form__column-2">
                <Col>
                  <div className="capitalize text-base  md:text-lg font-medium">
                    {t('checkout.fields.expirationDate.label')}
                  </div>
                  <ExpiryDate />
                  {process.env.REACT_APP_AMPLIFY_ENV !== 'prod' && (
                    <label style={{ color: 'gray', display: 'flex', alignItems: 'center' }}>
                      Ex: 12/24
                      <a className="cursor-pointer" data-tip data-for="minimumTooltip">
                        <IoInformationCircleSharp className="ml-2 text-gray-400 hover:text-white" />
                      </a>
                      <ReactTooltip id="minimumTooltip" place="right" type="dark">
                        <Box className="normal-case max-w-xs">{t('testLabel')}</Box>
                      </ReactTooltip>
                    </label>
                  )}
                  <ValidationError fieldKey={EXPIRY_DATE_CHECKOUT_KEY} />
                </Col>
                <Col>
                  <div className="capitalize text-base  md:text-lg font-medium">
                    {t('checkout.fields.securityCode.label')}
                  </div>
                  <Cvv />
                  {process.env.REACT_APP_AMPLIFY_ENV !== 'prod' && (
                    <label style={{ color: 'gray', display: 'flex', alignItems: 'center' }}>
                      Security code: 200
                      <a className="cursor-pointer" data-tip data-for="minimumTooltip">
                        <IoInformationCircleSharp className="ml-2 text-gray-400 hover:text-white" />
                      </a>
                      <ReactTooltip id="minimumTooltip" place="right" type="dark">
                        <Box className="normal-case max-w-xs">{t('testLabel')}</Box>
                      </ReactTooltip>
                    </label>
                  )}

                  <ValidationError fieldKey={CVV_CHECKOUT_KEY} />
                </Col>
              </Grid>
            </Frames>
          ) : (
            <Text className="text-red-400">Missing checkout key</Text>
          )}

          {redirectUrl && (
            <div className="my-5">
              Payment pending - further action required.
              {redirectUrl ? (
                <a href={redirectUrl} className="block font-bold">
                  If the page does not automatically redirect click here.
                </a>
              ) : (
                ''
              )}
            </div>
          )}
          {error && (
            <div className="my-5">
              <Text className="text-red-400">{error}</Text>
            </div>
          )}
        </Box>
      )}
    </>
  );
}

CheckoutFrameForm.propTypes = {
  exchange: exchangePropType.isRequired,
  exchangeIntent: PropTypes.string.isRequired,
  customPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default CheckoutFrameForm;
