import FansincEmptyBox from './fansinc';
import PatronsEmptyBox from './patrons';

const EmptyBox = ({ title, description }) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsEmptyBox title={title} description={description} />;
  } else {
    return <FansincEmptyBox title={title} description={description} />;
  }
};

export default EmptyBox;
