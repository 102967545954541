import FansincProfileSetting from './fansinc';
import PatronsProfileSetting from './patrons';

function ProfileSetting() {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsProfileSetting />;
  } else {
    return <FansincProfileSetting />;
  }
}

export default ProfileSetting;
