import { Route, Redirect } from 'react-router-dom';

import { useAuth } from '@libs/contexts/auth';

const GuestOnlyRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) => {
        return !isAuthenticated ? <Component {...props} /> : <Redirect to="/marketplace" />;
      }}
    />
  );
};

export default GuestOnlyRoute;
