import cx from 'classnames';
import { useEffect, useState, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BiRightArrowAlt } from 'react-icons/bi';
import { IoPlaySharp, IoPause, IoVolumeHighOutline, IoVolumeMuteOutline } from 'react-icons/io5';
import Slider from 'react-input-slider';

import { Box, Text, Link, Player } from '@components';
import { Duration } from '@libs/utils/duration';
import { ReactComponent as AppleMusic } from '@assets/svg/apple-music.svg';
import { ReactComponent as Spotify } from '@assets/svg/spotify.svg';
import { ReactComponent as Youtube } from '@assets/svg/youtube.svg';
import { PlayerContext } from '@libs/contexts/player';
import { fetchImage, fetchAssets } from './hooks';
import { randomString } from '@libs/utils/string';

const MediaAudio = ({ collectible, mime, showPlayer, full, showExternalLink = false }) => {
  const [id, setId] = useState();
  const [image, setImage] = useState();
  const [played, setPlayed] = useState(false);
  const [playerSrc, setPlayerSrc] = useState();
  const [showVolume, setShowVolume] = useState(false);
  const [playerData, setPlayerData] = useState({
    played: 0,
    loaded: 0,
    duration: 0,
    seeking: false,
    volume: 1,
    muted: false
  });

  const { isPlaying, play, pause } = useContext(PlayerContext);
  const player = useRef();

  useEffect(() => {
    fetchImage({
      image: collectible?.mediaThumbnail,
      setImage
    });
    fetchAssets({
      assets: collectible?.assetUrl,
      setAssets: setPlayerSrc
    });
    if (!id) {
      setId(collectible?.id + randomString(12));
    }
  }, [collectible?.mediaThumbnail, collectible?.assetUrl, mime]);

  const handleProgress = (state) => {
    // We only want to update time slider if we are not currently seeking
    if (!playerData.seeking) {
      setPlayerData({ ...playerData, ...state });
    }
  };

  const handleDuration = (duration) => {
    setPlayerData({ ...playerData, duration });
  };

  const handleMuteVolume = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (playerData.volume > 0) {
      setPlayerData({ ...playerData, muted: true, volume: 0 });
    } else {
      setPlayerData({ ...playerData, muted: false, volume: 1 });
    }
  };

  const handleShowVolume = () => {
    setShowVolume(!showVolume);
  };

  const mediaStyle = full
    ? {}
    : {
        backgroundImage: `url(${image || collectible?.mediaThumbnail})`
      };

  const playerStyle = {
    'media-hide': !showPlayer
  };

  return (
    <>
      <Box className="relative">
        <Box className={cx('media__audio')} style={mediaStyle}>
          {full ? (
            <img src={image || collectible?.mediaThumbnail} alt={collectible?.title} />
          ) : null}
        </Box>
        {!showExternalLink || collectible?.streamExternal !== 'TRUE' ? (
          collectible?.streamExternal !== 'TRUE' && showPlayer ? (
            <Player
              id={id}
              ref={(value) => {
                player.current = value;
              }}
              url={playerSrc}
              light={image || collectible?.mediaThumbnail}
              playIcon={
                <IoPlaySharp
                  className="m-4 mb-6 absolute bottom-0 left-0 z-50"
                  style={{ width: 24, height: 24 }}
                  width={24}
                  height={24}
                />
              }
              played={played}
              setPlayed={setPlayed}
              onProgress={handleProgress}
              onDuration={handleDuration}
              onEnded={() => pause(id)}
              volume={playerData.volume}
              muted={playerData.muted}
            />
          ) : (
            showPlayer && (
              <IoPlaySharp
                className="m-4 mb-6 absolute bottom-0 left-0 z-50"
                style={{ width: 24, height: 24 }}
                width={24}
                height={24}
              />
            )
          )
        ) : (
          <ExternalStream collectible={collectible} />
        )}

        <Box className={cx('media__audio-player', playerStyle)}>
          {!showExternalLink && played && (
            <Text
              as="span"
              className="media__audio-player__button"
              onClick={(e) => {
                isPlaying(id) ? pause(id) : play(id);
                setPlayed(true);
                e.preventDefault();
              }}
            >
              {isPlaying(id) ? <IoPause /> : <IoPlaySharp />}
            </Text>
          )}

          {collectible?.streamExternal !== 'TRUE' && (
            <Box
              className="media__audio-player__indicator"
              onClickCapture={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {played ? (
                <>
                  <Duration
                    className="player__controls__duration"
                    seconds={playerData.duration * playerData.played}
                  />
                  <div className="player__controls">
                    <Slider
                      className="player__controls__seek"
                      min={0}
                      axis="x"
                      x={playerData.duration * playerData.played}
                      xmax={playerData.duration}
                      xstep={1}
                      onChange={(e) => {
                        player.current.seekTo(e.x / playerData.duration);
                        setPlayerData({ ...playerData, played: e.x / playerData.duration });
                      }}
                      onDragStart={() => pause(id)}
                      onDragEnd={() => play(id)}
                    />
                    <div
                      className="player__controls__loaded"
                      style={{ width: `${100 * playerData.loaded}%` }}
                    ></div>
                  </div>
                </>
              ) : null}

              <Duration
                seconds={playerData.duration}
                className={cx(
                  'player__controls__duration',
                  !played ? 'player__controls__duration-no' : ''
                )}
              />
            </Box>
          )}

          {played ? (
            <Text
              as="span"
              className="media__audio-player__button media__audio-player__button--right"
              onClick={(e) => handleMuteVolume(e)}
              onMouseEnter={handleShowVolume}
              onMouseLeave={handleShowVolume}
            >
              {showVolume && (
                <div
                  className="player__volume"
                  onClickCapture={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <Slider
                    min={0}
                    axis="y"
                    y={playerData.volume}
                    ymax={1}
                    yreverse={true}
                    ystep={0.01}
                    onChange={(e) => {
                      setPlayerData({ ...playerData, volume: e.y });
                    }}
                    styles={{
                      thumb: { width: 8, height: 8 },
                      track: { width: 3, height: 75 }
                    }}
                  />
                </div>
              )}
              {!playerData.volume ? <IoVolumeMuteOutline /> : <IoVolumeHighOutline />}
            </Text>
          ) : null}
        </Box>
      </Box>
    </>
  );
};

const ExternalStream = ({ collectible }) => {
  const { t } = useTranslation();
  return (
    <Box className="media__audio-overlay">
      <Box className="media__audio-overlay__wrapper">
        <Text as="p" className="media__audio-overlay__title">
          {t('components.media.audio.streamThisSongOn')}
        </Text>
        <Box>
          {collectible?.streamUrl?.spotify && (
            <Link target="_blank" to={collectible?.streamUrl?.spotify}>
              <Box className="media__audio-overlay__button">
                <Spotify className="media__audio-overlay__button__icon" />
                <Text className="media__audio-overlay__button__text">
                  {t('components.media.audio.button.spotify')}
                </Text>
                <BiRightArrowAlt className="media__audio-overlay__button__arrow" />
              </Box>
            </Link>
          )}
          {collectible?.streamUrl?.appleMusic && (
            <Link target="_blank" to={collectible?.streamUrl?.appleMusic}>
              <Box className="media__audio-overlay__button">
                <AppleMusic className="media__audio-overlay__button__icon" />
                <Text className="media__audio-overlay__button__text">
                  {t('components.media.audio.button.appleMusic')}
                </Text>
                <BiRightArrowAlt className="media__audio-overlay__button__arrow" />
              </Box>
            </Link>
          )}
          {collectible?.streamUrl?.youtube && (
            <Link target="_blank" to={collectible?.streamUrl?.youtube}>
              <Box className="media__audio-overlay__button">
                <Youtube className="media__audio-overlay__button__icon" />
                <Text className="media__audio-overlay__button__text">
                  {t('components.media.audio.button.youtube')}
                </Text>
                <BiRightArrowAlt className="media__audio-overlay__button__arrow" />
              </Box>
            </Link>
          )}
        </Box>
      </Box>
    </Box>
  );
};

MediaAudio.defaultProps = {
  full: false,
  showPlayer: true,
  showPlayerSlider: false
};

export default MediaAudio;
