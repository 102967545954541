import { Container } from '@components';
import communityOwnerImage from '@assets/img/patrons/about/community-owner.png';
export default function CommunityOwner({ title, description }) {
  return (
    <div className="about-page-section-second bg-gray-75 w-full pb-10">
      <Container className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-1/2 lg:pr-16">
          <img src={communityOwnerImage} className="w-full h-fit" alt="community-owner" />
        </div>
        <div className="w-full lg:w-1/2 px-4 lg:px-20 flex justify-center items-start flex-col mt-4 lg:mt-0">
          <div className="font-Satoshi font-bold text-3xl lg:text-4xl capitalize text-primary  mb-8">
            {title}
          </div>
          <p className="text-lg font-Satoshi text-secondary leading-8">{description}</p>
        </div>
      </Container>
    </div>
  );
}
