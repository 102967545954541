import FansincHeader from './fansinc';
import PatronsHeader from './patrons';

const HeaderComingSoon = () => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsHeader />;
  } else {
    return <FansincHeader />;
  }
};

export default HeaderComingSoon;
