import { useState, useEffect, useCallback } from 'react';

import gql from '@libs/utils/gql';
import useToast from '@libs/utils/toast';
import { searchNotifications } from '@libs/custom-queries/notification';
import { updateReadNotification } from '@graphql/mutations';
import { useAuth } from '@libs/contexts/auth';
import { onNotifUpdatedV1 } from '@graphql/subscriptions';
import useQuery from '@libs/utils/query';
import { setIntervalLimited } from '@libs/utils/interval';

export const useNotification = (limit) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [unread, setUnread] = useState(0);
  const [data, setData] = useState([]);
  const { user } = useAuth();
  const [notifInterval, setNotifInterval] = useState();
  const query = useQuery();
  const exchange = query.get('eid');
  const deletedType = [
    'COLLECTIBLE_SOLD',
    'COLLECTIBLE_OFFER_MADE',
    'COLLECTIBLE_OFFER_RECEIVED',
    'COLLECTIBLE_BOUGHT',
    'COLLECTIBLE_OUTBID',
    'COLLECTIBLE_PUBLISHED',
    'COLLECTIBLE_RELEASE_STARTED',
    'USER_FOLLOWED',
    'COLLECTIBLE_OFFER_ACCEPTED',
    'COLLECTIBLE_BID_MADE',
    'COLLECTIBLE_BID_RECEIVED',
    'COLLECTIBLE_BID_DID_NOT_WON',
    'COLLECTIBLE_BID_WON',
    'COLLECTIBLE_SUBMITTED_ADMIN',
    'PAYMENT_FAILED_BUYER',
    'PURCHASE_CONFIRMED_BUYER',
    'USER_USERNAME_UPDATED_USER',
    'USER_VERIFIED_USER',
    'PRINT_PURCHASE_PAYMENT_CONFIRMED',
    'PRINT_ORDER_PAYMENT_CONFIRMED_ADMIN',
    'COLLECTIBLE_RESERVED',
    'WITHDRAW_REQUESTED_ADMIN'
  ];

  const getData = async () => {
    setLoading(true);

    try {
      const params = {
        sort: {
          direction: 'desc',
          field: 'createdAt'
        },
        filter: {
          userID: { eq: user?.id },
          not: { or: deletedType.map((d) => ({ searchableType: { eq: d } })) }
        }
      };

      if (limit) {
        params.limit = limit;
      }

      const { data: result } = await gql(searchNotifications, params, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });

      if (result?.searchNotifications?.items?.length) {
        const notifications = result.searchNotifications.items.map((notification) => {
          notification.event = JSON.parse(notification.event);
          return notification;
        });
        setData(notifications);
      }
    } catch (error) {
      const errorMessage = error?.errors[0]?.message;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getUnreadCount = async () => {
    try {
      const params = {
        sort: {
          direction: 'desc',
          field: 'createdAt'
        },
        filter: {
          userID: { eq: user?.id },
          searchableRead: { eq: 'FALSE' },
          not: { or: deletedType.map((d) => ({ searchableType: { eq: d } })) }
        }
      };

      const { data: result } = await gql(searchNotifications, params, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });

      setUnread(result?.searchNotifications?.total || 0);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (user) {
      let intervalID;
      let counter = 0;

      const payload = {};
      const subscription = gql(onNotifUpdatedV1, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      }).subscribe({
        next: () => {
          // Fetch the data every 2s for 10x until
          // all notification set to read on OpenSearch
          intervalID = setInterval(function () {
            if (counter < 10) {
              getData();
              getUnreadCount();
              counter += 1;
            } else {
              clearInterval(notifInterval);
              setNotifInterval(null);
            }
          }, 2000);

          setNotifInterval(intervalID);
        },
        error: (error) => console.warn(error)
      });

      return () => {
        if (subscription) subscription.unsubscribe();
        if (notifInterval) {
          clearInterval(notifInterval);
          setNotifInterval(null);
        }
      };
    }
  }, [user, notifInterval, setNotifInterval]); // eslint-disable-line

  useEffect(() => {
    if (user) {
      getData();
      getUnreadCount();

      if (exchange) {
        setIntervalLimited(
          () => {
            getData();
            getUnreadCount();
          },
          2000,
          10
        );
      }
    }
  }, [user]); // eslint-disable-line

  return {
    loading,
    data,
    getData,
    unread
  };
};

export const useUpdateReadNotification = () => {
  const [updateLoading, setUpdateLoading] = useState(false);

  const onUpdateNotification = useCallback(
    async (data) => {
      setUpdateLoading(true);
      try {
        const payload = {
          input: {
            itemType: data.itemType
          }
        };
        if (data.itemType === 'notification') {
          payload.input = {
            notificationIDs: [data.id],
            itemType: data.itemType
          };
        }
        await gql(updateReadNotification, payload, {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
      } catch (error) {
        console.error('error update user:', error);
      } finally {
        setUpdateLoading(false);
      }
    },

    // eslint-disable-next-line
    []
  );

  return {
    updateLoading,
    onUpdateNotification
  };
};
