import FansincCollectibleCardV2 from './fansinc';
import PatronsCollectibleCardV2 from './patrons';

const CollectibleCardV2 = ({
  className,
  collectible,
  isPreview,
  type,
  hasCollectibleTimer,
  showManageListing,
  updateExchangeData,
  showOwnerInUrl,
  showReleaseAsCollectible,
  showButton,
  showTopNavigation,
  showPrice = true,
  collectibleUrl,
  creatorUrl,
  onVisit,
  ...props
}) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return (
      <PatronsCollectibleCardV2
        className={className}
        collectible={collectible}
        isPreview={isPreview}
        type={type}
        hasCollectibleTimer={hasCollectibleTimer}
        showManageListing={showManageListing}
        updateExchangeData={updateExchangeData}
        showOwnerInUrl={showOwnerInUrl}
        showReleaseAsCollectible={showReleaseAsCollectible}
        showButton={showButton}
        showTopNavigation={showTopNavigation}
        showPrice={showPrice}
        collectibleUrl={collectibleUrl}
        onVisit={onVisit}
        creatorUrl={creatorUrl}
      />
    );
  } else {
    return (
      <FansincCollectibleCardV2
        className={className}
        collectible={collectible}
        isPreview={isPreview}
        type={type}
        hasCollectibleTimer={hasCollectibleTimer}
        showManageListing={showManageListing}
        updateExchangeData={updateExchangeData}
        showOwnerInUrl={showOwnerInUrl}
        showReleaseAsCollectible={showReleaseAsCollectible}
        showButton={showButton}
        showTopNavigation={showTopNavigation}
      />
    );
  }
};

export default CollectibleCardV2;
