import { useState, useEffect } from 'react';

import gql from '@libs/utils/gql';
import useToast from '@libs/utils/toast';
import { searchExchangesLite } from '@libs/custom-queries/exchange';
import { EXCHANGE_TYPES, STATUS_TYPES } from '@libs/utils/exchange';

export const useExchangesByCurrentOwner = ({ ownerID, limit = 5 }) => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  async function getData() {
    try {
      setLoading(true);
      const params = {
        limit,
        sort: { direction: 'desc', field: 'createdAt' },
        filter: {
          currentOwnerID: { eq: ownerID },
          searchableIsSold: { eq: 'FALSE' },
          searchableExchangeType: { eq: EXCHANGE_TYPES.LISTING },
          searchableStatus: { ne: STATUS_TYPES.TRANSFERRED_TO_NFT }
        }
      };

      const { data: res } = await gql(searchExchangesLite, params);

      if (res?.searchExchanges?.items?.length) {
        setData(res.searchExchanges.items);
      } else {
        setData([]);
      }
    } catch (error) {
      toast(error.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, [ownerID, limit]); // eslint-disable-line

  return {
    loading,
    data,
    getData
  };
};
