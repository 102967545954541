import { useState } from 'react';
import gql from '@libs/utils/gql';
import { viewCollectible } from '@graphql/mutations';
import useToast from '@libs/utils/toast';

export const useCollectibleView = () => {
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const onView = async (payload) => {
    setLoading(true);

    try {
      await gql(viewCollectible, {
        input: {
          collectibleID: payload?.collectibleID,
          userID: payload?.userID
        }
      });
    } catch (error) {
      const errorMessage = error?.errors[0]?.message;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    onView
  };
};
