import { useTranslation, Trans } from 'react-i18next';
import { useCallback, useState, useRef, useEffect } from 'react';
import { FiPackage } from 'react-icons/fi';
import { IoDownloadOutline } from 'react-icons/io5';
import ReactCanvasConfetti from 'react-canvas-confetti';
import { format } from 'date-fns';
import {
  Box,
  CollectibleCard,
  Container,
  Heading,
  Link,
  Text,
  ShareIcon,
  SkeletonLoading,
  Modal
} from '@components';
import { useOutsider } from '@libs/utils/outsider';
import { shareChannelKeys } from '@libs/utils/share';
import { urlParser } from '@pages/profile/utils';
import { EXCHANGE_INTENTS } from '@libs/utils/exchange';
import {
  useConfirmationQuery,
  usePollExchange,
  useRefreshSession
} from '@components/confirmation-modal/hooks';
import { getTitleAndDescription } from '@components/confirmation-modal/utils';
import { useLocale } from '@libs/contexts/locale';

const ErrorConfirmationContent = ({ title, description }) => (
  <Box className="confirmation confirmation--error">
    <Container className="confirmation__container">
      <Box className="confirmation__header">
        <Heading className="confirmation__header__title" as="h3">
          {title}
        </Heading>
        <Text as="p" className="confirmation__header__description">
          {description}
        </Text>
      </Box>
    </Container>
  </Box>
);

const LoadingConfirmationContent = ({ title }) => {
  const centerColClasses = 'flex flex-col items-center w-full';
  return (
    <>
      <Box className={centerColClasses}>
        <Box className={`confirmation__header ${centerColClasses}`}>
          <Heading as={'h4'} className="mb-6 w-10/12">
            {title}
          </Heading>
          <Box className={`confirmation__header__description ${centerColClasses}`}>
            {[...Array(3)].map((_, i) => (
              <SkeletonLoading className="my-1 h-3 w-10/12" key={`skel-${i}`} />
            ))}
          </Box>
        </Box>
        <SkeletonLoading className="h-96 mt-8 w-9/12" />
      </Box>
    </>
  );
};

const canvasStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0
};

function Confirmation({ fetchData }) {
  const [isOpenModal, setIsOpenModal] = useState(true);
  const { t } = useTranslation();
  const { status, exchangeID, exchangeIntent } = useConfirmationQuery();
  const [isOpen, setOpen] = useState(false);
  const { exchange, timeoutExceeded } = usePollExchange(exchangeID, 2000);
  const handleClose = () => setOpen(false);
  const { ref } = useOutsider(handleClose);
  useRefreshSession();
  const release = exchange?.release;

  const refAnimationInstance = useRef(null);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio)
      });
  }, []);

  const handleOpen = useCallback(
    (e) => {
      e.preventDefault();
      setOpen(!isOpen);
    },
    [isOpen]
  );

  const isFixedPrice = exchangeIntent === EXCHANGE_INTENTS.FIXED_PRICE_PURCHASE;
  const releaseUrl = window.location.hostname + urlParser(release, 'release', isFixedPrice);
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${releaseUrl}`;
  const { currencyCode, currencyFormatter } = useLocale();

  let { title, description } = getTitleAndDescription({
    exchangeIntent,
    exchange,
    status,
    timeoutExceeded,
    t,
    currencyCode,
    currencyFormatter
  });

  const hasError = status === 'fail' || timeoutExceeded;
  const exchangeNotFound = !exchangeID;

  useEffect(() => {
    if (release) {
      makeShot(0.25, {
        spread: 26,
        startVelocity: 55
      });
      makeShot(0.25, {
        spread: 26,
        startVelocity: 55
      });

      makeShot(0.2, {
        spread: 60
      });

      makeShot(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8
      });

      makeShot(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2
      });

      makeShot(0.1, {
        spread: 120,
        startVelocity: 45
      });
    }
  }, [release, makeShot]);

  const getData = useCallback(() => {
    if (fetchData) {
      fetchData();
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (exchange) {
      getData();
    }
  }, [exchange]); // eslint-disable-line

  if (hasError) {
    return (
      <Modal
        isOpen={isOpenModal}
        onClose={setIsOpenModal}
        customWrapperStyle="confirmation__modal confirmation__modal--error"
      >
        <ErrorConfirmationContent title={title} description={description} />
      </Modal>
    );
  }

  const auctionEndDate = exchange?.release?.collectible?.releases?.filter(
    (release) => release.listingType === 'AUCTION'
  );

  const ConfirmationContainerInner = () => (
    <>
      <Box className="confirmation__header">
        <Heading className="confirmation__header__title" as="h3">
          {title}
        </Heading>
        <Text as="p" className="confirmation__header__description">
          {description}
        </Text>
        {exchangeIntent === EXCHANGE_INTENTS.MAKE_OFFER ? (
          <Text as="p" className="confirmation__header__description">
            <Trans i18nKey="confirmation.makeAnOfferAdditionalDescription">
              {format(new Date(exchange?.expiryDate), 'MMMM d, yyyy')}
            </Trans>
          </Text>
        ) : exchangeIntent === EXCHANGE_INTENTS.PLACE_BID ? (
          <Text as="p" className="confirmation__header__description">
            <Trans i18nKey="confirmation.auctionAdditionalDescription">
              {format(new Date(auctionEndDate[0]?.saleEndDate), 'MMMM d, yyyy')}
            </Trans>
          </Text>
        ) : (
          <></>
        )}
      </Box>
      <Box className="confirmation__content">
        {release && (
          <CollectibleCard
            collectible={release}
            type="release"
            hasCollectibleTimer={false}
            showOwnerInUrl={isFixedPrice}
            showReleaseAsCollectible={
              exchangeIntent === EXCHANGE_INTENTS.MAKE_OFFER &&
              exchange?.creatorID === exchange?.currentOwnerID
            }
          ></CollectibleCard>
        )}
      </Box>
      <Box className="confirmation__footer">
        <Heading className="confirmation__footer__title" as="h4">
          {t('confirmation.letTheWorldKnow')}
        </Heading>
        <Box className="confirmation__footer__share">
          <Box className="confirmation__footer__share__button" ref={ref}>
            <ShareIcon
              channel={shareChannelKeys.facebook}
              onClick={handleOpen}
              style={{ marginRight: 0 }}
            />
            {isOpen && (
              <Box className="confirmation__footer__share__button__dropdown">
                <ul>
                  <li>
                    <Link target="_blank" to={facebookUrl}>
                      {t('confirmation.facebookFeed')}
                    </Link>
                  </li>
                </ul>
              </Box>
            )}
          </Box>
          <ShareIcon channel={shareChannelKeys.twitter} url={releaseUrl} />
          {/* <ShareIcon channel={shareChannelKeys.snapchat} url={releaseUrl} /> */}
          {/* <ShareIcon channel={shareChannelKeys.instagram} url={releaseUrl} /> */}
          {/* <ShareIcon channel={shareChannelKeys.copy} url={releaseUrl} /> */}
        </Box>
      </Box>
      {isFixedPrice ? (
        <Box className="confirmation__utility">
          <ul>
            <li>
              {t('confirmation.utility.originalMedia')} <IoDownloadOutline />
            </li>
            <li>
              {t('confirmation.utility.officialCertificate')} <IoDownloadOutline />
            </li>
            <li>
              {t('confirmation.utility.orderPrint')} <FiPackage />
            </li>
          </ul>
        </Box>
      ) : null}
    </>
  );

  return (
    !exchangeNotFound && (
      <Modal isOpen={isOpenModal} onClose={setIsOpenModal} customWrapperStyle="confirmation__modal">
        <Container className="confirmation__container">
          {exchange ? (
            <ConfirmationContainerInner />
          ) : (
            <LoadingConfirmationContent title={t('confirmation.loading')} />
          )}
        </Container>
        {exchangeIntent !== EXCHANGE_INTENTS.MAKE_OFFER &&
        exchangeIntent !== EXCHANGE_INTENTS.PLACE_BID ? (
          <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
        ) : null}
      </Modal>
    )
  );
}

export default Confirmation;
