import { useTranslation, Trans } from 'react-i18next';
import { useCallback, useState, useRef, useEffect } from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';
import {
  Box,
  Container,
  Heading,
  Link,
  Text,
  NotFound,
  SkeletonLoading,
  Button
} from '@components';
import { useAuth } from '@libs/contexts/auth';
import { useConfirmationQuery, useRefreshSession } from '@pages/confirmation/fansinc/hooks';
import { usePollExchange } from '@libs/hooks/exchange';
import { useOrderPrintByID } from '@libs/hooks/prodigi';

const ErrorConfirmationContent = ({ title, description }) => (
  <Box className="confirmation">
    <Container className="confirmation__container">
      <Box className="confirmation__header">
        <Heading className="confirmation__header__title" as="h3">
          {title}
        </Heading>
        <Text as="p" className="confirmation__header__description">
          {description}
        </Text>
      </Box>
    </Container>
  </Box>
);

const LoadingConfirmationContent = ({ title }) => {
  const centerColClasses = 'flex w-full items-center';
  return (
    <>
      <Heading as={'h4'} className="mb-6 text-center">
        {title}
      </Heading>
      <Box className={`${centerColClasses} flex-col sm:flex-row-reverse`}>
        <SkeletonLoading className="h-96 w-full max-w-9/12 ml-4 mb-5" />
        <Box className={`confirmation__header ${centerColClasses} flex-col`}>
          <Box className={`confirmation__header__description ${centerColClasses} flex-col`}>
            <SkeletonLoading className="my-1 h-8 w-10/12" key={`skel-1`} />
          </Box>
          <Box className={`confirmation__header__description ${centerColClasses} flex-col`}>
            {[...Array(3)].map((_, i) => (
              <SkeletonLoading className="my-1 h-3 w-10/12" key={`skel-${i}`} />
            ))}
          </Box>
          <Box className={`confirmation__header__description ${centerColClasses} flex-col`}>
            {[...Array(2)].map((_, i) => (
              <SkeletonLoading className="my-1 h-3 w-10/12" key={`skel-${i}`} />
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};

const PageNotFound = () => (
  <Box className="my-24 mx-12">
    <NotFound />
  </Box>
);

const canvasStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0
};

const tierMapper = {
  NUMBERED_EDITION: 'Limited Edition',
  DIGITAL_ORIGINAL: 'Digital Original'
};

function Confirmation() {
  const { t } = useTranslation();
  const { status, referenceID, imageUrl, color } = useConfirmationQuery();
  const { exchange, timeoutExceeded, loading, getExchangeData } = usePollExchange(
    referenceID,
    2000
  );
  const { user } = useAuth();
  useRefreshSession();
  const refAnimationInstance = useRef(null);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio)
      });
  }, []);

  const selectedFrame = color === 'White' ? '7px solid #E0E0E0' : '7px solid black';
  const title = t('congratulation.orderPrintLabel');
  const description = (
    <Trans i18nKey="congratulation.orderPrintDescription">
      {exchange?.collectible?.creator?.displayName ||
        exchange?.collectible?.creator?.firstName + ' ' + exchange?.collectible?.creator?.lastName}
      {exchange?.collectible?.title}
      {tierMapper[exchange?.tierType]}
      {exchange?.serialIndex?.toString()}
    </Trans>
  );

  const hasError = status === 'fail' || timeoutExceeded;
  const notFound = !referenceID;

  useEffect(() => {
    getExchangeData(referenceID);
  }, [referenceID]);

  useEffect(() => {
    if (exchange) {
      makeShot(0.25, {
        spread: 26,
        startVelocity: 55
      });
      makeShot(0.25, {
        spread: 26,
        startVelocity: 55
      });

      makeShot(0.2, {
        spread: 60
      });

      makeShot(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8
      });

      makeShot(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2
      });

      makeShot(0.1, {
        spread: 120,
        startVelocity: 45
      });
    }
  }, [exchange, makeShot]);

  if (hasError) {
    return <ErrorConfirmationContent title={title} description={description} />;
  }

  if (notFound || (exchange && user?.id !== exchange?.owner)) {
    return <PageNotFound />;
  }

  const ConfirmationContainerInner = () => (
    <div className="confirmation__content-wrapper confirmation__content-wrapper--reverse">
      <Box className="confirmation__left confirmation__left--reverse">
        <Box style={{ border: selectedFrame }}>
          <img src={imageUrl} alt="" />
        </Box>
      </Box>
      <Box className="confirmation__right">
        <Box>
          <Heading className="confirmation__header__title" as="h3">
            {title}
          </Heading>
          <Text as="p" className="confirmation__header__description">
            {description}
          </Text>
          <Link to="/settings/transactions?t=Order">
            <Button>{t('congratulation.orderButton')}</Button>
          </Link>
        </Box>
      </Box>
    </div>
  );

  return (
    <Box className="confirmation">
      <Container className={`confirmation__container confirmation__container--landscape`}>
        {!loading ? (
          <>
            <ConfirmationContainerInner />
          </>
        ) : (
          <LoadingConfirmationContent title={t('confirmation.loading')} />
        )}
      </Container>
      <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
    </Box>
  );
}

export default Confirmation;
