import { forwardRef } from 'react';

const CheckboxCategory = forwardRef(function CheckboxCat(
  { children, disabled, errorMessage, helpMessage, isChecked, ...props },
  ref
) {
  return (
    <>
      <div className="form-checkbox checkbox-category">
        <label>
          <input disabled={disabled} type="checkbox" {...props} ref={ref} checked={isChecked} />
          <span className="checkmark"></span>
          {children}
        </label>
      </div>
      <div>
        {errorMessage && <span className="field-help field-help--error">{errorMessage}</span>}
        {helpMessage && <span className="field-help">{helpMessage}</span>}
      </div>
    </>
  );
});

export default CheckboxCategory;
