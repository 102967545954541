import cx from 'classnames';
import { useRef } from 'react';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';

import { Box, CollectibleSliderItem, Heading, Text, SkeletonLoading } from '@components';

import { ReactComponent as NoItem } from '@assets/svg/no-item.svg';
import { isNewCollectibleSlider } from '@libs/utils/collectible';

const CollectibleSlider = ({
  title,
  collectibles,
  loading,
  showEmpty,
  isRelease,
  className,
  showButton = true,
  ...props
}) => {
  const { t } = useTranslation();
  const sliderRef = useRef();
  const newSlider = isNewCollectibleSlider();
  const settings = {
    dots: true,
    focusOnSelect: true,
    arrows: false,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 5000,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'collectible-slider',
    variableWidth: false,
    afterChange: function (index) {
      if (index === sliderRef.current.props.children.length - 1) {
        setTimeout(() => sliderRef.current?.slickGoTo(0), sliderRef.current.props.autoplaySpeed);
      }
    }
  };

  return (
    <>
      {loading ? (
        <Box className="collectible-slider__skeleton">
          <SkeletonLoading className="collectible-slider__skeleton__item" />
          <SkeletonLoading className="collectible-slider__skeleton__item" />
        </Box>
      ) : (
        <>
          {title && collectibles?.length > 0 ? (
            <Box className="pb-4 md:pb-6">
              <Text className="use-h6 profile-title">{title}</Text>
            </Box>
          ) : null}
          {collectibles?.length > 0 ? (
            <div
              className={cx(
                newSlider ? 'collectible-slider-wrapper-v2' : 'collectible-slider-wrapper'
              )}
              {...props}
            >
              {isRelease ? (
                <Slider {...settings} ref={sliderRef}>
                  {collectibles &&
                    collectibles.map((collectible) => (
                      <CollectibleSliderItem
                        key={collectible.id}
                        collectible={collectible.collectible}
                        isRelease={isRelease}
                        release={collectible}
                        showButton={showButton}
                      ></CollectibleSliderItem>
                    ))}
                </Slider>
              ) : (
                <Slider {...settings} ref={sliderRef}>
                  {collectibles &&
                    collectibles.map((collectible) => (
                      <CollectibleSliderItem
                        key={collectible.id}
                        collectible={collectible}
                        showButton={showButton}
                      ></CollectibleSliderItem>
                    ))}
                </Slider>
              )}
            </div>
          ) : null}

          {showEmpty && !loading && collectibles?.length < 1 ? (
            <Box className="collectible-grid__empty">
              <NoItem />
              <Heading as="h4" className="collectible-grid__empty__title">
                {t('components.collectibleGrid.noItemsFoundProfile')}
              </Heading>
              <Text className="collectible-grid__empty__description">
                {t('components.collectibleGrid.noItemsFoundDescription')}
              </Text>
            </Box>
          ) : null}
        </>
      )}
    </>
  );
};

CollectibleSlider.defaultProps = {
  isRelease: false,
  showEmpty: false,
  showButton: true
};

export default CollectibleSlider;
