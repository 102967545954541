import { useRef, useEffect } from 'react';
import { Auth } from 'aws-amplify';

import useQuery from '@libs/utils/query';

const autoExternalSignIn = async (provider) => {
  try {
    await Auth.federatedSignIn({ provider: provider });
  } catch (error) {
    console.error('Error auto sign in: ', error);
  }
};

/**
 * useAutoExternalSignIn hook attempts to auto sign in user after users register
 * an account with Google / Facebook external provider.
 *
 * When user first registers an account using Google / Facebook, a native user is created
 * in AWS Cognito and an error is throwed to the front end when the process is succeeded.
 */

const useAutoExternalSignIn = () => {
  const numRetry = useRef(0);
  const query = useQuery();
  const errorDes = query.get('error_description');

  useEffect(() => {
    if (numRetry.current < 2 && errorDes) {
      if (errorDes.includes('LINKED_EXTERNAL_USER_Facebook')) {
        autoExternalSignIn('Facebook');
        numRetry.current += 1;
      }
      if (errorDes.includes('LINKED_EXTERNAL_USER_Google')) {
        autoExternalSignIn('Google');
        numRetry.current += 1;
      }
    }
  }, [errorDes]);
};

export default useAutoExternalSignIn;
