import useImage from 'use-image';

export const GetImage = (src) => {
  return useImage(src, 'Anonymous');
};

export const download = (uri, name) => {
  var link = document.createElement('a');
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const imageSize = (image, width, height) => {
  let imageWidth = image?.width;
  let imageHeight = image?.height;
  let isVertical = false;
  let canvaHeight = height;
  let canvaWidth = width;
  let positionX = 0;
  let positionY = 0;
  let padding = 15;

  if (image?.width >= image?.height) {
    isVertical = true;
  }

  if (width / 20 <= 16 && height / 20 <= 16) {
    padding = 9;
  }

  if (isVertical) {
    canvaHeight = width;
    canvaWidth = height;
    do {
      imageHeight = imageHeight / 1.001;
      imageWidth = imageWidth / 1.001;
    } while (imageHeight > canvaHeight - padding || imageWidth > canvaWidth - padding);
  } else {
    do {
      imageHeight = imageHeight / 1.001;
      imageWidth = imageWidth / 1.001;
    } while (imageHeight > canvaHeight - padding || imageWidth > canvaWidth - padding);
  }
  positionX = (canvaWidth - imageWidth) / 2;
  positionY = (canvaHeight - imageHeight) / 2;
  return {
    width: imageWidth,
    height: imageHeight,
    canvaHeight,
    canvaWidth,
    positionX,
    positionY
  };
};
