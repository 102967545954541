import { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { BiRightArrowAlt } from 'react-icons/bi';
import cx from 'classnames';
import {
  Box,
  Button,
  Container,
  ConfirmationModal,
  ConfirmationModalBitPay,
  CollectibleOwnerActions,
  CollectibleModal,
  CreatorCard,
  CreatorCardGrid,
  ExchangeTable,
  Grid,
  Heading,
  Link,
  Media,
  MediaVinyl,
  MediaVIP,
  OfficialBadge,
  ProfileHeader,
  SkeletonLoading,
  Tabs,
  TabItem,
  Text,
  UserCard
} from '@components';
import { useRelatedCreators } from '@libs/hooks/user';
import { useChannelByOwner } from '@libs/hooks/discourse';
import { useAuth } from '@libs/contexts/auth';
import { LISTING_TYPES } from '@libs/utils/exchange';
import useQuery from '@libs/utils/query';
import { collectibleTypeParser } from '@pages/collectible/fansinc/utils';
import { createAssetUrl } from '@libs/utils/media';
import {
  useRedirect,
  useExchangesForUnitPage,
  useCollectibleAttribute
} from '@pages/release/fansinc/hooks';
import CollectibleContent from '@pages/release/fansinc/collectible-content';
import { urlParser } from '@pages/profile/utils';

const CollectibleUrl = ({ exchange, isLoadingExchange }) => {
  if (isLoadingExchange) {
    return <SkeletonLoading className="w-full h-4" />;
  }

  return `fans.inc/${exchange?.collectible.creator?.username}/${exchange?.slug}/${
    exchange?.serialIndex
  }/
      ${
        exchange?.currentOwner?.username === exchange?.creator?.username
          ? ''
          : exchange?.currentOwner?.username
      }`;
};

function Release() {
  const { t } = useTranslation();
  const { edition, owner, slug, username: creatorUsername_ } = useParams();
  const creatorUsername = creatorUsername_.toLowerCase();
  const { user } = useAuth();
  const history = useHistory();
  const query = useQuery();
  const [isCollectibleModalOpen, setCollectibleModalOpen] = useState(false);
  const exchangesForUnitPage = useExchangesForUnitPage({ slug, serialIndex: edition });
  const {
    currentExchange: exchange,
    isLoadingCurrentExchange: isLoadingExchange,
    unitExchanges,
    isAnExchangeOwnedByUser,
    clearData: clearExchangeData,
    bidExchanges,
    isLoadingBidExchanges,
    offerExchanges,
    refetchOffer,
    isLoadingOfferExchanges
  } = exchangesForUnitPage;
  const [collectible, creator, serialIndex] = [
    exchange?.collectible,
    exchange?.collectible?.creator,
    exchange?.serialIndex
  ];
  useRedirect({ exchange, isLoadingExchange });
  const { data: channels } = useChannelByOwner(creator?.id);
  const collectibleAttribute = useCollectibleAttribute(collectible, channels, serialIndex);
  const { data: relatedCreators, loading: relatedCreatorsLoading } =
    useRelatedCreators(creatorUsername);
  const buyNowRef = useRef();
  const handleButtonInsideStory = () => {
    buyNowRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (exchange && creator?.id !== exchange?.currentOwnerID) {
      if (!owner && !query.get('status')) {
        history.replace(urlParser(exchange, 'exchange'));
      }

      if (owner !== exchange?.currentOwner?.username) {
        history.replace(urlParser(exchange, 'exchange'));
      }
    }
    // eslint-disable-next-line
  }, [exchange, owner, query]);

  if (process.env.NODE_ENV === 'development') {
    console.info('🧪 exchange data', exchangesForUnitPage);
  }

  const isNotVIP = collectible?.category !== 'VIP_PASS';

  return (
    <>
      {query.get('pvd') === 'bitpay' ? (
        <ConfirmationModalBitPay />
      ) : (
        <ConfirmationModal fetchData={clearExchangeData} />
      )}
      <ProfileHeader user={creator} loading={isLoadingExchange} />
      <CollectibleModal
        collectible={collectible}
        onShow={isCollectibleModalOpen && collectible?.category !== 'VIP_PASS'}
        onClose={() => {
          setCollectibleModalOpen(false);
        }}
      />
      <Container className="collectible">
        <Grid className="collectible__grid">
          <Box className="collectible__media">
            <Box className={cx('collectible__media__content h-13', !isNotVIP && 'hidden')}>
              <Box>
                {collectible?.category ? (
                  isNotVIP && <OfficialBadge type={collectible?.category} isImage />
                ) : (
                  <SkeletonLoading className="w-full h-12" />
                )}
                <Heading className="collectible__content__title" as="h2">
                  {collectible?.title ? (
                    isNotVIP && collectible?.title
                  ) : (
                    <SkeletonLoading className="w-full h-10" />
                  )}
                </Heading>
                <Text as="Box" className="collectible__content__url">
                  {collectible?.title ? (
                    isNotVIP && (
                      <CollectibleUrl exchange={exchange} isLoadingExchange={isLoadingExchange} />
                    )
                  ) : (
                    <SkeletonLoading className="w-full h-10" />
                  )}
                </Text>
              </Box>
            </Box>
            <Box
              className="cursor-pointer"
              onClick={() => {
                setCollectibleModalOpen(true);
              }}
            >
              {collectible?.streamExternal !== 'TRUE' && collectible?.category === 'SONG' ? (
                <MediaVinyl
                  edition={exchange?.serialIndex}
                  collectible={collectible}
                  owner={exchange?.currentOwner}
                />
              ) : collectible?.category === 'VIP_PASS' ? (
                <MediaVIP
                  collectible={collectible}
                  url={CollectibleUrl({ exchange: exchange, isLoadingExchange: isLoadingExchange })}
                />
              ) : (
                <Media
                  collectible={collectible}
                  full
                  showPlayer
                  size={1080}
                  showPlayerSlider
                  showExternalLink={collectible?.streamExternal === 'TRUE'}
                />
              )}
            </Box>
            {exchange && (
              <CollectibleOwnerActions
                exchange={exchange}
                collectible={exchange?.collectible}
                isOwner={exchange.currentOwnerID === user?.id}
                isRelease
              />
            )}
          </Box>
          <CollectibleContent
            isLoadingExchange={isLoadingExchange}
            exchange={exchange}
            currentBidExchange={bidExchanges[0]}
            buyNowRef={buyNowRef}
            collectibleAttribute={collectibleAttribute}
            isAnExchangeOwnedByUser={isAnExchangeOwnedByUser}
            handleButtonInsideStory={handleButtonInsideStory}
          />
        </Grid>
      </Container>
      <Container className="pb-20">
        <Tabs
          classNameChild="bg-gray-850 py-9 shadow-inner-section"
          childrenButton={
            <Link to={`/${exchange?.collectible.creator?.username}/${exchange?.slug}`}>
              <Box className="tab-button inline-block">
                <Box className="flex items-center">
                  {t('release.historyTabs.otherEditions')}{' '}
                  <BiRightArrowAlt className="ml-1 md:ml-2" />
                </Box>
              </Box>
            </Link>
          }
        >
          {exchange?.listingType === LISTING_TYPES.AUCTION && (
            <TabItem label={t('release.historyTabs.bids')}>
              <ExchangeTable
                exchanges={bidExchanges}
                isLoading={isLoadingBidExchanges}
                listingType={LISTING_TYPES.AUCTION}
                isExchangeStatsVisible
                tableType="bidHistory"
              />
            </TabItem>
          )}
          <TabItem label={t('release.historyTabs.sales')}>
            <ExchangeTable
              exchanges={unitExchanges}
              isLoading={isLoadingExchange}
              listingType="SALE"
              isExchangeStatsVisible={exchange?.listingType !== 'NFT'}
              tableType="saleHistory"
            />
          </TabItem>
          <TabItem label={t('release.historyTabs.offers')}>
            <ExchangeTable
              exchanges={offerExchanges}
              isLoading={isLoadingOfferExchanges}
              listingType={LISTING_TYPES.MAKE_AN_OFFER}
              isExchangeStatsVisible
              refetch={refetchOffer}
              tableType="offerHistory"
              isOwner={exchange?.currentOwnerID === user?.id}
            />
          </TabItem>
        </Tabs>
      </Container>

      <Container className="profile__about" id={'about'}>
        <UserCard loading={isLoadingExchange ? 1 : 0} user={creator} />
      </Container>

      {relatedCreators && (
        <Box className="shadow-inner-section bg-gray-800">
          <Container className="pt-12 pb-8">
            <Box className="flex flex-row justify-between items-center mb-6">
              <Text className="text-default">{t('profile.fansAlsoViewed')}</Text>
              <Link to={`/${creatorUsername}/related`}>
                <Button outline>{t('viewAll')}</Button>
              </Link>
            </Box>
            <CreatorCardGrid>
              {relatedCreators &&
                relatedCreators.map((creator) => (
                  <CreatorCard
                    key={creator.id}
                    user={creator}
                    className="creator-card--item"
                    loading={relatedCreatorsLoading}
                  />
                ))}
            </CreatorCardGrid>
          </Container>
        </Box>
      )}
      <Helmet>
        <link rel="canonical" href={'https://fans.inc' + window.location.pathname} />
        <meta name="URL" content={'https://fans.inc' + window.location.pathname} />
        <meta name="twitter:card" content="summary_large_image" />
        <title>
          {t('release.meta.title', {
            creator: creator?.displayName || `${creator?.firstName} ${creator?.lastName}`,
            title: collectible?.title,
            edition:
              exchange?.tierType === 'NUMBERED_EDITION'
                ? `Limited Edition #${exchange?.serialIndex}`
                : 'Digital Original #1',
            type: collectibleTypeParser[collectible?.category]
          })}
        </title>
        <meta
          name="description"
          content={t('release.meta.description', {
            creator: creator?.displayName || `${creator?.firstName} ${creator?.lastName}`,
            title: collectible?.title,
            edition:
              exchange?.tierType === 'NUMBERED_EDITION'
                ? `Limited Edition #${exchange?.serialIndex} of Total ${collectible?.maxNumberedEditions}`
                : 'Digital Original #1 of Total 1',
            type: collectibleTypeParser[collectible?.category]
          })}
        />
        <meta property="og:url" content={'https://fans.inc' + window.location.pathname} />
        <meta
          property="og:title"
          content={t('release.meta.title', {
            creator: creator?.displayName || `${creator?.firstName} ${creator?.lastName}`,
            title: collectible?.title,
            edition:
              exchange?.tierType === 'NUMBERED_EDITION'
                ? `Limited Edition #${exchange?.serialIndex}`
                : 'Digital Original #1',
            type: collectibleTypeParser[collectible?.category]
          })}
        />
        <meta
          property="og:description"
          content={t('release.meta.description', {
            creator: creator?.displayName || `${creator?.firstName} ${creator?.lastName}`,
            title: collectible?.title,
            edition:
              exchange?.tierType === 'NUMBERED_EDITION'
                ? `Limited Edition #${exchange?.serialIndex} of Total ${collectible?.maxNumberedEditions}`
                : 'Digital Original #1 of Total 1',
            type: collectibleTypeParser[collectible?.category]
          })}
        />
        <meta
          property="og:image"
          content={
            collectible?.mediaThumbnail
              ? createAssetUrl(collectible?.mediaThumbnail, 800)
              : 'https://cdn.fans.inc/public/webapp/og-image.png'
          }
        />
      </Helmet>
    </>
  );
}

export default Release;
