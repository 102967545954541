import FansincShareIcon from './fansinc';
import PatronsShareIcon from './patrons';

const ShareIcon = ({
  pathname,
  onClick,
  children,
  onFrame,
  channel,
  url,
  style,
  onClickParent
}) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return (
      <PatronsShareIcon
        pathname={pathname}
        onClick={onClick}
        onFrame={onFrame}
        channel={channel}
        url={url}
        style={style}
        onClickParent={onClickParent}
      >
        {children}
      </PatronsShareIcon>
    );
  } else {
    return (
      <FansincShareIcon
        pathname={pathname}
        onClick={onClick}
        onFrame={onFrame}
        channel={channel}
        url={url}
        style={style}
        onClickParent={onClickParent}
      >
        {children}
      </FansincShareIcon>
    );
  }
};

export default ShareIcon;
