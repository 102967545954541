import { Trans } from 'react-i18next';

import { Price } from '@components';
import { EXCHANGE_INTENTS } from '@libs/utils/exchange';

const tierMapper = {
  NUMBERED_EDITION: 'Limited Edition',
  DIGITAL_ORIGINAL: 'Digital Original'
};

export const getTitleAndDescription = ({
  exchangeIntent,
  exchange,
  status,
  timeoutExceeded,
  t,
  currencyCode,
  currencyFormatter
}) => {
  const release = exchange?.release;
  let title = t('congratulation.label') + ' ' + release?.currentOwner?.firstName;
  let description = '';

  switch (exchangeIntent) {
    case EXCHANGE_INTENTS.FIXED_PRICE_PURCHASE:
      description = (
        <Trans i18nKey="confirmation.fixedPriceDescription">
          {release?.collectible?.title}
          {tierMapper[release?.tierType]}
        </Trans>
      );
      break;

    case EXCHANGE_INTENTS.MAKE_OFFER: {
      title = t('confirmation.offerConfirmed');
      const price = currencyCode + currencyFormatter.format(exchange?.offerPrice);
      description = (
        <Trans i18nKey="confirmation.makeAnOfferDescription">
          {price}
          {release?.collectible?.title}
          {tierMapper[release?.tierType]}
        </Trans>
      );
      break;
    }

    case EXCHANGE_INTENTS.PLACE_BID: {
      title = t('confirmation.bidConfirmed');
      const bidPrice = currencyCode + currencyFormatter.format(exchange?.bidPrice);
      description = (
        <Trans i18nKey="confirmation.auctionDescription">
          {bidPrice}
          {release?.collectible?.title}
          {tierMapper[release?.tierType]}
        </Trans>
      );
      break;
    }

    case EXCHANGE_INTENTS.ACCEPT_OFFER:
      title = t('confirmation.acceptOffer.title');
      description = (
        <Trans i18nKey="confirmation.acceptOffer.description">
          {release?.collectible?.title}
          {tierMapper[release?.tierType]}
          {release?.serialIndex?.toString()}
          {<Price amount={exchange?.offerPrice} inline />}
        </Trans>
      );
      break;

    default:
      description = (
        <Trans i18nKey="confirmation.fixedPriceDescription">
          {release?.collectible?.title}
          {tierMapper[release?.tierType]}
        </Trans>
      );
      break;
  }

  if (status === 'fail') {
    title = t('confirmation.errors.title');
    description = t('confirmation.errors.paymentFailed');
  }

  if (timeoutExceeded) {
    title = t('confirmation.errors.title');
    description = t('confirmation.errors.timeoutExceeded');
  }

  return { title, description };
};
