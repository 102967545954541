import cx from 'classnames';
import {
  FaFacebookF,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaSpotify,
  FaSoundcloud,
  FaRegEnvelope,
  FaGlobe
} from 'react-icons/fa';
import { BrowserRouter as Router } from 'react-router-dom';
import { Link } from '@components/';
import { isObjectNull } from '@libs/utils/object';

const SocialMedia = ({ socialMedia, className, darkMode, ...props }) => {
  const defaultStyle = {
    'social-media': true,
    'social-media--dark': darkMode
  };

  const isSocialMediaNull = socialMedia && isObjectNull(socialMedia);

  return (
    !isSocialMediaNull && (
      <div className={cx(defaultStyle, className)} {...props}>
        <Router>
          <ul className="social-media__menu">
            {socialMedia?.facebook && (
              <li>
                <Link target="_blank" to={socialMedia?.facebook}>
                  <FaFacebookF />
                </Link>
              </li>
            )}
            {socialMedia?.instagram && (
              <li>
                <Link target="_blank" to={socialMedia?.instagram}>
                  <FaInstagram />
                </Link>
              </li>
            )}
            {socialMedia?.youtube && (
              <li>
                <Link target="_blank" to={socialMedia?.youtube}>
                  <FaYoutube />
                </Link>
              </li>
            )}
            {socialMedia?.twitter && (
              <li>
                <Link target="_blank" to={socialMedia?.twitter}>
                  <FaTwitter />
                </Link>
              </li>
            )}
            {socialMedia?.spotify && (
              <li>
                <Link target="_blank" to={socialMedia?.spotify}>
                  <FaSpotify />
                </Link>
              </li>
            )}
            {socialMedia?.souncloud && (
              <li>
                <Link target="_blank" to={socialMedia?.souncloud}>
                  <FaSoundcloud />
                </Link>
              </li>
            )}
            {socialMedia?.email && (
              <li>
                <Link target="_blank" to={`mailto:${socialMedia?.email}`}>
                  <FaRegEnvelope />
                </Link>
              </li>
            )}
            {socialMedia?.website && (
              <li>
                <Link target="_blank" to={socialMedia?.website}>
                  <FaGlobe />
                </Link>
              </li>
            )}
          </ul>
        </Router>
      </div>
    )
  );
};

export default SocialMedia;
