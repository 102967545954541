import FansincModal from './fansinc';
import PatronsModal from './patrons';

const Modal = ({
  isOpen,
  onClose,
  heading,
  formSubmit,
  formSuccess,
  isForm,
  children,
  error,
  closable,
  action,
  size,
  isCheckoutModal,
  showCloseIcon,
  showOnlyCloseIcon,
  closeIconSize,
  verticalCenter,
  customWrapperStyle,
  backgroundColor,
  unpadded,
  overlayOpacity = 0.6,
  hideScroll,
  onCloseOutsider,
  loading = false
}) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return (
      <PatronsModal
        isOpen={isOpen}
        onClose={onClose}
        heading={heading}
        formSubmit={formSubmit}
        formSuccess={formSuccess}
        isForm={isForm}
        error={error}
        closable={closable}
        action={action}
        size={size}
        isCheckoutModal={isCheckoutModal}
        showCloseIcon={showCloseIcon}
        showOnlyCloseIcon={showOnlyCloseIcon}
        closeIconSize={closeIconSize}
        verticalCenter={verticalCenter}
        customWrapperStyle={customWrapperStyle}
        backgroundColor={backgroundColor}
        unpadded={unpadded}
        overlayOpacity={overlayOpacity}
        hideScroll={hideScroll}
        onCloseOutsider={onCloseOutsider}
        loading={loading}
      >
        {children}
      </PatronsModal>
    );
  } else {
    return (
      <FansincModal
        isOpen={isOpen}
        onClose={onClose}
        heading={heading}
        formSubmit={formSubmit}
        formSuccess={formSuccess}
        isForm={isForm}
        error={error}
        closable={closable}
        action={action}
        size={size}
        isCheckoutModal={isCheckoutModal}
        showCloseIcon={showCloseIcon}
        showOnlyCloseIcon={showOnlyCloseIcon}
        closeIconSize={closeIconSize}
        verticalCenter={verticalCenter}
        customWrapperStyle={customWrapperStyle}
        backgroundColor={backgroundColor}
        unpadded={unpadded}
        overlayOpacity={overlayOpacity}
        hideScroll={hideScroll}
        onCloseOutsider={onCloseOutsider}
        loading={loading}
      >
        {children}
      </FansincModal>
    );
  }
};

export default Modal;
