import { forwardRef } from 'react';
import { Stage, Layer, Image, Rect, Text } from 'react-konva';
import { GetImage, imageSize } from './hooks';

const Template = forwardRef(function FlexImagePhonePlain(
  { collectibleImage, collectible, wrapperSize, collectionName, wrapperHeightSize },
  ref
) {
  const [image] = GetImage(collectibleImage);
  const { width, height, canvaHeight, canvaWidth, positionX, positionY } = imageSize(
    image,
    wrapperSize * 20,
    wrapperHeightSize * 20
  );
  return (
    <>
      <Stage width={canvaWidth} height={canvaHeight} className="flex-it-canvas" ref={ref}>
        <Layer>
          <Rect x={0} y={0} width={canvaWidth} height={canvaHeight} fill="WHITE" />
          <Image x={positionX} y={positionY} image={image} height={height} width={width} />
        </Layer>
      </Stage>
    </>
  );
});

export default Template;
