import cx from 'classnames';
import { forwardRef } from 'react';

const Input = forwardRef(function InputPlain(
  {
    as,
    children,
    disabled,
    errorMessage,
    fullWidth,
    helpMessage,
    helpMessageMargin,
    showBothErrorAndHelp,
    label,
    labelRight,
    required,
    size,
    ...props
  },
  ref
) {
  const defaultStyle = {
    'border-red-700': !!errorMessage
  };

  const sizeMapper = {
    xs: 'input-xs',
    lg: 'input-lg',
    md: 'input-md',
    sm: 'input-sm'
  };

  const hideHelp = errorMessage && helpMessage && !showBothErrorAndHelp;
  return (
    <>
      {label && (
        <label className="form-label" htmlFor="forms-validationInputCode_error">
          {label}
        </label>
      )}
      <div className={cx('relative', fullWidth && 'w-full')}>
        <input
          type={as}
          ref={ref}
          required={required}
          disabled={disabled}
          className={cx(
            'form-control',
            defaultStyle,
            sizeMapper[size],
            fullWidth && 'w-full',
            errorMessage && 'ring-1 ring-red-700'
          )}
          {...props}
        />
        {labelRight && <label className="label-right">{labelRight}</label>}
      </div>
      {errorMessage && <span className="field-help field-help--error">{errorMessage}</span>}
      {helpMessage && !hideHelp && (
        <span className={`field-help ${helpMessageMargin ? 'mt-4' : ''}`}>{helpMessage}</span>
      )}
    </>
  );
});

Input.defaultProps = {
  as: 'text',
  size: 'md',
  showBothErrorAndHelp: true
};

export default Input;
