import { Container } from '@components';
import aboutHeroImage from '@assets/img/patrons/about/about-hero.png';
import AboutDataFlexing from './AboutDataFlexing';

export default function AboutHero({ title, description, data }) {
  return (
    <Container>
      <div className="flex lg:flex-row flex-col mt-0">
        <div className="w-full lg:w-2/3">
          <div className="mr-4 lg:pr-0 pb-20">
            <div className="single-page__header__about">{title}</div>
            <p className="text-primary font-Satoshi text-left text-2xl font-normal mb-10 mr-0 lg:mr-8 leading-10">
              {description}
            </p>
            <div className={`grid grid-cols-2 sm:grid-cols-4 gap-y-8`}>
              {data.map((item, i) => (
                <AboutDataFlexing key={i} number={item.number} info={item.name} />
              ))}
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/3 relative">
          <img
            src={aboutHeroImage}
            className="static lg:absolute -bottom-5 lg:-bottom-20 xl:-bottom-32 w-full max-w-full lg:max-w-lg right-0 h-auto lg:h-fit"
            alt="about-hero"
          />
        </div>
      </div>
    </Container>
  );
}
