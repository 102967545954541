import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useAuth } from '@libs/contexts/auth';
import { useExchangeUrlParams } from '@libs/hooks/exchange';
import { useCollectible } from '@libs/hooks/collectible';
import { sizePrices, framePrices, shippingPrices } from '@pages/print-collectible/utils';
import { urlParser } from '@pages/profile/utils';
import { EXCHANGE_TYPES } from '@libs/utils/exchange';
import { itemTypes } from './utils';

const useExchangeFromUrlParams = () => {
  const params = useParams();
  const [slug, setSlug] = useState(null);
  const [edition, setEdition] = useState(null);
  const [exchange, setExchange] = useState(null);
  const [isLoadingExchange, setIsLoadingExchange] = useState(true);

  const { data: exchanges, loading: isLoadingExchanges } = useExchangeUrlParams({
    slug,
    exchangeType: EXCHANGE_TYPES.LISTING,
    serialIndex: edition
  });

  useEffect(() => {
    if (params && params.edition) {
      setSlug(params.slug);
      setEdition(params.edition);
    }
  }, [params]);

  useEffect(() => {
    if (slug && edition && !isLoadingExchanges) {
      setExchange(exchanges?.[0]);
      setIsLoadingExchange(false);
    }
  }, [slug, edition, isLoadingExchanges, exchanges]);

  return { exchange, isLoadingExchange };
};

const useCollectibleFromUrlParams = () => {
  const params = useParams();
  const [slug, setSlug] = useState(null);
  const [isLoadingCollectible, setIsLoadingCollectible] = useState(true);
  const { data: collectible, loading } = useCollectible(slug);
  useEffect(() => {
    if (params && !params.edition) {
      setSlug(params.slug);
    }
  }, [params]);

  useEffect(() => {
    if (slug && !loading) {
      setIsLoadingCollectible(false);
    }
  }, [slug, loading]);

  return { collectible, isLoadingCollectible };
};

export const useItemFromUrlParams = () => {
  const [item, setItem] = useState(null);
  // TODO: this should be replaced by item.TYPE - exchange needs TYPE in qgl query
  const [itemType, setItemType] = useState(null);
  const [isLoadingItem, setIsLoadingItem] = useState(true);
  const { exchange, isLoadingExchange } = useExchangeFromUrlParams();
  const { collectible, isLoadingCollectible } = useCollectibleFromUrlParams();

  useEffect(() => {
    if (!isLoadingExchange) {
      setItem(exchange);
      setItemType(itemTypes.EXCHANGE);
      setIsLoadingItem(false);
    } else if (!isLoadingCollectible) {
      setItem(collectible ?? undefined);
      setItemType(itemTypes.COLLECTIBLE);
      setIsLoadingItem(false);
    }
  }, [exchange, collectible, isLoadingExchange, isLoadingCollectible]);

  return { item, isLoadingItem, itemType };
};

export const useUrl = ({ item, itemType = 'release', showOwnerInUrl = false }) => {
  const [url, setUrl] = useState(null);

  useEffect(() => {
    const typeMapper = {
      [itemTypes.EXCHANGE]: 'release',
      [itemTypes.COLLECTIBLE]: 'collectible'
    };

    if (item) {
      setUrl(urlParser(item, typeMapper[itemType], showOwnerInUrl ?? true));
    }
  }, [item, itemType, showOwnerInUrl]);

  return url;
};

export const useCollectibleUrl = ({ item, itemType, showOwnerInUrl = false }) => {
  const [collectible, setCollectible] = useState(null);
  const url = useUrl({ item: collectible, itemType: itemTypes.COLLECTIBLE, showOwnerInUrl });

  useEffect(() => {
    if (item && itemType) {
      if (itemType === itemTypes.EXCHANGE) {
        setCollectible(item.collectible);
        return;
      }
      setCollectible(item);
    }
  }, [item, itemType, showOwnerInUrl]);

  return url;
};

export const useRedirect = ({ item, isLoadingItem }) => {
  const history = useHistory();
  useEffect(() => {
    if (item === undefined && !isLoadingItem) {
      history.push('/404');
    }
  }, [item, history, isLoadingItem]);
};

export const usePrintPrice = ({ selectedSize, selectedFrame, selectedShipping, quantity }) => {
  const [printPrice, setPrintPrice] = useState(0);

  useEffect(() => {
    let printPrice_ = 0;
    printPrice_ += sizePrices[selectedSize] ?? 0;
    printPrice_ += framePrices[selectedFrame] ?? 0;
    printPrice_ += shippingPrices[selectedShipping] ?? 0;
    printPrice_ *= quantity ?? 0;
    isNaN(printPrice_) && (printPrice_ = 0);
    setPrintPrice(printPrice_);
  }, [selectedSize, selectedFrame, selectedShipping, quantity]);

  return printPrice;
};

export const useIsOwner = ({ item, itemType }) => {
  const { user } = useAuth();
  const [isOwner, setIsOwner] = useState(null);

  useEffect(() => {
    if (user && item && itemType === itemTypes.EXCHANGE) {
      setIsOwner(item.currentOwnerID === user.id);
    } else if (!user || itemType === itemTypes.COLLECTIBLE) {
      setIsOwner(false);
    }
  }, [user, item, itemType]);

  return isOwner;
};
