import { userAddressFragment } from '@libs/fragments/user';

export const searchWalletHistories = /* GraphQL */ `
  query SearchWalletHistories(
    $filter: SearchableWalletHistoryFilterInput
    $sort: SearchableWalletHistorySortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchWalletHistories(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        senderID
        receiverID
        paymentProvider
        debit
        credit
        invoiceID
        referenceID
        exchangeID
        balance
        transactionType
        status
        description
        withdrawal {
          provider
          destination
        }
        createdAt
        updatedAt
        searchableTransactionType
        searchableStatus
        _version
        _deleted
        sender {
          ...UserAddressProfile
        }
        receiver {
          ...UserAddressProfile
        }
        owner
      }
      nextToken
      total
    }
  }
  ${userAddressFragment}
`;
