import { userPublicFragment } from '@libs/fragments/user';

export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: SearchableUserSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchUsers(filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken, from: $from) {
      items {
        ...UserPublicProfile
      }
      nextToken
      total
    }
  }

  ${userPublicFragment}
`;

export default searchUsers;
