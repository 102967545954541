export const isSelected = (key, selectedKey) => key === selectedKey;

export const getButtonStyle = ({
  option,
  selectedKey,
  selectedButtonStyle,
  unselectedButtonStyle
}) => ({
  [isSelected(option.key, selectedKey) ? selectedButtonStyle : unselectedButtonStyle]: true,
  selected: isSelected(option.key, selectedKey)
});
