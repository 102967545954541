import cx from 'classnames';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ClampLines from 'react-clamp-lines';
import {
  Box,
  CollectibleCardUser,
  CollectibleTier,
  CollectibleLike,
  Heading,
  Link,
  Media,
  CollectibleOwnerActions,
  Separator
} from '@components';
import { getNearestReleaseDate } from '@libs/utils/collectible';
import { urlParser, collectibleParser } from '@pages/profile/utils';
import { useAuth } from '@libs/contexts/auth';
import { getButton, getPriceRelease } from '../../collectible-card.v2/utils';

const CollectibleSliderItemV2 = ({
  collectible,
  isRelease,
  release,
  className,
  showButton,
  ...props
}) => {
  const { t } = useTranslation();
  const [url, setUrl] = useState(null);
  const collectibleLink = isRelease
    ? `${release?.creator?.username}/${collectible?.slug}/${release?.serialIndex}/${release?.currentOwner?.username}`
    : `${collectible?.creator?.username}/${collectible?.slug}`;

  const type = isRelease ? 'release' : 'collectible';
  const { user } = useAuth();
  const data = collectibleParser(isRelease ? release : collectible, type);

  const releaseDate = getNearestReleaseDate(collectible?.releases || []);
  data?.releases?.sort((a, b) =>
    a.tierType === 'DIGITAL_ORIGINAL' ? -1 : a.releaseDate > b.releaseDate ? 1 : -1
  );

  useEffect(() => {
    let url_ = urlParser(data, 'collectible');
    setUrl(url_);
  }, []);

  const [currentTime, setCurrentTime] = useState(new Date().getTime());
  const releaseDateSeconds = new Date(releaseDate).getTime();
  const auctionEndDate =
    type === 'collectible' &&
    data?.releases[0]?.saleEndDate &&
    data?.releases[0]?.listingType === 'AUCTION'
      ? new Date(data?.releases[0]?.saleEndDate)
      : null;

  useEffect(() => {
    let intervalId = null;
    if (releaseDate) {
      intervalId = setInterval(() => {
        const now = new Date().getTime();
        setCurrentTime(now);
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [releaseDate]);

  return (
    <Box className={cx('collectible-slider__item', className)} {...props}>
      <Box className="collectible-slide">
        <Box className="collectible-slide__media">
          <Link to={collectibleLink}>
            <Media collectible={collectible} showPlayer thumbnail />
            {!isRelease && (
              <Box className="collectible-card-new__tier">
                {collectible?.promoEnabled !== 'TRUE' && (
                  <CollectibleTier tier="DIGITAL_ORIGINAL" collectible={collectible} />
                )}
                <CollectibleTier tier="NUMBERED_EDITION" collectible={collectible} />
              </Box>
            )}

            {isRelease && (
              <Box className="collectible-card-new__tier">
                <CollectibleTier
                  tier={release?.tierType}
                  type={isRelease && 'release'}
                  collectible={release}
                />
              </Box>
            )}
          </Link>
        </Box>
        <Box className="collectible-slide__content">
          <Box className="collectible-slide__content__top">
            <Link to={collectibleLink}>
              <Heading as="h3" className="collectible-slide__content__name">
                {collectible?.title}
              </Heading>
            </Link>
            <Box className="collectible-slide__content__creator">
              <Link to={collectible?.creator?.username}>
                <CollectibleCardUser user={collectible?.creator} />
              </Link>
              <CollectibleLike collectibleID={collectible?.id} />
            </Box>
            <Link to={collectibleLink}>
              <Box className="collectible-slide__content__description">
                <ClampLines moreText="" text={collectible?.description} lines={3} ellipsis="..." />
              </Box>
            </Link>
          </Box>
          <Box>
            <Separator dark className="mb-4" />
            <Box className="mb-4">
              {getPriceRelease(
                type,
                data,
                isRelease ? release : collectible,
                auctionEndDate,
                releaseDate,
                releaseDateSeconds,
                currentTime,
                collectible?.lastSoldAt
              )}
            </Box>
            {type === 'collectible' ? (
              getButton(true, type, url, releaseDateSeconds, currentTime)
            ) : (
              <>
                <Separator dark className="mb-4" />
                <Box className="collectible-slide__content__owner">
                  <CollectibleOwnerActions
                    collectible={collectible}
                    exchange={release}
                    isOwner={release?.currentOwner?.id === user?.id}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

CollectibleSliderItemV2.defaultProps = {
  isRelease: false
};

export default CollectibleSliderItemV2;
