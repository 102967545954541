import { useTranslation } from 'react-i18next';
import { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import gql from '@libs/utils/gql';
import { searchCollections, getCollectionStash } from '@libs/custom-queries/collection';
import { createCollections, updateCollections, deleteCollections } from '@graphql/mutations'; // eslint-disable-line
import useToast from '@libs/utils/toast';
import { useCollectionParams } from '@libs/hooks/collection/utils';

export const useCollection = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [createLoading, setCreateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const toast = useToast();
  const history = useHistory();
  const {
    query: params,
    onChangeName,
    onPaginate,
    onChangeType,
    onSortBy,
    onReset
  } = useCollectionParams();
  const [nextPageToken, setNextPageToken] = useState();

  const getData = async () => {
    setLoading(true);
    try {
      const { data: result } = await gql(searchCollections, params);
      if (result?.searchCollections?.items) {
        setData(data.concat(result?.searchCollections?.items));
        setTotal(result?.searchCollections?.total);
        setNextPageToken(result?.searchCollections?.nextToken);
      }
    } catch (error) {
      const errorMessage = error.errors[0]?.errorType;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onFilterName = (data) => {
    setData([]);
    onChangeName(data);
  };

  const onFilterType = (data) => {
    setData([]);
    onChangeType(data);
  };

  const onResetFilter = (data) => {
    setData([]);
    onReset(data);
  };

  useEffect(() => {
    getData(params);
  }, [params]); // eslint-disable-line

  const onCreate = useCallback(
    async (payload) => {
      setCreateLoading(true);

      try {
        await gql(
          createCollections,
          {
            input: {
              name: payload.name,
              collectionType: payload.collectionType,
              avatarUrl: payload.avatarUrl,
              coverUrl: payload.coverUrl,
              logoUrl: payload.logoUrl
            }
          },
          {
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          }
        );

        toast(t('collections.add.success'), 'success');
        history.push('/collections');
      } catch (error) {
        const errorMessage = error.errors[0]?.message;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setCreateLoading(false);
      }
    },
    [] // eslint-disable-line
  );

  const onSort = (sortBy) => {
    setData([]);
    onSortBy(sortBy);
  };

  const onDelete = async (payload) => {
    setDeleteLoading(true);
    try {
      await gql(
        deleteCollections,
        {
          input: {
            id: payload
          }
        },
        {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }
      );

      toast(t('collections.delete.title'), 'success');
    } catch (error) {
      const errorMessage = error.errors[0]?.message;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setDeleteLoading(false);
    }
  };

  return {
    data,
    loading,
    createLoading,
    onCreate,
    nextPageToken,
    getData,
    onDelete,
    onPaginate,
    onFilterName,
    onFilterType,
    onSort,
    onResetFilter,
    total,
    deleteLoading
  };
};

export const useCollectionBySlug = (slug) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [updateLoading, setUpdateLoading] = useState(false);
  const toast = useToast();

  const getData = async () => {
    setLoading(true);
    const params = { limit: 10, filter: { slug: { eq: slug } } };
    try {
      const { data: result } = await gql(searchCollections, params);
      if (result?.searchCollections?.items) {
        setData(result?.searchCollections?.items[0]);
      }
    } catch (error) {
      const errorMessage = error.errors[0]?.errorType;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (slug) {
      getData();
    }
  }, [slug]); // eslint-disable-line

  const onUpdate = useCallback(
    async (payload) => {
      setUpdateLoading(true);

      try {
        await gql(
          updateCollections,
          {
            input: {
              id: payload.id,
              name: payload.name,
              collectionType: payload.collectionType,
              avatarUrl: payload.avatarUrl,
              coverUrl: payload.coverUrl,
              logoUrl: payload.logoUrl
            }
          },
          {
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          }
        );

        toast(t('collections.edit.success'), 'success');
      } catch (error) {
        const errorMessage = error.errors[0]?.message;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setUpdateLoading(false);
      }
    },
    [] // eslint-disable-line
  );

  return {
    data,
    loading,
    updateLoading,
    onUpdate,
    getData
  };
};

export const useCollectionById = (id) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [collectionStash, setCollectionStash] = useState();
  const toast = useToast();

  const getData = async () => {
    setLoading(true);
    const params = { limit: 10, filter: { id: { eq: id } } };
    try {
      const { data: result } = await gql(searchCollections, params);
      if (result?.searchCollections?.items) {
        setData(result?.searchCollections?.items[0]);
      }
    } catch (error) {
      const errorMessage = error.errors[0]?.errorType;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const collectionStashFunction = async () => {
    try {
      const collectionStash = await gql(getCollectionStash, {
        input: {
          collectionID: id
        }
      });
      setCollectionStash(JSON.parse(collectionStash?.data?.getCollectionStash));
    } catch (error) {
      const errorMessage = error.errors[0]?.errorType;
      toast(errorMessage, 'error');
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      getData();
      collectionStashFunction();
    }
  }, [id]); // eslint-disable-line

  return {
    data,
    loading,
    collectionStash,
    getData
  };
};

export const useCollectionByName = (limit = 3) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  async function getData(name) {
    setLoading(true);
    try {
      const params = {
        filter: {
          name: { matchPhrasePrefix: name }
        },
        sort: { direction: 'desc', field: 'createdAt' },
        limit
      };

      const { data: res } = await gql(searchCollections, params);

      if (res?.searchCollections?.items?.length > 0) {
        setData(res.searchCollections.items);
      } else {
        setData([]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  return {
    data,
    loading,
    getData
  };
};
