import { Storage } from 'aws-amplify';
import { useEffect, useState } from 'react';

import { createAssetUrl } from '@libs/utils/media';

const Image = ({ src, alt, size, fallback, ...props }) => {
  const [image, setImage] = useState();

  useEffect(() => {
    async function fetchImage() {
      if (src && !src.startsWith('http')) {
        const s3Key = createAssetUrl(src, size);
        if (s3Key.startsWith('http')) {
          setImage(s3Key);
        } else {
          const result = await Storage.get(s3Key, { contentType: 'image/png' });
          setImage(result);
        }
      } else if (src) {
        setImage(src);
      } else {
        setImage(fallback);
      }
    }

    fetchImage();
  }, [src, fallback, size]);

  return (
    <img
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = 'https://cdn.fans.inc/public/images/fallback.png';
      }}
      {...props}
      src={image}
      alt={alt}
    />
  );
};

export default Image;
