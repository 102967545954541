import { Helmet } from 'react-helmet';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Price,
  SkeletonLoading,
  TabItem,
  Tabs,
  Text,
  WalletHistoryTable,
  WithdrawalModal,
  OrderHistoryTable,
  PurchaseHistoryTable,
  SellingHistoryTable
} from '@components';
import { useAuth } from '@libs/contexts/auth';
import {
  useWalletByOwner,
  useWalletHistoryByOwnerID,
  useWalletHistoryBySenderAndTransactionType,
  useWalletHistoryByReceiverAndTransactionType
} from '@libs/hooks/wallet';
import useQuery from '@libs/utils/query';

function SettingsTransaction() {
  const { t } = useTranslation();
  const query = useQuery();
  const { user } = useAuth();
  const availableTabs = ['Balance', 'Purchase', 'Selling', 'Order'];
  const [activeTab, setActiveTab] = useState(
    availableTabs.includes(query.get('t'))
      ? query.get('t')
      : false || t('p.pages.settings.transactions.balance.title')
  );
  const [withdrawModalVisible, seWithdrawModalVisible] = useState(false);
  const {
    data: wallet,
    loading: walletLoading,
    getData: refetchWallet
  } = useWalletByOwner(user?.id);
  const {
    data: walletHistory,
    loading: walletHistoryLoading,
    nextPageToken: walletNextToken,
    getData: refetch
  } = useWalletHistoryByOwnerID(user?.id);
  const {
    data: purchaseHistory,
    loading: purchaseHistoryLoading,
    nextPageToken: purchaseNextToken,
    getData: refetchPurchaseHistory
  } = useWalletHistoryBySenderAndTransactionType(user?.id, 'SALES');
  const {
    data: sellingHistory,
    loading: sellingHistoryLoading,
    nextPageToken: sellingNextToken,
    getData: refetchSellingHistory
  } = useWalletHistoryByReceiverAndTransactionType(user?.id, 'SALES');

  const refetchData = () => {
    refetchWallet(user?.id);
    refetch(user?.id);
  };

  return (
    <Box className="transactions-settings">
      <Tabs
        className="transactions-settings-tabs -mt-2"
        classNameChild="py-5 lg:py-8"
        directTo={activeTab}
        full
      >
        <TabItem label={t('p.pages.settings.transactions.balance.title')}>
          <Text className="transactions-settings-balance-description">
            {t('p.pages.settings.transactions.balance.description')}
          </Text>
          <Box className="transactions-settings-balance__box">
            <Text className="transactions-settings-balance__box__current">
              {t('p.pages.settings.transactions.balance.currentBalance')}
            </Text>
            <Box className="transactions-settings-balance__box__price">
              {walletLoading ? (
                <SkeletonLoading className="transactions-settings__loading-wallet" />
              ) : (
                <Price amount={wallet?.amount} inline></Price>
              )}
            </Box>
            <Button onClick={() => seWithdrawModalVisible(true)}>
              {t('p.pages.settings.transactions.balance.button.withdrawBalance')}
            </Button>
            <WithdrawalModal
              balance={wallet?.amount}
              visible={withdrawModalVisible}
              onCancel={() => seWithdrawModalVisible(false)}
              refetch={refetchData}
            />
          </Box>
          <WalletHistoryTable
            histories={walletHistory}
            loading={walletHistoryLoading}
            handleNextPage={() => {
              setActiveTab(t('p.pages.settings.transactions.balance.title'));
              refetch(user?.id, walletNextToken);
            }}
            nextPage={walletNextToken}
          />
        </TabItem>
        <TabItem label={t('p.pages.settings.transactions.purchase')}>
          <PurchaseHistoryTable
            histories={purchaseHistory}
            loading={purchaseHistoryLoading}
            handleNextPage={() => {
              setActiveTab(t('p.pages.settings.transactions.purchase'));
              refetchPurchaseHistory(user?.id, purchaseNextToken);
            }}
            nextPage={purchaseNextToken}
          />
        </TabItem>
        <TabItem label={t('p.pages.settings.transactions.selling')}>
          <SellingHistoryTable
            histories={sellingHistory}
            loading={sellingHistoryLoading}
            handleNextPage={() => {
              setActiveTab(t('p.pages.settings.transactions.selling'));
              refetchSellingHistory(user?.id, sellingNextToken);
            }}
            nextPage={sellingNextToken}
          />
        </TabItem>
        <TabItem label={t('settings.transactions.order')}>
          <OrderHistoryTable />
        </TabItem>
      </Tabs>
      <Helmet>
        <title>{t('p.pages.settings.transactions.title')}</title>
        <meta property="og:image" content="https://cdn.fans.inc/public/webapp/og-image.png" />
      </Helmet>
    </Box>
  );
}

export default SettingsTransaction;
