import { EXCHANGE_INTENTS, mapListingTypeToExchangeIntent } from '@libs/utils/exchange';

const PAYMENT_STATUS_CODES = {
  NO_PAYMENT_CUSTOM: -1,
  SUCCESS: 201,
  ACTION_REQUIRED: 202,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  INVALID_DATA: 422,
  SERVER_ERROR: 500
};

export const QUERY_STATUSES_3DS = {
  SUCCESS: 'success',
  FAIL: 'fail'
};

export const MINIMUM_BID_AMOUNT = 10;

export const getBidsFromExchange = (exchange) => {
  if (!exchange) {
    return { currentBid: null, nextBid: null };
  }

  const hasBids = exchange?.previousPrice != null;
  const openingBid = exchange?.auctionMinimumAllowedBidPrice ?? null;
  const currentBid = hasBids ? exchange?.bidPrice : null;
  const nextBid = hasBids ? exchange?.currentPrice : openingBid;

  return { currentBid, nextBid };
};

export const processPaymentResponse = ({
  paymentResponse,
  exchangeIntent,
  onSuccess = () => {},
  onFail = (e) => {
    console.error(e);
  },
  onRedirect = () => {}
}) => {
  const {
    statusCode,
    body: {
      message: errorMessage,
      redirectUrl: redirectUrl_,
      approved,
      // eslint-disable-next-line no-unused-vars
      responseCode,
      responseSummary
    }
  } = paymentResponse;

  let validExchangeIntents = [];

  const validateExchangeIntent = (validExchangeIntents, exchangeIntent) => {
    let exchangeIntent_ = exchangeIntent;
    if (exchangeIntent === EXCHANGE_INTENTS.VALIDATE_TRANSACTION) {
      exchangeIntent_ = mapListingTypeToExchangeIntent[paymentResponse?.body?.actionListingType];
    }
    return validExchangeIntents?.includes(exchangeIntent_);
  };

  switch (statusCode) {
    case PAYMENT_STATUS_CODES.NO_PAYMENT_CUSTOM:
      validExchangeIntents = [EXCHANGE_INTENTS.PLACE_BID];
      if (validateExchangeIntent(validExchangeIntents, exchangeIntent)) {
        onSuccess(paymentResponse.body);
        return;
      }
      onFail('You purchase intent is not valid.');
      break;
    case PAYMENT_STATUS_CODES.SUCCESS:
      validExchangeIntents = [
        EXCHANGE_INTENTS.FIXED_PRICE_PURCHASE,
        EXCHANGE_INTENTS.MAKE_OFFER,
        EXCHANGE_INTENTS.PLACE_BID
      ];
      if (validateExchangeIntent(validExchangeIntents, exchangeIntent)) {
        if (approved) {
          onSuccess(paymentResponse.body);
          return;
        }
        onFail(`We have an error. ${responseSummary}`);
        return;
      }
      onFail('You purchase intent is not valid.');
      break;
    case PAYMENT_STATUS_CODES.ACTION_REQUIRED:
      onRedirect(redirectUrl_?.href);
      break;
    case PAYMENT_STATUS_CODES.BAD_REQUEST:
      onFail(`We have an error. ${errorMessage ?? 'Unknown Error.'}`);
      break;
    case PAYMENT_STATUS_CODES.UNAUTHORIZED:
      onFail(`You are not authorized to make this transaction.`);
      break;
    case PAYMENT_STATUS_CODES.FORBIDDEN:
      onFail(`We have an error. ${errorMessage ?? 'Unknown Error.'}`);
      break;
    case PAYMENT_STATUS_CODES.INVALID_DATA:
      onFail('Payment failed. Invalid payment data was sent.');
      break;
    case PAYMENT_STATUS_CODES.SERVER_ERROR:
      onFail(
        errorMessage
          ? `Error: ${errorMessage}`
          : 'Payment failed. We are currently having problems with our payments. Try again later.'
      );
      break;
    default:
      onFail('We are currently having problems with our payments. Try again later.');
      break;
  }
};

export const processPaymentPrintCollectibleResponse = ({
  paymentResponse,
  onSuccess = () => {},
  onFail = (e) => {
    console.error(e);
  },
  onRedirect = () => {}
}) => {
  const statusCode = paymentResponse.statusCode;
  const errorMessage = paymentResponse.message || '';
  const redirectUrl_ = paymentResponse.links.redirect;

  switch (statusCode) {
    case PAYMENT_STATUS_CODES.SUCCESS:
      onSuccess(paymentResponse.body);
      break;
    case PAYMENT_STATUS_CODES.ACTION_REQUIRED:
      onRedirect(redirectUrl_?.href);
      break;
    case PAYMENT_STATUS_CODES.BAD_REQUEST:
      onFail(`We have an error. ${errorMessage ?? 'Unknown Error.'}`);
      break;
    case PAYMENT_STATUS_CODES.UNAUTHORIZED:
      onFail(`You are not authorized to make this transaction.`);
      break;
    case PAYMENT_STATUS_CODES.FORBIDDEN:
      onFail(`We have an error. ${errorMessage ?? 'Unknown Error.'}`);
      break;
    case PAYMENT_STATUS_CODES.INVALID_DATA:
      onFail('Payment failed. Invalid payment data was sent.');
      break;
    case PAYMENT_STATUS_CODES.SERVER_ERROR:
      onFail(
        errorMessage
          ? `Error: ${errorMessage}`
          : 'Payment failed. We are currently having problems with our payments. Try again later.'
      );
      break;
    default:
      onFail('We are currently having problems with our payments. Try again later.');
      break;
  }
};
