import { useParams } from 'react-router-dom';
import { useUserByUsername } from '@libs/hooks/user';
import { Box, Login, Logo } from '@components';
import { profileMapper } from '@libs/utils/mapper';
import NotFound from '@pages/not-found';

function ProfileLogin() {
  const params = useParams();
  const username = params.username.toLowerCase();
  const asset = profileMapper[username];
  const { data: profile, loading: profileLoading } = useUserByUsername(username);

  return (
    <>
      {typeof profile === 'undefined' && !profileLoading ? (
        <NotFound />
      ) : (
        <Box className="profile-auth" style={{ backgroundImage: `url(${asset?.bg})` }}>
          <Box className="profile-auth__box">
            <Login
              title={
                asset?.logo && (
                  <Box className="profile-auth__title">
                    {asset?.logo && <img src={asset?.logo} />}
                    <span>on</span>
                    <Logo />
                  </Box>
                )
              }
              redirect={`/${username}`}
              signupUrl={`/${username}/signup`}
              showSocialLogin={false}
            />
          </Box>
        </Box>
      )}
    </>
  );
}

export default ProfileLogin;
