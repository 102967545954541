import FansincPrintInvoice from './fansinc';
import PatronsPrintInvoice from './patrons';

const PrintInvoice = ({ history, amount, type, children }) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return (
      <PatronsPrintInvoice history={history} amount={amount} type={type}>
        {children}
      </PatronsPrintInvoice>
    );
  } else {
    return (
      <FansincPrintInvoice history={history} amount={amount} type={type}>
        {children}
      </FansincPrintInvoice>
    );
  }
};

export default PrintInvoice;
