import { SuspenseLoading } from '@components';
import ProfileMain from './template/main';
import ProfileTonyMott from './template/tony-mott';
import Default from '@pages/default';

function ProfileTemplate({ profile, profileLoading, refetchData }) {
  const profileName = profile?.verified
    ? profile?.displayName || `${profile?.firstName} ${profile?.lastName}`
    : `${profile?.firstName} ${profile?.lastName}`;
  const title = () => {
    if (profile?.verified === 'TRUE') {
      return `${profileName}'s Official Limited Edition Digital Collectible NFTs on Fans.Inc`;
    } else {
      return profileName;
    }
  };

  const description = () => {
    return profile?.verified === 'TRUE'
      ? `Explore Official Limited Edition Digital Collectible NFTs created by ${profileName} - Digital Originals and Limited Editions - Only on Fans.Inc`
      : `Explore Official Limited Edition Digital Collectible NFTs collected by ${profileName}`;
  };

  if (typeof profile === 'undefined' && !profileLoading) {
    return <Default />;
  }

  return profileLoading && !profile ? (
    <SuspenseLoading />
  ) : profile?.profileTemplate === 'tonymott' ? (
    <ProfileTonyMott profile={profile} title={title} description={description} />
  ) : (
    <ProfileMain
      profile={profile}
      profileLoafing={profileLoading}
      title={title}
      description={description}
      refetchData={refetchData}
    />
  );
}

export default ProfileTemplate;
