import FansincMintPage from './fansinc';
import PatronsMintPage from './patrons';

const MintPage = () => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsMintPage />;
  } else {
    return <FansincMintPage />;
  }
};

export default MintPage;
