import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';

import { Alert, Button, FormField, Box, Input, Link, Text } from '@components';

import { useUser } from '@libs/hooks/user';

function AuthForgotPassword() {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur'
  });

  const { error, forgotPass, loading: formLoading } = useUser();

  const onSubmit = (data) => forgotPass(data.email);

  return (
    <div className="auth">
      <Box className="auth__forgot__title">{t('auth.forgotPassword.heading')}</Box>
      <Box className="auth__forgot__description">{t('auth.forgotPassword.instruction')}</Box>
      <div className="auth__form">
        {error && (
          <Alert danger className="mb-4">
            {error}
          </Alert>
        )}

        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <FormField>
            <Input
              fullWidth
              label={t('auth.fields.email.label')}
              as="email"
              size="md"
              placeholder={t('auth.fields.email.label')}
              errorMessage={errors.email?.message}
              {...register('email', {
                required: { value: true, message: t('auth.fields.email.required') },
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: t('auth.fields.email.invalid')
                }
              })}
            />
          </FormField>
          <div className="mb-4">
            <Button primary fullWidth as="submit" disabled={formLoading} loading={formLoading}>
              {t('auth.forgotPassword.submit')}
            </Button>
          </div>
        </form>
      </div>
      <div className="auth__register">
        <Text center className="auth__forgot__backLogin">
          <Trans i18nKey="p.auth.backToLogin">
            <Link className="auth__forgot__backLogin__link" to="/login"></Link>
          </Trans>
        </Text>
      </div>

      <Helmet>
        <title>{t('auth.forgotPassword.pageTitle')}</title>
        <meta property="og:image" content="https://cdn.fans.inc/public/webapp/og-image.png" />
      </Helmet>
    </div>
  );
}

export default AuthForgotPassword;
