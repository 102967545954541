import React, { useState, useCallback } from 'react';
import Slider from 'react-input-slider';
import Cropper from 'react-easy-crop';
import { useTranslation } from 'react-i18next';

import { Button } from '@components';

const ImageCropper = ({ aspect, objectFit, image, onComplete, onCancel, loading }) => {
  const { t } = useTranslation();

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState();

  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  }, []);

  return (
    <div className="cropper">
      <div className="cropper__inner">
        <div className="cropper__inner__image">
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            objectFit={objectFit}
            aspect={aspect}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
        <div className="cropper__inner__control">
          <Slider
            axis="x"
            x={zoom}
            xmin={1}
            xstep={0.1}
            xmax={3}
            onChange={({ x }) => setZoom(x)}
          />
          <div className="cropper__inner__control__button text-right">
            <Button
              disabled={loading}
              outline
              className="modal__action__cancel"
              onClick={() => onCancel()}
            >
              {t('components.cropper.cancel')}
            </Button>
            <Button onClick={() => onComplete(croppedArea)}>
              {loading ? t('components.cropper.loading') : t('components.cropper.save')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

ImageCropper.defaultProps = {
  onCancel: () => {},
  aspect: 1 / 1,
  objectFit: 'contain'
};

export default ImageCropper;
