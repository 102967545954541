import { forwardRef } from 'react';
import cx from 'classnames';
import { Box } from '@components';

const RadioButton = forwardRef(function Radio(
  {
    children,
    disabled,
    name,
    isChecked,
    onChange,
    value,
    className,
    labelClassName,
    errorMessage,
    helpMessage,
    leftMode,
    ...props
  },
  ref
) {
  return (
    <>
      <Box className={cx('input-radio', className)}>
        <label htmlFor={value} className={cx('cursor-pointer', labelClassName)}>
          <input
            type="radio"
            name={name}
            id={value}
            checked={isChecked}
            onChange={onChange}
            value={value}
            disabled={disabled}
          />
          {leftMode && <span className="checkmark checkmark--left"></span>}
          {children}
          {!leftMode && <span className="checkmark"></span>}
        </label>
      </Box>
      <div>
        {errorMessage && <span className="field-help field-help--error">{errorMessage}</span>}
        {helpMessage && <span className="field-help">{helpMessage}</span>}
      </div>
    </>
  );
});

export default RadioButton;
