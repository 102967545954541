import cx from 'classnames';
import { useTranslation, Trans } from 'react-i18next';
import { useCallback, useState, useRef, useEffect } from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';
import {
  Box,
  Button,
  CollectibleCardV2,
  Heading,
  Link,
  Text,
  NotFound,
  ShareIcon,
  SkeletonLoading,
  Error
} from '@components';
import { useAuth } from '@libs/contexts/auth';
import { useOutsider } from '@libs/utils/outsider';
import { shareChannelKeys } from '@libs/utils/share';
import { ReactComponent as Search } from '@assets/svg/search-artists.svg';
import { ReactComponent as ArrowDown } from '@assets/svg/arrow-down.svg';
import useToast from '@libs/utils/toast';
import { urlParser } from '@pages/profile/utils';
import { BiPlus, BiMinus } from 'react-icons/bi';
import { useConfirmationQuery, useRefreshSession } from '@pages/confirmation/fansinc/hooks';
import { usePollExchange } from '@libs/hooks/exchange';
import { useCollectionById } from '@libs/hooks/collection';
import { faqsPatrons } from '@pages/single-page/utils';

import { ReactComponent as HandshakeIcon } from '@assets/svg/handshake.svg';
import { ReactComponent as AccordionUp } from '@assets/svg/patrons/accordionUp.svg';
import { ReactComponent as AccordionDown } from '@assets/svg/patrons/accordionDown.svg';
import { ReactComponent as PrintIcon } from '@assets/svg/printer.svg';
import { ReactComponent as MintIcon } from '@assets/svg/patrons/mint.svg';
import { ReactComponent as AltShareIcon } from '@assets/svg/patrons/share.svg';
import { ReactComponent as TradeIcon } from '@assets/svg/patrons/trade.svg';

import { BiShareAlt } from 'react-icons/bi';

import Marquee from '@pages/confirmation/patrons/marquee';

const ErrorConfirmationContent = ({ title, description }) => (
  <Box className="confirmation justify-center items-center flex pt-0" align="center">
    <Error title={title} description={description} />
  </Box>
);

const LoadingConfirmationContent = () => {
  return (
    <>
      <Box className="w-full flex flex-col md:flex-row">
        <Box className="text-primary w-full md:w-1/2">
          <Box className="w-full p-10 md:p-20 flex justify-center items-center">
            <Box className="w-full md:w-3/5">
              <Box className="flex justify-center items-center mb-7 w-full">
                <SkeletonLoading className="my-1 h-9 w-10/12" />
              </Box>
              <Box className="mb-5">
                {[...Array(3)].map((_, i) => (
                  <Box className="mb-2" key={`skel-${i}`}>
                    <SkeletonLoading className="my-1 h-3 w-full" />
                  </Box>
                ))}
                <Box className="mb-2">
                  <SkeletonLoading className="my-1 h-3 w-10/12" />
                </Box>
              </Box>
              <Box className="mb-2">
                <SkeletonLoading className="my-1 h-80 w-full" />
              </Box>
              <Box className="mb-2">
                <SkeletonLoading className="my-1 h-14 w-full" />
              </Box>
              <Box className="mb-2 flex justify-center items-center">
                <SkeletonLoading className="my-1 h-5 w-1/2" />
              </Box>
              <Box className="mb-2 flex flex-row gap-x-3 justify-center items-center">
                <SkeletonLoading className="my-1 w-12 h-12" />
                <SkeletonLoading className="my-1 w-12 h-12" />
                <SkeletonLoading className="my-1 w-12 h-12" />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="w-full md:w-1/2 bg-gray-300 confirmation">
          <Box className="flex items-center justify-center h-full md:py-0 py-10">
            <Box className="w-60 md:w-96">
              <SkeletonLoading className="h-60 md:h-96 w-full" />
              <Box className="mt-1">
                <SkeletonLoading className="my-1 h-14 w-full" />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const PageNotFound = () => (
  <Box className="confirmation justify-center items-center flex pt-0" align="center">
    <NotFound
      title="Page not found"
      description="We’re sorry, the page you're looking for can't be found."
    />
  </Box>
);

const Accordion = ({ exchange, collection }) => {
  const { t } = useTranslation();
  const [faqIndex, setFaqIndex] = useState(0);
  return (
    <>
      <Box className="collectible-accordion">
        <Box className="collectible-accordion-label">{t('p.congratulation.accordionLabel')}</Box>
        <Box
          className={cx(
            'collectible-accordion-item',
            faqIndex === 0 ? 'collectible-accordion-item-active' : ''
          )}
        >
          <Box
            className="collectible-accordion-heading"
            onClick={() => setFaqIndex(faqIndex === 0 ? null : 0)}
          >
            <Box className="flex items-center text-base md:text-xl mr-auto">
              <Search className="mr-2" />
              {t('p.congratulation.view.label')}
            </Box>
            <span className="collectible-accordion-icon">
              {faqIndex === 0 ? <AccordionUp /> : <AccordionDown />}
            </span>
          </Box>

          <div className="collectible-accordion-content">
            <Box className="collectible-accordion-content-title">
              {t('p.congratulation.view.profile')}
            </Box>
            <Link
              to={`/${exchange?.currentOwner?.username}`}
              style={{ textDecoration: 'none' }}
              target="_blank"
              className="collectible-accordion-content-link"
            >
              <Trans i18nKey="p.congratulation.view.profileLink">
                {{ username: exchange?.currentOwner?.username }}
              </Trans>
            </Link>
            <Box className="collectible-accordion-content-title mt-2">
              {t('p.congratulation.view.artist')}
            </Box>
            <Link
              to={`/${exchange?.collectible?.creator?.username}`}
              style={{ textDecoration: 'none' }}
              target="_blank"
              className="collectible-accordion-content-link"
            >
              <Trans i18nKey="p.congratulation.view.artistLink">
                {{ username: exchange?.collectible?.creator?.username }}
              </Trans>
            </Link>
            {collection?.slug && (
              <>
                <Box className="collectible-accordion-content-title mt-2">
                  {t('p.congratulation.view.gallery')}
                </Box>
                <Link
                  to={`/collection/${collection?.slug}`}
                  style={{ textDecoration: 'none' }}
                  target="_blank"
                  className="collectible-accordion-content-link"
                >
                  <Trans i18nKey="p.congratulation.view.galleryLink">
                    {{ galleryName: collection?.slug }}
                  </Trans>
                </Link>
              </>
            )}
          </div>
        </Box>
        <Box
          className={cx(
            'collectible-accordion-item',
            faqIndex === 1 ? 'collectible-accordion-item-active' : ''
          )}
        >
          <Box
            className="collectible-accordion-heading"
            onClick={() => setFaqIndex(faqIndex === 1 ? null : 1)}
          >
            <Box className="flex items-center text-base md:text-xl mr-auto">
              <TradeIcon className="mr-2" />
              {t('p.congratulation.tradeIt.label')}
            </Box>
            <span className="collectible-accordion-icon">
              {faqIndex === 1 ? <AccordionUp /> : <AccordionDown />}
            </span>
          </Box>

          <div className="collectible-accordion-content">
            <Box className="collectible-accordion-content-description">
              <Trans i18nKey="p.congratulation.tradeIt.description">
                <Link
                  to={`/${exchange?.collectible?.creator?.username}/${exchange?.collectible?.slug}/?show=scmarketplace`}
                  className="text-primary font-bold"
                  target="_blank"
                >
                  {{ marketplace: 'HERE' }}
                </Link>
                <Link
                  to={`/sell/${exchange?.collectible?.creator?.username}/${exchange?.collectible?.slug}/${exchange?.serialIndex}`}
                  target="_blank"
                  className="text-primary font-bold"
                >
                  {{ manageListing: 'HERE' }}
                </Link>
              </Trans>
            </Box>
          </div>
        </Box>
        <Box
          className={cx(
            'collectible-accordion-item',
            faqIndex === 2 ? 'collectible-accordion-item-active' : ''
          )}
        >
          <Box
            className="collectible-accordion-heading"
            onClick={() => setFaqIndex(faqIndex === 2 ? null : 2)}
          >
            <Box className="flex items-center text-base md:text-xl mr-auto">
              <MintIcon className="mr-2" />
              {t('p.congratulation.mintIt.label')}
            </Box>
            <span className="collectible-accordion-icon">
              {faqIndex === 2 ? <AccordionUp /> : <AccordionDown />}
            </span>
          </Box>

          <div className="collectible-accordion-content">
            <Box className="collectible-accordion-content-description">
              <Trans i18nKey="p.congratulation.mintIt.description">
                <Link
                  to={`/mint/${exchange?.collectible?.creator?.username}/${exchange?.collectible?.slug}/${exchange?.serialIndex}`}
                  className="text-primary font-bold"
                  target="_blank"
                >
                  {{ mint: 'HERE' }}
                </Link>
              </Trans>
            </Box>
          </div>
        </Box>
      </Box>
    </>
  );
};

const canvasStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0
};

const tierMapper = {
  NUMBERED_EDITION: 'Limited Edition',
  DIGITAL_ORIGINAL: 'Digital Original'
};

function Confirmation() {
  const { t } = useTranslation();
  const toast = useToast();
  const { status, referenceID } = useConfirmationQuery();
  const { exchange, timeoutExceeded, loading, getExchangeData } = usePollExchange(
    referenceID,
    2000
  );
  const { data: collection, loading: collectionLoading } = useCollectionById(
    exchange?.collectible?.collectionID
  );

  const { user } = useAuth();
  const [isOpen, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const { ref } = useOutsider(handleClose);
  useRefreshSession();

  const refAnimationInstance = useRef(null);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio)
      });
  }, []);

  const handleOpen = useCallback(
    (e) => {
      e.preventDefault();
      setOpen(!isOpen);
    },
    [isOpen]
  );

  const exchangeURL = window.location.hostname + urlParser(exchange, 'release', true);
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${exchangeURL}`;

  const title = t('congratulation.label') + ' ' + exchange?.currentOwner?.firstName;
  const description = (
    <Trans i18nKey="p.congratulation.fixedPriceDescriptionNew">
      {exchange?.collectible?.creator.firstName + ' ' + exchange?.collectible?.creator.lastName}
      {exchange?.collectible?.title}
      {`${tierMapper[exchange?.tierType]} #${exchange?.serialIndex}`}
    </Trans>
  );

  const hasError = status === 'fail' || timeoutExceeded;
  const exchangeNotFound = !referenceID;

  useEffect(() => {
    getExchangeData(referenceID);
  }, [referenceID]);

  useEffect(() => {
    if (exchange) {
      makeShot(0.25, {
        spread: 26,
        startVelocity: 55
      });
      makeShot(0.25, {
        spread: 26,
        startVelocity: 55
      });

      makeShot(0.2, {
        spread: 60
      });

      makeShot(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8
      });

      makeShot(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2
      });

      makeShot(0.1, {
        spread: 120,
        startVelocity: 45
      });
    }
  }, [exchange, makeShot]);

  if (hasError) {
    return (
      <ErrorConfirmationContent
        title={t('p.congratulation.error')}
        description={t('p.congratulation.errorDescription')}
      />
    );
  }

  /*
   * Check only if exchange is not found
   * owner check was removed due to guest-checkout behaviour
   */
  if (exchangeNotFound) {
    return <PageNotFound />;
  }

  const ConfirmationContainerInner = () => (
    <div>
      <Box className="w-full flex flex-col md:flex-row ">
        <Box className="text-primary w-full md:w-1/2">
          <Box className="w-full flex justify-center items-center">
            <Box style={{ marginBottom: '60px' }} className="max-w-lg mt-5 md:mt-24 mx-5">
              <Box className="block md:hidden">
                {exchange && (
                  <CollectibleCardV2
                    collectible={exchange}
                    type="release"
                    showButton={false}
                    showPrice={false}
                    hasCollectibleTimer={false}
                    showOwnerInUrl={true}
                    showReleaseAsCollectible={false}
                    collectibleUrl={`/${exchange?.collectible?.creator?.username}/${exchange?.slug}`}
                    creatorUrl={`/${exchange?.collectible?.creator?.username}`}
                  ></CollectibleCardV2>
                )}
              </Box>
              <Heading
                as="h3"
                className="text-primary text-center text-2xl mdtext-4xl capitalize mb-4 md:mb-7"
              >
                {title}
              </Heading>
              <Text as="p" className="text-lg text-secondary leading-7">
                {description}
              </Text>
              <Accordion exchange={exchange} collection={collection} />
              <Box className="flex flex-col justify-center text-center items-center mt-5">
                <span className="congratulation-share-media">{t('p.congratulation.shareIt')}</span>
                <Box className="congratulation-share-icon grid grid-cols-3 justify-center gap-x-12 items-center mt-4">
                  <Box>
                    <ShareIcon
                      onFrame
                      channel={shareChannelKeys.facebook}
                      url={exchangeURL}
                      className="mx-2"
                    />
                  </Box>
                  <Box>
                    <ShareIcon onFrame channel={shareChannelKeys.twitter} url={exchangeURL} />
                  </Box>

                  <Box onClick={() => toast('Link copied!', 'success')}>
                    <ShareIcon channel={shareChannelKeys.link} url={exchangeURL} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          style={{ minHeight: '100vh' }}
          className="text-primary w-full md:w-1/2 bg-gray-200 relative overflow-hidden congratulation hidden md:block"
        >
          <div className="absolute w-full -top-12">
            <Marquee title={title} />
          </div>
          <Box className="flex justify-center items-center h-full">
            <Box className="w-full px-10 md:px-0 py-10 md:py-0 md:w-1/2 z-50">
              <Box className="relative">
                {exchange && (
                  <CollectibleCardV2
                    collectible={exchange}
                    type="release"
                    showButton={false}
                    showPrice={false}
                    hasCollectibleTimer={false}
                    showOwnerInUrl={true}
                    showReleaseAsCollectible={false}
                    collectibleUrl={`/${exchange?.collectible?.creator?.username}/${exchange?.slug}`}
                    creatorUrl={`/${exchange?.collectible?.creator?.username}`}
                  ></CollectibleCardV2>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );

  return (
    <Box className="w-full">
      {!loading && !collectionLoading ? (
        <>
          <ConfirmationContainerInner />
        </>
      ) : (
        <LoadingConfirmationContent title={t('p.congratulation.loading')} />
      )}
      <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
    </Box>
  );
}

export default Confirmation;
