import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  ProfileHeader,
  Tabs,
  TabItem,
  FollowButton,
  CollectibleCard,
  CollectibleSkeleton
} from '@components';
import { useTranslation, Trans } from 'react-i18next';
import Collected from './components/collected';
import Sold from './components/sold';
import Loved from './components/loved';
import CollectibleSlider from './components/collectible-slider';
import Collectible from './components/collectible';
import { useSearchCollectiblesByOwner } from '@libs/hooks/collectible';

function Profile({ profile, profileLoading }) {
  const { t } = useTranslation();
  const windowUrl = window.location.search;
  const query = new URLSearchParams(windowUrl);
  const defaultShow = query.get('default') || 'collected';
  const { data: latestCollectibles, loading } = useSearchCollectiblesByOwner(
    profile?.username,
    true
  );
  const title = () => {
    if (profile) {
      if (profile?.verified === 'TRUE') {
        return `${
          profile?.displayName || profile?.firstName + ' ' + profile?.lastName
        }'s Official Limited Edition Collectible on Patrons.Art`;
      } else {
        return profile?.firstName + ' ' + profile?.lastName;
      }
    }
  };
  const description = () => {
    return profile?.verified === 'TRUE'
      ? `Explore Official Limited Edition Collectible created by ${
          profile?.displayName || profile?.firstName + ' ' + profile?.lastName
        } - Limited Editions - Only on Patrons.Art`
      : `Explore Official Limited Edition Collectible collected by ${
          profile?.firstName + ' ' + profile?.lastName
        }`;
  };
  return (
    <>
      {profile ? (
        <>
          {profile?.verified === 'TRUE' ? (
            <Box className="profile-artist">
              <Container className="profile-artist__container">
                <Box className="profile-artist__top">
                  <Box className="profile-artist__top__left">
                    {loading ? (
                      <CollectibleSkeleton />
                    ) : (
                      <>
                        {latestCollectibles?.length > 0 && (
                          <CollectibleCard
                            key={latestCollectibles[0]?.id}
                            collectible={latestCollectibles[0]}
                            showPrice={false}
                          ></CollectibleCard>
                        )}
                      </>
                    )}
                  </Box>
                  <Box className="profile-artist__top__right">
                    <Box>
                      <Box className="profile-artist__top__right__name">
                        <Trans i18nKey="p.pages.profile.artist.title">
                          {profile?.displayName || profile?.firstName + ' ' + profile?.lastName}
                        </Trans>
                      </Box>
                      <Box className="profile-artist__top__right__description">
                        <Trans i18nKey="p.pages.profile.artist.description">
                          {profile?.displayName || profile?.firstName + ' ' + profile?.lastName}
                        </Trans>
                      </Box>
                      <Box className="mt-7">
                        <FollowButton user={profile} />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {/* <CollectibleSlider profile={profile} /> */}
                <Box className="profile-artist__collectible">
                  <Collectible profile={profile} />
                </Box>
              </Container>
              <Box className="profile-artist__about">
                <Container>
                  <Box className="profile-artist__about__wrapper">
                    <Box className="profile-artist__about__wrapper__left">
                      <Box className="profile-artist__about__wrapper__left__title">
                        {t('p.pages.profile.artist.about')}
                      </Box>
                      <Box className="profile-artist__about__wrapper__left__creator">
                        {profile?.displayName || profile?.firstName + ' ' + profile?.lastName}
                      </Box>
                    </Box>
                    <Box className="profile-artist__about__wrapper__right">
                      <Box>{profile?.bio}</Box>
                    </Box>
                  </Box>
                </Container>
              </Box>
            </Box>
          ) : (
            <Box className="profile-fans">
              <Container>
                <ProfileHeader user={profile} profileLoading={profileLoading} />
                <Box className="profile-fans__top">
                  <Box className="profile-fans__top__wrapper">
                    <Box className="profile-fans__top__wrapper__name">
                      {profile?.displayName || profile?.firstName + ' ' + profile?.lastName}
                    </Box>
                    <Box className="profile-fans__top__wrapper__bio">{profile?.bio}</Box>
                  </Box>
                </Box>
                <Box className="profile-fans__collectible">
                  <Tabs directTo={defaultShow} full>
                    <TabItem label="collected">
                      <Collected userID={profile?.id} />
                    </TabItem>
                    <TabItem label="sold">
                      <Sold userID={profile?.id} />
                    </TabItem>
                    <TabItem label="favourite">
                      <Loved userID={profile?.id} />
                    </TabItem>
                  </Tabs>
                </Box>
              </Container>
            </Box>
          )}
        </>
      ) : (
        <></>
      )}
      <Helmet>
        <title>{title()}</title>
        <meta name="description" content={description()} />
      </Helmet>
    </>
  );
}

export default Profile;
