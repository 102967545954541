import { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash.debounce';
import gql from '@libs/utils/gql';
import useToast from '@libs/utils/toast';
import { searchCollectibles } from '@libs/custom-queries/collectible';
import { useCollectibleParams } from '@libs/hooks/collectible/utils';
import { facetedMarketplace } from '@graphql/mutations';

export const useMarketplace = (limit) => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [isFilter, setFilter] = useState(false);
  const [total, setTotal] = useState(0);
  const [sidebarFilter, setSidebarFilter] = useState({
    category: [],
    genre: [],
    artist: [],
    era: []
  });
  const [data, setData] = useState([]);
  const { query: params, onFilter, eraParser, onPaginate } = useCollectibleParams(limit);
  const [pageToken, setPageToken] = useState();
  const [searchFilter, setSearchFilter] = useState({
    title: null,
    category: [],
    genre: [],
    artist: [],
    era: [],
    price: []
  });

  const getData = useCallback(
    async (params) => {
      setLoading(true);
      try {
        const { data: res } = await gql(searchCollectibles, params);
        if (res?.searchCollectibles?.items?.length < 6) {
          setData(data.concat(res.searchCollectibles?.items));
          setTotal(res.searchCollectibles?.total);
          setPageToken(null);
        } else {
          setData(data.concat(res.searchCollectibles?.items));
          setTotal(res.searchCollectibles?.total);
          setPageToken(res.searchCollectibles?.nextToken);
        }
      } catch (error) {
        const errorMessage = error?.errors
          ? error?.errors[0]?.message
          : error.message ?? 'unknown error';
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [params] // eslint-disable-line
  );

  const getFilterData = useCallback(
    async (searchFilter) => {
      setFilter(false);
      setSidebarFilter({
        category: [],
        genre: [],
        artist: [],
        era: []
      });
      try {
        const { data: res } = await gql(facetedMarketplace, {
          input: searchFilter
        });
        const parsedData = res ? JSON.parse(res?.facetedMarketplace) : null;

        if (parsedData.body.filter.isFilter) {
          setFilter(true);
          setSidebarFilter({
            category: parsedData.body.filter.category,
            genre: parsedData.body.filter.genre,
            artist: parsedData.body.filter.artist,
            era: parsedData.body.filter.era
          });
        } else {
          setFilter(false);
          setSidebarFilter({
            category: [],
            genre: [],
            artist: [],
            era: []
          });
        }
      } catch (error) {
        setFilter(false);
        setSidebarFilter({
          category: [],
          genre: [],
          artist: [],
          era: []
        });
      }
    },
    [searchFilter] // eslint-disable-line
  );

  useEffect(() => {
    getData(params);
  }, [params]); // eslint-disable-line

  useEffect(() => {
    getFilterData(searchFilter);
  }, [searchFilter]); // eslint-disable-line

  const onChangeFilter = (title, categories, genres, eras, artists, sort, price_from, price_to) => {
    setData([]);
    const parsedEra = [];
    for (let i = 0; i < eras.length; i++) {
      parsedEra.push(eraParser(eras[i]));
    }
    setSearchFilter({
      title: title,
      category: categories,
      genre: genres,
      artist: artists,
      era: eras,
      price: [price_from, price_to]
    });
    onFilter(title, categories, genres, parsedEra, artists, sort, price_from, price_to);
  };

  return {
    data,
    loading,
    pageToken,
    total,
    onChangeFilter: debounce(
      (title, categories, genres, eras, artists, sort, price_from, price_to) =>
        onChangeFilter(title, categories, genres, eras, artists, sort, price_from, price_to),
      500
    ),
    onPaginate,
    isFilter,
    sidebarFilter
  };
};
