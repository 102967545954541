import React from 'react';
import { Page, Text, View, Document, Image, Font } from '@react-pdf/renderer';
import { format as formatDate } from 'date-fns';

import PatronsLogo from '@assets/img/patrons-logo.png';
import Helvetica from '@assets/pdf/fonts/Helvetica.ttf';
import HelveticaBold from '@assets/pdf/fonts/Helvetica-Bold.ttf';
import HelveticaItalic from '@assets/pdf/fonts/Helvetica-Italic.ttf';
import listCountries from '@libs/json/prodigiCountries.json';
import config from '@/config';
import { styles, captilizeAllWords, formatPrice } from './utils';
import { paymentMapper } from '@libs/utils/mapper';

Font.register({
  family: 'Helvetica',
  fonts: [
    {
      fontWeight: 400,
      src: Helvetica,
      fontStyle: 'Regular'
    },
    {
      fontWeight: 400,
      src: HelveticaItalic,
      fontStyle: 'Italic'
    },
    {
      fontWeight: 700,
      src: HelveticaBold
    }
  ]
});

Font.register({
  family: 'HelveticaBold',
  fonts: [
    {
      fontWeight: 700,
      src: HelveticaBold
    }
  ]
});

const InvoiceWalletDocument = ({ history, account, exchange }) => {
  const receiver = history?.receiver;
  const sender = history?.sender;
  const { fansWallet } = config;

  const senderName = sender ? sender?.firstName + ' ' + sender?.lastName : null;
  const senderEmail = sender ? sender?.email : null;
  const senderAddress = sender?.address?.line1 ? sender?.address?.line1 : null;
  const countryS = listCountries.filter((country) => country.isoCode === sender?.address?.country);
  const senderCountry = countryS[0]?.name?.toLowerCase();
  const senderCityStateCode =
    sender?.address?.city || sender?.address?.stateName || sender?.address?.postCode
      ? (sender?.address?.city ? sender?.address?.city + ', ' : null) +
        (sender?.address?.stateName ? sender?.address?.stateName + ', ' : null) +
        (sender?.address?.postCode ? sender?.address?.postCode : null)
      : ' ';

  const receiverName = receiver ? receiver?.firstName + ' ' + receiver?.lastName : null;
  const receiverAddress = receiver?.address?.line1 ? receiver?.address?.line1 : null;
  const receiverEmail = receiver?.email ? receiver?.email : null;
  const countryR = listCountries.filter(
    (country) => country.isoCode === receiver?.address?.country
  );
  const countryParser = (id) => {
    const listC = listCountries.filter((country) => country.isoCode === id);
    return listC[0]?.name?.toLowerCase();
  };
  const receiverCountry = countryR[0]?.name?.toLowerCase();
  const receiverCityStateCode =
    receiver?.address?.city || receiver?.address?.stateName || receiver?.address?.postCode
      ? (receiver?.address?.city ? receiver?.address?.city + ', ' : null) +
        (receiver?.address?.stateName ? receiver?.address?.stateName + ', ' : null) +
        (receiver?.address?.postCode ? receiver?.address?.postCode : null)
      : ' ';
  const cityStateCode =
    account?.city?.length > 0 || account?.state?.length > 0 || account?.postcode?.length > 0
      ? (account?.city ? account?.city + ', ' : '') +
        (account?.state ? account?.state + ', ' : '') +
        (account?.postcode ? account?.postcode : '')
      : '';

  const amount = history?.debit === '0' ? history?.credit : history?.debit;

  return (
    history && (
      <Document>
        <Page style={styles.pageWrapper} wrap>
          <View style={styles.pageContent}>
            <View style={styles.headerWrapper}>
              <View>
                <Text style={styles.standardText}>From</Text>

                {history?.receiverID === fansWallet ? (
                  <>
                    <Text style={styles.standardTitle}>Patrons.Art</Text>
                    <Text style={styles.standardText}>85 William St</Text>
                    <Text style={styles.standardText}>Darlinghurst NSW 2010</Text>
                    <Text style={[styles.standardText, styles.capitalize]}>
                      {captilizeAllWords('australia')}
                    </Text>
                  </>
                ) : (
                  <>
                    {(account && ['ROYALTY_FEE', 'ROYALTY'].includes(history?.transactionType)) ||
                    (account && exchange && exchange?.creatorID === exchange?.currentOwnerID) ? (
                      <>
                        <Text style={styles.standardTitleOrg}>{account?.legalName}</Text>
                        <Text style={styles.standardText}>{receiverName}</Text>
                        <View style={[styles.pb5]}></View>
                        {account?.address?.length > 0 && (
                          <Text style={styles.standardText}>{account?.address}</Text>
                        )}
                        {cityStateCode?.length > 0 && (
                          <Text style={styles.standardText}>{cityStateCode}</Text>
                        )}
                        {account?.country && (
                          <Text style={[styles.standardText, styles.capitalize]}>
                            {captilizeAllWords(countryParser(account?.country))}
                          </Text>
                        )}
                      </>
                    ) : (
                      <>
                        <Text style={styles.standardTitle}>{receiverName}</Text>
                        {receiverEmail && <Text style={styles.standardText}>{receiverEmail}</Text>}
                        {receiverAddress && (
                          <Text style={styles.standardText}>{receiverAddress}</Text>
                        )}
                        {receiverCityStateCode && (
                          <Text style={styles.standardText}>{receiverCityStateCode}</Text>
                        )}
                        {receiverCountry && (
                          <Text style={[styles.standardText, styles.capitalize]}>
                            {captilizeAllWords(receiverCountry)}
                          </Text>
                        )}
                      </>
                    )}
                  </>
                )}
                <View style={styles.spacer}></View>
                <Text style={styles.standardText}>Bill to</Text>
                {history?.senderID === fansWallet ? (
                  <>
                    <Text style={styles.standardTitle}>Patrons.Art</Text>
                    <Text style={styles.standardText}>85 William St</Text>
                    <Text style={styles.standardText}>Darlinghurst NSW 2010</Text>
                    <Text style={[styles.standardText, styles.capitalize]}>
                      {captilizeAllWords('australia')}
                    </Text>
                  </>
                ) : (
                  <>
                    <Text style={styles.standardTitle}>{senderName}</Text>
                    {senderEmail && <Text style={styles.standardText}>{senderEmail}</Text>}
                    {senderAddress && <Text style={styles.standardText}>{senderAddress}</Text>}
                    {senderCityStateCode && (
                      <Text style={styles.standardText}>{senderCityStateCode}</Text>
                    )}
                    {senderCountry && (
                      <Text style={[styles.standardText, styles.capitalize]}>
                        {captilizeAllWords(senderCountry)}
                      </Text>
                    )}
                  </>
                )}
              </View>
              <View style={styles.colRight}>
                <Image src={PatronsLogo} style={styles.logoImage} />
                <Text style={[styles.standardText, styles.standardTextMargin, styles.textRight]}>
                  Invoice #
                </Text>
                <Text style={[styles.standardTitle, styles.textRight]}>{history.invoiceID}</Text>
                <Text style={[styles.standardText, styles.standardTextMargin, styles.textRight]}>
                  Date
                </Text>
                <Text style={[styles.standardSubtitle, styles.textRight]}>
                  {formatDate(new Date(history?.createdAt), 'd MMMM yyyy')}
                </Text>

                <Text
                  style={[
                    styles.standardText,
                    styles.textItalic,
                    styles.textSecondary,
                    styles.my8,
                    styles.textRight
                  ]}
                >
                  Due on receipt
                </Text>
                <Text style={[styles.standardText, styles.standardTextMargin, styles.textRight]}>
                  Payment method
                </Text>
                <Text style={[styles.standardSubtitle, styles.textRight]}>
                  {paymentMapper[history?.paymentProvider] ||
                    paymentMapper[history?.withdrawal?.provider] ||
                    'Patrons.Art account balance'}
                </Text>
              </View>
            </View>
            <View style={styles.bodyWrapper}>
              <View style={[styles.table, styles.tableHeadWrapper]}>
                <Text style={[styles.tableHead, styles.tableCol50, styles.standardText]}>
                  Description
                </Text>
                <Text
                  style={[
                    styles.tableHead,
                    styles.tableCol50,
                    styles.textAlignRight,
                    styles.standardText
                  ]}
                >
                  Amount
                </Text>
              </View>
              <View style={[styles.table, styles.tableHeadWrapper]}>
                <Text style={[styles.tableBody, styles.tableCol50, styles.standardText]}>
                  <Text style={[styles.tableCol100, styles.displayBlock]}>
                    {history?.description?.charAt(0) === 'S'
                      ? history?.description
                          ?.replace('Sales of ', '')
                          .replace('Numbered Edition', 'Limited Edition')
                      : history?.description?.replace('Numbered Edition', 'Limited Edition')}
                  </Text>
                </Text>
                <Text
                  style={[
                    styles.tableBody,
                    styles.tableCol50,
                    styles.textAlignRight,
                    styles.standardText
                  ]}
                >
                  $ {formatPrice(amount)}
                </Text>
              </View>

              <>
                <View style={[styles.pb5]}></View>
                <View style={[styles.table]}>
                  <Text
                    style={[
                      styles.tableBodySummary,
                      styles.tableCol80,
                      styles.textAlignRight,
                      styles.standardText
                    ]}
                  >
                    Total
                  </Text>
                  <Text
                    style={[
                      styles.tableBodySummary,
                      styles.tableCol20,
                      styles.textAlignRight,
                      styles.standardText
                    ]}
                  >
                    $ {formatPrice(amount)}
                  </Text>
                </View>
              </>
            </View>
          </View>
        </Page>
      </Document>
    )
  );
};

export default InvoiceWalletDocument;
