import { React } from 'react';
import FansincNotificationMenuItem from './fansinc';
import PatronsNotificationMenuItem from './patrons';

function NotificationMenuItem({ notification, className, ...props }) {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return (
      <PatronsNotificationMenuItem notification={notification} {...props} className={className} />
    );
  } else {
    return (
      <FansincNotificationMenuItem notification={notification} {...props} className={className} />
    );
  }
}
export default NotificationMenuItem;
