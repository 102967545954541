export const getUserName = (name) => {
  if (name?.length > 3) {
    var strFirstTwo = name.substring(0, 2);

    if (strFirstTwo === '0x' && name?.length > 35) {
      return formatWalletAddress(name);
    } else {
      return name;
    }
  } else {
    return name;
  }
};

export const formatWalletAddress = (walletAddress) =>
  String(walletAddress).substring(0, 6) + '...' + String(walletAddress).substring(38);
