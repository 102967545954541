import { Box, Table } from '@components';
import { categoryParser } from '@pages/collectible/patrons/utils';
import React from 'react';
import { CATEGORY_TYPES } from '@libs/utils/collectible';
import { Trans, useTranslation } from 'react-i18next';

export default function PatronsCollectibleDetail({ collectible, maxQuantity }) {
  const { t } = useTranslation();
  return (
    <div>
      <Box className="collectible__tab__detail">
        <Box className="collectible__tab__detail__description">
          <Box className="collectible__tab__detail__description__title">
            {t('p.pages.release.tab.detail.description')}
          </Box>
          <Box className="collectible__tab__detail__description__value">
            {collectible?.description}
          </Box>
        </Box>
        <Box className="collectible__tab__detail__table">
          <Table rows={detailRows(collectible, maxQuantity)} />
        </Box>
      </Box>
    </div>
  );
}

function detailRows(collectible, maxQuantity) {
  const { t } = useTranslation();
  let medium;
  if (
    [CATEGORY_TYPES.ARTWORK, CATEGORY_TYPES.PHOTOGRAPH, CATEGORY_TYPES.SONG_LYRICS].includes(
      collectible?.category
    )
  ) {
    medium = (
      <Trans i18nKey="collectible.expansionInformation.attributes.verifiedOriginal">
        {{ category: categoryParser(collectible?.category) }}
        {{ print: ' + ' + 'Print' }}
      </Trans>
    );
  } else {
    medium = (
      <Trans i18nKey="collectible.expansionInformation.attributes.verifiedOriginal">
        {{ category: categoryParser(collectible?.category) }}
        {{ print: ' + ' + 'Cover Art Print' }}
      </Trans>
    );
  }
  return [
    [
      [
        <Box key={1}>
          <Box className="collectible__tab__detail__table__left">
            {t('p.pages.release.tab.detail.collectibleTitle')}
          </Box>
        </Box>
      ],
      [
        <Box key={2}>
          <Box className="collectible__tab__detail__table__right">{collectible?.title}</Box>
        </Box>
      ]
    ],
    [
      [
        <Box key={3}>
          <Box className="collectible__tab__detail__table__left">
            {t('p.pages.release.tab.detail.artist')}
          </Box>
        </Box>
      ],
      [
        <Box key={4}>
          <Box className="collectible__tab__detail__table__right">
            {collectible?.creator.displayName
              ? collectible?.creator.displayName
              : collectible?.creator.firstName + ' ' + collectible?.creator.lastName}
          </Box>
        </Box>
      ]
    ],
    [
      [
        <Box key={5}>
          <Box className="collectible__tab__detail__table__left">
            {t('p.pages.release.tab.detail.collectibleType')}
          </Box>
        </Box>
      ],
      [
        <Box key={6}>
          <Box className="collectible__tab__detail__table__right">
            {collectible?.type.toLowerCase()}
          </Box>
        </Box>
      ]
    ],
    [
      [
        <Box key={5}>
          <Box className="collectible__tab__detail__table__left">
            {t('p.pages.release.tab.detail.dimensions')}
          </Box>
        </Box>
      ],
      [
        <Box key={6}>
          <Box className="collectible__tab__detail__table__right lowercase">
            <Trans i18nKey="p.pages.release.tab.detail.size">
              {{
                width: (collectible?.width * 2.54).toFixed(1),
                height: (collectible?.height * 2.54).toFixed(1)
              }}
            </Trans>
          </Box>
        </Box>
      ]
    ],
    [
      [
        <Box key={7}>
          <Box className="collectible__tab__detail__table__left">
            {t('p.pages.release.tab.detail.yearOfCreation')}
          </Box>
        </Box>
      ],
      [
        <Box key={8}>
          <Box className="collectible__tab__detail__table__right">
            {collectible?.yearOfCreation}
          </Box>
        </Box>
      ]
    ],
    [
      [
        <Box key={9}>
          <Box className="collectible__tab__detail__table__left">
            {t('p.pages.release.tab.detail.maxQuantity')}
          </Box>
        </Box>
      ],
      [
        <Box key={10}>
          <Box className="collectible__tab__detail__table__right">{maxQuantity}</Box>
        </Box>
      ]
    ],
    [
      [
        <Box key={11}>
          <Box className="collectible__tab__detail__table__left">
            {t('p.pages.release.tab.detail.medium')}
          </Box>
        </Box>
      ],
      [
        <Box key={12}>
          <Box className="collectible__tab__detail__table__right">{medium}</Box>
        </Box>
      ]
    ],
    [
      [
        <Box key={13}>
          <Box className="collectible__tab__detail__table__left">
            {t('p.pages.release.tab.detail.releaseStatus')}
          </Box>
        </Box>
      ],
      [
        <Box key={14}>
          <Box className="collectible__tab__detail__table__right">
            {collectible?.status.toLowerCase()}
          </Box>
        </Box>
      ]
    ]
  ];
}
