export const creatorPublicFragment = `
  fragment CreatorPublicProfile on User {
    id
    firstName
    lastName
    username
    media {
      avatarUrl
      avatarVideoUrl
      fanclubLogoUrl
    }
  }
`;

export const userPublicFragment = `
  fragment UserPublicProfile on User {
    id
    username
    firstName
    lastName
    displayName
    bio
    joinDate
    verified
    fanProfile
    emailVerified
    profileTemplate
    totalSales
    totalSalesVolume
    totalPurchase
    totalCollectionValues
    status
    links {
      facebook
      youtube
      instagram
      twitter
      spotify
      soundcloud
      website
      tiktok
      snapchat
      appleMusic
      clubhouse
    }
    media {
      avatarUrl
      avatarVideoUrl
      coverUrl
      fanclubLogoUrl
    }
  }
`;

export const fansEmailFragment = `
  fragment FansEmailOnly on User {
    email
  }
`;

export const userAddressFragment = `
  fragment UserAddressProfile on User {
    id
    owner
    accountID
    email
    username
    firstName
    lastName
    displayName
    verified
    joinDate
    isHidden
    accountID
    media {
      avatarUrl
    }
    address {
      recipientName
      line1
      country
      stateName
      city
      postCode
      phone
    }
  }
`;
