import React, { useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { Hero, Split, NFTImageCard } from '@components';
import { useExchangeFromUrlParams } from '@pages/mint/patrons/hooks';
import { useIsOwner } from '@pages/print-collectible/hooks';
import MintForm from '@pages/mint/patrons/mint-form';
import MintLandingGallery from '@pages/mint/patrons/mint-landing-gallery';

const MintPage = () => {
  const { t } = useTranslation();
  const [isNftImageCardReady, setIsNftImageCardReady] = useState(false);
  const setIsNftImageCardReadyCallback = useCallback(setIsNftImageCardReady, [
    setIsNftImageCardReady
  ]);

  // will load either specified exchange or first available exchange depending on url structure
  const { exchange, isLoading: isLoadingExchange, exchangeFrom } = useExchangeFromUrlParams();
  const isOwner = useIsOwner({ item: exchange, itemType: exchangeFrom });

  const HeroWrapper = ({ fadeBottom }) => (
    <Hero
      heading={t('mint.hero.heading')}
      text={t('mint.hero.text')}
      showBack
      fadeRight
      fadeBottom={fadeBottom}
    />
  );

  if (isLoadingExchange || !exchange) {
    // TODO: create loading skeleton here
    return (
      <>
        <MintLandingGallery />
        <Helmet>
          <title>{t('mint.hero.heading')}</title>
          <meta property="og:image" content="https://cdn.fans.inc/public/webapp/og-image.png" />
        </Helmet>
      </>
    );
  }

  if (isOwner == null) {
    return <></>;
  }

  if (isOwner) {
    return (
      <>
        <MintForm exchange={exchange} isNftImageCardReady={true} />
        <Helmet>
          <title>{t('mint.hero.heading')}</title>
          <meta property="og:image" content="https://cdn.fans.inc/public/webapp/og-image.png" />
        </Helmet>
      </>
    );
  }

  return (
    <>
      <HeroWrapper fadeBottom />
      <MintLandingGallery
        exchange={exchange}
        setIsNftImageCardReady={setIsNftImageCardReadyCallback}
      />
      <Helmet>
        <title>{t('mint.hero.heading')}</title>
        <meta property="og:image" content="https://cdn.fans.inc/public/webapp/og-image.png" />
      </Helmet>
    </>
  );
};

export default MintPage;
