import { Trans } from 'react-i18next';
import cx from 'classnames';
import ReactDOM from 'react-dom';
import { forwardRef, useState, useEffect } from 'react';
import { IoCloseSharp } from 'react-icons/io5';
import { AiFillWarning } from 'react-icons/ai';
import { Box, Button, Heading, Separator } from '@components';

const portalRoot = document.getElementById('portal-root');

const Modal = forwardRef(function ModalPlain(
  {
    isOpen,
    onClose,
    heading,
    formSubmit,
    formSuccess,
    isForm,
    children,
    error,
    closable,
    action,
    size,
    isCheckoutModal,
    showCloseIcon,
    showOnlyCloseIcon,
    closeIconSize,
    verticalCenter,
    customWrapperStyle,
    backgroundColor,
    unpadded,
    overlayOpacity = 0.6,
    hideScroll,
    onCloseOutsider,
    loading = false
  },
  ref
) {
  const [modalClose, setModalClose] = useState(false);

  const closeModal = () => {
    setModalClose(true);
    onClose();
    setTimeout(() => {
      setModalClose(false);
    }, 100);
  };

  useEffect(() => {
    if (formSuccess === true) {
      closeModal();
    }
    // eslint-disable-next-line
  }, [formSuccess]);

  useEffect(() => {
    const body = document.getElementById('body');

    if (isOpen === true) {
      portalRoot?.classList?.add('portal-root');
      if (hideScroll === true) {
        body?.classList?.add('overflow-hidden');
      }
    } else {
      portalRoot?.classList?.remove('portal-root');
      if (hideScroll === true) {
        body?.classList?.remove('overflow-hidden');
      }
    }

    // eslint-disable-next-line
  }, [isOpen]);

  if (!isOpen && !modalClose) {
    if (modalClose === true) {
      closeModal();
    }
    return null;
  }

  const sizeMapper = {
    md: 'modal-md',
    lg: 'modal-lg',
    full: 'modal-full',
    fullPage: 'modal-full-page',
    fit: 'modal-fit',
    profile: 'modal-profile'
  };

  const defaultStyle = {
    'modal-wrapper': true,
    'modal-wrapper-open': !modalClose,
    'modal-wrapper-close': modalClose,
    'modal-wrapper-checkout': isCheckoutModal
  };

  const FormSubmit = async (e) => {
    e.preventDefault();
    await formSubmit();
  };

  return ReactDOM.createPortal(
    <Box
      ref={ref}
      className={cx(
        defaultStyle,
        customWrapperStyle,
        (size === 'full' || size === 'fullPage') && 'modal-wrapper__full'
      )}
      aria-hidden={!isOpen}
    >
      <Box
        className="modal-wrapper__overlay"
        onClick={() => onCloseOutsider && closeModal()}
        style={{ opacity: overlayOpacity }}
      />
      <Box
        className={cx(
          'modal',
          sizeMapper[size],
          verticalCenter ? 'modal-vertical-center' : '',
          unpadded ? 'unpadded' : '',
          loading ? 'modal-loading' : ''
        )}
        style={{ backgroundColor }}
      >
        {showOnlyCloseIcon && (
          <IoCloseSharp
            className="modal__heading__close-only"
            onClick={() => closeModal()}
            size={closeIconSize}
            style={{ height: closeIconSize, width: closeIconSize }}
          />
        )}
        {heading && (
          <>
            <Box className="modal__heading">
              <Box className="relative">
                {heading && (
                  <Heading as="h3" className="modal__heading__title">
                    {heading}
                  </Heading>
                )}
                {showCloseIcon && (
                  <IoCloseSharp
                    className="modal__heading__close"
                    onClick={() => closeModal()}
                    size={closeIconSize ?? '33'}
                  />
                )}
                {error && <AiFillWarning className="modal__heading__error" size="25" />}
              </Box>
            </Box>
            <Separator />
          </>
        )}
        {isForm ? (
          <form onSubmit={(e) => FormSubmit(e)} noValidate>
            {children && (
              <Box className="modal__content">
                {children}
                {closable !== false || action !== null ? (
                  <>
                    <Box className="modal__action">
                      {(closable === true || typeof closable === 'string') && (
                        <>
                          <Button
                            onClick={() => closeModal()}
                            outline
                            className="modal__action__cancel"
                          >
                            {typeof closable === 'string' ? (
                              closable
                            ) : (
                              <Trans i18nKey="components.modal.cancel" />
                            )}
                          </Button>
                        </>
                      )}
                      {action && action}
                    </Box>
                  </>
                ) : null}
              </Box>
            )}
          </form>
        ) : (
          children && (
            <Box className="modal__content">
              {children}
              {closable !== false || action !== null ? (
                <>
                  <Box className="modal__action">
                    {(closable === true || typeof closable === 'string') && (
                      <>
                        <Button
                          onClick={() => closeModal()}
                          outline
                          className="modal__action__cancel"
                        >
                          {typeof closable === 'string' ? (
                            closable
                          ) : (
                            <Trans i18nKey="components.modal.cancel" />
                          )}
                        </Button>
                      </>
                    )}
                    {action && action}
                  </Box>
                </>
              ) : null}
            </Box>
          )
        )}
      </Box>
    </Box>,
    portalRoot
  );
});

Modal.defaultProps = {
  onClose: () => {},
  formSubmit: () => {},
  formSuccess: false,
  isForm: false,
  action: null,
  closable: false,
  isCheckoutModal: false,
  showCloseIcon: false,
  showOnlyCloseIcon: false,
  error: false,
  verticalCenter: false,
  size: 'md',
  hideScroll: true,
  onCloseOutsider: true
};

export default Modal;
