import { ReactComponent as USD } from '@assets/svg/currency/usd.svg';
//import { ReactComponent as AUD } from '@assets/svg/currency/aud.svg';
//import { ReactComponent as GBP } from '@assets/svg/currency/gbp.svg';

export const currencyList = [
  {
    label: 'USD',
    countryCode: 'US',
    icon: <USD />
  }
];
