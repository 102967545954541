import format from 'date-fns/format';
import add from 'date-fns/add';
import { Trans } from 'react-i18next';
import { Box, Button, Countdown, Price, SkeletonLoading } from '@components';
import { TIER_TYPES } from '@libs/utils/release';
import { getBidsFromExchange } from '@libs/utils/checkout';
import { LISTING_TYPES, EXCHANGE_INTENTS } from '@libs/utils/exchange';
import AuthButton from './button';

export const isDigitalOriginalReleased = (tier, currentTime) => {
  const releaseEndDateSeconds = new Date(tier?.saleEndDate).getTime();
  const isCollectibleReleased = currentTime < releaseEndDateSeconds;

  if (isCollectibleReleased) {
    return true;
  }

  return false;
};

const comingSoonContent = (tier, button, refetchTierTiles) => {
  const notifyButton = (
    <AuthButton
      loading={button.loading}
      isAuthenticated={button.isAuthenticated}
      isFollow={button.isFollow}
      onClick={button.onClick}
    />
  );

  switch (tier.listingType) {
    case LISTING_TYPES.FIXED_PRICE:
      return {
        title: <Trans i18nKey="components.editionCard.comingSoon" />,
        value: null,
        button: notifyButton,
        onClick: button.onClick
      };

    case LISTING_TYPES.MAKE_AN_OFFER:
      return {
        title: <Trans i18nKey="components.editionCard.comingSoon" />,
        value: null,
        button: notifyButton,
        onClick: button.onClick
      };

    case LISTING_TYPES.AUCTION:
      return {
        title: <Trans i18nKey="components.editionCard.comingSoon" />,
        value: null,
        button: notifyButton,
        onClick: button.onClick
      };

    default:
      return {};
  }
};

const preReleaseContent = (tier, button, refetchTierTiles) => {
  const countdown = <Countdown time={tier.releaseDate} refetchTierTiles={refetchTierTiles} />;
  const notifyButton = (
    <AuthButton
      loading={button.loading}
      isAuthenticated={button.isAuthenticated}
      isFollow={button.isFollow}
      onClick={button.onClick}
    />
  );

  switch (tier.listingType) {
    case LISTING_TYPES.FIXED_PRICE:
      return {
        title: <Trans i18nKey="components.editionCard.saleStarts" />,
        value: countdown,
        button: notifyButton,
        onClick: button.onClick
      };

    case LISTING_TYPES.MAKE_AN_OFFER:
      return {
        title: <Trans i18nKey="components.editionCard.offersOpen" />,
        value: countdown,
        button: notifyButton,
        onClick: button.onClick
      };

    case LISTING_TYPES.AUCTION:
      return {
        title: <Trans i18nKey="components.editionCard.auctionStart" />,
        value: countdown,
        button: notifyButton,
        onClick: button.onClick
      };

    default:
      return {};
  }
};

const getTierType = (exchangeType) => {
  return exchangeType === 'DIGITAL_ORIGINAL' ? 'do' : 'ne';
};

const releasedContent = (tier, exchangesData, digitalOriginalExchange, onClick) => {
  const currentBidExchange =
    exchangesData?.primaryMarket?.currentBidExchange ?? digitalOriginalExchange;

  switch (tier.listingType) {
    case LISTING_TYPES.FIXED_PRICE:
      return {
        title: <Trans i18nKey="components.editionCard.purchasePrice" />,
        value: <Price amount={tier.startingPrice} />,
        button: (
          <Button
            onClick={() =>
              onClick(
                EXCHANGE_INTENTS.FIXED_PRICE_PURCHASE,
                getTierType(exchangesData.primaryMarket.tierType)
              )
            }
          >
            <Trans i18nKey="components.editionCard.button.fixedPrice.buyNow" />
          </Button>
        ),
        onClick: () =>
          onClick(
            EXCHANGE_INTENTS.FIXED_PRICE_PURCHASE,
            getTierType(exchangesData.primaryMarket.tierType)
          )
      };

    case LISTING_TYPES.MAKE_AN_OFFER:
      return {
        title: <Trans i18nKey="components.editionCard.acceptingOffersUntil" />,
        value: <Countdown time={tier.saleEndDate} />,
        button: (
          <Button
            onClick={() =>
              onClick(
                EXCHANGE_INTENTS.MAKE_OFFER,
                getTierType(exchangesData.primaryMarket.tierType)
              )
            }
          >
            <Trans i18nKey="components.editionCard.button.makeAnOffer.makeOffer" />
          </Button>
        ),
        onClick: () =>
          onClick(EXCHANGE_INTENTS.MAKE_OFFER, getTierType(exchangesData.primaryMarket.tierType))
      };

    case LISTING_TYPES.AUCTION: {
      const { currentBid, nextBid } = getBidsFromExchange(currentBidExchange);

      return {
        title: currentBidExchange ? (
          <Box className="relative">
            <Box className="edition-card__price">
              <Trans
                i18nKey={`components.editionCard.${
                  currentBid ? 'highestBid' : nextBid ? 'openingBid' : 'noOpeningBid'
                }`}
              />
            </Box>
            <Box className="absolute top-0 mx-auto right-0 left-0 edition-card__auction">
              {currentBid && <Trans i18nKey={`components.editionCard.auctionEnd`} />}
            </Box>
          </Box>
        ) : (
          <SkeletonLoading className="w-8/12 h-4 mx-auto mb-2" />
        ),
        value: (
          <>
            {currentBidExchange ? (
              currentBid ?? nextBid ? (
                <Box className="relative">
                  <Price className="edition-card__price" amount={currentBid ?? nextBid} />
                  <Countdown
                    className="absolute top-0 mx-auto right-0 left-0 edition-card__auction"
                    time={tier.saleEndDate}
                  />
                </Box>
              ) : null
            ) : (
              <SkeletonLoading className="w-6/12 h-8 mx-auto" />
            )}
          </>
        ),
        button: (
          <Button
            onClick={() =>
              onClick(
                EXCHANGE_INTENTS.PLACE_BID,
                getTierType(exchangesData.primaryMarket.tierType),
                currentBidExchange
              )
            }
          >
            <Trans i18nKey="components.editionCard.button.auction.bidNow" />
          </Button>
        ),
        onClick: () =>
          onClick(
            EXCHANGE_INTENTS.PLACE_BID,
            getTierType(exchangesData.primaryMarket.tierType),
            currentBidExchange
          )
      };
    }

    default:
      return {};
  }
};

const getNumberedEditionContent = (exchangesData, onClick) => {
  const containsFixedPriceAndOffer = ['fixedPrice', 'offer'].every(
    (x) => exchangesData.secondaryMarket[x]
  );

  if (containsFixedPriceAndOffer) {
    const lowestFixedPriceListing = exchangesData.secondaryMarket.fixedPrice;
    return {
      title: <Trans i18nKey="components.editionCard.from" />,
      value: <Price amount={lowestFixedPriceListing?.sellingPrice} />,
      button: (
        <>
          <Button
            onClick={() =>
              onClick(EXCHANGE_INTENTS.FIXED_PRICE_PURCHASE, lowestFixedPriceListing?.id)
            }
          >
            <Trans i18nKey="components.editionCard.button.makeAnOffer.getItNow" />
          </Button>
          <Button
            onClick={() =>
              onClick(
                EXCHANGE_INTENTS.MAKE_OFFER,
                lowestFixedPriceListing?.id + '?type=MAKE_AN_OFFER'
              )
            }
          >
            <Trans i18nKey="components.editionCard.button.makeAnOffer.makeOffer" />
          </Button>
        </>
      ),
      hasExtraButton: true
    };
  }

  // contains only make an fixedPrice
  const fixedPrice = exchangesData.secondaryMarket.fixedPrice;
  if (fixedPrice) {
    return {
      title: <Trans i18nKey="components.editionCard.price" />,
      value: <Price amount={fixedPrice?.sellingPrice} />,
      button: (
        <Button onClick={() => onClick('SCROLL_TO_COLLECTIBLES_TABLE')}>
          <Trans i18nKey="components.editionCard.button.makeAnOffer.getItNow" />
        </Button>
      )
    };
  }

  // contains only make an offer
  const offer = exchangesData.secondaryMarket.offer;
  return {
    title: <Trans i18nKey="components.editionCard.lastSold" />,
    value: <Price amount={offer?.startingPrice} />,
    button: (
      <Button onClick={() => onClick('SCROLL_TO_COLLECTIBLES_TABLE')}>
        <Trans i18nKey="components.editionCard.button.makeAnOffer.makeOffer" />
      </Button>
    )
  };
};

const secondaryMarketContent = (tier, exchangesData, onClick, userID) => {
  const numbEdition = getNumberedEditionContent(exchangesData, onClick);
  const isDigitalOriginal = tier.tierType === TIER_TYPES.DIGITAL_ORIGINAL;
  const hasFixedPrice = exchangesData.secondaryMarket.fixedPrice;
  const exchangeToBuy = hasFixedPrice
    ? exchangesData.secondaryMarket?.fixedPrice
    : exchangesData.secondaryMarket?.offer;
  const isOwner = exchangeToBuy.currentOwnerID === userID;

  switch (exchangeToBuy?.listingType) {
    case LISTING_TYPES.FIXED_PRICE:
      return {
        title: isDigitalOriginal ? (
          <Trans i18nKey="components.editionCard.price" />
        ) : (
          numbEdition.title
        ),
        value: isDigitalOriginal ? (
          <Price amount={exchangeToBuy?.sellingPrice} />
        ) : (
          numbEdition.value
        ),
        button: isDigitalOriginal ? (
          <>
            {hasFixedPrice && (
              <Button
                onClick={() => onClick(EXCHANGE_INTENTS.FIXED_PRICE_PURCHASE, exchangeToBuy?.id)}
                disabled={isOwner}
              >
                <Trans i18nKey="components.editionCard.button.fixedPrice.getItNow" />
              </Button>
            )}
          </>
        ) : (
          numbEdition.button
        ),
        onClick: () => onClick(EXCHANGE_INTENTS.FIXED_PRICE_PURCHASE, exchangeToBuy?.id),
        hasExtraButton: isDigitalOriginal ? hasFixedPrice : numbEdition.hasExtraButton
      };

    case LISTING_TYPES.MAKE_AN_OFFER:
      return {
        title: <Trans i18nKey="components.editionCard.lastPrice" />,
        value: isDigitalOriginal ? (
          <Price amount={exchangesData.secondaryMarket?.lowestPrice?.startingPrice} />
        ) : (
          numbEdition.value
        ),
        button: isDigitalOriginal ? (
          <Button
            onClick={() =>
              onClick(EXCHANGE_INTENTS.MAKE_OFFER, exchangesData.secondaryMarket?.lowestPrice?.id)
            }
            disabled={isOwner}
          >
            <Trans i18nKey="components.editionCard.button.makeAnOffer.makeOffer" />
          </Button>
        ) : (
          numbEdition.button
        ),
        onClick: () =>
          onClick(EXCHANGE_INTENTS.MAKE_OFFER, exchangesData.secondaryMarket?.lowestPrice?.id),
        hasExtraButton: isDigitalOriginal ? hasFixedPrice : numbEdition.hasExtraButton
      };

    case LISTING_TYPES.AUCTION:
    default:
      return {};
  }
};

const saleClosedContent = (tier) => {
  switch (tier.listingType) {
    case LISTING_TYPES.FIXED_PRICE:
      return {
        title: <Trans i18nKey="components.editionCard.saleClosed" />,
        value: <Price amount={tier.finalPrice} />,
        button: (
          <Button>
            <Trans i18nKey="components.editionCard.button.fixedPrice.saleClosed" />
          </Button>
        )
      };

    case LISTING_TYPES.MAKE_AN_OFFER:
      return {
        title: <Trans i18nKey="components.editionCard.offersClosed" />,
        value: '00:00:00',
        button: (
          <Button>
            <Trans i18nKey="components.editionCard.button.makeAnOffer.offerClosed" />
          </Button>
        )
      };

    case LISTING_TYPES.AUCTION:
      return {
        title: <Trans i18nKey="components.editionCard.auctionClosed" />,
        value: '00:00:00',
        button: (
          <Button>
            <Trans i18nKey="components.editionCard.button.auction.auctionClosed" />
          </Button>
        )
      };

    default:
      return {};
  }
};

const saleSoldOutContent = (tier, digitalOriginalExchange, onClick) => {
  switch (tier.listingType) {
    case LISTING_TYPES.FIXED_PRICE:
      return {
        title: <Trans i18nKey="components.editionCard.price" />,
        value: <Price amount={tier.finalPrice} />,
        button: (
          <Button onClick={() => onClick('SCROLL_TO_COLLECTIBLES_TABLE')}>
            <Trans
              i18nKey={`components.editionCard.button.fixedPrice.${
                tier.tierType === 'DIGITAL_ORIGINAL' ? 'getItNow' : 'seeOwners'
              }`}
            />
          </Button>
        ),
        onClick: () => onClick('SCROLL_TO_COLLECTIBLES_TABLE')
      };

    case LISTING_TYPES.MAKE_AN_OFFER:
      return {
        title: <Trans i18nKey="components.editionCard.soldOut" />,
        value: '',
        button: (
          <Button onClick={() => onClick('SCROLL_TO_COLLECTIBLES_TABLE')}>
            <Trans
              i18nKey={`components.editionCard.button.makeAnOffer.${
                tier.tierType === 'DIGITAL_ORIGINAL' ? 'makeOffer' : 'soldOut'
              }`}
            />
          </Button>
        ),
        onClick: () => onClick('SCROLL_TO_COLLECTIBLES_TABLE')
      };

    case LISTING_TYPES.AUCTION: {
      const hasBids = digitalOriginalExchange?.previousPrice != null;
      return {
        title: digitalOriginalExchange ? (
          <Trans i18nKey="components.editionCard.lastSold" />
        ) : (
          <SkeletonLoading className="w-8/12 h-4 mx-auto mb-2" />
        ),
        value: digitalOriginalExchange ? (
          <Price
            amount={
              hasBids
                ? digitalOriginalExchange?.previousPrice
                : digitalOriginalExchange?.currentPrice
            }
          />
        ) : (
          <SkeletonLoading className="w-6/12 h-8 mx-auto" />
        ),
        button: (
          <Button onClick={() => onClick('SCROLL_TO_COLLECTIBLES_TABLE')}>
            <Trans i18nKey="components.editionCard.button.fixedPrice.soldOut" />
          </Button>
        ),
        onClick: () => onClick('SCROLL_TO_COLLECTIBLES_TABLE')
      };
    }

    default:
      return {};
  }
};

const unsoldContent = (tier, button) => {
  const notifyButton = (
    <AuthButton
      loading={button.loading}
      isAuthenticated={button.isAuthenticated}
      isFollow={button.isFollow}
      onClick={button.onClick}
    />
  );
  switch (tier.listingType) {
    case LISTING_TYPES.FIXED_PRICE:
      return {
        title: <Trans i18nKey="components.editionCard.lastSold" />,
        value: '-',
        button: (
          <Button>
            <Trans i18nKey="components.editionCard.saleClosed" />
          </Button>
        )
      };

    case LISTING_TYPES.MAKE_AN_OFFER:
      return {
        title: <Trans i18nKey="components.editionCard.lastSold" />,
        value: '-',
        button: (
          <Button>
            <Trans i18nKey="components.editionCard.offersClosed" />
          </Button>
        )
      };

    case LISTING_TYPES.AUCTION:
      return {
        title: <Trans i18nKey="components.editionCard.lastSold" />,
        value: '-',
        button: (
          <Button>
            <Trans i18nKey="components.editionCard.auctionClosed" />
          </Button>
        )
      };

    default:
      return {};
  }
};

export const createContent = (
  tier,
  hasExchanges,
  exchangesData,
  currentTime,
  buttonProps,
  digitalOriginalExchange,
  onClick,
  userID,
  refetchTierTiles
) => {
  const releaseDateSeconds = new Date(tier.releaseDate).getTime();
  const releaseEndDateSeconds = new Date(tier?.saleEndDate).getTime();
  const twoMonthsFromNow = format(add(new Date(), { months: 2 }), 'T');
  const isReleaseDateBeyondTwoMonths = twoMonthsFromNow < releaseDateSeconds;
  const isReleaseDateWithinTwoMonths =
    twoMonthsFromNow >= releaseDateSeconds && releaseDateSeconds > currentTime;
  const isCurrentlyReleased = true;
  const releaseNotEnded = currentTime < releaseEndDateSeconds;

  if (hasExchanges.primaryMarket) {
    if (isReleaseDateBeyondTwoMonths) {
      return comingSoonContent(tier, buttonProps, refetchTierTiles);
    }

    if (isReleaseDateWithinTwoMonths) {
      return preReleaseContent(tier, buttonProps, refetchTierTiles);
    }

    if (
      (isCurrentlyReleased && tier.tierType === TIER_TYPES.NUMBERED_EDITION) ||
      (releaseNotEnded && isCurrentlyReleased && tier.tierType === TIER_TYPES.DIGITAL_ORIGINAL)
    ) {
      return releasedContent(tier, exchangesData, digitalOriginalExchange, onClick);
    }

    // post-release
    if (hasExchanges.secondaryMarket) {
      return secondaryMarketContent(tier, exchangesData, onClick, userID);
    }

    if (!tier?.saleEndDate) {
      return releasedContent(tier, exchangesData, digitalOriginalExchange, onClick);
    }

    return unsoldContent(tier, buttonProps);
  }

  if (hasExchanges.secondaryMarket && tier.tierType === TIER_TYPES.DIGITAL_ORIGINAL) {
    return secondaryMarketContent(tier, exchangesData, onClick, userID);
  }

  if (!hasExchanges.primaryMarket && isCurrentlyReleased) {
    return saleSoldOutContent(tier, digitalOriginalExchange, onClick, hasExchanges.secondaryMarket);
  }

  if (!hasExchanges.secondaryMarket && tier.tierType === TIER_TYPES.NUMBERED_EDITION) {
    return unsoldContent(tier, buttonProps);
  }

  return saleClosedContent(tier);
};
