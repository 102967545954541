import { Box } from '@components';
import { useRef } from 'react';
import Slider from 'react-slick';
import { useCollectibleByOwners } from '@libs/hooks/collectible';
import CollectibleCard from './collectibleCard';
import { ReactComponent as ArrowLeftIcon } from '@assets/svg/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '@assets/svg/arrow-right.svg';

function CollectibleSlider({ profile }) {
  const slideFeaturedRef = useRef();
  const slideFeaturedSettings = {
    dots: true,
    focusOnSelect: false,
    arrows: false,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    customPaging: function (i) {
      return <div></div>;
    },
    dotsClass: 'slick-custom-pagging',
    className: 'collectible-slider-test',
    variableWidth: true
  };
  const RenderArrows = ({ refVal }) => {
    return (
      <div className="slider-arrow">
        <Box className="arrow-btn slide-arrow-prev" onClick={() => refVal.current.slickPrev()}>
          <Box className="slide-arrow-prev-button">
            <ArrowLeftIcon />
          </Box>
        </Box>
        <Box className="arrow-btn slide-arrow-next" onClick={() => refVal.current.slickNext()}>
          <Box className="slide-arrow-next-button">
            <ArrowRightIcon />
          </Box>
        </Box>
      </div>
    );
  };
  const { data: latestCollectibles, loading } = useCollectibleByOwners(profile?.id, 10);
  return (
    <Box className="landing-artist-slider">
      {latestCollectibles?.length > 0 && !loading && (
        <>
          {latestCollectibles?.length > 4 && <RenderArrows refVal={slideFeaturedRef} />}
          <Box className="landing-artist-slider__slider">
            <Slider {...slideFeaturedSettings} ref={slideFeaturedRef}>
              {latestCollectibles &&
                latestCollectibles.map((collectible) => (
                  <CollectibleCard key={collectible?.id} collectible={collectible} />
                ))}
            </Slider>
          </Box>
          <Box className="landing-artist-slider__left"></Box>
          <Box className="landing-artist-slider__right"></Box>
        </>
      )}
    </Box>
  );
}

export default CollectibleSlider;
