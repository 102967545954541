import { React } from 'react';
import FansincSkeletonLoading from './fansinc';
import PatronsSkeletonLoading from './patrons';

function SkeletonLoading({ className, ...props }) {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsSkeletonLoading className={className} {...props} />;
  } else {
    return <FansincSkeletonLoading className={className} {...props} />;
  }
}
export default SkeletonLoading;
