import { useState, useEffect } from 'react';

import gql from '@libs/utils/gql';
import useToast from '@libs/utils/toast';
import { searchExchangesLite } from '@libs/custom-queries/exchange';

export const useExchangesBySlugAndSerialIndex = ({ slug, serialIndex = 0, limit = 10, filter }) => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [isDataCleared, setIsDataCleared] = useState(false);

  async function getData() {
    try {
      setLoading(true);
      const params = {
        limit,
        sort: { direction: 'desc', field: 'createdAt' },
        filter: {
          slug: { eq: slug },
          ...(serialIndex ? { serialIndex: { eq: serialIndex } } : null),
          ...filter
        }
      };

      const { data: res } = await gql(searchExchangesLite, params);

      if (res?.searchExchanges?.items?.length) {
        setData(res.searchExchanges.items[0]);
      }
    } catch (error) {
      toast(error.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const clearData = () => {
    setData(null);
    setIsDataCleared(true);
  };

  if (isDataCleared && data == null && slug) {
    getData();
    setIsDataCleared(false);
  }

  useEffect(() => {
    getData();
  }, [limit]); // eslint-disable-line

  return {
    loading,
    data,
    getData,
    clearData
  };
};
