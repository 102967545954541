import FansincError from './fansinc';
import PatronsError from './patrons';

const Error = ({ title, description }) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsError title={title} description={description} />;
  } else {
    return <FansincError title={title} description={description} />;
  }
};

export default Error;
