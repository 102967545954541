import { useTranslation } from 'react-i18next';
import { Box, Countdown, Text } from '@components';

const CollectibleCardTimer = ({ releaseDate, releaseDateSeconds, currentTime }) => {
  const { t } = useTranslation();

  return (
    releaseDateSeconds > currentTime && (
      <Box className="collectible-timer-new">
        <Countdown
          time={releaseDateSeconds > currentTime ? releaseDate : releaseDate}
          showSeconds
          showThreeDigits
          showSeparator
          label={
            <Text as="span" className="collectible-timer-new__label">
              {t('components.collectibleTimer.releaseDate')}
            </Text>
          }
        />
      </Box>
    )
  );
};

export default CollectibleCardTimer;
