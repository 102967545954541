import cx from 'classnames';
import { forwardRef } from 'react';

const TextArea = forwardRef(function TextAreaPlain(
  { label, placeholder, children, size, fullWidth, errorMessage, required, ...props },
  ref
) {
  const full = fullWidth ? 'w-full' : '';

  const sizeMapper = {
    xs: 'textarea-xs',
    lg: 'textarea-lg',
    md: 'textarea-md',
    sm: 'textarea-sm'
  };

  return (
    <>
      {label && (
        <label className="form-label" htmlFor="forms-validationInputCode_error">
          {label}
        </label>
      )}
      <textarea
        ref={ref}
        placeholder={placeholder}
        required={required}
        className={cx(sizeMapper[size], full)}
        {...props}
      >
        {children}
      </textarea>
      {errorMessage && <span className="field-help field-help--error">{errorMessage}</span>}
    </>
  );
});

TextArea.defaultProps = {
  size: 'md'
};

export default TextArea;
