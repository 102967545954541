import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useCallback, useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import debounce from 'lodash.debounce';
import addDays from 'date-fns/addDays';
import config from '@/config';
import gql from '@libs/utils/gql';
import { useAuth } from '@libs/contexts/auth';
import useToast from '@libs/utils/toast';
import { getUserMedia } from '@graphql/queries';
import {
  updateUserMedia,
  removeAvatarImage,
  removeCoverImage,
  updateEmail,
  updateUsername,
  verifyEmail,
  updateShowNotifications,
  updateAccountUser,
  collectVisitCollectible
} from '@graphql/mutations';
import { Link } from '@components';
import { whitelistEmail } from '@libs/utils/email';
import {
  findByEmail,
  findByID,
  findByUsername,
  listRelatedCreator,
  searchUsers
} from '@libs/custom-queries/user';
import { useUserParams, useCollectorsParams } from '@libs/hooks/user/utils';

// @todo: move this hook handlers to contexts/auth.js
export const useUser = () => {
  const toast = useToast();
  const history = useHistory();
  const { user } = useAuth();
  const { t } = useTranslation();
  const [cookies, setCookie, removeCookie] = useCookies([config.cookie.USER_ACTIVATION]);

  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [notificationLoading, setNotificationLoading] = useState(false);

  const [updateAvatarLoading, setUpdateAvatarLoading] = useState(false);
  const [updateCoverLoading, setUpdateCoverLoading] = useState(false);
  const [removeAvatarLoading, setRemoveAvatarLoading] = useState(false);
  const [removeCoverLoading, setRemoveCoverLoading] = useState(false);
  const [usernameLoading, setUsernameLoading] = useState(false);
  const [availabilityLoading, setAvailabilityLoading] = useState(false);
  const [usernameAvailability, setUsernameAvailability] = useState(false);
  const [emailAvailability, setEmailAvailability] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [message, setMessage] = useState({ success: null, error: null });

  const signUp = async ({
    firstName,
    lastName,
    password,
    email,
    captchaToken,
    claimCollectible
  }) => {
    setLoading(true);

    try {
      const validateEmail = await whitelistEmail(email);
      if (!validateEmail) {
        setLoading(false);
        setMessageStatus(
          'error',
          <Trans i18nKey="auth.whitelistEmail">
            <Link to="mailto:support@fans.inc" target="_blank" />
            {{ environment: process.env.REACT_APP_AMPLIFY_ENV }}
          </Trans>
        );
        return;
      }
    } catch (error) {
      console.error(error);
    }

    try {
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          given_name: firstName,
          family_name: lastName,
          'custom:user_role': 'Fan',
          'custom:captcha_token': captchaToken,
          'custom:claim_collectible': claimCollectible
        }
      });

      if (cookies[config.cookie.ACTIVATE_USERNAME]) {
        removeCookie(config.cookie.ACTIVATE_USERNAME);
      }

      const nextDay = addDays(new Date(), 1);
      setCookie(config.cookie.ACTIVATE_USERNAME, email, { expires: nextDay });

      history.push(`/activate-user?email=${encodeURIComponent(email)}`);
    } catch (error) {
      console.error('error signing up:', error);
      toast(error.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  const confirmSignUp = useCallback(
    async (code) => {
      try {
        await Auth.confirmSignUp(cookies[config.cookie.ACTIVATE_USERNAME], code);

        removeCookie(config.cookie.ACTIVATE_USERNAME);
        history.push('/login');
      } catch (error) {
        console.error('error confirming sign up', error);
        toast(error.message, 'error');
      }
    },
    // eslint-disable-next-line
    [cookies]
  );

  const resendSignUp = useCallback(
    async (email) => {
      setResendLoading(true);
      try {
        await Auth.resendSignUp(email);
        toast(t('auth.activate.notifications.resent', { email: email }), 'success');
      } catch (error) {
        console.error('error resending code: ', error);
        setMessageStatus('error', error.message);
      } finally {
        setResendLoading(false);
      }
    },
    // eslint-disable-next-line
    [cookies]
  );

  const changePass = async ({ oldPassword, newPassword }) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, newPassword);

      toast(t('editProfile.successUpdatePassword'), 'success');
    } catch (error) {
      console.error('error change pass:', error);
      toast(error.message, 'error');
    }
  };

  const forgotPass = async (email) => {
    setLoading(true);
    try {
      await Auth.forgotPassword(email);
    } catch (error) {
      console.error('error forgot pass:', error);
    } finally {
      setLoading(false);
    }
    if (cookies[config.FORGOT_PASS]) {
      removeCookie(config.FORGOT_PASS);
    }
    const nextDay = addDays(new Date(), 1);
    setCookie(config.FORGOT_PASS, email, { expires: nextDay });

    history.push('/reset-password');
  };

  const resendForgotPass = useCallback(async () => {
    const email = cookies[config.FORGOT_PASS];
    try {
      await Auth.forgotPassword(cookies[config.FORGOT_PASS]);
      toast(t('auth.resetPassword.resent', { email: email }), 'success');
    } catch (error) {
      console.error('error resending code: ', error);
      if (error?.message) {
        if (error?.message === 'Username/client id combination not found.') {
          toast(t('auth.resetPassword.resent', { email: email }), 'success');
        } else {
          toast(error.message, 'error');
        }
      }
    }
    // eslint-disable-next-line
  }, [cookies]);

  const resetPass = useCallback(
    async ({ code, newPassword }) => {
      setLoading(true);
      try {
        await Auth.forgotPasswordSubmit(cookies[config.FORGOT_PASS], code, newPassword);
        removeCookie(config.FORGOT_PASS);
        toast(t('auth.resetPassword.success'), 'success');
        setTimeout(() => {
          history.push('/login?passwordChanged=1');
        }, 500);
      } catch (error) {
        console.error('error reset pass:', error);
        if (error?.message) {
          if (error?.message === 'Username/client id combination not found.') {
            toast('Invalid code provided, please request a code again.', 'error');
          } else {
            toast(error.message, 'error');
          }
        }
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    [cookies]
  );

  const onUpdate = useCallback(
    async ({ firstName, lastName, bio, links, address, shippingAddress, addressForm }) => {
      setUpdateLoading(true);
      try {
        const payload = {
          input: {
            firstName,
            lastName,
            bio,
            links: {
              facebook: links.facebook,
              instagram: links.instagram,
              twitter: links.twitter,
              email: links.email,
              website: links.website
            },
            address: {
              line1: address.line1,
              country: address.country,
              stateName: address.stateName,
              city: address.city,
              postCode: address.postCode,
              phone: address.phone,
              recipientName: address.recipientName
            },
            shippingAddress: {
              line1: shippingAddress.line1,
              country: shippingAddress.country,
              stateName: shippingAddress.stateName,
              city: shippingAddress.city,
              postCode: shippingAddress.postCode,
              phone: shippingAddress.phone,
              recipientName: shippingAddress.recipientName
            }
          }
        };
        await gql(updateAccountUser, payload, {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
        addressForm ? null : toast(t('editProfile.successUpdateProfile'), 'success');
      } catch (error) {
        console.error('error updating user:', error);
        const errorMessage = error?.errors[0]?.message;
        toast(errorMessage, 'error');
      } finally {
        setUpdateLoading(false);
      }
    },
    // eslint-disable-next-line
    [user]
  );

  const onUpdateAvatar = useCallback(
    async (avatarUrl) => {
      setUpdateAvatarLoading(true);
      try {
        const { data: media } = await gql(getUserMedia, { id: user.id });

        const payload = {
          input: {
            id: user.id,
            avatarUrl,
            coverUrl: media?.getUserMedia?.coverUrl || ''
          }
        };

        await gql(updateUserMedia, payload, {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });

        toast(t('editProfile.successUpdateMedia'), 'success');
      } catch (error) {
        const errorMessage = error?.errors[0]?.message;
        toast(errorMessage, 'error');

        console.error('error update user media:', error);
      } finally {
        setUpdateAvatarLoading(false);
      }
    },

    // eslint-disable-next-line
    [user]
  );

  const onRemoveAvatar = useCallback(
    async (key) => {
      setRemoveAvatarLoading(true);
      const payload = {
        input: {
          field: 'avatarUrl'
        }
      };
      try {
        await gql(removeAvatarImage, payload, {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });

        key === 'remove' && toast(t('editProfile.successRemoveAvatar'), 'success');
      } catch (error) {
        const errorMessage = error?.errors[0]?.message;
        toast(errorMessage, 'error');

        console.error('error update user media:', error);
      } finally {
        setRemoveAvatarLoading(false);
      }
    },

    // eslint-disable-next-line
    [user]
  );

  const onUpdateCover = useCallback(
    async (coverUrl) => {
      setUpdateCoverLoading(true);
      try {
        const { data: media } = await gql(getUserMedia, { id: user.id });

        const payload = {
          input: {
            id: user.id,
            coverUrl,
            avatarUrl: media?.getUserMedia?.avatarUrl || ''
          }
        };

        await gql(updateUserMedia, payload, {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });

        toast(t('editProfile.successUpdateCover'), 'success');
      } catch (error) {
        const errorMessage = error?.errors[0]?.message;
        toast(errorMessage, 'error');

        console.error('error update user media:', error);
      } finally {
        setUpdateCoverLoading(false);
      }
    },

    // eslint-disable-next-line
    [user]
  );

  const onRemoveCover = useCallback(
    async (key) => {
      setRemoveCoverLoading(true);
      const payload = {
        input: {
          field: 'coverUrl'
        }
      };
      try {
        await gql(removeCoverImage, payload, {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });

        key === 'remove' && toast(t('editProfile.successRemoveCover'), 'success');
      } catch (error) {
        const errorMessage = error?.errors[0]?.message;
        toast(errorMessage, 'error');

        console.error('error update user media:', error);
      } finally {
        setRemoveCoverLoading(false);
      }
    },

    // eslint-disable-next-line
    [user]
  );

  const onUpdateUsername = useCallback(
    async (username) => {
      setUsernameLoading(true);
      try {
        const payload = {
          input: {
            username: username
          }
        };
        await gql(updateUsername, payload, {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });

        toast(t('editProfile.successUpdateUsername'), 'success');
      } catch (error) {
        const errorMessage = error?.errors[0]?.message;
        toast(errorMessage, 'error');

        console.error('error update user:', error);
      } finally {
        setUsernameLoading(false);
      }
    },

    // eslint-disable-next-line
    [user]
  );

  const onCheckUsername = async (username) => {
    setAvailabilityLoading(true);
    try {
      const { data: res } = await gql(findByUsername, { username });
      const isAvailable = !!res?.userByUsername?.items?.length;
      setUsernameAvailability(isAvailable);
    } catch (err) {
      console.error(err);
    } finally {
      setAvailabilityLoading(false);
    }
  };

  const onCheckEmail = async (email) => {
    setAvailabilityLoading(true);
    try {
      const { data: res } = await gql(findByEmail, { email });
      const isAvailable = !!res?.userByEmail?.items?.length;
      setEmailAvailability(isAvailable);
    } catch (err) {
      console.error(err);
    } finally {
      setAvailabilityLoading(false);
    }
  };

  const onUpdateEmail = useCallback(
    async (email) => {
      setEmailLoading(true);
      const payload = { input: { email } };
      try {
        const { data: res } = await gql(updateEmail, payload, {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
        let toastSuccessMsg = t('editProfile.successUpdateEmail', { newEmail: email });
        if (email === '') {
          toastSuccessMsg = t('editProfile.successUndoEmail');
        }

        if (res) {
          toast(toastSuccessMsg, 'success');
        } else {
          toast(t('editProfile.wentWrong'), 'error');
        }
      } catch (error) {
        const errorMessage = error?.errors[0]?.message;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setEmailLoading(false);
      }
    },

    // eslint-disable-next-line
    [user]
  );

  const onVerifyEmail = useCallback(
    async (code) => {
      setEmailLoading(true);
      const payload = { input: { code } };
      try {
        const { data: res } = await gql(verifyEmail, payload, {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });

        if (res) {
          toast(t('editProfile.emailVerified'), 'success');
        } else {
          toast(t('editProfile.wentWrong'), 'error');
        }
      } catch (error) {
        const errorMessage = error?.errors[0]?.message;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setEmailLoading(false);
      }
    },

    // eslint-disable-next-line
    [user]
  );

  const setMessageStatus = (status, message) => {
    if (!['success', 'error'].includes(status)) {
      throw Error('Invalid message type');
    }

    setMessage({ [status]: message });
  };

  const onUpdateShowNotifications = useCallback(
    async (value) => {
      const notificationType = [
        'outbid',
        'bidResponses',
        'offerResponses',
        'successfulPurchase',
        'collectibleSold',
        'bidReceived',
        'offerReceived',
        'collectibleMinted',
        'collectiblePrinted',
        'newCollectibles',
        'collectibleRelease',
        'fansincNewsletter'
      ];
      setNotificationLoading(true);
      try {
        if (notificationType.includes(value.notification)) {
          const payload = {
            input: value
          };
          await gql(updateShowNotifications, payload, {
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          });
          if (process.env.REACT_APP_NAME === 'fansinc') {
            toast(t('editProfile.successUpdateShowNotifications'), 'success');
          }
        }
      } catch (error) {
        const errorMessage = error?.errors[0]?.message;
        toast(errorMessage, 'error');

        console.error('error update show notification:', error);
      } finally {
        setNotificationLoading(false);
      }
    },

    // eslint-disable-next-line
    [user]
  );

  const checkConfirmedEmail = async (email) => {
    setAvailabilityLoading(true);
    try {
      const password = Math.random().toString(36);
      const { username } = await Auth.signIn(email, password);
    } catch (error) {
      if (error.message !== 'User is not confirmed.') {
        if (error.message === 'User does not exist.') {
          toast(t('auth.activate.emailNotFound'), 'error');
          history.push(`/signup`);
        } else {
          toast(t('auth.activate.alreadyActive'), 'success');
          history.push(`/login`);
        }
      }
    }
  };

  return {
    loading,
    resendLoading,
    updateLoading,
    updateAvatarLoading,
    updateCoverLoading,
    removeAvatarLoading,
    removeCoverLoading,
    usernameLoading,
    notificationLoading,
    error: message.error,
    success: message.success,
    signUp,
    confirmSignUp,
    resendSignUp,
    changePass,
    forgotPass,
    resendForgotPass,
    resetPass,
    onUpdate,
    onUpdateAvatar,
    onUpdateCover,
    onUpdateUsername,
    onRemoveAvatar,
    onRemoveCover,
    onCheckUsername: debounce(onCheckUsername, 500),
    onCheckEmail,
    availabilityLoading,
    usernameAvailability,
    emailAvailability,
    onUpdateEmail,
    emailLoading,
    onVerifyEmail,
    onUpdateShowNotifications,
    checkConfirmedEmail
  };
};

export const useUserByUsername = (username) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  async function getData() {
    setLoading(true);
    try {
      const { data: res } = await gql(findByUsername, { username });
      if (res?.userByUsername?.items?.length) {
        if (res.userByUsername.items[0]?.status !== 'NOT_ACTIVE') {
          setData(res.userByUsername.items[0]);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (username) {
      getData();
    }
  }, [username]); // eslint-disable-line

  return {
    data,
    refetchData: getData,
    loading
  };
};

export const useRelatedCreators = (username, limit = 6) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        const { data: res } = await gql(listRelatedCreator, {
          verified: 'TRUE',
          filter: {
            username: {
              ne: username
            },
            isHidden: { eq: 'FALSE' }
          },
          sortDirection: 'DESC',
          limit
        });

        if (res?.userByVerifiedJoinDate?.items?.length > 2) {
          setData(res.userByVerifiedJoinDate.items);
        } else {
          setData(false);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }

    if (username) getData();
  }, [username, limit]);

  return {
    data,
    loading
  };
};

export const useCreators = (limit = 6) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState([]);
  const {
    query: params,
    onPaginate,
    onChangeSort,
    onChangeName,
    onReset,
    onChangeStyle
  } = useUserParams(limit);
  const [nextPageToken, setNextPageToken] = useState();

  async function getData() {
    setLoading(true);
    try {
      const { data: res } = await gql(searchUsers, params);

      if (res?.searchUsers?.items) {
        setTotal(res.searchUsers.total);
        setData(data.concat(res.searchUsers.items));
        setNextPageToken(res.searchUsers.nextToken);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  const onSortBy = (sortBy) => {
    setData([]);
    onChangeSort(sortBy);
  };

  const onHandleReset = () => {
    setData([]);
    onReset();
  };

  const handleChangeName = (name) => {
    setData([]);
    onChangeName(name);
  };

  const handleChangeStyle = (style) => {
    setData([]);
    onChangeStyle(style);
  };

  useEffect(() => {
    getData();
  }, [params]); // eslint-disable-line

  return {
    data,
    loading,
    total,
    onPaginate,
    onSortBy,
    handleChangeName,
    onHandleReset,
    handleChangeStyle,
    nextPageToken
  };
};

export const useFilterCollectors = (limit = 6) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState([]);
  const {
    query: params,
    onPaginate,
    onChangeSort,
    onChangeName,
    onReset,
    onChangeStyle
  } = useCollectorsParams(limit);
  const [nextPageToken, setNextPageToken] = useState();

  async function getData() {
    setLoading(true);
    try {
      const { data: res } = await gql(searchUsers, params);

      if (res?.searchUsers?.items) {
        setTotal(res.searchUsers.total);
        setData(data.concat(res.searchUsers.items));
        setNextPageToken(res.searchUsers.nextToken);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  const onSortBy = (sortBy) => {
    setData([]);
    onChangeSort(sortBy);
  };

  const onHandleReset = () => {
    setData([]);
    onReset();
  };

  const handleChangeName = (name) => {
    setData([]);
    onChangeName(name);
  };

  const handleChangeStyle = (style) => {
    setData([]);
    onChangeStyle(style);
  };

  useEffect(() => {
    getData();
  }, [params]); // eslint-disable-line

  return {
    data,
    loading,
    total,
    onPaginate,
    onSortBy,
    handleChangeName,
    onHandleReset,
    handleChangeStyle,
    nextPageToken
  };
};

export const useUserByID = (id) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  async function getData() {
    setLoading(true);
    try {
      const { data: res } = await gql(findByID, { id });
      if (res?.getUser) {
        setData(res.getUser);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id]); // eslint-disable-line

  return {
    data,
    refetchData: getData,
    loading
  };
};

export const useCreatorsBySalesVolume = (limit = 5) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  async function getData() {
    setLoading(true);
    try {
      const params = {
        filter: {
          searchableVerified: { eq: 'TRUE' },
          totalSalesVolume: { ne: 0 }
        },
        sort: { direction: 'desc', field: 'totalSalesVolume' },
        limit
      };
      const { data: res } = await gql(searchUsers, params);

      if (res?.searchUsers?.items?.length > 0) {
        setData(res.searchUsers.items);
      } else {
        setData([]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return {
    data,
    loading
  };
};

export const useUsersByCollectionValues = (limit = 5) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  async function getData() {
    setLoading(true);
    try {
      const params = {
        filter: {
          searchableVerified: { eq: 'FALSE' },
          totalCollectionValues: { ne: 0 }
        },
        sort: { direction: 'desc', field: 'totalCollectionValues' },
        limit
      };
      const { data: res } = await gql(searchUsers, params);

      if (res?.searchUsers?.items?.length > 0) {
        setData(res.searchUsers.items);
      } else {
        setData([]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return {
    data,
    loading
  };
};

export const useUserByDisplayName = (limit = 3) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  async function getData(name) {
    setLoading(true);
    try {
      const params = {
        filter: {
          searchableVerified: { eq: 'TRUE' },
          displayName: { matchPhrasePrefix: name }
        },
        sort: { direction: 'desc', field: 'totalCollectionValues' },
        limit
      };
      const { data: res } = await gql(searchUsers, params);

      if (res?.searchUsers?.items?.length > 0) {
        setData(res.searchUsers.items);
      } else {
        setData([]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  return {
    data,
    loading,
    getData
  };
};

export const useCollectorsFilterName = (limit = 3) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  async function getData(name) {
    setLoading(true);
    try {
      const params = {
        filter: {
          searchableVerified: { eq: 'FALSE' },
          displayName: { matchPhrasePrefix: name }
        },
        sort: { direction: 'desc', field: 'totalCollectionValues' },
        limit
      };
      const { data: res } = await gql(searchUsers, params);

      if (res?.searchUsers?.items?.length > 0) {
        setData(res.searchUsers.items);
      } else {
        setData([]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  return {
    data,
    loading,
    getData
  };
};

export const useCollectors = (limit = 6) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();

  async function getData() {
    setLoading(true);
    try {
      const params = {
        filter: {
          searchableVerified: { eq: 'FALSE' }
        },
        limit
      };
      const { data: res } = await gql(searchUsers, params);

      if (res?.searchUsers?.items?.length > 0) {
        setTotal(res.searchUsers.total);
        setData(res.searchUsers.items);
      } else {
        setData([]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return {
    data,
    loading,
    total,
    getData
  };
};

export const useCollectCollectible = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [createLoading, setCreateLoading] = useState(false);
  const toast = useToast();

  const onCreateUser = async (payload) => {
    setCreateLoading(true);
    try {
      await gql(collectVisitCollectible, {
        input: {
          collectibleID: payload?.collectibleID,
          email: payload?.email,
          firstName: payload?.firstName,
          surName: payload?.surName,
          password: payload?.password
        }
      });
      toast(t('p.pages.collectCollectible.success'), 'success');
      history.push('/login');
    } catch (error) {
      const errorMessage = error?.errors[0]?.message;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setCreateLoading(false);
    }
  };

  return {
    createLoading,
    onCreateUser
  };
};
