import cx from 'classnames';
import { Heading, Text } from '@components';

const TeamCard = ({ user, className, children, ...props }) => {
  return (
    <div className={cx('team-card', className)}>
      <img src={user.media.avatarUrl} alt={user.firstName} className="team-card__image" />
      <Heading as="h4" className="team-card__title">
        {user.name}
      </Heading>
      <Text className="team-card__position">{user.position}</Text>
    </div>
  );
};

export default TeamCard;
