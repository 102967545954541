import React, { useEffect, useCallback, useState } from 'react';
import cx from 'classnames';
import { useOutsider } from '@libs/utils/outsider';
import { FORMAT_TYPE, mediaChecker, createAssetUrl } from '@libs/utils/media';
// import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import Player from './video-player';

function Lightbox({ show, onClose, index, setIndex, sources }) {
  const [play, setPlay] = useState(show);
  const handleCloseModal = () => onClose();
  const { ref: refModal } = useOutsider(handleCloseModal);
  const keyFunction = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        handleCloseModal();
      }

      if (event.key === 'ArrowLeft' && index > 0) {
        setIndex && setIndex(index - 1);
      }
      if (event.key === 'ArrowRight' && index < sources.length - 1) {
        setIndex && setIndex(index + 1);
      }
    },
    [index] //eslint-disable-line
  );

  useEffect(() => {
    document.addEventListener('keydown', keyFunction, false);

    return () => {
      document.removeEventListener('keydown', keyFunction, false);
    };
  }, [index]); //eslint-disable-line

  const url = sources?.[index]?.srcSet || sources?.[index]?.src;
  return (
    <div
      className={cx({
        'lightbox-container': true,
        'lightbox-container-show': show
      })}
    >
      <div className="lightbox-container__wrapper">
        <AiOutlineLeft
          style={{ color: index === 0 ? 'darkgray' : 'white', fontSize: 20, marginLeft: 50 }}
        />
        <div className="lightbox-container__wrapper__content" ref={refModal}>
          {mediaChecker(url) === FORMAT_TYPE.VIDEO ? (
            // <div onClick={() => setPlay(!play)}>
            <Player
              key={`img-${new Date().getTime()}-${index}`}
              url={url}
              full={true}
              controls={true}
              playing={show}
              fallbackSrc={createAssetUrl(
                `fanclub_uploads/${url.split('/').pop().split('.')[0]}.mp4`,
                'full'
              )}
            />
          ) : (
            // </div>
            <div>
              <img src={url} alt={`img-${url}`} className="lightbox-container__image" />
            </div>
          )}
        </div>
        <AiOutlineRight
          style={{
            color: index > sources.length - 2 ? 'darkgray' : 'white',
            fontSize: 20,
            marginRight: 50
          }}
        />
      </div>
    </div>
  );
}

Lightbox.defaultProps = {
  onClose: () => {},
  show: false
};

export default Lightbox;
