import { Container } from '@components';
import communityOwnerImage from '@assets/img/patrons/about/become-patrons.png';
export default function BecomePatrons({ title, description, secondDescription }) {
  return (
    <div className="about-page-section-third bg-gray-75 w-full">
      <Container className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-1/2 px-4 lg:px-16 flex justify-center mt-4 lg:mt-0 items-start flex-col order-last lg:order-first">
          <div className="about-page-section-third-text">
            <div className="font-Satoshi font-bold text-3xl lg:text-4xl text-primary  mb-8">
              {title}
            </div>
            <p className="text-lg font-Satoshi text-secondary leading-8">{description}</p>
            <p className="text-lg font-Satoshi text-secondary leading-8 mt-4">
              {secondDescription}
            </p>
          </div>
        </div>
        <div className="w-full lg:w-1/2">
          <img src={communityOwnerImage} className="w-full h-fit" alt="community-owner" />
        </div>
      </Container>
    </div>
  );
}
