import { React } from 'react';
import FansincConfirmation from './fansinc';
import PatronsConfirmation from './patrons';

function Confirmation() {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsConfirmation />;
  } else {
    return <FansincConfirmation />;
  }
}
export default Confirmation;
