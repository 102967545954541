import { toast } from 'react-toastify';

// alternative to useToast for use outside hooks, styled for developer messages & only displayed in dev mode
function showDevelopmentToast(title, feature, message, type, theme) {
  if (process.env.NODE_ENV === 'development') {
    toast[type](
      <div style={{ maxWidth: 240, wordBreak: 'break-word' }}>
        <b>🔨 {title}</b>
        {feature !== '' && (
          <div style={{ border: '1px solid', borderRadius: 2, padding: 5, margin: '5px 0' }}>
            {feature}
          </div>
        )}
        {message}
      </div>,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme
      }
    );
  }
}

export default showDevelopmentToast;
