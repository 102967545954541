import { forwardRef } from 'react';
import cx from 'classnames';

const Checkbox = forwardRef(function CheckboxPlain(
  { className, children, disabled, errorMessage, helpMessage, onChange, ...props },
  ref
) {
  return (
    <>
      <div className={cx('form-checkbox', className)}>
        <label>
          <input disabled={disabled} type="checkbox" ref={ref} onChange={onChange} {...props} />
          {children}
          <span className="checkmark"></span>
        </label>
      </div>
      <div>
        {errorMessage && <span className="field-help field-help--error">{errorMessage}</span>}
        {helpMessage && <span className="field-help">{helpMessage}</span>}
      </div>
    </>
  );
});

export default Checkbox;
