import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Price,
  SkeletonLoading,
  Table,
  Button,
  Modal,
  VerticalTable,
  PrintInvoice
} from '@components';
import { useAuth } from '@libs/contexts/auth';
import { useOrderHistoryByUserID, useCancelOrderPrint } from '@libs/hooks/prodigi';

const tierMapper = {
  NUMBERED_EDITION: 'Limited Edition',
  DIGITAL_ORIGINAL: 'Digital Original'
};

const description = (history) => {
  return `Print collectible ${history?.exchange?.collectible?.title} (${
    tierMapper[history?.exchange?.tierType]
  } #${history?.exchange?.serialIndex}) by ${
    history?.exchange?.collectible?.creator?.displayName ||
    history?.exchange?.collectible?.creator?.firstName +
      ' ' +
      history?.exchange?.collectible?.creator?.lastName
  }`;
};

const OrderHistoryTable = () => {
  const { user } = useAuth();
  const {
    data: histories,
    loading,
    nextToken: nextOrderHistoryToken,
    getData: fetchOrderHistory
  } = useOrderHistoryByUserID(user?.id);
  const handleOrderHistoryLoadMore = () => {
    fetchOrderHistory(nextOrderHistoryToken);
  };
  const { loading: cancelLoading, onCancel } = useCancelOrderPrint();
  const [modalDetail, setModalDetail] = useState(false);
  const [history, setHistory] = useState([]);
  const [orderData, setOrderData] = useState();
  const [isCancelModalOpen, setIsCancelModalOpen] = useState();
  const { t } = useTranslation();

  const getHistoriesRows = ({ loading, histories, action }) => {
    const [historyRows, setHistoryRows] = useState([]);

    useEffect(() => {
      if (!loading && history) {
        let historyRows_ = histories.map((history, historyIndex) => [
          [
            <Box
              className="order-history-table-order"
              key={historyIndex}
              onClick={() => action(history)}
            >
              {history?.invoiceID}
            </Box>
          ],
          [format(new Date(history?.createdAt), 'd/MM/yyyy')],
          [
            <Box key={historyIndex} className="order-history-table-description">
              <Box>{description(history)}</Box>
              <Box className="order-history-table-description-payment">
                {t('components.orderHistoryTable.checkoutPayment')}
              </Box>
            </Box>
          ],
          [
            <Box key={historyIndex}>
              {history?.orderStatus === 'Complete' ? (
                <Box style={{ color: '#41D15A' }} className="order-history-table-status">
                  {history?.orderStatus}
                </Box>
              ) : (
                <Box className="order-history-table-status">{history?.orderStatus}</Box>
              )}
            </Box>
          ],
          [
            <Box key={historyIndex}>
              <Price key={history?.id} amount={history?.totalPrice} />
            </Box>
          ],
          [
            (history?.orderStatus === 'InProgress' ||
              history?.orderStatus === 'In Progress' ||
              history?.orderStatus === 'Preparing print asset' ||
              history?.orderStatus === 'Draft') && (
              <Box key={historyIndex}>
                <Box
                  style={{ color: '#FB5758' }}
                  className="cursor-pointer font-medium"
                  onClick={() => {
                    setIsCancelModalOpen(true);
                    setOrderData(history);
                  }}
                >
                  X {t('components.orderHistoryTable.cancelOrder')}
                </Box>
              </Box>
            )
          ]
        ]);
        setHistoryRows(historyRows_);
      }
    }, [loading, history]);

    return historyRows;
  };
  const getHistoriesCols = () => {
    const historiesCols = [
      ['#'],
      [t('components.orderHistoryTable.column.date')],
      [t('components.orderHistoryTable.column.description')],
      [<Box key="status">{t('components.orderHistoryTable.column.status')}</Box>],
      [<Box key="total">{t('components.orderHistoryTable.column.total')}</Box>],
      [t('components.orderHistoryTable.column.action')]
    ];
    return historiesCols;
  };

  const action = (i) => {
    setHistory(i);
    setModalDetail(true);
  };

  const historyRow = getHistoriesRows({
    histories,
    loading,
    action
  });

  return (
    <>
      <Table cols={getHistoriesCols()} rows={historyRow} className="order-history-table" />
      {loading && <LoadingOrderHistoryTable />}
      {nextOrderHistoryToken && (
        <Button onClick={() => handleOrderHistoryLoadMore()} className="mt-2">
          {t('components.orderHistoryTable.loadMore')}
        </Button>
      )}
      <ModalWalletHistoryTable
        isOpen={modalDetail}
        onClose={() => setModalDetail(false)}
        history={history}
      />
      <Modal
        isOpen={isCancelModalOpen}
        onClose={() => setIsCancelModalOpen(false)}
        className="orderPrint-modal"
        showOnlyCloseIcon
        closeIconSize={45}
        heading={t('components.orderHistoryTable.cancelOrder')}
      >
        <Trans i18nKey={'components.orderHistoryTable.cancelOrderDescription'}>
          {{ collectibleName: orderData?.exchange?.collectible?.title }}
          {{ tierType: tierMapper[orderData?.exchange?.tierType] }}
          {{ serialIndex: orderData?.exchange?.serialIndex }}
          {{
            creatorName:
              orderData?.exchange?.collectible?.creator?.displayName ||
              orderData?.exchange?.collectible?.creator?.firstName +
                ' ' +
                orderData?.exchange?.collectible?.creator?.lastName
          }}
        </Trans>
        <Box className="orderPrint-modal-button">
          <Button
            outline
            onClick={() => setIsCancelModalOpen(false)}
            className="orderPrint-modal-button-close"
          >
            {t('components.orderHistoryTable.button.close')}
          </Button>
          <Button
            loading={cancelLoading}
            onClick={async () => {
              await onCancel(orderData?.prodigiOrderID);
              setIsCancelModalOpen(false);
            }}
          >
            {t('components.orderHistoryTable.button.cancel')}
          </Button>
        </Box>
      </Modal>
    </>
  );
};

const ModalWalletHistoryTable = ({ isOpen, history, onClose }) => {
  const { t } = useTranslation();
  let modalAttribute = [
    [t('components.walletHistoryTable.modal.column.transactionId'), history?.invoiceID],
    [
      t('components.walletHistoryTable.modal.column.date'),
      history?.createdAt ? format(new Date(history?.createdAt), 'd/MM/yyyy') : null
    ],
    [t('components.walletHistoryTable.modal.column.description'), description(history)],
    [
      t('components.walletHistoryTable.modal.column.amount'),
      <Price key={history?.id} amount={history?.totalPrice} />
    ]
  ];
  return (
    <Modal showCloseIcon isOpen={isOpen} onClose={onClose} heading={`#${history?.invoiceID}`}>
      <VerticalTable rows={modalAttribute} />
      <Box className="wallet-history-table-modal-button">
        <PrintInvoice type="order_print" history={history}></PrintInvoice>
      </Box>
    </Modal>
  );
};

const LoadingOrderHistoryTable = () => (
  <div className="my-5">
    <SkeletonLoading />
    <div className="my-2" />
    <SkeletonLoading />
  </div>
);

export default OrderHistoryTable;
