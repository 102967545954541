import { Box, Button, Link, Text } from '@components';
import Lottie from 'react-lottie';
import errorData from '@lotties/Warning';

const Error = ({ title, description }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: errorData
  };
  return (
    <Box align="center">
      <Box className="w-32 h-32 md:w-40 md:h-40">
        <Lottie options={defaultOptions} />
      </Box>
      <Text className="text-center text-3xl md:text-4xl uppercase font-bold text-primary">
        {title}
      </Text>
      <Text className="text-base text-primary font-normal mt-4">{description}</Text>
      <Link to="/marketplace">
        <Button className="mt-6">View our marketplace</Button>
      </Link>
    </Box>
  );
};

export default Error;
