import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { VlagProvider } from '@vincere/react-vlag';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import '@/plugins/i18n';

import { Helmet, SuspenseLoading } from '@components';
import { AuthProvider } from '@libs/contexts/auth';
import { LocaleProvider } from '@libs/contexts/locale';
import { NotificationProvider } from '@libs/contexts/notification';
import PlayerProvider from '@libs/contexts/player';
import App from '@/App';
import config, { AWSConfig } from '@/config';
import '@/css/tailwind.css';

import reportWebVitals from '@/reportWebVitals';

Amplify.configure(AWSConfig);

if (process.env.REACT_APP_NAME === 'patrons') {
  require('@/css/patrons.css');
} else {
  require('@/css/fansinc.css');
}

const flags = [
  {
    id: 'ff_bitpay',
    name: 'Bitpay payment provider',
    description: 'Enable bitpay payment at checkout',
    helperText: '',
    disabled: false
  }
];

Sentry.init({
  dsn: config.sentryDSN,
  integrations: [new BrowserTracing()]
});

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <Helmet />
      <Router>
        <LocaleProvider>
          <AuthProvider>
            <NotificationProvider>
              <PlayerProvider>
                <Suspense fallback={<SuspenseLoading />}>
                  <VlagProvider flags={flags}>
                    <App />
                  </VlagProvider>
                </Suspense>
              </PlayerProvider>
            </NotificationProvider>
          </AuthProvider>
        </LocaleProvider>
      </Router>
      <ToastContainer closeOnClick autoClose={9000} />
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
