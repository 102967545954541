import Creatable from 'react-select/creatable';

const CreatableSelect = ({
  label,
  onCreate,
  isLoading,
  onInputChange,
  onChange,
  options,
  value,
  disabled
}) => {
  return (
    <>
      {label && (
        <label className="block mb-1 text-gray-700" htmlFor="forms-validationInputCode_error">
          {label}
        </label>
      )}
      <Creatable
        isMulti
        cacheOptions
        onCreateOption={onCreate}
        isLoading={isLoading}
        onInputChange={onInputChange}
        onChange={onChange}
        options={options}
        value={value}
        isDisabled={disabled}
        className="fans-creatable"
        classNamePrefix="fans"
      />
    </>
  );
};

CreatableSelect.defaultProps = {
  onCreateOption: () => {},
  disabled: false
};

export default CreatableSelect;
