import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import gql from '@libs/utils/gql';
import useToast from '@libs/utils/toast';
import { customUpdateListing } from '@graphql/mutations';

export const useUpdateListing = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const [updateLoading, setUpdateLoading] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const onUpdateListing = useCallback(async (data, cb) => {
    setUpdateLoading(true);
    try {
      const payload = {
        input: {
          exchangeID: data.exchangeID,
          listingType: data.listingType,
          listingPrice: data.listingPrice || null,
          minimumOfferPrice: data.minimumOfferPrice || null,
          isMinimumOfferVisible: data.isMinimumOfferVisible || 'FALSE',
          isAutoAcceptActive: data.isAutoAcceptActive || 'FALSE',
          autoAcceptOfferPrice: data.autoAcceptOfferPrice || null
        }
      };
      await gql(customUpdateListing, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      setUpdateSuccess(true);
      cb && cb();
      toast(t('manageListing.updateSuccess'), 'success');
    } catch (error) {
      const errorMessage = error?.errors[0]?.message;
      toast(errorMessage, 'error');

      console.error('error update user:', error);
    } finally {
      setUpdateLoading(false);
    }
  }, []);

  return {
    updateLoading,
    updateSuccess,
    setUpdateSuccess,
    onUpdateListing
  };
};
