export const searchCollections = /* GraphQL */ `
  query SearchCollections(
    $filter: SearchableCollectionsFilterInput
    $sort: SearchableCollectionsSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCollections(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        collectionType
        avatarUrl
        coverUrl
        logoUrl
        createdAt
        searchableCollectionType
        slug
        collectionUrl
        shortName
        isShowLogo
      }
      nextToken
      total
    }
  }
`;

export const getCollectionStash = /* GraphQL */ `
  query GetCollectionStash($input: collectionStashInput!) {
    getCollectionStash(input: $input)
  }
`;
