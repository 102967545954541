import { userPublicFragment } from '@libs/fragments/user';
import { collectibleCardFragment } from '@libs/fragments/collectible';

export const collectibleLikeByUserAndCollectible = /* GraphQL */ `
  query CollectibleLikeByUserAndCollectible(
    $userID: ID
    $collectibleID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCollectibleLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    collectibleLikeByUserAndCollectible(
      userID: $userID
      collectibleID: $collectibleID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        onVisit
        collectibleID
        createdAt
        updatedAt
        collectible {
          ...CollectibleCardPublic
          creator {
            ...UserPublicProfile
          }
        }
        owner
        user {
          ...UserPublicProfile
        }
      }
      nextToken
      startedAt
    }
  }

  ${collectibleCardFragment}
  ${userPublicFragment}
`;
