import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { LISTING_TYPES } from '@libs/utils/exchange';
import { urlParser } from '@pages/profile/utils';
import { Link, SerialItem, CollectibleTooltip, Box } from '@components';
import { ReactComponent as OpenSeaIcon } from '@assets/simple-icons_opensea.svg';

const Opensea = ({ unit }) => {
  const { t } = useTranslation();
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <div key={unit.id} style={{ display: 'flex', alignItems: 'center' }}>
      <Link key={unit.id} to={urlParser(unit, 'release')}>
        <SerialItem
          listing={unit}
          key={unit.id}
          badge={unit.tierType === 'NUMBERED_EDITION' ? false : true}
        />
      </Link>
      {unit.currentListingType === LISTING_TYPES.NFT && (
        <OpenSeaIcon
          data-tip
          data-for="minimumTooltip"
          key={unit.id}
          width={16}
          height={16}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        />
      )}
      <ReactTooltip
        className="listing-table__opensea-tooltip "
        id="minimumTooltip"
        place="right"
        type="dark"
      >
        <Box className="capitalize">{t('components.listingTable.minted')}</Box>
      </ReactTooltip>
    </div>
  );
};

export default Opensea;
