import { useTranslation } from 'react-i18next';
import { Box, Logo } from '@components';

function Landing() {
  const { t } = useTranslation();
  return (
    <>
      <Box className="landing">
        <Box className="landing__content">
          <Box className="landing__content__logo">
            <Logo height="36" />
          </Box>
          <Box className="landing__content__success">
            <Box>{t('p.home.meta.waitlist')}</Box>
          </Box>
          <Box style={{ maxWidth: '448px' }} className="text-lg mt-5">
            {t('p.home.meta.subDescription')}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Landing;
