import FansincAlert from './fansinc';
import PatronsAlert from './patrons';

const Alert = ({ info, success, danger, warning, heading, action, className, children }) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return (
      <PatronsAlert
        info={info}
        success={success}
        danger={danger}
        warning={warning}
        heading={heading}
        action={action}
        className={className}
      >
        {children}
      </PatronsAlert>
    );
  } else {
    return (
      <FansincAlert
        info={info}
        success={success}
        danger={danger}
        warning={warning}
        heading={heading}
        action={action}
        className={className}
      >
        {children}
      </FansincAlert>
    );
  }
};

export default Alert;
