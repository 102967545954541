import cx from 'classnames';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation, Trans } from 'react-i18next';
import { IoCloseSharp } from 'react-icons/io5';
import { BiChevronRight } from 'react-icons/bi';
import { Box, Container, Link, Logo, Text, Button } from '@components';
import { useAuth } from '@libs/contexts/auth';
import config from '@/config';
import { ToggleButton } from '@components/index';
import { Disclosure } from '@headlessui/react';

const Footer = ({ className, ...props }) => {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    config.cookie.SANDBOX_NOTIFICATION,
    config.cookie.ALLOW_COOKIES,
    config.cookie.NECESSARY_COOKIES,
    config.cookie.ANALYTICS_COOKIES,
    config.cookie.TARGETING_COOKIES
  ]);

  const [sandboxNotif, setSandboxNotif] = useState(true);
  const [allowCookies, setAllowCookies] = useState(false);
  const [analyticsCookies, setAnalyticsCookies] = useState(
    cookies[config.cookie.ANALYTICS_COOKIES] === 'true' ? true : false
  );
  const [targetingCookies, setTargetingCookies] = useState(
    cookies[config.cookie.TARGETING_COOKIES] === 'true' ? true : false
  );

  const { isAuthenticated } = useAuth();

  const socialMedia = {
    facebook: config.facebookUrl,
    instagram: config.instagramUrl,
    twitter: config.twitterUrl,
    youtube: config.youtube
  };

  const NEXT_MONTH = 3600 * 24 * 30;
  const cookieOpts = {
    maxAge: NEXT_MONTH
  };

  useEffect(() => {
    if (isAuthenticated) {
      if (typeof cookies[config.cookie.SANDBOX_NOTIFICATION] === 'undefined') {
        setCookie(config.cookie.SANDBOX_NOTIFICATION, true);
      } else {
        setSandboxNotif(cookies[config.cookie.SANDBOX_NOTIFICATION] === 'true');
      }
    }

    if (typeof cookies[config.cookie.ALLOW_COOKIES] === 'undefined') {
      setCookie(config.cookie.ALLOW_COOKIES, false, cookieOpts);
      setCookie(config.cookie.NECESSARY_COOKIES, true, cookieOpts);
      setCookie(config.cookie.ANALYTICS_COOKIES, false, cookieOpts);
      setCookie(config.cookie.TARGETING_COOKIES, false, cookieOpts);
    } else {
      setAllowCookies(cookies[config.cookie.ALLOW_COOKIES] === 'true');
      setCookie(config.cookie.NECESSARY_COOKIES, true, cookieOpts);
      setCookie(
        config.cookie.ANALYTICS_COOKIES,
        cookies[config.cookie.ANALYTICS_COOKIES],
        cookieOpts
      );
      setCookie(
        config.cookie.TARGETING_COOKIES,
        cookies[config.cookie.TARGETING_COOKIES],
        cookieOpts
      );
    }
  }, [isAuthenticated]);

  const handleSubmit = () => {
    setAllowCookies(true);
    setCookie(config.cookie.ALLOW_COOKIES, true, cookieOpts);
    setCookie(config.cookie.NECESSARY_COOKIES, true, cookieOpts);
    setCookie(config.cookie.ANALYTICS_COOKIES, analyticsCookies, cookieOpts);
    setCookie(config.cookie.TARGETING_COOKIES, targetingCookies, cookieOpts);
  };

  return (
    <>
      <footer className={cx('footer', className)} {...props}>
        <Container>
          <div className="footer__wrapper">
            <Box className="footer__left">
              <Link to="/marketplace" target="_blank" className="footer__logo">
                <Logo width="240" height="40" footer={true} />
              </Link>
              <Text className="text-white text-lg">
                <Trans i18nKey="p.components.footer.copyright">
                  {{ year: new Date().getFullYear() }}
                </Trans>
              </Text>
            </Box>
            <Box className="footer__right">
              <ul className="footer__menu">
                {/*
              <li>
                <Link to="/about" className="footer__link">
                  {t('components.footer.menu.about')}
                </Link>
              </li>
              */}
                <li>
                  <Link to="/faq" className="footer__link">
                    {t('p.components.footer.menu.faq')}
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="footer__link">
                    {t('p.components.footer.menu.contact')}
                  </Link>
                </li>
                <li>
                  <Link to="/terms-and-conditions" className="footer__link">
                    {t('p.components.footer.menu.termsAndConditions')}
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy" className="footer__link">
                    {t('p.components.footer.menu.privacyPolicy')}
                  </Link>
                </li>
                {/* <li>
                  <Link target="_blank" to={'https://support.fans.inc'} className="footer__link">
                    {t('p.components.footer.menu.helpCenter')}
                  </Link>
                </li> */}
              </ul>
            </Box>
          </div>
        </Container>
      </footer>
      {process.env.REACT_APP_AMPLIFY_ENV === 'sandbox' && isAuthenticated && sandboxNotif && (
        <Box className="footer-sandbox">
          <Container>
            <span>{t('p.components.footer.sandboxInformation')}</span>
            <Box
              className="w-5"
              onClick={() => {
                setSandboxNotif(false);
                setCookie(config.cookie.SANDBOX_NOTIFICATION, false);
              }}
            >
              <IoCloseSharp size={18} />
            </Box>
          </Container>
        </Box>
      )}
      {!allowCookies && (
        <Box style={{ zIndex: '100' }} className="fixed right-7 bottom-8 bg-white">
          <Box className="text-primary py-7 max-w-xl px-7">
            <Box className="font-bold text-xl text-primary mb-2">
              {t('p.components.footer.cookies.title')}
            </Box>
            <Box className="text-justify">{t('p.components.footer.cookies.description')}</Box>
            <Box className="flex flex-col">
              <Box className="relative mt-7">
                <Box className="absolute top-0 right-0">
                  <span className="text-base font-bold text-red-toggle">
                    {t('p.components.footer.cookies.alwaysActive')}
                  </span>
                </Box>
                <Box className="flex flex-col justify-start items-start">
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="inline-flex justify-center items-center">
                          <BiChevronRight
                            className={open ? 'rotate-90 transform w-5 h-5 mr-2' : 'w-5 h-5 mr-2'}
                          />
                          <span className="text-base font-bold">
                            {t('p.components.footer.cookies.necessaryCookies.title')}
                          </span>
                        </Disclosure.Button>
                        <Disclosure.Panel className="text-justify my-2 ml-7">
                          {t('p.components.footer.cookies.necessaryCookies.description')}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </Box>
              </Box>
              <Box className="relative mt-7">
                <Box className="absolute top-0 right-0 mt-1">
                  <ToggleButton
                    status={analyticsCookies}
                    onClick={() => setAnalyticsCookies(!analyticsCookies)}
                  />
                </Box>
                <Box className="flex flex-col justify-start items-start">
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="inline-flex justify-center items-center">
                          <BiChevronRight
                            className={open ? 'rotate-90 transform w-5 h-5 mr-2' : 'w-5 h-5 mr-2'}
                          />
                          <span className="text-base font-bold">
                            {t('p.components.footer.cookies.analyticsCookies.title')}
                          </span>
                        </Disclosure.Button>
                        <Disclosure.Panel className="text-justify my-2 ml-7">
                          {t('p.components.footer.cookies.analyticsCookies.description')}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </Box>
              </Box>

              <Box className="relative mt-7">
                <Box className="absolute top-0 right-0 mt-1">
                  <ToggleButton
                    status={targetingCookies}
                    onClick={() => setTargetingCookies(!targetingCookies)}
                  />
                </Box>
                <Box className="flex flex-col justify-start items-start">
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="inline-flex justify-center items-center">
                          <BiChevronRight
                            className={open ? 'rotate-90 transform w-5 h-5 mr-2' : 'w-5 h-5 mr-2'}
                          />
                          <span className="text-base font-bold">
                            {t('p.components.footer.cookies.targetingCookies.title')}
                          </span>
                        </Disclosure.Button>
                        <Disclosure.Panel className="text-justify my-2 ml-7">
                          {t('p.components.footer.cookies.targetingCookies.description')}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </Box>
              </Box>
            </Box>
            <Box className="flex flex-col mt-7">
              <Button onClick={handleSubmit}>
                <span className="uppercase">{t('p.components.footer.cookies.allowSelection')}</span>
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Footer;
