import { useState } from 'react';
import { Avatar, Box, CollectibleTooltip, Link, Text, NFTAddress } from '@components';
import { getUserName } from '@libs/utils/user';

const UserLink = ({
  user,
  children,
  showName,
  showTooltip,
  exchange,
  avatarSize = 'xs',
  showAddress,
  ...props
}) => {
  const [tooltip, setTooltip] = useState(false);
  if (user == null) {
    return <Avatar size={avatarSize} />;
  }
  const { username, firstName, lastName } = user;
  return (
    <Link className="user-link" to={`/${username}`}>
      <Avatar
        size={avatarSize}
        user={user}
        onMouseEnter={() => showTooltip && setTooltip(true)}
        onMouseLeave={() => setTooltip(false)}
      />
      {showName && (
        <Box className="user-link__name">
          <Text as="span">
            {getUserName(firstName)} {lastName}
          </Text>
          {showAddress && <NFTAddress exchange={exchange} className="mt-px" />}
        </Box>
      )}
      {showTooltip && (
        <CollectibleTooltip capitalize onShow={tooltip} className="user-link__tooltip">
          {firstName} {lastName}
        </CollectibleTooltip>
      )}
    </Link>
  );
};

UserLink.defaultProps = {
  showName: true,
  showTooltip: true
};

export default UserLink;
