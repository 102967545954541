import QRCode from 'qrcode';

export const generateQR = async (text) => {
  try {
    const qrCodeRes = await QRCode.toDataURL(text);
    return qrCodeRes;
  } catch (err) {
    console.error(err);
    return err;
  }
};
