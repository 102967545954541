import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import React, { useState, useCallback, useEffect } from 'react';
import cx from 'classnames';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { Vlag } from '@vincere/react-vlag';

import {
  Alert,
  Avatar,
  Box,
  Button,
  Col,
  Container,
  FormField,
  Input,
  Grid,
  RadioButton,
  ReleaseCheckout,
  Separator,
  SkeletonLoading,
  Text,
  OrderSummary,
  Select,
  Price,
  Tabs,
  TabItem,
  CollectibleOwnerActions
} from '@components';
import { useAuth } from '@libs/contexts/auth';
import { useLocale } from '@libs/contexts/locale';

import { useUser, useUserByUsername } from '@libs/hooks/user';
import { useExchangeByExchangeIDCheckout } from '@libs/hooks/exchange';
import useBitPayCheckout from '@libs/hooks/bitpay';
import { useCollectible } from '@libs/hooks/collectible';

import { EXCHANGE_INTENTS, mapListingTypeToExchangeIntent } from '@libs/utils/exchange';
import { getBidsFromExchange, MINIMUM_BID_AMOUNT } from '@libs/utils/checkout';
import useQuery from '@libs/utils/query';

import NotFound from '@pages/not-found';
import BitpayAcceptedImage from '@assets/img/bitpay-accepted.png';
import CCAcceptedImage from '@assets/img/cc-accepted.png';
import { ReactComponent as BitPay } from '@assets/svg/bitpay.svg';
import { ReactComponent as CreditCard } from '@assets/svg/credit-card.svg';
import { useTierTilesData } from '@pages/collectible/fansinc/hooks';
import { buttonText, summaryTitle, getCollectibleDetailsFromExchange, termsLabel } from './utils';
import CheckoutFrame from '../components/fansinc/checkout-frame';
import BitpayFrame from '../components/fansinc/bitpay-frame';
import { useCheckoutInputData } from './hooks';

const offerExpiry = [{ value: '7', label: '7 days' }];

function OrderSummarySwitch({ exchange, exchangeIntent, customPrice = 0 }) {
  switch (exchangeIntent) {
    case EXCHANGE_INTENTS.FIXED_PRICE_PURCHASE:
      return (
        <>
          <OrderSummary price={exchange?.currentPrice ?? 0} />
        </>
      );
    case EXCHANGE_INTENTS.MAKE_OFFER:
    case EXCHANGE_INTENTS.PLACE_BID:
      return (
        <>
          {customPrice < 1 ? null : (
            <>
              <OrderSummary price={customPrice} />
            </>
          )}
        </>
      );
    default:
      return <OrderSummary price={exchange?.currentPrice ?? 0} />;
  }
}

const Checkout = () => {
  const params = useParams();
  const history = useHistory();
  const query = useQuery();
  const edition = params.edition;
  const username = params.username?.toLowerCase();
  const slug = params.slug;
  const type = query.get('type');

  const { t } = useTranslation();
  const {
    user: auth,
    authenticate,
    error: errorAuth,
    loading: authLoading,
    isAuthenticated
  } = useAuth();
  const { data: collectible, loading: collectibleLoading } = useCollectible(slug);
  const { data: exchange, loading: exchangeLoading } = useExchangeByExchangeIDCheckout(
    edition,
    slug,
    !collectibleLoading && !collectible ? '404' : collectible
  );

  const [exchangeIntent, setSelectedExchangeIntent] = useState(null);
  const [selectedBidExchange, setSelectedCurrentBidExchange] = useState(null);
  const { roundPrice, currencyCode, currencyFormatter, calculateTax } = useLocale();
  const { onCheckEmail, emailAvailability, availabilityLoading } = useUser();
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const {
    tierTilesData,
    isLoading: isLoadingTierTilesData,
    hasError: tierTilesDataHasError,
    getData: refetchTierTiles
  } = useTierTilesData(collectible);

  const { currentBid, nextBid } = getBidsFromExchange(tierTilesData?.primaryMarket[0]?.currentBid);

  useEffect(() => {
    if (tierTilesData?.primaryMarket[0]?.currentBid) {
      setSelectedCurrentBidExchange(tierTilesData?.primaryMarket[0]?.currentBid);
    }
  }, [tierTilesData]);

  const [bitpayIframeURL, setBitPayIFrameURL] = useState();
  const {
    register,
    watch,
    formState: { errors },
    reset,
    trigger,
    setFocus
  } = useForm({
    mode: 'onBlur',
    shouldFocusError: false
  });

  const firstName = watch('firstName');
  const lastName = watch('lastName');
  const email = watch('email');
  const password = watch('password');

  const { onChange: onEmailChange, ...rest } = register('email', {
    required: {
      value: true,
      message: t('checkout.fields.email.required')
    },
    pattern: {
      value: /^\S+@\S+$/i,
      message: t('checkout.fields.email.invalid')
    }
  });

  // eslint-disable-next-line
  const handleEmailChange = useCallback(
    debounce((e) => {
      onEmailChange(e);
      onCheckEmail(e.target.value);
    }, 1000),
    []
  );

  const { loading: bitpayLoading, onPurchase, onMakeOffer } = useBitPayCheckout();

  const [paymentMethod, setPaymentMethod] = useState(
    process.env.REACT_APP_AMPLIFY_ENV !== 'prod' ? 'CREDIT_CARD' : null
  );

  const { customPrice, setCustomPrice, checkoutInputData, updateCheckoutInputData } =
    useCheckoutInputData({
      email,
      firstName,
      lastName,
      password,
      exchange,
      currentBidExchange: selectedBidExchange,
      selectedExchangeIntent: exchangeIntent,
      paymentMethod
    });
  // const exchanges = exchange ? resetStartingPrice() : null;

  const handleLogin = useCallback(async () => {
    await authenticate({ email, password });
    reset({ email: '', password: '', firstname: '', lastName: '' });
    updateCheckoutInputData();
  }, [email, password]); // eslint-disable-line

  useEffect(() => {
    if (exchange) {
      const isSecondaryMarket = exchange?.creator?.id !== exchange?.currentOwnerID;
      if (isSecondaryMarket && type === 'MAKE_AN_OFFER' && exchange.listingType === 'FIXED_PRICE') {
        setSelectedExchangeIntent(mapListingTypeToExchangeIntent['MAKE_AN_OFFER']);
      } else {
        setSelectedExchangeIntent(mapListingTypeToExchangeIntent[exchange.listingType]);
      }
    }
  }, [exchange]);

  useEffect(() => {
    if (!isAuthenticated && !exchangeLoading) {
      trigger('email');
    }
  }, [isAuthenticated, exchangeLoading]);

  const validateForm = useCallback(async () => {
    if (!auth) {
      if (!emailAvailability) {
        trigger('firstName');
        trigger('lastName');
      }
    }
    if (exchangeIntent === EXCHANGE_INTENTS.PLACE_BID) {
      trigger('maximumBid');
    }
    if (exchangeIntent === EXCHANGE_INTENTS.MAKE_OFFER) {
      trigger('offerAmount');
    }
    for (const error in errors) {
      if (['firstName', 'email', 'lastName', 'maximumBid', 'offerAmount'].includes(error)) {
        setFocus(error);
        return;
      }
    }

    if (paymentMethod === 'CREDIT_CARD') {
      setIsSubmittingForm(true);
    } else {
      if (exchangeIntent === EXCHANGE_INTENTS.FIXED_PRICE_PURCHASE) {
        const purchase = await onPurchase({
          exchangeID: exchange?.id,
          currencyCode: 'USD',
          token: 'xxx-bitpay',
          email,
          firstName,
          lastName,
          paymentMethod
        });

        if (!purchase?.url) {
          // todo: handle error
          return;
        }

        setBitPayIFrameURL(purchase.url);
      }

      if (exchangeIntent === EXCHANGE_INTENTS.MAKE_OFFER) {
        const makeOffer = await onMakeOffer({
          exchangeID: exchange?.id,
          currencyCode: 'USD',
          token: 'xxx-bitpay',
          email,
          firstName,
          lastName,
          customPrice,
          paymentMethod
        });

        if (!makeOffer?.url) {
          // todo: handle error
          return;
        }

        const url = new URL(makeOffer.url);
        if (url.search) history.replace(url.pathname + url.search);
      }
    }
    // eslint-disable-next-line
  }, [
    exchange,
    email,
    firstName,
    lastName,
    customPrice,
    paymentMethod,
    exchangeIntent,
    emailAvailability
  ]);

  const [dataError, setDataError] = useState(false);
  useEffect(() => {
    if (typeof exchange?.collectible?.title === 'undefined' && !exchangeLoading) {
      setDataError(true);
    }
    if (exchange?.currentOwnerID === auth?.id && !exchangeLoading) {
      setDataError(true);
    }
  }, [exchange, exchangeLoading, auth]);

  useEffect(() => {
    if (exchange) {
      if (exchangeIntent === EXCHANGE_INTENTS.MAKE_OFFER) {
        setCustomPrice(roundPrice(exchange?.offerPrice ?? 0));
      } else if (exchangeIntent === EXCHANGE_INTENTS.PLACE_BID) {
        setCustomPrice(roundPrice(nextBid ?? MINIMUM_BID_AMOUNT ?? 0));
      } else {
        setCustomPrice(roundPrice(exchange?.currentPrice ?? 0));
      }
      updateCheckoutInputData();
    }
  }, [exchangeIntent, exchange]);

  if (dataError) {
    return <NotFound />;
  }

  return (
    <>
      <Box className="checkout" id="checkout-modal">
        <form
          noValidate
          className={`checkout__form ${isSubmittingForm ? 'checkout__form__disabled' : ''}`}
        >
          <Container>
            <Grid className="checkout__grid" id="checkout">
              <Box className="checkout__left checkout__form">
                <Box className="checkout__owner">
                  <Text className="checkout__owner-title">{t('checkout.ownerDetails')}</Text>
                  {isAuthenticated ? (
                    auth ? (
                      <Box className="checkout__owner-detail">
                        <Avatar user={auth} size="sm" className="checkout__owner-detail__avatar" />
                        <Box className="checkout__owner-detail__info">
                          <Text className="checkout__owner-detail__info-name">
                            {auth?.firstName} {auth?.lastName}{' '}
                          </Text>
                          <Text className="checkout__owner-detail__info-link">
                            fans.inc/{auth?.username}
                          </Text>
                        </Box>
                      </Box>
                    ) : (
                      <SkeletonLoading className="w-full h-24 mb-4" />
                    )
                  ) : (
                    <>
                      <Text className="mb-4">{t('checkout.ownerDetailsDescription')}</Text>
                      <FormField>
                        <Input
                          loading
                          as="email"
                          fullwidth
                          label={t('checkout.fields.email.label')}
                          helpMessage={
                            availabilityLoading ? t('checkout.fields.email.loading') : ''
                          }
                          errorMessage={errors.email?.message}
                          {...rest}
                          onChange={handleEmailChange}
                        />
                      </FormField>
                      {!emailAvailability ? (
                        <Grid className="checkout__form__column-2">
                          <Col>
                            <FormField>
                              <Input
                                as="text"
                                {...register('firstName', {
                                  required: {
                                    value: true,
                                    message: t('checkout.fields.firstName.required')
                                  }
                                })}
                                errorMessage={errors.firstName?.message}
                                label={t('checkout.fields.firstName.label')}
                                onBlur={() => updateCheckoutInputData()}
                              />
                            </FormField>
                          </Col>
                          <Col>
                            <FormField>
                              <Input
                                as="text"
                                {...register('lastName', {
                                  required: {
                                    value: true,
                                    message: t('checkout.fields.lastName.required')
                                  }
                                })}
                                errorMessage={errors.lastName?.message}
                                label={t('checkout.fields.lastName.label')}
                                onBlur={() => updateCheckoutInputData()}
                              />
                            </FormField>
                          </Col>
                        </Grid>
                      ) : (
                        <>
                          <FormField>
                            <Input
                              as="password"
                              fullwidth
                              label={t('checkout.fields.password.label')}
                              errorMessage={errors.password?.message}
                              {...register('password', {
                                required: {
                                  value: true,
                                  message: t('checkout.fields.password.required')
                                }
                              })}
                              onBlur={() => updateCheckoutInputData()}
                            />
                          </FormField>
                          {errorAuth && (
                            <Alert danger className="mb-4">
                              {errorAuth}
                            </Alert>
                          )}
                          <Button
                            fullWidth
                            loading={authLoading}
                            className="mr-3"
                            onClick={handleLogin}
                          >
                            {t('checkout.submit.login')}
                          </Button>
                        </>
                      )}
                    </>
                  )}
                  <Separator />
                  {exchangeIntent === EXCHANGE_INTENTS.MAKE_OFFER && exchange ? (
                    <>
                      <Box className="checkout__offer">
                        <Text className="checkout__offer-title">{t('checkout.offerDetails')}</Text>
                        <FormField>
                          <Input
                            pattern="^\d*(\.\d{0,2})?$"
                            as="number"
                            {...register('offerAmount', {
                              required: {
                                value: true,
                                message: t('checkout.fields.offerAmount.required')
                              },
                              min: {
                                value: roundPrice(exchange?.offerPrice ?? 0),
                                message: (
                                  <>
                                    {t('checkout.fields.offerAmount.minValue')}
                                    <Price amount={exchange?.offerPrice} inline />
                                  </>
                                )
                              }
                            })}
                            defaultValue={roundPrice(exchange?.offerPrice ?? 0)}
                            errorMessage={errors.offerAmount?.message}
                            helpMessage={t('checkout.fields.offerAmount.helpMessage')}
                            placeholder={t('checkout.fields.offerAmount.placeholder')}
                            label={t('checkout.fields.offerAmount.label')}
                            labelRight={currencyCode}
                            value={customPrice}
                            onChange={(e) => setCustomPrice(e.target.value)}
                            onBlur={(e) => {
                              setCustomPrice(roundPrice(e.target.value));
                              updateCheckoutInputData();
                            }}
                          />
                        </FormField>
                        <FormField className="hidden">
                          <Select
                            fullWidth
                            options={offerExpiry}
                            {...register('month')}
                            label={t('checkout.fields.offerExpiry.label')}
                          />
                        </FormField>
                      </Box>
                      <Separator />
                    </>
                  ) : (
                    <></>
                  )}

                  {exchangeIntent === EXCHANGE_INTENTS.PLACE_BID ? (
                    <>
                      <Box className="checkout__bid">
                        <Text className="checkout__bid-title">{t('checkout.bidDetails')}</Text>
                        <Text className="mb-4">{t('checkout.bidDescription')}</Text>
                        {currentBid && (
                          <Text className="checkout__bid-subtitle inline-block">
                            {t(`checkout.currentBid`)}
                            <Price amount={currentBid} inline />
                            {exchange?.sellingPrice && (
                              <Text className="checkout__bid-subtitle__subtle inline-block ml-2">
                                (
                                {currentBid >= parseFloat(exchange?.sellingPrice)
                                  ? t(`checkout.reservePriceMet`)
                                  : t(`checkout.reservePriceNotMet`)}
                                )
                              </Text>
                            )}
                          </Text>
                        )}
                        <FormField>
                          <Input
                            pattern="^\d*(\.\d{0,2})?$"
                            as="number"
                            {...register('maximumBid', {
                              required: {
                                value: true,
                                message: t('checkout.fields.maximumBid.required')
                              },
                              min: {
                                value: roundPrice(nextBid ?? MINIMUM_BID_AMOUNT),
                                message: (
                                  <>
                                    {t('checkout.fields.maximumBid.minValue')}
                                    <Price amount={nextBid ?? MINIMUM_BID_AMOUNT} inline />
                                  </>
                                )
                              }
                            })}
                            defaultValue={roundPrice(nextBid ?? MINIMUM_BID_AMOUNT)}
                            errorMessage={errors.maximumBid?.message}
                            helpMessage={
                              <>
                                {t(
                                  `checkout.fields.maximumBid.helper${
                                    selectedBidExchange?.previousPrice ? 'MinimumBid' : 'Starting'
                                  }`
                                )}
                                <Price amount={nextBid ?? MINIMUM_BID_AMOUNT} inline />
                              </>
                            }
                            placeholder={t('checkout.fields.maximumBid.placeholder')}
                            label={t('checkout.fields.maximumBid.label')}
                            labelRight={currencyCode}
                            value={customPrice}
                            onChange={(e) => setCustomPrice(e.target.value)}
                            onBlur={(e) => {
                              setCustomPrice(roundPrice(e.target.value));
                              updateCheckoutInputData();
                            }}
                            showBothErrorAndHelp={false}
                            helpMessageMargin
                          />
                        </FormField>
                      </Box>
                      <Separator />
                    </>
                  ) : null}
                  <Box className="py-4 uppercase">
                    <Text>{t('checkout.selectPaymentMethod')}</Text>
                  </Box>
                  {exchangeLoading ? (
                    <>
                      <Vlag name="ff_bitpay">
                        {(isActive) =>
                          isActive ? (
                            <>
                              <SkeletonLoading className="h-20 w-full mb-4" />
                              <SkeletonLoading className="h-20 w-full" />
                            </>
                          ) : (
                            <SkeletonLoading className="h-20 w-full" />
                          )
                        }
                      </Vlag>
                    </>
                  ) : (
                    <>
                      <Box
                        className={cx(
                          'checkout__method',
                          paymentMethod === 'CREDIT_CARD' ? 'checkout__method-active' : ''
                        )}
                      >
                        <FormField className="m-0">
                          <RadioButton
                            className="checkout__form__radio"
                            name="paymentMethod"
                            value="CREDIT_CARD"
                            isChecked={paymentMethod === 'CREDIT_CARD'}
                            onChange={(e) => setPaymentMethod(e.target.value)}
                          >
                            <Box className="checkout__radio-icon">
                              <Box className="checkout__radio_label">
                                <CreditCard />
                                Credit Card
                              </Box>
                              <Box>
                                <img src={CCAcceptedImage} alt="Credit Card Accepted" />
                              </Box>
                            </Box>
                          </RadioButton>
                        </FormField>
                        {paymentMethod === 'CREDIT_CARD' ? (
                          <Box className="checkout__card">
                            {exchangeIntent === EXCHANGE_INTENTS.PLACE_BID ? (
                              <Text className="mt-4">{t('checkout.cardDetailsAuction')}</Text>
                            ) : exchangeIntent === EXCHANGE_INTENTS.MAKE_OFFER ? (
                              <Text className="mt-4">{t('checkout.cardDetailsMakeAnOffer')}</Text>
                            ) : null}
                            {exchange && (
                              <CheckoutFrame
                                exchange={exchange}
                                exchangeIntent={exchangeIntent}
                                submitForm={isSubmittingForm}
                                setSubmitForm={setIsSubmittingForm}
                                inputData={checkoutInputData}
                              />
                            )}
                          </Box>
                        ) : null}
                      </Box>
                      {['FIXED_PRICE', 'MAKE_AN_OFFER'].includes(exchange?.currentListingType) ? (
                        <Vlag name="ff_bitpay">
                          {(isActive) =>
                            isActive || process.env.REACT_APP_AMPLIFY_ENV === 'sandbox' ? (
                              <Box
                                className={cx(
                                  'checkout__method',
                                  paymentMethod === 'BITPAY' ? 'checkout__method-active' : ''
                                )}
                              >
                                <FormField className="mb-0">
                                  <RadioButton
                                    className="checkout__form__radio"
                                    name="paymentMethod"
                                    value="BITPAY"
                                    isChecked={paymentMethod === 'BITPAY'}
                                    onChange={(e) => setPaymentMethod(e.target.value)}
                                  >
                                    <Box className="checkout__radio-icon">
                                      <Box className="checkout__radio_label">
                                        <BitPay />
                                        Cryptocurrency
                                      </Box>
                                      <Box>
                                        <img
                                          src={BitpayAcceptedImage}
                                          alt="Bitpay Accepted Payment"
                                        />
                                      </Box>
                                    </Box>
                                  </RadioButton>
                                </FormField>
                              </Box>
                            ) : null
                          }
                        </Vlag>
                      ) : null}
                    </>
                  )}
                </Box>
              </Box>
              <Box className="checkout__right">
                {exchangeLoading ? (
                  <SkeletonLoading className="w-full h-20" />
                ) : (
                  <ReleaseCheckout
                    exchange={exchange}
                    editionBadgeProps={{ square: true, size: 'base' }}
                    headingRight
                  />
                )}
                <Tabs className="mt-5" classNameChild="checkout__tabs" full>
                  <TabItem label={t('checkout.whatToDoWithIt')}>
                    <Box className="checkout__actions">
                      <CollectibleOwnerActions
                        isCheckoutModal
                        showDescription
                        isRelease={false}
                        exchange={exchange}
                        collectible={exchange.collectible}
                      />
                    </Box>
                  </TabItem>
                  <TabItem label={t('checkout.whatsIncluded')}>
                    <Box className="checkout__whatsincluded__content">
                      {exchangeLoading ? (
                        <SkeletonLoading className="w-full h-20" />
                      ) : (
                        exchange?.collectible && getCollectibleDetailsFromExchange(exchange)
                      )}
                    </Box>
                  </TabItem>
                </Tabs>
                <Separator className="mb-5" />
                {exchangeLoading ? (
                  <SkeletonLoading className="w-full h-20" />
                ) : (
                  <>
                    <Text className="checkout__summary-title">
                      {summaryTitle(t)[exchangeIntent]}
                    </Text>
                    {exchange && (
                      <OrderSummarySwitch
                        exchange={exchange}
                        exchangeIntent={exchangeIntent}
                        customPrice={checkoutInputData.customPrice}
                      />
                    )}
                    <Separator />
                    <Box className="py-5">
                      {
                        termsLabel(
                          t,
                          auth ?? { firstName, lastName, email },
                          `${currencyCode}${currencyFormatter.format(
                            calculateTax(checkoutInputData.customPrice).afterTax
                          )}`
                        )[exchangeIntent]
                      }
                    </Box>
                    <Button
                      fullWidth
                      loading={isSubmittingForm || bitpayLoading}
                      disabled={isSubmittingForm || bitpayLoading || (emailAvailability && !auth)}
                      className="mr-3"
                      onClick={validateForm}
                    >
                      {buttonText(t)[exchangeIntent]}
                    </Button>
                  </>
                )}

                <BitpayFrame
                  iframeURL={bitpayIframeURL}
                  closeIframe={() => setBitPayIFrameURL(null)}
                />
              </Box>
            </Grid>
          </Container>
        </form>
      </Box>
      <Helmet>
        <title>{t('checkout.title')}</title>
        <meta property="og:image" content="https://cdn.fans.inc/public/webapp/og-image.png" />
      </Helmet>
    </>
  );
};

export default Checkout;
