import { useHistory, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useUserByUsername } from '@libs/hooks/user';
import { Box, Signup, Logo } from '@components';
import { profileMapper } from '@libs/utils/mapper';
import NotFound from '@pages/not-found';

function ProfileSignup() {
  const params = useParams();
  const username = params.username.toLowerCase();
  const asset = profileMapper[username];
  const history = useHistory();

  const { data: profile, loading: profileLoading } = useUserByUsername(username);

  useEffect(() => {
    if (typeof profile === 'undefined' && !profileLoading) {
      <NotFound />;
    }
  }, [profileLoading, profile, history]);

  return (
    <Box
      className="profile-auth profile-auth-signup"
      style={{ backgroundImage: `url(${asset?.bg})` }}
    >
      <Box className="profile-auth__box profile-auth-signup__box">
        <Signup showSocialLogin={false} loginUrl={`/${username}/login`} />
      </Box>
    </Box>
  );
}

export default ProfileSignup;
