import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Modal, Text } from '@components';

function PrintCollectibleOwnershipModal({ isOpen, setIsOpen }) {
  const { t } = useTranslation();
  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} showOnlyCloseIcon>
      <Text as="span">{t('printCollectiblePage.ownershipModal.text')}</Text>
    </Modal>
  );
}

PrintCollectibleOwnershipModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func
};

export default PrintCollectibleOwnershipModal;
