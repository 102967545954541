import { default as config, AWSConfig } from '@/config';

export const FORMAT_TYPE = {
  IMAGE: 'IMAGE',
  AUDIO: 'AUDIO',
  VIDEO: 'VIDEO'
};

export const mediaChecker = (filename, mime = false) => {
  if (!filename) {
    return false;
  }

  const filenameSplit = filename.split('.');
  // File extension always in last position
  const fileExt = filenameSplit[filenameSplit.length - 1];

  let mediaFormatType = {
    mp4: 'video/mp4',
    mp3: 'audio/mpeg',
    ogg: 'audio/ogg',
    wav: 'audio/wav',
    m3u8: 'video/m3u8'
  };

  if (!mime) {
    mediaFormatType = {
      mp4: FORMAT_TYPE.VIDEO,
      mov: FORMAT_TYPE.VIDEO,
      wmv: FORMAT_TYPE.VIDEO,
      avi: FORMAT_TYPE.VIDEO,
      avchd: FORMAT_TYPE.VIDEO,
      flv: FORMAT_TYPE.VIDEO,
      mkv: FORMAT_TYPE.VIDEO,
      mp3: FORMAT_TYPE.AUDIO,
      ogg: FORMAT_TYPE.AUDIO,
      wav: FORMAT_TYPE.AUDIO,
      m3u8: FORMAT_TYPE.VIDEO
    };
  }

  if (mediaFormatType[fileExt]) {
    return mediaFormatType[fileExt];
  } else {
    return FORMAT_TYPE.IMAGE;
  }
};

export const createAssetUrl = (src, size) => {
  const availableSize = [48, 200, 400, 600, 800, 1080, 1600, 1920, 'full'];
  const getMimeType = mediaChecker(src);

  if (getMimeType === FORMAT_TYPE.AUDIO) {
    return config.cdnUrl ? `${config.cdnUrl}/public/${src}` : src;
  }

  if (getMimeType === FORMAT_TYPE.VIDEO) {
    if (size === 'full') {
      return config.cdnUrl ? `${config.cdnUrl}/public/${src}` : src;
    }

    let filename = src.substring(src.lastIndexOf('/') + 1);
    filename = filename.replace(/\.[^/.]+$/, '');
    return `https://${AWSConfig.awsOutputVideo}/${filename}/${filename}.m3u8`;
  }

  if (!availableSize.includes(size) || getMimeType !== FORMAT_TYPE.IMAGE) {
    return src;
  }

  if (size === 'full') {
    return config.cdnUrl ? `${config.cdnUrl}/public/${src}` : src;
  }

  const resized = src
    .split('/')
    .map((x, i, arr) => (i === arr.length - 1 ? `${size}_${x}` : x))
    .join('/');

  return config.cdnUrl ? `${config.cdnUrl}/public/${resized}` : resized;
};
