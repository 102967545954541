import FansincHeaderNFTAddress from './fansinc';
import PatronsHeaderNFTAddress from './patrons';

const NFTAddress = ({
  exchange,
  address,
  icon,
  iconSize,
  className,
  showStatus = false,
  ...props
}) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return (
      <PatronsHeaderNFTAddress
        exchange={exchange}
        address={address}
        icon={icon}
        iconSize={iconSize}
        className={className}
        showStatus={showStatus}
        {...props}
      />
    );
  } else {
    return (
      <FansincHeaderNFTAddress
        exchange={exchange}
        address={address}
        icon={icon}
        iconSize={iconSize}
        className={className}
        showStatus={showStatus}
        {...props}
      />
    );
  }
};

export default NFTAddress;
