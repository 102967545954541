import { PropTypes } from 'prop-types';
import cx from 'classnames';

import { Box, Button, Container, Heading, Link, Text } from '@components';
import { getFadeClass } from '@components/landing-gallery/utils';

import '@components/landing-gallery/cta/style.css';

function LandGalleryCta({ heading, text, link, buttonText, size, fadeLeft, fadeRight }) {
  return (
    <Box
      className={cx(
        'landing-gallery__cta',
        getFadeClass({ className: 'landing-gallery__cta', fadeLeft, fadeRight })
      )}
    >
      <Container size={size}>
        <Box className="landing-gallery__cta__content">
          <Heading as="h3" className="landing-gallery__section__content__title">
            {heading}
          </Heading>
          <Text
            className="landing-gallery__section__content__text mx-auto"
            style={{ maxWidth: '420px' }}
          >
            {text}
          </Text>
          <Link to={link}>
            <Button size="lg">{buttonText}</Button>
          </Link>
        </Box>
      </Container>
    </Box>
  );
}

LandGalleryCta.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
  buttonText: PropTypes.string,
  size: PropTypes.string,
  fadeLeft: PropTypes.bool,
  fadeRight: PropTypes.bool
};

LandGalleryCta.defaultProps = {
  size: 'md',
  fadeLeft: false,
  fadeRight: false
};

export default LandGalleryCta;
