import { useTranslation } from 'react-i18next';
import { Avatar, Box, OfficialBadge, Link, Media, Text } from '@components';
import ClampLines from 'react-clamp-lines';

const CardBack = ({ collectible, collectibleUrl, type, release }) => {
  const { t } = useTranslation();
  const user = type === 'release' ? release?.currentOwner : collectible?.creator;
  return (
    <Box className="collectible-card__side collectible-card__back">
      <Box className="collectible-card__back__info">
        <OfficialBadge isImage className="mb-4" />
        <Box className="collectible-card__back__info__description">
          <ClampLines moreText="" text={collectible?.description} lines={4} ellipsis="..." />
        </Box>
      </Box>
      <Box className="collectible-card__back__preview">
        <Box className="collectible-card__back__preview__wrapper">
          <Box className="collectible-card__back__preview__thumbnail">
            <Media collectible={collectible} thumbnail borderThinner />
          </Box>
          <Box className="collectible-card__back__preview__users">
            <Box className="collectible-card__back__preview__users__item">
              <Text className="collectible-card__back__preview__users__item__title">
                {type === 'collectible'
                  ? t('components.collectibleCard.back.creator')
                  : t('components.collectibleCard.back.owner')}
              </Text>
              <Link
                to={`/${user?.username}`}
                className="collectible-card__back__preview__users__item__user"
              >
                <Avatar
                  className="collectible-card__back__preview__users__item__user__avatar"
                  size="xs"
                  user={user}
                />
                <Box className="collectible-card__back__preview__users__item__user__detail">
                  <Text className="collectible-card__back__preview__users__item__user__detail__name">
                    {`${user?.firstName} ${user?.lastName}`}
                  </Text>
                  <Text className="collectible-card__back__preview__users__item__user__detail__url">
                    {`fans.inc/${user?.username}`}
                  </Text>
                </Box>
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

CardBack.defaultProps = {
  creatorUrl: '/'
};

export default CardBack;
