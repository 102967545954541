import FansincWalletHistory from './fansinc';
import PatronsWalletHistory from './patrons';

const WalletHistoryTable = ({ histories, loading, handleNextPage, nextPage }) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return (
      <PatronsWalletHistory
        histories={histories}
        loading={loading}
        handleNextPage={handleNextPage}
        nextPage={nextPage}
      />
    );
  } else {
    return (
      <FansincWalletHistory
        histories={histories}
        loading={loading}
        handleNextPage={handleNextPage}
        nextPage={nextPage}
      />
    );
  }
};

export default WalletHistoryTable;
