import { useState, useCallback } from 'react';
import cx from 'classnames';
import {
  Box,
  Container,
  Separator,
  Input,
  Button,
  GalleryCard,
  SkeletonLoading,
  EmptyBox,
  Text,
  Image,
  Link,
  ResponsiveModal,
  CustomDropdown
} from '@components';
import { ReactComponent as ArrowDownIcon } from '@assets/svg/arrow-down.svg';
import { ReactComponent as Search } from '@assets/svg/search-artists.svg';
import { useCollection, useCollectionByName } from '@libs/hooks/collection';
import { useOutsider } from '@libs/utils/outsider';
import { ReactComponent as FilterIcon } from '@assets/svg/patrons/sliders-horizontal.svg';
import PatronsCover from '@assets/img/patrons/patrons-logo-square.png';
import { useTranslation, Trans } from 'react-i18next';
import debounce from 'lodash.debounce';

function Artists() {
  const { t } = useTranslation();
  const {
    data,
    loading,
    total,
    onPaginate,
    onFilterName,
    onFilterType,
    onSort,
    onResetFilter,
    nextPageToken: nextCollectionToken
  } = useCollection();

  const { data: listFilter, loading: filterLoading, getData } = useCollectionByName();
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => setIsOpen(false);
  const { ref } = useOutsider(handleClose);
  const [nameFilter, setNameFilter] = useState('');

  const handleSearch = async (data) => {
    getData(data);
  };

  const NameComponent = ({ name, filter, onChangeName }) => {
    const displayName = name.toUpperCase().split(filter.toUpperCase());
    const onFilterNameChange = () => {
      document.getElementById('input-filter').value = name;
    };
    return (
      <Box
        onClick={() => {
          onFilterNameChange();
          onChangeName(name);
          setIsOpen(false);
        }}
        className="filter-displayname__top capitalize"
      >
        {displayName?.map((x, index) => {
          if (index === displayName.length - 1) {
            return <span>{x.toLowerCase()}</span>;
          }
          return (
            <>
              <span>{x.toLowerCase()}</span>
              <span className="text-primary">{filter.toLowerCase()}</span>
            </>
          );
        })}
      </Box>
    );
  };

  const AvatarComponent = ({ gallery }) => {
    return (
      <Link to={`collection/${gallery?.slug}`}>
        <Box className="galleries-avatar-filter__profile">
          <Box className="galleries-avatar-filter__profile__avatar">
            {gallery?.logoUrl ? (
              <Image style={{ height: '60px', width: '60px' }} src={gallery?.logoUrl} />
            ) : (
              <img style={{ height: '60px', width: '60px' }} src={PatronsCover} alt="" />
            )}
          </Box>
          <Box className="galleries-avatar-filter__profile__name">{gallery?.name}</Box>
        </Box>
      </Link>
    );
  };

  const onNextPage = async () => {
    await onPaginate(nextCollectionToken);
  };

  const handleFilterType = async (data) => {
    await onFilterType(data);
  };

  const handleSort = async (data) => {
    await onSort(data);
  };

  //SORTING
  const [isOpenSorting, setOpenSorting] = useState(false);
  const handleCloseSort = () => setOpenSorting(false);
  const { ref: refSort } = useOutsider(handleCloseSort);
  const [sort, setSort] = useState('TITLE_ASC');
  const handleOpenSorting = useCallback(
    (e) => {
      e.preventDefault();
      setOpenSorting(!isOpenSorting);
    },
    [isOpenSorting]
  );
  const getSortLabel = () => {
    const filter = listSort.filter((list) => {
      return list.value === sort;
    });
    return filter[0].label;
  };
  const listSort = [
    {
      label: 'Name (A - Z)',
      value: 'TITLE_ASC'
    },
    {
      label: 'Name (Z - A)',
      value: 'TITLE_DESC'
    }
  ];

  //TYPE
  const [isOpenType, setOpenType] = useState(false);
  const handleCloseType = () => setOpenType(false);
  const { ref: refType } = useOutsider(handleCloseType);
  const [type, setType] = useState('');
  const handleOpenType = useCallback(
    (e) => {
      e.preventDefault();
      setOpenType(!isOpenType);
    },
    [isOpenType]
  );

  const getTypeLabel = () => {
    const filter = listType.filter((list) => {
      return list.value === type;
    });
    return filter[0].label;
  };

  const onChangeResponsiveType = (value) => {
    setType(value);
    handleFilterType(value);
    handleCloseType();
  };

  const onChangeResponsiveSort = (value) => {
    setSort(value);
    handleSort(value);
    handleCloseSort();
  };

  const resetFilter = () => {
    setType('');
    setSort('TITLE_ASC');
    onResetFilter();
  };

  const listType = [
    {
      label: 'All',
      value: ''
    },
    {
      label: 'Gallery',
      value: 'GALLERY'
    },
    {
      label: 'Museum',
      value: 'MUSEUM'
    }
  ];

  const [colsActive, setColsActive] = useState(2);

  return (
    <Box className="galleries-page">
      <Container className="galleries-page__body">
        <Box className="galleries-page__body__title">{t('p.pages.galleries.title')}</Box>
        <Box className="galleries-page__body__filter">
          <Box ref={ref} className="galleries-page__body__filter__input">
            <Search />
            <Box className="marketplace-page__body__filter__input__wrapper">
              <Input
                id="input-filter"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    onFilterName(e.target.value);
                    setIsOpen(false);
                  }
                }}
                onChange={debounce((e) => {
                  if (e.target.value) {
                    setIsOpen(true);
                    handleSearch(e.target.value);
                    setNameFilter(e.target.value);
                  } else {
                    setNameFilter(e.target.value);
                    setIsOpen(false);
                  }
                }, 1000)}
                placeholder={t('p.pages.galleries.search')}
              />
            </Box>
            {isOpen && listFilter?.length > 0 && (
              <>
                <Box className="artists-page__body__filter__input__dropdown">
                  <Box>
                    {listFilter?.map((gallery) => (
                      <NameComponent
                        key={gallery?.id}
                        name={gallery?.name}
                        onChangeName={onFilterName}
                        setNameFilter={setNameFilter}
                        filter={nameFilter}
                      />
                    ))}
                    <Box className="artists-page__body__filter__input__dropdown__artists mb-4">
                      {t('p.pages.galleries.galleries')}
                    </Box>
                    {listFilter?.map((gallery) => (
                      <AvatarComponent key={gallery?.id} gallery={gallery} />
                    ))}
                  </Box>
                </Box>
              </>
            )}
          </Box>
          <Box className="marketplace-page_search">
            <Box className="marketplace-page__search-sorting">
              <Text className="marketplace-page__search-sorting-header" onClick={handleOpenType}>
                <Text as="span" className="marketplace-page__search-sorting-header__type">
                  {t('p.pages.galleries.type')}
                </Text>
                <Text as="span" className="marketplace-page__search-sorting-header__item">
                  {getTypeLabel()}
                  <ArrowDownIcon />
                </Text>
              </Text>
              {isOpenType && (
                <Box className="select-multiple__dropdown overflow-hidden" ref={refType}>
                  <ul>
                    {listType &&
                      listType.map((o) => (
                        <>
                          <li
                            className={cx(type === o.value && 'active')}
                            key={o.value}
                            value={o.value}
                            onClick={() => {
                              setType(o.value);
                              handleFilterType(o.value);
                              handleCloseType();
                            }}
                          >
                            {o.label}
                          </li>
                          <Separator />
                        </>
                      ))}
                  </ul>
                </Box>
              )}
            </Box>
          </Box>
          <Box className="marketplace-page_search">
            <Box className="marketplace-page__search-sorting">
              <Text className="marketplace-page__search-sorting-header" onClick={handleOpenSorting}>
                <Text as="span" className="marketplace-page__search-sorting-header__type">
                  {t('p.pages.marketplace.sort')}
                </Text>
                <Text as="span" className="marketplace-page__search-sorting-header__item">
                  {getSortLabel()}
                  <ArrowDownIcon />
                </Text>
              </Text>
              {isOpenSorting && (
                <Box className="select-multiple__dropdown overflow-hidden" ref={refSort}>
                  <ul>
                    {listSort &&
                      listSort.map((o) => (
                        <>
                          <li
                            className={cx(sort === o.value && 'active')}
                            key={o.value}
                            value={o.value}
                            onClick={() => {
                              setSort(o.value);
                              handleSort(o.value);
                              handleCloseSort();
                            }}
                          >
                            {o.label}
                          </li>
                          <Separator />
                        </>
                      ))}
                  </ul>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Separator className="galleries-page-separator mt-4 mb-8" />
        <Box className="marketplace-page__body__result">
          <Box className="marketplace-page__body__result__count">
            {total === 1 ? (
              <Trans i18nKey="p.pages.marketplace.result">{{ total: total || 0 }}</Trans>
            ) : (
              <Trans i18nKey="p.pages.marketplace.results">{{ total: total || 0 }}</Trans>
            )}
          </Box>
          <Box className="marketplace-page__body__result__show">
            <Box
              onClick={() => setColsActive(2)}
              className="grid grid-cols-2 point-show cursor-pointer"
            >
              {[1, 2, 3, 4].map((x) => (
                <Box
                  key={x}
                  className={cx(colsActive === 2 ? 'point-filter-active' : 'point-filter')}
                ></Box>
              ))}
            </Box>
            <Box
              onClick={() => setColsActive(3)}
              className="grid grid-cols-3 point-show cursor-pointer"
            >
              {[1, 2, 3, 4, 5, 6].map((x) => (
                <Box
                  key={x}
                  className={cx(colsActive === 3 ? 'point-filter-active' : 'point-filter')}
                ></Box>
              ))}
            </Box>
            <Box
              onClick={() => setColsActive(4)}
              className="grid grid-cols-4 point-show cursor-pointer"
            >
              {[1, 2, 3, 4, 5, 6, 7, 8].map((x) => (
                <Box
                  key={x}
                  className={cx(colsActive === 4 ? 'point-filter-active' : 'point-filter')}
                ></Box>
              ))}
            </Box>
            <Box className="responsive-filter" onClick={() => setOpenModal(true)}>
              <FilterIcon />
            </Box>
          </Box>
        </Box>
        {data?.length > 0 ? (
          <Box className={cx('galleries-page__body__cards grid', `grid-cols-${colsActive}`)}>
            {data?.map((gallery) => (
              <GalleryCard key={gallery.id} gallery={gallery} colsActive={colsActive} />
            ))}
          </Box>
        ) : (
          <>
            {loading ? (
              <Box className={cx('galleries-page__body__cards grid', `grid-cols-${colsActive}`)}>
                {[...Array(colsActive * 2)].map((x, index) => (
                  <SkeletonLoading key={index} style={{ height: '640px' }} />
                ))}
              </Box>
            ) : (
              <EmptyBox title="No galleries found" />
            )}
          </>
        )}
        {nextCollectionToken && (
          <Button loading={loading} onClick={() => onNextPage()}>
            {t('p.pages.artists.loadMore')}
          </Button>
        )}
      </Container>
      <ResponsiveModal isOpen={openModal} onClose={() => setOpenModal(false)}>
        <Box className="flex items-center justify-between mb-2">
          <Box className="text-lg font-bold">{t('p.pages.galleries.filterGalleries')}</Box>
          <Box
            onClick={() => resetFilter()}
            className="text-sm text-secondary underline font-medium"
          >
            {t('p.pages.marketplace.reset')}
          </Box>
        </Box>
        <CustomDropdown
          value={type}
          list={listType}
          setValue={onChangeResponsiveType}
          label="Type"
        />
        <CustomDropdown
          value={sort}
          list={listSort}
          setValue={onChangeResponsiveSort}
          label="Sort"
        />
        <Button className="mt-5" onClick={() => setOpenModal(false)} fullWidth>
          {t('p.pages.marketplace.close')}
        </Button>
      </ResponsiveModal>
    </Box>
  );
}

export default Artists;
