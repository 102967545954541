import { React, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useAuth } from '@libs/contexts/auth';
import { useHistory } from 'react-router-dom';
import Landing from './landing';
import Waitlist from './waitlist';
import Signup from './signup';
import { Box } from '@components';

function ComingSoon() {
  const history = useHistory();
  const { isAuthenticated } = useAuth();
  useEffect(() => {
    if (isAuthenticated) {
      history.push('/home');
    }
  }, [isAuthenticated]);
  return (
    <Box>
      <Box className="landing-section">
        <Box className="landing-section__left">
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route exact path="/waitlist/verified" component={Waitlist} />
            <Route exact path="/waitlist-signup/:id" component={Signup} />
          </Switch>
        </Box>
        <Box className="landing-section__right"></Box>
      </Box>
    </Box>
  );
}

export default ComingSoon;
