/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onPublishedNotification = /* GraphQL */ `
  subscription OnPublishedNotification($owner: ID!) {
    onPublishedNotification(owner: $owner) {
      id
      owner
      type
      event
      read
      __typename
    }
  }
`;
export const onProcessPaymentV1 = /* GraphQL */ `
  subscription OnProcessPaymentV1 {
    onProcessPaymentV1
  }
`;
export const onNotifUpdatedV1 = /* GraphQL */ `
  subscription OnNotifUpdatedV1 {
    onNotifUpdatedV1
  }
`;
export const onDiscourseUpdated = /* GraphQL */ `
  subscription OnDiscourseUpdated {
    onDiscourseUpdated
  }
`;
export const onCommentaryUpdated = /* GraphQL */ `
  subscription OnCommentaryUpdated {
    onCommentaryUpdated
  }
`;
