import { useState, useCallback, useEffect } from 'react';
import { Box, Avatar, Separator, RichTextEditor, Modal, Button, ImageCollage } from '@components';
import cx from 'classnames';
import { formatDistanceToNowStrict } from 'date-fns';
import { Trans, useTranslation } from 'react-i18next';
import { FiMoreVertical, FiArrowRight } from 'react-icons/fi';
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import { BsFillPinAngleFill } from 'react-icons/bs';
import { useOutsider } from '@libs/utils/outsider';
import { useReplyPost, usePost } from '@libs/hooks/discourse';
import { useAuth } from '@libs/contexts/auth';
import Comment from './comment';
import { reportList } from './utils';

const Post = ({ user, postId, channel, fetchPosts, post, setLoadingPosts, featured }) => {
  const { user: userLogin } = useAuth();
  const {
    data: updatePostData,
    deleteLoading,
    updateLoading,
    likeLoading,
    reportLoading,
    getData: fetchPost,
    deletePost,
    reportPost,
    updatePost,
    likePost,
    unlikePost
  } = usePost(postId, userLogin?.id);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [contentPost, setContentPost] = useState(post);
  const [listComment, setListComment] = useState([]);
  const [liked, setLiked] = useState(contentPost?.likedBy?.includes(userLogin?.id));
  const [totalLike, setTotalLike] = useState(contentPost?.likedBy?.length);
  const [totalComment, setTotalComment] = useState(0);
  const countComment = async () => {
    let count = 0;
    await post?.replies?.items?.forEach((x) => {
      count += x.post?.replies?.items?.length;
    });
    setTotalComment(count + post?.replies?.items?.length);
  };
  const { data, replyLoading, getData, replyPost } = useReplyPost(post.id);
  useEffect(() => {
    countComment();
    if (post && !replyLoading) {
      setListComment(
        post?.replies?.items
          ?.map((comment) => comment.post)
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      );
      setTotalLike(post?.likedBy?.length);
    }
  }, [post]); // eslint-disable-line
  const [inputEditValue, setInputEditValue] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showReportSubmitModal, setShowReportSubmitModal] = useState(false);
  const [cooked, setCooked] = useState('');
  const [imageList, setImageList] = useState([]);

  const postAuthor = contentPost?.user;

  const handleInputEditChange = (editor) => {
    setInputEditValue(editor);
  };

  const handleDeletePost = async () => {
    await deletePost();
    setShowDeleteModal(false);
    setLoadingPosts(true);
    setTimeout(function () {
      fetchPosts();
    }, 2000);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleCloseReportModal = () => {
    setShowReportModal(false);
  };

  const handleCloseReportSubmitModal = () => {
    setShowReportSubmitModal(false);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  const handleClickReason = async (reason) => {
    await reportPost(contentPost?.id, reason, () => {
      handleCloseReportModal();
      setShowReportSubmitModal(true);
    });
  };

  const handleLikePost = async () => {
    if (userLogin?.id !== contentPost?.userID) {
      if (!likeLoading) {
        setLiked(true);
        setTotalLike(totalLike + 1);
        await likePost(contentPost?.id);
      }
    }
  };
  const handleUnlikePost = async () => {
    if (!likeLoading) {
      setLiked(false);
      setTotalLike(totalLike - 1);
      await unlikePost(contentPost?.id);
    }
  };

  const handleClose = () => setIsOpen(false);
  const { ref } = useOutsider(handleClose);

  const handledSendComment = useCallback(
    async (comment) => {
      if (!replyLoading) {
        const commentRaw = comment.slice(0, -11);
        const payload = {
          raw: commentRaw,
          topicID: post?.topicID,
          userID: userLogin?.id,
          category: post?.channelID,
          parentID: post?.id
        };
        const newComment = {
          user: userLogin,
          content: commentRaw,
          createdAt: new Date()
        };
        if (commentRaw.length > 20) {
          setListComment(listComment.concat(newComment));
          setTotalComment(totalComment + 1);
        }
        await replyPost(payload);
        setTimeout(function () {
          getData();
        }, 2000);
      }
    },
    [listComment, replyLoading]
  ); // eslint-disable-line

  useEffect(() => {
    if (data?.length > 0) {
      setListComment(data);
    }
  }, [data]);

  const handleUpdatePost = useCallback(async () => {
    const payload = {
      raw: inputEditValue,
      id: post?.id,
      userID: userLogin?.id,
      channelID: channel?.id
    };
    await updatePost(payload);
    setTimeout(function () {
      fetchPost();
    }, 2000);
    setShowEditModal(false);
  }, [inputEditValue]); // eslint-disable-line

  useEffect(() => {
    if (updatePostData) {
      setContentPost(updatePostData);
    }
  }, [updatePostData]); // eslint-disable-line

  useEffect(() => {
    if (contentPost?.content) {
      /**
       * Separate image and text content on discourse post
       * Image to display on image collage always inside div with data-component"image-collage"
       */
      const mockDocument = document.createElement('p');
      mockDocument.innerHTML = contentPost?.content;
      const imgList = mockDocument.querySelectorAll('div[data-component="image-collage"] img');

      let imgSource = [];
      for (const img of imgList) {
        const srcSet = img.srcset.split(', ');
        imgSource.push({
          src: img.src,
          srcSet: srcSet[srcSet.length - 1].split(' ')[0] || img.src
        });
      }

      setImageList(imgSource);
      setCooked(mockDocument.querySelector('p').innerHTML);
    }
  }, [contentPost]);

  const isOwnerPost =
    userLogin?.id === contentPost?.userID
      ? 'post-body-response-like-owner'
      : 'post-body-response-like';

  return (
    <>
      {contentPost && (
        <Box className="post">
          {featured && (
            <Box className="post-featured">
              <BsFillPinAngleFill style={{ fontSize: '16px' }} />
              <Box className="post-featured-text">{t('components.post.post.featuredPost')}</Box>
            </Box>
          )}
          <Box className="post-header">
            <Avatar user={postAuthor} size="xs-40" className="post-header-avatar" />
            <Box className="post-header-detail">
              <Box className="post-header-detail-name">
                {postAuthor?.displayName || postAuthor?.firstName + ' ' + postAuthor?.lastName}
              </Box>
              <Box className="post-header-detail-time">
                {post &&
                  formatDistanceToNowStrict(new Date(contentPost?.createdAt), { addSuffix: true })}
              </Box>
            </Box>
            {userLogin?.id === contentPost?.userID && (
              <Box ref={ref} className="post-header-tools">
                <FiMoreVertical onClick={() => setIsOpen(!isOpen)} style={{ cursor: 'pointer' }} />
                {isOpen && (
                  <>
                    <ul className="post-header-tools-dropdown">
                      <li
                        onClick={() => {
                          setShowEditModal(true);
                          setIsOpen(false);
                        }}
                      >
                        {t('components.post.post.edit')}
                      </li>
                      <li
                        onClick={() => {
                          setShowDeleteModal(true);
                          setIsOpen(false);
                        }}
                      >
                        {t('components.post.post.delete')}
                      </li>
                    </ul>
                  </>
                )}
              </Box>
            )}

            {userLogin && userLogin?.id !== contentPost?.userID && (
              <Box ref={ref} className="post-header-tools">
                <FiMoreVertical onClick={() => setIsOpen(!isOpen)} style={{ cursor: 'pointer' }} />
                {isOpen && (
                  <>
                    <ul className="post-header-tools-dropdown">
                      <li
                        onClick={() => {
                          setShowReportModal(true);
                          setIsOpen(false);
                        }}
                      >
                        {t('components.post.post.report')}
                      </li>
                    </ul>
                  </>
                )}
              </Box>
            )}
          </Box>
          <Box className="post-body">
            <Box className="post-body-content">
              <Box
                dangerouslySetInnerHTML={{
                  __html: cooked
                }}
              ></Box>
              {imageList?.length > 0 && (
                <Box className="post-body-content-image">
                  <ImageCollage style={{ marginTop: 16 }} images={imageList} />
                </Box>
              )}
            </Box>
            <Separator />
            <Box className="post-body-response">
              <Box className={cx(isOwnerPost)}>
                {liked ? (
                  <>
                    <AiFillHeart onClick={() => handleUnlikePost()} color="#D6222A" size={20} />
                    <Box className="post-body-response-like-count">{totalLike}</Box>
                  </>
                ) : (
                  <>
                    <AiOutlineHeart onClick={() => handleLikePost()} size={20} />
                    <Box className="post-body-response-like-count">{totalLike}</Box>
                  </>
                )}
              </Box>
              <Box className="post-body-response-comment">
                <Box className="post-body-response-comment-count">
                  <Trans i18nKey="components.post.comment.count">
                    {{
                      total: totalComment
                    }}
                  </Trans>
                </Box>
              </Box>
            </Box>
            <Separator />
          </Box>
          <Box className="post-comment">
            {listComment?.map((comment) => (
              <Comment
                user={user}
                comment={comment}
                post={post}
                setTotalComment={setTotalComment}
                totalComment={totalComment}
                fetchPost={fetchPost}
                key={`comment-${comment.id}`}
              />
            ))}
          </Box>
          {/* {!replyLoading && ( */}
          <Box className="post-create-comment">
            <Avatar user={userLogin} size="xs-40" className="post-header-avatar" />
            <Box className="post-create-comment-input">
              <RichTextEditor
                // value={inputPostValue}
                placeholder={t('components.post.comment.placeholder')}
                onEnter={handledSendComment}
              />
            </Box>
          </Box>
          {/* )} */}
          <Modal
            heading={t(['components.post.post.deletePost'])}
            isOpen={showDeleteModal}
            onClose={() => handleCloseDeleteModal()}
          >
            {t('components.post.post.deletePostDescription')}
            <Box className="post-modal-delete-button">
              <Button
                onClick={() => handleCloseDeleteModal()}
                className="post-modal-delete-button-cancel"
                outline
              >
                {t('components.post.post.cancel')}
              </Button>
              <Button loading={deleteLoading} onClick={() => handleDeletePost()}>
                {t('components.post.post.delete')}
              </Button>
            </Box>
          </Modal>
          <Modal
            heading={t(['components.post.post.report'])}
            isOpen={showReportModal}
            onClose={() => handleCloseReportModal()}
            closable
            customWrapperStyle="post-modal-report"
            showCloseIcon
            loading={reportLoading}
          >
            <Box className="post-modal-report-title">
              {t('components.post.post.reportDescription')}
            </Box>
            <Box className="post-modal-report-list">
              {reportList?.map((list) => (
                <Box
                  onClick={() => handleClickReason(list?.value)}
                  className="post-modal-report-list__item"
                  key={list?.label}
                >
                  {t(`components.post.post.reportList.${list?.label}`)} <FiArrowRight />
                </Box>
              ))}
            </Box>
          </Modal>
          <Modal
            heading={t(['components.post.post.reportSubmit'])}
            isOpen={showReportSubmitModal}
            onClose={() => handleCloseReportSubmitModal()}
            closable={t('components.post.post.button.close')}
            customWrapperStyle="post-modal-report"
            showCloseIcon
          >
            <Box className="post-modal-report-title">
              {t('components.post.post.reportSubmitTitle')}
            </Box>
            <Box>{t('components.post.post.reportSubmitDescription')}</Box>
          </Modal>
          <Modal
            heading={t(['components.post.post.editPost'])}
            isOpen={showEditModal}
            showCloseIcon
            size="lg"
            onClose={() => handleCloseEditModal()}
          >
            <Box className="post-modal-edit-input">
              <RichTextEditor defaultValue={cooked} editForm onEnter={handleInputEditChange} />
            </Box>
            <Box className="post-modal-edit-button">
              <Button loading={updateLoading} onClick={() => handleUpdatePost()}>
                {t('components.post.post.save')}
              </Button>
            </Box>
          </Modal>
        </Box>
      )}
    </>
  );
};

export default Post;
