import { React } from 'react';
import FansincNotifications from './fansinc';
import PatronsNotifications from './patrons';

function Notifications() {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsNotifications />;
  } else {
    return <FansincNotifications />;
  }
}
export default Notifications;
