import cx from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaVideo } from 'react-icons/fa';
import { Avatar, Box, Image, ProfileVideo, Modal, Text, Button, Cropper } from '@components';
import { AiOutlineCamera } from 'react-icons/ai';
import { motion } from 'framer-motion/dist/framer-motion';
import { useAuth } from '@libs/contexts/auth';
import { useUploader, useImageResizer } from '@libs/utils/uploader';
import { useUser } from '@libs/hooks/user';
const ProfileHeader = ({
  release,
  user,
  loading,
  bannerColor,
  className,
  onHandleFollow,
  followLoading,
  isFollow,
  customFollow,
  refetchData,
  ...props
}) => {
  const { t } = useTranslation();
  const { user: authUser, getCurrentUser } = useAuth();
  const [openVideo, setOpenVideo] = useState(false);
  const [isHoverAvatar, setIsHoverAvatar] = useState(false);

  //update avatar
  const { updateAvatarLoading, onUpdateAvatar, onRemoveAvatar } = useUser();
  const [uploadAvatarLoading, setUploadAvatarLoading] = useState(false);
  const {
    inputRef: avatarRef,
    result: avatarResult,
    loading: onLoadingUploderAvatar,
    onClick: onClickUploadAvatar,
    onUpload: onUploadAvatar
  } = useUploader();
  const { loading: imageResizeLoading, onResize } = useImageResizer();
  const [modalAvatar, setModalAvatar] = useState(false);

  const handleOpenModalAvatar = () => {
    setModalAvatar(true);
  };

  const handleCloseModalAvatar = () => {
    if (
      !(onLoadingUploderAvatar || imageResizeLoading || updateAvatarLoading || uploadAvatarLoading)
    ) {
      setModalAvatar(false);
    }
  };

  //update avatar

  const bannerColorClass = {
    fern: 'profile-header__banner--fern',
    sapphireBlue: 'profile-header__banner--sapphireBlue',
    blue: 'profile-header__banner--blue',
    berryRed: 'profile-header__banner--berryRed',
    bloodRed: 'profile-header__banner--bloodRed',
    pineGreen: 'profile-header__banner--pineGreen',
    fernGreen: 'profile-header__banner--fernGreen',
    purple: 'profile-header__banner--purple',
    violet: 'profile-header__banner--violet',
    brown: 'profile-header__banner--brown',
    sandBrown: 'profile-header__banner--sandBrown',
    gold: 'profile-header__banner--gold',
    gray: 'profile-header__banner--gray'
  };

  const subAvatarAnimate = {
    enter: {
      opacity: 1,
      rotateX: 0,
      transition: {
        duration: 0.4
      },
      display: 'block'
    },
    exit: {
      opacity: 0,
      rotateX: 0,
      transition: {
        duration: 0.4
      },
      transitionEnd: {
        display: 'none'
      }
    }
  };

  return (
    <>
      <Box className="profile-header">
        <Box className="profile-header__top">
          <Box className={cx('profile-header__banner', bannerColorClass[bannerColor])}>
            {!loading && (
              <Image
                size={1920}
                className="profile-header__banner__image"
                src={user?.media?.coverUrl}
                fallback={`https://cdn.patrons.art/public/covers/patrons-default-cover.png`}
                alt={t('components.profileHeader.coverAlt', {
                  name: `${user?.firstName} ${user?.lastName}`
                })}
              />
            )}
          </Box>
          <Box className="profile-header__avatar">
            <Box className="profile-header__avatar__wrapper">
              {authUser?.id === user?.id ? (
                <Avatar
                  loading={loading}
                  user={authUser}
                  size="lg"
                  showVerified
                  className="profile-header__avatar__image"
                />
              ) : (
                <Avatar
                  loading={loading}
                  user={user}
                  size="lg"
                  showVerified
                  className="profile-header__avatar__image"
                />
              )}

              {authUser?.id === user?.id && (
                <motion.div
                  initial="exit"
                  onHoverStart={() => setIsHoverAvatar(!isHoverAvatar)}
                  onHoverEnd={() => setIsHoverAvatar(!isHoverAvatar)}
                  variants={subAvatarAnimate}
                  animate={isHoverAvatar ? 'enter' : 'exit'}
                  className="profile-header__avatar__hover"
                  onClick={handleOpenModalAvatar}
                >
                  <AiOutlineCamera size={20} />
                </motion.div>
              )}
            </Box>
            {user?.media?.avatarVideoUrl && (
              <Box onClick={() => setOpenVideo(true)} className="profile-header__avatar__icon">
                <FaVideo className="profile-header__avatar__icon_shadow cursor-pointer" size={20} />
              </Box>
            )}
            <ProfileVideo user={user} isOpen={openVideo} onClose={() => setOpenVideo(false)} />
          </Box>
        </Box>
        <Modal
          verticalCenter
          showCloseIcon
          className="modal-avatar"
          isOpen={modalAvatar}
          onClose={handleCloseModalAvatar}
          heading={t('p.pages.settings.profile.fields.avatar.uploadImage')}
          size="profile"
        >
          <Box className="modal-avatar__upload">
            <Text className="text-gray-400">
              {t('p.pages.settings.profile.fields.avatar.placeholder')}
            </Text>
            <Button
              onClick={onClickUploadAvatar}
              disabled={
                onLoadingUploderAvatar ||
                imageResizeLoading ||
                updateAvatarLoading ||
                uploadAvatarLoading
              }
              loading={onLoadingUploderAvatar}
              className="modal-avatar__upload__button"
            >
              {t('p.pages.settings.profile.fields.avatar.chooseFile')}
            </Button>
            <input
              ref={avatarRef}
              accept="image/jpeg, image/png, image/webp"
              className="invisible w-0"
              type="file"
              onChange={(e) => onUploadAvatar(e, 'avatar')}
            />
          </Box>

          {avatarRef?.current?.value && avatarResult.fileUrl ? (
            <Box className="modal-avatar__cropper">
              <Cropper
                loading={imageResizeLoading || updateAvatarLoading || uploadAvatarLoading}
                image={avatarResult.fileUrl}
                onComplete={async (croppedArea) => {
                  setUploadAvatarLoading(true);
                  const resized = await onResize({
                    key: avatarResult.s3?.key,
                    folder: 'avatars',
                    ...croppedArea
                  });

                  if (resized) {
                    await onRemoveAvatar();
                    await onUpdateAvatar(resized?.key);
                    await getCurrentUser();
                    setUploadAvatarLoading(false);
                  }

                  avatarRef.current.value = '';
                  setUploadAvatarLoading(false);
                  setModalAvatar(false);
                }}
                onCancel={() => {
                  setModalAvatar(false);
                  avatarRef.current.value = '';
                }}
              />
            </Box>
          ) : null}
        </Modal>
      </Box>
    </>
  );
};

ProfileHeader.defaultProps = {
  bannerColor: '',
  customFollow: false
};

export default ProfileHeader;
