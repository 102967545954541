import FansincUserMenu from './fansinc';
import PatronsUserMenu from './patrons';

const UserMenu = ({ size, className, ...props }) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsUserMenu size={size} className={className} {...props} />;
  } else {
    return <FansincUserMenu size={size} className={className} {...props} />;
  }
};

export default UserMenu;
