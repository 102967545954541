import FansincNotFound from './fansinc';
import PatronsNotFound from './patrons';

const NotFound = ({ title, description }) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsNotFound title={title} description={description} />;
  } else {
    return <FansincNotFound title={title} description={description} />;
  }
};

export default NotFound;
