import { useTranslation } from 'react-i18next';
import { Button } from '@components';

const AuthButton = ({ isAuthenticated, isFollow, onClick, loading }) => {
  const { t } = useTranslation();

  if (isAuthenticated) {
    return (
      <Button outline={isFollow} onClick={onClick} loading={loading} disabled={loading}>
        {isFollow
          ? t('components.editionCard.button.following')
          : t('components.editionCard.button.notifyMe')}
      </Button>
    );
  }

  return null;
};

export default AuthButton;
