import cx from 'classnames';
import { Box, Image, Separator, SkeletonLoading, Link } from '@components';
import { useCollectionById } from '@libs/hooks/collection';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@libs/contexts/auth';
import PatronsCover from '@assets/img/patrons/patrons-logo-landscape.png';
import CollectibleSlider from './components/collectibleSlider';

function GalleryCard({ gallery, colsActive }) {
  const { t } = useTranslation();
  const { collectionStash } = useCollectionById(gallery?.id);
  const { user } = useAuth();
  return (
    <Box className="gallery-card">
      <Link to={`/collection/${gallery?.slug}`}>
        <Box className="gallery-card__image">
          {gallery?.coverUrl ? (
            <Image
              size={1920}
              src={gallery?.coverUrl}
              alt={t('components.profileHeader.coverAlt', {
                name: `${user?.firstName} ${user?.lastName}`
              })}
            />
          ) : (
            <img style={{ width: '100%' }} src={PatronsCover} alt="" />
          )}
          <Box className="gallery-card__image__logo">
            <Image src={gallery?.logoUrl} size="full" />
          </Box>
        </Box>
      </Link>
      <Link to={`/collection/${gallery?.slug}`}>
        <Box
          className={cx(
            'gallery-card__profile',
            colsActive === 4 ? 'text-left' : 'flex items-center'
          )}
        >
          <Box
            className={cx(
              'gallery-card__profile__name',
              colsActive === 4 ? 'gallery-card__profile__name__small' : 'text-lg'
            )}
          >
            {gallery?.name}
          </Box>
          <Box className="ml-auto text-secondary">{gallery?.city}</Box>
        </Box>
      </Link>
      <Link to={`/collection/${gallery?.slug}`}>
        <Box className="gallery-card__detail">
          <Box className="gallery-card__detail__field">
            <Box className="gallery-card__detail__field__name">
              {t('p.pages.profile.museum.artworks')}
            </Box>
            {collectionStash ? (
              <Box className="gallery-card__detail__field__value">
                {collectionStash?.collectibles}
              </Box>
            ) : (
              <SkeletonLoading className="w-4 h-6" />
            )}
          </Box>
          <Box className="gallery-card__detail__field">
            <Box className="gallery-card__detail__field__name">
              {t('p.pages.profile.museum.artists')}
            </Box>
            {collectionStash ? (
              <Box className="gallery-card__detail__field__value">{collectionStash?.artists}</Box>
            ) : (
              <SkeletonLoading className="w-4 h-6" />
            )}
          </Box>
          <Box className="gallery-card__detail__field">
            <Box className="gallery-card__detail__field__name">
              {t('p.pages.profile.museum.visitors')}
            </Box>
            {collectionStash ? (
              <Box className="gallery-card__detail__field__value">{collectionStash?.visitors}</Box>
            ) : (
              <SkeletonLoading className="w-4 h-6" />
            )}
          </Box>
        </Box>
      </Link>
      <Separator />
      <Box className="p-5">
        <CollectibleSlider gallery={gallery} />
      </Box>
    </Box>
  );
}

export default GalleryCard;
