import { useEffect, useState } from 'react';
import cx from 'classnames';
import { formatDistanceToNowStrict } from 'date-fns';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Avatar, Box, Link, Image } from '@components';
import { useLocale } from '@libs/contexts/locale';
import { useUpdateReadNotification } from '@libs/hooks/notification';
import { useNotifications } from '@libs/contexts/notification';
import Logo from '@assets/img/patrons/logo-patrons.png';
import { reportList } from '@components/post/utils';
import {
  CollectibleMintedNotification,
  processCollectibleMintedNotification
} from '@components/notification-menu/notifications';

const NotificationMenuItem = ({ notification, dropdown, className, ...props }) => {
  const { readNotification } = useNotifications();
  const { t } = useTranslation();
  const notificationTime = notification?.createdAt
    ? formatDistanceToNowStrict(new Date(notification?.createdAt), { addSuffix: true })
    : '';

  const event = notification?.event;
  const { getCurrencyFormatter } = useLocale();
  const currencyFormatter = getCurrencyFormatter();
  const [linkRedirect, setLinkRedirect] = useState('');
  const history = useHistory();

  let doer = {};
  let collectible = {};
  switch (notification?.type) {
    case 'USER_FOLLOWED_FOLLOWEE':
      {
        const follower = event.followerUser;
        doer.id = follower.id;
        doer.fullName = follower.firstName + ' ' + follower.lastName;
        doer.username = follower.username;
        doer.media = { avatarUrl: follower?.avatarUrl };
        doer.profileName = follower.firstName + ' ' + follower.lastName;
      }
      break;

    case 'COLLECTIBLE_LIKED':
      {
        const follower = event.followerUser;
        doer.id = follower.id;
        doer.fullName = follower.firstName + ' ' + follower.lastName;
        doer.username = follower.username;
        doer.media = { avatarUrl: follower?.avatarUrl };
        doer.profileName = follower.firstName + ' ' + follower.lastName;
      }
      break;

    case 'COLLECTIBLE_MINTED':
      processCollectibleMintedNotification({ event, doer, collectible });
      break;

    case 'COLLECTIBLE_BID_MADE':
      {
        const owner = event.ownerUser;
        doer.id = owner.id;
        doer.tier = '(Limited Edition #' + event.exchange.serialIndex + ')';
        doer.price = event.exchange.offerPrice
          ? currencyFormatter.format(Number(event.exchange.offerPrice))
          : currencyFormatter.format(Number(event.exchange.bidPrice));
        doer.fullName = (owner.firstName + ' ' + owner.lastName).trim();
        doer.username = owner.username;
        doer.media = {
          avatarUrl: owner.avatarUrl
        };
        collectible.mediaThumbnail = event.collectible.mediaThumbnail;
        doer.profileName = 'Collectible';
      }
      break;

    case 'AUCTION_BID_SUBMITTED_BUYER':
      {
        const owner = event.ownerUser;
        doer.id = owner.id;
        doer.tier = '(Limited Edition #' + event.exchange.serialIndex + ')';
        doer.price = event.exchange.offerPrice
          ? currencyFormatter.format(Number(event.exchange.offerPrice))
          : currencyFormatter.format(Number(event.exchange.bidPrice));
        doer.fullName = (owner.firstName + ' ' + owner.lastName).trim();
        doer.username = owner.username;
        doer.media = {
          avatarUrl: owner.avatarUrl
        };
        collectible.mediaThumbnail = event.collectible.mediaThumbnail;
        doer.profileName = 'Collectible';
      }
      break;

    case 'OFFER_SUBMITTED_BUYER':
      {
        const owner = event.ownerUser;
        doer.id = owner.id;
        doer.tier = '(Limited Edition #' + event.exchange.serialIndex + ')';
        doer.price = event.exchange.offerPrice
          ? currencyFormatter.format(Number(event.exchange.offerPrice))
          : currencyFormatter.format(Number(event.exchange.bidPrice));
        doer.fullName = (owner.firstName + ' ' + owner.lastName).trim();
        doer.username = owner.username;
        doer.media = {
          avatarUrl: owner.avatarUrl
        };
        collectible.mediaThumbnail = event.collectible.mediaThumbnail;
        doer.profileName = 'Collectible';
      }
      break;

    case 'COLLECTIBLE_SOLD_FANOWNER':
      {
        const user = event.ownerUser;
        doer.id = user.id;
        doer.fullName = (user.firstName + ' ' + user.lastName).trim();
        doer.username = user.username;
        doer.price = currencyFormatter.format(Number(event.exchange.sellingPrice));
        doer.media = {
          avatarUrl: user.avatarUrl
        };
        collectible.mediaThumbnail = event.collectible.mediaThumbnail;
        doer.profileName = 'Collectible';
      }
      break;

    case 'OFFER_ACCEPTED_BUYER':
      {
        const user = event.ownerUser;
        doer.id = user.id;
        doer.fullName = (user.firstName + ' ' + user.lastName).trim();
        doer.username = user.username;
        doer.tier = '(Limited Edition #' + event.exchange.serialIndex + ')';
        doer.price = currencyFormatter.format(Number(event.exchange.sellingPrice));
        doer.media = {
          avatarUrl: user.avatarUrl
        };
        collectible.mediaThumbnail = event.collectible.mediaThumbnail;
        doer.profileName = 'Collectible';
      }
      break;

    case 'OFFER_ACCEPTED_BUYER_BITPAY':
      {
        const creator = event.creatorUser;

        doer.id = creator.id;
        doer.tier = '(Limited Edition #' + event.exchange.serialIndex + ')';
        doer.fullName = (creator.displayName || creator.firstName + ' ' + creator.lastName).trim();
        doer.username = creator.username;
        doer.media = {
          avatarUrl: creator.avatarUrl
        };
        collectible.mediaThumbnail = event.collectible.mediaThumbnail;
        doer.profileName = 'Collectible';
      }
      break;

    case 'OFFER_PAYMENT_REQUESTED_BUYER_BITPAY':
      {
        const user = event.ownerUser;
        doer.id = user.id;
        doer.fullName = (user.firstName + ' ' + user.lastName).trim();
        doer.username = user.username;
        doer.tier = '(Limited Edition #' + event.exchange.serialIndex + ')';
        doer.price = currencyFormatter.format(Number(event.offer.offerPrice));
        doer.media = {
          avatarUrl: user.avatarUrl
        };
        collectible.mediaThumbnail = event.collectible.mediaThumbnail;
        doer.profileName = 'Collectible';
      }
      break;

    case 'PRINT_PURCHASE_CONFIRMED':
      {
        const user = event.buyer;
        doer.id = user.id;
        doer.username = event.buyer.username;
        doer.tier =
          event.printedItem.serialIndex === 1
            ? '(Digital Original #1)'
            : '(Limited Edition #' + event.printedItem.serialIndex + ')';
        collectible.mediaThumbnail = event.collectible.mediaThumbnail;
        doer.profileName = 'Collectible';
      }
      break;

    case 'PRINT_ORDER_PAYMENT_CONFIRMED_OWNER':
      {
        const user = event.buyer;
        doer.id = user.id;
        doer.tier = '(Limited Edition #' + event.exchange.serialIndex + ')';
        collectible.mediaThumbnail = event.collectible.mediaThumbnail;
        doer.profileName = 'Collectible';
      }
      break;

    case 'COLLECTIBLE_PUBLISHED_FOLLOWERS':
      {
        const user = event.creatorProfile;
        doer.id = user.id;
        doer.fullName = (user.displayName || user.firstName + ' ' + user.lastName).trim();
        doer.username = user.username;
        doer.media = {
          avatarUrl: user.avatarUrl
        };
        collectible.mediaThumbnail = event.collectible.mediaThumbnail;
        doer.profileName = 'Collectible';
      }
      break;

    case 'COLLECTIBLE_RELEASED_FOLLOWERS':
      {
        const released = event.collectible.releases
          ?.filter((x) => x.isReleased === 'TRUE')
          .sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate));
        doer.tier = released?.[0].tierType;
        doer.releasedCount = released?.[0].releaseCount;
        const user = event.creatorProfile;
        doer.id = user.id;
        doer.fullName = (user.displayName || user.firstName + ' ' + user.lastName).trim();
        doer.username = user.username;
        doer.media = {
          avatarUrl: user.avatarUrl
        };
        collectible.mediaThumbnail = event.collectible.mediaThumbnail;
        doer.profileName = 'Collectible';
      }
      break;

    case 'AUCTION_BID_OUTBID_BUYER':
      {
        const user = event.patronUser;
        doer.id = user.id;
        doer.fullName = (user.displayName || user.firstName + ' ' + user.lastName).trim();
        doer.username = user.username;
        doer.price = currencyFormatter.format(Number(event.exchange.bidPrice));
        doer.media = {
          avatarUrl: user.avatarUrl
        };
        collectible.mediaThumbnail = event.collectible.mediaThumbnail;
        doer.profileName = 'Collectible';
      }
      break;

    case 'PRINT_ORDER_STATUS_UPDATED_BUYER':
      {
        const user = event.ownerUser;
        doer.id = user.id;
        doer.tier = '(Limited Edition #' + event.exchange.serialIndex + ')';
        collectible.mediaThumbnail = event.collectible.mediaThumbnail;
        doer.profileName = 'Collectible';
      }
      break;

    case 'WITHDRAW_REQUESTED_FANS':
      {
        const user = event.ownerUser;
        doer.id = user.id;
        doer.amount = currencyFormatter.format(Number(event?.wallet?.credit || event?.amount));
        doer.media = {
          avatarUrl: user.avatarUrl
        };
      }
      break;

    case 'WITHDRAW_REJECTED_FANS':
      {
        const user = event.ownerUser;
        doer.id = user.id;
        doer.amount = currencyFormatter.format(Number(event.wallet.credit));
        doer.media = {
          avatarUrl: user.avatarUrl
        };
      }
      break;

    case 'WITHDRAW_APPROVED_FANS':
      {
        const user = event.ownerUser;
        doer.id = user.id;
        doer.amount = currencyFormatter.format(Number(event.wallet.credit));
        doer.media = {
          avatarUrl: user.avatarUrl
        };
        doer.profileName = (user.displayName || user.firstName + ' ' + user.lastName).trim();
      }
      break;

    case 'AUCTION_BID_SUBMITTED_FANSELLER':
      {
        const user = event.patronUser;
        doer.id = user.id;
        doer.tier = '(Limited Edition #' + event.exchange.serialIndex + ')';
        doer.price = event.exchange.offerPrice
          ? currencyFormatter.format(Number(event.exchange.offerPrice))
          : currencyFormatter.format(Number(event.exchange.bidPrice));
        doer.fullName = (user.firstName + ' ' + user.lastName).trim();
        doer.username = user.username;
        doer.media = {
          avatarUrl: user.avatarUrl
        };
        doer.profileName = (user.displayName || user.firstName + ' ' + user.lastName).trim();
      }
      break;

    case 'COLLECTIBLE_BID_RECEIVED':
      {
        const user = event.patronUser;
        doer.id = user.id;
        doer.tier = '(Limited Edition #' + event.exchange.serialIndex + ')';
        doer.price = event.exchange.offerPrice
          ? currencyFormatter.format(Number(event.exchange.offerPrice))
          : currencyFormatter.format(Number(event.exchange.bidPrice));
        doer.destination = '/settings/offers-bids';
        doer.fullName = (user.firstName + ' ' + user.lastName).trim();
        doer.username = user.username;
        doer.media = {
          avatarUrl: user.avatarUrl
        };
        doer.profileName = (user.displayName || user.firstName + ' ' + user.lastName).trim();
      }
      break;

    case 'OFFER_SUBMITTED_FANOWNER':
      {
        const user = event.patronUser;
        doer.id = user.id;
        doer.tier = '(Limited Edition #' + event.exchange.serialIndex + ')';
        doer.price = event.exchange.offerPrice
          ? currencyFormatter.format(Number(event.exchange.offerPrice))
          : currencyFormatter.format(Number(event.exchange.bidPrice));
        doer.destination = '/settings/offers-bids?t=OffersReceived';
        doer.fullName = (user.firstName + ' ' + user.lastName).trim();
        doer.username = user.username;
        doer.media = {
          avatarUrl: user.avatarUrl
        };
        collectible.mediaThumbnail = event.collectible.mediaThumbnail;
        doer.profileName = 'Collectible';
      }
      break;

    case 'COLLECTIBLE_BOUGHT_BUYER':
      {
        const creator = event.creatorUser;

        doer.id = creator.id;
        doer.fullName = (creator.displayName || creator.firstName + ' ' + creator.lastName).trim();
        doer.username = creator.username;
        doer.tier = '(Limited Edition #' + event.exchange.serialIndex + ')';
        doer.media = {
          avatarUrl: creator.avatarUrl
        };
        collectible.mediaThumbnail = event.collectible.mediaThumbnail;
        doer.profileName = 'Collectible';
      }
      break;

    case 'AUCTION_WON_BIDDER':
      {
        const owner = event.ownerUser;
        doer.id = owner.id;
        doer.tier = '(Limited Edition #' + event.exchange.serialIndex + ')';
        doer.price = currencyFormatter.format(Number(event.exchange.sellingPrice));
        doer.fullName = (owner.firstName + ' ' + owner.lastName).trim();
        doer.username = owner.username;
        doer.media = {
          avatarUrl: owner.avatarUrl
        };
        collectible.mediaThumbnail = event.collectible.mediaThumbnail;
        doer.profileName = 'Collectible';
      }
      break;

    case 'COLLECTIBLE_SUBMITTED_ADMIN':
      {
        const user = event.creatorProfile;
        doer.id = user.id;
        doer.fullName = (user.displayName || user.firstName + ' ' + user.lastName).trim();
        doer.username = user.username;
        doer.media = {
          avatarUrl: user.avatarUrl
        };
        doer.profileName = (user.displayName || user.firstName + ' ' + user.lastName).trim();
      }
      break;

    case 'OFFER_EXPIRED_BUYER':
    case 'OFFER_WITHDRAWN_BUYER':
    case 'OFFER_WITHDRAWN_SELLER':
      {
        const patron = event.patronUser;
        doer.id = patron.id;
        doer.tier = '(Limited Edition #' + event.exchange.serialIndex + ')';
        doer.fullName = (patron.firstName + ' ' + patron.lastName).trim();
        doer.username = patron.username;
        doer.media = {
          avatarUrl: patron.avatarUrl
        };

        collectible.mediaThumbnail = event.collectible.mediaThumbnail;
        doer.profileName = 'Collectible';
      }
      break;

    case 'OFFER_REJECTED_BUYER':
      {
        const patron = event.patronUser;
        doer.id = patron.id;
        doer.tier = '(Limited Edition #' + event.exchange.serialIndex + ')';
        doer.price = currencyFormatter.format(Number(event.exchange.offerPrice));
        doer.fullName = (patron.firstName + ' ' + patron.lastName).trim();
        doer.username = patron.username;
        doer.media = {
          avatarUrl: patron.avatarUrl
        };

        collectible.mediaThumbnail = event.collectible.mediaThumbnail;
        doer.profileName = 'Collectible';
      }
      break;

    case 'OFFER_PAYMENT_REQUESTED_SELLER_BITPAY':
      {
        const owner = event.ownerUser;
        doer.id = owner.id;
        doer.fullName = (owner.firstName + ' ' + owner.lastName).trim();
        doer.username = owner.username;
        doer.tier = '(Limited Edition #' + event.exchange.serialIndex + ')';
        doer.price = currencyFormatter.format(Number(event.offer.offerPrice));
        doer.media = {
          avatarUrl: owner.avatarUrl
        };

        collectible.mediaThumbnail = event.collectible.mediaThumbnail;
        doer.profileName = 'Collectible';
      }
      break;

    case 'AUCTION_WON_WINNER':
      {
        const owner = event.ownerUser;
        doer.id = owner.id;
        doer.tier = '(Limited Edition #' + event.exchange.serialIndex + ')';
        doer.price = currencyFormatter.format(Number(event.exchange.sellingPrice));
        doer.fullName = (owner.firstName + ' ' + owner.lastName).trim();
        doer.username = owner.username;
        doer.media = {
          avatarUrl: owner.avatarUrl
        };
        collectible.mediaThumbnail = event.collectible.mediaThumbnail;
        doer.profileName = 'Collectible';
      }
      break;

    case 'OFFER_PAYMENT_CANCELLED_BUYER_BITPAY':
      {
        const patron = event.patronUser;
        doer.id = patron.id;
        doer.tier = '(Limited Edition #' + event.exchange.serialIndex + ')';
        doer.fullName = (patron.firstName + ' ' + patron.lastName).trim();
        doer.username = patron.username;
        doer.media = {
          avatarUrl: patron.avatarUrl
        };

        collectible.mediaThumbnail = event.collectible.mediaThumbnail;
        doer.profileName = 'Collectible';
      }
      break;

    case 'OFFER_PAYMENT_CANCELLED_SELLER_BITPAY':
      {
        const patron = event.patronUser;
        doer.id = patron.id;
        doer.tier = '(Limited Edition #' + event.exchange.serialIndex + ')';
        doer.fullName = (patron.firstName + ' ' + patron.lastName).trim();
        doer.username = patron.username;
        doer.media = {
          avatarUrl: patron.avatarUrl
        };

        collectible.mediaThumbnail = event.collectible.mediaThumbnail;
        doer.profileName = 'Collectible';
      }
      break;

    case 'COLLECTIBLE_RESERVED_BUYER':
      {
        const owner = event.ownerUser;
        doer.id = owner.id;
        doer.fullName = (owner.firstName + ' ' + owner.lastName).trim();
        doer.username = owner.username;
        doer.media = {
          avatarUrl: owner.avatarUrl
        };

        collectible.mediaThumbnail = event.collectible.mediaThumbnail;
        doer.profileName = 'Collectible';
      }
      break;

    case 'COLLECTIBLE_RESERVED_SELLER':
      {
        const owner = event.ownerUser;
        doer.id = owner.id;
        doer.fullName = (owner.firstName + ' ' + owner.lastName).trim();
        doer.username = owner.username;
        doer.media = {
          avatarUrl: owner.avatarUrl
        };
        doer.tier = '(Limited Edition #' + event.exchange.serialIndex + ')';

        collectible.mediaThumbnail = event.collectible.mediaThumbnail;
        doer.profileName = 'Collectible';
      }
      break;

    case 'FCLUB_THREAD_CREATED_CREATOR':
      {
        const owner = event.creatorUser;
        doer.id = owner.id;
        doer.fullName = (owner.displayName || owner.firstName + ' ' + owner.lastName).trim();
        doer.username = owner.username;
        doer.media = {
          avatarUrl: owner.avatarUrl
        };
        doer.profileName = (owner.displayName || owner.firstName + ' ' + owner.lastName).trim();
      }
      break;

    case 'FCLUB_COMMENT_CREATED':
      {
        const owner = event.creatorUser;
        doer.id = owner.id;
        doer.fullName = (owner.displayName || owner.firstName + ' ' + owner.lastName).trim();
        doer.username = owner.username;
        doer.media = {
          avatarUrl: owner.avatarUrl
        };
        doer.profileName = (owner.displayName || owner.firstName + ' ' + owner.lastName).trim();
      }
      break;

    case 'FCLUB_THREAD_REPORTED':
      {
        const owner = event.creatorUser;
        doer.id = owner.id;
        doer.fullName = (owner.displayName || owner.firstName + ' ' + owner.lastName).trim();
        doer.username = owner.username;
        doer.media = {
          avatarUrl: owner.avatarUrl
        };
        doer.profileName = (owner.displayName || owner.firstName + ' ' + owner.lastName).trim();
      }
      break;

    case 'FCLUB_THREAD_LIKED':
      {
        const owner = event.creatorUser;
        doer.id = owner.id;
        doer.fullName = (owner.displayName || owner.firstName + ' ' + owner.lastName).trim();
        doer.username = owner.username;
        doer.media = {
          avatarUrl: owner.avatarUrl
        };
        doer.profileName = (owner.displayName || owner.firstName + ' ' + owner.lastName).trim();
      }
      break;

    case 'FCLUB_THREAD_DELETED_BY_CREATOR':
      {
        const owner = event.creatorUser;
        doer.id = owner.id;
        doer.fullName = (owner.displayName || owner.firstName + ' ' + owner.lastName).trim();
        doer.username = owner.username;
        doer.media = {
          avatarUrl: owner.avatarUrl
        };
        doer.profileName = (owner.displayName || owner.firstName + ' ' + owner.lastName).trim();
      }
      break;

    case 'FCLUB_THREAD_FEATURED_FANS':
      {
        const owner = event.creatorUser;
        doer.id = owner.id;
        doer.fullName = (owner.displayName || owner.firstName + ' ' + owner.lastName).trim();
        doer.username = owner.username;
        doer.media = {
          avatarUrl: owner.avatarUrl
        };
        doer.profileName = (owner.displayName || owner.firstName + ' ' + owner.lastName).trim();
      }
      break;

    default:
      doer.id = '';
      doer.fullName = '';
      doer.username = '';
      break;
  }

  const { onUpdateNotification } = useUpdateReadNotification(notification?.id);
  const onUpdate = async () => {
    if (notification.searchableRead === 'FALSE') {
      readNotification(notification?.id);
      const data = {
        id: notification?.id,
        itemType: 'notification'
      };
      await onUpdateNotification(data);
    }
  };

  return (
    <Box
      onClick={() => onUpdate()}
      className={cx(
        'notification-menu-patrons flex py-4 text-xl transition-colors border-t relative',
        className
      )}
      {...props}
    >
      {notification.searchableRead === 'TRUE' ? (
        <Box
          className={
            dropdown
              ? 'unread-dropdown flex items-center justify-center p-1 mx-2 bg-transparent rounded-full'
              : 'unread-page flex items-center justify-center p-1 mx-2 bg-transparent rounded-full md:mx-3'
          }
        ></Box>
      ) : (
        <Box
          className={
            dropdown
              ? 'unread-dropdown flex items-center justify-center p-1 mx-2 bg-red-300 rounded-full'
              : 'unread-page flex items-center justify-center p-1 mx-2 bg-red-300 rounded-full md:mx-3'
          }
        ></Box>
      )}

      <Box
        className={
          dropdown
            ? 'flex items-center justify-center w-11 h-11'
            : 'flex items-center justify-center w-14 h-14'
        }
      >
        {linkRedirect ? (
          <Link to={linkRedirect}>
            {collectible.mediaThumbnail ? (
              <Image
                size={600}
                src={collectible.mediaThumbnail}
                className="object-cover rounded-full"
              />
            ) : notification.type === 'WITHDRAW_APPROVED_FANS' ? (
              <img src={Logo} alt="" className="object-cover rounded-full w-14 h-14" />
            ) : (
              <Avatar user={doer} size="full" className="object-cover rounded-full w-14 h-14" />
            )}
          </Link>
        ) : (
          <Box>
            {collectible.mediaThumbnail ? (
              <Image
                size={600}
                src={collectible.mediaThumbnail}
                className="object-cover rounded-full"
              />
            ) : notification.type === 'WITHDRAW_APPROVED_FANS' ? (
              <img src={Logo} alt="" className="object-cover rounded-full w-14 h-14" />
            ) : (
              <Avatar user={doer} size="full" className="object-cover rounded-full w-14 h-14" />
            )}
          </Box>
        )}
      </Box>
      <Box
        className={
          dropdown
            ? 'flex flex-col items-start justify-start w-full pl-4 pr-4 text-sm'
            : 'block lg:flex flex-col items-center justify-center w-full pl-4 pr-6 text-sm md:flex-row md:justify-between'
        }
      >
        <Box
          className={
            dropdown
              ? 'text-base font-medium text-primary'
              : 'text-sm font-medium md:text-xl text-secondary order-last lg:order-first'
          }
        >
          <Box className={dropdown ? 'hidden' : 'font-bold capitalize text-xl text-primary'}>
            {linkRedirect ? (
              <>
                <Link to={linkRedirect}>{doer.profileName}</Link>
                <Box style={{ color: '#979797' }} className="text-base font-medium block lg:hidden">
                  {notificationTime}
                </Box>
              </>
            ) : (
              <>
                <Box>{doer.profileName}</Box>
                <Box style={{ color: '#979797' }} className="text-base font-medium block lg:hidden">
                  {notificationTime}
                </Box>
              </>
            )}
          </Box>
          {(() => {
            switch (notification?.type) {
              case 'COLLECTIBLE_MINTED':
                return <CollectibleMintedNotification notification={notification} doer={doer} />;

              case 'USER_FOLLOWED_FOLLOWEE':
                useEffect(() => {
                  if (notification) {
                    setLinkRedirect(`/${doer.username}`);
                  }
                }, [notification]);
                return (
                  <>
                    <Link
                      style={{ textDecoration: 'none' }}
                      className="text-secondary text-lg font-normal"
                      to={`/${doer.username}`}
                    >
                      <Trans i18nKey="notifications.USER_FOLLOWED_FOLLOWEE">
                        {{ fullName: doer.fullName }}
                      </Trans>
                    </Link>
                  </>
                );

              case 'COLLECTIBLE_LIKE': {
                const collectible = notification?.event?.collectible;
                const collectibleName = collectible.title;
                useEffect(() => {
                  if (notification) {
                    setLinkRedirect(`/${doer.username}`);
                  }
                }, [notification]);
                return (
                  <>
                    <Link to={`/${doer.username}`}>
                      <Box className="text-secondary text-lg font-normal">
                        <Trans i18nKey="notifications.COLLECTIBLE_LIKE">
                          {{ fullName: doer.fullName }}
                          {{ collectibleName }}
                        </Trans>
                      </Box>
                    </Link>
                  </>
                );
              }

              case 'COLLECTIBLE_BID_MADE': {
                const creator = notification?.event?.creatorUser;
                const collectible = notification?.event?.collectible;
                useEffect(() => {
                  if (notification) {
                    setLinkRedirect(`/${creator.username}/${collectible.slug}`);
                  }
                }, [notification]);

                return (
                  <>
                    <Link
                      className="text-secondary text-lg font-normal"
                      style={{ textDecoration: 'none' }}
                      to={`/${creator.username}/${collectible.slug}`}
                    >
                      <Trans i18nKey={`notifications.${notification?.type}`}>
                        {{ price: doer.price }}
                        {{ collectibleName: collectible.title }}
                        {{ tier: doer.tier }}
                      </Trans>
                    </Link>
                  </>
                );
              }

              case 'AUCTION_BID_SUBMITTED_BUYER': {
                const creator = notification?.event?.creatorUser;
                const collectible = notification?.event?.collectible;
                const exchange = notification?.event?.exchange;
                useEffect(() => {
                  if (notification) {
                    setLinkRedirect(
                      `/${creator.username}/${collectible.slug}/${exchange.serialIndex}`
                    );
                  }
                }, [notification]);

                return (
                  <>
                    <Link
                      className="text-secondary text-lg font-normal"
                      style={{ textDecoration: 'none' }}
                      to={`/${creator.username}/${collectible.slug}/${exchange.serialIndex}`}
                    >
                      <Trans i18nKey={`notifications.${notification?.type}`}>
                        {{ price: doer.price }}
                        {{ collectibleName: collectible.title }}
                        {{ tier: doer.tier }}
                      </Trans>
                    </Link>
                  </>
                );
              }

              case 'PRINT_ORDER_STATUS_UPDATED_BUYER': {
                const collectible = notification?.event?.collectible;
                const statusDescription =
                  notification?.event?.orderPrint?.orderStatus === 'In Production'
                    ? 'framed print is being processed!'
                    : notification?.event?.orderPrint?.orderStatus === 'Shipping'
                    ? 'framed print is on the way to you!'
                    : notification?.event?.orderPrint?.orderStatus === 'Complete'
                    ? 'framed print order is completed.'
                    : 'has been cancelled.';
                useEffect(() => {
                  if (notification) {
                    setLinkRedirect(`/settings/transactions`);
                  }
                }, [notification]);
                return (
                  <>
                    <Box
                      className="text-secondary text-lg font-normal"
                      style={{ textDecoration: 'none' }}
                      onClick={() => history.replace(`/settings/transactions?t=Order`)}
                    >
                      <Trans i18nKey={`notifications.${notification?.type}`}>
                        {{ collectibleName: collectible.title }}
                        {{ tier: doer.tier }}
                        {{ description: statusDescription }}
                      </Trans>
                    </Box>
                  </>
                );
              }

              case 'OFFER_SUBMITTED_BUYER': {
                const collectible = notification?.event?.collectible;
                useEffect(() => {
                  if (notification) {
                    setLinkRedirect(`/settings/offers-bids`);
                  }
                }, [notification]);

                return (
                  <>
                    <Link
                      style={{ textDecoration: 'none' }}
                      className="text-secondary text-lg font-normal"
                      to="/settings/offers-bids"
                    >
                      <Trans i18nKey={`notifications.${notification?.type}`}>
                        {{ price: doer.price }}
                        {{ collectibleName: collectible.title }}
                        {{ tier: doer.tier }}
                      </Trans>
                    </Link>
                  </>
                );
              }

              case 'COLLECTIBLE_SOLD_FANOWNER': {
                const collectible = notification?.event?.collectible;
                const creator = notification?.event?.creatorUser;
                useEffect(() => {
                  if (notification) {
                    setLinkRedirect(`/${creator.username}/${collectible.slug}`);
                  }
                }, [notification]);
                return (
                  <>
                    <Link
                      className="text-secondary text-lg font-normal"
                      style={{ textDecoration: 'none' }}
                      to={`/${creator.username}/${collectible.slug}`}
                    >
                      <div className="text-secondary">
                        <Trans i18nKey={`notifications.${notification?.type}`}>
                          {{ collectibleName: collectible.title }}
                          {{ price: doer.price }}
                          {{ fullName: doer.fullName }}
                        </Trans>
                      </div>
                    </Link>
                  </>
                );
              }

              case 'OFFER_PAYMENT_REQUESTED_SELLER_BITPAY': {
                const creator = notification?.event?.creatorUser;
                const collectible = notification?.event?.collectible;
                useEffect(() => {
                  if (notification) {
                    setLinkRedirect(`/${creator.username}/${collectible.slug}`);
                  }
                }, [notification]);
                return (
                  <>
                    <Link
                      className="text-secondary text-lg font-normal"
                      style={{ textDecoration: 'none' }}
                      to={`/${creator.username}/${collectible.slug}`}
                    >
                      <div className="text-secondary">
                        <Trans i18nKey={`notifications.${notification?.type}`}>
                          {{ collectibleName: collectible.title }}
                          {{ price: doer.price }}
                          {{ tier: doer.tier }}
                        </Trans>
                      </div>
                    </Link>
                  </>
                );
              }

              case 'OFFER_ACCEPTED_BUYER': {
                const collectible = notification?.event?.collectible;
                const creator = notification?.event?.creatorUser;
                const exchange = notification?.event?.exchange;
                useEffect(() => {
                  if (notification) {
                    setLinkRedirect(
                      `/${creator.username}/${collectible.slug}/${exchange.serialIndex}`
                    );
                  }
                }, [notification]);
                return (
                  <>
                    <Link
                      className="text-secondary text-lg font-normal"
                      style={{ textDecoration: 'none' }}
                      to={`/${creator.username}/${collectible.slug}/${exchange.serialIndex}`}
                    >
                      <div className="text-secondary">
                        <Trans i18nKey={`notifications.${notification?.type}`}>
                          {{ price: doer.price }}
                          {{ collectibleName: collectible.title }}
                          {{ tier: doer.tier }}
                        </Trans>
                      </div>
                    </Link>
                  </>
                );
              }

              case 'OFFER_ACCEPTED_BUYER_BITPAY': {
                const exchange = notification?.event?.exchange;
                const collectible = notification?.event?.collectible;
                const owner = notification?.event?.ownerUser;
                useEffect(() => {
                  if (notification) {
                    setLinkRedirect(
                      `/${doer.username}/${collectible.slug}/${exchange.serialIndex}/${owner.username}`
                    );
                  }
                }, [notification]);
                return (
                  <>
                    <Link
                      className="text-secondary text-lg font-normal"
                      style={{ textDecoration: 'none' }}
                      to={`/${doer.username}/${collectible.slug}/${exchange.serialIndex}/${owner.username}`}
                    >
                      <Trans i18nKey={`notifications.${notification?.type}`}>
                        {{ collectibleName: collectible.title }}
                        {{ tier: doer.tier }}
                        {{ fullName: doer.fullName }}
                      </Trans>
                    </Link>
                  </>
                );
              }

              case 'OFFER_PAYMENT_REQUESTED_BUYER_BITPAY': {
                const collectible = notification?.event?.collectible;
                const offer = notification?.event?.offer;
                useEffect(() => {
                  if (notification) {
                    setLinkRedirect(`/bitpay-payment/${offer.id}`);
                  }
                }, [notification]);
                return (
                  <>
                    <Link style={{ textDecoration: 'none' }} to={`/bitpay-payment/${offer.id}`}>
                      <div className="text-secondary text-lg font-normal">
                        <Trans i18nKey={`notifications.${notification?.type}`}>
                          {{ price: doer.price }}
                          {{ collectibleName: collectible.title }}
                          {{ tier: doer.tier }}
                        </Trans>
                      </div>
                    </Link>
                  </>
                );
              }

              case 'COLLECTIBLE_PUBLISHED_FOLLOWERS': {
                const collectible = notification?.event?.collectible;
                const creator = notification?.event?.creatorProfile;
                useEffect(() => {
                  if (notification) {
                    setLinkRedirect(`/${creator.username}/${collectible.slug}`);
                  }
                }, [notification]);
                return (
                  <>
                    <Link
                      style={{ textDecoration: 'none' }}
                      to={`/${creator.username}/${collectible.slug}`}
                    >
                      <div className="text-secondary text-lg font-normal">
                        <Trans i18nKey={`notifications.${notification?.type}`}>
                          {{ fullName: doer.fullName }}
                          {{ collectibleName: collectible.title }}
                          {{ collectibleCategory: collectible.category }}
                        </Trans>
                      </div>
                    </Link>
                  </>
                );
              }

              case 'COLLECTIBLE_RELEASED_FOLLOWERS': {
                const collectible = notification?.event?.collectible;
                const creator = notification?.event?.creatorProfile;
                useEffect(() => {
                  if (notification) {
                    setLinkRedirect(`notifications.${notification?.type}_${doer.tier}`);
                  }
                }, [notification]);
                return (
                  <>
                    <Link
                      style={{ textDecoration: 'none' }}
                      to={`/${creator.username}/${collectible.slug}`}
                    >
                      <div className="text-secondary text-lg font-normal">
                        <Trans i18nKey={`notifications.${notification?.type}_${doer.tier}`}>
                          {{ collectibleName: collectible.title }}
                          {{ collectibleCategory: collectible.category }}
                          {{ releasedCount: doer.releasedCount }}
                          {{ fullName: doer.fullName }}
                        </Trans>
                      </div>
                    </Link>
                  </>
                );
              }

              case 'OFFER_PAYMENT_CANCELLED_BUYER_BITPAY': {
                const exchange = notification?.event?.exchange;
                const collectible = notification?.event?.collectible;
                const creator = notification?.event?.creatorUser;
                useEffect(() => {
                  if (notification) {
                    setLinkRedirect(
                      `/${creator.username}/${collectible.slug}/${exchange.serialIndex}`
                    );
                  }
                }, [notification]);
                return (
                  <>
                    <Link
                      className="text-secondary text-lg font-normal"
                      style={{ textDecoration: 'none' }}
                      to={`/${creator.username}/${collectible.slug}/${exchange.serialIndex}`}
                    >
                      <Trans i18nKey={`notifications.${notification?.type}`}>
                        {{ collectibleName: collectible.title }}
                        {{ tier: doer.tier }}
                      </Trans>
                    </Link>
                  </>
                );
              }

              case 'OFFER_PAYMENT_CANCELLED_SELLER_BITPAY': {
                const exchange = notification?.event?.exchange;
                const collectible = notification?.event?.collectible;
                const creator = notification?.event?.creatorUser;
                useEffect(() => {
                  if (notification) {
                    setLinkRedirect(
                      `/${creator.username}/${collectible.slug}/${exchange.serialIndex}`
                    );
                  }
                }, [notification]);
                return (
                  <>
                    <Link
                      className="text-secondary text-lg font-normal"
                      style={{ textDecoration: 'none' }}
                      to={`/${creator.username}/${collectible.slug}/${exchange.serialIndex}`}
                    >
                      <Trans i18nKey={`notifications.${notification?.type}`}>
                        {{ fullName: doer.fullName }}
                        {{ collectibleName: collectible.title }}
                        {{ tier: doer.tier }}
                      </Trans>
                    </Link>
                  </>
                );
              }

              case 'AUCTION_BID_OUTBID_BUYER': {
                const collectible = notification?.event?.collectible;
                const creator = notification?.event?.creatorUser;
                const exchange = notification?.event?.exchange;
                useEffect(() => {
                  if (notification) {
                    setLinkRedirect(
                      `/${creator.username}/${collectible.slug}/${exchange.serialIndex}`
                    );
                  }
                }, [notification]);
                return (
                  <>
                    <Link
                      style={{ textDecoration: 'none' }}
                      to={`/${creator.username}/${collectible.slug}/${exchange.serialIndex}`}
                    >
                      <div className="text-secondary text-lg font-normal">
                        <Trans i18nKey={`notifications.${notification?.type}`}>
                          {{ fullName: doer.fullName }}
                          {{ collectibleName: collectible.title }}
                        </Trans>
                      </div>
                    </Link>
                  </>
                );
              }

              case 'COLLECTIBLE_BID_RECEIVED': {
                const collectible = notification?.event?.collectible;
                useEffect(() => {
                  if (notification) {
                    setLinkRedirect(doer.destination);
                  }
                }, [notification]);
                return (
                  <>
                    <Link
                      className="text-secondary text-lg font-normal"
                      style={{ textDecoration: 'none' }}
                      to={doer.destination}
                    >
                      <Trans i18nKey={`notifications.${notification?.type}`}>
                        {{ price: doer.price }}
                        {{ collectibleName: collectible.title }}
                        {{ tier: doer.tier }}
                      </Trans>
                    </Link>
                  </>
                );
              }

              case 'AUCTION_BID_SUBMITTED_FANSELLER': {
                const creator = notification?.event?.creatorUser;
                const collectible = notification?.event?.collectible;
                const exchange = notification?.event?.exchange;
                useEffect(() => {
                  if (notification) {
                    setLinkRedirect(
                      `/${creator.username}/${collectible.slug}/${exchange.serialIndex}`
                    );
                  }
                }, [notification]);

                return (
                  <>
                    <Link
                      className="text-secondary text-lg font-normal"
                      style={{ textDecoration: 'none' }}
                      to={`/${creator.username}/${collectible.slug}/${exchange.serialIndex}`}
                    >
                      <Trans i18nKey={`notifications.${notification?.type}`}>
                        {{ fullName: doer.fullName }}
                        {{ price: doer.price }}
                        {{ collectibleName: collectible.title }}
                        {{ tier: doer.tier }}
                      </Trans>
                    </Link>
                  </>
                );
              }

              case 'OFFER_SUBMITTED_FANOWNER': {
                const collectible = notification?.event?.collectible;
                useEffect(() => {
                  if (notification) {
                    setLinkRedirect(`/settings/offers-bids`);
                  }
                }, [notification]);
                return (
                  <>
                    <Box
                      className="text-secondary text-lg font-normal"
                      style={{ textDecoration: 'none' }}
                      onClick={() => history.replace(doer.destination)}
                    >
                      <Trans i18nKey={`notifications.${notification?.type}`}>
                        {{ price: doer.price }}
                        {{ collectibleName: collectible.title }}
                        {{ tier: doer.tier }}
                        {{ fullName: doer.fullName }}
                      </Trans>
                    </Box>
                  </>
                );
              }

              case 'COLLECTIBLE_SUBMITTED_ADMIN': {
                const collectible = notification?.event?.collectible;
                return (
                  <>
                    <Link style={{ textDecoration: 'none' }}>
                      <div className="text-secondary text-lg font-normal">
                        <Trans i18nKey={`notifications.${notification?.type}`}>
                          {{ fullName: doer.fullName }}
                          {{ collectibleName: collectible.title }}
                        </Trans>
                      </div>
                    </Link>
                  </>
                );
              }

              case 'COLLECTIBLE_BOUGHT_BUYER': {
                const exchange = notification?.event?.exchange;
                const collectible = notification?.event?.collectible;
                const owner = notification?.event?.ownerUser;
                useEffect(() => {
                  if (notification) {
                    setLinkRedirect(
                      `/${doer.username}/${collectible.slug}/${exchange.serialIndex}/${owner.username}`
                    );
                  }
                }, [notification]);

                return (
                  <>
                    <Link
                      className="text-secondary text-lg font-normal"
                      style={{ textDecoration: 'none' }}
                      to={`/${doer.username}/${collectible.slug}/${exchange.serialIndex}/${owner.username}`}
                    >
                      <Trans i18nKey={`notifications.${notification?.type}`}>
                        {{ collectibleName: collectible.title }}
                        {{ tier: doer.tier }}
                        {{ fullName: doer.fullName }}
                      </Trans>
                    </Link>
                  </>
                );
              }

              case 'AUCTION_WON_BIDDER': {
                const exchange = notification?.event?.exchange;
                const collectible = notification?.event?.collectible;
                useEffect(() => {
                  if (notification) {
                    setLinkRedirect(
                      `/${doer.username}/${collectible.slug}/${exchange.serialIndex}`
                    );
                  }
                }, [notification]);
                return (
                  <>
                    <Link
                      className="text-secondary text-lg font-normal"
                      style={{ textDecoration: 'none' }}
                      to={`/${doer.username}/${collectible.slug}/${exchange.serialIndex}`}
                    >
                      <Trans i18nKey={`notifications.${notification?.type}`}>
                        {{ collectibleName: collectible.title }}
                        {{ tier: doer.tier }}
                        {{ price: doer.price }}
                      </Trans>
                    </Link>
                  </>
                );
              }
              case 'OFFER_WITHDRAWN_SELLER': {
                const collectible = notification?.event?.collectible;
                useEffect(() => {
                  if (notification) {
                    setLinkRedirect(`/settings/offers-bids`);
                  }
                }, [notification]);
                return (
                  <>
                    <Link
                      className="text-secondary text-lg font-normal"
                      style={{ textDecoration: 'none' }}
                      to={`/settings/offers-bids`}
                    >
                      <Trans i18nKey={`notifications.${notification?.type}`}>
                        {{ collectibleName: collectible.title }}
                        {{ tier: doer.tier }}
                        {{ fullName: doer.fullName }}
                      </Trans>
                    </Link>
                  </>
                );
              }
              case 'OFFER_EXPIRED_BUYER':
              case 'OFFER_WITHDRAWN_BUYER': {
                const exchange = notification?.event?.exchange;
                const collectible = notification?.event?.collectible;
                const creator = notification?.event?.creatorUser;
                useEffect(() => {
                  if (notification) {
                    setLinkRedirect(
                      `/${creator.username}/${collectible.slug}/${exchange.serialIndex}`
                    );
                  }
                }, [notification]);
                return (
                  <>
                    <Link
                      className="text-secondary text-lg font-normal"
                      style={{ textDecoration: 'none' }}
                      to={`/${creator.username}/${collectible.slug}/${exchange.serialIndex}`}
                    >
                      <Trans i18nKey={`notifications.${notification?.type}`}>
                        {{ collectibleName: collectible.title }}
                        {{ tier: doer.tier }}
                      </Trans>
                    </Link>
                  </>
                );
              }
              case 'OFFER_REJECTED_BUYER': {
                const exchange = notification?.event?.exchange;
                const collectible = notification?.event?.collectible;
                const creator = notification?.event?.creatorUser;
                useEffect(() => {
                  if (notification) {
                    setLinkRedirect(
                      `/${creator.username}/${collectible.slug}/${exchange.serialIndex}`
                    );
                  }
                }, [notification]);
                return (
                  <>
                    <Link
                      className="text-secondary text-lg font-normal"
                      style={{ textDecoration: 'none' }}
                      to={`/${creator.username}/${collectible.slug}/${exchange.serialIndex}`}
                    >
                      <Trans i18nKey={`notifications.${notification?.type}`}>
                        {{ collectibleName: collectible.title }}
                        {{ tier: doer.tier }}
                        {{ price: doer.price }}
                      </Trans>
                    </Link>
                  </>
                );
              }
              case 'PRINT_PURCHASE_CONFIRMED': {
                const exchange = notification?.event?.printedItem;
                const collectible = notification?.event?.collectible;
                useEffect(() => {
                  if (notification) {
                    setLinkRedirect(
                      `/${doer.username}/${collectible.slug}/${exchange.serialIndex}`
                    );
                  }
                }, [notification]);
                return (
                  <>
                    <Link
                      className="text-secondary text-lg font-normal"
                      style={{ textDecoration: 'none' }}
                      to={`/${doer.username}/${collectible.slug}/${exchange.serialIndex}`}
                    >
                      <Trans i18nKey={`notifications.${notification?.type}`}>
                        {{ collectibleName: collectible.title }}
                        {{ tier: doer.tier }}
                        {{ price: doer.price }}
                      </Trans>
                    </Link>
                  </>
                );
              }

              case 'PRINT_ORDER_PAYMENT_CONFIRMED_OWNER': {
                const exchange = notification?.event?.exchange;
                const collectible = notification?.event?.collectible;
                useEffect(() => {
                  if (notification) {
                    setLinkRedirect(`/settings/transactions`);
                  }
                }, [notification]);
                return (
                  <>
                    <Box
                      className="text-secondary text-lg font-normal"
                      style={{ textDecoration: 'none' }}
                      onClick={() => history.replace(`/settings/transactions?t=Order`)}
                    >
                      <Trans i18nKey={`notifications.${notification?.type}`}>
                        {{ collectibleName: collectible.title }}
                        {{ tier: doer.tier }}
                      </Trans>
                    </Box>
                  </>
                );
              }

              case 'AUCTION_WON_WINNER': {
                const exchange = notification?.event?.exchange;
                const collectible = notification?.event?.collectible;
                useEffect(() => {
                  if (notification) {
                    setLinkRedirect(
                      `/${doer.username}/${collectible.slug}/${exchange.serialIndex}`
                    );
                  }
                }, [notification]);
                return (
                  <>
                    <Link
                      className="text-secondary text-lg font-normal"
                      style={{ textDecoration: 'none' }}
                      to={`/${doer.username}/${collectible.slug}/${exchange.serialIndex}`}
                    >
                      <Trans i18nKey={`notifications.${notification?.type}`}>
                        {{ collectibleName: collectible.title }}
                        {{ tier: doer.tier }}
                      </Trans>
                    </Link>
                  </>
                );
              }

              case 'WITHDRAW_REQUESTED_FANS': {
                useEffect(() => {
                  if (notification) {
                    setLinkRedirect(`/settings/transactions`);
                  }
                }, [notification]);
                return (
                  <>
                    <Link
                      className="text-secondary text-lg font-normal"
                      style={{ textDecoration: 'none' }}
                      to={`/settings/transactions`}
                    >
                      <Trans i18nKey={`notifications.${notification?.type}`}>
                        {{ amount: doer.amount }}
                      </Trans>
                    </Link>
                  </>
                );
              }

              case 'WITHDRAW_REJECTED_FANS': {
                useEffect(() => {
                  if (notification) {
                    setLinkRedirect(`/settings/transactions`);
                  }
                }, [notification]);
                return (
                  <>
                    <Link
                      className="text-secondary text-lg font-normal"
                      style={{ textDecoration: 'none' }}
                      to={`/settings/transactions`}
                    >
                      <Trans i18nKey={`notifications.${notification?.type}`}>
                        {{ amount: doer.amount }}
                      </Trans>
                    </Link>
                  </>
                );
              }

              case 'WITHDRAW_APPROVED_FANS': {
                const provider = notification?.event?.wallet?.withdrawal?.provider;
                useEffect(() => {
                  if (notification) {
                    setLinkRedirect(`/settings/transactions`);
                  }
                }, [notification]);
                return (
                  <>
                    <Link
                      className="text-secondary text-lg font-normal"
                      style={{ textDecoration: 'none' }}
                      to={`/settings/transactions`}
                    >
                      <Trans i18nKey={`notifications.${notification?.type}`}>
                        {{ amount: doer.amount }}
                        {{ withdrawMethod: provider }}
                      </Trans>
                    </Link>
                  </>
                );
              }

              case 'COLLECTIBLE_RESERVED_BUYER':
                return (
                  <>
                    <Box className="text-secondary text-lg font-normal">
                      <Trans i18nKey={`notifications.${notification?.type}`} />
                    </Box>
                  </>
                );

              case 'COLLECTIBLE_RESERVED_SELLER':
                const collectible = notification?.event?.collectible;
                return (
                  <>
                    <Box className="text-secondary text-lg font-normal">
                      <Trans i18nKey={`notifications.${notification?.type}`}>
                        {{ collectibleName: collectible.title }}
                        {{ tier: doer.tier }}
                      </Trans>
                    </Box>
                  </>
                );

              case 'FCLUB_THREAD_CREATED_CREATOR': {
                const channel = notification?.event?.channelData;
                return (
                  <>
                    <Link
                      className="text-secondary text-lg font-normal"
                      style={{ textDecoration: 'none' }}
                      to={`/${doer.username}/fanclub/${channel.id}`}
                    >
                      <Trans i18nKey={`notifications.${notification?.type}`}>
                        {{ fullName: doer.fullName }}
                        {{ channelName: channel.name }}
                      </Trans>
                    </Link>
                  </>
                );
              }

              case 'FCLUB_COMMENT_CREATED': {
                const channel = notification?.event?.channelData;
                return (
                  <>
                    <Link
                      className="text-secondary text-lg font-normal"
                      style={{ textDecoration: 'none' }}
                      to={`/${channel?.ownerUsername}/fanclub/${channel.id}`}
                    >
                      <Trans
                        i18nKey={`notifications.${notification?.type}_${notification?.event?.type}`}
                      >
                        {{ fullName: doer.fullName }}
                      </Trans>
                    </Link>
                  </>
                );
              }

              case 'FCLUB_THREAD_REPORTED': {
                const reason = reportList.filter((x) => x.value === notification?.event?.reason);
                const channel = notification?.event?.channelData;
                return (
                  <>
                    <Link
                      className="text-secondary text-lg font-normal"
                      style={{ textDecoration: 'none' }}
                      to={`/${channel?.ownerUsername}/fanclub/${channel.id}`}
                    >
                      <Trans i18nKey={`notifications.${notification?.type}`}>
                        {{ fullName: doer.fullName }}
                        {{ reason: t(`components.post.post.reportList.${reason[0]?.label}`) }}
                      </Trans>
                    </Link>
                  </>
                );
              }

              case 'FCLUB_THREAD_DELETED_BY_CREATOR': {
                const channel = notification?.event?.channelData;
                return (
                  <>
                    <Link
                      className="text-secondary text-lg font-normal"
                      style={{ textDecoration: 'none' }}
                      to={`/${channel?.ownerUsername}/fanclub/${channel.id}`}
                    >
                      <Trans i18nKey={`notifications.${notification?.type}`}>
                        {{ fullName: doer.fullName }}
                        {{ type: notification?.event?.type?.toLowerCase() }}
                      </Trans>
                    </Link>
                  </>
                );
              }

              case 'FCLUB_THREAD_LIKED': {
                const channel = notification?.event?.channelData;
                return (
                  <>
                    <Link
                      className="text-secondary text-lg font-normal"
                      style={{ textDecoration: 'none' }}
                      to={`/${channel?.ownerUsername}/fanclub/${channel.id}`}
                    >
                      <Trans i18nKey={`notifications.${notification?.type}`}>
                        {{ fullName: doer.fullName }}
                      </Trans>
                    </Link>
                  </>
                );
              }

              case 'FCLUB_THREAD_FEATURED_FANS': {
                const channel = notification?.event?.channelData;
                return (
                  <>
                    <Link
                      className="text-secondary text-lg font-normal"
                      style={{ textDecoration: 'none' }}
                      to={`/${channel?.ownerUsername}/fanclub/${channel.id}`}
                    >
                      <Trans i18nKey={`notifications.${notification?.type}`}>
                        {{ fullName: doer.fullName }}
                      </Trans>
                    </Link>
                  </>
                );
              }

              default:
                return;
            }
          })()}
        </Box>
        <Box
          style={{ color: '#979797' }}
          className={
            dropdown
              ? 'text-base mt-2 font-medium capitalize  text-time'
              : 'text-sm font-medium capitalize md:text-base text-time hidden lg:block'
          }
        >
          {notificationTime}
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationMenuItem;
