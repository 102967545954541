import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Box, Split, Modal } from '@components';
import ScrollTo from '@libs/utils/scrollTo';
import { useAuth } from '@libs/contexts/auth';

import PrintCollectibleHero from '@pages/print-collectible/fansinc/print-collectible-hero';
import PrintCollectibleContentRight from '@pages/print-collectible/fansinc/print-collectible-content-right';
import PrintCollectiblePrintPreview from '@pages/print-collectible/fansinc/print-collectible-print-preview';
import PrintCollectibleCheckoutModal from '@pages/print-collectible/fansinc/print-collectible-checkout-modal';
import PrintCollectibleOwnershipModal from '@pages/print-collectible/fansinc/print-collectible-ownership-modal';
import listCountries from '@libs/json/prodigiCountries.json';
import PrintCollectibleGallery from '@pages/print-collectible/fansinc/print-collectible-gallery';
import PrintImage from '@pages/print-collectible/fansinc/print-preview-image';
import {
  useItemFromUrlParams,
  useUrl,
  useIsOwner,
  useRedirect
} from '@pages/print-collectible/fansinc/hooks';
import {
  frameKeys,
  isLocalhost,
  colorProdigiKeys,
  skuFrame,
  skuFrameTest
} from '@pages/print-collectible/fansinc/utils';
import { useQuotesProdigi } from '@libs/hooks/prodigi';

import '@pages/print-collectible/fansinc/style.css';

function PrintCollectiblePage() {
  const useStub = isLocalhost();

  // will load either exchange or collectible depending on url structure
  // we refer to this as an item with type exchange or collectible
  const { item, isLoadingItem, itemType } = useItemFromUrlParams();
  const { user } = useAuth();
  const url = useUrl({ item, itemType });
  const { t } = useTranslation();

  const isOwner = useIsOwner({ item, itemType });
  const [selectedFrame, setSelectedFrame] = useState(frameKeys.BLACK);
  const setSelectedFrameCallback = useCallback(setSelectedFrame, [setSelectedFrame]);
  const [selectedSize, setSelectedSize] = useState('A4');
  const setSelectedSizeCallback = useCallback(setSelectedSize, [setSelectedSize]);
  const [quantity, setQuantity] = useState(1);
  const [selectedCountry, setSelectedCountry] = useState();
  const [previewModal, setPreviewModal] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);
  const setQuantityCallback = useCallback(setQuantity, [setQuantity]);
  const [isPrintPreviewOpen, setIsPrintPreviewOpen] = useState(false);
  const setIsPrintPreviewOpenCallback = useCallback(setIsPrintPreviewOpen, [setIsPrintPreviewOpen]);
  const [isPrintCollectibleCheckoutOpen, setIsPrintCollectibleCheckoutOpen] = useState(false);
  const setIsPrintCollectibleCheckoutOpenCallback = useCallback(setIsPrintCollectibleCheckoutOpen, [
    setIsPrintCollectibleCheckoutOpen
  ]);
  const [isOwnershipModalOpen, setIsOwnershipModalOpen] = useState(false);
  const setIsOwnershipModalOpenCallback = useCallback(setIsOwnershipModalOpen, [
    setIsOwnershipModalOpen
  ]);

  const { data, loading: prodigiLoading, getData } = useQuotesProdigi();
  const countries = data?.shippingCountries
    ?.map((country) => ({
      value: country,
      label: listCountries.find((e) => e.isoCode === country).name
    }))
    .sort(function (a, b) {
      return a.label.localeCompare(b.label);
    });

  useRedirect({ item, isLoadingItem });
  const contentRef = useRef();
  const mediaRef = useRef();

  useEffect(async () => {
    if (selectedFrame && selectedSize && quantity && selectedCountry && isOwner) {
      const payload = {
        sku: skuFrame[selectedSize],
        copies: quantity,
        color: colorProdigiKeys[selectedFrame],
        country: selectedCountry
      };
      getData(payload);
    }
  }, [selectedFrame, selectedSize, quantity, selectedCountry, isOwner]);

  useEffect(() => {
    if (listCountries.find((c) => c.isoCode === user?.address?.country)) {
      setSelectedCountry(user?.address?.country);
    } else {
      setSelectedCountry('AU');
    }
  }, [user]);

  const price = Number(data?.price);

  const scrollToContent = () => ScrollTo(contentRef.current.offsetTop);

  if (isLoadingItem || !item) {
    // TODO: create loading skeleton here
    return null;
  }
  const onClosePreview = () => {
    setPreviewModal(false);
  };

  return (
    <>
      <Box className="print-collectible-page">
        <PrintCollectibleHero
          item={item}
          itemType={itemType}
          url={url}
          selectedSize={selectedSize}
          setIsPrintPreviewOpen={setIsPrintPreviewOpenCallback}
          setIsOwnershipModalOpen={setIsOwnershipModalOpenCallback}
          setPreviewModal={setPreviewModal}
          scrollToContent={scrollToContent}
          isOwner={isOwner}
          useStub={useStub}
          mediaRef={mediaRef}
        />
        <Split
          ref={contentRef}
          left={
            <Box id="print-it-main-image">
              <PrintImage
                item={item}
                ref={mediaRef}
                selectedSize={selectedSize}
                selectedFrame={selectedFrame}
                setPreviewImage={setPreviewImage}
                loading={isLoadingItem}
              />
            </Box>
          }
          right={
            <PrintCollectibleContentRight
              selectedFrame={selectedFrame}
              setSelectedFrame={setSelectedFrameCallback}
              selectedSize={selectedSize}
              setSelectedSize={setSelectedSizeCallback}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              quantityValue={quantity}
              setQuantityValue={setQuantityCallback}
              setIsCheckoutOpen={setIsPrintCollectibleCheckoutOpenCallback}
              item={item}
              itemType={itemType}
              isOwner={isOwner}
              mediaRef={mediaRef}
              countries={countries}
              exchangeID={item?.id}
              price={price}
              loading={prodigiLoading}
            />
          }
        />
        <Box>
          <PrintCollectibleGallery />
        </Box>
        <Modal
          isOpen={previewModal}
          showOnlyCloseIcon
          onClose={onClosePreview}
          closeIconSize={40}
          size="fullPage"
        >
          <Box className="print-collectible-page__preview">
            <Box className="print-collectible-page__preview__text">
              {t('printCollectiblePage.printPreview.tagline')}
            </Box>
            <img className="print-collectible-page__preview__image" src={previewImage} />
          </Box>
        </Modal>
      </Box>
      <PrintCollectiblePrintPreview
        isOpen={isPrintPreviewOpen}
        setIsOpen={setIsPrintPreviewOpenCallback}
        item={item}
        itemType={itemType}
        isOwner={isOwner}
        selectedFrame={selectedFrame}
        selectedSize={selectedSize}
      />
      <PrintCollectibleCheckoutModal
        isOpen={isPrintCollectibleCheckoutOpen}
        setIsOpen={setIsPrintCollectibleCheckoutOpenCallback}
        item={item}
        itemType={itemType}
        selectedFrame={selectedFrame}
        selectedSize={selectedSize}
        quantity={quantity}
        previewImage={previewImage}
        price={price}
        selectedCountry={selectedCountry}
        setSelectedCountry={setSelectedCountry}
        countries={countries}
        exchangeID={item?.id}
        loading={prodigiLoading}
      />
      <PrintCollectibleOwnershipModal
        isOpen={isOwnershipModalOpen}
        setIsOpen={setIsOwnershipModalOpenCallback}
      />
      <Helmet>
        <title>{t('printCollectiblePage.hero.heading')}</title>
        <meta property="og:image" content="https://cdn.fans.inc/public/webapp/og-image.png" />
      </Helmet>
    </>
  );
}

PrintCollectiblePage.propTypes = {};

export default PrintCollectiblePage;
