import stylesA4Portrait from './a4';
import stylesA2Portrait from './a2';
import stylesA1Portrait from './a1';

const stylesPortrait = {
  A_FOUR: stylesA4Portrait,
  A_TWO: stylesA2Portrait,
  A_ONE: stylesA1Portrait
};

export default stylesPortrait;
