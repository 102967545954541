import { Box, Table } from '@components';
import { useLocale } from '@libs/contexts/locale';
import { useChannelByOwner } from '@libs/hooks/discourse';
import { useUserByUsername } from '@libs/hooks/user';
import { dateFormater } from '@libs/utils/date';
import { categoryParser } from '@pages/collectible/patrons/utils';
import React from 'react';
import { useParams } from 'react-router-dom';
import { CATEGORY_TYPES } from '@libs/utils/collectible';
import { Trans, useTranslation } from 'react-i18next';

export default function PatronsCollectibleDetail({ collectible, maxQuantity }) {
  return (
    <div>
      <Box className="collectible__tab__detail">
        <Box className="collectible__tab__detail__description">
          <Box className="collectible__tab__detail__description__title">Description</Box>
          <Box className="collectible__tab__detail__description__value">
            {collectible?.description}
          </Box>
        </Box>
        <Box className="collectible__tab__detail__table">
          <Table rows={detailRows(collectible, maxQuantity)} />
        </Box>
      </Box>
    </div>
  );
}

function detailRows(collectible, maxQuantity) {
  const { t } = useTranslation();
  const { getCurrencyFormatter } = useLocale();
  const currencyFormatter = getCurrencyFormatter();
  const numberedEdition = collectible?.releases?.find((x) => x.tierType === 'NUMBERED_EDITION');
  const price = currencyFormatter.format(Number(numberedEdition?.startingPrice));
  const releaseDate = dateFormater(numberedEdition?.releaseDate);
  const params = useParams();
  const username = params.username?.toLowerCase();
  const { data: profile, loading: profileLoading } = useUserByUsername(username);
  const { data: channels } = useChannelByOwner(profile?.id);
  let medium;
  if (
    [CATEGORY_TYPES.ARTWORK, CATEGORY_TYPES.PHOTOGRAPH, CATEGORY_TYPES.SONG_LYRICS].includes(
      collectible?.category
    )
  ) {
    medium = (
      <Trans i18nKey="collectible.expansionInformation.attributes.verifiedOriginal">
        {{ category: categoryParser(collectible?.category) }}
        {{ print: ' + ' + 'Print' }}
      </Trans>
    );
  } else {
    medium = (
      <Trans i18nKey="collectible.expansionInformation.attributes.verifiedOriginal">
        {{ category: categoryParser(collectible?.category) }}
        {{ print: ' + ' + 'Cover Art Print' }}
      </Trans>
    );
  }
  return [
    [
      [
        <Box key={1}>
          <Box className="collectible__tab__detail__table__left">Collectible title</Box>
        </Box>
      ],
      [
        <Box key={2}>
          <Box className="collectible__tab__detail__table__right">{collectible?.title}</Box>
        </Box>
      ]
    ],
    [
      [
        <Box key={3}>
          <Box className="collectible__tab__detail__table__left">Artist</Box>
        </Box>
      ],
      [
        <Box key={4}>
          <Box className="collectible__tab__detail__table__right">
            {collectible?.creator.displayName
              ? collectible?.creator.displayName
              : collectible?.creator.firstName + ' ' + collectible?.creator.lastName}
          </Box>
        </Box>
      ]
    ],
    [
      [
        <Box key={5}>
          <Box className="collectible__tab__detail__table__left">Collectible type</Box>
        </Box>
      ],
      [
        <Box key={6}>
          <Box className="collectible__tab__detail__table__right">
            {collectible?.type.toLowerCase()}
          </Box>
        </Box>
      ]
    ],
    [
      [
        <Box key={5}>
          <Box className="collectible__tab__detail__table__left">
            {t('p.pages.release.tab.detail.dimensions')}
          </Box>
        </Box>
      ],
      [
        <Box key={6}>
          <Box className="collectible__tab__detail__table__right lowercase">
            <Trans i18nKey="p.pages.release.tab.detail.size">
              {{
                width: (collectible?.width * 2.54).toFixed(1),
                height: (collectible?.height * 2.54).toFixed(1)
              }}
            </Trans>
          </Box>
        </Box>
      ]
    ],
    [
      [
        <Box key={7}>
          <Box className="collectible__tab__detail__table__left">Year of creation</Box>
        </Box>
      ],
      [
        <Box key={8}>
          <Box className="collectible__tab__detail__table__right">
            {collectible?.yearOfCreation}
          </Box>
        </Box>
      ]
    ],
    [
      [
        <Box key={9}>
          <Box className="collectible__tab__detail__table__left">Max quantity</Box>
        </Box>
      ],
      [
        <Box key={10}>
          <Box className="collectible__tab__detail__table__right">{maxQuantity}</Box>
        </Box>
      ]
    ],
    [
      [
        <Box key={11}>
          <Box className="collectible__tab__detail__table__left">Medium</Box>
        </Box>
      ],
      [
        <Box key={12}>
          <Box className="collectible__tab__detail__table__right">{medium}</Box>
        </Box>
      ]
    ],
    [
      [
        <Box key={13}>
          <Box className="collectible__tab__detail__table__left">Release Status</Box>
        </Box>
      ],
      [
        <Box key={14}>
          <Box className="collectible__tab__detail__table__right">
            {collectible?.status.toLowerCase()}
          </Box>
        </Box>
      ]
    ],
    [
      [
        <Box key={15}>
          <Box className="collectible__tab__detail__table__left">Release Date</Box>
        </Box>
      ],
      [
        <Box key={16}>
          <Box className="collectible__tab__detail__table__right">{releaseDate}</Box>
        </Box>
      ]
    ],
    [
      [
        <Box key={17}>
          <Box className="collectible__tab__detail__table__left">Price</Box>
        </Box>
      ],
      [
        <Box key={18}>
          <Box className="collectible__tab__detail__table__right">{price}</Box>
        </Box>
      ]
    ]
  ];
}
