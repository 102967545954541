import { Box, Media, Link } from '@components';
import { useTierAvailability } from '@libs/hooks/exchange';
import { Trans } from 'react-i18next';

function CollectibleCard({ collectible }) {
  const { data: totalRelease } = useTierAvailability({
    collectibleID: collectible?.id,
    creatorID: collectible?.userID,
    serialIndex: 1
  });
  return (
    <Link to={`/${collectible?.creator?.username}/${collectible?.slug}`}>
      <Box className="landing-collectible-card">
        <Box className="landing-collectible-card__image">
          <Media collectible={collectible} thumbnail />
          <Box className="landing-collectible-card__image__detail">
            <Box className="landing-collectible-card__image__detail__wrapper">
              <Trans i18nKey="p.pages.landing.releaseCount">
                {{
                  release: totalRelease,
                  total: collectible?.maxNumberedEditions
                }}
              </Trans>
            </Box>
          </Box>
        </Box>
      </Box>
    </Link>
  );
}

export default CollectibleCard;
