import { FaFacebookF, FaTwitter, FaInstagram, FaSnapchatGhost, FaLink } from 'react-icons/fa';
import { IoCopyOutline } from 'react-icons/io5';

export const copyToClipboard = (shareUrl, callback) => {
  var textField = document.createElement('textarea');
  textField.innerText = shareUrl;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
  callback && callback();
};

export const getFacebookShareUrl = (shareUrl) =>
  'https://www.facebook.com/sharer/sharer.php?u=' + shareUrl;

export const getTwitterShareUrl = (shareUrl) => 'https://twitter.com/share?url=' + shareUrl;

export const shareChannelKeys = {
  facebook: 'FACEBOOK',
  twitter: 'TWITTER',
  instagram: 'INSTAGRAM',
  snapchat: 'SNAPCHAT',
  copy: 'COPY',
  link: 'LINK'
};

export const getShareChannel = (channel, url, callback) => {
  const shareChannel = {
    [shareChannelKeys.facebook]: {
      pathname: getFacebookShareUrl(url),
      icon: <FaFacebookF />
    },
    [shareChannelKeys.twitter]: {
      pathname: getTwitterShareUrl(url),
      icon: <FaTwitter />
    },
    [shareChannelKeys.instagram]: {
      // pathname: url,
      onClick: () => copyToClipboard(url, null),
      icon: <FaInstagram />
    },
    [shareChannelKeys.snapchat]: {
      // pathname: url,
      onClick: () => copyToClipboard(url, null),
      icon: <FaSnapchatGhost />
    },
    [shareChannelKeys.copy]: {
      onClick: () => copyToClipboard(url, null),
      icon: <IoCopyOutline />
    },
    [shareChannelKeys.link]: {
      onClick: () => {
        copyToClipboard(url, null);
      },
      icon: <FaLink />
    }
  };
  return shareChannel[channel];
};
