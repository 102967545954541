import { useCallback } from 'react';
import { useExchangeByUser } from '@libs/hooks/exchange/useExchangeByUser';
import { CollectibleCard, CollectibleGrid } from '@components';
import { useAuth } from '@libs/contexts/auth';

const Release = ({ userID }) => {
  const {
    data: exchanges,
    pageToken: nextExchangeToken,
    getData: fetchExchange,
    loading: exchangeLoading,
    total: totalExchange
  } = useExchangeByUser(userID);

  const { user } = useAuth();

  const nextData = async () => {
    await fetchExchange(userID, '', nextExchangeToken, false);
  };

  const reloadData = useCallback(async () => {
    await fetchExchange(userID, '', null, true);
  }, [userID]); // eslint-disable-line

  return (
    <>
      <CollectibleGrid
        loading={exchangeLoading}
        showEmpty
        profile
        nextData={nextData}
        release
        infiniteScroll={true}
        hasNoMore={!nextExchangeToken}
        showTotal
        total={totalExchange}
      >
        {exchanges &&
          exchanges.map((exchange) => (
            <CollectibleCard
              updateExchangeData={reloadData}
              key={exchange.id}
              showManageListing={user?.id === exchange.currentOwnerID}
              collectible={exchange}
              type="release"
            />
          ))}
      </CollectibleGrid>
    </>
  );
};

export default Release;
