import cx from 'classnames';
import { Box, Media, Link } from '@components';

const CardFront = ({ collectible, collectibleUrl, isPreview, type, isFlipped, ...props }) => {
  return (
    <Box className="collectible-card__side collectible-card__front" {...props}>
      <Box className="collectible-card__front__container">
        <Box className="collectible-card__front__cover">
          <Box
            className={cx(
              'collectible-card__front__cover__content',
              isFlipped ? 'collectible-card__front__cover__content-invisible' : ''
            )}
          >
            {isPreview ? (
              <Media collectible={collectible} />
            ) : (
              <Link to={collectibleUrl}>
                <Media collectible={collectible} showPlayer thumbnail />
              </Link>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

CardFront.defaultProps = {
  isFlipped: false
};

export default CardFront;
