export const getFadeClass = ({ className, fadeLeft, fadeRight, fadeBottom }) => {
  let fadeClassName = '';
  if (fadeLeft) {
    fadeClassName += `${className}__fade-left`;
  } else if (fadeRight) {
    fadeClassName += `${className}__fade-right`;
  }
  if (fadeBottom) {
    fadeClassName += ` ${className}__fade-bottom`;
  }
  return fadeClassName;
};
