import { React } from 'react';
import FansincContact from './fansinc';
import PatronsContact from './patrons';

function Contact() {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsContact />;
  } else {
    return <FansincContact />;
  }
}
export default Contact;
