import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box, Button, Link, Text } from '@components';
import Lottie from 'react-lottie';
import notFoundData from '@lotties/404';

const NotFound = ({ title, description }) => {
  const { t } = useTranslation();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: notFoundData
  };
  return (
    <Box className="py-12 px-5 md:px-0 text-center flex  justify-center items-center flex-col">
      <Box className="w-60 h-60 md:w-80 md:h-80">
        <Lottie options={defaultOptions} />
      </Box>
      <Text className="text-center text-3xl md:text-4xl uppercase font-bold text-primary">
        {title}
      </Text>
      <Text className="text-base text-primary font-normal mt-4">{description}</Text>
      <Link to="/marketplace">
        <Button className="mt-6">View our marketplace</Button>
      </Link>
    </Box>
  );
};

export default NotFound;
