import cx from 'classnames';
import { useTranslation, Trans } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Box, FormField, Input, Modal, Button, RadioButton, Separator, Price } from '@components';
import BitpayAcceptedImage from '@assets/img/bitpay-accepted.png';
import Image1 from '@assets/img/paypal.png';
import { useLocale } from '@libs/contexts/locale';
import { useWithdrawal } from '@libs/hooks/withdrawal';

const WithdrawalAmount = ({ isOpen, form, setForm, onCancel, action, balance }) => {
  const { t } = useTranslation();
  const { request: requestWithdrawal, loading } = useWithdrawal();
  const { currencyCode, getCurrencyFormatter, calculateFees, calculateTax } = useLocale();
  const currencyFormatter = getCurrencyFormatter();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch
  } = useForm({
    mode: 'onBlur',
    shouldFocusError: false
  });

  const amountValue = watch('amount');

  const submitModalActionClose = () => {
    onCancel();
    reset();
  };

  const onSubmit = async (values) => {
    setForm({
      ...form,
      values
    });
    const { paymentMethod } = form;
    const { amount, destination } = values;
    const destinationWithdrawal =
      paymentMethod === 'PAYPAL' ? destination.paypal : destination.crypto;
    const withdrawalID = await requestWithdrawal(paymentMethod, destinationWithdrawal, amount);
    reset();
    action('amount', withdrawalID);
  };

  return (
    <Modal
      customWrapperStyle="withdrawal-modal"
      showCloseIcon
      heading={t(['p.components.withdrawalModal.amount.title'])}
      isOpen={isOpen}
      size="md"
      onClose={() => submitModalActionClose()}
    >
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Box className="amount-modal">
          <Box className="amount-modal__balance">
            <Box className="amount-modal__balance__wrapper">
              <Box className="amount-modal__balance__wrapper__field">
                {t('p.components.withdrawalModal.amount.currentBalance')}
              </Box>
              <Box className="amount-modal__balance__wrapper__value">
                <Price amount={balance} inline className="font-bold" />
              </Box>
            </Box>
          </Box>
          <Box className="amount-modal__input">
            <FormField>
              <Box className="font-medium text-lg mb-2">
                {t('p.components.withdrawalModal.amount.fields.amount.label')}
              </Box>
              <Input
                required
                placeholder={t('p.components.withdrawalModal.amount.fields.amount.placeholder')}
                labelRight={currencyCode}
                pattern="^\d*(\.\d{0,2})?$"
                as="number"
                {...register('amount', {
                  required: {
                    value: true,
                    message: t('p.components.withdrawalModal.amount.fields.amount.required')
                  },
                  min: {
                    value: 10,
                    message: <>{t('p.components.withdrawalModal.amount.min')}</>
                  },
                  max: {
                    value: balance,
                    message: <>{t('p.components.withdrawalModal.amount.max')}</>
                  }
                })}
                errorMessage={errors.amount?.message}
              />
            </FormField>
          </Box>
        </Box>
        {amountValue >= 10 && (
          <Box className="withdrawal-modal__calculate">
            <ul className="withdrawal-modal__calculate-list">
              <li className="withdrawal-modal__calculate-list-item">
                <span className="font-bold capitalize lg:uppercase">
                  {t('p.components.withdrawalModal.withdrawalFee')}
                </span>
                <span className="text-red-300">
                  <span className="mr-1">{currencyFormatter.format(Number(1))}</span>
                  <span className="text-gray-400">{currencyCode}</span>
                </span>
              </li>
              <Separator className="my-4" />
              <li className="withdrawal-modal__calculate-list-item">
                <span className="font-bold capitalize lg:uppercase">
                  {t('p.components.withdrawalModal.withdrawalAmount')}
                </span>
                <span>
                  <span className="mr-1">{currencyFormatter.format(Number(amountValue - 1))}</span>
                  <span className="text-gray-400">{currencyCode}</span>
                </span>
              </li>
            </ul>
          </Box>
        )}

        <label className="block mb-3 text-lg capitalize font-medium">
          {t('p.components.withdrawalModal.amount.fields.paymentMethod.label')}
        </label>
        <Box
          className={cx(
            'withdrawal-modal-method',
            form.paymentMethod === 'PAYPAL' ? 'withdrawal-modal-method-active' : ''
          )}
        >
          <FormField className="mb-0">
            <RadioButton
              className="withdrawal-modal-radio"
              name="paymentMethod"
              value="PAYPAL"
              isChecked={form.paymentMethod === 'PAYPAL'}
              leftMode={true}
              onChange={(e) => {
                setForm({
                  ...form,
                  paymentMethod: e.target.value
                });
              }}
            >
              <div className="withdrawal-modal-radio-icon">
                <div className="withdrawal-modal-radio-label">
                  <Box className="text-lg font-bold">Paypal</Box>
                  <Box className="ml-auto">
                    <img src={Image1} />
                  </Box>
                </div>
              </div>
            </RadioButton>
          </FormField>
          {form.paymentMethod === 'PAYPAL' && (
            <>
              <Separator />
              <FormField className="mt-4">
                <Box className="font-medium text-lg mb-2">
                  {t('p.components.withdrawalModal.paypal.fields.email.label')}
                </Box>
                <Input
                  fullwidth
                  required
                  placeholder={t('p.components.withdrawalModal.paypal.fields.email.placeholder')}
                  errorMessage={errors.destination?.paypal?.message}
                  {...register('destination.paypal', {
                    required: {
                      value: true,
                      message: t('p.components.withdrawalModal.paypal.fields.email.required')
                    },

                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: t('p.components.withdrawalModal.paypal.fields.email.invalid')
                    }
                  })}
                />
              </FormField>
            </>
          )}
        </Box>
        <Box
          className={cx(
            'withdrawal-modal-method',
            form.paymentMethod === 'BITPAY' ? 'withdrawal-modal-method-active' : ''
          )}
        >
          <FormField className="mb-0">
            <RadioButton
              className="withdrawal-modal-radio"
              name="paymentMethod"
              value="BITPAY"
              leftMode={true}
              isChecked={form.paymentMethod === 'BITPAY'}
              onChange={(e) => {
                setForm({
                  ...form,
                  paymentMethod: e.target.value
                });
              }}
            >
              <div className="withdrawal-modal-radio-icon">
                <div className="withdrawal-modal-radio-label">
                  <Box className="text-lg font-bold">Cryptocurrency</Box>
                  <Box className="ml-auto icon-bitpay">
                    <img src={BitpayAcceptedImage} />
                  </Box>
                </div>
              </div>
            </RadioButton>
          </FormField>
          {form.paymentMethod === 'BITPAY' && (
            <>
              <Separator />
              <FormField className="mt-4">
                <Box className="font-medium text-lg mb-2">
                  {t('p.components.withdrawalModal.cryptocurrency.fields.walletAddress.label')}
                </Box>
                <Input
                  fullwidth
                  placeholder={t(
                    'p.components.withdrawalModal.cryptocurrency.fields.walletAddress.placeholder'
                  )}
                  errorMessage={errors.destination?.crypto?.message}
                  {...register('destination.crypto', {
                    required: {
                      value: true,
                      message: t(
                        'p.components.withdrawalModal.cryptocurrency.fields.walletAddress.required'
                      )
                    },
                    validate: {
                      walletPattern: (value) =>
                        /^(0x[a-fA-F0-9]{40}$)/.test(value) ||
                        /[a-z|A-Z|0-9]+[^I]\s?.eth$/.test(value) ||
                        !value ||
                        t(
                          'p.components.withdrawalModal.cryptocurrency.fields.walletAddress.invalid'
                        )
                    }
                  })}
                />
              </FormField>
            </>
          )}
        </Box>

        <Box className="withdrawal-modal-button">
          <Button outline fullWidth darkOutline onClick={() => submitModalActionClose()}>
            {t('p.components.withdrawalModal.button.cancel')}
          </Button>
          <Button as="submit" fullWidth loading={loading} disabled={loading}>
            {t('p.components.withdrawalModal.button.continue')}
          </Button>
        </Box>
      </form>
    </Modal>
  );
};

export default WithdrawalAmount;
