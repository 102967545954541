import FansincHelmet from './fansinc';
import PatronsHelmet from './patrons';

const Helmet = () => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsHelmet />;
  } else {
    return <FansincHelmet />;
  }
};

export default Helmet;
