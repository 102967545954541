import cx from 'classnames';
import PropTypes from 'prop-types';
import { useLocale } from '@libs/contexts/locale';
import { Box } from '@components';
import { LISTING_TYPES } from '@libs/utils/exchange';

const PriceNft = ({ exchange, inlineClass, className, ...props }) => {
  const { nftERC20PaymentAmount, nftERC20PaymentTokenSymbol, nftERC20PaymentTokenDecimals } =
    exchange;
  let value = parseInt(nftERC20PaymentAmount) * 10 ** -parseInt(nftERC20PaymentTokenDecimals);
  value = value?.toFixed(6);
  if (!value || isNaN(value)) {
    return null;
  }

  return (
    <Box className={cx('price', inlineClass, className)} {...props}>
      {`${value} ${nftERC20PaymentTokenSymbol ?? ''}`}
    </Box>
  );
};

const Price = ({
  amount,
  exchange,
  inline,
  minus,
  showCurrency,
  currencyTextColor,
  className,
  ...props
}) => {
  const { getCurrencyFormatter, currencyCode } = useLocale();
  const currencyFormatter = getCurrencyFormatter();

  const inlineClass = inline ? 'inline-block' : false;
  const currencyTextColorClass = currencyTextColor === 'grey' ? 'text-gray-400' : false;

  if (amount == null && exchange == null) {
    return null;
  }

  if (exchange && exchange.listingType === LISTING_TYPES.NFT) {
    return (
      <PriceNft exchange={exchange} inlineClass={inlineClass} className={className} {...props} />
    );
  }

  return (
    <Box className={cx('price', inlineClass, className)} {...props}>
      {minus && '-'}
      {currencyFormatter.format(amount)}
      <span className={currencyTextColorClass}>{showCurrency && ' ' + currencyCode}</span>
    </Box>
  );
};

Price.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inline: PropTypes.bool,
  minus: PropTypes.bool,
  showCurrency: PropTypes.bool,
  currencyTextColor: PropTypes.string,
  className: PropTypes.string
};

Price.defaultProps = {
  inline: false,
  minus: false,
  showCurrency: false
};

export default Price;
