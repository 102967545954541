import cx from 'classnames';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoEllipsisVertical, IoNotifications } from 'react-icons/io5';
import { ReactComponent as NotificationIcon } from '@assets/svg/notification.svg';

import { Box, Link, NotificationMenuItem, Text } from '@components';
import { useOutsider } from '@libs/utils/outsider';
import { useUpdateReadNotification } from '@libs/hooks/notification';
import { useNotifications } from '@libs/contexts/notification';
import { MdOutlineNotificationsActive } from 'react-icons/md';
const LIMIT_NOTIF = 5;

const NotificationMenu = ({ className, ...props }) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { notifications, unread, readAllNotifications } = useNotifications(); // TODO: implement these

  const handleClose = () => {
    setOpen(false);
    setMenuOpen(false);
  };
  const handleMenuClose = () => setMenuOpen(false);

  const { ref } = useOutsider(handleClose);
  const { ref: refMenu } = useOutsider(handleMenuClose);

  const handleOpen = useCallback(
    (e) => {
      e.preventDefault();
      setOpen(!isOpen);
    },
    [isOpen]
  );

  const handleMenuOpen = useCallback(
    (e) => {
      e.preventDefault();
      setMenuOpen(!isMenuOpen);
    },
    [isMenuOpen]
  );
  const { onUpdateNotification } = useUpdateReadNotification();
  const onUpdate = async (e) => {
    e.preventDefault();
    handleMenuClose();
    readAllNotifications();
    const data = {
      itemType: 'user'
    };
    await onUpdateNotification(data);
  };

  return (
    <Box ref={ref} className={cx('notification-menu', className)} {...props}>
      <Box className="notification-menu__icon" onClick={handleOpen}>
        {process.env.REACT_APP_NAME === 'patrons' ? (
          <MdOutlineNotificationsActive size={25} color="rgba(43, 43, 43, 1)" />
        ) : (
          <IoNotifications />
        )}
        {unread > 0 && <span className="unread">{unread > 99 ? '99+' : unread}</span>}
      </Box>
      {isOpen && (
        <Box className="notification-menu__box">
          <Box ref={refMenu} className="notification-menu__box__heading">
            <Text>{t('components.notificationMenu.title')}</Text>
            <Text onClick={handleMenuOpen}>
              <IoEllipsisVertical />
            </Text>
            {isMenuOpen && (
              <Box className="notification-menu__options">
                <Link to="" onClick={onUpdate}>
                  {t('components.notificationMenu.options.markAllRead')}
                </Link>
                <Link to="/settings/notifications/" onClick={handleClose}>
                  {t('components.notificationMenu.options.settings')}
                </Link>
                <Link to="/notifications" onClick={handleClose}>
                  {t('components.notificationMenu.options.openNotifications')}
                </Link>
              </Box>
            )}
          </Box>
          <Box className="notification-menu__box__items">
            {notifications.slice(0, LIMIT_NOTIF).map(function (notification, i) {
              return <NotificationMenuItem key={i} index={i} notification={notification} />;
            })}
          </Box>
          {notifications.length === 0 && (
            <Box className="notification-menu__box__empty">
              <Box className="notification-menu__box__empty__icon">
                <NotificationIcon />
              </Box>
              <Text className="notification-menu__box__empty__title">
                {t('components.notificationMenu.empty.title')}
              </Text>
              <Text className="notification-menu__box__empty__body">
                {t('components.notificationMenu.empty.body')}
              </Text>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

NotificationMenu.defaultProps = {
  unread: 0
};

export default NotificationMenu;
