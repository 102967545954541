import { forwardRef } from 'react';

import { Box, Container, Grid } from '@components';
import '@components/split/style.css';

const Split = forwardRef(({ left, right, ...props }, ref) => {
  return (
    <Box className="split" ref={ref} {...props}>
      <Container size="xl">
        <Grid className="split__grid">
          <Box className="split__grid__left">{left}</Box>
          <Box className="split__grid__right">{right}</Box>
        </Grid>
      </Container>
    </Box>
  );
});

Split.displayName = 'Split';

export default Split;
