import FansincMedia from './fansinc';
import PatronsMedia from './patrons';

const Media = ({
  as,
  full,
  collectible,
  url,
  className,
  showPlayer,
  size,
  thumbnail,
  borderThinner,
  showPlayerSlider,
  showExternalLink,
  ...props
}) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return (
      <PatronsMedia
        as={as}
        full={full}
        collectible={collectible}
        url={url}
        className={className}
        showPlayer={showPlayer}
        size={size}
        thumbnail={thumbnail}
        borderThinner={borderThinner}
        showPlayerSlider={showPlayerSlider}
        showExternalLink={showExternalLink}
      />
    );
  } else {
    return (
      <FansincMedia
        as={as}
        full={full}
        collectible={collectible}
        url={url}
        className={className}
        showPlayer={showPlayer}
        size={size}
        thumbnail={thumbnail}
        borderThinner={borderThinner}
        showPlayerSlider={showPlayerSlider}
        showExternalLink={showExternalLink}
      />
    );
  }
};

export default Media;
