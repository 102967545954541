import { React, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiDownload } from 'react-icons/fi';
import { AiOutlineEye } from 'react-icons/ai';
import { RiTruckLine } from 'react-icons/ri';

import { Hero, Button, Modal, Box } from '@components';
import '@pages/print-collectible/fansinc/print-collectible-hero/style.css';

function PrintCollectibleHero({ scrollToContent, isOwner, mediaRef, setPreviewModal }) {
  const { t } = useTranslation();
  const [showNotPermittedModal, setShowNotPermittedModal] = useState();
  const onClose = () => {
    setShowNotPermittedModal(false);
  };

  return (
    <Hero heading={t('printCollectiblePage.hero.heading')} showBack>
      <Box className="print-collectible-hero__buttons">
        <Button
          whiteOutline
          onClick={() => {
            mediaRef.current.onPreview();
            setPreviewModal(true);
          }}
        >
          <AiOutlineEye />
          {t('printCollectiblePage.hero.buttons.preview')}
        </Button>
        <Button
          whiteOutline
          onClick={async () =>
            isOwner ? await mediaRef.current.onDownload() : setShowNotPermittedModal(true)
          }
        >
          <FiDownload />
          {t('printCollectiblePage.hero.buttons.download')}
        </Button>
        <Button whiteOutline onClick={scrollToContent}>
          <RiTruckLine />
          {t('printCollectiblePage.hero.buttons.order')}
        </Button>
      </Box>
      <Modal
        isOpen={showNotPermittedModal}
        showOnlyCloseIcon
        onClose={onClose}
        closeIconSize={20}
        size="fit"
      >
        <Box className="print-collectible-hero__modal">
          {t('printCollectiblePage.content.getCollectibleSubHeading')}
        </Box>
      </Modal>
    </Hero>
  );
}

PrintCollectibleHero.propTypes = {};

export default PrintCollectibleHero;
