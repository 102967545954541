import React, { useState, lazy } from 'react';
import { Switch, Route, useLocation, matchPath } from 'react-router-dom';
import { useVlag } from '@vincere/react-vlag';

import { ProtectedRoute, GuestRoute, Header, Footer } from '@components';

// PAGES
import Profile from '@pages/profile';
import RelatedProfile from '@pages/related-profile';
import Auth from '@pages/auth';
import Settings from '@pages/settings';
import Notifications from '@pages/notifications';
import Releases from '@pages/releases';
import Marketplace from '@pages/marketplace';
import Landing from '@pages/landing';
import Collectible from '@pages/collectible';
import Release from '@pages/release';
import PrintRelesePage from '@pages/print-collectible';
import PrintCollectiblePage from '@pages/print-collectible/patrons/collectible';
import Sell from '@pages/sell';

// Single pages
//import About from '@pages/single-page/about';
import Contact from '@pages/single-page/contact';
import Faq from '@pages/single-page/faq';
import HowitWorks from '@pages/single-page/how-it-works';
import TermsAndConditions from '@pages/single-page/terms-and-conditions';
import UnsubscribeCreator from '@pages/single-page/unsubscribe-creator';
import PrintCollectible from './single-page/print-collectible';
import PrivacyPolicy from '@pages/single-page/privacy-policy';
import Confirmation from '@pages/confirmation';
import Congratulation from '@pages/congratulation';
import PrintOrderConfirmation from '@pages/confirmation/print-order';
import PrintOrderCongratulation from '@pages/congratulation/orderApproved';
import PrintNFT from '@pages/print-nft';
import NotFound from '@pages/not-found';
import BitpayPayment from './single-page/bitpay-payment';
import Flex from '@pages/flex';
import MintPage from '@pages/mint';
import Checkout from '@pages/checkout';
import Artists from '@pages/artists';
import Galleries from '@pages/galleries';
import Collectors from '@pages/collectors';

import { useUserByUsername } from '@libs/hooks/user';
import ProfileTemplate from '@pages/profile/fansinc/profile-template';
import Collection from '@pages/gallery-profile';
import { useEffect } from 'react';

import checkReservedWords from '@libs/utils/check-reserved-word';
import About from './single-page/about';
import CollectCollectible from './collect-collectible';

export default function Default() {
  const [usingTemplate, setUsingTemplate] = useState(false);
  const location = useLocation();
  const profileUrl = matchPath(location.pathname, {
    path: '/:username'
  });

  const onProfilePage = matchPath(location.pathname, {
    exact: true,
    strict: true,
    path: '/:username'
  });

  const [galleryLogo, setGalleryLogo] = useState({
    isShowLogo: true,
    logo: '',
    name: ''
  });
  const profileParams =
    profileUrl?.params?.username.substring(0, profileUrl?.params?.username.indexOf('?')) ||
    profileUrl?.params?.username;

  const { data: profile, loading: profileLoading, refetchData } = useUserByUsername(profileParams);

  useEffect(() => {
    const valid = onProfilePage && !checkReservedWords(onProfilePage?.params?.username);
    if (valid && profile && !profileLoading) {
      setUsingTemplate(profile?.profileTemplate === 'tonymott' && profileParams);
    } else if (!valid && !profileLoading) {
      setUsingTemplate(false);
    }
  }, [onProfilePage, profile, profileLoading]);

  const ff = useVlag();
  return (
    <div className="root">
      {!usingTemplate && <Header galleryLogo={galleryLogo} />}
      <main className="main">
        <Switch>
          {process.env.REACT_APP_NAME === 'patrons' && (
            <Route exact path="/home" component={Landing} />
          )}
          <Route exact path="/releases" component={Releases} />
          <Route exact path="/marketplace" component={Marketplace} />
          {process.env.REACT_APP_NAME === 'patrons' && (
            <Route exact path="/artists" component={Artists} />
          )}
          {process.env.REACT_APP_NAME === 'patrons' && (
            <Route exact path="/collectors" component={Collectors} />
          )}
          {process.env.REACT_APP_NAME === 'patrons' && (
            <Route exact path="/galleries" component={Galleries} />
          )}
          {process.env.REACT_APP_NAME === 'patrons' && (
            <Route
              exact
              path="/collection/:slug"
              render={() => <Collection setGalleryLogo={setGalleryLogo} />}
            />
          )}

          {process.env.REACT_APP_NAME === 'patrons' && (
            <Route exact path="/collect-collectible/:id/" component={CollectCollectible} />
          )}

          {/* Auth pages */}
          <Route exact path="/login" component={Auth} />
          <GuestRoute exact path="/signup" component={Auth} />
          <Route exact path="/activate-user" component={Auth} />
          <GuestRoute exact path="/forgot-password" component={Auth} />
          <GuestRoute exact path="/reset-password" component={Auth} />

          {/* Setting pages */}
          <ProtectedRoute exact path="/settings" component={Settings} />
          <ProtectedRoute exact path="/settings/notifications" component={Settings} />
          <ProtectedRoute exact path="/settings/subscriptions" component={Settings} />
          <ProtectedRoute exact path="/settings/account" component={Settings} />
          <ProtectedRoute exact path="/settings/offers-bids" component={Settings} />
          <ProtectedRoute exact path="/settings/bids" component={Settings} />
          <ProtectedRoute exact path="/settings/transactions" component={Settings} />

          {/* Notifications */}
          <ProtectedRoute exact path="/notifications" component={Notifications} />

          {/* Informational pages */}
          <Route exact path="/about" component={About} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/faq" component={Faq} />
          <Route exact path="/how-it-works" component={HowitWorks} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
          <Route exact path="/print-collectible" component={PrintCollectible} />
          <Route exact path="/404" component={NotFound} />

          <Route exact path="/bitpay-payment/:exchange" component={BitpayPayment} />
          <Route exact path="/unsubscribe/:followeeID/:followerID" component={UnsubscribeCreator} />

          <Route exact path="/confirmation/:slug/:prevExchange" component={Confirmation} />
          <Route exact path="/confirmation" component={Confirmation} />
          <Route exact path="/congratulation" component={Congratulation} />
          <Route exact path="/po-confirmation/:exchange" component={PrintOrderConfirmation} />
          <Route exact path="/po-congratulation" component={PrintOrderCongratulation} />
          <Route exact path="/print" component={PrintNFT} />

          {/* Manage listing */}

          {process.env.REACT_APP_NAME !== 'patrons' && (
            <Route exact path="/flex/:username/:slug/:edition?" component={Flex} />
          )}

          {/* Print collectible pages */}
          <Route exact path="/print/:username/:slug/:edition" component={PrintRelesePage} />
          <Route exact path="/print/:username/:slug" component={PrintCollectiblePage} />

          {/* Mint collectible pages */}
          <Route exact path="/mint/:username/:slug/:edition" component={MintPage} />
          <Route exact path="/mint/:username/:slug" component={MintPage} />

          {/* Manage listing */}
          <Route exact path="/sell/:username/:slug/:edition?" component={Sell} />

          {/* Profile pages */}
          {profile === undefined && !profileLoading && <Route path="*" component={NotFound} />}

          <Route
            exact
            path="/:username/collectibles"
            render={() => <Profile profile={profile} profileLoading={profileLoading} />}
          />
          <Route
            exact
            path="/:username/sold"
            render={() => <Profile profile={profile} profileLoading={profileLoading} />}
          />
          <Route
            exact
            path="/:username/liked"
            render={() => <Profile profile={profile} profileLoading={profileLoading} />}
          />
          <Route
            exact
            path="/:username/fancards"
            render={() => <Profile profile={profile} profileLoading={profileLoading} />}
          />
          <Route
            exact
            path="/:username/fanclub/:channelID"
            render={() => <Profile profile={profile} profileLoading={profileLoading} />}
          />
          <Route
            exact
            path="/:username/about"
            render={() => <Profile profile={profile} profileLoading={profileLoading} />}
          />
          <Route exact path="/:username/related" component={RelatedProfile} />

          {/* Collectible & collection pages */}
          <Route
            exact
            path="/:username/:slug"
            render={() => <Collectible setGalleryLogo={setGalleryLogo} />}
          />
          <Route exact path="/:username/:slug/checkout/:edition" component={Checkout} />
          <Route
            exact
            path="/:username/:slug/:edition/:owner?"
            render={() => <Release setGalleryLogo={setGalleryLogo} />}
          />
          <Route
            exact
            path="/:username"
            render={
              usingTemplate && !profileLoading
                ? () => (
                    <ProfileTemplate
                      profile={profile}
                      profileLoading={profileLoading}
                      refetchData={refetchData}
                    />
                  )
                : () => <Profile profile={profile} profileLoading={profileLoading} />
            }
          />
        </Switch>
      </main>
      {!usingTemplate && <Footer />}
    </div>
  );
}
