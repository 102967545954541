import React from 'react';
import cx from 'classnames';

import { Box, Container, Col } from '@components';
import { getFadeClass } from '@components/landing-gallery/utils';
import { PropTypes } from 'prop-types';

function LandingGallerySection({ children, size, fadeLeft, fadeRight, image, reverse: isReverse }) {
  return (
    <Box
      className={cx(
        'landing-gallery__section',
        getFadeClass({ className: 'landing-gallery__section', fadeLeft, fadeRight })
      )}
    >
      <Container size={size}>
        <Box
          className={cx(
            'landing-gallery__section__wrapper',
            isReverse ? 'landing-gallery__section__wrapper-reverse' : ''
          )}
        >
          {image && image}
          <Col
            className={cx(
              'landing-gallery__section__content',
              isReverse ? 'landing-gallery__section__content-reverse' : ''
            )}
          >
            <Box>{children}</Box>
          </Col>
        </Box>
      </Container>
    </Box>
  );
}

LandingGallerySection.propTypes = {
  size: PropTypes.string,
  fadeLeft: PropTypes.bool,
  fadeRight: PropTypes.bool,
  reverse: PropTypes.bool
};

LandingGallerySection.defaultProps = {
  size: 'lg',
  fadeLeft: false,
  fadeRight: false,
  reverse: false
};

export default LandingGallerySection;
