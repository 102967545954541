import cx from 'classnames';
import { useEffect, useState, useRef, useContext } from 'react';
import { IoPlaySharp, IoPause, IoVolumeHighOutline, IoVolumeMuteOutline } from 'react-icons/io5';
import Slider from 'react-input-slider';

import { Box, SkeletonLoading, Text, Player } from '@components';
import { Duration } from '@libs/utils/duration';
import { PlayerContext } from '@libs/contexts/player';
import { ReactComponent as SpinnerIcon } from '@assets/svg/spinner.svg';
import { randomString } from '@libs/utils/string';
import { fetchImage, fetchAssets } from './hooks';

const MediaVideo = ({ collectible, mime, showPlayer, full, showPlayerSlider }) => {
  const [id, setId] = useState();
  const [image, setImage] = useState();
  const [played, setPlayed] = useState(false);
  const [playerSrc, setPlayerSrc] = useState();
  const [showVolume, setShowVolume] = useState(false);
  const [buffering, setBuffering] = useState(false);
  const [playerData, setPlayerData] = useState({
    played: 0,
    loaded: 0,
    duration: 0,
    seeking: false,
    volume: 1,
    muted: false
  });

  const { isPlaying, play, pause } = useContext(PlayerContext);
  const player = useRef();

  useEffect(() => {
    fetchImage({
      image: collectible?.mediaThumbnail,
      setImage
    });
    fetchAssets({
      assets: collectible?.assetUrl,
      setAssets: setPlayerSrc
    });
    if (!id) {
      setId(collectible?.id + randomString(12));
    }
  }, [collectible?.mediaThumbnail, collectible?.assetUrl, mime]);

  const handleProgress = (state) => {
    // We only want to update time slider if we are not currently seeking
    if (!playerData.seeking) {
      setPlayerData({ ...playerData, ...state });
    }
  };

  const handleDuration = (duration) => {
    setPlayerData({ ...playerData, duration });
  };

  const handleMuteVolume = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (playerData.volume > 0) {
      setPlayerData({ ...playerData, muted: true, volume: 0 });
    } else {
      setPlayerData({ ...playerData, muted: false, volume: 1 });
    }
  };

  const handleShowVolume = () => {
    setShowVolume(!showVolume);
  };

  const mediaStyle = full
    ? {}
    : {
        backgroundImage: `url(${image || collectible?.mediaThumbnail})`
      };

  const playerStyle = {
    'media-hide': !showPlayer
  };

  return (
    <>
      <Box className="relative video">
        {collectible?.mediaThumbnail || playerSrc ? (
          <>
            <Box className={cx('media__video')} style={{ ...mediaStyle }} />
            {buffering && (
              <SpinnerIcon
                style={{
                  fontSize: 40,
                  position: 'absolute',
                  bottom: '48%',
                  left: '48%',
                  zIndex: 55
                }}
              />
            )}

            {showPlayer && (
              <Player
                id={id}
                className={cx('video-player', playerStyle)}
                playsinline={true}
                config={{
                  file: {
                    hlsOptions: {
                      maxMaxBufferLength: 30 // Will stream the resource until next 30seconds only
                    }
                  }
                }}
                ref={(value) => {
                  player.current = value;
                }}
                url={playerSrc}
                light={image || collectible?.mediaThumbnail}
                playIcon={
                  <IoPlaySharp
                    className="m-4 mb-6 absolute bottom-0 left-0 z-50"
                    style={{ width: 24, height: 24 }}
                    width={24}
                    height={24}
                  />
                }
                played={played}
                volume={playerData.volume}
                muted={playerData.muted}
                setPlayed={setPlayed}
                onProgress={handleProgress}
                onDuration={handleDuration}
                onEnded={() => pause(id)}
                onBuffer={() => {
                  setBuffering(true);
                }}
                onBufferEnd={() => {
                  setBuffering(false);
                }}
                width="100%"
                height="100%"
              />
            )}

            {full && played ? (
              <Box
                className={cx('video-player-overlay')}
                onClick={(e) => {
                  isPlaying(id) ? pause(id) : play(id);
                  setPlayed(true);
                  e.preventDefault();
                }}
              ></Box>
            ) : null}
            <Box className={cx('media__video-player', playerStyle)}>
              <Text
                as="span"
                className={`media__video-player__button ${played ? 'visible' : 'invisible'}`}
                onClick={(e) => {
                  isPlaying(id) ? pause(id) : play(id);
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {isPlaying(id) ? <IoPause /> : <IoPlaySharp />}
              </Text>
              <Box
                className="media__video-player__indicator"
                onClickCapture={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {played || showPlayerSlider ? (
                  <>
                    <Duration
                      className="player__controls__duration"
                      seconds={playerData.duration * playerData.played}
                    />
                    <div className="player__controls">
                      <Slider
                        className="player__controls__seek"
                        min={0}
                        axis="x"
                        x={playerData.duration * playerData.played}
                        xmax={playerData.duration}
                        xstep={1}
                        onChange={(e) => {
                          player.current.seekTo(e.x / playerData.duration);
                          setPlayerData({ ...playerData, played: e.x / playerData.duration });
                        }}
                        onDragStart={() => pause(id)}
                        onDragEnd={() => play(id)}
                      />
                      <div
                        className="player__controls__loaded"
                        style={{ width: `${100 * playerData.loaded}%` }}
                      ></div>
                    </div>
                  </>
                ) : null}
                <Duration
                  seconds={playerData.duration}
                  className={cx(
                    'player__controls__duration',
                    !played && !showPlayerSlider ? 'player__controls__duration-no' : ''
                  )}
                />
              </Box>
              {played || showPlayerSlider ? (
                <Text
                  as="span"
                  className="media__audio-player__button media__audio-player__button--right"
                  onClick={(e) => handleMuteVolume(e)}
                  onMouseEnter={handleShowVolume}
                  onMouseLeave={handleShowVolume}
                >
                  {showVolume && (
                    <div
                      className="player__volume"
                      onClickCapture={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    >
                      <Slider
                        min={0}
                        axis="y"
                        y={playerData.volume}
                        ymax={1}
                        yreverse={true}
                        ystep={0.01}
                        onChange={(e) => {
                          setPlayerData({ ...playerData, volume: e.y });
                        }}
                        styles={{
                          thumb: { width: 8, height: 8 },
                          track: { width: 3, height: 75 }
                        }}
                      />
                    </div>
                  )}
                  {!playerData.volume ? <IoVolumeMuteOutline /> : <IoVolumeHighOutline />}
                </Text>
              ) : null}
            </Box>
          </>
        ) : (
          <SkeletonLoading className="aspect-w-6 aspect-h-6" />
        )}
      </Box>
    </>
  );
};

MediaVideo.defaultProps = {
  full: false,
  showPlayer: true,
  showPlayerSlider: false
};

export default MediaVideo;
