import { userPublicFragment } from '@libs/fragments/user';

const findByUsername = /* GraphQL */ `
  query FindByUsername(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...UserPublicProfile
      }
    }
  }

  ${userPublicFragment}
`;

export default findByUsername;
