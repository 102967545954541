import React from 'react';

export default function Marquee({ title }) {
  return (
    <>
      {[...Array(10)].map((_, i) => (
        <div key={i}>
          <div className="marquee">
            <div className="content">
              <span>{title}</span>
              <span>{title}</span>
              <span>{title}</span>
              <span>{title}</span>
              <span>{title}</span>
              <span>{title}</span>
              <span>{title}</span>
              <span>{title}</span>
              <span>{title}</span>
              <span>{title}</span>
              <span>{title}</span>
              <span>{title}</span>
              <span>{title}</span>
              <span>{title}</span>
              <span>{title}</span>
              <span>{title}</span>
              <span>{title}</span>
              <span>{title}</span>
            </div>
          </div>
          <div className="marquee">
            <div className="content-reverse">
              <span>{title}</span>
              <span>{title}</span>
              <span>{title}</span>
              <span>{title}</span>
              <span>{title}</span>
              <span>{title}</span>
              <span>{title}</span>
              <span>{title}</span>
              <span>{title}</span>
              <span>{title}</span>
              <span>{title}</span>
              <span>{title}</span>
              <span>{title}</span>
              <span>{title}</span>
              <span>{title}</span>
              <span>{title}</span>
              <span>{title}</span>
              <span>{title}</span>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
