import { useState, useCallback } from 'react';
import cx from 'classnames';
import { Box } from '@components';
import { useOutsider } from '@libs/utils/outsider';
import { ReactComponent as Times } from '@assets/svg/times.svg';
import { ReactComponent as Dropdown } from '@assets/svg/dropdown-icon.svg';

const SelectMultiple = ({ placeholder, options, setValue, selected, ...props }) => {
  const [isOpenDropdown, setOpenDropdown] = useState();
  const handleCloseDropdown = () => setOpenDropdown(false);
  const { ref: refDropdown } = useOutsider(handleCloseDropdown);
  const handleOpenDropdown = useCallback(
    (e) => {
      e.preventDefault();
      setOpenDropdown(!isOpenDropdown);
    },
    [isOpenDropdown]
  );
  const selectOption = (value) => {
    const isExists = selected?.filter((x) => x.id === value.id);
    const newValue =
      isExists?.length > 0 ? selected?.filter((x) => x.id !== value.id) : [...selected, value];
    setValue(newValue);
  };

  return (
    <>
      <Box className="select-wrapper select-multiple" onClick={handleOpenDropdown}>
        <Box className="select">{placeholder}</Box>
        <Dropdown
          className={cx(
            'select-multiple__dropdown-icon',
            isOpenDropdown && 'select-multiple__dropdown-icon-open'
          )}
        />
        {isOpenDropdown && (
          <Box className="select-multiple__dropdown">
            <ul ref={refDropdown}>
              {options &&
                options.map((o) => (
                  <li
                    className={cx(
                      selected?.filter((x) => x.id === o.value)?.length > 0 && 'active'
                    )}
                    key={o.value}
                    value={o.value}
                    onClick={() => {
                      selectOption({
                        id: o.value,
                        label: o.label
                      });
                    }}
                  >
                    {o.label}
                  </li>
                ))}
            </ul>
          </Box>
        )}
      </Box>
      {selected?.length > 0 && (
        <Box className={cx('select-multiple__selected', selected?.length > 0 && 'mt-2 md:mt-4')}>
          {selected?.map((o) => (
            <Box className="select-multiple__selected-item" key={'selected-' + o.id}>
              {o.label}{' '}
              <Times
                onClick={() => {
                  selectOption({
                    id: o.id,
                    label: o.label
                  });
                }}
              />
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export default SelectMultiple;
