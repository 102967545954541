import cx from 'classnames';
import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Slider from 'react-slick';
import { IoChevronDown, IoChevronDownSharp } from 'react-icons/io5';
import {
  Box,
  Button,
  CheckboxCategory,
  Heading,
  CollectibleSkeleton,
  Text,
  Link,
  SkeletonLoading
} from '@components';
import { useOutsider } from '@libs/utils/outsider';
import { ReactComponent as NoItem } from '@assets/svg/no-item.svg';
import { InfiniteScroll } from 'react-simple-infinite-scroll-patched';
// uncomment this to show the end of the pagination feedback
// import { ReactComponent as NoMoreItem } from '@assets/svg/check-circle.svg';
import { categories, listEras, listSort } from '@libs/utils/options';
import {
  singleGenreParser,
  eraParser,
  typeParser,
  sortParser
} from '@pages/collectible/fansinc/utils';

const CollectibleGrid = ({
  loading,
  className,
  children,
  defaultCollectible,
  defaultArtist,
  filterCollectible,
  filterable,
  filterByGenre,
  filterByYear,
  filterByArtist,
  filterMultipleByArtist,
  listArtist,
  listEra,
  listGenre,
  listEraLoading,
  listGenreLoading,
  listArtistLoading,
  sortable,
  profile,
  onChangeCategory,
  onChangeSort,
  onChangeGenre,
  onChangeYearRange,
  onChangeFeaturedArtist,
  onChangeEraMultipleCase,
  showEmpty,
  username,
  hasNoMore,
  infiniteScroll,
  release,
  viewAll,
  nextData,
  otherCollectibles,
  owner,
  ownerUsername,
  yearFilter,
  isSlider,
  profileCollectibleLink,
  showTotal,
  total,
  ...props
}) => {
  const { t } = useTranslation();
  const defaultTypes = {
    ARTWORK: 0,
    PHOTOGRAPH: 0,
    FILM_POSTER: 0,
    SONG: 0,
    SONG_LYRICS: 0,
    VIDEO: 0,
    OTHER: 0
  };
  const types = {
    ARTWORK: 0,
    PHOTOGRAPH: 0,
    FILM_POSTER: 0,
    SONG: 0,
    SONG_LYRICS: 0,
    VIDEO: 0,
    OTHER: 0
  };
  const defaultEras = {
    1970: 0,
    1980: 0,
    1990: 0,
    2000: 0,
    2010: 0,
    2020: 0
  };
  const eras = {
    1970: 0,
    1980: 0,
    1990: 0,
    2000: 0,
    2010: 0,
    2020: 0
  };
  const [categoryList, setCategoryList] = useState();
  const [erasList, setErasList] = useState();

  useEffect(() => {
    if (filterCollectible) {
      setCategoryList(
        (filterCollectible || []).reduce((prev, col) => {
          prev[col.category] += 1;
          return prev;
        }, types)
      );
    }
  }, [filterCollectible]); // eslint-disable-line
  const defaultEra = (defaultCollectible || []).reduce((prev, col) => {
    if (col.yearOfCreation >= 1970 && col.yearOfCreation < 1980) {
      prev['1970'] += 1;
      return prev;
    } else if (col.yearOfCreation >= 1980 && col.yearOfCreation < 1990) {
      prev['1980'] += 1;
      return prev;
    } else if (col.yearOfCreation >= 1990 && col.yearOfCreation < 2000) {
      prev['1990'] += 1;
      return prev;
    } else if (col.yearOfCreation >= 2000 && col.yearOfCreation < 2010) {
      prev['2000'] += 1;
      return prev;
    } else if (col.yearOfCreation >= 2010 && col.yearOfCreation < 2020) {
      prev['2010'] += 1;
      return prev;
    } else if (col.yearOfCreation >= 2020 && col.yearOfCreation < 2030) {
      prev['2020'] += 1;
      return prev;
    } else {
      return prev;
    }
  }, eras);
  const defaultCategory = (defaultCollectible || []).reduce((prev, col) => {
    prev[col.category] += 1;
    return prev;
  }, defaultTypes);
  useEffect(() => {
    if (filterCollectible) {
      setErasList(
        (filterCollectible || []).reduce((prev, col) => {
          if (col.yearOfCreation >= 1970 && col.yearOfCreation < 1980) {
            prev['1970'] += 1;
            return prev;
          } else if (col.yearOfCreation >= 1980 && col.yearOfCreation < 1990) {
            prev['1980'] += 1;
            return prev;
          } else if (col.yearOfCreation >= 1990 && col.yearOfCreation < 2000) {
            prev['1990'] += 1;
            return prev;
          } else if (col.yearOfCreation >= 2000 && col.yearOfCreation < 2010) {
            prev['2000'] += 1;
            return prev;
          } else if (col.yearOfCreation >= 2010 && col.yearOfCreation < 2020) {
            prev['2010'] += 1;
            return prev;
          } else if (col.yearOfCreation >= 2020 && col.yearOfCreation < 2030) {
            prev['2020'] += 1;
            return prev;
          } else {
            return prev;
          }
        }, defaultEras)
      );
    }
  }, [filterCollectible]); // eslint-disable-line
  const sliderRef = useRef();
  const [selected, setSelected] = useState([]);
  const [selectedGenre, setSelectedGenre] = useState();
  const [selectedEra, setSelectedEra] = useState(null);
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [selectedSort, setSelectedSort] = useState('date released (descending)');
  const [selectedArtistName, setSelectedArtistName] = useState();
  const [page, setPage] = useState(1);

  const [isOpenSort, setOpenSort] = useState();
  const [isOpenFilter, setOpenFilter] = useState(false);
  const [isOpenGenre, setOpenGenre] = useState(false);
  const [isOpenYear, setOpenYear] = useState(false);
  const [isOpenArtist, setOpenArtist] = useState(false);

  const handleCloseSort = () => setOpenSort(false);
  const handleCloseFilter = () => setOpenFilter(false);
  const handleCloseGenre = () => setOpenGenre(false);
  const handleCloseYear = () => setOpenYear(false);
  const handleCloseArtist = () => setOpenArtist(false);

  const { ref: refSort } = useOutsider(handleCloseSort);
  const { ref: refFilter } = useOutsider(handleCloseFilter);
  const { ref: refGenre } = useOutsider(handleCloseGenre);
  const { ref: refYear } = useOutsider(handleCloseYear);
  const { ref: refArtist } = useOutsider(handleCloseArtist);
  let useArtistList = !selectedEra && !selected.length > 0 ? defaultArtist : listArtist;
  let useCategoryList = !selectedEra && !selectedArtist ? defaultCategory : categoryList;
  let useEraList = !selectedArtist && !selected.length > 0 ? defaultEra : erasList;

  const handleOpenSort = useCallback(
    (e) => {
      e.preventDefault();
      setOpenSort(!isOpenSort);
    },
    [isOpenSort]
  );

  const loadMore = async () => {
    setPage(page + 1);
    await nextData();
  };

  const onClickSort = (sort) => {
    setPage(1);
    onChangeSort(sort);
  };

  const onClickCategory = useCallback(
    (category) => {
      const newValue = selected.includes(category)
        ? selected.filter((s) => s !== category)
        : selected.concat([category]);
      setSelected(newValue);
      onChangeCategory(newValue);
      setPage(1);
      handleCloseFilter();
    },
    [selected] // eslint-disable-line
  );

  const onClickOneCategory = (category) => {
    const newValue = selected.includes(category)
      ? selected.filter((s) => s !== category)
      : category;
    if (newValue.length === 0) {
      onChangeCategory(newValue);
      setSelected(newValue);
    } else {
      onChangeCategory([newValue]);
      setSelected([newValue]);
    }
    setPage(1);
  }; // eslint-disable-line

  const onHandleClickSort = (value, name) => {
    if (name !== selectedSort) {
      setSelectedSort(name);
      onChangeSort(value);
    }
  }; // eslint-disable-line

  const onClickGenre = useCallback(
    (genre) => {
      if (genre !== selectedGenre) {
        setSelectedGenre(genre);
        onChangeGenre(genre);
      } else {
        setSelectedGenre(null);
        onChangeGenre(null);
      }
    },
    [selectedGenre] // eslint-disable-line
  );

  const onClickEra = useCallback(
    (era) => {
      if (era !== selectedEra) {
        setSelectedEra(era);
        onChangeYearRange(era);
      } else {
        setSelectedEra(null);
        onChangeYearRange(null);
      }
    },
    [selectedEra] // eslint-disable-line
  );

  const onClickEraMultipleCase = (era) => {
    if (era !== selectedEra) {
      setSelectedEra(era);
      onChangeEraMultipleCase(era);
    } else {
      setSelectedEra(null);
      onChangeEraMultipleCase(null);
    }
  }; // eslint-disable-line

  const onClickArtist = useCallback(
    (id, name) => {
      if (id !== selectedArtist) {
        setSelectedArtist(id);
        setSelectedArtistName(name);
        onChangeFeaturedArtist(id);
      } else {
        setSelectedArtist(null);
        setSelectedArtistName(null);
        onChangeFeaturedArtist(null);
      }
    },
    [selectedArtist] // eslint-disable-line
  );

  const onClickArtistMultipleCase = (id, name) => {
    if (id !== selectedArtist) {
      setSelectedArtist(id);
      setSelectedArtistName(name);
      onChangeFeaturedArtist(id);
    } else {
      setSelectedArtist(null);
      setSelectedArtistName(null);
      onChangeFeaturedArtist(null);
    }
  };

  const handleOpenFilter = useCallback(
    (e) => {
      e.preventDefault();
      setOpenFilter(!isOpenFilter);
    },
    [isOpenFilter]
  );

  const handleOpenGenre = useCallback(
    (e) => {
      e.preventDefault();
      setOpenGenre(!isOpenGenre);
    },
    [isOpenGenre]
  );

  const handleOpenYear = useCallback(
    (e) => {
      e.preventDefault();
      setOpenYear(!isOpenYear);
    },
    [isOpenYear]
  );

  const handleOpenArtist = useCallback(
    (e) => {
      e.preventDefault();
      setOpenArtist(!isOpenArtist);
    },
    [isOpenArtist]
  );

  const defaultStyle = {
    'collectible-grid__header': true,
    'collectible-grid__header__sort-only': !filterable && sortable
  };

  const settings = {
    dots: false,
    focusOnSelect: false,
    arrows: true,
    infinite: false,
    autoplay: false,
    draggable: false,
    swipe: false,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 3,
    className: 'collectible-grid-slider',
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  useEffect(() => {
    if (!profile) {
      setSelectedSort(null);
    }
  }, [profile]);

  return (
    <Box>
      {otherCollectibles && (
        <Text className="collectible-grid__header__owner text-default">
          <Trans i18nKey="components.collectibleGrid.owner">
            <Link to={`/${ownerUsername}`}>{{ owner: owner }}</Link>
          </Trans>
        </Text>
      )}
      {profile ? (
        <>
          {filterByGenre ? (
            <>
              {listGenreLoading ? (
                <SkeletonLoading className="collectible-grid__header__filter__loading" />
              ) : (
                <>
                  <Box className="collectible-grid__genre">
                    <Text className="collectible-grid__header__filter-by">
                      <Text as="span" className="collectible-grid__header__filter-by__type">
                        <Trans i18nKey="components.collectibleGrid.genre"></Trans>
                      </Text>
                      <Text
                        as="span"
                        className="collectible-grid__header__filter-by__item"
                        onClick={handleOpenGenre}
                      >
                        {singleGenreParser(selectedGenre)} <IoChevronDownSharp />
                      </Text>
                    </Text>
                    {isOpenGenre && (
                      <ul ref={refGenre} className="collectible-grid__header__filter__dropdown">
                        {listGenre.map((cat) => (
                          <li key={cat.value}>
                            <CheckboxCategory
                              isChecked={selectedGenre === cat.value}
                              onClick={() => onClickGenre(cat.value)}
                            >
                              {cat.name}
                            </CheckboxCategory>
                          </li>
                        ))}
                      </ul>
                    )}
                    <Box className="collectible-grid__header__profile__button">
                      <Link to={profileCollectibleLink}>
                        <Button outline>{t('components.collectibleGrid.viewAll')}</Button>
                      </Link>
                    </Box>
                  </Box>
                </>
              )}
            </>
          ) : null}

          {filterByYear ? (
            <>
              {listEraLoading ? (
                <SkeletonLoading className="collectible-grid__header__filter__loading" />
              ) : (
                <Box className="collectible-grid__year">
                  <Text className="collectible-grid__header__filter-by">
                    <Text as="span" className="collectible-grid__header__filter-by__type">
                      <Trans i18nKey="components.collectibleGrid.era"></Trans>
                    </Text>
                    <Text
                      as="span"
                      className={cx(
                        'collectible-grid__header__filter-by__item',
                        selectedEra !== null
                          ? 'collectible-grid__header__filter-by__item-lowercase'
                          : ''
                      )}
                      onClick={handleOpenYear}
                    >
                      {eraParser(selectedEra)} <IoChevronDownSharp />
                    </Text>
                  </Text>
                  {isOpenYear && (
                    <ul ref={refYear} className="collectible-grid__header__filter__dropdown ">
                      {listEra.map((era) => (
                        <li key={era}>
                          <CheckboxCategory
                            isChecked={selectedEra === era}
                            onClick={() => onClickEra(era)}
                          >
                            {era}
                          </CheckboxCategory>
                        </li>
                      ))}
                    </ul>
                  )}
                  <Box className="collectible-grid__header__profile__button">
                    <Link to={profileCollectibleLink}>
                      <Button outline>{t('components.collectibleGrid.viewAll')}</Button>
                    </Link>
                  </Box>
                </Box>
              )}
            </>
          ) : null}

          {filterByArtist ? (
            <>
              {listArtistLoading ? (
                <SkeletonLoading className="collectible-grid__header__filter__loading" />
              ) : (
                <Box className="collectible-grid__year">
                  <Text className="collectible-grid__header__filter-by">
                    <Text as="span" className="collectible-grid__header__filter-by__type">
                      <Trans i18nKey="components.collectibleGrid.featuredArtist"></Trans>
                    </Text>
                    <Text
                      as="span"
                      className="collectible-grid__header__filter-by__item"
                      onClick={handleOpenArtist}
                    >
                      {selectedArtist === null
                        ? t('components.collectibleGrid.all')
                        : selectedArtistName}{' '}
                      <IoChevronDownSharp />
                    </Text>
                  </Text>
                  {isOpenArtist && (
                    <ul ref={refArtist} className="collectible-grid__header__filter__dropdown">
                      {listArtist.map((artist) => (
                        <li key={artist.id}>
                          <CheckboxCategory
                            isChecked={selectedArtist === artist.id}
                            onClick={() => onClickArtist(artist.id, artist.artistName)}
                          >
                            {artist.artistName}
                          </CheckboxCategory>
                        </li>
                      ))}
                    </ul>
                  )}
                  <Box className="collectible-grid__header__profile__button">
                    <Link to={profileCollectibleLink}>
                      <Button outline>{t('components.collectibleGrid.viewAll')}</Button>
                    </Link>
                  </Box>
                </Box>
              )}
            </>
          ) : null}
        </>
      ) : null}

      {!profile ? (
        (filterable || sortable) && (
          <Box className={cx(defaultStyle)}>
            {filterable && (
              <Box className="collectible-grid__header__filter">
                <Box
                  className="collectible-grid__header__filter__button__wrapper"
                  onClick={handleOpenFilter}
                >
                  {t('components.collectibleGrid.category')}
                  <IoChevronDown />
                </Box>
                {isOpenFilter && (
                  <ul ref={refFilter} className="collectible-grid__header__filter__dropdown">
                    {categories.map((cat) => (
                      <li key={cat.value}>
                        <CheckboxCategory
                          isChecked={selected.includes(cat.value)}
                          onClick={() => onClickCategory(cat.value)}
                        >
                          {cat.label}
                        </CheckboxCategory>
                      </li>
                    ))}
                  </ul>
                )}
              </Box>
            )}
            {sortable && (
              <Box className="collectible-grid__header__sort">
                <Text className="collectible-grid__header__sort__wrapper" onClick={handleOpenSort}>
                  {t('components.collectibleGrid.sortBy')}
                  {selectedSort && (
                    <span className="hidden md:inline-block ml-1 text-white">
                      {sortParser(selectedSort)}
                    </span>
                  )}

                  <IoChevronDown />
                </Text>
                {isOpenSort && (
                  <ul
                    ref={refSort}
                    onClick={handleCloseSort}
                    className="collectible-grid__header__sort__dropdown"
                  >
                    <li>
                      <Text
                        as="span"
                        onClick={(e) => {
                          e.preventDefault();
                          onClickSort('RELEASED_AT_ASC');
                          setSelectedSort(t('components.collectibleGrid.oldest'));
                        }}
                      >
                        {t('components.collectibleGrid.oldest')}
                      </Text>
                    </li>
                    <li>
                      <Text
                        as="span"
                        onClick={(e) => {
                          e.preventDefault();
                          onClickSort('RELEASED_AT_DESC');
                          setSelectedSort(t('components.collectibleGrid.mostRecent'));
                        }}
                      >
                        {t('components.collectibleGrid.mostRecent')}
                      </Text>
                    </li>
                  </ul>
                )}
              </Box>
            )}
          </Box>
        )
      ) : (
        // PROFILE PAGE
        <Box className={cx(defaultStyle, 'collectible-grid__header__profile')}>
          <Box className="collectible-grid__header__profile__filter">
            <Box className="collectible-grid__header__profile__filter__item">
              {filterable && (
                <>
                  <Box className="collectible-grid__year">
                    <Text className="collectible-grid__header__filter-by">
                      <Text as="span" className="collectible-grid__header__filter-by__type">
                        <Trans i18nKey="components.collectibleGrid.type"></Trans>
                      </Text>
                      <Text
                        as="span"
                        className={cx('collectible-grid__header__filter-by__item')}
                        onClick={handleOpenFilter}
                      >
                        {typeParser(selected)} <IoChevronDownSharp />
                      </Text>
                    </Text>
                    {isOpenFilter && (
                      <ul
                        ref={refFilter}
                        className="collectible-grid__header__filter__dropdown__multiple"
                      >
                        {categories.map((cat) => (
                          <>
                            {useCategoryList[cat.value] > 0 && (
                              <li key={cat.value}>
                                <CheckboxCategory
                                  isChecked={selected.includes(cat.value)}
                                  onClick={() => {
                                    if (release) {
                                      // do nothing
                                    } else {
                                      onClickOneCategory(cat.value);
                                    }
                                  }}
                                >
                                  {cat.name}
                                </CheckboxCategory>
                              </li>
                            )}
                          </>
                        ))}
                      </ul>
                    )}
                  </Box>
                </>
              )}
              {filterMultipleByArtist ? (
                <>
                  <Box className="collectible-grid__artist">
                    <Text className="collectible-grid__header__filter-by">
                      <Text as="span" className="collectible-grid__header__filter-by__type">
                        <Trans i18nKey="components.collectibleGrid.featuredArtist"></Trans>
                      </Text>
                      <Text
                        as="span"
                        className="collectible-grid__header__filter-by__item"
                        onClick={handleOpenArtist}
                      >
                        {selectedArtist === null
                          ? t('components.collectibleGrid.all')
                          : selectedArtistName}{' '}
                        <IoChevronDownSharp />
                      </Text>
                    </Text>
                    {isOpenArtist && (
                      <ul
                        ref={refArtist}
                        className="collectible-grid__header__filter__dropdown__multiple collectible-grid__header__filter__dropdown__multiple-new"
                      >
                        <li className={cx(selectedArtist === null && 'active')}>
                          <CheckboxCategory
                            isChecked={selectedArtist === null}
                            onClick={() => {
                              setSelectedArtist(null);
                              setSelectedArtistName(null);
                              onChangeFeaturedArtist(null);
                            }}
                          >
                            All
                          </CheckboxCategory>
                        </li>
                        {useArtistList.map((artist) => (
                          <li
                            key={artist.id}
                            className={cx(selectedArtist === artist.id && 'active')}
                          >
                            <CheckboxCategory
                              isChecked={selectedArtist === artist.id}
                              onClick={() =>
                                onClickArtistMultipleCase(artist.id, artist.artistName)
                              }
                            >
                              {artist.artistName}
                            </CheckboxCategory>
                          </li>
                        ))}
                      </ul>
                    )}
                  </Box>
                </>
              ) : null}
              {yearFilter ? (
                <>
                  <Box className="collectible-grid__year">
                    <Text className="collectible-grid__header__filter-by">
                      <Text as="span" className="collectible-grid__header__filter-by__type">
                        <Trans i18nKey="components.collectibleGrid.era"></Trans>
                      </Text>
                      <Text
                        as="span"
                        className={cx(
                          'collectible-grid__header__filter-by__item',
                          selectedEra !== null
                            ? 'collectible-grid__header__filter-by__item-lowercase'
                            : ''
                        )}
                        onClick={handleOpenYear}
                      >
                        {eraParser(selectedEra)} <IoChevronDownSharp />
                      </Text>
                    </Text>
                    {isOpenYear && (
                      <ul
                        ref={refYear}
                        className="collectible-grid__header__filter__dropdown__multiple collectible-grid__header__filter__dropdown__multiple-new collectible-grid__header__filter__dropdown__multiple-new-year"
                      >
                        <li key="0" className={cx(selectedEra === null && 'active')}>
                          <CheckboxCategory
                            isChecked={selectedEra === null}
                            onClick={() => {
                              setSelectedEra(null);
                              onChangeEraMultipleCase(null);
                            }}
                          >
                            All
                          </CheckboxCategory>
                        </li>
                        {listEras.map((era) => (
                          <>
                            {useEraList[era.label] > 0 && (
                              <li
                                key={era.value}
                                className={cx(selectedEra === era.value && 'active')}
                              >
                                <CheckboxCategory
                                  isChecked={selectedEra === era.value}
                                  onClick={() => onClickEraMultipleCase(era.value)}
                                >
                                  {era.value}
                                </CheckboxCategory>
                              </li>
                            )}
                          </>
                        ))}
                      </ul>
                    )}
                  </Box>
                </>
              ) : null}
              {sortable && (
                <>
                  <Box className="collectible-grid__year">
                    <Text className="collectible-grid__header__filter-by">
                      <Text as="span" className="collectible-grid__header__filter-by__type">
                        <Trans i18nKey="components.collectibleGrid.sortBy"></Trans>
                      </Text>
                      <Text
                        as="span"
                        className={cx('collectible-grid__header__filter-by__item')}
                        onClick={handleOpenSort}
                      >
                        {sortParser(selectedSort)} <IoChevronDownSharp />
                      </Text>
                    </Text>
                    {isOpenSort && (
                      <ul
                        ref={refSort}
                        className="collectible-grid__header__filter__dropdown__multiple__sort"
                      >
                        {listSort.map((sort) => (
                          <li key={sort.name}>
                            <CheckboxCategory
                              isChecked={selectedSort === sort.name}
                              onClick={() => onHandleClickSort(sort.value, sort.name)}
                            >
                              {sort.name}
                            </CheckboxCategory>
                          </li>
                        ))}
                      </ul>
                    )}
                  </Box>
                </>
              )}
            </Box>
            <Box>
              {showTotal ? (
                <>
                  {total} {t(`components.collectibleGrid.item${total !== 1 ? 's' : ''}`)}
                </>
              ) : null}
            </Box>
          </Box>
          {viewAll && (
            <Box className="collectible-grid__header__profile__button">
              <Button outline>{t('components.collectibleGrid.viewAll')}</Button>
            </Box>
          )}
        </Box>
      )}
      {showEmpty && !loading && children.length < 1 ? (
        <Box className="collectible-grid__empty">
          {!profile ? <NoItem /> : null}
          <Heading as="h4" className="collectible-grid__empty__title">
            {profile
              ? t('components.collectibleGrid.noItemsFoundProfile')
              : t('components.collectibleGrid.noItemsFound')}
          </Heading>
          <Text className="collectible-grid__empty__description">
            {profile ? (
              <Trans i18nKey="components.collectibleGrid.noItemsFoundProfileDescription">
                <Link to="/marketplace"></Link>
              </Trans>
            ) : (
              t('components.collectibleGrid.noItemsFoundDescription')
            )}
          </Text>
        </Box>
      ) : (
        <>
          {infiniteScroll ? (
            <>
              {page % 5 !== 0 ? (
                <InfiniteScroll
                  throttle={500}
                  isLoading={loading}
                  hasMore={!hasNoMore}
                  onLoadMore={loadMore}
                >
                  <Box className={cx(className, 'collectible-grid__content')}>
                    {children}
                    {loading && (
                      <>
                        <CollectibleSkeleton />
                        <CollectibleSkeleton />
                        <CollectibleSkeleton />
                      </>
                    )}
                  </Box>
                </InfiniteScroll>
              ) : (
                <>
                  <Box className={cx(className, 'collectible-grid__content')}>
                    {children}
                    {loading && (
                      <>
                        <CollectibleSkeleton />
                        <CollectibleSkeleton />
                        <CollectibleSkeleton />
                      </>
                    )}
                  </Box>
                  {!hasNoMore && !loading && (
                    <Box className="text-center">
                      <Button className="mt-5 mx-auto px-10 btn-load-more" onClick={loadMore}>
                        {t('home.button.loadMore')}
                      </Button>
                    </Box>
                  )}
                </>
              )}
            </>
          ) : !isSlider ? (
            <Box className={cx(className, 'collectible-grid__content')}>
              {loading && (
                <>
                  <CollectibleSkeleton />
                  <CollectibleSkeleton />
                  <CollectibleSkeleton />
                </>
              )}
              {children}
            </Box>
          ) : (
            <Box className={cx(children?.length < 4 ? 'collectible-grid-slider-no-arrow' : '')}>
              {loading ? (
                <Box className={cx(className, 'collectible-grid__content')}>
                  <CollectibleSkeleton />
                  <CollectibleSkeleton />
                  <CollectibleSkeleton />
                </Box>
              ) : (
                <Slider {...settings} ref={sliderRef}>
                  {children}
                </Slider>
              )}
            </Box>
          )}
        </>
      )}
      {/* uncomment this to show the end of the pagination feedback  */}
      {/* {(infiniteScroll && hasNoMore && (!children?.length < 1)) && (
        <Box className="collectible-grid__all-item-loaded">
        <NoMoreItem />
        <Heading as="h4" className="collectible-grid__all-item-loaded__title">
          {t('components.collectibleGrid.noMoreItem')}
        </Heading>
        <Text className="collectible-grid__all-item-loaded__description">
          {t('components.collectibleGrid.noMoreItemDescription')}
        </Text>
      </Box>
      )} */}
    </Box>
  );
};

CollectibleGrid.defaultProps = {
  showEmpty: false,
  profile: false,
  profileCollectibleLink: '/',
  release: false,
  viewAll: false,
  otherCollectibles: false,
  loading: true,
  filterByGenre: false,
  filterByArtist: false,
  isSlider: false,
  listArtist: [],
  listEra: [],
  listGenre: [],
  listEraLoading: false,
  listGenreLoading: false,
  listArtistLoading: false,
  showTotal: false,
  total: 0,
  onChangeGenre: () => {},
  onChangeYearRange: () => {},
  onChangeFeaturedArtist: () => {}
};

export default CollectibleGrid;
