import { useTranslation } from 'react-i18next';
import { Box, Container, Text, Link, Logo, SocialMedia } from '@components';
import config from '@/config';

const socialMedia = {
  facebook: config.facebookUrl,
  instagram: config.instagramUrl,
  twitter: config.twitterUrl,
  youtube: config.youtube
};

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="relative footer footer-coming-soon-patrons">
      <Container>
        <Box className="flex flex-row items-end justify-between">
          <Box className="text-left">
            <Link to="/marketplace" target="_blank" className="">
              <Logo width="150" height="50" footer={true} />
            </Link>
          </Box>
          <Box>
            <SocialMedia socialMedia={socialMedia} darkMode className="inline-block" />
          </Box>
        </Box>
        <Box className="absolute">
          <Text className="text-white">{t('p.components.footer.copyright')}</Text>
        </Box>
      </Container>
    </footer>
  );
}

export default Footer;
