import { Helmet } from 'react-helmet';
import { useTranslation, Trans } from 'react-i18next';
import { useForm } from 'react-hook-form';

import {
  Avatar,
  Box,
  Button,
  Col,
  CoverImage,
  FormField,
  Grid,
  Cropper,
  Input,
  Text,
  TextArea,
  Loading,
  Modal,
  Select
} from '@components';

import { useAuth } from '@libs/contexts/auth';
import { useUser } from '@libs/hooks/user';
import { useUploader, useImageResizer } from '@libs/utils/uploader';
import { useState } from 'react';
import countries from '@libs/json/countries.json';

function SettingsProfile() {
  const { t } = useTranslation();
  const URL_PATTERN =
    /(http(s?):\/\/)[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi;
  const FACEBOOK_PATTERN =
    /(http(s?):\/\/)((www.)?facebook.com\/){1,6}([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi;
  const TWITTER_PATTERN =
    /(http(s?):\/\/)((www.)?twitter.com\/){1,6}([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi;
  const INSTAGRAM_PATTERN =
    /(http(s?):\/\/)((www.)?instagram.com\/){1,6}([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi;
  const EMAIL_PATTERN =
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,255})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
  const NAME_PATTERN = /^[a-zA-Z0-9 '.-]*$/;

  const {
    inputRef: coverRef,
    result: coverResult,
    loading: onLoadingUploderCover,
    onClick: onClickUploadCover,
    onUpload: onUploadCover
  } = useUploader();

  const {
    inputRef: avatarRef,
    result: avatarResult,
    loading: onLoadingUploderAvatar,
    onClick: onClickUploadAvatar,
    onUpload: onUploadAvatar
  } = useUploader();

  const { loading: imageResizeLoading, onResize } = useImageResizer();
  const { loading: coverResizeLoading, onResize: onResizeCover } = useImageResizer();
  const [uploadAvatarLoading, setUploadAvatarLoading] = useState(false);
  const [uploadCoverLoading, setUploadCoverLoading] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({
    mode: 'onChange'
  });
  const [firstNameLength, setFirstNameLength] = useState('0');
  const [lastNameLength, setLastNameLength] = useState('0');
  const [bioLength, setBioLength] = useState('0');

  const { onChange: onChangeFirstName, ...restFirstName } = register('firstName', {
    required: { value: true, message: t('p.editProfile.firstNameRequired') },
    pattern: {
      value: NAME_PATTERN,
      message: t('p.editProfile.firstNamePattern')
    },
    maxLength: {
      value: 50,
      message: <Trans i18nKey="p.editProfile.firstNameMaxLength">{firstNameLength}</Trans>
    }
  });
  const { onChange: onChangeLastName, ...restLastName } = register('lastName', {
    required: { value: true, message: t('p.editProfile.lastNameRequired') },
    pattern: {
      value: NAME_PATTERN,
      message: t('p.editProfile.lastNamePattern')
    },
    maxLength: {
      value: 50,
      message: <Trans i18nKey="p.editProfile.lastNameMaxLength">{lastNameLength}</Trans>
    }
  });

  const { onChange: onChangeBio, ...restBio } = register('bio', {
    maxLength: {
      value: 800,
      message: <Trans i18nKey="p.editProfile.bioMaxLength">{bioLength}</Trans>
    }
  });

  const { user, loading, getCurrentUser } = useAuth();
  const {
    updateLoading,
    updateAvatarLoading,
    updateCoverLoading,
    removeAvatarLoading,
    removeCoverLoading,
    onUpdate,
    onUpdateAvatar,
    onRemoveAvatar,
    onUpdateCover,
    onRemoveCover
  } = useUser();
  const [modalAvatar, setModalAvatar] = useState(false);
  const [modalCover, setModalCover] = useState(false);
  const [croppedAvatar, setCroppedAvatar] = useState();

  const handleOpenModalAvatar = () => {
    setModalAvatar(true);
  };

  const handleCloseModalAvatar = () => {
    if (
      !(onLoadingUploderAvatar || imageResizeLoading || updateAvatarLoading || uploadAvatarLoading)
    ) {
      setModalAvatar(false);
    }
  };

  const handleOpenModalCover = () => {
    setModalCover(true);
  };

  const handleCloseModalCover = () => {
    if (
      !(onLoadingUploderCover || coverResizeLoading || updateCoverLoading || uploadCoverLoading)
    ) {
      setModalCover(false);
    }
  };

  const handleRemoveAvatar = async () => {
    await onRemoveAvatar('remove');
    await getCurrentUser();
  };

  const handleRemoveCover = async () => {
    await onRemoveCover('remove');
    await getCurrentUser();
  };

  const onSubmit = async (data) => {
    reset(data);
    const payload = {
      ...data,
      shippingAddress: {
        line1: user?.shippingAddress?.line1,
        country: user?.shippingAddress?.country,
        stateName: user?.shippingAddress?.stateName,
        city: user?.shippingAddress?.city,
        postCode: user?.shippingAddress?.postCode,
        phone: user?.shippingAddress?.phone,
        recipientName: user?.shippingAddress?.recipientName
      }
    };
    await onUpdate(payload);
    await getCurrentUser();
  };

  return (
    <Box className="settings">
      <Modal
        verticalCenter
        showCloseIcon
        className="modal-avatar"
        isOpen={modalAvatar}
        onClose={handleCloseModalAvatar}
        heading={t('p.pages.settings.profile.fields.avatar.uploadImage')}
        size="profile"
      >
        <Box className="modal-avatar__upload">
          <Text className="text-gray-400">
            {t('p.pages.settings.profile.fields.avatar.placeholder')}
          </Text>
          <Button
            onClick={onClickUploadAvatar}
            disabled={
              onLoadingUploderAvatar ||
              imageResizeLoading ||
              updateAvatarLoading ||
              uploadAvatarLoading
            }
            loading={onLoadingUploderAvatar}
            className="modal-avatar__upload__button"
          >
            {t('p.pages.settings.profile.fields.avatar.chooseFile')}
          </Button>
          <input
            ref={avatarRef}
            accept="image/jpeg, image/png, image/webp"
            className="invisible w-0"
            type="file"
            onChange={(e) => onUploadAvatar(e, 'avatar')}
          />
        </Box>

        {avatarRef?.current?.value && avatarResult.fileUrl ? (
          <Box className="modal-avatar__cropper">
            <Cropper
              loading={imageResizeLoading || updateAvatarLoading || uploadAvatarLoading}
              image={avatarResult.fileUrl}
              onComplete={async (croppedArea) => {
                setUploadAvatarLoading(true);
                const resized = await onResize({
                  key: avatarResult.s3?.key,
                  folder: 'avatars',
                  ...croppedArea
                });

                if (resized) {
                  setCroppedAvatar(resized?.key);
                  await onRemoveAvatar();
                  await onUpdateAvatar(resized?.key);
                  await getCurrentUser();
                  setUploadAvatarLoading(false);
                }

                avatarRef.current.value = '';
                setUploadAvatarLoading(false);
                setModalAvatar(false);
              }}
              onCancel={() => {
                setModalAvatar(false);
                avatarRef.current.value = '';
              }}
            />
          </Box>
        ) : null}
      </Modal>

      <Modal
        verticalCenter
        showCloseIcon
        className="modal-cover"
        isOpen={modalCover}
        onClose={handleCloseModalCover}
        heading={t('p.pages.settings.profile.fields.cover.uploadImage')}
        size="profile"
      >
        <Box className="modal-cover__upload">
          <Text className="text-gray-400">
            {t('p.pages.settings.profile.fields.cover.placeholder')}
          </Text>
          <Button
            onClick={onClickUploadCover}
            disabled={
              onLoadingUploderCover ||
              coverResizeLoading ||
              updateCoverLoading ||
              uploadCoverLoading
            }
            loading={onLoadingUploderCover}
            className="modal-cover__upload__button"
          >
            {t('p.pages.settings.profile.fields.cover.chooseFile')}
          </Button>
          <input
            ref={coverRef}
            accept="image/jpeg, image/png, image/webp"
            className="invisible w-0"
            type="file"
            onChange={onUploadCover}
          />
        </Box>
        {coverRef?.current?.value && coverResult.fileUrl ? (
          <Box className="modal-avatar__cropper">
            <Cropper
              aspect={10 / 3}
              objectFit="cover"
              loading={coverResizeLoading || updateCoverLoading || uploadCoverLoading}
              image={coverResult.fileUrl}
              onComplete={async (croppedArea) => {
                setUploadCoverLoading(true);
                const resized = await onResizeCover({
                  key: coverResult.s3?.key,
                  folder: 'covers',
                  ...croppedArea
                });

                if (resized) {
                  await onRemoveCover();
                  await onUpdateCover(resized?.key);
                  await getCurrentUser();
                  setUploadCoverLoading(false);
                }
                coverRef.current.value = '';
                setUploadCoverLoading(false);
                setModalCover(false);
              }}
              onCancel={() => {
                setModalCover(false);
                coverRef.current.value = '';
              }}
            />
          </Box>
        ) : null}
      </Modal>

      {loading || !user ? (
        <Loading title="Please wait" description="Fetching data from server" />
      ) : (
        <>
          <Box className="profile-settings">
            <Box className="profile-settings__title">{t('p.pages.settings.profile.title')}</Box>
            <Box className="profile-settings__cover">
              <Box className="profile-settings__cover__title">
                {t('p.pages.settings.profile.cover.title')}
                <FormField>
                  <Box className="profile-settings__cover__image">
                    <CoverImage
                      src={
                        user?.media?.coverUrl ||
                        `https://cdn.patrons.art/public/covers/patrons-default-cover.png`
                      }
                      className="cover-field__image-preview"
                    />
                    <Box className="profile-settings__cover__image__button">
                      <Box className="profile-settings__cover__image__button__wrapper">
                        <Button
                          loading={updateCoverLoading}
                          disabled={removeCoverLoading}
                          onClick={handleOpenModalCover}
                          fullWhite
                        >
                          {t('p.pages.settings.profile.fields.cover.edit')}
                        </Button>
                        <Button
                          loading={removeCoverLoading}
                          disabled={updateCoverLoading}
                          onClick={handleRemoveCover}
                          fullWhite
                        >
                          {t('p.pages.settings.profile.fields.avatar.delete')}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </FormField>
              </Box>
            </Box>
            <Box className="profile-settings__grid">
              <Box className="profile-settings__grid__information">
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                  <Grid className="settings__grid-column-2">
                    <Col>
                      <FormField>
                        <Input
                          fullwidth
                          label={t('p.pages.settings.profile.fields.firstName.label')}
                          defaultValue={user?.firstName}
                          errorMessage={errors.firstName?.message}
                          {...restFirstName}
                          onChange={(e) => {
                            onChangeFirstName(e);
                            setFirstNameLength((e.target.value.length + 1).toString());
                          }}
                        />
                      </FormField>
                    </Col>
                    <Col>
                      <FormField>
                        <Input
                          fullwidth
                          label={t('p.pages.settings.profile.fields.surname.label')}
                          defaultValue={user?.lastName}
                          errorMessage={errors.lastName?.message}
                          {...restLastName}
                          onChange={(e) => {
                            setLastNameLength((e.target.value.length + 1).toString());
                            onChangeLastName(e);
                          }}
                        />
                      </FormField>
                    </Col>
                  </Grid>
                  <Grid column="1">
                    <Col>
                      <FormField>
                        <TextArea
                          fullWidth
                          label={t('p.pages.settings.profile.fields.bio.label')}
                          placeholder={t('p.pages.settings.profile.fields.bio.placeholder')}
                          defaultValue={user?.bio}
                          errorMessage={errors.bio?.message}
                          onChange={(e) => {
                            setBioLength((e.target.value.length + 1).toString());
                            onChangeBio(e);
                          }}
                          {...restBio}
                        />
                      </FormField>
                    </Col>
                  </Grid>
                  <Box className="profile-settings__grid__information__section">
                    {t('p.pages.settings.profile.section.billing')}
                  </Box>
                  <Grid column="2" className="settings__grid-column-2">
                    <Col className="settings__grid-column-2-address">
                      <FormField>
                        <TextArea
                          fullWidth
                          label={t('p.pages.settings.profile.fields.address.label')}
                          placeholder={t('p.pages.settings.profile.fields.address.placeholder')}
                          defaultValue={user?.address?.line1}
                          errorMessage={errors.address?.line1?.message}
                          {...register('address.line1')}
                        />
                      </FormField>
                    </Col>

                    <Col>
                      <FormField>
                        <Select
                          size="lg"
                          fullWidth
                          placeholderHidden
                          defaultValue={user?.address?.country}
                          options={countries}
                          {...register('address.country')}
                          label={t('p.pages.settings.profile.fields.country.label')}
                          placeholder={t('p.pages.settings.profile.fields.country.placeholder')}
                        />
                      </FormField>
                    </Col>
                    <Col>
                      <FormField>
                        <Input
                          fullWidth
                          label={t('p.pages.settings.profile.fields.state.label')}
                          errorMessage={errors?.address?.stateName?.message}
                          defaultValue={user?.address?.stateName}
                          {...register('address.stateName')}
                        />
                      </FormField>
                    </Col>
                    <Col>
                      <FormField>
                        <Input
                          fullWidth
                          label={t('p.pages.settings.profile.fields.city.label')}
                          errorMessage={errors.address?.city?.message}
                          defaultValue={user?.address?.city}
                          {...register('address.city')}
                        />
                      </FormField>
                    </Col>
                    <Col>
                      <FormField>
                        <Input
                          fullWidth
                          label={t('p.pages.settings.profile.fields.postcode.label')}
                          errorMessage={errors.postcode?.message}
                          defaultValue={user?.address?.postCode}
                          {...register('address.postCode')}
                        />
                      </FormField>
                    </Col>
                    <Col className="hidden">
                      <FormField>
                        <Input defaultValue={user?.address?.phone} {...register('address.phone')} />
                      </FormField>
                    </Col>
                    <Col className="hidden">
                      <FormField>
                        <Input
                          defaultValue={user?.address?.recipientName}
                          {...register('address.recipientName')}
                        />
                      </FormField>
                    </Col>
                  </Grid>

                  <Box className="profile-settings__grid__information__section">
                    {t('p.pages.settings.profile.section.media')}
                  </Box>

                  <Grid column="1">
                    <Col>
                      <FormField>
                        <Box className="social-field">
                          <Box className="social-field__input">
                            <Input
                              label={t('p.pages.settings.profile.fields.social.website.label')}
                              placeholder={t(
                                'p.pages.settings.profile.fields.social.website.placeholder'
                              )}
                              errorMessage={errors.links?.website?.message}
                              defaultValue={user.links?.website}
                              {...register('links.website', {
                                pattern: {
                                  value: URL_PATTERN,
                                  message: t('p.editProfile.websitePattern')
                                },
                                maxLength: {
                                  value: 2048,
                                  message: t('p.editProfile.websiteMaxLength')
                                }
                              })}
                            />
                          </Box>
                        </Box>
                        <Box className="social-field">
                          <Box className="social-field__input">
                            <Input
                              label={t('p.pages.settings.profile.fields.social.email.label')}
                              placeholder={t(
                                'p.pages.settings.profile.fields.social.email.placeholder'
                              )}
                              errorMessage={errors.links?.email?.message}
                              defaultValue={user.links?.email}
                              {...register('links.email', {
                                pattern: {
                                  value: EMAIL_PATTERN,
                                  message: t('p.editProfile.emailInvalid')
                                }
                              })}
                            />
                          </Box>
                        </Box>
                        <Box className="social-field">
                          <Box className="social-field__input">
                            <Input
                              label={t('p.pages.settings.profile.fields.social.facebook.label')}
                              placeholder={t(
                                'p.pages.settings.profile.fields.social.facebook.placeholder'
                              )}
                              defaultValue={user.links?.facebook}
                              errorMessage={errors.links?.facebook?.message}
                              {...register('links.facebook', {
                                pattern: {
                                  value: FACEBOOK_PATTERN,
                                  message: t('p.editProfile.facebookPattern')
                                },
                                maxLength: {
                                  value: 2048,
                                  message: t('p.editProfile.facebookMaxLength')
                                }
                              })}
                            />
                          </Box>
                        </Box>
                        <Box className="social-field">
                          <Box className="social-field__input">
                            <Input
                              label={t('p.pages.settings.profile.fields.social.instagram.label')}
                              placeholder={t(
                                'p.pages.settings.profile.fields.social.instagram.placeholder'
                              )}
                              errorMessage={errors.links?.instagram?.message}
                              defaultValue={user.links?.instagram}
                              {...register('links.instagram', {
                                pattern: {
                                  value: INSTAGRAM_PATTERN,
                                  message: t('p.editProfile.instagramPattern')
                                },
                                maxLength: {
                                  value: 2048,
                                  message: t('p.editProfile.instagramMaxLength')
                                }
                              })}
                            />
                          </Box>
                        </Box>
                        <Box className="social-field">
                          <Box className="social-field__input">
                            <Input
                              label={t('p.pages.settings.profile.fields.social.twitter.label')}
                              placeholder={t(
                                'p.pages.settings.profile.fields.social.twitter.placeholder'
                              )}
                              errorMessage={errors.links?.twitter?.message}
                              defaultValue={user.links?.twitter}
                              {...register('links.twitter', {
                                pattern: {
                                  value: TWITTER_PATTERN,
                                  message: t('p.editProfile.twitterPattern')
                                },
                                maxLength: {
                                  value: 2048,
                                  message: t('p.editProfile.twitterMaxLength')
                                }
                              })}
                            />
                          </Box>
                        </Box>
                      </FormField>
                    </Col>
                  </Grid>

                  <Grid column="1">
                    <Col>
                      <Box>
                        <Button
                          primary
                          as="submit"
                          disabled={updateLoading}
                          loading={updateLoading}
                        >
                          {t('p.editProfile.save')}
                        </Button>
                      </Box>
                    </Col>
                  </Grid>
                </form>
              </Box>
              <Box className="profile-settings__grid__profile">
                <Box className="profile-settings__grid__profile__text">
                  {t('p.pages.settings.profile.yourPhotos')}
                </Box>
                <Box className="profile-settings__grid__profile__avatar">
                  <Box className="profile-settings__grid__profile__avatar__left">
                    <Avatar
                      user={user}
                      size="sm"
                      preview={user?.media?.avatarUrl && croppedAvatar}
                    />
                  </Box>
                  <Box className="profile-settings__grid__profile__avatar__right">
                    <Box className="profile-settings__grid__profile__avatar__right__title">
                      {t('p.pages.settings.profile.fields.avatar.label')}
                    </Box>
                    <Box className="profile-settings__grid__profile__avatar__right__options">
                      {removeAvatarLoading ? (
                        <Box className="isLoading"></Box>
                      ) : (
                        <Box
                          onClick={handleRemoveAvatar}
                          className="profile-settings__grid__profile__avatar__right__options__delete"
                        >
                          {t('p.pages.settings.profile.fields.avatar.delete')}
                        </Box>
                      )}
                      <Box
                        onClick={handleOpenModalAvatar}
                        className="profile-settings__grid__profile__avatar__right__options__update"
                      >
                        {t('p.pages.settings.profile.fields.avatar.update')}
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {/* <Box className="profile-settings__grid__profile__information">
                  <Box className="profile-settings__grid__profile__information__wrapper">
                    <Box className="profile-settings__grid__profile__information__wrapper__icon">
                      <ImagePlus />
                    </Box>
                    {t('p.pages.settings.profile.fields.avatar.description')}
                  </Box>
                </Box> */}
              </Box>
            </Box>
          </Box>
        </>
      )}
      <Helmet>
        <title>{t('settings.profile.title')}</title>
        <meta property="og:image" content="https://cdn.fans.inc/public/webapp/og-image.png" />
      </Helmet>
    </Box>
  );
}

export default SettingsProfile;
