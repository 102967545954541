import { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Login, Box, Container, Heading, BitPayIFrame } from '@components';
import { useParams } from 'react-router-dom';
import { useAuth } from '@libs/contexts/auth';
import { useExchangeByID } from '@libs/hooks/exchange';
import { ReactComponent as LoginIcon } from '@assets/svg/login.svg';
import { ReactComponent as ErrorIcon } from '@assets/svg/error.svg';
import { ReactComponent as WarningIcon } from '@assets/svg/warning.svg';
import { ReactComponent as TimeIcon } from '@assets/svg/time.svg';
import useBitPayCheckout from '@libs/hooks/bitpay';

function BitpayPayment() {
  const { t } = useTranslation();
  const params = useParams();
  const { user, isAuthenticated } = useAuth();
  const { data: exchange } = useExchangeByID(params.exchange);
  const [showLogin, setShowLogin] = useState(false);
  const [warningLogin, setWarningLogin] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [waiting, setWaiting] = useState(true);
  const [redirectURL, setRedirectURL] = useState();
  const { onPayOffer } = useBitPayCheckout();
  const [warningChangeAccount, setWarningChangeAccount] = useState(false);
  const supportEmail = 'support@fans.inc';

  useEffect(() => {
    if (!user || !exchange) {
      return;
    }

    async function handleAcceptOffer(id) {
      const bitPayRes = await onPayOffer(id);
      if (!bitPayRes?.url) {
        // todo: handle error
        return;
      }
      setRedirectURL(bitPayRes?.url);
    }

    if (isAuthenticated) {
      setShowLogin(false);
      setWarningLogin(false);
      setWaiting(false);

      if (user && exchange && user?.id === exchange?.patronID && exchange?.isSold === 'FALSE') {
        if (
          exchange.paymentProvider === 'BITPAY' &&
          exchange.bitpayPaymentRequested === 'TRUE' &&
          new Date(exchange.paymentRequestExpiredAt) > new Date() &&
          exchange.currentOwner.id !== user?.id &&
          exchange?.parentExchange?.searchableIsSold === 'FALSE'
        ) {
          handleAcceptOffer(exchange?.id);
          setWaiting(true);
        } else {
          setInvalid(true);
          setWaiting(false);
        }
      } else {
        setWarningChangeAccount(true);
        setWarningLogin(false);
        setWaiting(false);
      }
    } else {
      setWarningLogin(true);
      setWarningChangeAccount(false);
      setShowLogin(false);
      setWaiting(false);
    }
  }, [user, exchange, isAuthenticated]); // eslint-disable-line
  return (
    <>
      {redirectURL && (
        <BitPayIFrame iframeURL={redirectURL} closeIframe={() => setRedirectURL(null)} />
      )}
      {warningLogin && (
        <Container className="bitpay-payment" size="lg">
          <LoginIcon className="bitpay-payment-login-icon" />
          <Heading as="h3" className="bitpay-payment-login-title">
            {t('bitpayPayment.login.title')}
          </Heading>
          <Box className="bitpay-payment-login-body">
            <Trans i18nKey="bitpayPayment.login.body">
              <span
                className="bitpay-payment-login-body-event"
                onClick={() => {
                  setShowLogin(true);
                  setWarningLogin(false);
                  setWarningChangeAccount(false);
                }}
              >
                {{ event: 'Click here' }}
              </span>
            </Trans>
          </Box>
        </Container>
      )}
      {showLogin && (
        <Box className="auth-section">
          <Box className="auth-container">
            <Box className="auth-box">
              <Login redirect={`/bitpay-payment/${params.exchange}`} />
            </Box>
          </Box>
        </Box>
      )}
      {invalid && (
        <Container className="bitpay-payment" size="lg">
          <WarningIcon className="bitpay-payment-login-icon" />
          <Heading as="h3" className="bitpay-payment-login-title">
            {t('bitpayPayment.invalid.title')}
          </Heading>
          <Box className="bitpay-payment-login-body">
            <Trans i18nKey="bitpayPayment.invalid.body">
              <a className="bitpay-payment-login-body-event" href={`mailto:${supportEmail}`}>
                {{ supportEmail }}
              </a>
            </Trans>
          </Box>
        </Container>
      )}
      {waiting && (
        <Container className="bitpay-payment" size="lg">
          <TimeIcon className="bitpay-payment-login-icon" />
          <Heading as="h3" className="bitpay-payment-login-title">
            {t('bitpayPayment.waiting.title')}
          </Heading>
          <Box className="bitpay-payment-login-body">{t('bitpayPayment.waiting.body')}</Box>
        </Container>
      )}
      {warningChangeAccount && (
        <Container className="bitpay-payment" size="lg">
          <ErrorIcon className="bitpay-payment-changeAccount-icon" />
          <Heading as="h3" className="bitpay-payment-login-title">
            {t('bitpayPayment.changeAccount.title')}
          </Heading>
          <Box className="bitpay-payment-changeAccount-body">
            <Trans i18nKey="bitpayPayment.changeAccount.body">
              <span
                className="bitpay-payment-changeAccount-body-event"
                onClick={() => {
                  setShowLogin(true);
                  setWarningLogin(false);
                  setWarningChangeAccount(false);
                }}
              >
                {{ event: 'Click here' }}
              </span>
            </Trans>
          </Box>
        </Container>
      )}
    </>
  );
}

export default BitpayPayment;
