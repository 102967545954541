import { Price } from '@components';
import { getDateValid2Month } from '@libs/utils/date';
import { CATEGORY_TYPES } from '@libs/utils/collectible';
import { Trans } from 'react-i18next';
import { getReleaseStatus, listingParser, listingTranslationParser } from '@libs/utils/release';

export const checkDigitalSupply = (collectible, releases) => {
  var released = 0;
  var total = collectible?.maxNumberedEditions + 1;

  for (var i = 0; i < releases.length; i++) {
    if (new Date().getTime() > new Date(releases[i].releaseDate).getTime()) {
      released++;
    }
  }
  return total + ' (' + released + ' released)';
};

export const collectibleTypeParser = {
  ARTWORK: 'Artwork',
  PHOTOGRAPH: 'Photograph',
  FILM_POSTER: 'Film poster',
  SONG: 'Song',
  SONG_LYRICS: 'Song lyrics',
  VIDEO: 'Video',
  VIP_PASS: 'VIP Pass',
  OTHER: 'Other'
};

const CollectibleGenre = {
  ROCK: 'Rock',
  CLASSIC_ROCK: 'Classic Rock',
  POP_ROCK: 'Pop-Rock',
  ALT_ROCK: 'Alt-Rock',
  POP: 'Pop',
  ALT_POP: 'Alt-Pop',
  URBAN: 'Urban',
  RAP: 'Rap',
  HIPHOP: 'Hip Hop',
  REGGAE: 'Reggae',
  DANCE: 'Dance',
  ALTERNATIVE: 'Alternative',
  COUNTRY: 'Country',
  BLUES: 'Blues',
  JAZZ: 'Jazz',
  ADULT_CONTEMPORARY: 'Adult Contemporary',
  NEW_AGE: 'New Age',
  SPIRITUAL: 'Spiritual',
  ELECTRONIC: 'Electronic',
  FOLK: 'Folk',
  PUNK_ROCK: 'Punk Rock',
  HEAVY_METAL: 'Heavy Metal',
  SOUL: 'Soul',
  EDM: 'EDM',
  OTHER: 'Other'
};

const CollectibleCategory = {
  ARTWORK: 'artwork',
  PHOTOGRAPH: 'photograph',
  SONG: 'song',
  SONG_LYRICS: 'song lyrics',
  VIDEO: 'video',
  OTHER: 'collectible'
};

const CollectibleGroupCategory = {
  ARTWORK: 'artworks',
  PHOTOGRAPH: 'photographs',
  SONG: 'songs',
  SONG_LYRICS: 'song lyrics',
  VIDEO: 'videos',
  OTHER: 'others'
};

export const categoryParser = (category, group = false) => {
  return group ? CollectibleGroupCategory[category] : CollectibleCategory[category];
};

export const singleGenreParser = (genre) => (genre ? CollectibleGenre[genre] : 'All');

export const eraParser = (era) => (era ? era : 'All');

export const typeParser = (category) => (category.length > 0 ? category : 'All');

export const sortParser = (sort) => sort;

export const genreParser = (genres) => {
  if (!genres || genres.length < 1) {
    return '-';
  }

  let genreStr = '';
  genres.forEach((genre) => {
    const genreText = CollectibleGenre[genre];
    if (genreText) {
      genreStr += `${genreText}, `;
    }
  });

  if (genreStr === '') {
    return '-';
  }

  // Removing the last comma and space from string
  const genreResult = genreStr.slice(0, -2);
  return genreResult;
};

export const getTierAttribute = (collectible, tier, channels) => {
  let medium = (
    <Trans i18nKey="collectible.expansionInformation.attributes.verifiedOriginal">
      {{ category: categoryParser(collectible?.category) }}
    </Trans>
  );

  let fanClubAccess = '';

  switch (tier?.tierType) {
    case 'NUMBERED_EDITION':
      const channelNumbered = channels?.filter((x) =>
        collectible?.perksNumbered?.fanClubAccess?.includes(x.id)
      );
      fanClubAccess =
        channelNumbered?.length > 0 ? (
          <div style={{ whiteSpace: 'normal' }}>
            {channelNumbered?.map((x) => x.name).join(', ')}
          </div>
        ) : (
          <Trans i18nKey="collectible.expansionInformation.attributes.noChannel" />
        );
      if (
        [CATEGORY_TYPES.ARTWORK, CATEGORY_TYPES.PHOTOGRAPH, CATEGORY_TYPES.SONG_LYRICS].includes(
          collectible?.category
        )
      ) {
        medium = (
          <Trans i18nKey="collectible.expansionInformation.attributes.verifiedOriginal">
            {{ category: categoryParser(collectible?.category) }}
            {{ print: ' + ' + 'Print' }}
          </Trans>
        );
      } else {
        medium = (
          <Trans i18nKey="collectible.expansionInformation.attributes.verifiedOriginal">
            {{ category: categoryParser(collectible?.category) }}
            {{ print: ' + ' + 'Cover Art Print' }}
          </Trans>
        );
      }
      break;

    case 'DIGITAL_ORIGINAL':
      const channelOriginal = channels?.filter((x) =>
        collectible?.perksOriginal?.fanClubAccess?.includes(x.id)
      );
      fanClubAccess =
        channelOriginal?.length > 0 ? (
          <div style={{ whiteSpace: 'normal' }}>
            {channelOriginal?.map((x) => x.name).join(', ')}
          </div>
        ) : (
          <Trans i18nKey="collectible.expansionInformation.attributes.noChannel" />
        );
      if (
        [CATEGORY_TYPES.ARTWORK, CATEGORY_TYPES.PHOTOGRAPH, CATEGORY_TYPES.SONG_LYRICS].includes(
          collectible?.category
        )
      ) {
        medium = (
          <Trans i18nKey="collectible.expansionInformation.attributes.verifiedOriginal">
            {{ category: categoryParser(collectible?.category) }}
            {{ print: ' + ' + 'Framed Print' }}
          </Trans>
        );
      } else {
        medium = (
          <Trans i18nKey="collectible.expansionInformation.attributes.verifiedOriginal">
            {{ category: categoryParser(collectible?.category) }}
            {{ print: ' + ' + 'Cover Art Print' }}
          </Trans>
        );
      }

      break;

    default:
      break;
  }

  let releaseStatus = <Trans i18nKey={`collectible.releaseStatus.${getReleaseStatus(tier)}`} />;
  const mediumLabel = <Trans i18nKey="collectible.expansionInformation.attributes.medium" />;
  const releaseStatusLabel = (
    <Trans i18nKey="collectible.expansionInformation.attributes.releaseStatus" />
  );
  const releaseDateLabel = (
    <Trans i18nKey="collectible.expansionInformation.attributes.releaseDate" />
  );
  const listingTypeLabel = (
    <Trans i18nKey="collectible.expansionInformation.attributes.listingType" />
  );
  const priceLabel = <Trans i18nKey="collectible.expansionInformation.attributes.price" />;
  const fanClubLabel = (
    <Trans i18nKey="collectible.expansionInformation.attributes.fanClubAccess" />
  );

  return [
    [mediumLabel, medium],
    [releaseStatusLabel, releaseStatus],
    [releaseDateLabel, getDateValid2Month(tier?.releaseDate, 'TBA')],
    [listingTypeLabel, listingParser(tier?.listingType)],
    [
      priceLabel,
      tier?.listingType === 'AUCTION' || tier?.listingType === 'MAKE_AN_OFFER' ? (
        <Trans
          i18nKey={`collectible.expansionInformation.attributes.price${listingTranslationParser(
            tier?.listingType
          )}`}
        />
      ) : (
        <Price amount={parseFloat(tier?.startingPrice)} inline />
      )
    ],
    [fanClubLabel, fanClubAccess]
  ];
};
