import FansincLoading from './fansinc';
import PatronsLoading from './patrons';

const Loading = ({ title, description }) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsLoading title={title} description={description} />;
  } else {
    return <FansincLoading title={title} description={description} />;
  }
};

Loading.defaultProps = {
  title: 'Loading'
};

export default Loading;
