import { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@libs/contexts/auth';
import gql from '@libs/utils/gql';
import { likeCollectible } from '@graphql/mutations';
import { collectibleLikeByUserAndCollectible } from '@libs/custom-queries/collectible-like';
import useToast from '@libs/utils/toast';

export const useCollectibleLike = (collectibleID, autoFetch = true) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const { user, isAuthenticated } = useAuth();
  const [isLiked, setIsLiked] = useState(false);

  useEffect(() => {
    async function getData() {
      setLoading(true);
      if (!user) {
        return;
      }

      if (!collectibleID) {
        return;
      }

      try {
        const params = {
          userID: user.id,
          collectibleID: {
            eq: collectibleID
          }
        };

        const { data: result } = await gql(collectibleLikeByUserAndCollectible, params);
        setIsLiked(result?.collectibleLikeByUserAndCollectible?.items?.length > 0 ? true : false);
      } catch (error) {
        const errorMessage = error?.errors[0]?.message;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    if (isAuthenticated && autoFetch) {
      getData();
    }
  }, [user, collectibleID, isAuthenticated, autoFetch]); // eslint-disable-line

  const onLike = useCallback(
    async (payload) => {
      if (!isAuthenticated) {
        toast(t('components.collectibleLike.error.unauthorized'), 'error');
        return;
      }

      setLoading(true);

      try {
        setIsLiked(!isLiked);

        await gql(
          likeCollectible,
          {
            input: {
              collectibleID: payload.collectibleID,
              onVisit: payload.onVisit
            }
          },
          {
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          }
        );
      } catch (error) {
        const errorMessage = error?.errors[0]?.message;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [toast]
  );

  return {
    isLiked,
    loading,
    onLike
  };
};

export const useUserCollectibleLike = (userID) => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [pageToken, setPageToken] = useState();
  const [total, setTotal] = useState(0);

  const getData = async (userID, nextToken, reloadData) => {
    setLoading(true);
    if (!userID) {
      return;
    }
    try {
      const params = {
        userID,
        sortDirection: 'DESC'
      };

      if (nextToken) {
        params.nextToken = nextToken;
      }
      params.limit = 6;
      const { data: res } = await gql(collectibleLikeByUserAndCollectible, params);

      if (res?.collectibleLikeByUserAndCollectible?.items) {
        if (reloadData) {
          setData(res.collectibleLikeByUserAndCollectible.items);
          setPageToken(res.collectibleLikeByUserAndCollectible.nextToken);
        } else {
          setData(data.concat(res.collectibleLikeByUserAndCollectible.items));
          setPageToken(res.collectibleLikeByUserAndCollectible.nextToken);
        }
        setTotal(total + res?.collectibleLikeByUserAndCollectible?.items?.length);
      } else {
        setPageToken(null);
      }
      setLoading(false);
    } catch (error) {
      const errorMessage = error?.errors[0]?.message;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getData(userID);
  }, [userID]); // eslint-disable-line

  return {
    loading,
    data,
    pageToken,
    total,
    getData
  };
};

export const useUserAllCollectibleLike = (userID) => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [pageToken, setPageToken] = useState();
  const [total, setTotal] = useState(0);

  const getData = async (userID, nextToken) => {
    setLoading(true);
    if (!userID) {
      return;
    }
    try {
      const params = {
        userID,
        sortDirection: 'DESC'
      };

      if (nextToken) {
        params.nextToken = nextToken;
      }
      const { data: res } = await gql(collectibleLikeByUserAndCollectible, params);

      if (res?.collectibleLikeByUserAndCollectible?.items) {
        setData(data.concat(res.collectibleLikeByUserAndCollectible.items));
        setPageToken(res.collectibleLikeByUserAndCollectible.nextToken);
        setTotal(total + res?.collectibleLikeByUserAndCollectible?.items?.length);
      } else {
        setPageToken(null);
      }
    } catch (error) {
      const errorMessage = error?.errors[0]?.message;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (userID) {
      do {
        getData(userID);
      } while (pageToken);
    }
  }, [userID, pageToken]); // eslint-disable-line

  return {
    loading,
    data,
    total,
    getData
  };
};
