import { Box, SkeletonLoading } from '@components';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import Slider from 'react-slick';
import { useCollectibleByCollection } from '@libs/hooks/collectible';
import CollectibleCard from './collectibleCard';
import { ReactComponent as ArrowLeftIcon } from '@assets/svg/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '@assets/svg/arrow-right.svg';

function CollectibleSlider({ gallery, colsActive }) {
  const { t } = useTranslation();
  const slideFeaturedRef = useRef();
  const slideFeaturedSettings = {
    dots: true,
    focusOnSelect: false,
    arrows: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    customPaging: function (i) {
      return <div></div>;
    },
    dotsClass: 'slick-custom-pagging',
    className: 'collectible-slider-test',
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }
    ]
  };
  const RenderArrows = ({ refVal }) => {
    return (
      <div className="slider-arrow">
        <Box className="arrow-btn slide-arrow-prev" onClick={() => refVal.current.slickPrev()}>
          <Box className="slide-arrow-prev-button">
            <ArrowLeftIcon />
          </Box>
        </Box>
        <Box className="arrow-btn slide-arrow-next" onClick={() => refVal.current.slickNext()}>
          <Box className="slide-arrow-next-button">
            <ArrowRightIcon />
          </Box>
        </Box>
      </div>
    );
  };
  const { data, loading } = useCollectibleByCollection(gallery?.id);
  return (
    <Box className="gallery-card-slider">
      {data?.length > 0 ? (
        <>
          {data?.length > 4 ? (
            <>
              <RenderArrows refVal={slideFeaturedRef} />
              <Box className="gallery-card-slider__slider">
                <Slider {...slideFeaturedSettings} ref={slideFeaturedRef}>
                  {data &&
                    data.map((collectible) => (
                      <CollectibleCard key={collectible?.id} collectible={collectible} />
                    ))}
                </Slider>
              </Box>
              <Box className="gallery-card-slider__left"></Box>
              <Box className="gallery-card-slider__right"></Box>
            </>
          ) : (
            <>
              <Box className="gallery-card-slider__slider flex">
                {data &&
                  data.map((collectible) => (
                    <CollectibleCard key={collectible?.id} collectible={collectible} />
                  ))}
              </Box>
            </>
          )}
        </>
      ) : (
        <>
          {loading ? (
            <>
              <SkeletonLoading className="mr-5" style={{ height: '110px' }} />
            </>
          ) : (
            <Box className="gallery-card__collectible__empty">
              <Box>
                <Box className="gallery-card__collectible__empty__title">
                  {t('p.pages.profile.museum.empty.title')}
                </Box>
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );
}

export default CollectibleSlider;
