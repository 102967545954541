import { add, format } from 'date-fns';

export const getDateValid2Month = (date, comingSoonText, formatDate = 'd MMMM, yyyy') => {
  const nextTwoMonths = format(add(new Date(), { months: 2 }), 'T');
  const dateSeconds = new Date(date).getTime();

  if (nextTwoMonths < dateSeconds) {
    return comingSoonText;
  } else {
    return format(new Date(date), formatDate);
  }
};

export const dateFormater = (date, formatDate = 'd MMMM yyyy') => {
  return format(new Date(date), formatDate);
};
