import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { SkeletonLoading } from '@components';
import { useConfirmationQuery } from '@components/confirmation-modal/hooks';
import {
  useCollectibleOwnership,
  useSellHistory,
  useTransactionHistory
} from '@libs/hooks/exchange';
import { EXCHANGE_TYPES, LISTING_TYPES } from '@libs/utils/exchange';
import { useAuth } from '@libs/contexts/auth';
import { useCollectibleDetail } from '@libs/hooks/collectible';
import { genreParser, collectibleTypeParser } from '@pages/collectible/fansinc/utils';

export const useExchangesForUnitPage = ({ slug, serialIndex }) => {
  const { user } = useAuth();
  const [currentExchange, setCurrentExchange] = useState(null);
  const [isLoadingCurrentExchange, setIsLoadingCurrentExchange] = useState(true);
  const now = new Date().toISOString();

  const {
    data: unitExchanges,
    loading: isLoadingUnitExchanges,
    clearData: clearUnitExchanges
  } = useSellHistory({
    slug,
    exchangeType: EXCHANGE_TYPES.LISTING,
    serialIndex: serialIndex
  });

  const {
    data: exchangeInProcess,
    loading: isLoadingExchangeInProcess,
    clearData: clearExchangeInProcess
  } = useSellHistory({
    slug,
    exchangeType: EXCHANGE_TYPES.LISTING_IN_PROCESS,
    serialIndex: serialIndex
  });

  const { data: exchangesOwnedByUser } = useCollectibleOwnership({
    collectibleID: currentExchange?.collectibleId,
    userID: user?.id
  });

  const { data: bidExchanges, loading: isLoadingBidExchanges } = useTransactionHistory({
    parentExchangeID: currentExchange?.id,
    expiryDate: now,
    exchangeType: EXCHANGE_TYPES.BID
  });

  const {
    data: offerExchanges,
    loading: isLoadingOfferExchanges,
    refetch: refetchOffer
  } = useTransactionHistory({
    parentExchangeID: currentExchange?.id,
    expiryDate: now,
    exchangeType: EXCHANGE_TYPES.OFFER
  });

  useEffect(() => {
    if (
      !isLoadingExchangeInProcess &&
      exchangeInProcess?.length &&
      exchangeInProcess[0].isSold === 'FALSE'
    ) {
      setCurrentExchange(exchangeInProcess[0]);
    } else if (!isLoadingUnitExchanges) {
      const exchangeForSale = unitExchanges.find((exchange) => exchange.isSold === 'FALSE');
      if (exchangeForSale != null) {
        setCurrentExchange(exchangeForSale);
      }
    }
  }, [unitExchanges, isLoadingUnitExchanges, exchangeInProcess, isLoadingExchangeInProcess]);

  useEffect(() => {
    if (!isLoadingUnitExchanges && !isLoadingExchangeInProcess) {
      setIsLoadingCurrentExchange(false);
    }
  }, [isLoadingUnitExchanges, isLoadingExchangeInProcess]);

  const clearData = () => {
    setCurrentExchange(null);
    clearUnitExchanges();
    clearExchangeInProcess();
  };

  return {
    currentExchange,
    isLoadingCurrentExchange,
    unitExchanges,
    isAnExchangeOwnedByUser: user && exchangesOwnedByUser?.length > 0,
    clearData,
    bidExchanges: bidExchanges.sort((a, b) => {
      if (a.bidPrice === b.bidPrice) {
        if (a.status === 'ACTIVE' || b.status === 'ACTIVE') {
          return a.status === 'ACTIVE' ? -1 : 1;
        } else {
          return parseFloat(a.maxBidPrice) < parseFloat(b.maxBidPrice) ? -1 : 1;
        }
      } else {
        return parseFloat(a.bidPrice) > parseFloat(b.bidPrice) ? -1 : 1;
      }
    }),
    isLoadingBidExchanges,
    offerExchanges,
    refetchOffer,
    isLoadingOfferExchanges
  };
};

export const useRedirect = ({ exchange, isLoadingExchange }) => {
  const history = useHistory();
  const { exchangeID } = useConfirmationQuery();

  useEffect(() => {
    if (typeof exchange?.collectible?.creator === 'undefined' && !isLoadingExchange) {
      history.push('/404');
    }
  }, [exchange, history]);

  const isPrimaryMarket =
    !isLoadingExchange && exchange != null
      ? exchange?.currentOwnerID === exchange?.creatorID
      : false;

  if (isPrimaryMarket && exchange?.listingType !== LISTING_TYPES.AUCTION && !exchangeID) {
    const collectibleGalleryUrl =
      exchange?.collectible?.creator?.username && exchange?.collectible?.slug ? (
        `/${exchange?.collectible?.creator?.username}/${exchange?.collectible?.slug}`
      ) : (
        <SkeletonLoading className="w-1/2 h-4" />
      );
    if (collectibleGalleryUrl) {
      history.push(collectibleGalleryUrl);
    } else {
      history.push('/404');
    }
  }
};

export const useCollectibleAttribute = (collectible, channels, serialIndex) => {
  const { t } = useTranslation();
  const { data: collectibleSupply, loading: collectibleSupplyLoading } = useCollectibleDetail(
    collectible?.id
  );
  const releaseChannel =
    serialIndex === 1
      ? channels?.filter((x) => collectible?.perksOriginal?.fanClubAccess?.includes(x.id))
      : channels?.filter((x) => collectible?.perksNumbered?.fanClubAccess?.includes(x.id));
  const fanClubAccess =
    releaseChannel?.length > 0 ? (
      <div style={{ whiteSpace: 'normal' }}>{releaseChannel?.map((x) => x.name).join(', ')}</div>
    ) : (
      t('collectible.attributes.noChannel')
    );
  const collectibleAttribute = [
    [t('collectible.attributes.title'), collectible?.title],
    [
      t('collectible.attributes.creator'),
      collectible?.creator?.displayName ||
        `${collectible?.creator?.firstName} ${collectible?.creator?.lastName}`
    ],
    [t('collectible.attributes.collectibleType'), collectibleTypeParser[collectible?.category]],
    [t('collectible.attributes.yearOfCreation'), `${collectible?.yearOfCreation || '-'}`],
    [t('collectible.attributes.relevantGenres'), genreParser(collectible?.genre)],
    [
      t('collectible.attributes.maxQuantity'),
      collectibleSupplyLoading ? (
        <SkeletonLoading />
      ) : (
        collectibleSupply?.totalSupply + ' (' + collectibleSupply?.totalReleased + ' released)'
      )
    ],
    [t('collectible.attributes.fanClubAccess'), fanClubAccess]
  ];

  return collectibleAttribute;
};
