import { useTranslation } from 'react-i18next';
import { Container, Box, Button, Link } from '@components';

import MintImage1 from '@assets/img/mint-it/mint-1.png';
import MintImage2 from '@assets/img/mint-it/mint-2.png';
import MintImage3 from '@assets/img/mint-it/mint-3.png';

const LandingSell = () => {
  const { t } = useTranslation();
  return (
    <Container className="mint-landing">
      <Box className="mint-landing__top">
        <Box className="mint-landing__wrapper">
          <Box className="mint-landing__top__title">{t('p.mint.landing.title')}</Box>
          <Box className="mint-landing__top__description">{t('p.mint.landing.description')}</Box>
        </Box>
      </Box>
      <Box className="flex items-center justify-center">
        <Box className="mint-landing__section__wrapper">
          <Box className="mint-landing__section">
            <Box className="mint-landing__section__left">
              <img src={MintImage1} alt="sell it" />
            </Box>
            <Box className="mint-landing__section__right">
              <Box className="mint-landing__section__right__title">
                {t('p.mint.landing.nft.title')}
              </Box>
              <Box className="mint-landing__section__right__description">
                {t('p.mint.landing.nft.description')}
              </Box>
            </Box>
          </Box>
          <Box className="mint-landing__section">
            <Box className="mint-landing__section__middle__left">
              <Box className="mint-landing__section__right__title">
                {t('p.mint.landing.verified.title')}
              </Box>
              <Box className="mint-landing__section__right__description">
                {t('p.mint.landing.verified.description')}
              </Box>
            </Box>
            <Box className="mint-landing__section__middle__right">
              <img src={MintImage2} alt="sell it" />
            </Box>
          </Box>
          <Box className="mint-landing__section">
            <Box className="mint-landing__section__left">
              <img src={MintImage3} alt="sell it" />
            </Box>
            <Box className="mint-landing__section__right">
              <Box className="mint-landing__section__right__title">
                {t('p.mint.landing.sell.title')}
              </Box>
              <Box className="mint-landing__section__right__description">
                {t('p.mint.landing.sell.description')}
              </Box>
            </Box>
          </Box>
          <Box className="mint-landing__section__bottom">
            <Box className="mint-landing__section__bottom__left">
              <Box className="mint-landing__section__bottom__left__title">
                {t('p.mint.landing.bottom.title')}
              </Box>
              <Box className="mint-landing__section__bottom__left__description">
                {t('p.mint.landing.bottom.description')}
              </Box>
            </Box>
            <Link to="/marketplace">
              <Box className="mint-landing__section__bottom__right">
                <Button>{t('p.mint.landing.bottom.button')}</Button>
              </Box>
            </Link>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default LandingSell;
