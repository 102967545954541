import React from 'react';
import PropTypes from 'prop-types';

import { Box, Button, Link } from '@components';
import { getShareChannel } from '@libs/utils/share';

import './style.css';

function OnClickWrapper({ pathname, onClick, children, onFrame }) {
  return onClick ? (
    <div onClick={onClick}>{children}</div>
  ) : onFrame ? (
    <a href={pathname}>{children}</a>
  ) : (
    <Link target="_blank" to={pathname}>
      {children}
    </Link>
  );
}

function ShareIcon({ channel, onClick, url, onFrame, style, onClickParent }) {
  const {
    pathname,
    onClick: onClickOverride,
    icon
  } = getShareChannel(channel, url ?? window.location.href);
  return (
    <Box className="share-icon" style={style}>
      <OnClickWrapper onClick={onClickOverride ?? onClick} onFrame={onFrame} pathname={pathname}>
        <Button whiteOutline>{icon}</Button>
      </OnClickWrapper>
    </Box>
  );
}

ShareIcon.propTypes = {
  channel: PropTypes.string.isRequired,
  url: PropTypes.string,
  onClick: PropTypes.func
};

ShareIcon.defaultProps = {
  channel: 'facebook',
  url: window.location.href,
  onClick: null
};

export default ShareIcon;
