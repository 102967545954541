import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Text, Modal } from '@components';
import { useTranslation } from 'react-i18next';

import { connectWallet } from '@components/connect-wallet-button/fansinc/utils';
import {
  useWalletConnected,
  useWalletChanged,
  useSyncWalletAddress
} from '@components/connect-wallet-button/fansinc/hooks';
import { BiWalletAlt } from 'react-icons/bi';

function ConnectWalletButton({
  walletAddress,
  setWalletAddress,
  setHasError,
  inUserMenu = false,
  disableSubmit = false
}) {
  const [status, setStatus] = useState('');
  const setStatusCallback = useCallback(setStatus, [setStatus]);
  const [showSandboxModal, setShowSandboxModal] = useState(false);
  useWalletConnected({
    setWalletAddress,
    setStatus: setStatusCallback,
    className: `connect-wallet-button-mint${
      inUserMenu ? ' connect-wallet-button-mint__user-menu' : ''
    }`
  });
  useWalletChanged({ setWalletAddress, setStatus: setStatusCallback });
  const { isLoading: isSyncingWalletAddress, error: syncingWalletAddressError } =
    useSyncWalletAddress({ walletAddress, setHasError });
  const { t } = useTranslation();

  const connectWalletPressed = async () => {
    if (disableSubmit) {
      setShowSandboxModal(true);
      return;
    }
    const walletResponse = await connectWallet(
      t,
      `connect-wallet-button-mint${inUserMenu ? ' connect-wallet-button-mint__user-menu' : ''}`
    );
    setStatus(walletResponse.status);
    setWalletAddress(walletResponse.address);
  };

  if (syncingWalletAddressError) {
    return (
      <Box className="connect-wallet-button">
        <Box className="connect-wallet-button__sync-error">
          <Text>
            {syncingWalletAddressError}
            <br />
            {t('components.connectWalletButton.error.reconnectWallet')}
          </Text>
        </Box>
      </Box>
    );
  }

  const isWalletConnected = walletAddress !== '';

  return (
    <Box className="connect-wallet-button">
      {!syncingWalletAddressError && !isWalletConnected && (
        <Button
          id="walletButton"
          onClick={connectWalletPressed}
          loading={isSyncingWalletAddress}
          whiteOutline
        >
          <Text as="span">
            <BiWalletAlt /> {t('components.connectWalletButton.button.connectWallet')}
          </Text>
        </Button>
      )}
      <Box className="connect-wallet-button__status">
        <Text>{status}</Text>
      </Box>
      <Modal
        heading={t(['components.connectWalletButton.sandbox.title'])}
        isOpen={showSandboxModal}
        showCloseIcon
        size="md"
        onClose={() => setShowSandboxModal()}
      >
        <Box>{t('components.connectWalletButton.sandbox.description')}</Box>
        <Box className="post-modal-edit-button">
          <Button onClick={() => setShowSandboxModal(false)} outline>
            {t('components.connectWalletButton.sandbox.cancel')}
          </Button>
        </Box>
      </Modal>
    </Box>
  );
}

ConnectWalletButton.propTypes = {
  walletAddress: PropTypes.string.isRequired,
  setWalletAddress: PropTypes.func.isRequired
};

export default ConnectWalletButton;
