import { useTranslation, Trans } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
import { useCollectibleLike } from '@libs/hooks/collectible-like';
import { useForm } from 'react-hook-form';
import useToast from '@libs/utils/toast';
import {
  Box,
  CollectibleCardV2,
  NotFound,
  Link,
  FormField,
  Text,
  Input,
  Button
} from '@components';
import Marquee from './marquee';
import { useCollectibleById } from '@libs/hooks/collectible';
import { useAuth } from '@libs/contexts/auth';
import { useCollectCollectible } from '@libs/hooks/user';

function Confirmation() {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    user,
    isAuthenticated,
    collectibleLikes,
    collectibleLikesLoading,
    updateCollectibleLikes
  } = useAuth();
  const toast = useToast();
  const { onCreateUser, createLoading } = useCollectCollectible();
  const { id } = useParams();
  localStorage.setItem('collectibleCollected', id);
  const [firstNameLength, setFirstNameLength] = useState('0');
  const [lastNameLength, setLastNameLength] = useState('0');
  const [collectibleCollected, setCollectibleCollected] = useState(false);
  const { data: collectible } = useCollectibleById(id);
  const { onLike } = useCollectibleLike(collectible?.id, false);
  useEffect(async () => {
    if (isAuthenticated && !collectibleLikesLoading && collectible?.id && !collectibleCollected) {
      if (!collectibleLikes?.includes(collectible?.id)) {
        setCollectibleCollected(true);
        await onLike({
          collectibleID: collectible?.id,
          onVisit: 'TRUE'
        });
        updateCollectibleLikes(collectibleLikes.concat(collectible?.id));
        toast('Successfully collecting free edition collectible', 'success');
        history.replace(`/${user?.username}/?default=favourite`);
        localStorage.removeItem('collectibleCollected');
      } else {
        toast('Free edition collectible already collected', 'success');
        history.replace(`/${user?.username}/?default=favourite`);
        localStorage.removeItem('collectibleCollected');
      }
    }
  }, [
    isAuthenticated,
    collectibleLikes,
    collectibleLikesLoading,
    collectible,
    collectibleCollected
  ]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    resetField
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur'
  });

  const password = useRef({});
  password.current = watch('password', '');

  const onSubmit = async (data) => {
    await onCreateUser({
      firstName: data.firstName,
      surName: data.lastName,
      email: data.email,
      password: data.confirm_password,
      collectibleID: collectible?.id
    });
    resetField('password');
    resetField('confirm_password');
  };

  return (
    <div>
      <Box className="w-full flex flex-col md:flex-row">
        <Box className="text-primary w-full md:w-1/2">
          {isAuthenticated ? (
            <Box className="w-full h-full flex justify-center items-center">
              <Box
                style={{
                  paddingTop: '60px',
                  paddingBottom: '60px'
                }}
                className="max-w-lg py-5 px-10"
              >
                <Box
                  as="h3"
                  style={{ lineHeight: '150%' }}
                  className="text-primary text-2xl md:text-4xl font-bold mb-4"
                >
                  {t('p.pages.collectCollectible.loading.title')}
                </Box>
                <Box className="text-base md:text-lg leading-7 mb-7">
                  {t('p.pages.collectCollectible.loading.description')}
                </Box>
              </Box>
            </Box>
          ) : (
            <Box className="w-full flex justify-center items-center">
              <Box
                style={{
                  paddingTop: '60px',
                  paddingBottom: '60px'
                }}
                className="max-w-lg py-5 px-10"
              >
                <Box className="block md:hidden mt-10">
                  {collectible?.id && (
                    <CollectibleCardV2
                      collectible={collectible}
                      type="collectible"
                      showButton={false}
                      showPrice={false}
                      hasCollectibleTimer={false}
                    ></CollectibleCardV2>
                  )}
                </Box>
                <Box
                  as="h3"
                  style={{ lineHeight: '150%' }}
                  className="text-primary text-2xl md:text-4xl font-bold mb-4"
                >
                  {t('p.pages.collectCollectible.title')}
                </Box>
                <Box className="text-base md:text-lg leading-7 mb-7">
                  {t('p.pages.collectCollectible.description')}
                </Box>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                  <Box className="auth__form__flex">
                    <FormField>
                      <Input
                        fullWidth
                        label={t('auth.fields.firstName.label')}
                        placeholder={t('auth.fields.firstName.label')}
                        errorMessage={errors.firstName?.message}
                        {...register('firstName', {
                          required: { value: true, message: t('auth.fields.firstName.required') },
                          pattern: {
                            value: /^[a-z ,.'-]+$/i,
                            message: t('auth.fields.firstName.invalid')
                          },
                          minLength: { value: 1, message: t('auth.fields.firstName.minLength') },
                          maxLength: {
                            value: 50,
                            message: t('auth.fields.firstName.maxLength', {
                              length: firstNameLength
                            })
                          }
                        })}
                        onChange={(e) => {
                          setFirstNameLength(e.target.value.length.toString());
                        }}
                      />
                    </FormField>
                    <FormField>
                      <Input
                        fullWidth
                        label={t('auth.fields.lastName.label')}
                        placeholder={t('auth.fields.lastName.label')}
                        errorMessage={errors.lastName?.message}
                        {...register('lastName', {
                          required: { value: true, message: t('auth.fields.lastName.required') },
                          pattern: {
                            value: /^[a-z ,.'-]+$/i,
                            message: t('auth.fields.lastName.invalid')
                          },
                          minLength: { value: 1, message: t('auth.fields.lastName.minLength') },
                          maxLength: {
                            value: 50,
                            message: t('auth.fields.lastName.maxLength', { length: lastNameLength })
                          }
                        })}
                        onChange={(e) => {
                          setLastNameLength(e.target.value.length.toString());
                        }}
                      />
                    </FormField>
                  </Box>
                  <FormField>
                    <Input
                      fullWidth
                      label={t('auth.fields.email.label')}
                      placeholder={t('auth.fields.email.label')}
                      as="email"
                      errorMessage={errors.email?.message}
                      {...register('email', {
                        required: { value: true, message: t('auth.fields.email.required') },
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: t('auth.fields.email.invalid')
                        }
                      })}
                    />
                  </FormField>
                  <FormField>
                    <Input
                      fullWidth
                      label={t('auth.fields.password.label')}
                      placeholder={t('auth.fields.password.label')}
                      as="password"
                      errorMessage={errors.password?.message}
                      {...register('password', {
                        required: { value: true, message: t('auth.fields.password.required') },
                        pattern: {
                          value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                          message: t('auth.fields.password.strength')
                        },
                        minLength: { value: 8, message: t('auth.fields.password.minLength') },
                        maxLength: { value: 128, message: t('auth.fields.password.maxLength') }
                      })}
                    />
                    <Text className="auth__form__password-requirement">
                      {t('auth.fields.password.requirements')}
                    </Text>
                  </FormField>
                  <FormField>
                    <Input
                      fullWidth
                      label={t('auth.fields.confirmPassword.label')}
                      placeholder={t('auth.fields.confirmPassword.label')}
                      as="password"
                      errorMessage={errors.confirm_password?.message}
                      {...register('confirm_password', {
                        required: {
                          value: true,
                          message: t('auth.fields.confirmPassword.required')
                        },
                        validate: (value) =>
                          value === password.current || t('auth.fields.confirmPassword.match')
                      })}
                    />
                  </FormField>
                  <Box className="mb-2">
                    <Button
                      loading={createLoading}
                      id="signup-button"
                      as="submit"
                      primary
                      fullWidth
                    >
                      <span style={{ textTransform: 'initial' }}>
                        {t('p.pages.collectCollectible.button')}
                      </span>
                    </Button>
                  </Box>
                  <div className="auth__register">
                    <Text center className="auth__forgot__backLogin">
                      <Trans i18nKey="p.auth.register.singin">
                        <Link className="auth__forgot__backLogin__link" to="/login"></Link>
                      </Trans>
                    </Text>
                  </div>
                </form>
              </Box>
            </Box>
          )}
        </Box>
        <Box
          style={{
            minHeight: 'calc(100vh - 200px)'
          }}
          className="text-primary w-full md:w-1/2 bg-gray-200 relative overflow-hidden hidden md:block"
        >
          <div className="absolute w-full -top-12">
            <Marquee title="collect now" />
          </div>
          <Box className="flex justify-center items-center h-full">
            <Box className="w-full px-10 md:px-0 py-10 md:py-0 md:w-1/2 z-50">
              <Box className="relative">
                {collectible?.id && (
                  <CollectibleCardV2
                    collectible={collectible}
                    type="collectible"
                    showButton={false}
                    showPrice={false}
                    hasCollectibleTimer={false}
                  ></CollectibleCardV2>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default Confirmation;
