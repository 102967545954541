import { userPublicFragment } from '@libs/fragments/user';

export const searchHistoryOrderPrints = /* GraphQL */ `
  query SearchOrderPrints(
    $filter: SearchableOrderPrintFilterInput
    $sort: SearchableOrderPrintSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchOrderPrints(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        shippingProduct {
          sku
          copies
          size
          frame
          # attributes -> This field currently not used in order print
          assets
        }
        exchangeID
        userID
        prodigiOrderID
        totalPrice
        createdAt
        paymentStatus
        orderStatus
        exchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectible {
            id
            accountID
            userID
            type
            category
            genre
            title
            slug
            description
            insideStory
            assetUrl
            mediaThumbnail
            marketingText
            whatsIncludedOriginal
            whatsIncludedNumbered
            featured
            featuredInProfile
            status
            yearOfCreation
            streamExternal
            streamUrl {
              spotify
              appleMusic
              youtube
            }
            releasedAt
            createdAt
            updatedAt
            maxNumberedEditions
            metaDescription
            searchableType
            searchableFeatured
            searchablefeaturedInProfile
            searchableCategory
            searchableStatus
            searchableLowestPrice
            searchableHighestPrice
            searchableHighestBidPrice
            searchableGenre
            owner
            creator {
              ...UserPublicProfile
              address {
                recipientName
                line1
                country
                stateName
                city
                postCode
                phone
              }
              walletAddresses
            }
          }
          collectibleReleaseIndex
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          owner
        }
        owner
        user {
          ...UserPublicProfile
          address {
            recipientName
            line1
            country
            stateName
            city
            postCode
            phone
          }
          walletAddresses
          createdAt
        }
      }
      nextToken
      total
    }
  }

  ${userPublicFragment}
`;
