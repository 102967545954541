import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DigitalOriginalTier } from '@assets/tier/digital_original.svg';
import { ReactComponent as DigitalOriginalTierSmall } from '@assets/tier/digital_original_small.svg';
import { ReactComponent as NumberedTier } from '@assets/tier/numbered_edition.svg';
import { Box } from '@components';

const EditionBadge = ({
  tier,
  size,
  animate,
  serialIndex,
  className,
  white,
  showEdition,
  square,
  isSecondaryMarket = false,
  ...props
}) => {
  const { t } = useTranslation();
  let source = <NumberedTier />;

  if (tier?.tierType === 'DIGITAL_ORIGINAL') {
    if (isSecondaryMarket) {
      source = <DigitalOriginalTierSmall />;
    } else {
      source = <DigitalOriginalTier />;
    }
  }
  if (tier?.tierType === 'NUMBERED_EDITION') {
    if (square) {
      source = (
        <Box className="edition-badge__numbered">
          {t('components.editionBadge.numberedEdition')}
        </Box>
      );
    }
  }

  const defaultStyle = {
    'edition-badge': true,
    'edition-badge--digital': tier?.tierType === 'DIGITAL_ORIGINAL',
    'edition-badge--white': white
  };

  const sizeClass = {
    sm: 'edition-badge--small',
    base: 'edition-badge--base',
    md: 'edition-badge--medium'
  };

  const editionStyle = {
    'animate-edition': animate === true
  };

  return (
    <span className={cx(defaultStyle, sizeClass[size], className)} {...props}>
      <Box className={cx(editionStyle)}>{source}</Box>
      {showEdition === true ? (
        <span
          className={cx(
            'edition-badge__edition',
            tier?.tierType === 'DIGITAL_ORIGINAL' &&
              isSecondaryMarket &&
              'edition-badge__edition__do-small'
          )}
        >
          {serialIndex
            ? serialIndex === 1
              ? `#${serialIndex}`
              : serialIndex
            : tier?.releaseCount === 1
            ? `#${tier?.releaseCount}`
            : ''}
        </span>
      ) : (
        <></>
      )}
    </span>
  );
};

EditionBadge.defaultProps = {
  size: 'md',
  showEdition: true,
  animate: false,
  square: false
};

export default EditionBadge;
