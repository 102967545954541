import { Helmet } from 'react-helmet';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Container } from '@components';
import CollectImage from '@assets/svg/patrons/how-it-works/collect.svg';
import ContributeImage from '@assets/svg/patrons/how-it-works/contribute.svg';
import TradeImage from '@assets/svg/patrons/how-it-works/trade.svg';

function HowItWorksCard({ image, title, description, reverse = false }) {
  return (
    <Box className={`flex flex-col ${reverse ? 'md:flex-row-reverse' : 'md:flex-row'}`}>
      <Box className="w-full md:w-1/2 px-0 md:px-10 py-10">
        <img src={image} alt={title} className="w-full max-w-sm" />
      </Box>
      <Box className="w-full md:w-1/2 p-0 md:p-10 flex flex-col justify-center items-start">
        <h3 className="text-primary capitalize leading-12 font-Satoshi text-2xl lg:text-4xl mb-5 lg:mb-7">
          {title}
        </h3>
        <p className="text-secondary font-normal text-lg font-Satoshi">{description}</p>
      </Box>
    </Box>
  );
}

function HowitWorks() {
  const { t } = useTranslation();
  const [index, setIndex] = useState(1);

  return (
    <Box className="pb-20 overflow-auto">
      <Container size="lg">
        <Box className="single-page__header__hiw text-center">{t('p.howitworks.title')}</Box>
        <Box className="w-full mt-0 lg:mt-7">
          <p className="text-center text-base lg:text-2xl text-secondary font-medium font-Satoshi">
            {t('p.howitworks.description')}
          </p>
        </Box>
        <Box className="mt-7 flex flex-col">
          <HowItWorksCard
            title={t('p.howitworks.collect.title')}
            description={t('p.howitworks.collect.description')}
            image={CollectImage}
          />
          <HowItWorksCard
            reverse={true}
            title={t('p.howitworks.contribute.title')}
            description={t('p.howitworks.contribute.description')}
            image={ContributeImage}
          />
          <HowItWorksCard
            title={t('p.howitworks.trade.title')}
            description={t('p.howitworks.trade.description')}
            image={TradeImage}
          />
        </Box>
      </Container>
      <Helmet>
        <title>{t('p.howitworks.title')}</title>
        <meta property="og:image" content="https://cdn.fans.inc/public/webapp/og-image.png" />
      </Helmet>
    </Box>
  );
}

export default HowitWorks;
