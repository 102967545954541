/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_mobile_analytics_app_id": "95199a0af25e43a0b64bf3ac97850c66",
    "aws_mobile_analytics_app_region": "us-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "checkout",
            "endpoint": "https://fx5tekn6j5.execute-api.us-west-2.amazonaws.com/patrons",
            "region": "us-west-2"
        },
        {
            "name": "nft",
            "endpoint": "https://afe38fngg8.execute-api.us-west-2.amazonaws.com/patrons",
            "region": "us-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://zmybfk32fngyfmcpgiupuh3diy.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-rhkcrdxxrvdmzgg3uqetyr3nqy",
    "aws_cognito_identity_pool_id": "us-west-2:8c1d683f-3ba2-4ff5-a319-fe0547f31a74",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_XxXbr57au",
    "aws_user_pools_web_client_id": "2ll0theb681qp6am77a8q58mat",
    "oauth": {
        "domain": "fans-patrons.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://develop.dkcaq2fjgc03l.amplifyapp.com/marketplace/,http://localhost:3000/marketplace/,http://localhost:3001/marketplace/,http://localhost:3002/marketplace/,https://staging.d367x2857zuuqs.amplifyapp.com/marketplace/,https://fans.inc/marketplace/,https://www.fans.inc/marketplace/,https://sandbox.fans.inc/marketplace/,https://staging.fans.inc/marketplace/,https://www.patrons.art/marketplace/",
        "redirectSignOut": "https://develop.dkcaq2fjgc03l.amplifyapp.com/marketplace/,http://localhost:3000/marketplace/,http://localhost:3001/marketplace/,http://localhost:3002/marketplace/,https://staging.d367x2857zuuqs.amplifyapp.com/marketplace/,https://fans.inc/marketplace/,https://www.fans.inc/marketplace/,https://sandbox.fans.inc/marketplace/,https://staging.fans.inc/marketplace/,https://www.patrons.art/marketplace/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "8",
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "fansinc-bucket142621-patrons",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
