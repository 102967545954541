import { useState } from 'react';
import gql from '@libs/utils/gql';
import useToast from '@libs/utils/toast';
import { requestWithdrawal, verifyWithdrawal, requestWithdrawalCode } from '@graphql/mutations';

export const useWithdrawal = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const request = async (provider, destination, amount) => {
    setLoading(true);
    try {
      const payload = {
        input: {
          provider,
          destination,
          amount
        }
      };

      const res = await gql(requestWithdrawal, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });

      return res.data.requestWithdrawal;
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const resend = async (withdrawalID) => {
    try {
      const payload = {
        input: {
          withdrawalID
        }
      };

      const res = await gql(requestWithdrawalCode, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });

      return res.data.requestWithdrawalCode;
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    }
  };

  const verify = async (withdrawalID, inputOTP) => {
    setLoading(true);
    try {
      const payload = {
        input: {
          withdrawalID,
          OTP: inputOTP
        }
      };

      const res = await gql(verifyWithdrawal, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });

      return res;
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    request,
    resend,
    verify,
    loading
  };
};
