import FansincForgot from './fansinc';
import PatronsForgot from './patrons';

function Forgot() {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsForgot />;
  } else {
    return <FansincForgot />;
  }
}

export default Forgot;
