import { useEffect, useState } from 'react';
import { Switch } from '@headlessui/react';
import ReactTooltip from 'react-tooltip';

export default function ToggleButton({ info, value, disabled, status, ...props }) {
  const [enabled, setEnabled] = useState(status);
  useEffect(() => {
    setEnabled(status);
  }, [status]);
  return (
    <div className="flex items-center justify-center" data-tip={info && info}>
      <button className="flex items-center" {...props} disabled={disabled}>
        <Switch
          value={value}
          disabled={disabled}
          checked={enabled}
          onChange={setEnabled}
          style={{ height: '15px' }}
          className={`${enabled ? 'bg-red-toggleBg' : 'bg-gray-200'}
          relative inline-flex w-8 shrink-0 ${
            disabled ? 'cursor-not-allowed' : 'cursor-pointer'
          } rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
        >
          <span
            aria-hidden="true"
            className={`${
              enabled
                ? disabled
                  ? 'translate-x-3 -translate-y-1 bg-red-100'
                  : 'translate-x-3 -translate-y-1 bg-red-toggle'
                : disabled
                ? '-translate-x-1 -translate-y-1 bg-gray-200'
                : '-translate-x-1 -translate-y-1 bg-white'
            }
            pointer-events-none inline-block h-5 w-5 transform rounded-full shadow ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
        <ReactTooltip className="text-sm" place="top" type="dark" effect="solid" />
      </button>
    </div>
  );
}
