import { Helmet } from 'react-helmet';
import { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, Avatar, Button } from '@components';
import Fade from './components/fade';
import { useParams } from 'react-router-dom';
import { useUserByID } from '@libs/hooks/user';
import { useFollowerUnauthorized } from '@libs/hooks/follower';
import NotFound from '@pages/not-found';
import { getUserName } from '@libs/utils/user';

function UnsubscribeCreator() {
  const { t } = useTranslation();
  const params = useParams();

  const [unsubscribe, setUnsubscribe] = useState(false);
  const { data: user } = useUserByID(params.followeeID);
  const { loading: updateLoading, onUpdateEmailNotification, getData } = useFollowerUnauthorized();

  const [dataError, setDataError] = useState(false);
  useEffect(async () => {
    if (user) {
      if (user?.editors < 1) {
        setDataError(true);
      } else {
        const follower = await getData(params.followeeID, params.followerID);
        if (!follower || follower.emailNotification === 'FALSE') {
          setDataError(true);
        }
      }
    } else {
      setDataError(true);
    }
  }, [user]);

  const handlerUpdateEmailNotification = useCallback(
    async () => {
      const payload = {
        followeeID: params.followeeID,
        followerID: params.followerID
      };
      await onUpdateEmailNotification(payload);
      setUnsubscribe(true);
    },
    [params.followeeID, params.followerID] // eslint-disable-line
  );

  if (dataError) {
    return <NotFound />;
  }

  return (
    <Box className="relative">
      <Fade className="-right-20 -top-40" />
      <Fade className="-left-80 top-1/4" />
      <Fade className="-right-20 top-2/4" />
      <Fade className="-left-80 top-3/4" />
      <Fade className="-right-20 bottom-0" />
      <Container size="lg">
        <Box className="unsubscribe-page">
          <Box className="unsubscribe-page__content">
            <Avatar className="unsubscribe-page__content__avatar" user={user} size="md" />
            <h1>{user?.displayName || getUserName(user?.firstName) + ' ' + user?.lastName}</h1>
            <h6>
              {unsubscribe
                ? t('unsubscribeCreator.subscribeSuccessText')
                : t('unsubscribeCreator.unsubscribeText')}
            </h6>
            <Button
              loading={updateLoading}
              hidden={unsubscribe}
              onClick={() => handlerUpdateEmailNotification()}
              className="unsubscribe-page__content__button"
              danger
            >
              {t('unsubscribeCreator.button')}
            </Button>
          </Box>
        </Box>
      </Container>
      <Helmet>
        <title>{t('unsubscribeCreator.title')}</title>
        <meta property="og:image" content="https://cdn.fans.inc/public/webapp/og-image.png" />
      </Helmet>
    </Box>
  );
}

export default UnsubscribeCreator;
