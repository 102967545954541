import gql from '@libs/utils/gql';
import { mintNFT as mintNFTgql } from '@graphql/mutations';
import { Text, Link } from '@components';
import { getNftUrl, NFT_MARKETPLACES, LISTING_TYPES } from '@libs/utils/exchange';
import handleGraphQLErrors from '@libs/utils/errors/graphql';

const ETHERSCAN_URL = 'https://mumbai.polygonscan.com/tx/';

const parseError = (error) => error?.message ?? error?.errors?.[0]?.message ?? error;

export const mintNFT = async ({ exchange, walletAddress, setIsMinting }) => {
  try {
    setIsMinting(true);

    const {
      data: { mintNFT: responseString }
    } = await gql(
      mintNFTgql,
      { input: { exchangeID: exchange.id, walletAddress } },
      {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      }
    );
    const { statusCode, body } = JSON.parse(responseString);
    if (statusCode !== 200) {
      throw new Error(JSON.parse(responseString)?.message ?? 'Unknown error');
    }
    const newExchange = {
      listingType: LISTING_TYPES.NFT,
      nftContractAddress: body.contractAddress,
      nftTokenIDString: body.tokenID
    };
    return {
      success: true,
      status: (
        <Text>
          Check out your transaction on Etherscan:
          <br />
          <Link
            target="_blank"
            to={`${ETHERSCAN_URL}${body.transactionHash}`}
            className="break-all"
            underlined="white"
          >{`${ETHERSCAN_URL}${body.transactionHash}`}</Link>
        </Text>
      ),
      url: getNftUrl({
        exchange: newExchange,
        marketplace: NFT_MARKETPLACES.OPENSEA_TESTNETS_MUBAI
      })
    };
  } catch (e) {
    console.error(e);
    const handledError = handleGraphQLErrors(e);
    return {
      success: false,
      status: `😥 Something went wrong: ${handledError?.messages[0] ?? 'Unknown error'}`,
      url: '/'
    };
  } finally {
    setIsMinting(false);
  }
};
