import { useState } from 'react';

const createSorter = (sortBy) => {
  switch (sortBy) {
    case 'LOWEST_VOLUME':
      return { direction: 'asc', field: 'totalSalesVolume' };
    case 'HIGHEST_VOLUME':
      return { direction: 'desc', field: 'totalSalesVolume' };
    case 'TITLE_ASC':
      return { direction: 'asc', field: 'displayName' };
    case 'TITLE_DESC':
      return { direction: 'desc', field: 'displayName' };
    case 'CREATED_AT_DESC':
    default:
      return { direction: 'desc', field: 'createdAt' };
  }
};

const createCollectorSorter = (sortBy) => {
  switch (sortBy) {
    case 'LOWEST_PURCHASE':
      return { direction: 'asc', field: 'totalPurchase' };
    case 'HIGHEST_PURCHASE':
      return { direction: 'desc', field: 'totalPurchase' };
    case 'LOWEST_VALUE':
      return { direction: 'asc', field: 'totalCollectionValues' };
    case 'HIGHEST_VALUE':
      return { direction: 'desc', field: 'totalCollectionValues' };
    case 'TITLE_ASC':
      return { direction: 'asc', field: 'displayName' };
    case 'TITLE_DESC':
      return { direction: 'desc', field: 'displayName' };
    default:
      return { direction: 'asc', field: 'displayName' };
  }
};

export const useUserParams = (limit) => {
  const [name, setName] = useState();
  const [query, setQuery] = useState({
    filter: {
      searchableVerified: { eq: 'TRUE' },
      displayName: { exists: true }
    },
    sort: { direction: 'desc', field: 'createdAt' },
    limit
  });

  const onPaginate = (nextToken) => {
    setQuery({ ...query, nextToken });
  };

  const onChangeSort = (sortBy) => {
    setQuery({ ...query, sort: createSorter(sortBy), nextToken: undefined });
  };

  const onReset = () => {
    const filter = {
      ...query.filter,
      style: undefined
    };
    setQuery({ ...query, sort: createSorter('CREATED_AT_DESC'), nextToken: undefined, filter });
  };

  const onChangeName = (data) => {
    if (data) {
      if (data === name) {
        setQuery({
          ...query,
          filter: { ...query.filter, displayName: { matchPhrasePrefix: data } }
        });
      } else {
        setName(data);
        setQuery({
          ...query,
          nextToken: undefined,
          filter: { ...query.filter, displayName: { matchPhrasePrefix: data } }
        });
      }
    } else {
      setQuery({
        ...query,
        nextToken: undefined,
        filter: { ...query.filter, displayName: { exists: true } }
      });
    }
  };

  const onChangeStyle = (style) => {
    if (style) {
      const filter = {
        ...query.filter,
        style: { eq: style }
      };
      setQuery({ ...query, nextToken: null, filter });
    } else {
      const filter = {
        ...query.filter,
        style: undefined
      };
      setQuery({ ...query, filter, nextToken: null });
    }
  };

  return {
    query,
    onPaginate,
    onChangeSort,
    onReset,
    onChangeStyle,
    onChangeName
  };
};

export const useCollectorsParams = (limit) => {
  const [name, setName] = useState();
  const [query, setQuery] = useState({
    filter: {
      searchableVerified: { eq: 'FALSE' },
      or: [{ totalPurchase: { gt: 0 } }, { totalLikeCollectible: { gt: 0 } }]
    },
    sort: createCollectorSorter('TITLE_ASC'),
    limit
  });

  const onPaginate = (nextToken) => {
    setQuery({ ...query, nextToken });
  };

  const onChangeSort = (sortBy) => {
    setQuery({ ...query, sort: createCollectorSorter(sortBy), nextToken: undefined });
  };

  const onReset = () => {
    const filter = {
      ...query.filter,
      style: undefined
    };
    setQuery({ ...query, sort: createSorter('TITLE_ASC'), nextToken: undefined, filter });
  };

  const onChangeName = (data) => {
    if (data) {
      if (data === name) {
        setQuery({
          ...query,
          filter: { ...query.filter, displayName: { matchPhrasePrefix: data } }
        });
      } else {
        setName(data);
        setQuery({
          ...query,
          nextToken: undefined,
          filter: { ...query.filter, displayName: { matchPhrasePrefix: data } }
        });
      }
    } else {
      setQuery({
        ...query,
        nextToken: undefined,
        filter: { ...query.filter, displayName: undefined }
      });
    }
  };

  const onChangeStyle = (style) => {
    if (style) {
      const filter = {
        ...query.filter,
        style: { eq: style }
      };
      setQuery({ ...query, nextToken: null, filter });
    } else {
      const filter = {
        ...query.filter,
        style: undefined
      };
      setQuery({ ...query, filter, nextToken: null });
    }
  };

  return {
    query,
    onPaginate,
    onChangeSort,
    onReset,
    onChangeStyle,
    onChangeName
  };
};
