import FansincNotificationMenu from './fansinc';
import PatronsNotificationMenu from './patrons';

const NotificationMenu = ({ className, ...props }) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsNotificationMenu className={className} {...props} />;
  } else {
    return <FansincNotificationMenu className={className} {...props} />;
  }
};

export default NotificationMenu;
