import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Box, Button, Logo, FormField, Input } from '@components';
import { useWaitlist } from '@libs/hooks/email-waitlist';

function Landing() {
  const { t } = useTranslation();
  const [isSuccess, setIsSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur'
  });

  const { onCreate, createLoading } = useWaitlist();

  const onSubmit = async (values) => {
    const result = await onCreate({
      email: values?.email
    });
    if (result) {
      setIsSuccess(true);
    }
  };
  return (
    <>
      <Box className="landing">
        {isSuccess ? (
          <Box className="landing__content">
            <Box className="landing__content__logo">
              <Logo height="36" />
            </Box>
            <Box className="landing__content__success">
              <Box>{t('p.home.meta.sendEmail')}</Box>
            </Box>
          </Box>
        ) : (
          <Box className="landing__content">
            <Box className="landing__content__logo">
              <Logo height="36" />
            </Box>
            <Box className="landing__content__description">
              <Box>{t('p.home.meta.description')}</Box>
              <Box>{t('p.home.meta.digitally')}</Box>
            </Box>
            <Box className="landing__content__extra">
              <Box>{t('p.home.meta.extra')}</Box>
            </Box>
            <Box className="landing__content__form">
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <FormField>
                  <Input
                    fullWidth
                    placeholder={t('p.home.meta.email.placeholder')}
                    as="email"
                    size="md"
                    errorMessage={errors.email?.message}
                    {...register('email', {
                      required: { value: true, message: t('auth.fields.email.required') },
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: t('auth.fields.email.invalid')
                      }
                    })}
                  />
                </FormField>
                <Button
                  type="submit"
                  className="landing__content__form__button"
                  loading={createLoading}
                >
                  {t('p.home.meta.joinWaitlist')}
                </Button>
              </form>
            </Box>
          </Box>
        )}
        <Helmet>
          <title>{t('p.head.meta.title')}</title>
          <meta name="description" content={t('p.head.meta.description')} />
          <meta property="og:title" content={t('p.head.meta.title')} />
          <meta property="og:description" content={t('p.head.meta.description')} />
          <meta property="og:image" content="https://cdn.patrons.art/public/webapp/og-image.png" />
        </Helmet>
      </Box>
    </>
  );
}

export default Landing;
