import { React } from 'react';
import FansincComingSoon from './fansinc';
import PatronsComingSoon from './patrons';

function ComingSoonWrapper() {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsComingSoon />;
  } else {
    return <FansincComingSoon />;
  }
}
export default ComingSoonWrapper;
