/* eslint-disable */

import React, { useState, useEffect, useContext, createContext } from 'react';

export const COUNTRY_CODES = {
  AU: 'AU',
  US: 'US',
  GB: 'GB'
}; // use package for these?

export const CURRENCY_CODES = {
  AU: 'AUD',
  US: 'USD',
  GB: 'GBR'
}; // use package for these?

export const TAX_PERCENTAGE = { AU: 0.1, US: 0.1 };

const FANS_INC_FEE_PERCENTAGE = 0.2;
const CREATORS_ROYALTY_FEE_PERCENTAGE = 0.1;

export const getCurrencySymbol = (currencyCode) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })
    .format(0)
    .replace(/\d/g, '')
    .trim();

const countryCodeDefault = COUNTRY_CODES.US;

const initialContext = {
  countryCode: countryCodeDefault,
  currencyCode: CURRENCY_CODES[countryCodeDefault],
  currencySymbol: getCurrencySymbol(CURRENCY_CODES[countryCodeDefault]),
  tax: TAX_PERCENTAGE[countryCodeDefault],
  fees: {
    FANS_INC_FEE_PERCENTAGE,
    CREATORS_ROYALTY_FEE_PERCENTAGE
  },
  setCountryCode: () => {},
  calculateTax: () => {},
  calculateFees: () => {},
  currencyFormatter: null,
  getCurrencyFormatter: () => {},
  roundPrice: () => {}
};

const LocaleContext = createContext(initialContext);

export const LocaleProvider = ({ children }) => {
  const [countryCode, setCountryCode] = useState(initialContext.countryCode);
  const [currencyCode, setCurrencyCode] = useState(initialContext.currencyCode);
  const [currencySymbol, setCurrencySymbol] = useState(initialContext.currencySymbol);
  const [tax, setTax] = useState(initialContext.tax);

  useEffect(() => {
    setCurrencyCode(CURRENCY_CODES[countryCode]);
    setCurrencySymbol(getCurrencySymbol(CURRENCY_CODES[countryCode]));
    setTax(TAX_PERCENTAGE[countryCode]);
  }, [countryCode]);

  const calculateTax = (amount) => {
    const parsedAmount = parseFloat(amount);
    const amountBeforeTax = parsedAmount / (1 + tax);
    const taxOnAmount = parsedAmount - amountBeforeTax;
    const amountAfterTax = parsedAmount;
    return { tax: taxOnAmount, beforeTax: amountBeforeTax, afterTax: amountAfterTax };
  };

  const calculateFees = (amount, salesFee = 20) => {
    if (!amount) return { fansIncFee: 0, royaltyFee: 0, amountAfterFees: 0 };
    const fansIncFeeAmount = Math.round(((salesFee / 100) * amount + Number.EPSILON) * 100) / 100;
    const royaltyFeeAmount =
      Math.round((CREATORS_ROYALTY_FEE_PERCENTAGE * amount + Number.EPSILON) * 100) / 100;
    const amountAfterFees = amount - (fansIncFeeAmount + royaltyFeeAmount);
    return { fansIncFee: fansIncFeeAmount, royaltyFee: royaltyFeeAmount, amountAfterFees };
  };

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode
  });

  const getCurrencyFormatter = () => currencyFormatter;

  const roundPrice = (price) => (Math.ceil((price ?? 0) * 100) / 100).toFixed(2);

  return (
    <LocaleContext.Provider
      value={{
        countryCode,
        currencyCode,
        currencySymbol,
        tax,
        fees: initialContext.fees,
        setCountryCode,
        calculateTax,
        calculateFees,
        currencyFormatter,
        getCurrencyFormatter,
        roundPrice
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
};

export const useLocale = () => useContext(LocaleContext);
