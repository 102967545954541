import { PropTypes } from 'prop-types';
import { Countdown, Text } from '@components';
import { EXCHANGE_TYPES } from '@libs/utils/exchange';

function AuctionCountdown({ exchange }) {
  const { exchangeType, expiryDate, parentExchange } = exchange;

  // gets the auctionListing if we pass a bid
  const isListing = exchangeType === EXCHANGE_TYPES.LISTING;
  const expiryDate_ = isListing ? expiryDate : parentExchange?.expiryDate;
  const now = new Date().toISOString();

  if (expiryDate_) {
    if (expiryDate_ < now) {
      return <Text>Auction ended</Text>;
    }

    return <Countdown time={expiryDate_} showSeconds={false} />;
  }

  return null;
}

AuctionCountdown.propTypes = {
  exchange: PropTypes.object.isRequired
};

export default AuctionCountdown;
