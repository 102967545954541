import { BsArrowRightShort } from 'react-icons/bs';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  FooterCollab,
  Heading,
  Text,
  Button,
  Media,
  MediaVinyl,
  Container,
  Grid,
  ClaimCollectibleCard,
  ProfileVideo
} from '@components';
import { customCollectibleMapper } from '@libs/utils/mapper';
import { useCollectible } from '@libs/hooks/collectible';

function CollectibleThisIsMeNow() {
  const slug = 'thisismenow';
  const asset = customCollectibleMapper[slug];
  const { data: collectible, loading: collectibleLoading, refetch } = useCollectible(slug);
  const [openVideo, setOpenVideo] = useState(false);

  return (
    <Box className="this-is-me-now">
      <Box className="this-is-me-now__banner">
        <Box className="this-is-me-now__banner__wrapper">
          <Link to="/marketplace" className="this-is-me-now__banner__header">
            <img src={asset?.creator?.headerLogo} alt="" />
          </Link>
          <Box className="this-is-me-now__banner__content">
            <Text className="this-is-me-now__banner__subtitle">Coming soon, JLo's new album</Text>
            <Heading as="h1" className="this-is-me-now__banner__title">
              <span className="text-gradient">This is</span>
              <span className="text-gradient">Me, Now</span>
            </Heading>
            <Button
              onClick={() => setOpenVideo(true)}
              size="lg"
              className="this-is-me-now__banner__button"
            >
              Play Video <BsArrowRightShort />
            </Button>
          </Box>
          <img className="this-is-me-now__banner__hero" src={asset?.creator?.hero} alt="" />
        </Box>
      </Box>
      {collectible?.creator && (
        <ProfileVideo
          video={asset?.creator?.openingVideo}
          isOpen={openVideo}
          verticalCenter={false}
          onClose={() => setOpenVideo(false)}
          title={'Claim your Secret JLo Album Collectible'}
          description={
            "Get a sneak peak into JLo's new album This is me, Now by claiming your Secret Collectible at Fans.Inc"
          }
          className="this-is-me-now__banner__video"
          footer={
            <Box className="this-is-me-now__banner__video__footer">
              <img src={asset?.creator?.footer} />
            </Box>
          }
        />
      )}
      <Box className="this-is-me-now__content">
        <Container>
          <Grid className="this-is-me-now__content__wrapper">
            <Box className="this-is-me-now__content__collectible">
              {collectible?.streamExternal !== 'TRUE' && collectible?.category === 'SONG' ? (
                <MediaVinyl collectible={collectible} />
              ) : (
                <Media
                  collectible={collectible}
                  full
                  showPlayer
                  size={1080}
                  showPlayerSlider
                  showExternalLink={collectible?.streamExternal === 'TRUE'}
                />
              )}
            </Box>
            <Box className="this-is-me-now__content__form">
              <ClaimCollectibleCard collectible={collectible} refetch={refetch} />
            </Box>
          </Grid>
        </Container>
      </Box>
      <FooterCollab image={asset?.creator?.logo} />
    </Box>
  );
}

export default CollectibleThisIsMeNow;
