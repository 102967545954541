import { useCallback, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { ReactComponent as UserPlus } from '@assets/svg/user-plus.svg';
import { ReactComponent as UserCheck } from '@assets/svg/user-check.svg';
import { ReactComponent as DoubleCheck } from '@assets/svg/double-check.svg';
import { ReactComponent as FollowCross } from '@assets/svg/follow-cross.svg';
import ReactTooltip from 'react-tooltip';

import { Button, Modal, Box } from '@components';

import { useAuth } from '@libs/contexts/auth';
import { useFollower } from '@libs/hooks/follower';
import { getUserName } from '@libs/utils/user';

const FollowButton = ({ user, type, subscribeLabel, unsubscribeLabel }) => {
  const { t } = useTranslation();
  const { user: auth } = useAuth();
  const { isFollowing, onCreate, onDelete, loading: formLoading } = useFollower(user?.id, user);

  const handleFollow = useCallback(
    (data, e) => {
      e.preventDefault();
      if (isFollowing) {
        onDelete(data);
      } else {
        onCreate(data);
      }
    },
    [isFollowing] // eslint-disable-line
  );

  const [unfollowModal, setUnfollowModal] = useState(false);

  const setUnfollowModalClose = () => {
    setUnfollowModal(false);
  };

  return auth?.username !== user?.username ? (
    <>
      {type === 'icon' ? (
        <Box
          className="cursor-pointer"
          onClick={(e) => {
            isFollowing
              ? process.env.REACT_APP_NAME === 'patrons'
                ? handleFollow({ followeeID: user?.id }, e)
                : setUnfollowModal(true)
              : handleFollow({ followeeID: user?.id }, e);
          }}
        >
          {formLoading ? (
            <Box className="relative isFollowLoading mr-4"></Box>
          ) : (
            <>
              {isFollowing ? (
                <UserCheck />
              ) : (
                <>
                  <Box data-tip data-for={`minimumTooltip${user?.id}`}>
                    <UserPlus />
                  </Box>
                  <ReactTooltip id={`minimumTooltip${user?.id}`} place="right" type="dark">
                    <Box className="normal-case max-w-xs">
                      <Trans i18nKey="p.components.followButton.tooltip">
                        {{ name: user?.displayName || user?.firstName + ' ' + user?.lastName }}
                      </Trans>
                    </Box>
                  </ReactTooltip>
                </>
              )}
            </>
          )}
        </Box>
      ) : (
        <>
          {isFollowing ? (
            <Button
              className="follow-button-green"
              default={!isFollowing}
              green
              onClick={(e) => {
                isFollowing
                  ? process.env.REACT_APP_NAME === 'patrons'
                    ? handleFollow({ followeeID: user?.id }, e)
                    : setUnfollowModal(true)
                  : handleFollow({ followeeID: user?.id }, e);
              }}
              disabled={formLoading}
              loading={formLoading}
            >
              {isFollowing ? (
                unsubscribeLabel ? (
                  unsubscribeLabel
                ) : (
                  <Box className="flex items-center">
                    <Box className="follow-double-check">
                      {t('p.components.followButton.following')}
                    </Box>
                    <Box className="follow-cross">{t('p.components.followButton.unsubscribe')}</Box>
                    {!formLoading && <DoubleCheck className="ml-2 follow-double-check" />}
                    {!formLoading && <FollowCross className="ml-2 follow-cross" />}
                  </Box>
                )
              ) : subscribeLabel ? (
                subscribeLabel
              ) : (
                t('p.components.followButton.follow')
              )}
            </Button>
          ) : (
            <Button
              className="follow-button"
              default={!isFollowing}
              darkOutline={isFollowing}
              onClick={(e) => {
                isFollowing
                  ? process.env.REACT_APP_NAME === 'patrons'
                    ? handleFollow({ followeeID: user?.id }, e)
                    : setUnfollowModal(true)
                  : handleFollow({ followeeID: user?.id }, e);
              }}
              disabled={formLoading}
              loading={formLoading}
            >
              {isFollowing
                ? unsubscribeLabel
                  ? unsubscribeLabel
                  : t('p.components.followButton.following')
                : subscribeLabel
                ? subscribeLabel
                : t('p.components.followButton.follow')}
            </Button>
          )}
        </>
      )}
      <Modal
        showCloseIcon
        size="md"
        isOpen={unfollowModal}
        onClose={setUnfollowModalClose}
        heading={t('p.components.followButton.modal.heading', {
          name: `${getUserName(user?.firstName)} ${user?.lastName}`
        })}
        closable={t('p.components.followButton.modal.cancel')}
        verticalCenter
        action={
          <Button
            danger
            default
            onClick={(e) => {
              handleFollow({ followeeID: user?.id }, e);
              setUnfollowModalClose();
            }}
            disabled={formLoading}
            loading={formLoading}
          >
            {t('p.components.followButton.modal.unsubscribe')}
          </Button>
        }
      >
        {t('p.components.followButton.modal.confirmation')}
      </Modal>
    </>
  ) : (
    <></>
  );
};

export default FollowButton;
