import cx from 'classnames';
import { forwardRef } from 'react';

const Box = forwardRef(function BoxPlain({ children, className, ...props }, ref) {
  return (
    <div ref={ref} className={cx('box', className)} {...props}>
      {children}
    </div>
  );
});

export default Box;
