import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Box, Container, Heading } from '@components';
import Fade from '@pages/single-page/components/fade';
import Hero from '@pages/single-page/components/hero';

function TermsAndConditions() {
  const { t } = useTranslation();

  return (
    <Box className="relative overflow-hidden">
      <Fade className="-right-20 -top-40" />
      <Fade className="-left-80 top-1/4" />
      <Fade className="-right-20 top-2/4" />
      <Fade className="-left-80 top-3/4" />
      <Fade className="bottom-0 -right-20" />
      <Container className="single-page" size="md">
        <Box className="single-page__header">{t('termsAndConditions.title')}</Box>
        <Box className="single-page__content">
          <h2>1. Introduction</h2>
          <p>
            Patrons.Art provides a marketplace for users to offer, buy, trade, sell, collect, and
            showcase limited edition digital collectibles (<strong>Collectibles</strong>).
          </p>
          <p>
            These Terms and Conditions, along with all policies and additional terms that we make
            available on our websites from time to time, apply to all transactions activities taking
            place on the Patrons.Art platform (the <strong>Platform</strong>) and outline the
            services that Patrons.Art provides and your responsibilities and obligations while using
            the Platform. Please read them carefully.
          </p>
          <p>
            These Terms and Conditions also include provisions that govern how disputes between you
            and Patrons.Art should be resolved.
          </p>
          <h3>Definitions</h3>
          <p>
            <strong>Account</strong> means an authorised account for the Platform for a validly
            registered User.
          </p>
          <p>
            <strong>Collectible</strong> means a limited edition digital Collectible featuring
            Content and available on the Platform.{' '}
          </p>
          <p>
            <strong>Content</strong> means any material used in connection with a Collectible on the
            Platform, including without limitation any artworks, photographs, audio or audio visual
            recordings, and any Intellectual Property Rights embodied in such material.{' '}
          </p>
          <p>
            <strong>Patrons.Art Marks</strong> means any Patrons.Art trade marks or logos included
            on the Platform.
          </p>
          <p>
            <strong>Intellectual Property Rights</strong> means all industrial and intellectual
            property rights of whatever nature throughout the world conferred under statute, common
            law or equity, whether existing now or at any time in the future, and includes rights in
            respect of or in connection with copyright, inventions (including patents), formulae,
            databases, business processes and methods, trade marks, service marks, business names,
            trade names, domain names, designs, confidential information, trade secrets and know-how
            and similar industrial and intellectual property rights, whether or not registered or
            registrable, and includes the right to apply for or renew the registration of such
            rights.
          </p>
          <p>
            <strong>Personnel</strong> means the officers, employees, contractors, agents, assignees
            and successors in title of:
          </p>
          <ul className="line">
            <li>a party;</li>
            <li>a party’s Related Bodies Corporate; and </li>
            <li>a party’s contractors.</li>
          </ul>
          <p>
            <strong>Platform Material</strong> means all elements and material comprising the
            Platform and all present and future Intellectual Property Rights embodied in the
            Platform, including without limitation all copyright works and subject matter, trade
            marks, designs, systems, computer code, software, methods, information, but excluding
            the Collectibles and Content.{' '}
          </p>
          <p>
            <strong>Purchaser</strong> means a User who purchases a Collectible on the Platform in
            accordance with these Terms and Conditions.{' '}
          </p>
          <p>
            <strong>Related Body Corporate</strong> has the meaning given to that term in the
            Corporations Act 2001 (Cth).
          </p>
          <p>
            <strong>Seller</strong> means a User who offers a Collectible for sale on the Platform
            in accordance with these Terms and Conditions, and includes Creators selling
            Collectibles featuring their Content and also Users who have previously purchased a
            Collectible on the Platform and who are making such Collectible available for resale.{' '}
          </p>
          <p>
            <strong>Services</strong> means the services provided by Patrons.Art and the
            functionality of the Platform, outlined in paragraph 5 of these Terms and Conditions.
          </p>
          <p>
            <strong>Users</strong> means any person who uses the Platform.
          </p>
          <h2>2. Patrons.Art Platform</h2>
          <ul className="line">
            <li>The Platform is an online application available at www.patrons.art.</li>
            <li>
              The entity you are contracting with is Fans Int Pty Ltd (ABN 18 653 473 486) of 15,
              9-13 Hunter Street, Sydney NSW 2000 (<strong>Patrons.Art, we, us, or our</strong>).
            </li>
            <li>
              By using the Platform, or indicating your acceptance of these terms by clicking the
              relevant box when setting up your Account, you confirm that you have read, understand
              and unconditionally agree to be bound by these Terms and Conditions and that you have
              the capacity to contract and to use the Services.
            </li>
            <li>
              You may only use the Platform and sign up for an Account if you are at least 13 years
              of age. If you are under the age of 13 you may not use the Platform.{' '}
            </li>
            <li>
              You acknowledge that the most recent version of the Terms and Conditions available on
              the Platform will prevail over any other version or any other contradictory document.
              We may update the Terms and Conditions from time to time, and we will notify you of
              any changes by email or through posts on the Platform. You acknowledge that any
              changes will take effect 14 days following any such notification email or post.{' '}
            </li>
          </ul>
          <h2>3. Accounts</h2>
          <ul className="line">
            <li>
              To buy, sell, or trade Collectibles on the Platform you must create an Account and
              register your details which may include your name, email address, billing address and
              phone number, and set up a password for the Account in accordance with the password
              requirements specified by Patrons.Art. Alternatively, Users may choose to create an
              Account by authenticating with their personal Facebook, Google, or Apple account
              “social authentication” with the Platform. If a User chooses to create an account via
              social authentication, their personal social media user account will serve as their
              Account on the Platform.{' '}
            </li>
            <li>
              You are responsible for any actions of any person using your Account, including
              without your consent. You must immediately report any unauthorised or fraudulent use
              of your Account and password to Patrons.Art.{' '}
            </li>
            <li>
              You agree that Patrons.Art will not be liable for any loss or damage you incur due to
              the loss or theft of your password.{' '}
            </li>
          </ul>
          <h2>4 Closure or Termination of Accounts</h2>
          <ul className="line">
            <li>
              You may request the closure and deletion of your Account. The closure of your Account
              may result in the permanent deletion of all of your data associated with the Account
              and ability to access the Collectibles associated with your Account.
            </li>
            <li>
              We may suspend Accounts that have been inactive for a substantial period of time.
            </li>
            <li>
              Once you submit a request for the closure and deletion of your Account, you have a
              period of 30 days to sell any Collectibles you have purchased on the Platform, and at
              the expiry of this period your Account will be deleted and ownership of any unsold
              Collectibles associated with your Account will be transferred to Patrons.Art.{' '}
            </li>
          </ul>
          <h2>5 Services on The Platform</h2>
          <h3 className="normal-case">5.1 Availability of Collectibles on the Platform</h3>
          <ul className="line">
            <li>
              The value of the Collectibles is dependent on their scarcity and desirability. There
              are two categories of Collectibles available on the Platform:
              <ul className="line ml-8">
                <li>
                  the “Digital Original” of such Collectible, meaning it is the first of the
                  Collectible to be issued, and is therefore the most valuable iteration of a
                  Collectible; or
                </li>
                <li>
                  a “Limited Edition” being one of a limited number of the Collectibles issued in
                  addition to the Digital Original.
                </li>
              </ul>
            </li>
            <li>
              Additional categories of Collectibles may be offered on the Platform in relation to
              new Collectibles (not affecting existing Collectibles on the Platform).
            </li>
            <li>
              Due to the way that details of ownership and provenance of Collectibles are recorded
              on a digital ledger and the measures Patrons.Art uses to protect the Collectibles,
              Collectibles may only be offered, purchased, and sold on the Platform and cannot be
              used or dealt with on a third party platform or marketplace without approval from
              Patrons.Art.{' '}
            </li>
          </ul>
          <h3 className="normal-case">5.2 Trading on the Platform</h3>
          <ul className="line">
            <li>
              For Collectibles offered at auction, the purchaser of the Collectible is the bidder
              who has submitted the highest bid at the conclusion of the auction (Purchaser). Any
              bid submitted on the Platform through a valid Account is deemed authentic and valid.
              Bids are binding and cannot be withdrawn or modified after they have been submitted.
              Patrons.Art may set a floor price for auctions before Users may submit bids in respect
              of such Collectibles for a fixed period of time.
            </li>
            <li>
              If there are technical difficulties or service interruptions which affect Users’
              ability to access the Platform or submit bids in an auction, Patrons.Art reserves the
              right to extend the duration of an auction or to pause an auction for it to be
              restarted at a later time and date.
            </li>
            <li>
              In addition to making Collectibles available by way of an auction, Users are permitted
              to offer any of their purchased Collectibles on the Platform at a fixed price and/or
              open for offers. An offer submitted by a Purchaser will remain on the Platform for up
              to 48 hours and if the offer has not been accepted by a Seller within such period the
              offer will expire and be removed from the Platform.
            </li>
            <li>
              You acknowledge that Patrons.Art does not intervene in any way in the selection of
              Collectibles offered for sale on the Platform or the pricing of Collectibles in the
              Platform.
            </li>
          </ul>
          <h2>6 Prohibited Activity on The Platform</h2>
          <h3 className="normal-case">6.1 Prohibited activities</h3>
          <p>You agree that in your use of the Platform and the Collectibles you will not:</p>
          <ul className="line">
            <li>fail to comply with all or part of these terms and conditions;</li>
            <li>fail to validly pay for Collectibles you purchase;</li>
            <li>
              trade Collectibles on the Platform without the primary aim of selling or purchasing a
              Collectible, and disrupt the proper functioning of the Platform;
            </li>
            <li>
              carry out suspicious transactions, in particular by repeatedly buying or selling your
              Collectibles at prices that do not reasonably reflect the market value of the relevant
              Collectibles;
            </li>
            <li>
              engage in fraudulent conduct, or use the Platform through automated means or under
              false or pretences, including by impersonating another person;
            </li>
            <li>disclose your login or password to third parties;</li>
            <li>use several distinct Accounts;</li>
            <li>
              send, upload or disseminate any material which is unlawful, false, inaccurate,
              defamatory, libellous, offensive, obscene, sexually explicit, harassing, threatening,
              objectionable or abusive;
            </li>
            <li>
              distribute viruses, worms, defects, corrupted files, Trojan horses or any deceptive or
              destructive material or technologies;
            </li>
            <li>
              engage in conduct, which is unlawful or infringes the rights, including any
              Intellectual Property Rights of Patrons.Art or any third parties;
            </li>
            <li>become bankrupt or insolvent;</li>
            <li>
              use or reproduce the Platform or any Platform Material or Content for the purposes of
              competing with Patrons.Art;
            </li>
            <li>
              employ or use a computer program to simulate human behaviour, or use any robot,
              spider, scraper, data mining tools, data gathering and extraction tools, in connection
              with the Platform;
            </li>
            <li>
              circumvent the payment systems and procedures, or trading processes of the Platform;
            </li>
            <li>collect information about Users without their consent;</li>
            <li>
              bid on a Collectible that is owned by you or someone you know to artificially inflate
              the price of the Collectible; and
            </li>
            <li>
              authorise or direct any third party to carry out any of the prohibited activities
              listed in paragraphs 6.1(a) to (p).
            </li>
          </ul>
          <h3>6.2 Action Patrons.Art may take in respect of prohibited activities</h3>
          <ul className="line">
            <li>
              If you carry out or take part in any of the prohibited activities in paragraph 6.1
              above, we reserve the right to suspend, refuse, modify or terminate all or part of our
              Services to you, in our complete discretion.
            </li>
            <li>
              If we decide to suspend or terminate your Account we will notify you by email to the
              email address registered to your Account, and such notice will specify the reason for
              the suspension or termination, and the duration of the suspension or if your Account
              will be permanently terminated.
            </li>
            <li>
              If we decide to permanently terminate your Account, you have a period of 30 days to
              sell any Collectibles you have purchased on the Platform, and at the expiry of this
              period your Account will be deleted and ownership of any unsold Collectibles
              associated with your Account will be transferred to Patrons.Art.
            </li>
          </ul>
          <h2>7 Intellectual Property</h2>
          <h3>7.1 Ownership of the Platform</h3>
          <ul className="line">
            <li>
              You acknowledge that Patrons.Art owns the Platform and all Platform Material. All
              right, title and interest in and to the Platform and Platform Material, vest solely
              and exclusively in Patrons.Art.
            </li>
            <li>
              Your use of the Platform or ownership of any Collectibles does not grant you ownership
              of or any other rights in respect of the Platform and Platform Material. You must not
              use, reproduce, distribute or commercialise any Platform Materials.
            </li>
            <li>
              You must not apply for or register any Intellectual Property Rights embodied in the
              Platform Materials (including the Patrons.Art Marks) or any Collectibles. You must not
              disassemble or decompile, reverse engineer or otherwise attempt to discover any source
              code contained in the Platform or Services.
            </li>
          </ul>
          <h3>7.2 Copyright</h3>
          <ul className="line">
            <li>
              You are solely responsible for use of the Services and for any Content provided,
              including compliance with applicable laws, rules, and regulations. We take no
              responsibility for Content posted or listed via the Services.
            </li>
            <li>
              You retain your rights to any Content you submit, post, or display using the Services.
            </li>
            <li>
              By submitting, posting or displaying Content on or through the Services, you grant us
              a non-exclusive rights to use, copy, modify, and display any text, content, files,
              communications, comments, feedback, suggestions, ideas, concepts, questions, data or
              other content that you submit or post on or through the Services or through tools or
              applications we provide for posting or sharing such content for our lawful business
              purposes, including to provide, promote, and improve the Services.
            </li>
            <li>
              The Patrons.Art marketplace and the value of the Collectibles offered on the Platform
              depends on encouraging digital scarcity combined with verified authenticity of the
              Collectibles. For Content provided for the purposes of creating Collectibles, you
              agree not to create or distribute (or authorise others, including other platforms or
              artists, to create or distribute) any digital Collectibles using the same Content
              featured on the Platform, in any form or media including non-fungible tokens (NFTs).
            </li>
            <li>
              You represent and warrant that you have, or have obtained, all rights, licenses,
              consents, permissions, power and/or authority necessary to grant the rights granted
              herein for any Content that you submit, post or display on or through the Services.
              You agree that such Content will not contain material subject to copyright or other
              proprietary rights, unless you have necessary permission or are otherwise legally
              entitled to post the material and to grant Patrons.Art the license described above.
            </li>
            <li>
              Patrons.Art reserves the right to remove Content without prior notice. Patrons.Art
              will take down works in response to formal infringement claims and will terminate a
              User's access to the Services if the User is determined to be a repeat infringer.
            </li>
          </ul>
          <h3>7.3 Ownership of Content</h3>
          <ul className="line">
            <li>
              Each Collectible features Content that is owned by Creators and which has been
              licensed by the Creators to Patrons.Art.
            </li>
            <li>
              All right, title and interest in and to the Content, is owned solely and exclusively
              by the relevant Creators. Nothing in these Terms and Conditions or otherwise arising
              from your purchase of a Collectible transfers any right, title or interest in or to
              the Content to you except as expressly provided in clause 7.3 below.
            </li>
          </ul>
          <h3>7.4 Licence to use Content</h3>
          <ul className="line">
            <li>
              If you purchase a Collectible on the Platform, Patrons.Art grants to you a
              non-exclusive, non-transferable, royalty-free licence to use the Content associated
              with your Collectible solely for your own personal and non-commercial use, within and
              as permitted by the Platform environment only. For the avoidance of doubt, you must
              not reproduce, broadcast, communicate, publish, display, exhibit or use the Content
              outside of the Platform or on any third-party platforms, except to the limited extent
              that such use is expressly permitted in these Terms and Conditions.
            </li>
            <li>
              You must not, and are granted no rights to, edit, modify or change any Collectibles or
              Content.
            </li>
            <li>
              Patrons.Art grants you a non-exclusive, non-transferable, royalty-free licence to use
              the Content associated with your Collectible solely for the purposes of promoting your
              Collectible on social media platforms, throughout the world, but using the “share”
              function on the Platform only.
            </li>
          </ul>
          <h3>7.5 Ownership of Collectibles</h3>
          <p>
            Subject to your compliance with these Terms and Conditions, if you rightfully acquire or
            purchase a Collectible on the Platform in accordance with these Terms and Conditions,
            you are the owner of that Collectible (excluding any Platform Material or Content
            associated with the Collectible) and we will acknowledge you as the owner of the
            Collectible on the Platform.{' '}
          </p>
          <h2>8 Payments</h2>
          <ul className="line">
            <li>
              You may purchase Collectibles using a credit card. Any transactions that you engage in
              via the Platform will be conducted solely through third party service providers. We
              have no control over these payments or transactions, nor do we have the ability to
              reverse any payments or transactions. We have no liability to you or to any third
              party for any claims or damages that may arise as a result of any payments or
              transactions that you engage in via the Platform, or any other payment or transactions
              that you conduct via our third party service providers. We do not provide refunds for
              any purchases that you might make on or through the Platform.
            </li>
          </ul>
          <h2>9 Fees and Taxes</h2>
          <ul className="line">
            <li>
              We charge fees on transactions taking place on the Platform (“
              <strong>Transaction Fee</strong>”) which are outlined in the{' '}
              <strong>Fee Policy</strong>. We may update the Fee Policy and the fees charged on
              transactions from time to time but we will update you at least 14 days in advance
              through posts on the Platform or through an email to your Account email address.
            </li>
            <li>
              You will be solely responsible to pay any and all sales, use, value-added and other
              taxes, duties, and assessments (except taxes on our net income) now or hereafter
              claimed or imposed by any governmental authority (collectively, the “Taxes”)
              associated with your use of the Platform. Except for income taxes levied on us, you:
              (i) will pay or reimburse us for all national, federal, state, local or other taxes
              and assessments of any jurisdiction, including value added taxes and taxes as required
              by international tax treaties, customs or other import or export taxes, and amounts
              levied in lieu thereof based on charges set, services performed or payments made
              hereunder, as are now or hereafter may be imposed under the authority of any national,
              state, local or any other taxing jurisdiction; and (ii) will not be entitled to deduct
              the amount of any such taxes, duties or assessments from payments made to us pursuant
              to these Terms and Conditions.
            </li>
            <li>
              You must include a credit card or payment method on your Account for use to pay for
              the purchase of any Collectibles along with any fees and taxes.
            </li>
            <li>If your payment method fails you may be subject to late fees. </li>
          </ul>
          <h2>10 Limitation of Liability and Disclaimers</h2>
          <ul className="line">
            <li>
              You acknowledge and agree that your use of the Services, including the Collectibles
              and the Platform, is entirely at your own risk, and that our Services and the Platform
              are provided “as is” and “as available”. Without limitation, Patrons.Art, its
              Personnel and Related Bodies Corporate, will not be liable for any losses, damages,
              claims, liabilities or expenses arising from:
              <ul className="line ml-8">
                <li>
                  your use of or inability to use the Platform or the Services, including
                  disruptions or delays to our Services;
                </li>
                <li>
                  cyberattacks, viruses or malware obtained by using the Platform or the Services;
                </li>
                <li>errors, inaccuracies bugs, or glitches of any kind;</li>
                <li>
                  damage to your hardware or devices from use of the Platform or the Services;
                </li>
                <li>
                  the acts and omissions of any third parties in respect of the Platform, including
                  without limitation unlawful activities, activities which breach these Terms and
                  Conditions, and any acts or omissions in relation to any third-party Content used
                  in association with the Collectibles; and
                </li>
                <li>
                  any action that we take under paragraph 6.2 above (Prohibited Activity on the
                  Platform).
                </li>
              </ul>
            </li>
            <li>
              Patrons.Art cannot guarantee the continuous operation of the Platform, and you
              acknowledge that bids on the Platform may be subject to delays and may not be updated
              on the Platform in real time.
            </li>
            <li>
              To the full extent permitted by applicable law, Patrons.Art, its Personnel and Related
              Bodies Corporate make no express warranties and disclaim all implied warranties
              regarding the Platform, the Services and the Collectibles, including in relation to
              merchantability, fitness for any purpose, non-infringement, correctness, accuracy or
              reliability.
            </li>
            <li>
              Patrons.Art does not provide any warranty to you that:
              <ul className="line ml-8">
                <li>your use of the Platform will meet your requirements;</li>
                <li>
                  your access to and use of the Platform will be uninterrupted, timely, secure or
                  free from error; and
                </li>
                <li>the information on the Platform is accurate.</li>
              </ul>
            </li>
            <li>
              You acknowledge that the value and prices of Collectibles may be volatile and that the
              Collectibles do not have an inherent or intrinsic value. Patrons.Art makes no
              representations or warranties, and cannot guarantee, that the Collectibles you
              purchase will retain the original value at which they were purchased, as the conduct
              of other Users on the Platform can affect the value of the Collectibles you own.
            </li>
            <li>
              To the full extent permitted by law, Patrons.Art will not be liable to you for any
              special, indirect or consequential loss, or any loss of profit, data, business
              opportunity, goodwill or reputation, any other intangible losses, whether at common
              law, under tort (including negligence), in equity, in accordance with statute or
              otherwise. Nothing precludes the recovery by a party of loss or damage which may
              fairly and reasonably be considered to arise naturally, that is, according to the
              usual course of things, from the breach or other act or omission giving rise to the
              relevant liability.
            </li>
            <li>
              You agree that the total, aggregate liability to you for any and all claims arising
              out of or relating to the Platform or the Collectibles, whether in contract, tort,
              strict liability, or any other legal theory, is limited to the amounts you have
              actually and lawfully paid Patrons.Art and any Users while you have used the Platform,
              less any amounts you have received from the sale or exchange of Collectibles.
            </li>
            <li>
              Nothing in these Terms and Conditions excludes, restricts or modifies any rights or
              statutory guarantees that you may have under applicable laws that cannot be excluded,
              restricted or modified, including any such rights or statutory guarantees under the
              Australian Consumer Law.
            </li>
          </ul>
          <h2>11 Indemnity</h2>
          <ul className="line">
            <li>
              You will at all times indemnify and keep indemnified Patrons.Art and our Personnel and
              Related Bodies Corporate, from and against any losses, claims, injuries, damages or
              liabilities (including reasonable legal costs and expenses, and any amounts payable to
              a third party whether as a result of a settlement or awarded by a court) that we or
              any third party incurs in respect of your breach of these Terms and Conditions, your
              use of the Collectibles, your misuse of the Services or the Platform, and your
              unlawful, wilful or negligent acts or omissions. We reserve the right, to assume
              control of the defence or settlement of any such claims, at our own expense, in which
              case you agree to provide all reasonable assistance requested by us.
            </li>
          </ul>
          <h2>12 Disputes</h2>
          <ul className="line">
            <li>
              You agree and hereby express to give up any rights to:
              <ul className="line ml-8">
                <li>
                  litigate claims in a court. Other rights that you would have if you went to court
                  may also be unavailable or may be limited in arbitration.
                </li>
                <li>have a trial by jury.</li>
                <li>
                  participate as a member of a class of claimants in any lawsuit, including, but not
                  limited to, class action lawsuits involving any such dispute.
                </li>
              </ul>
            </li>
            <li>
              If a dispute arises between you and Patrons.Art, we will seek to reach a quick and
              cost-effective resolution to the dispute, and we will consider initially resolving the
              dispute through alternative dispute resolution procedures, such as mediation. Each
              party will cover its own fees and costs associated with alternative dispute resolution
              procedures.
            </li>
            <li>
              In the event a dispute proceeds to arbitration, the award of the arbitrator will be
              final and binding, and any judgment on the award rendered by the arbitrator may be
              entered in any court of competent jurisdiction. The parties agree that they will not
              appeal any arbitration decision to any court.
            </li>
            <li>
              Notwithstanding the foregoing, we may seek and obtain injunctive relief in any
              jurisdiction in any court of competent jurisdiction, and you agree that these Terms
              and Conditions are specifically enforceable by us through injunctive relief and other
              equitable remedies without proof of monetary damages.
            </li>
          </ul>
          <h2>13 Assumption of Risks Related to Digital Collectibles</h2>
          <ul className="line">
            <li>
              Volatility: The prices of Collectibles may be extremely volatile and subjective.
              Fluctuations in the price of other digital assets could materially and adversely
              affect the value of your Collectibles, which may also be subject to significant price
              volatility. The value of Collectibles is inherently subjective, and factors occurring
              outside of the control of Patrons.Art may materially impact the value and desirability
              of any particular Collectible.
            </li>
            <li>
              Inherent risks: There are inherent risks associated with using Collectibles,
              including, but not limited to, the risk of hardware, software and internet
              connections, the risk of malicious software introduction, and the risk that third
              parties may obtain unauthorised access to a User’s Account. The regulatory regime
              governing tokens is uncertain, and new regulations or policies may materially
              adversely affect the potential utility or value of the Collectibles.
            </li>
          </ul>
          <h2>14 General</h2>
          <ul className="line">
            <li>
              These Terms and Conditions supersede all previous agreements, understandings,
              negotiations, representations and warranties about its subject matter and embodies the
              entire agreement between the parties about its subject matter.
            </li>
            <li>
              You acknowledge that no representations and warranties about the subject matter of
              these Terms and Conditions have been made by or on behalf Patrons.Art except as
              expressly set out in these Terms and Conditions and that you have not relied on any
              representations or warranties about the Platform or the Collectibles given by or on
              behalf of Patrons.Art, except as expressly provided in these Terms and Conditions.
            </li>
            <li>
              In these Terms and Conditions headings are for convenience only and do not affect
              interpretation.
            </li>
            <li>
              You agree that Patrons.Art may provide you with notices by email or through posts on
              the Platform. Besides notices issued under paragraph 2(e) of these Terms and
              Conditions, notices sent by email will be effective 24 hours after an email is sent,
              unless the sending party is notified that the email address is invalid or that the
              email has not been delivered, and notices we provide by posting will be effective upon
              posting. You must ensure that your contact details in your Account are up to date.
            </li>
            <li>
              If you wish to contact Patrons.Art in relation to these Terms and Conditions please
              send us an email at support@patrons.art, or a letter to Patrons.Art, 15, 9-13 Hunter
              Street, Sydney NSW 2000 or contact us through the Contact Us page on the Platform.
            </li>
            <li>
              Any term of these Terms and Conditions which is wholly or partially void or
              unenforceable is severed to the extent that it is void or unenforceable. The validity
              or enforceability of the remainder of these Terms and Conditions is not affected.
            </li>
            <li>
              Paragraphs 6, 7.1, 7.2, 10, 11, 12, and 13 survive the closure or deletion of your
              Account, together with any other term which by its nature is intended to do so.
            </li>
            <li>
              Except as expressly provided in these Terms and Conditions, only you have a right or
              benefit under it.
            </li>
            <li>
              No waiver of a right or remedy under these Terms and Conditions is effective unless it
              is in writing and signed by the party granting it. It is only effective in the
              specific instance and for the specific purpose for which it is granted.
            </li>
            <li>
              A single or partial exercise of a right or remedy under these Terms and Conditions
              does not prevent a further exercise of that or of any other right or remedy.
            </li>
            <li>
              Failure to exercise or delay in exercising a right or remedy under these Terms and
              Conditions does not operate as a waiver or prevent further exercise of that or any
              other right or remedy.
            </li>
            <li>
              You must not assign or novate any of your rights or obligations under these Terms and
              Conditions without the prior written approval of Patrons.Art. We may in our complete
              discretion assign or novate our rights and obligations under these Terms and
              Conditions to an affiliate, Related Body Corporate, or in connection with a merger,
              acquisition or sale of Patrons.Art.
            </li>
            <li>
              These Terms and Conditions are governed by the laws of New South Wales, Australia. If
              a dispute cannot be resolved in accordance with paragraph 12 above then you and
              Patrons.Art irrevocably submit to the non-exclusive jurisdiction of the courts of New
              South Wales, Australia.
            </li>
          </ul>
        </Box>
      </Container>
      <Helmet>
        <title>{t('termsAndConditions.title')}</title>
        <meta property="og:image" content="https://cdn.patrons.art/public/webapp/og-image.png" />
      </Helmet>
    </Box>
  );
}

export default TermsAndConditions;
