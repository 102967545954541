import { useEffect, useState } from 'react';
import { EXCHANGE_INTENTS } from '@libs/utils/exchange';
import { useLocale } from '@libs/contexts/locale';
import gql from '@libs/utils/gql';
import { makeOffer, placeBid, fixedPricePurchase } from '@graphql/mutations';
import { getBidsFromExchange, MINIMUM_BID_AMOUNT } from '@libs/utils/checkout';

export const useStartingCustomPrice = ({
  exchange,
  selectedExchangeIntent,
  currentBidExchange
}) => {
  const { roundPrice } = useLocale();

  switch (selectedExchangeIntent) {
    case EXCHANGE_INTENTS.FIXED_PRICE_PURCHASE:
      return roundPrice(exchange?.currentPrice ?? 0);
    case EXCHANGE_INTENTS.MAKE_OFFER:
      return roundPrice(exchange?.offerPrice ?? 0);
    case EXCHANGE_INTENTS.PLACE_BID:
      const { nextBid } = getBidsFromExchange(currentBidExchange ?? exchange);
      return roundPrice(nextBid ?? MINIMUM_BID_AMOUNT);
    default:
      return roundPrice(exchange?.currentPrice ?? 0);
  }
};

export const useCheckoutInputData = ({
  email,
  firstName,
  lastName,
  password,
  exchange,
  currentBidExchange,
  selectedExchangeIntent,
  paymentMethod
}) => {
  const [customPrice, setCustomPrice] = useState(
    useStartingCustomPrice({ exchange, selectedExchangeIntent, currentBidExchange })
  );
  const [checkoutInputData, setCheckoutInputData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    customPrice,
    paymentMethod: 'CREDIT_CARD'
  });
  const [shouldUpdateCheckoutInputData, setShouldUpdateCheckoutInputData] = useState(false);

  const updateCheckoutInputData = () => {
    setShouldUpdateCheckoutInputData(true);
  };

  useEffect(() => {
    if (shouldUpdateCheckoutInputData) {
      setCheckoutInputData({ firstName, lastName, email, password, customPrice, paymentMethod });
      setShouldUpdateCheckoutInputData(false);
    }
  }, [
    customPrice,
    email,
    firstName,
    lastName,
    password,
    shouldUpdateCheckoutInputData,
    paymentMethod
  ]);

  return { customPrice, setCustomPrice, checkoutInputData, updateCheckoutInputData };
};

const createQuery = (excType) => {
  switch (excType) {
    case EXCHANGE_INTENTS.MAKE_OFFER:
      return { query: makeOffer, name: 'makeOffer' };
    case EXCHANGE_INTENTS.PLACE_BID:
      return { query: placeBid, name: 'placeBid' };
    case EXCHANGE_INTENTS.FIXED_PRICE_PURCHASE:
    default:
      return { query: fixedPricePurchase, name: 'fixedPricePurchase' };
  }
};

export const useBitPayCheckout = () => {
  const [loading, setLoading] = useState(false);

  const onCheckout = async (excType, input) => {
    setLoading(true);
    try {
      const {
        token,
        exchangeID,
        currencyCode,
        email,
        firstName,
        lastName,
        paymentMethod,
        customPrice
      } = input;

      const gqlQuery = createQuery(excType);
      const res = await gql(
        gqlQuery.query,
        {
          input: {
            token,
            exchangeID,
            currencyCode,
            email,
            firstName,
            lastName,
            paymentMethod,
            customPrice
          }
        },
        {
          authMode: email ? 'API_KEY' : 'AMAZON_COGNITO_USER_POOLS'
        }
      );

      const bitPayRes = JSON.parse(res.data[gqlQuery.name]);
      return bitPayRes?.body;
    } catch (error) {
      console.error();
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    onCheckout
  };
};
