import { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { IoEllipsisVertical } from 'react-icons/io5';

import { Box, Container, Heading, Link, NotificationMenuItem, Text } from '@components';
import { useOutsider } from '@libs/utils/outsider';
import { ReactComponent as NotificationIcon } from '@assets/svg/notification.svg';
import { useUpdateReadNotification } from '@libs/hooks/notification';
import { useNotifications } from '@libs/contexts/notification';

function Notifications() {
  const { t } = useTranslation();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const handleMenuClose = () => setMenuOpen(false);
  const { ref } = useOutsider(handleMenuClose);
  const { notifications, readAllNotifications } = useNotifications();

  const handleMenuOpen = useCallback(
    (e) => {
      e.preventDefault();
      setMenuOpen(!isMenuOpen);
    },
    [isMenuOpen]
  );

  const todayNotifications = [];
  const olderNotifications = []; // notifications.filter((notification) => ;

  function getDifferenceInDays(date1, date2) {
    const diffInMs = Math.abs(date2 - date1);
    return diffInMs / (1000 * 60 * 60 * 24);
  }

  const now = new Date();
  notifications?.forEach((notification) => {
    const notificationDate = new Date(notification?.createdAt);
    if (getDifferenceInDays(now, notificationDate) >= 1) {
      olderNotifications.push(notification);
    } else {
      todayNotifications.push(notification);
    }
  });
  const { onUpdateNotification } = useUpdateReadNotification();
  const onUpdate = async (e) => {
    e.preventDefault();
    handleMenuClose();
    readAllNotifications();
    const data = {
      itemType: 'user'
    };
    await onUpdateNotification(data);
  };

  return (
    <>
      <Container size="sm" className="all-notifications">
        <Box className="all-notifications__header">
          <Heading as="h3" className="all-notifications__heading">
            {t('allNotifications.title')}
          </Heading>
          <Text className="notification-menu" onClick={handleMenuOpen}>
            <IoEllipsisVertical />
          </Text>
          {isMenuOpen && (
            <Box ref={ref} className="notification-menu__options">
              <Link to="#" onClick={onUpdate}>
                {t('components.notificationMenu.options.markAllRead')}
              </Link>
              <Link to="/settings/notifications/">
                {t('components.notificationMenu.options.settings')}
              </Link>
            </Box>
          )}
        </Box>
        {todayNotifications.length > 0 && (
          <Box className="notifications-group">
            <Text className="notifications-group__name">{t('allNotifications.today')}</Text>
            <Box className="notifications-group__items">
              {todayNotifications?.map(function (notification, i) {
                return (
                  <NotificationMenuItem
                    key={i}
                    index={i}
                    notification={notification}
                    className="notifications-group-item"
                  />
                );
              })}
            </Box>
          </Box>
        )}

        {olderNotifications.length > 0 && (
          <Box className="notifications-group">
            <Text className="notifications-group__name">{t('allNotifications.older')}</Text>
            <Box className="notifications-group__items">
              {olderNotifications.map(function (notification, i) {
                return (
                  <NotificationMenuItem
                    key={i}
                    index={i}
                    notification={notification}
                    className="notifications-group-item"
                  />
                );
              })}
            </Box>
          </Box>
        )}
        {notifications?.length === 0 && (
          <Box className="notification-empty">
            <Box className="notification-empty__icon">
              <NotificationIcon />
            </Box>
            <Text className="notification-empty__title">
              {t('components.notificationMenu.empty.title')}
            </Text>
            <Text className="notification-empty__body">
              {t('components.notificationMenu.empty.body')}
            </Text>
          </Box>
        )}
      </Container>
      <Helmet>
        <title>{t('allNotifications.title')}</title>
        <meta property="og:image" content="https://cdn.fans.inc/public/webapp/og-image.png" />
      </Helmet>
    </>
  );
}

export default Notifications;
