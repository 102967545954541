import cx from 'classnames';
import { Text } from '@components';

const Button = ({
  outline,
  whiteOutline,
  darkOutline,
  link,
  fullWidth,
  size,
  as,
  loading,
  children,
  className,
  success,
  warning,
  danger,
  info,
  form,
  selected,
  ...props
}) => {
  const activeType = link
    ? 'link'
    : whiteOutline
    ? 'whiteOutline'
    : darkOutline
    ? 'darkOutline'
    : success
    ? 'success'
    : danger
    ? 'danger'
    : warning
    ? 'warning'
    : info
    ? 'info'
    : form
    ? 'form'
    : outline
    ? 'outline'
    : 'default';

  const defaultStyle = {
    btn: true,
    'btn--md': true,
    'btn--lg': size === 'lg',
    'btn--sm': size === 'sm',
    'btn--xl': size === 'xl',
    'w-full': fullWidth
  };

  const buttonTypeStyle = {
    default: 'btn--default',
    outline: 'btn--outline',
    whiteOutline: 'btn--white-outline',
    darkOutline: 'btn--dark-outline',
    link: 'btn--link',
    success: outline ? 'btn--success--outline' : 'btn--success',
    danger: outline ? 'btn--danger--outline' : 'btn--danger',
    warning: outline ? 'btn--warning--outline' : 'btn--warning',
    info: outline ? 'btn--info--outline' : 'btn--info',
    form: 'btn--form'
  };

  const loadingClass = {
    isLoading: loading
  };

  return (
    <button
      className={cx(
        defaultStyle,
        buttonTypeStyle[activeType],
        loadingClass,
        selected ? 'btn-selected' : '',
        className
      )}
      type={as}
      {...props}
    >
      {link ? (
        <Text as="span" className="btn--link__text">
          {children}
        </Text>
      ) : (
        children
      )}
    </button>
  );
};

Button.defaultProps = {
  as: 'button',
  loading: false,
  link: false
};

export default Button;
