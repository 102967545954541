import { useState } from 'react';
import gql from '@libs/utils/gql';
import useToast from '@libs/utils/toast';
import { makeOfferV2, fixedPricePurchaseV2, acceptOffer } from '@graphql/mutations';
import handleGraphQLErrors from '@libs/utils/errors/graphql';

const useBitPayCheckout = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const onPurchase = async (input) => {
    setLoading(true);
    try {
      const { token, exchangeID, currencyCode, email, firstName, lastName, paymentMethod } = input;

      const res = await gql(
        fixedPricePurchaseV2,
        {
          input: {
            token,
            exchangeID,
            currencyCode,
            email,
            firstName,
            lastName,
            paymentMethod
          }
        },
        {
          authMode: email ? 'API_KEY' : 'AMAZON_COGNITO_USER_POOLS'
        }
      );

      const gqlRes = JSON.parse(res.data.fixedPricePurchaseV2);
      if (gqlRes?.statusCode !== 200) {
        toast(gqlRes.body.message, 'error');
        return;
      }

      return gqlRes?.body;
    } catch (error) {
      console.error(error);
      const handledError = handleGraphQLErrors(error);
      const errorMessage = handledError?.messages[0];
      toast(errorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  const onMakeOffer = async (input) => {
    setLoading(true);
    try {
      const {
        token,
        exchangeID,
        currencyCode,
        email,
        firstName,
        lastName,
        paymentMethod,
        customPrice
      } = input;

      const res = await gql(
        makeOfferV2,
        {
          input: {
            token,
            exchangeID,
            currencyCode,
            email,
            firstName,
            lastName,
            paymentMethod,
            customPrice
          }
        },
        {
          authMode: email ? 'API_KEY' : 'AMAZON_COGNITO_USER_POOLS'
        }
      );

      const gqlRes = JSON.parse(res.data.makeOfferV2);
      if (gqlRes?.statusCode !== 200) {
        toast(gqlRes.body.message, 'error');
        return;
      }

      return gqlRes?.body;
    } catch (error) {
      console.error(error);
      const handledError = handleGraphQLErrors(error);
      const errorMessage = handledError?.messages[0];
      toast(errorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  const onPayOffer = async (offerID) => {
    setLoading(true);
    try {
      const payload = {
        input: {
          exchangeID: offerID
        }
      };
      const res = await gql(acceptOffer, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });

      const bitPayRes = JSON.parse(res.data.acceptOffer);
      if (bitPayRes?.statusCode !== 200) {
        toast(bitPayRes.body.message, 'error');
        return;
      }

      return bitPayRes.body;
    } catch (error) {
      console.error(error);
      const handledError = handleGraphQLErrors(error);
      const errorMessage = handledError?.messages[0];
      toast(errorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    onPurchase,
    onMakeOffer,
    onPayOffer
  };
};

export default useBitPayCheckout;
