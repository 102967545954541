import { Helmet } from 'react-helmet';
import { useTranslation, Trans } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Box, Button, Container, FormField, Heading, Input, Text, TextArea } from '@components';
import { useInbox } from '@libs/hooks/inbox';

function Contact() {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField
  } = useForm({
    mode: 'onBlur'
  });

  const { onCreate, createLoading } = useInbox();

  const supportEmail = 'support@fans.inc';
  const onSubmit = async (values) => {
    await onCreate(values);
    resetField('email');
    resetField('message');
  };

  return (
    <>
      <Box className="page-contact">
        <Container>
          <Box className="contact">
            <Heading as="h3" className="contact__heading">
              {t('contact.heading')}
            </Heading>
            <Text className="contact__description">
              <Trans i18nKey="contact.description">
                <a href={`mailto:${supportEmail}`}>{{ supportEmail }}</a>
              </Trans>
            </Text>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <FormField>
                <Input
                  fullWidth
                  label={t('contact.fields.email.label')}
                  placeholder={t('contact.fields.email.placeholder')}
                  as="email"
                  errorMessage={errors.email?.message}
                  {...register('email', {
                    required: { value: true, message: t('contact.fields.email.required') },
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: t('contact.fields.email.invalid')
                    }
                  })}
                />
              </FormField>
              <FormField>
                <TextArea
                  fullWidth
                  label={t('contact.fields.message.label')}
                  placeholder={t('contact.fields.message.placeholder')}
                  errorMessage={errors.message?.message}
                  {...register('message', {
                    required: { value: true, message: t('contact.fields.message.required') }
                  })}
                />
              </FormField>
              <Button primary as="submit" loading={createLoading}>
                {t('contact.submit')}
              </Button>
            </form>
          </Box>
        </Container>
      </Box>
      <Helmet>
        <title>{t('contact.title')}</title>
        <meta property="og:image" content="https://cdn.fans.inc/public/webapp/og-image.png" />
      </Helmet>
    </>
  );
}

export default Contact;
