import { Box } from '@components';
import cx from 'classnames';

const PatronsSeparator = ({ className, dark = false, ...props }) => {
  return (
    <Box className={cx('separator', className, dark ? 'separator-dark' : '')} {...props}></Box>
  );
};

export default PatronsSeparator;
