import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Link, Text, Button } from '@components';
import { getNftUrl, NFT_MARKETPLACES, LISTING_TYPES } from '@libs/utils/exchange';
import { ReactComponent as OpenSeaIcon } from '@assets/svg/opensea.svg';

const ViewOnOpenSea = ({ exchange, isButton }) => {
  const { t } = useTranslation();
  const [openSeaUrl, setOpenSeaUrl] = useState(null);

  useEffect(() => {
    if (exchange && exchange.listingType === LISTING_TYPES.NFT) {
      setOpenSeaUrl(getNftUrl({ exchange, marketplace: NFT_MARKETPLACES.OPENSEA_TESTNETS_MUBAI }));
    }
  }, [exchange]);

  if (!openSeaUrl) {
    return null;
  }

  return (
    <>
      <Link to={openSeaUrl} target="_blank" underlined={isButton ? false : 'white'}>
        {isButton ? (
          <Button>
            <OpenSeaIcon style={{ display: 'inline', marginRight: 10 }} />
            {t('components.viewOnOpenSea.text')}
          </Button>
        ) : (
          <Text>{t('components.viewOnOpenSea.text')}</Text>
        )}
      </Link>
    </>
  );
};

export default ViewOnOpenSea;
