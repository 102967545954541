import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container } from '@components';
import Release from './components/release';
import Collectible from './components/collectible';

function ProfileCollectibles({ profile }) {
  const params = useParams();
  const username = params.username.toLowerCase();
  const { t } = useTranslation();

  let collectible;
  if (profile) {
    if (profile?.verified !== 'TRUE') {
      collectible = <Release userID={profile?.id} />;
    } else {
      collectible = <Collectible username={username} />;
    }
  }

  return (
    <>
      <Container size="xl" className="py-14">
        {collectible}
      </Container>
      <Helmet>
        <title>{t('profile.collectibles.title')}</title>
        <link rel="canonical" href={'https://fans.inc' + window.location.pathname} />
        <meta name="URL" content={'https://fans.inc' + window.location.pathname} />
      </Helmet>
    </>
  );
}

export default ProfileCollectibles;
