import FansincConnectWalletButton from './fansinc';
import PatronsConnectWalletButton from './patrons';

function ConnectWalletButton({
  walletAddress,
  setWalletAddress,
  setHasError,
  inUserMenu = false,
  disableSubmit = false
}) {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return (
      <PatronsConnectWalletButton
        walletAddress={walletAddress}
        setWalletAddress={setWalletAddress}
        setHasError={setHasError}
        inUserMenu={inUserMenu}
        disableSubmit={disableSubmit}
      />
    );
  } else {
    return (
      <FansincConnectWalletButton
        walletAddress={walletAddress}
        setWalletAddress={setWalletAddress}
        setHasError={setHasError}
        inUserMenu={inUserMenu}
        disableSubmit={disableSubmit}
      />
    );
  }
}

export default ConnectWalletButton;
