import cx from 'classnames';
import { useEffect, useState, useRef } from 'react';
import ReactPlayer from 'react-player/lazy';
import { IoPlaySharp } from 'react-icons/io5';
import Slider from 'react-input-slider';
import { ArcText } from '@arctext/react';
import { Box, Image } from '@components';
import Stick from '@assets/img/stick.png';
import Overlay from '@assets/img/overlay.png';
import { ReactComponent as Vinyl } from '@assets/svg/vinyl.svg';
import { fetchImage, fetchAssets } from './hooks';
import { getUserName } from '@libs/utils/user';

const MediaVinyl = ({ collectible, mime, owner, edition }) => {
  const [image, setImage] = useState();
  const [count, setCount] = useState(0);
  const [collectibleImageClass, setCollectibleImageClass] = useState('');
  const [ownerImageClass, setOwnerImageClass] = useState('');
  const [playerSrc, setPlayerSrc] = useState();
  const [playing, setPlaying] = useState(false);
  const [curvedSize, setCurvedSize] = useState(530);
  const [curvedTextSize, setCurvedTextSize] = useState(3.2);
  const casseteRef = useRef(null);
  const [played, setPlayed] = useState(false);
  const player = useRef();
  const [playerData, setPlayerData] = useState({
    played: 0,
    loaded: 0,
    duration: 0,
    seeking: false,
    volume: 1,
    muted: false
  });

  useEffect(() => {
    fetchImage({
      image: collectible?.mediaThumbnail,
      setImage
    });
    fetchAssets({
      assets: collectible?.assetUrl,
      setAssets: setPlayerSrc
    });
  }, [collectible?.mediaThumbnail, collectible?.assetUrl, mime]);

  const handleProgress = (state) => {
    // We only want to update time slider if we are not currently seeking
    if (!playerData.seeking) {
      setPlayerData({ ...playerData, ...state });
    }
  };

  const handleDuration = (duration) => {
    setPlayerData({ ...playerData, duration });
  };

  const handleEnded = () => setPlaying(false);
  // const handleResize = () => {
  //   if (casseteRef?.current) {
  //     // console.log(casseteRef?.current?.getBoundingClientRect());
  //     const size = casseteRef?.current?.getBoundingClientRect();
  //     setCurvedSize(size.width + 50);
  //   }
  // };

  useEffect(() => {
    if (casseteRef?.current) {
      // console.log(casseteRef?.current?.getBoundingClientRect());
      const size = casseteRef?.current?.getBoundingClientRect();
      setCurvedSize(size.width + 50);
    }
  }, []);

  // window.addEventListener('resize', handleResize);

  useEffect(() => {
    if (playing) {
      const id = setInterval(() => setCount((oldCount) => oldCount + 1), 1000);

      return () => {
        clearInterval(id);
      };
    }
  }, [playing]);

  useEffect(() => {
    if (count > 0) {
      if (count % 7 === 0) {
        if (count % 2 !== 0) {
          setOwnerImageClass('media__vinyl__cassette__img-in');
          setCollectibleImageClass('media__vinyl__cassette__img-out opacity-0');
        } else {
          setOwnerImageClass('media__vinyl__cassette__img-out');
          setCollectibleImageClass('media__vinyl__cassette__img-in');
        }
      }
    }
  }, [count]);
  return (
    <>
      <Box className="relative">
        <ReactPlayer
          ref={(value) => {
            player.current = value;
          }}
          width={played ? 0 : '100%'}
          height={played ? 0 : '100%'}
          playing={playing}
          url={playerSrc}
          onProgress={handleProgress}
          onDuration={handleDuration}
          onEnded={handleEnded}
          volume={playerData.volume}
          muted={playerData.muted}
          onClickPreview={(e) => {
            setPlaying(!playing);
            e.preventDefault();
          }}
        />

        <Box className={cx('media__vinyl')}>
          <Box className="media__vinyl__curved media__vinyl__curved__edition">
            <ArcText
              text={
                !edition
                  ? 'LIMITED EDITION'
                  : edition === 1
                  ? 'DIGITAL ORIGINAL'
                  : `LIMITED EDITION #${edition}`
              }
              width={curvedSize}
              characterWidth={curvedTextSize}
            />
          </Box>
          <Box className="media__vinyl__curved media__vinyl__curved__owner">
            <ArcText
              upsideDown
              text={
                getUserName(owner?.firstName)
                  ? `${owner.firstName} ${owner.lastName}`
                  : 'YOUR NAME HERE'
              }
              width={curvedSize}
              characterWidth={curvedTextSize}
            />
          </Box>
          <Box className={cx('media__vinyl__stick', playing && 'media__vinyl__stick-play')}>
            <img src={Stick} alt="" />
          </Box>
          <IoPlaySharp
            className={cx('media__vinyl__play', playing && 'hidden')}
            style={{ width: 34, height: 34 }}
            width={34}
            height={34}
            onClick={() => setPlaying(!playing)}
          />
          <Box
            className={cx(
              'media__vinyl__box media__vinyl__box-spin',
              !playing && 'media__vinyl__box-spin-paused'
            )}
          >
            <Box className={cx('media__vinyl__cassette')} onClick={() => setPlaying(!playing)}>
              <Vinyl ref={casseteRef} className={cx('media__vinyl__cassette__box')} />
              <Box>
                {owner && (
                  <Image
                    className={cx(
                      'media__vinyl__cassette__img',
                      playing && ownerImageClass,
                      !playing && 'media__vinyl__cassette__img-in-paused'
                    )}
                    size={400}
                    src={
                      owner?.media?.avatarUrl || 'https://cdn.fans.inc/public/images/fallback.png'
                    }
                    alt={owner?.firstName}
                  />
                )}
                <img
                  className={cx(
                    'media__vinyl__cassette__img',
                    owner && collectibleImageClass,
                    owner && !playing && 'media__vinyl__cassette__img-out-paused'
                  )}
                  src={image || collectible?.mediaThumbnail}
                  alt={collectible?.title}
                />
                {!playing && (
                  <img
                    className={cx('media__vinyl__cassette__img')}
                    src={Overlay}
                    alt={collectible?.title}
                  />
                )}
              </Box>
            </Box>
          </Box>
          <Box className="media__vinyl__control">
            <Slider
              className="player__controls__seek"
              min={0}
              axis="x"
              x={playerData.duration * playerData.played}
              xmax={playerData.duration}
              xstep={1}
            />
            <Box
              className="player__controls__loaded"
              style={{ width: `${100 * playerData.loaded}%` }}
            ></Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

MediaVinyl.defaultProps = {
  full: false,
  showPlayer: true,
  showPlayerSlider: false
};

export default MediaVinyl;
