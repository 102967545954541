export const listSettings = /* GraphQL */ `
  query ListSettings($filter: ModelSettingsFilterInput, $limit: Int, $nextToken: String) {
    listSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        whitelisted_email
        _version
      }
    }
  }
`;
