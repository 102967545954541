/* eslint-disable */
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { Box, Hero } from '@components';
import { useExchangesBySlugAndSerialIndex } from '@libs/hooks/exchange';
import { useAuth } from '@libs/contexts/auth';
import { EXCHANGE_TYPES, STATUS_TYPES } from '@libs/utils/exchange';

import SellForm from './form/index';
import SellLandingGallery from './landing/index';

import { ReactComponent as SpinnerIcon } from '@assets/svg/spinner.svg';

function Sell() {
  const { slug, edition } = useParams();
  const { t } = useTranslation();
  const { user, loading: isLoadingUser } = useAuth();

  const { data: exchange, loading: isLoadingExchange } = useExchangesBySlugAndSerialIndex({
    slug: slug,
    serialIndex: edition,
    filter: {
      searchableIsSold: { eq: 'FALSE' },
      searchableExchangeType: { eq: EXCHANGE_TYPES.LISTING },
      searchableStatus: { ne: STATUS_TYPES.TRANSFERRED_TO_NFT }
    }
  });

  const [isEditable, setEditable] = useState(false);

  useEffect(() => {
    setEditable(user && edition && user && exchange?.currentOwnerID === user?.id);
    // eslint-disable-next-line
  }, [exchange, user]);

  return (
    <>
      <Hero heading={t('sell.title')} text={t('sell.description')} showBack fadeRight fadeBottom />
      <Box className="sell">
        {isLoadingExchange || isLoadingUser ? (
          <SpinnerIcon className="loading" />
        ) : isEditable ? (
          <SellForm exchange={exchange} />
        ) : (
          <SellLandingGallery />
        )}
      </Box>
      <Helmet>
        <title>{t('sell.title')}</title>
        <meta property="og:image" content="https://cdn.fans.inc/public/webapp/og-image.png" />
      </Helmet>
    </>
  );
}

export default Sell;
