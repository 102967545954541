/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { FiPackage } from 'react-icons/fi';

import useToast from '@libs/utils/toast';
import { generateQR } from '@libs/utils/qrcode';
import { Button } from '@components';
import { createAssetUrl } from '@libs/utils/media';
import { FORMAT_TYPE, mediaChecker } from '@libs/utils/media';

import CollectibleDocument from '@components/print-collectible/collectible-document';
import LandscapeDocument from '@components/print-collectible/landscape-document';
import { orientations, aspectRatios, documentSizeKeys } from '@components/print-collectible/utils';

import stub from '@assets/img/print-collectible/stub-portrait-1.png';

const orientationChecker = (url) => {
  return new Promise((resolve, reject) => {
    const image = document.createElement('img');
    image.src = url;

    image.onerror = () => {
      reject(new Error('Cannot load image.'));
    };

    image.addEventListener('load', () => {
      const { width, height } = image;
      let orientation = orientations.PORTRAIT;
      let aspectRatio = aspectRatios.PORTRAIT;
      if (height < width) {
        orientation = orientations.LANDSCAPE;
        aspectRatio = aspectRatios.LANDSCAPE;
      }

      resolve([orientation, aspectRatio]);
    });
  });
};

export const getCollectiblePrint = async ({
  collectible,
  exchange,
  url,
  useStub = false,
  documentSize,
  imageSize
}) => {
  const collectibleDetail = collectible ?? exchange.collectible;
  const collectibleType = mediaChecker(collectibleDetail?.assetUrl);
  let s3key = '';
  if (useStub) {
    s3key = stub;
  } else {
    if (collectibleType === FORMAT_TYPE.VIDEO || collectibleType === FORMAT_TYPE.AUDIO) {
      s3key = createAssetUrl(collectibleDetail?.mediaThumbnail, imageSize);
    } else {
      s3key = createAssetUrl(collectibleDetail?.assetUrl, imageSize);
    }
  }
  const qrCode = await generateQR(`https://www.fans.inc${url}`);

  let orientation = orientations.PORTRAIT;
  let aspectRatio = aspectRatios.PORTRAIT;
  try {
    [orientation, aspectRatio] = await orientationChecker(s3key);
  } catch (e) {
    console.error(e);
  }

  const CollectiblePrintDocument =
    orientation === orientations.PORTRAIT ? (
      <CollectibleDocument
        collectible={collectible}
        exchange={exchange}
        qrCode={qrCode}
        url={url}
        image={s3key}
        size={documentSize ?? documentSizeKeys.A_ONE}
      />
    ) : (
      <LandscapeDocument
        collectible={collectible}
        exchange={exchange}
        qrCode={qrCode}
        url={url}
        image={s3key}
        size={documentSize ?? documentSizeKeys.A_ONE}
      />
    );
  return { orientation, aspectRatio, CollectiblePrintDocument };
};

const PrintButton = ({
  collectible,
  exchange,
  url,
  children,
  imageSize,
  documentSize,
  useStub
}) => {
  const { t } = useTranslation();
  const [document, setDocument] = useState(null);
  const toast = useToast();

  const [loading, setLoading] = useState(false);

  const getPrintFilename = ({ exchange, collectible }) => {
    const { title } = collectible ?? exchange.collectible;
    const serialIndex = exchange?.serialIndex ?? 1;
    return `${title}-print-edition-${serialIndex}`;
  };

  useEffect(() => {
    const generateDoc = async () => {
      if (!loading) {
        try {
          setLoading(true);
          const { CollectiblePrintDocument } = await getCollectiblePrint({
            collectible,
            exchange,
            url,
            useStub,
            imageSize: 'full',
            documentSize
          });
          setDocument(CollectiblePrintDocument);
          setLoading(false);
        } catch (error) {
          toast(t('components.collectibleCard.ownerMenu.error.downloadPrint'), 'error');
          console.error(error);
        }
      }
    };

    generateDoc();
    // eslint-disable-next-line
  }, [collectible, documentSize, exchange, imageSize, url, useStub]);

  const btnDefault = (
    <PDFDownloadLink document={document} fileName={getPrintFilename({ exchange, collectible })}>
      {({ blob, url, loading: loadingDocument, error }) => (
        <Button>
          {loadingDocument ? 'Loading' : t('confirmation.utility.orderPrint')}
          <FiPackage />
        </Button>
      )}
    </PDFDownloadLink>
  );

  const btnCustom = (
    <PDFDownloadLink document={document} fileName={getPrintFilename({ exchange, collectible })}>
      {({ blob, url, loading, error }) => {
        return children;
      }}
    </PDFDownloadLink>
  );
  const btnPrint = children ? btnCustom : btnDefault;

  return btnPrint;
};

const PrintCollectible = ({
  collectible,
  exchange,
  url,
  imageSize,
  documentSize,
  useStub,
  children
}) => {
  return (
    <div>
      <PrintButton
        collectible={collectible}
        exchange={exchange}
        url={url}
        imageSize={imageSize}
        documentSize={documentSize}
        useStub={useStub}
      >
        {children}
      </PrintButton>
    </div>
  );
};

export default PrintCollectible;
