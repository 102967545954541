import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box } from '@components';

const CollectibleTooltip = ({
  onShow,
  arrow,
  label,
  className,
  options,
  children,
  capitalize,
  ...props
}) => {
  const { t } = useTranslation();
  const tooltipMapper = {
    left: 'collectible-tooltip-left',
    right: 'collectible-tooltip-right',
    bottom: 'collectible-tooltip-bottom'
  };

  const tooltipDefault = {
    'collectible-tooltip-content': true,
    'collectible-tooltip-content-show': onShow
  };

  return (
    <Box className={cx('collectible-tooltip', tooltipMapper[arrow], className)}>
      <Box
        className={cx(tooltipDefault, options && 'whitespace-nowrap', capitalize && 'capitalize')}
      >
        {label || children}{' '}
        {!options && !children && t('components.collectibleTooltip.collectible')}
        <div className="collectible-tooltip-arrow"></div>
      </Box>
    </Box>
  );
};

CollectibleTooltip.defaultProps = {
  arrow: 'left',
  onShow: false
};

export default CollectibleTooltip;
