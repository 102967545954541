import awsConfig from './aws-exports';
import awsVideoConfig from './aws-video-exports';

const env = process.env.REACT_APP_AMPLIFY_ENV;
const captchaKey = process.env.REACT_APP_TOKEN_SITE_KEY_CAPTCHA;
const captchaContactKey = process.env.REACT_APP_TOKEN_SITE_KEY_CAPTCHA_CONTACT;
const fansPublicAppUrl = process.env.REACT_APP_CURRENT_PUBLIC_APP_URL;
const cdnUrl = process.env.REACT_APP_CDN_URL;
const checkoutPublicKey = process.env.REACT_APP_CHECKOUT_PUBLIC_KEY;
const fansWallet = process.env.REACT_APP_FANS_INC_WALLET;
const sentryDSN = process.env.REACT_APP_SENTRY_DSN;
const gtmID = process.env.REACT_APP_GTM_ID;

const config = {
  cookie: {
    USER_ACTIVATION: 'userActivation',
    SANDBOX_NOTIFICATION: 'sandboxNotification',
    ALLOW_COOKIES: 'allowCookies',
    NECESSARY_COOKIES: 'necessaryCookies',
    ANALYTICS_COOKIES: 'analyticsCookies',
    TARGETING_COOKIES: 'targetingCookies',
    ACTIVATE_USERNAME: 'subAlt',
    FORGOT_PASS: 'forgotPassword',
    TOKEN_PREFIX: 'tokenPrefix',
    TOKEN_MAX_AGE: 'tokenMaxAge',
    TOKEN_SITE_KEY_CAPTCHA_CONTACT: captchaContactKey || '6LfTm6ckAAAAAGmCcepK4K3dB3PImlPuxZBx_Y9D',
    TOKEN_SITE_KEY_CAPTCHA: captchaKey || '6LcIFRIiAAAAAByS3PmbvzHBBazGpk4rziFpCMym',
    VIDEO_PREFIX: 'video_'
  },
  currentUrl: fansPublicAppUrl || `https://${env}.dkcaq2fjgc03l.amplifyapp.com`,
  cdnUrl: cdnUrl || 'https://d7zeymq3tadw6.cloudfront.net', // default to develop env's CDN
  checkoutPublicKey: checkoutPublicKey || 'pk_sbox_sv5avsrz4lghx3xqfqp3r4z3aia',
  facebookUrl: 'https://facebook.com/fans.inc',
  instagramUrl: 'https://instagram.com/fans.inc_official',
  twitterUrl: 'https://twitter.com/FANSINCofficial',
  fansWallet: fansWallet || '3deba0d8-2215-55d5-ae39-45a75a2338fd',
  sentryDSN,
  gtmID: gtmID || 'GTM-54JBLS7'
};

if (['develop', 'staging'].includes(env)) {
  console.info('Env vars:', process.env);
}

const signInUrl = awsConfig.oauth?.redirectSignIn?.split(',') ?? ['https://www.patrons.art/marketplace/'];
const signOutUrl = awsConfig.oauth?.redirectSignOut?.split(',') ?? ['https://www.patrons.art/marketplace/'];
const redirectSignIn = signInUrl.find((x) => x.indexOf(window.location.host) > -1);
const redirectSignOut = signOutUrl.find((x) => x.indexOf(window.location.host) > -1);

export const AWSConfig = {
  ...awsConfig,
  ...awsVideoConfig,
  aws_appsync_apiKey: 'da2-uarrwlveprd4vleclju5h5o6oy',
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn,
    redirectSignOut
  }
};

if (window.localStorage.getItem('debug-env') === '1') {
  console.log('AWSConfig', AWSConfig);
  console.log('AppConfig', config);
}

export default config;
