import cx from 'classnames';
import { MediaAudio, MediaImage, MediaVideo } from '@components';
import { FORMAT_TYPE, mediaChecker } from '@libs/utils/media';

const Media = ({
  as,
  full,
  collectible,
  url,
  className,
  showPlayer,
  size,
  thumbnail,
  borderThinner,
  showPlayerSlider,
  showExternalLink,
  ...props
}) => {
  const fullClass = full && 'media--full';

  const switchType = (as, mime) => {
    switch (as) {
      case FORMAT_TYPE.VIDEO:
        return (
          <MediaVideo
            full={full}
            showPlayer={showPlayer}
            collectible={collectible}
            mime={mime}
            showPlayerSlider={showPlayerSlider}
          />
        );
      case FORMAT_TYPE.AUDIO:
        return (
          <MediaAudio
            full={full}
            showPlayer={showPlayer}
            collectible={collectible}
            mime={mime}
            showPlayerSlider={showPlayerSlider}
            showExternalLink={showExternalLink}
          />
        );
      default:
        return (
          <MediaImage
            full={full}
            collectible={collectible}
            mime={mime}
            size={size}
            thumbnail={thumbnail}
          />
        );
    }
  };

  const defaultStyle = {
    media: true,
    'media-photograph': collectible?.category === 'PHOTOGRAPH' && !full,
    'media-photograph-thinner': collectible?.category === 'PHOTOGRAPH' && !full && borderThinner
  };

  return (
    <div className={cx(defaultStyle, fullClass, className)} {...props}>
      {collectible?.category === 'SONG' && collectible?.streamExternal === 'TRUE'
        ? switchType(FORMAT_TYPE.AUDIO, mediaChecker(collectible?.assetUrl, true))
        : switchType(
            mediaChecker(collectible?.assetUrl),
            mediaChecker(collectible?.assetUrl, true)
          )}
    </div>
  );
};

Media.defaultProps = {
  as: 'image',
  full: false,
  size: 800,
  showPlayer: false,
  thumbnail: false,
  borderThinner: false,
  showPlayerSlider: false
};

export default Media;
