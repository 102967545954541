import ReactQuill from 'react-quill';
import { useRef, forwardRef } from 'react';
const RichTextEditor = function RichTextEditor({
  value,
  onBlur,
  onChange,
  placeholder,
  onEnter,
  editForm,
  defaultValue
}) {
  const ref = useRef();
  const handleOnChange = (html, delta, source, editor) => {
    if (source === 'user' && onChange) {
      onChange(editor);
    }
  };
  const handleOnBlur = (range, source, editor) => {
    if (source === 'user' && onBlur) {
      onBlur(editor);
    }
  };
  const config = {
    toolbar: false
  };
  if (editForm) {
    return (
      <ReactQuill
        ref={ref}
        modules={config}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        onKeyDown={(e) => {
          e.code === 'Enter' &&
            !e.shiftKey &&
            onEnter &&
            onEnter(ref.current.unprivilegedEditor.getHTML());
        }}
      />
    );
  }
  return (
    <ReactQuill
      ref={ref}
      modules={config}
      value={value}
      placeholder={placeholder}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      onKeyDown={(e) =>
        e.code === 'Enter' &&
        !e.shiftKey &&
        onEnter &&
        onEnter(ref.current.unprivilegedEditor.getHTML())
      }
    />
  );
};
export default RichTextEditor;
