import { creatorPublicFragment } from '@libs/fragments/user';

export const listRelatedCreator = /* GraphQL */ `
  query ListRelatedCreator(
    $verified: CustomBooleanType
    $joinDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByVerifiedJoinDate(
      verified: $verified
      joinDate: $joinDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...CreatorPublicProfile
      }
    }
  }

  ${creatorPublicFragment}
`;

export default listRelatedCreator;
