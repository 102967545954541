import { forwardRef } from 'react';
import { Stage, Layer, Image, Rect, Text } from 'react-konva';
import { GetImage, imageSize } from './hooks';

const Template = forwardRef(function FlexImagePhonePlain(
  { collectibleImage, collectible, wrapperSize, collectionName, wrapperHeightSize },
  ref
) {
  const [image] = GetImage(collectibleImage);
  const { width, height } = imageSize(image, wrapperSize, wrapperHeightSize - 40);
  const backgroundHeight = (wrapperHeightSize * 15) / 100;
  const padding = (wrapperSize * 3) / 100;
  const titleSize = (wrapperSize * 3.6) / 100;
  const descriptionSize = (wrapperSize * 2.8) / 100;
  const spaceSize = (wrapperSize * 7) / 100;
  const galleriesWidth = (wrapperSize * 30) / 100;
  return (
    <>
      <Stage width={wrapperSize} height={wrapperHeightSize} className="flex-it-canvas" ref={ref}>
        <Layer>
          <Rect x={0} y={0} width={width} height={height} fill="white" />
          <Image
            x={-(width - wrapperSize) / 2}
            y={-(height - wrapperHeightSize) / 2 - backgroundHeight}
            image={image}
            height={height}
            width={width}
          />
          <Text
            x={padding}
            y={wrapperHeightSize - backgroundHeight + padding}
            fontSize={titleSize}
            fontFamily="Satoshi"
            text={collectible?.title}
            fill="#2B2B2B"
            fontStyle="bold"
          />
          <Text
            x={padding}
            y={wrapperHeightSize - padding * 2}
            fontSize={descriptionSize}
            fontFamily="Satoshi"
            text="By"
            fill="#5C5C5C"
          />
          <Text
            x={spaceSize}
            y={wrapperHeightSize - padding * 2}
            fontSize={descriptionSize}
            fontFamily="Satoshi"
            text={
              collectible?.creator?.displayName ||
              collectible?.creator?.firstName + ' ' + collectible?.creator?.lastName
            }
            fill="#5C5C5C"
            fontStyle="bold"
            textDecoration="underline"
          />
          {collectionName && (
            <Text
              x={wrapperHeightSize - galleriesWidth}
              y={wrapperHeightSize - padding * 2}
              fontSize={descriptionSize}
              fontFamily="Satoshi"
              text={`At ${collectionName}`}
              fill="#2B2B2B"
              fontStyle="bold"
              align="right"
              width={galleriesWidth}
              offsetX={padding}
            />
          )}
        </Layer>
      </Stage>
    </>
  );
});

export default Template;
