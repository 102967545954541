import { React } from 'react';
import { Switch, Route } from 'react-router-dom';
import Landing from './landing';
import TermsAndConditions from './terms';
import PrivacyPolicy from './privacy';
import Faq from './faq';
import { Box } from '@components';

function LandingWrapper() {
  return (
    <Box className="landing-new">
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route exact path="/early-access/terms-and-conditions" component={TermsAndConditions} />
        <Route exact path="/early-access/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/early-access/faq" component={Faq} />
      </Switch>
    </Box>
  );
}
export default LandingWrapper;
