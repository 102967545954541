export const categories = [
  { label: 'Artwork', value: 'ARTWORK' },
  { label: 'Photograph', value: 'PHOTOGRAPH' },
  { label: 'Song', value: 'SONG' },
  { label: 'Song Lyrics', value: 'SONG_LYRICS' },
  { label: 'Video', value: 'VIDEO' },
  { label: 'Other', value: 'OTHER' }
];

export const genres =
  process.env.REACT_APP_NAME === 'patrons'
    ? [
        { label: 'Cubist', value: 'CUBIST' },
        { label: 'Abstract', value: 'ABSTRACT' },
        { label: 'Avant-garde', value: 'AVANT_GARDE' },
        { label: 'Art Nouveau', value: 'ART_NOUVEAU' },
        { label: 'Baroque', value: 'BAROQUE' },
        { label: 'Bauhaus', value: 'BAUHAUS' },
        { label: 'Conceptual Art', value: 'CONCEPTUAL_ART' },
        { label: 'Cubism', value: 'CUBISM' },
        { label: 'Dada', value: 'DADA' },
        { label: 'Expressionism', value: 'EXPRESSIONISM' },
        { label: 'Modernism', value: 'MODERNISM' },
        { label: 'Contemporary Australian', value: 'CONTEMPORARY_AUSTRALIAN' },
        { label: 'Indigenous Australian', value: 'INDIGENOUS_AUSTRALIAN' },
        { label: 'Fluxus', value: 'FLUXUS' },
        { label: 'Other', value: 'OTHER' }
      ]
    : [
        { label: 'Adult Contemporary', value: 'ADULT_CONTEMPORARY' },
        { label: 'Alternative', value: 'ALTERNATIVE' },
        { label: 'Alt-Pop', value: 'ALT_POP' },
        { label: 'Alt-Rock', value: 'ALT_ROCK' },
        { label: 'Blues', value: 'BLUES' },
        { label: 'Classic Rock', value: 'CLASSIC_ROCK' },
        { label: 'Country', value: 'COUNTRY' },
        { label: 'Dance', value: 'DANCE' },
        { label: 'EDM', value: 'EDM' },
        { label: 'Electronic', value: 'ELECTRONIC' },
        { label: 'Folk', value: 'FOLK' },
        { label: 'Heavy Metal', value: 'HEAVY_METAL' },
        { label: 'Hip Hop', value: 'HIPHOP' },
        { label: 'Jazz', value: 'JAZZ' },
        { label: 'New Age', value: 'NEW_AGE' },
        { label: 'Other', value: 'OTHER' },
        { label: 'Pop', value: 'POP' },
        { label: 'Pop-Rock', value: 'POP_ROCK' },
        { label: 'Punk Rock', value: 'PUNK_ROCK' },
        { label: 'Rap', value: 'RAP' },
        { label: 'Reggae', value: 'REGGAE' },
        { label: 'Rock', value: 'ROCK' },
        { label: 'Spiritual', value: 'SPIRITUAL' },
        { label: 'Soul', value: 'SOUL' },
        { label: 'Urban', value: 'URBAN' }
      ];

export const listEras = [
  { label: '2020', value: '2020s' },
  { label: '2010', value: '2010s' },
  { label: '2000', value: '2000s' },
  { label: '1990', value: '1990s' },
  { label: '1980', value: '1980s' },
  { label: '1970', value: '1970s' }
];

export const listSort = [
  { name: 'date released (descending)', value: 'CREATED_AT_DESC' },
  { name: 'date released (ascending)', value: 'CREATED_AT_ASC' }
];
