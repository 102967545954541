export const urlParser = (data, type, showOwner = true, hideNumber = false) => {
  let url = null;
  if (type === 'collectible') {
    url = `/${data?.creator?.username}/${data?.slug}`;
  } else if (type === 'release' || type === 'exchange') {
    const collectible = data?.collectible;
    const creator = collectible?.creator || data?.creator;

    url = `/${creator?.username}/${data?.slug}`;
    if (!hideNumber) {
      url += `/${data?.serialIndex}/${showOwner ? data?.currentOwner?.username : ''}`;
    }
  }
  return url;
};

const defaultOptions = {
  type: 'collectible', // 'collectible' | 'release' | 'exchange'
  showOwner: true,
  hideNumber: false
};
export const urlParserV2 = (data, { type, showOwner, hideNumber } = defaultOptions) => {
  let url = null;
  if (type === 'collectible') {
    url = `/${data?.creator?.username}/${data?.slug}`;
  } else if (type === 'release' || type === 'exchange') {
    const collectible = data?.collectible;
    const creator = collectible?.creator || data?.creator;

    url = `/${creator?.username}/${data?.slug}`;
    if (!hideNumber) {
      url += `/${data?.serialIndex}/${showOwner ? data?.currentOwner?.username : ''}`;
    }
  }
  return url;
};

export const collectibleParser = (data, type) => {
  let collectible = null;
  if (type === 'collectible') {
    collectible = data;
  } else if (type === 'release' || type === 'exchange') {
    collectible = data?.collectible;
  }
  return collectible;
};
