import { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { Alert, Button, FormField, Heading, Input, Text, Box } from '@components';
import { useUser } from '@libs/hooks/user';
import config from '@/config';

function AuthResetPassword() {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    mode: 'onBlur'
  });
  const history = useHistory();
  const { success, error, resetPass, resendForgotPass, loading: formLoading } = useUser();
  const [cookies] = useCookies([config.cookie.USER_ACTIVATION]);
  const password = useRef({});
  password.current = watch('password', '');

  const onSubmit = (data) =>
    resetPass({
      code: data.code,
      newPassword: data.password
    });

  const onResend = () => resendForgotPass();

  if (!cookies[config.FORGOT_PASS]) {
    history.push('/forgot-password');
  }

  return (
    <div className="auth items-center">
      <Box className="auth__reset__title">{t('auth.resetPassword.heading')}</Box>
      <Box className="flex justify-center">
        <Box className="auth__reset__description">{t('auth.resetPassword.instruction')}</Box>
      </Box>
      <Alert className="text-lg text-left" danger>
        <Trans i18nKey="p.auth.resetPassword">
          <span className="font-bold">{{ email: cookies[config.FORGOT_PASS] }}</span>
        </Trans>
      </Alert>
      <div className="auth__form">
        {error && <Alert>{error}</Alert>}
        {success && (
          <Alert success className="mb-4">
            {success}
          </Alert>
        )}
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <FormField>
            <Input
              fullWidth
              label={t('auth.fields.verificationCode.label')}
              placeholder={t('auth.fields.verificationCode.placeholder')}
              errorMessage={errors.code?.message}
              {...register('code', {
                required: { value: true, message: t('auth.fields.verificationCode.required') }
              })}
            />
          </FormField>
          <FormField>
            <Input
              fullWidth
              placeholder={t('p.auth.fields.newPassword.placeholder')}
              label={t('p.auth.fields.newPassword.label')}
              as="password"
              errorMessage={errors.password?.message}
              {...register('password', {
                required: { value: true, message: t('p.auth.fields.newPassword.required') },
                pattern: {
                  value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                  message: t('auth.fields.password.strength')
                },
                minLength: { value: 8, message: t('auth.fields.password.minLength') },
                maxLength: { value: 128, message: t('auth.fields.password.maxLength') }
              })}
            />
            <Text className="auth__form__password-requirement">
              {t('auth.fields.password.requirements')}
            </Text>
          </FormField>
          <FormField>
            <Input
              fullWidth
              label={t('p.auth.fields.confirmPassword.label')}
              placeholder={t('p.auth.fields.confirmPassword.placeholder')}
              as="password"
              errorMessage={errors.confirm_password?.message}
              {...register('confirm_password', {
                required: { value: true, message: t('p.auth.fields.confirmPassword.required') },
                validate: (value) =>
                  value === password.current || t('auth.fields.confirmPassword.match')
              })}
            />
          </FormField>
          <div className="mb-4">
            <Button primary fullWidth as="submit" disabled={formLoading} loading={formLoading}>
              {t('auth.resetPassword.submit')}
            </Button>
          </div>
        </form>
      </div>
      <Button outline fullWidth onClick={onResend}>
        {t('auth.resetPassword.resend')}
      </Button>

      <Helmet>
        <title>{t('auth.resetPassword.pageTitle')}</title>
        <meta property="og:image" content="https://cdn.fans.inc/public/webapp/og-image.png" />
      </Helmet>
    </div>
  );
}

export default AuthResetPassword;
