import cx from 'classnames';

const Grid = ({ column, gap, center, children, className, ...props }) => {
  const columnMapper = {
    1: 'grid-cols-1',
    2: 'grid-cols-2',
    3: 'grid-cols-3',
    4: 'grid-cols-4',
    5: 'grid-cols-5',
    6: 'grid-cols-6',
    7: 'grid-cols-7',
    8: 'grid-cols-8',
    9: 'grid-cols-9',
    10: 'grid-cols-10',
    11: 'grid-cols-11',
    12: 'grid-cols-12'
  };

  const gapMapper = {
    no: 'gap-0',
    sm: 'gap-x-4',
    md: 'gap-x-8',
    lg: 'gap-x-12'
  };

  const defaultStyle = {
    grid: true,
    'items-center justify-center': center
  };

  return (
    <div className={cx(defaultStyle, columnMapper[column], gapMapper[gap], className)} {...props}>
      {children}
    </div>
  );
};

Grid.defaultProps = {
  gap: 'md',
  column: 12
};

export default Grid;
