import { useTransactionHistory } from '@libs/hooks/exchange';
import { EXCHANGE_TYPES } from '@libs/utils/exchange';
import { UserLink, Price } from '@components';

function GetHighestBid({ parentExchangeID, bidder }) {
  const now = new Date().toISOString();
  const { data } = useTransactionHistory({
    parentExchangeID: parentExchangeID,
    expiryDate: now,
    exchangeType: EXCHANGE_TYPES.BID
  });
  return (
    <>
      {bidder ? (
        <UserLink
          user={data?.[0]?.patron}
          key="user-link-from"
          exchange={data?.[0]}
          avatarSize="xs-40"
          showAddress
          showName={false}
        />
      ) : (
        <Price amount={data?.[0]?.bidPrice} />
      )}
    </>
  );
}

export default GetHighestBid;
