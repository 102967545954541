import React, { useState, useEffect, useRef } from 'react';
import { Frames, CardNumber, ExpiryDate, Cvv } from 'frames-react';
import { useTranslation } from 'react-i18next';

import config from '@/config';
import { Box, Text, Grid, Col } from '@components';
import { processPaymentPrintCollectibleResponse } from '@libs/utils/checkout';
import { usePurchasePrinted } from '@libs/hooks/prodigi';
import handleGraphQLErrors from '@libs/utils/errors/graphql';

const CARD_NUMBER_CHECKOUT_KEY = 'card-number';
const EXPIRY_DATE_CHECKOUT_KEY = 'expiry-date';
const CVV_CHECKOUT_KEY = 'cvv';
const INITIAL_FIELD_STATUS = {
  [CARD_NUMBER_CHECKOUT_KEY]: {
    isValid: true,
    isFocused: false,
    isEmpty: true,
    name: 'Card number'
  },
  [EXPIRY_DATE_CHECKOUT_KEY]: {
    isValid: true,
    isFocused: false,
    isEmpty: true,
    name: 'Expiry Date'
  },
  [CVV_CHECKOUT_KEY]: { isValid: true, isFocused: false, isEmpty: true, name: 'CVV' }
};

function PrintCheckoutFrameForm({
  submitForm,
  selectedShipping,
  setSubmitForm,
  color,
  copies,
  sku,
  exchangeID
}) {
  const { t } = useTranslation();
  const [fieldStatus, setFieldStatus] = useState(INITIAL_FIELD_STATUS);
  const [showValidationMessages, setShowValidationMessages] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [iframeUrl, setIframeUrl] = useState(null);
  const [error, setError] = useState('');
  const [consoleError, setConsoleError] = useState('');
  const { checkoutPublicKey } = config;
  const checkoutForm = useRef();
  const checkoutIframe = useRef();
  const { onPurchase } = usePurchasePrinted();

  useEffect(() => {
    const reportError = (message) => console.error(message);
    if (consoleError) {
      reportError(consoleError);
    }
  }, [consoleError]);

  useEffect(() => {
    if (redirectUrl) {
      window.location.assign(redirectUrl);
    }
  }, [redirectUrl]);

  useEffect(() => {
    const handleSubmit = () => {
      if (!Frames.isCardValid()) {
        // show validation errors
        setShowValidationMessages(true);
        setSubmitForm(false);
        return;
      }
      try {
        Frames.submitCard();
      } catch (error) {
        setError(error);
        Frames.init();
        setSubmitForm(false);
      }
    };

    if (submitForm) {
      handleSubmit();
    }
  }, [setSubmitForm, submitForm]);

  const handleCardTokenized = async (event, data) => {
    const { token } = event;
    if (token) {
      try {
        let response = null;
        // make payment here
        const purchasePrintedRes = await onPurchase({
          ...data,
          token
        });
        response = purchasePrintedRes?.data?.purchasePrintedCollectible;
        const paymentResponseParsed = response ? JSON.parse(response) : null;
        const onSuccess = (response) => {
          setRedirectUrl(response?.successUrl);
        };
        const onFail = (e) => {
          throw e;
        };
        const onRedirect = (redirectUrl) => setIframeUrl(redirectUrl);
        processPaymentPrintCollectibleResponse({
          paymentResponse: paymentResponseParsed,
          onSuccess,
          onFail,
          onRedirect
        });
      } catch (error) {
        setConsoleError(error);
        const handledError = handleGraphQLErrors(error);
        setError(handledError?.messages[0]);
        Frames.init();
      }
    }
    setSubmitForm(false);
  };

  const onFrameValidationChanged = (event) => {
    const { element, isValid, isEmpty } = event;
    setFieldStatus((prev) => ({ ...prev, [element]: { ...prev[element], isValid, isEmpty } }));
  };

  const onFrameFocus = (event) => {
    const { element } = event;
    setFieldStatus((prev) => ({ ...prev, [element]: { ...prev[element], isFocused: true } }));
  };

  const onFrameBlur = (event) => {
    const { element } = event;
    setFieldStatus((prev) => ({ ...prev, [element]: { ...prev[element], isFocused: false } }));
  };

  const checkoutConfig = {
    debug: false,
    publicKey: checkoutPublicKey,
    localization: {
      cardNumberPlaceholder: 'Card number',
      expiryMonthPlaceholder: 'MM',
      expiryYearPlaceholder: 'YY',
      cvvPlaceholder: '3 Digits'
    },
    style: {
      base: {
        marginBottom: '10px',
        color: 'black',
        border: '1px solid #e0e0e0',
        fontSize: '18px',
        padding: '8px 12px',
        borderRadius: '4px'
      },
      invalid: {
        color: 'red'
      },
      focus: {
        background: '#F5F5F5',
        border: '1px solid rgba(255, 67, 7, 0.4)'
      },
      placeholder: {
        base: {
          fontSize: '16px',
          color: '#A3A3A3'
        }
      }
    }
  };

  const ValidationError = ({ fieldKey }) => {
    const { isValid, isFocused, isEmpty, name } = fieldStatus[fieldKey];
    const getEmptyFieldMessage = (fieldName) => `${fieldName} cannot be empty`;
    const getValidationErrorMessage = (fieldName) => `${fieldName} is invalid`;

    let message = '';

    if (showValidationMessages && isEmpty && !isFocused) {
      message = getEmptyFieldMessage(name);
    } else if (showValidationMessages && !isValid && !isFocused) {
      message = getValidationErrorMessage(name);
    }

    return (
      <div className={`checkout_frame__validation_error ${message ? 'visible' : ''}`}>
        {message}
      </div>
    );
  };

  return (
    <>
      {iframeUrl ? (
        <iframe
          src={iframeUrl}
          title="checkout-3ds-iframe"
          sandbox="allow-top-navigation allow-scripts allow-same-origin allow-forms"
          style={{
            minHeight: '100vh',
            width: '100vw',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 1000,
            backgroundColor: 'rgba(255,255,255,0.5)'
          }}
          ref={checkoutIframe}
          onLoad={(e) => {
            try {
              e.target.contentWindow?.location?.href &&
                setRedirectUrl(e.target.contentWindow.location.href);
            } catch (e) {
              console.warn(e);
            }
          }}
        />
      ) : (
        <Box className="mt-4" ref={checkoutForm}>
          {checkoutPublicKey ? (
            <Frames
              config={checkoutConfig}
              cardTokenized={(e) =>
                handleCardTokenized(e, {
                  sku,
                  copies,
                  exchangeID,
                  color
                })
              }
              ready={() => {}}
              frameActivated={(e) => {}}
              frameFocus={onFrameFocus}
              frameBlur={onFrameBlur}
              frameValidationChanged={onFrameValidationChanged}
              paymentMethodChanged={(e) => {}}
              cardValidationChanged={(e) => {}}
              cardSubmitted={() => {}}
              cardTokenizationFailed={(e) => {
                setError(e);
                setSubmitForm(false);
              }}
              key={`checkout-frame-${sku}-${color}-${copies}-${exchangeID}`}
            >
              <label className="capitalize font-medium text-lg">
                {t('checkout.fields.cardNumber.label')}
              </label>
              <CardNumber className="mt-2" />
              <ValidationError fieldKey={CARD_NUMBER_CHECKOUT_KEY} />
              <Grid className="checkout__form__column-2">
                <Col>
                  <label
                    style={{ fontSize: '18px !important' }}
                    className="capitalize font-medium text-lg"
                  >
                    {t('checkout.fields.expirationDate.label')}
                  </label>
                  <ExpiryDate className="mt-2" />
                  <ValidationError fieldKey={EXPIRY_DATE_CHECKOUT_KEY} />
                </Col>
                <Col>
                  <label className="capitalize font-medium text-lg">
                    {t('checkout.fields.securityCode.label')}
                  </label>
                  <Cvv className="mt-2" />
                  <ValidationError fieldKey={CVV_CHECKOUT_KEY} />
                </Col>
              </Grid>
            </Frames>
          ) : (
            <Text className="text-red-400">Missing checkout key</Text>
          )}

          {redirectUrl && (
            <div className="my-5">
              Payment pending - further action required.
              {redirectUrl ? (
                <a href={redirectUrl} className="block font-bold">
                  If the page does not automatically redirect click here.
                </a>
              ) : (
                ''
              )}
            </div>
          )}
          {error && (
            <div className="my-5">
              <Text className="text-red-400">{error}</Text>
            </div>
          )}
        </Box>
      )}
    </>
  );
}

export default PrintCheckoutFrameForm;
