import { forwardRef } from 'react';
import cx from 'classnames';

const Container = forwardRef(function ContainerPlain({ children, size, className, ...props }, ref) {
  const sizeMapper = {
    sm: 'max-w-screen-sm',
    md: 'max-w-screen-md',
    lg: 'max-w-screen-lg',
    xl: 'container--xl',
    full: 'w-full px-4',
    stretched: 'w-full'
  };

  return (
    <div ref={ref} className={cx('container', className, sizeMapper[size])} {...props}>
      {children}
    </div>
  );
});

Container.defaultProps = {
  size: 'xl'
};

export default Container;
