import { useTranslation } from 'react-i18next';
import { Box, Container, Text, Link } from '@components';

function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="footer-coming-soon">
      <Container>
        <Box className="footer-coming-soon__wrapper">
          <Box className="footer-coming-soon__left">
            <Text>{t('components.footer.copyright')}</Text>
          </Box>
          <Box className="footer-coming-soon__right">
            <ul className="footer-coming-soon__menu">
              <li>
                <Link to="/early-access/faq" className="footer-coming-soon__link">
                  {t('components.footer.menu.faq')}
                </Link>
              </li>
              <li>
                <Link to="/early-access/terms-and-conditions" className="footer-coming-soon__link">
                  {t('components.footer.menu.terms')}
                </Link>
              </li>
              <li>
                <Link to="/early-access/privacy-policy" className="footer-coming-soon__link">
                  {t('components.footer.menu.privacy')}
                </Link>
              </li>
            </ul>
          </Box>
        </Box>
      </Container>
    </footer>
  );
}

export default Footer;
