export const faqs = [
  {
    title: 'WHEN WILL FANS.INC LAUNCH?',
    alias: 'faq-1',
    description: 'Fans.Inc will launch in Q3 of 2023.'
  },
  {
    title: 'WHY SHOULD I SIGN UP EARLY TO FANS.INC?',
    alias: 'faq-2',
    description:
      "By joining Fans.Inc now, you'll receive updates about Fans.Inc including the official launch date, and be the first to get access to the first releases of a new type of premium Digital Collectible NFT called a Digital Original NFT."
  },
  {
    title: 'IS IT FREE TO JOIN FANS.INC?',
    alias: 'faq-3',
    description: "Yes, it's free to be a member of Fans.Inc."
  },
  {
    title: 'WHAT IS A DIGITAL COLLECTIBLE NFT?',
    alias: 'faq-4',
    description:
      'Our Digital Collectible NFTs are official creator-verified, limited edition digital collectibles that are tokenised both on a custom URL and on the blockchain. It is a unique digital asset class whose ownership is verified by the creator who released it, authenticated by Fans.Inc, then secured and tracked on the blockchain.'
  },
  {
    title: 'WHAT TYPE OF DIGITAL COLLECTIBLES WILL YOU OFFER?',
    alias: 'faq-5',
    description:
      "Soon you will be able to collect and trade the world's greatest songs, films, books, moments in history, photographs and more, as limited edition, digital collectible NFTs!"
  },
  {
    title: 'ANYONE CAN COPY DIGITAL CONTENT - WHAT MAKES YOURS VALUABLE AND COLLECTABLE?',
    alias: 'faq-6',
    description:
      "Any digital content can be viewed and copied. The beauty of a Fans.Inc Limited Edition Digital Collectible NFT is that it is tokenised and showcased on a one-of-a-kind URL, which includes the creator's and the fan/collector's name and then is further secured on an immutable blockchain (NFT).  These cannot be copied and thus can be collected, showcased and traded like any other asset or collectible. Furthermore, and most importantly, they have built in SCARCITY. The verified creator of the content will warrantee that they will only release an extremely limited number of collectibles for their content. For example, a best-selling song that may have sold ten million copies might only have 10,000 limited edition digital collectibles on offer, ever, thus making them scarce compared with the millions who love that song. Imagine the collectible value of the greatest songs of all time."
  },
  {
    title: 'WHAT IS A DIGITAL ORIGINAL?',
    alias: 'faq-7',
    description:
      'A Digital Original is the most important digital version of a set of content (song, film etc...) as determined and verified by the creator of the content. It is the “digital master copy”, the one-off, the number one version of that work, that can be collected and owned. It is based on a <a href="https://www.digitaloriginals.org" target="_blank">white paper written here</a> by a Fans.Inc founder in 2009; outlining the creation of a brand, new asset class of digital collectibles, that was way before its time. With the advent of NFTs, the concept of creating a digital twin asset of legacy cultural content has come of age and Fans.Inc are developing a platform that will allow the world\'s best Creators to NFT their legacy content as Digital Originals and numbered Limited Editions, thus creating a new class of premium music, film and other collectibles that will eventually rival fine art in size and collector status. All digital collectibles released on Fans.Inc will be official digital collectibles. The intellectual property owner and/or rights holder to the content have authorised for that content to be used as a digital collectible exclusively on Fans.Inc and have warranted to fans that they will not release the same content elsewhere as a digital collectible Thus they will only ever be released in limited quantities, making them scarce.'
  },
  {
    title: 'WHO CAN CREATE DIGITAL COLLECTIBLES ON FANS.INC?',
    alias: 'faq-8',
    description:
      'We only allow verified creators of the world\'s most iconic content in music, news and entertainment to release digital collectibles on Fans.Inc. We will only offer authenticated, quality, premium collectibles. If you are a leading creator or rights holder, contact the Fans.Inc Creator Services team on <a href="mailto:creator@fans.inc">creator@fans.inc</a> for further assistance.'
  }
];

export const faqsPatrons = [
  {
    title:
      'WHAT IS A LIMITED EDITION, DIGITAL VERSION OF AN ORIGINAL ARTWORK MASTERPIECE ON PATRONS.ART?',
    alias: 'faq-1',
    description:
      'Just like artists release signed and authenticated limited edition print versions of great masterpieces, they are now releasing artist and / or museum verified limited edition, digital versions of their masterpieces on Patrons.Art. Effectively a new collectible asset class for art collectors and a once-in-a-lifetime chance to own a piece of art history, digitally!'
  },
  {
    title: 'WHY WOULD I WANT TO COLLECT A LIMITED EDITION, DIGITAL VERSION OF A MASTERPIECE? ',
    alias: 'faq-2',
    description:
      'These special editions are issued by the artists who created them (or in the case of works out of copyright; the famous museum that owns them) in guarranteed limited editions. This makes them scarce and valuable, just like their print editions or the original are valuable. Thus, as unique and collectable, digital assets they can be purchased, showcased and traded on Patrons.Art and (should the Collectors choose to) as an NFT on NFT marketplaces*'
  },
  {
    title:
      'BUT ANYONE CAN COPY DIGITAL CONTENT - WHAT MAKES THESE COLLECTABLE, TRADABLE AND VALUABLE?',
    alias: 'faq-3',
    description:
      "Any digital content can be viewed and copied. Also most artwork images could be printed off the web, but neither would have any value. However, Patrons.Art limited edition digital versions of art masterpieces have unique and authenticated digital identifiers. They are tokenised on a unique URL at Patrons.Art, under an authenticated and verified artists’ and / museums Patrons.Art profile. This special web URL, includes the artist’s name, title, edition number and collector's name once purchased - ie.</br>  www.Patrons.Art/LeonardoDaVinci/MonaLisa#2/BernardArnault . They can can be further secured as an NFT, should the collector choose this option*. So although the image/content can be copied, the limited edition digital versions are something uniquely authenticated, identifiable and guarranteed, so unlike other digital images/content, they can be showcased, tracked and traded from artist-to-collector(s) and that provenance cannot be copied."
  },
  {
    title: 'WHAT IS IN IT FOR ART LOVERS AND COLLECTORS?',
    alias: 'faq-4',
    description:
      'By collecting and trading these rare art collectibles at Patrons.Art, you support the artists who have created them and the museums who preserve the physical works - you become a true art patron! And they may become quite valuable, if we compare them to print editions of great works. For example Banksy’s prints of his works trade in limited editions of 150 from $10,000 to $150,000+. As we spend so much time in a digital world, who knows what verified, unique limited digital editions of great masterpieces will eventually sell for? Collectors of the digitals, also get their own profile at Patrons.Art/YourCollectorName to showcase their collection. It also appears on both the artist profile and the galleries profiles. And of course, you can share it on social media.'
  },
  {
    title: 'HOW DO I GET STARTED?',
    alias: 'faq-5',
    description:
      'Simply <a href="/signup" target="_blank">Sign Up Here</a> - it is  currently free. Once signed up you will notified onf upcoming releases and have access to the marketplace. You will be asked to set up a simple profile page where you will see your Collected editions and your Loved lists. It’s easy to purchase with a credit cards (or even crypto if you like). And show off your Collection on your profile, on the artists’ and museums’ profile and share with your friends on social media. When you are ready, subject to custom conditions on each artwork, you can put it up for sale and trade. You can set a fixed price, a minimum bid price or request any bids. Once sold, after standard identity checks you will be paid out the proceeds minus the outlined fees. <a href="/home" target="_blank">Why not get started</a>, its quick and easy!'
  },
  {
    title: 'WHAT IS IN IT FOR ARTISTS? ',
    alias: 'faq-6',
    description:
      'Patrons.Art provides artists the opportunity to create limited digital editions of their greatest artworks and a marketplace to sell the digital versions. As an artist you usually own the copyright to the artwork and thus can create these new digital versions of your works. This will allow new and different collectors to collect and enjoy your work. You earn most of the primary sales revenue and each time the digital editions are resold you earn royalties once again, making this an ongoing annuity to support your work. You can work directly with us, or manage it through your commercial gallerist and / or any public museum that hosts your work. Please apply here'
  },
  {
    title: 'WHAT IS IN IT FOR ART MUSEUMS?',
    alias: 'faq-7',
    description:
      'Patrons.Art provides a platform service and marketplace to create limited number, digital editions of the museums legacy art, as well the opportunity to sell contemporary exhibiting artists’ editions. Turn your art loving visitors into a passionate community of collector-patrons, by offering this new art collectible. Alongside each carefully curated artwork displayed in your gallery will be a simple message and a QR code - “ Scan to Collect this Art - digitally”. When the visitor scans, it will take them to the limited edition digital version of the artwork at Patrons.Art/YourMuseum, with a quick explainer and the ability to purchase. Resulting revenues are split between the Museum and the artist/artist estates, with a platform fee being charged by Patrons.Art. This will create significant revenue for the museum, while at the same time supporting contemporary exhibiting artists and artists’ estates.  '
  },
  {
    title: 'WHAT IS IN IT FOR COMMERCIAL GALLERIES?',
    alias: 'faq-8',
    description:
      'Patrons.Art provides a marketplace that creates limited number, digital editions of the gallery’s artists’ works. Each major physical artwork can now also have a digital edition, that can be sold in conjunction with the original and / print editions. We provide a special area to showcase your gallery on Patrons.Art for free** at Patrons.Art/YourGalleryName. Now you can offer this additional service to your stable of artists, to sell both new work and also their older, perhaps even more valuable works. By offering this new art collectible for digitally savvy collectors will lead to increase in revenues for both for yourselves and your artists. Both artists and yourselves will earn additional royalties each time the digital editions are traded between collectors, in perpetuity.'
  },
  {
    title: 'IS THIS JUST ANOTHER NFT? DO I NEED TO USE CRYPTO?',
    alias: 'faq-9',
    description:
      'NOT AT ALL! Limited edition digitals are stand alone digital assets issued by the artists / artists estates and / museums on Patrons.Art. You buy them in fiat currency like USD and then own them on the site at your URL: Patrons.Art/YourCollectorName. Your ownership is guarranteed on Patrons.Art. You can trade them on Patrons.Art to other Collectors and we will pay you in fiat currency. You don’t need to know about NFT’s or use crypto at all.'
  },
  {
    title:
      '*IS THERE AN OPTION TO CONVERT MY LIMITED EDITION DIGITAL PURCHASE INTO AN NFT? CAN I PAY WITH CRYPTO?',
    alias: 'faq-10',
    description:
      'At Patrons.Art, we understand some collectors prefer to hold digital editions in an NFT format. Generally***, we provide the opportunity once you’ve purchased the limited edition digital version to convert it into an NFT and thus it can be traded on NFT marketplaces as well. However, once converted into an NFT, it cannot be traded on Patrons.and we cannot guarrantee the safety of your ownership, any loss of value from taking it off Patrons.Art or any help in trading or showcasing it as an NFT. We suggest this option should only be considered by very astute, experienced NFT traders. And yes, you can choose to pay with crypto instead of fiat, but it may cost a little more due to the currency conversions. '
  },
  {
    title: 'WHAT IS THE BACKSTORY TO PATRONS.ART?',
    alias: 'faq-11',
    description:
      'Patrons.Art is the realization of a dream that began in 2009 with artist Colin Colorful and a group of visionary artists called the Imaginists. Their idea was simple yet revolutionary: to create a unique digital version of an original artwork, authenticated by the artist, making them rare, valuable, and collectible. They called them Digital Originals. They even wrote a detailed plan that laid the groundwork for what would become the Patrons.Art marketplace.<br /><br />This conceptual technology was outlined in a 2009 white paper at www.DigitalOriginals.org. Colin used this technological breakthrough shortly afterward, to create and authenticate the world’s first collectible artwork in unique ( non-fungible) digital format at www.SpamCans.org<br /><br />It was a decade ahead of its time, but with the advent of NFT art, Patrons.Art now introduces these new art collectibles with an added extra to the Digital Original concept – numbered, limited editions of art masterpieces – that can be collected digitally. The very first of these editions, is known as the Digital Original, with each subsequent edition uniquely numbered.'
  },
  {
    title: 'WHY SHOULD I JOIN THE WAITLIST ON PATRONS.ART?',
    alias: 'faq-12',
    description: `Are you looking to be at the forefront of a new and exciting collectible asset class? Look no further than Patrons.Art. By joining our waitlist today, you'll not only be the first to receive updates and exclusive access to our initial releases, but you'll also be considered a VIP member. And the best part? Signing up is currently free! Don't miss out on this opportunity to be a part of something truly unique and be among the first to own these one-of-a-kind collectibles. But hurry, access to our VIP waitlist may come at a cost for future collectors. Join now to secure your spot and be among the elite in this emerging field <br/><br/> *** Some artists/artists estates/museums may not allow NFT’ to be created from their works for their own reasons. Perhaps because they can’t manage the crypto royalty receipts on trading and / they are worried about collectors losing their keys and the works being effectively lost in time and / or they prefer to keep a clear record of identifiable provenance via Patrons.Art. If you still require an NFT version, then before you purchase make sure the artwork sales page says “NFT-convertible”, then it will be convertible into an NFT after purchase. 
      `
  }
];

export const faqsHomePatrons = [
  {
    title:
      'WHAT IS A LIMITED EDITION, DIGITAL VERSION OF AN ORIGINAL ARTWORK MASTERPIECE ON PATRONS.ART?',
    alias: 'faq-1',
    description:
      'Just like artists release signed and authenticated limited edition print versions of great masterpieces, they are now releasing artist and / or museum verified limited edition, digital versions of their masterpieces on Patrons.Art. Effectively a new collectible asset class for art collectors and a once-in-a-lifetime chance to own a piece of art history, digitally!'
  },
  {
    title: 'WHY WOULD I WANT TO COLLECT A LIMITED EDITION, DIGITAL VERSION OF A MASTERPIECE? ',
    alias: 'faq-2',
    description:
      'These special editions are issued by the artists who created them (or in the case of works out of copyright; the famous museum that owns them) in guarranteed limited editions. This makes them scarce and valuable, just like their print editions or the original are valuable. Thus, as unique and collectable, digital assets they can be purchased, showcased and traded on Patrons.Art and (should the Collectors choose to) as an NFT on NFT marketplaces*'
  },
  {
    title:
      'BUT ANYONE CAN COPY DIGITAL CONTENT - WHAT MAKES THESE COLLECTABLE, TRADABLE AND VALUABLE?',
    alias: 'faq-3',
    description:
      "Any digital content can be viewed and copied. Also most artwork images could be printed off the web, but neither would have any value. However, Patrons.Art limited edition digital versions of art masterpieces have unique and authenticated digital identifiers. They are tokenised on a unique URL at Patrons.Art, under an authenticated and verified artists’ and / museums Patrons.Art profile. This special web URL, includes the artist’s name, title, edition number and collector's name once purchased - ie.</br>  www.Patrons.Art/LeonardoDaVinci/MonaLisa#2/BernardArnault . They can can be further secured as an NFT, should the collector choose this option*. So although the image/content can be copied, the limited edition digital versions are something uniquely authenticated, identifiable and guarranteed, so unlike other digital images/content, they can be showcased, tracked and traded from artist-to-collector(s) and that provenance cannot be copied."
  },
  {
    title: 'WHAT IS IN IT FOR ART LOVERS AND COLLECTORS?',
    alias: 'faq-4',
    description:
      'By collecting and trading these rare art collectibles at Patrons.Art, you support the artists who have created them and the museums who preserve the physical works - you become a true art patron! And they may become quite valuable, if we compare them to print editions of great works. For example Banksy’s prints of his works trade in limited editions of 150 from $10,000 to $150,000+. As we spend so much time in a digital world, who knows what verified, unique limited digital editions of great masterpieces will eventually sell for? Collectors of the digitals, also get their own profile at Patrons.Art/YourCollectorName to showcase their collection. It also appears on both the artist profile and the galleries profiles. And of course, you can share it on social media.'
  },
  {
    title: 'HOW DO I GET STARTED?',
    alias: 'faq-5',
    description:
      'Simply <a href="/signup" target="_blank">Sign Up Here</a> - it is  currently free. Once signed up you will notified onf upcoming releases and have access to the marketplace. You will be asked to set up a simple profile page where you will see your Collected editions and your Loved lists. It’s easy to purchase with a credit cards (or even crypto if you like). And show off your Collection on your profile, on the artists’ and museums’ profile and share with your friends on social media. When you are ready, subject to custom conditions on each artwork, you can put it up for sale and trade. You can set a fixed price, a minimum bid price or request any bids. Once sold, after standard identity checks you will be paid out the proceeds minus the outlined fees. <a href="/home" target="_blank">Why not get started</a>, its quick and easy!'
  }
];

export const teams = [
  {
    name: 'Jhon Chena',
    position: 'CEO & Co-Founder',
    media: {
      avatarUrl: 'https://i.imgur.com/WoTsg92.png'
    }
  },
  {
    name: 'Jhon Chena',
    position: 'CEO & Co-Founder',
    media: {
      avatarUrl: 'https://i.imgur.com/WoTsg92.png'
    }
  },
  {
    name: 'Jhon Chena',
    position: 'CEO & Co-Founder',
    media: {
      avatarUrl: 'https://i.imgur.com/WoTsg92.png'
    }
  },
  {
    name: 'Jhon Chena',
    position: 'CEO & Co-Founder',
    media: {
      avatarUrl: 'https://i.imgur.com/WoTsg92.png'
    }
  },
  {
    name: 'Jhon Chena',
    position: 'CEO & Co-Founder',
    media: {
      avatarUrl: 'https://i.imgur.com/WoTsg92.png'
    }
  },
  {
    name: 'Jhon Chena',
    position: 'CEO & Co-Founder',
    media: {
      avatarUrl: 'https://i.imgur.com/WoTsg92.png'
    }
  },
  {
    name: 'Jhon Chena',
    position: 'CEO & Co-Founder',
    media: {
      avatarUrl: 'https://i.imgur.com/WoTsg92.png'
    }
  },
  {
    name: 'Jhon Chena',
    position: 'CEO & Co-Founder',
    media: {
      avatarUrl: 'https://i.imgur.com/WoTsg92.png'
    }
  }
];

export const works = [
  {
    title: 'What is Fans.inc?',
    alias: 'work-1',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut porta et eros et lobortis. Curabitur justo nisi, rhoncus ut neque at, pellentesque ultrices dolor.'
  },
  {
    title: 'What is the return policy?',
    alias: 'work-2',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut porta et eros et lobortis. Curabitur justo nisi, rhoncus ut neque at, pellentesque ultrices dolor.'
  },
  {
    title: 'Where are you located?',
    alias: 'work-3',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut porta et eros et lobortis. Curabitur justo nisi, rhoncus ut neque at, pellentesque ultrices dolor.'
  },
  {
    title: 'Product Info',
    alias: 'work-4',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut porta et eros et lobortis. Curabitur justo nisi, rhoncus ut neque at, pellentesque ultrices dolor.'
  }
];
