import FansincProfileHeader from './fansinc';
import PatronsProfileHeader from './patrons';

const ProfileHeader = ({
  release,
  user,
  loading,
  bannerColor,
  className,
  onHandleFollow,
  followLoading,
  isFollow,
  customFollow,
  refetchData,
  ...props
}) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return (
      <PatronsProfileHeader
        release={release}
        user={user}
        loading={loading}
        bannerColor={bannerColor}
        className={className}
        onHandleFollow={onHandleFollow}
        followLoading={followLoading}
        isFollow={isFollow}
        customFollow={customFollow}
        refetchData={refetchData}
        {...props}
      />
    );
  } else {
    return (
      <FansincProfileHeader
        release={release}
        user={user}
        loading={loading}
        bannerColor={bannerColor}
        className={className}
        onHandleFollow={onHandleFollow}
        followLoading={followLoading}
        isFollow={isFollow}
        customFollow={customFollow}
        refetchData={refetchData}
        {...props}
      />
    );
  }
};

export default ProfileHeader;
