import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import cx from 'classnames';
import { Box, CollectibleSlider, Container } from '@components';

import { useExchangesByCurrentOwner } from '@libs/hooks/exchange';
import { useCollectiblesByOwnerFeatured } from '@libs/hooks/collectible';
import { useTranslation } from 'react-i18next';
import { createAssetUrl } from '@libs/utils/media';
import Release from './components/release';
import CollectibleGroup from './components/collectible-group';
import CollectibleCategory from './components/collectible-category';

function ProfileMain({ profile, profileLoafing, title, description, refetchData, ...props }) {
  const { t } = useTranslation();
  const params = useParams();
  const username = params.username.toLowerCase();
  const { data: featuredCollectibles, loading: featuredCollectiblesLoading } =
    useCollectiblesByOwnerFeatured(profile, 5);
  const { data: featuredRelease, loading: featuredReleaseLoading } = useExchangesByCurrentOwner({
    ownerID: profile?.id
  });

  let collectible = <CollectibleGroup username={username} user={profile} />;
  if (profile) {
    if (profile?.verified !== 'TRUE') {
      collectible = <Release userID={profile?.id} />;
    } else {
      const kevinJamesUI = ['kevinjames', 'kevinjames2', 'jameskevin'].includes(username);
      if (kevinJamesUI) {
        collectible = (
          <>
            <CollectibleCategory username={username} category="SONG" />
            <CollectibleCategory username={username} category="SONG_LYRICS" />
            <CollectibleCategory username={username} category="PHOTOGRAPH" />
          </>
        );
      }
    }
  }

  const isVerified = profile?.verified === 'TRUE';

  return (
    <>
      <Box>
        {isVerified && (
          <Container className={cx('overflow-hidden pt-14')}>
            <Box className="pb-4">
              <CollectibleSlider
                collectibles={isVerified ? featuredCollectibles : featuredRelease}
                loading={isVerified ? featuredCollectiblesLoading : featuredReleaseLoading}
                isRelease={!isVerified}
                showEmpty
                title={isVerified ? t('profile.featuredCollectibles') : null}
                showButton={isVerified ? true : false}
              />
            </Box>
          </Container>
        )}

        <Container size="xl" className="profile__container">
          {collectible}
        </Container>
      </Box>
      <Helmet>
        <link rel="canonical" href={'https://fans.inc' + window.location.pathname} />
        <title>{title()}</title>
        <meta name="URL" content={'https://fans.inc' + window.location.pathname} />
        <meta name="description" content={description()} />
        <meta property="og:title" content={title()} />
        <meta property="og:description" content={description()} />
        <meta property="og:url" content={'https://fans.inc' + window.location.pathname} />
        <meta
          property="og:image"
          content={
            profile?.media?.avatarUrl
              ? createAssetUrl(profile?.media?.avatarUrl, 400)
              : 'https://cdn.fans.inc/public/webapp/og-image.png'
          }
        />
      </Helmet>
    </>
  );
}

export default ProfileMain;
