import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation, Trans } from 'react-i18next';
import {
  Box,
  Container,
  Grid,
  Media,
  Button,
  Tabs,
  TabItem,
  Avatar,
  ShareLink,
  SkeletonLoading,
  Link,
  Modal,
  Text,
  Cropper
} from '@components';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as ArrowUpRight } from '@assets/svg/arrow-up-right.svg';
import { ReactComponent as OpenSeaOutline } from '@assets/svg/opensea-outline.svg';
import { useAuth } from '@libs/contexts/auth';
import { getNftUrl, NFT_MARKETPLACES, LISTING_TYPES } from '@libs/utils/exchange';
import useQuery from '@libs/utils/query';
import { ReactComponent as Opensea } from '@assets/svg/opensea-blue.svg';
import { useOutsider } from '@libs/utils/outsider';
import { ReactComponent as LayoutGrid } from '@assets/svg/patrons/layout-grid.svg';
import { ReactComponent as ZoomIn } from '@assets/svg/zoom-in.svg';
import { createAssetUrl } from '@libs/utils/media';
import { collectibleTypeParser } from '@pages/collectible/patrons/utils';
import { formatWalletAddress } from '@components/connect-wallet-button/patrons/utils';
import { useExchangesForUnitPage } from '@pages/release/patrons/hooks';
import { urlParser } from '@pages/profile/utils';
import { useCollectionById } from '@libs/hooks/collection';
import { useLocale } from '@libs/contexts/locale';
import ReleaseDetail from './components/release-detail';
import { AiOutlineCamera } from 'react-icons/ai';
import { ReactComponent as CrossZoom } from '@assets/svg/patrons/cross-zoom.svg';
import { ReactComponent as MetamaskLogoIcon } from '@assets/svg/metamask-logo.svg';
import History from './components/history';
import Artist from './components/artist';
import ReleaseVTMS from './components/vtms';
import { useCollectibleDetail } from '@libs/hooks/collectible';
import { motion } from 'framer-motion/dist/framer-motion';
import { useUser } from '@libs/hooks/user';
import { useUploader, useImageResizer } from '@libs/utils/uploader';
import { ReactComponent as ArrowRightWhite } from '@assets/svg/arrow-right-white.svg';
import Certificate from '@assets/img/certificate.png';

function Release({ setGalleryLogo }) {
  const { user, getCurrentUser } = useAuth();
  const { getCurrencyFormatter } = useLocale();
  const currencyFormatter = getCurrencyFormatter();
  const history = useHistory();
  const [isHoverAvatar, setIsHoverAvatar] = useState(false);
  const query = useQuery();
  const { t } = useTranslation();
  const { edition, owner, slug } = useParams();
  const [showMore, setShowMore] = useState(false);
  const exchangesForUnitPage = useExchangesForUnitPage({ slug, serialIndex: edition });
  const {
    currentExchange: exchange,
    isLoadingCurrentExchange,
    unitExchanges,
    offerExchanges
  } = exchangesForUnitPage;
  const [collectible, creator] = [
    exchange?.collectible,
    exchange?.collectible?.creator,
    exchange?.serialIndex
  ];
  const { data: collection, loading: collectionLoading } = useCollectionById(
    collectible?.collectionID
  );
  const [showZoom, setShowZoom] = useState(false);
  const [isOpenViewIt, setIsOpenViewIt] = useState(false);
  const handleCloseViewIt = () => setIsOpenViewIt(false);
  const { ref: refViewIt } = useOutsider(handleCloseViewIt);
  useEffect(() => {
    setGalleryLogo({
      isShowLogo: collection?.isShowLogo === 'TRUE' ? true : false,
      logo: collection?.isShowLogo === 'TRUE' ? collection?.logoUrl : collection?.shortName,
      name: collection?.name
    });
  }, [collection]);
  const { data: collectibleSupply } = useCollectibleDetail(collectible?.id);
  const maxQuantity =
    collectible?.maxNumberedEditions + ' (' + collectibleSupply?.totalReleased + ' released)';

  const redirectUrl = (redirect) => {
    switch (redirect) {
      case 'MANAGE_LISTING':
        {
          history.push(
            `/sell/${exchange?.collectible?.creator?.username}/${exchange?.collectible?.slug}/${edition}`
          );
        }
        break;
      case 'MAKE_OFFER':
        {
          history.push(
            `/${exchange?.collectible?.creator?.username}/${exchange?.collectible?.slug}/checkout/${exchange?.id}?type=MAKE_AN_OFFER`
          );
        }
        break;
      case 'FIXED_PRICE':
        {
          history.push(
            `/${exchange?.collectible?.creator?.username}/${exchange?.collectible?.slug}/checkout/${exchange?.id}?type=`
          );
        }
        break;
      default:
        break;
    }
  };
  const shortDescription = collectible?.description.substring(0, 160) + '... ';
  const collectibleType = collectibleTypeParser[collectible?.category];
  useEffect(() => {
    if (exchange && creator?.id !== exchange?.currentOwnerID) {
      if (!owner && !query.get('status')) {
        history.replace(urlParser(exchange, 'exchange'));
      }

      if (owner !== exchange?.currentOwner?.username) {
        history.replace(urlParser(exchange, 'exchange'));
      }
    }
    // eslint-disable-next-line
  }, [exchange, owner, query]);

  const subAvatarAnimate = {
    enter: {
      opacity: 1,
      rotateX: 0,
      transition: {
        duration: 0.4
      },
      display: 'block'
    },
    exit: {
      opacity: 0,
      rotateX: 0,
      transition: {
        duration: 0.4
      },
      transitionEnd: {
        display: 'none'
      }
    }
  };

  //update avatar
  const { updateAvatarLoading, onUpdateAvatar, onRemoveAvatar } = useUser();
  const [uploadAvatarLoading, setUploadAvatarLoading] = useState(false);
  const {
    inputRef: avatarRef,
    result: avatarResult,
    loading: onLoadingUploderAvatar,
    onClick: onClickUploadAvatar,
    onUpload: onUploadAvatar
  } = useUploader();
  const { loading: imageResizeLoading, onResize } = useImageResizer();
  const [modalAvatar, setModalAvatar] = useState(false);

  const handleOpenModalAvatar = () => {
    setModalAvatar(true);
  };

  const handleCloseModalAvatar = () => {
    if (
      !(onLoadingUploderAvatar || imageResizeLoading || updateAvatarLoading || uploadAvatarLoading)
    ) {
      setModalAvatar(false);
    }
  };

  //update avatar

  return (
    <>
      <Container className="collectible-unit">
        <Grid column="12">
          <Box className="collectible__left">
            <Box className="collectible__image">
              <Box className="collectible__image__wrapper">
                <Media
                  collectible={collectible}
                  full
                  showPlayer
                  size={1080}
                  showPlayerSlider
                  showExternalLink={collectible?.streamExternal === 'TRUE'}
                />
                {!isLoadingCurrentExchange && (
                  <Box onClick={() => setShowZoom(true)} className="collectible__image__zoom">
                    <ZoomIn />
                  </Box>
                )}
              </Box>
            </Box>
            <Box className="w-full collectible-unit__tab__sticky">
              <Box className="collectible-unit__tab mt-12">
                <Tabs directTo={t('p.pages.release.tab.detail.title')} full>
                  <TabItem label={t('p.pages.release.tab.detail.title')}>
                    <ReleaseDetail collectible={collectible} maxQuantity={maxQuantity} />
                  </TabItem>
                  <TabItem label={t('p.pages.release.tab.history.saleHistory')}>
                    <Box className="overflow-auto">
                      <Box className="collectible-unit__tab__transaction">
                        <Box className="collectible-unit__tab__transaction__wrapper">
                          <History exchanges={unitExchanges} type="sale" />
                        </Box>
                      </Box>
                    </Box>
                  </TabItem>
                  <TabItem label={t('p.pages.release.tab.history.offerHistory')}>
                    <Box className="overflow-auto">
                      <Box className="collectible-unit__tab__transaction">
                        <Box className="collectible-unit__tab__transaction__wrapper">
                          <History exchanges={offerExchanges} type="offer" />
                        </Box>
                      </Box>
                    </Box>
                  </TabItem>
                  <TabItem label={t('p.pages.release.tab.theArtist')}>
                    <Box className="collectible-unit__tab__story">
                      <Artist collectible={collectible} />
                    </Box>
                  </TabItem>
                </Tabs>
              </Box>
            </Box>
          </Box>
          <Box className="collectible-unit__detail">
            <Box className="collectible-unit__detail__sticky">
              <Box className="collectible-unit__detail__top">
                <Box className="collectible-unit__detail__top__field">
                  <ShareLink style={{ paddingTop: '3px' }} />
                  <Link
                    to={`/${exchange?.collectible?.creator?.username}/${exchange?.collectible?.slug}`}
                    className="collectible-unit__detail__top__field ml-5"
                  >
                    <LayoutGrid className="collectible-unit__detail__top__field__icon" />
                    <Box className="collectible-unit__detail__top__field__text">
                      {t('p.pages.release.viewAllEditions')}
                    </Box>
                  </Link>
                </Box>
              </Box>
              {isLoadingCurrentExchange ? (
                <SkeletonLoading className="w-full h-11 mt-5" />
              ) : (
                <Box className="collectible-unit__detail__title">{collectible?.title}</Box>
              )}
              {isLoadingCurrentExchange ? (
                <SkeletonLoading className="w-full h-7 mt-5" />
              ) : (
                <Box className="collectible-unit__detail__serialIndex">
                  <Trans i18nKey="p.pages.release.limitedEdition">
                    {{ serialIndex: exchange?.serialIndex }}
                  </Trans>
                </Box>
              )}
              {isLoadingCurrentExchange ? (
                <SkeletonLoading className="w-full h-7 mt-4" />
              ) : (
                <Box className="collectible__detail__creator">
                  <Trans i18nKey="p.pages.collectible.creatorName">
                    <Link
                      to={`/${collectible?.creator?.username}`}
                      className="collectible__detail__creator__name"
                    >
                      {{
                        creatorName:
                          collectible?.creator?.displayName ||
                          collectible?.creator?.firstName + ' ' + collectible?.creator?.lastName
                      }}
                    </Link>
                  </Trans>
                  {collection?.shortName && (
                    <Trans i18nKey="p.pages.collectible.collectionName">
                      <Link
                        to={`/collection/${collection?.slug}`}
                        className="collectible__detail__creator__name"
                      >
                        {{
                          collectionName: collection?.shortName
                        }}
                      </Link>
                    </Trans>
                  )}
                </Box>
              )}
              {/* {collectionLoading || isLoadingCurrentExchange ? (
                <SkeletonLoading className="h-10 w-52 my-3" />
              ) : (
                <>
                  {collection?.collectionUrl && (
                    <Link target="_blank" to={collection?.collectionUrl}>
                      <Box className="collectible__detail__link">
                        <Box className="collectible__detail__link__content">
                          <Box>
                            <Trans i18nKey="p.pages.collectible.visitCollection">
                              {{ collectionName: collection?.shortName }}
                            </Trans>
                          </Box>
                          <ArrowUpRight className="ml-2" />
                        </Box>
                      </Box>
                    </Link>
                  )}
                </>
              )} */}
              {isLoadingCurrentExchange ? (
                <SkeletonLoading style={{ height: '84px' }} className="w-full mt-4" />
              ) : (
                <Box className="collectible__detail__description">
                  {showMore ? (
                    <>
                      {collectible?.description}
                      <span
                        onClick={() => setShowMore(false)}
                        className="collectible__detail__description__more ml-1"
                      >
                        {t('p.pages.collectible.collapse')}
                      </span>
                    </>
                  ) : (
                    <>
                      {shortDescription}
                      <span
                        onClick={() => setShowMore(true)}
                        className="collectible__detail__description__more"
                      >
                        {t('p.pages.collectible.readMore')}
                      </span>
                    </>
                  )}
                </Box>
              )}
              {/* {isLoadingCurrentExchange ? (
              <SkeletonLoading className="w-full h-44 mt-5" />
            ) : (
              <Box className="collectible-unit__detail__description">
                {collectible?.description}
              </Box>
            )} */}
              {isLoadingCurrentExchange ? (
                <SkeletonLoading className="w-full h-14 mt-5" />
              ) : (
                <Box className="collectible-unit__detail__profile">
                  <Box className="collectible-unit__detail__profile__creator">
                    <Box className="collectible-unit__artists">
                      <Box>
                        <Avatar user={creator} size="xs-40" />
                      </Box>
                      <Box className="collectible-unit__artists__detail">
                        <Box className="collectible-unit__artists__detail__name">
                          {t('p.pages.release.artist')}
                        </Box>
                        <Box className="collectible-unit__artists__detail__description">
                          {creator?.displayName || creator?.firstName + ' ' + creator?.lastName}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="collectible-unit__detail__profile__owner">
                    {user?.id === exchange?.currentOwner?.id ? (
                      <Box className="collectible-unit__artists">
                        <motion.div
                          onHoverStart={() => setIsHoverAvatar(!isHoverAvatar)}
                          onHoverEnd={() => setIsHoverAvatar(!isHoverAvatar)}
                          className="collectible-unit__artists__avatar"
                        >
                          <Avatar user={user} size="xs-40" />
                          <motion.div
                            initial="enter"
                            variants={subAvatarAnimate}
                            animate={isHoverAvatar ? 'enter' : 'exit'}
                            className="collectible-unit__artists__avatar__hover"
                            onClick={handleOpenModalAvatar}
                          >
                            <AiOutlineCamera size={20} />
                          </motion.div>
                        </motion.div>
                        <Box className="collectible-unit__artists__detail">
                          <Box className="collectible-unit__artists__detail__name">
                            {t('p.pages.release.collector')}
                          </Box>
                          <Box className="collectible-unit__artists__detail__description">
                            {exchange?.currentOwner?.firstName +
                              ' ' +
                              exchange?.currentOwner?.lastName}
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      <Box className="collectible-unit__artists">
                        <Box>
                          <Avatar user={exchange?.currentOwner} size="xs-40" />
                        </Box>
                        <Box className="collectible-unit__artists__detail">
                          <Box className="collectible-unit__artists__detail__name">
                            {t('p.pages.release.collector')}
                          </Box>
                          <Box className="collectible-unit__artists__detail__description">
                            {exchange?.currentOwner?.firstName +
                              ' ' +
                              exchange?.currentOwner?.lastName}
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
              {exchange?.currentListingType === 'NFT' ? (
                <Box className="collectible-unit__detail__minted">
                  {user?.id === exchange?.currentOwner?.id ? (
                    <Box className="collectible-unit__detail__minted__owner">
                      <Box className="collectible-unit__detail__minted__owner__left">
                        <Box className="collectible-unit__detail__minted__owner__left__text">
                          {t('p.pages.release.mint.mintedIn')}
                        </Box>
                        <Box className="collectible-unit__detail__minted__owner__left__wallet">
                          <Box>
                            <MetamaskLogoIcon width={20} height={20} />
                          </Box>
                          <Box className="ml-2">
                            {formatWalletAddress(exchange?.nftContractAddress)}
                          </Box>
                        </Box>
                      </Box>
                      <Box className="collectible-unit__detail__minted__owner__right">
                        <Link
                          to={getNftUrl({
                            exchange,
                            marketplace: NFT_MARKETPLACES.OPENSEA_TESTNETS_MUBAI
                          })}
                          target="_blank"
                        >
                          <Button blue>
                            <Box className="collectible-unit__detail__mint__button__wrapper uppercase">
                              {t('p.pages.release.mint.view')}
                              <span>
                                <OpenSeaOutline className="ml-2" />
                              </span>
                            </Box>
                          </Button>
                        </Link>
                      </Box>
                    </Box>
                  ) : (
                    <Box className="collectible-unit__detail__minted__buyer">
                      <Box className="collectible-unit__detail__minted__buyer__title">
                        <Opensea />
                        <Box className="collectible-unit__detail__minted__buyer__title__text">
                          {t('p.pages.release.mint.buyTitle')}
                        </Box>
                      </Box>
                      <Box className="collectible-unit__detail__minted__buyer__description">
                        {t('p.pages.release.mint.buyDescription')}
                      </Box>
                      <Link
                        to={getNftUrl({
                          exchange,
                          marketplace: NFT_MARKETPLACES.OPENSEA_TESTNETS_MUBAI
                        })}
                        target="_blank"
                      >
                        <Button className="mt-6 uppercase" fullWidth>
                          <Box className="uppercase">{t('p.pages.release.mint.buyNow')}</Box>
                        </Button>
                      </Link>
                      <Link
                        to={getNftUrl({
                          exchange,
                          marketplace: NFT_MARKETPLACES.OPENSEA_TESTNETS_MUBAI
                        })}
                        target="_blank"
                      >
                        <Button style={{ marginTop: '10px' }} fullWidth>
                          <Box className="uppercase">{t('p.pages.release.mint.offerNow')}</Box>
                        </Button>
                      </Link>
                    </Box>
                  )}
                </Box>
              ) : (
                <Box className="collectible-unit__detail__price">
                  <Box className="collectible-unit__detail__price__detail">
                    <Box className="collectible-unit__detail__price__detail__field">
                      <Box className="collectible-unit__detail__price__detail__field__name">
                        {t('p.pages.release.minimumOffer')}
                      </Box>
                      {isLoadingCurrentExchange ? (
                        <SkeletonLoading className="h-7 w-8" />
                      ) : (
                        <Box className="collectible-unit__detail__price__detail__field__value">
                          {currencyFormatter.format(Number(exchange?.offerPrice))}
                        </Box>
                      )}
                    </Box>
                    <Box className="collectible-unit__detail__price__detail__field">
                      <Box className="collectible-unit__detail__price__detail__field__name">
                        {t('p.pages.release.lastSold')}
                      </Box>
                      {isLoadingCurrentExchange ? (
                        <SkeletonLoading className="h-7 w-8" />
                      ) : (
                        <Box className="collectible-unit__detail__price__detail__field__value">
                          {currencyFormatter.format(Number(exchange?.previousPrice))}
                        </Box>
                      )}
                    </Box>
                  </Box>
                  {isLoadingCurrentExchange ? (
                    <SkeletonLoading className="h-14 w-full mt-4" />
                  ) : (
                    <>
                      {user?.id === exchange?.currentOwner?.id ? (
                        <Button
                          onClick={() => redirectUrl('MANAGE_LISTING')}
                          fullWidth
                          className="mt-4"
                        >
                          {t('p.pages.release.manageListing')}
                        </Button>
                      ) : (
                        <>
                          {exchange?.listingType === 'FIXED_PRICE' ? (
                            <Box className="flex gap-3">
                              <Button
                                onClick={() => redirectUrl('FIXED_PRICE')}
                                fullWidth
                                className="mt-4"
                              >
                                {t('p.pages.release.buyNow')}
                              </Button>
                              <Button
                                onClick={() => redirectUrl('MAKE_OFFER')}
                                fullWidth
                                className="mt-4"
                              >
                                {t('p.pages.release.makeAnOffer')}
                              </Button>
                            </Box>
                          ) : (
                            <Button
                              onClick={() => redirectUrl('MAKE_OFFER')}
                              fullWidth
                              className="mt-4"
                            >
                              {t('p.pages.release.makeAnOffer')}
                            </Button>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Box>
              )}
              {collectible?.printable === 'TRUE' && (
                <Link
                  to={`/print/${collectible?.creator?.username}/${collectible?.slug}/${exchange?.serialIndex}`}
                >
                  <Box className="collectible__detail__printable">
                    <Box className="collectible__detail__printable__icon">
                      <img src={Certificate} alt="" />
                    </Box>
                    <Box className="collectible__detail__printable__text">
                      {t('p.pages.collectible.printable')}
                    </Box>
                    <Box className="collectible__detail__printable__arrow">
                      <ArrowRightWhite />
                    </Box>
                  </Box>
                </Link>
              )}
              {exchange?.currentListingType !== 'NFT' && (
                <Box className="collectible-unit__detail__vtms">
                  <Box className="text-base md:text-lg font-bold cursor-pointer">
                    {t('p.pages.collectible.vtms')}
                  </Box>
                  <ReleaseVTMS collection={collection} exchange={exchange} />
                </Box>
              )}
            </Box>
          </Box>
          <Box className="w-full collectible-unit__tab__block col-span-12">
            <Box className="collectible-unit__tab">
              <Tabs directTo={t('p.pages.release.tab.detail.title')} full>
                <TabItem label={t('p.pages.release.tab.detail.title')}>
                  <ReleaseDetail collectible={collectible} maxQuantity={maxQuantity} />
                </TabItem>
                <TabItem label={t('p.pages.release.tab.history.saleHistory')}>
                  <Box className="overflow-auto">
                    <Box className="collectible-unit__tab__transaction">
                      <Box className="collectible-unit__tab__transaction__wrapper">
                        <History exchanges={unitExchanges} type="sale" />
                      </Box>
                    </Box>
                  </Box>
                </TabItem>
                <TabItem label={t('p.pages.release.tab.history.offerHistory')}>
                  <Box className="overflow-auto">
                    <Box className="collectible-unit__tab__transaction">
                      <Box className="collectible-unit__tab__transaction__wrapper">
                        <History exchanges={offerExchanges} type="offer" />
                      </Box>
                    </Box>
                  </Box>
                </TabItem>
                <TabItem label={t('p.pages.release.tab.theArtist')}>
                  <Box className="collectible-unit__tab__story">
                    <Artist collectible={collectible} />
                  </Box>
                </TabItem>
              </Tabs>
            </Box>
          </Box>
        </Grid>
        {showZoom && (
          <Box className="collectible__zoom">
            <Box className="collectible__zoom__image">
              <Box className="collectible__zoom__image__media">
                <Media
                  collectible={collectible}
                  full
                  showPlayer
                  size={1920}
                  showPlayerSlider
                  showExternalLink={collectible?.streamExternal === 'TRUE'}
                />
              </Box>
              <Box className="collectible__zoom__image__close">
                <CrossZoom onClick={() => setShowZoom(false)} />
              </Box>
            </Box>
          </Box>
        )}
        <Modal
          verticalCenter
          showCloseIcon
          className="modal-avatar"
          isOpen={modalAvatar}
          onClose={handleCloseModalAvatar}
          heading={t('p.pages.settings.profile.fields.avatar.uploadImage')}
          size="profile"
        >
          <Box className="modal-avatar__upload">
            <Text className="text-gray-400">
              {t('p.pages.settings.profile.fields.avatar.placeholder')}
            </Text>
            <Button
              onClick={onClickUploadAvatar}
              disabled={
                onLoadingUploderAvatar ||
                imageResizeLoading ||
                updateAvatarLoading ||
                uploadAvatarLoading
              }
              loading={onLoadingUploderAvatar}
              className="modal-avatar__upload__button"
            >
              {t('p.pages.settings.profile.fields.avatar.chooseFile')}
            </Button>
            <input
              ref={avatarRef}
              accept="image/jpeg, image/png, image/webp"
              className="invisible w-0"
              type="file"
              onChange={(e) => onUploadAvatar(e, 'avatar')}
            />
          </Box>

          {avatarRef?.current?.value && avatarResult.fileUrl ? (
            <Box className="modal-avatar__cropper">
              <Cropper
                loading={imageResizeLoading || updateAvatarLoading || uploadAvatarLoading}
                image={avatarResult.fileUrl}
                onComplete={async (croppedArea) => {
                  setUploadAvatarLoading(true);
                  const resized = await onResize({
                    key: avatarResult.s3?.key,
                    folder: 'avatars',
                    ...croppedArea
                  });

                  if (resized) {
                    await onRemoveAvatar();
                    await onUpdateAvatar(resized?.key);
                    await getCurrentUser();
                    setUploadAvatarLoading(false);
                  }

                  avatarRef.current.value = '';
                  setUploadAvatarLoading(false);
                  setModalAvatar(false);
                }}
                onCancel={() => {
                  setModalAvatar(false);
                  avatarRef.current.value = '';
                }}
              />
            </Box>
          ) : null}
        </Modal>
      </Container>
      <Helmet>
        <link rel="canonical" href={'https://patrons.art' + window.location.pathname} />
        <meta name="URL" content={'https://patrons.art' + window.location.pathname} />
        <meta name="twitter:card" content="summary_large_image" />
        <title>
          {t('p.pages.release.meta.title', {
            creator: creator?.displayName || `${creator?.firstName} ${creator?.lastName}`,
            title: collectible?.title,
            edition: `Limited Edition #${exchange?.serialIndex}`,
            type: collectibleTypeParser[collectible?.category]
          })}
        </title>
        <meta
          name="description"
          content={t('p.pages.release.meta.description', {
            creator: creator?.displayName || `${creator?.firstName} ${creator?.lastName}`,
            title: collectible?.title,
            edition: `Limited Edition #${exchange?.serialIndex} of Total ${collectible?.maxNumberedEditions}`,
            type: collectibleTypeParser[collectible?.category]
          })}
        />
        <meta property="og:url" content={'https://patrons.art' + window.location.pathname} />
        <meta
          property="og:title"
          content={t('p.pages.release.meta.title', {
            creator: creator?.displayName || `${creator?.firstName} ${creator?.lastName}`,
            title: collectible?.title,
            edition: `Limited Edition #${exchange?.serialIndex}`,
            type: collectibleTypeParser[collectible?.category]
          })}
        />
        <meta
          property="og:description"
          content={t('p.pages.release.meta.description', {
            creator: creator?.displayName || `${creator?.firstName} ${creator?.lastName}`,
            title: collectible?.title,
            edition: `Limited Edition #${exchange?.serialIndex} of Total ${collectible?.maxNumberedEditions}`,
            type: collectibleTypeParser[collectible?.category]
          })}
        />
        <meta
          property="og:image"
          content={
            collectible?.mediaThumbnail
              ? createAssetUrl(collectible?.mediaThumbnail, 800)
              : 'https://cdn.patrons.art/public/webapp/og-image.png'
          }
        />
      </Helmet>
    </>
  );
}

export default Release;
