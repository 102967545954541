import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Text, Button } from '@components';
import { ReactComponent as OpenSeaIcon } from '@assets/svg/opensea.svg';
import { ReactComponent as OpenSeaIconBlack } from '@assets/simple-icons_opensea_black.svg';
import { ReactComponent as ArrowLeftIcon } from '@assets/svg/arrow-left.svg';

const Minted = ({ nftUrl, collectibleUrl }) => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <Box>
      <Box className="sell-minted">
        <OpenSeaIcon />
        <Text className="sell-minted-title">{t('sell.minted.label')}</Text>
        <Text className="sell-minted-description">{t('sell.minted.description')}</Text>
        <Button className="sell-minted-btn" onClick={() => window.open(nftUrl, '_blank')}>
          <OpenSeaIconBlack />
          <span className="ml-3">{t('sell.minted.viewOnOpensea')}</span>
        </Button>
      </Box>
      <Box className="sell-minted-back" onClick={() => history.push(collectibleUrl)}>
        <ArrowLeftIcon />
        <span>{t('sell.minted.back')}</span>
      </Box>
    </Box>
  );
};

export default Minted;
