import FansincWithdrawalModal from './fansinc';
import PatronsWithdrawalModal from './patrons';

const WithdrawalModal = ({ balance, visible, onCancel, refetch }) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return (
      <PatronsWithdrawalModal
        balance={balance}
        visible={visible}
        onCancel={onCancel}
        refetch={refetch}
      />
    );
  } else {
    return (
      <FansincWithdrawalModal
        balance={balance}
        visible={visible}
        onCancel={onCancel}
        refetch={refetch}
      />
    );
  }
};

export default WithdrawalModal;
