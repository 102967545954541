import { React } from 'react';
import FansincHowitWorks from './fansinc';
import PatronsHowitWorks from './patrons';

function HowitWorks() {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsHowitWorks />;
  } else {
    return <FansincHowitWorks />;
  }
}
export default HowitWorks;
