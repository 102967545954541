import cx from 'classnames';
import { Trans } from 'react-i18next';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { FaVideo } from 'react-icons/fa';
import {
  Avatar,
  Box,
  Heading,
  ProfileVideo,
  Separator,
  SkeletonLoading,
  SocialMedia,
  Text
} from '@components';
import { isObjectNull } from '@libs/utils/object';
import { getUserName } from '@libs/utils/user';

const UserCard = ({ user, showVerified, loading, className, ...props }) => {
  const joinDate = user?.joinDate ? format(new Date(user?.joinDate), 'MMMM d, yyyy') : '';
  const { t } = useTranslation();
  const [openVideo, setOpenVideo] = useState(false);

  const creatorCategoryClass = {
    'has-category': user?.creatorCategory && user?.creatorCategory !== 'FAN'
  };

  const bioClass = {
    'has-bio': user?.bio
  };

  const showSeparator =
    user?.bio ||
    (user?.creatorCategory && user?.creatorCategory) ||
    (user?.links && !isObjectNull(user?.links));

  return (
    <Box className={cx('user-card', creatorCategoryClass, bioClass, className)} {...props}>
      {loading ? (
        <SkeletonLoading className="m-auto w-11/12 h-60" />
      ) : (
        <>
          <ProfileVideo user={user} isOpen={openVideo} onClose={() => setOpenVideo(false)} />
          <Box className="user-card__avatar">
            <Avatar size="xl" user={user} />
            {user?.media?.avatarVideoUrl && (
              <Box onClick={() => setOpenVideo(true)} className="profile-header__avatar__icon">
                <FaVideo className="profile-header__avatar__icon_shadow cursor-pointer" size={20} />
              </Box>
            )}
          </Box>
          <Box className="user-card__content">
            <Box className="user-card__content__heading">
              <Box>
                <Heading as="h2" className="user-card__content__heading__name">
                  {user?.displayName || getUserName(user?.firstName) + ' ' + user?.lastName}
                </Heading>
                {user?.verified === 'TRUE' ? (
                  <span className="user-card__content__heading__verified">
                    {t('components.userCard.verifiedCreator')}
                  </span>
                ) : (
                  <></>
                )}
              </Box>
              <Box className="user-card__content__heading__meta">
                <Text className="user-card__content__heading__meta__join-date">
                  <Trans i18nKey="components.userCard.joined">{{ joinDate }}</Trans>
                </Text>
                <Text>fans.inc/{getUserName(user?.username)}</Text>
              </Box>
            </Box>
            {showSeparator && <Separator className="user-card__separator" />}

            {user?.bio ? <Text className="user-card__content__bio">{user?.bio}</Text> : null}
            {!loading && user?.creatorCategory && user?.creatorCategory !== 'FAN' ? (
              <Text as="span" className="user-card__avatar__category">
                {user?.creatorCategory}
              </Text>
            ) : (
              <></>
            )}
            <SocialMedia socialMedia={user?.links} darkMode className="mt-6 relative -left-2" />
          </Box>
        </>
      )}
    </Box>
  );
};

UserCard.defaultProps = {
  showVerified: true,
  loading: true
};

export default UserCard;
