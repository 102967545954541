import { useState, useEffect } from 'react';

import gql from '@libs/utils/gql';
import { getAccount } from '@graphql/queries';

export const useAccount = (id) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        const { data: res } = await gql(getAccount, { id });
        const options = res?.getAccount;
        setData(options);
      } catch (error) {
        console.error(error);
        setData([]);
      } finally {
        setLoading(false);
      }
    }
    getData();
  }, [id]);

  return {
    loading,
    data
  };
};
