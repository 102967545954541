import FansincHeader from './fansinc';
import PatronsHeader from './patrons';

const Separator = ({ className, dark = false }) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsHeader className={className} dark={dark} />;
  } else {
    return <FansincHeader className={className} dark={dark} />;
  }
};

export default Separator;
