import { useTranslation, Trans } from 'react-i18next';
import { useState, useEffect } from 'react';
import { IoInformationCircleSharp } from 'react-icons/io5';
import ReactTooltip from 'react-tooltip';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Box, Button, FormField, Input, Text } from '@components';
import { useUpdateListing } from '@libs/hooks/exchange/useUpdateListing';
import { LISTING_TYPES } from '@libs/utils/release';
import { urlParserV2 } from '@pages/profile/utils';
import { getFacebookShareUrl, getTwitterShareUrl } from '@libs/utils/share';
import { useLocale } from '@libs/contexts/locale';
import { getNftUrl, NFT_MARKETPLACES } from '@libs/utils/exchange';

import SellMinted from '../minted/index';
import { CollectibleCardV2, Heading, Link, Separator } from '@components/index';
import Marquee from '@pages/confirmation/patrons/marquee';
import { Switch } from '@headlessui/react';

const SellForm = ({ exchange }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { currencyCode, getCurrencyFormatter, calculateFees, roundPrice } = useLocale();
  const currencyFormatter = getCurrencyFormatter();

  const { onUpdateListing, updateLoading } = useUpdateListing();
  const [listingPrice, setListingPrice] = useState();
  const [minimumOffer, setMinimumOffer] = useState();
  const [selectedType, setSelectedType] = useState(exchange?.currentListingType);
  const [facebookUrl, setFacebookUrl] = useState();
  const [twitterUrl, setTwitterUrl] = useState();
  const [feeAmount, setFeeAmount] = useState({
    listing: { salesFee: 0, royaltyFee: 0 },
    offer: { salesFee: 0, royaltyFee: 0 }
  });
  const [userFee, setUserFee] = useState();

  const currency = [{ value: 'usd', label: 'USD' }];
  const {
    register,
    formState: { errors },
    handleSubmit,
    setError
  } = useForm({
    mode: 'onBlur'
  });

  const { onChange: validateListingPrice, ...restListingPrice } = register('listingPrice', {
    min: {
      value: 0,
      message: t('sell.fields.price.noNegative')
    }
  });

  const { onChange: validateMinimumOfferPrice, ...restMinimumOfferPrice } = register(
    'minimumOfferPrice',
    {
      min: {
        value: 0,
        message: t('sell.fields.price.noNegative')
      }
    }
  );

  const { onChange: validateAutoAcceptOfferPrice, ...restAutoAcceptOfferPrice } = register(
    'autoAcceptOfferPrice',
    {
      pattern: {
        value: /^[1-9]\d*$/,
        message: t('sell.fields.price.noDecimal')
      }
    }
  );

  // const replaceDecimal = (value) => {
  //   if (value) {
  //     return value.replace('.', '');
  //   } else {
  //     return null;
  //   }
  // };

  const onSubmit = async (payload) => {
    if (selectedType === LISTING_TYPES.FIXED_PRICE) {
      if (Number(minimumOffer) > Number(listingPrice)) {
        setError(
          'minimumOfferPrice',
          { type: 'max', message: t('p.sell.fields.offer.amount.maxAmount') },
          { shouldFocus: true }
        );
        return;
      }
    }
    const data = {
      ...payload,
      minimumOfferPrice: minimumOffer,
      listingPrice: listingPrice,
      exchangeID: exchange.id,
      listingType: selectedType,
      isMinimumOfferVisible: 'TRUE'
    };
    await onUpdateListing(data, () => {
      const url = urlParserV2(exchange, { type: 'exchange' });
      history.push(url);
    });
  };

  useEffect(() => {
    if (exchange) {
      setSelectedType(exchange?.currentListingType);
      setListingPrice(exchange?.sellingPrice);
      setMinimumOffer(exchange?.offerPrice);

      const { creator } = exchange;
      setUserFee({
        salesFee: creator?.fee?.salesFee || 20,
        royaltyFee: creator?.fee?.royaltyFee || 10
      });

      let url_ = window.location.hostname + urlParserV2(exchange, { type: 'exchange' });
      setFacebookUrl(getFacebookShareUrl(url_));
      setTwitterUrl(getTwitterShareUrl(url_));
    }
  }, [exchange]);

  useEffect(() => {
    const listingFee = calculateFees(listingPrice, userFee?.salesFee);

    const offerFee = calculateFees(minimumOffer, userFee?.salesFee);
    setFeeAmount({ listing: listingFee, offer: offerFee });
  }, [listingPrice, minimumOffer, userFee]);

  const [enabled, setEnabled] = useState(
    exchange?.currentListingType === LISTING_TYPES.FIXED_PRICE
  );
  const disabled = updateLoading;

  const switchHandle = () => {
    setEnabled(!enabled);
    setSelectedType(
      selectedType === LISTING_TYPES.FIXED_PRICE
        ? LISTING_TYPES.MAKE_AN_OFFER
        : LISTING_TYPES.FIXED_PRICE
    );
  };

  return (
    <>
      <Box className="w-full flex flex-col lg:flex-row">
        <Box className="text-primary w-full lg:w-1/2">
          {selectedType === LISTING_TYPES.NFT ? (
            <SellMinted
              nftUrl={getNftUrl({
                exchange,
                marketplace: NFT_MARKETPLACES.OPENSEA_TESTNETS_MUBAI
              })}
              collectibleUrl={urlParserV2(exchange, { type: 'exchange' })}
            />
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              {exchange && (
                <Box className="px-10 md:px-20 lg:px-32 py-10 md:py-20">
                  <Box className="relative lg:hidden block">
                    <CollectibleCardV2
                      collectible={exchange}
                      type="release"
                      showButton={false}
                      showPrice={false}
                      hasCollectibleTimer={false}
                      showOwnerInUrl={true}
                      showReleaseAsCollectible={false}
                      collectibleUrl={`/${exchange?.collectible?.creator?.username}/${exchange?.slug}`}
                      creatorUrl={`/${exchange?.collectible?.creator?.username}`}
                    ></CollectibleCardV2>
                  </Box>
                  <Box className="sell-form-left-title text-primary text-4xl font-bold font-Satoshi capitalize mb-7">
                    <Trans i18nKey="p.sell.title">
                      {{
                        collectibleTitle: exchange?.collectible?.title
                      }}
                    </Trans>
                  </Box>
                  <Box className="sell-form-left-description text-base font-medium text-secondary mb-5">
                    <Trans i18nKey="p.sell.creator">
                      <Link
                        className="underline text-secondary"
                        to={`/${exchange?.collectible?.creator?.username}`}
                      >
                        <span>
                          {{
                            creatorName:
                              exchange?.collectible?.creator?.displayName ||
                              exchange?.collectible?.creator?.firstName +
                                ' ' +
                                exchange?.collectible?.creator?.lastName
                          }}
                        </span>
                      </Link>
                    </Trans>
                  </Box>
                  <Box className="relative inline-block">
                    <label className="mb-1 capitalize flex items-center text-base md:text-lg font-medium">
                      {t('p.sell.fields.offer.amount.label')}
                      <a className="cursor-pointer" data-tip data-for="minimumTooltip">
                        <IoInformationCircleSharp className="ml-2 text-gray-400 hover:text-primary" />
                      </a>
                      <ReactTooltip id="minimumTooltip" place="right" type="dark">
                        <Box className="normal-case max-w-xs">{t('p.sell.fields.offer.note')}</Box>
                      </ReactTooltip>
                    </label>
                  </Box>

                  <Box className="w-full">
                    <FormField className="relative">
                      <Input
                        step="any"
                        min="0"
                        pattern="^\d*(\.\d{0,2})?$"
                        as="number"
                        fullwidth
                        value={minimumOffer}
                        errorMessage={errors.minimumOfferPrice?.message}
                        {...restMinimumOfferPrice}
                        placeholder={t('p.sell.fields.offer.amount.placeholder')}
                        onChange={(e) => {
                          validateMinimumOfferPrice(e);
                          setMinimumOffer(e.target.value);
                        }}
                        onBlur={(e) => {
                          setMinimumOffer(roundPrice(e.target.value));
                        }}
                      />
                      <Text className="absolute right-3 top-2.5 font-Satoshi font-normal text-time text-base md:text-lg">
                        USD
                      </Text>
                    </FormField>
                  </Box>
                  {minimumOffer > 0 && (
                    <Box className="sell__detail">
                      <ul className="sell__detail-list">
                        <li className="sell__detail-list-item">
                          <span>{t('p.sell.fields.offer.label')}</span>
                          <span>
                            <span className="mr-1 font-normal mt-2">
                              {currencyFormatter.format(Number(minimumOffer))}
                            </span>
                            <span className="sell__detail-list-currency">{currencyCode}</span>
                          </span>
                        </li>
                        <Separator />
                        <li className="sell__detail-list-item mt-2">
                          <span>{t('p.components.orderSummary.creatorRoyalties')}</span>
                          <span>
                            <span className="text-red-errorText mr-1 font-normal">{`-${currencyFormatter.format(
                              Number(feeAmount.offer.royaltyFee)
                            )}`}</span>
                            <span className="sell__detail-list-currency">{currencyCode}</span>
                          </span>
                        </li>
                        <Separator />
                        <li className="sell__detail-list-item mt-2">
                          <span>{t('p.components.orderSummary.total')}</span>
                          <span>
                            <span className="mr-1 font-bold">
                              {currencyFormatter.format(
                                Number(minimumOffer) - Number(feeAmount.offer.royaltyFee)
                              )}
                            </span>
                            <span className="sell__detail-list-currency">{currencyCode}</span>
                          </span>
                        </li>
                      </ul>
                    </Box>
                  )}

                  <Box className="flex flex-row justify-start items-center mb-7">
                    {/* <Switch
                      toggle={selectedType === LISTING_TYPES.FIXED_PRICE}
                      setToggle={() =>
                        setSelectedType(
                          selectedType === LISTING_TYPES.FIXED_PRICE
                            ? LISTING_TYPES.MAKE_AN_OFFER
                            : LISTING_TYPES.FIXED_PRICE
                        )
                      }
                    /> */}
                    <Switch
                      disabled={false}
                      onChange={switchHandle}
                      style={{ height: '15px' }}
                      className={`${enabled ? 'bg-red-toggleBg' : 'bg-gray-200'}
          relative inline-flex w-8 shrink-0 ${
            disabled ? 'cursor-not-allowed' : 'cursor-pointer'
          } rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                    >
                      <span
                        aria-hidden="true"
                        className={`${
                          enabled
                            ? disabled
                              ? 'translate-x-3 -translate-y-1 bg-red-100'
                              : 'translate-x-3 -translate-y-1 bg-red-toggle'
                            : disabled
                            ? '-translate-x-1 -translate-y-1 bg-gray-200'
                            : '-translate-x-1 -translate-y-1 bg-white'
                        }
            pointer-events-none inline-block h-5 w-5 transform rounded-full shadow ring-0 transition duration-200 ease-in-out`}
                      />
                    </Switch>
                    <Box className="ml-2 capitalize text-base md:text-lg font-medium">
                      {t('p.sell.fields.fixed.label')}
                    </Box>
                  </Box>
                  {selectedType === LISTING_TYPES.FIXED_PRICE && (
                    <>
                      <Box className="relative">
                        <label className="mb-2 font-medium font-Satoshi text-base md:text-lg capitalize flex items-center">
                          {t('p.sell.fields.fixed.input.label')}
                        </label>
                      </Box>
                      <Box className="w-full">
                        <FormField className="relative">
                          <Input
                            step="any"
                            min="0"
                            pattern="^\d*(\.\d{0,2})?$"
                            as="number"
                            value={listingPrice}
                            errorMessage={errors.listingPrice?.message}
                            fullwidth
                            {...restListingPrice}
                            placeholder={t('p.sell.fields.price.placeholder')}
                            onChange={(e) => {
                              validateListingPrice(e);
                              setListingPrice(e.target.value);
                            }}
                            onBlur={(e) => {
                              setListingPrice(roundPrice(e.target.value));
                            }}
                          />
                          <Text className="absolute right-3 top-2.5 font-Satoshi font-normal text-time text-lg">
                            USD
                          </Text>
                        </FormField>
                      </Box>
                    </>
                  )}
                  {selectedType === LISTING_TYPES.FIXED_PRICE && listingPrice > 0 && (
                    <Box className="sell__detail">
                      <ul className="sell__detail-list">
                        <li className="sell__detail-list-item mt-2">
                          <span>{t('p.sell.fields.listingPrice.label')}</span>
                          <span>
                            <span className="mr-1 font-normal">
                              {currencyFormatter.format(Number(listingPrice))}
                            </span>
                            <span className="sell__detail-list-currency">{currencyCode}</span>
                          </span>
                        </li>
                        <Separator />
                        <li className="sell__detail-list-item mt-2">
                          <span>{t('p.components.orderSummary.creatorRoyalties')}</span>
                          <span>
                            <span className="text-red-errorText mr-1 font-normal">{`-${currencyFormatter.format(
                              Number(feeAmount.listing.royaltyFee)
                            )}`}</span>
                            <span className="sell__detail-list-currency">{currencyCode}</span>
                          </span>
                        </li>
                        <Separator />
                        <li className="sell__detail-list-item mt-2">
                          <span>{t('p.components.orderSummary.total')}</span>
                          <span>
                            <span className="mr-1 font-bold">
                              {currencyFormatter.format(
                                Number(listingPrice) - Number(feeAmount.listing.royaltyFee)
                              )}
                            </span>
                            <span className="sell__detail-list-currency">{currencyCode}</span>
                          </span>
                        </li>
                      </ul>
                    </Box>
                  )}

                  <Button
                    as="submit"
                    loading={updateLoading}
                    className="mb-6 w-full font-Satoshi uppercase"
                  >
                    {t('p.sell.save')}
                  </Button>
                  <Text className="sell__fields__bottom__note text-lg">
                    {t('p.sell.note')}
                    <Link to="/faq" className="ml-2 underline">
                      FAQs
                    </Link>
                  </Text>
                </Box>
              )}
            </form>
          )}
        </Box>
        <Box className="sell-form-right hidden lg:block text-primary w-full lg:w-1/2 bg-gray-200 relative overflow-hidden congratulation">
          <div className="absolute w-full -top-12">
            <Marquee title="Trade It" />
          </div>
          <Box className="flex justify-center items-center h-full">
            <Box className="w-full px-10 md:px-0 py-10 md:py-0 md:w-1/2 z-50">
              <Box className="relative">
                {exchange && (
                  <CollectibleCardV2
                    collectible={exchange}
                    type="release"
                    showButton={false}
                    showPrice={false}
                    hasCollectibleTimer={false}
                    showOwnerInUrl={true}
                    showReleaseAsCollectible={false}
                    collectibleUrl={`/${exchange?.collectible?.creator?.username}/${exchange?.slug}`}
                    creatorUrl={`/${exchange?.collectible?.creator?.username}`}
                  ></CollectibleCardV2>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SellForm;
