import cx from 'classnames';
import { Box } from '@components';

const tierMapper = {
  NUMBERED_EDITION: 'Limited Edition',
  DIGITAL_ORIGINAL: 'Digital Original'
};

const CollectibleTier = ({ tier, collectible, type = 'collectible' }) => {
  return (
    <Box className={cx('collectible-tier', tier === 'DIGITAL_ORIGINAL' && 'collectible-tier-do')}>
      {type === 'collectible' ? (
        <>
          {tier === 'NUMBERED_EDITION' && collectible?.maxNumberedEditions} {tierMapper[tier]}
          {tier === 'NUMBERED_EDITION' && collectible?.maxNumberedEditions > 1 && 's'}
        </>
      ) : (
        <>
          {tierMapper[tier]} #{collectible?.serialIndex}
          {tier === 'NUMBERED_EDITION' && ' / ' + collectible?.collectible?.maxNumberedEditions}
        </>
      )}
    </Box>
  );
};

export default CollectibleTier;
