import React from 'react';
import { Svg, Path } from '@react-pdf/renderer';

const Logo = ({ style, color }) => (
  <Svg
    width="139"
    height="39"
    viewBox="0 0 381 59"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <Path
      d="M0 58.1059V0.901367H40.3314V12.4168H15.0818V23.9298H38.5275V35.3731H15.0842V58.0963L0 58.1059Z"
      fill={color}
    />
    <Path
      d="M32.9556 58.1059L60.0067 0.901367H71.9031L98.9542 58.1059H83.8554L78.8541 46.5016H53.0387L48.0374 58.1059H32.9556ZM65.8262 16.4788L57.9576 35.0583H73.86L65.9913 16.4788H65.8262Z"
      fill={color}
    />
    <Path
      d="M104.108 58.106V0.90146H115.175L141.735 33.6787V0.891846H155.671V58.0964H144.685L118.127 25.3191V58.106H104.108Z"
      fill={color}
    />
    <Path
      d="M184.444 59.0001C180.044 59.018 175.661 58.4596 171.409 57.3392C167.257 56.2304 163.842 54.7554 161.163 52.9143L165.344 41.31C167.856 42.9876 170.807 44.3136 174.196 45.2878C177.525 46.2523 180.975 46.7435 184.444 46.7468C188.052 46.7468 190.579 46.2733 192.026 45.3263C193.473 44.3793 194.201 43.2031 194.211 41.7979C194.225 41.2125 194.094 40.6326 193.83 40.1089C193.565 39.5852 193.175 39.1335 192.694 38.7934C191.684 38.0371 189.894 37.3882 187.326 36.8466L177.733 34.818C167.35 32.6003 162.159 27.2171 162.159 18.6685C162.159 14.9366 163.183 11.6629 165.232 8.84757C167.281 6.03221 170.137 3.85459 173.8 2.31471C177.46 0.774838 181.722 0.00409796 186.588 0.00249559C190.435 -0.0180086 194.263 0.542868 197.94 1.66576C201.519 2.7762 204.432 4.27762 206.68 6.17001L202.5 17.0437C200.312 15.4762 197.873 14.2835 195.287 13.5153C192.416 12.6562 189.431 12.2316 186.432 12.2558C183.37 12.2558 181.023 12.7838 179.391 13.8397C177.76 14.8957 176.94 16.2617 176.932 17.9378C176.923 18.506 177.052 19.068 177.308 19.5763C177.565 20.0846 177.941 20.5243 178.406 20.8581C179.391 21.6176 181.14 22.2674 183.652 22.8074L193.162 24.836C203.709 27.0553 208.983 32.303 208.984 40.5793C208.984 44.3128 207.973 47.5592 205.95 50.3185C203.926 53.0777 201.085 55.2145 197.425 56.7287C193.738 58.2414 189.411 58.9985 184.444 59.0001Z"
      fill={color}
    />
    <Path d="M217.943 58.106V36.2336H240.401V58.106H217.943Z" fill="#D6222A" />
    <Path d="M250.989 58.1059V0.901367H266.071V58.1059H250.989Z" fill={color} />
    <Path
      d="M273.286 58.106V0.90146H284.352L310.911 33.6883V0.891846H324.846V58.0964H313.863L287.309 25.3191V58.106H273.286Z"
      fill={color}
    />
    <Path
      d="M361.327 59.0001C354.932 59.0001 349.439 57.7823 344.849 55.3467C340.377 53.0295 336.708 49.4399 334.315 45.0403C331.887 40.6065 330.673 35.4125 330.673 29.4581C330.673 23.5037 331.887 18.324 334.315 13.9191C336.717 9.53609 340.385 5.96132 344.849 3.65112C349.441 1.21713 354.934 0.000128076 361.327 0.000128076C365.086 -0.00998791 368.823 0.579211 372.393 1.74511C375.944 2.90843 378.813 4.43708 381 6.33108L376.491 18.0965C374.287 16.4763 371.858 15.1809 369.278 14.2508C366.909 13.4179 364.414 12.9919 361.9 12.9913C356.816 12.9913 352.99 14.4118 350.423 17.2528C347.856 20.0938 346.572 24.1622 346.57 29.4581C346.57 34.8164 347.855 38.9152 350.423 41.7546C352.992 44.594 356.818 46.0137 361.9 46.0137C364.414 46.014 366.909 45.5888 369.278 44.7567C371.858 43.8275 374.288 42.5321 376.491 40.911L381 52.6884C378.813 54.5824 375.944 56.1111 372.393 57.2744C368.822 58.4337 365.085 59.0164 361.327 59.0001Z"
      fill={color}
    />
  </Svg>
);

export default Logo;
