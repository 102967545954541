import FansincAvatar from './fansinc';
import PatronsAvatar from './patrons';

const Avatar = ({ user, loading, size, showVerified, className, preview, ...props }) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return (
      <PatronsAvatar
        user={user}
        loading={loading}
        size={size}
        showVerified={showVerified}
        className={className}
        preview={preview}
        {...props}
      />
    );
  } else {
    return (
      <FansincAvatar
        user={user}
        loading={loading}
        size={size}
        showVerified={showVerified}
        className={className}
        preview={preview}
        {...props}
      />
    );
  }
};

export default Avatar;
