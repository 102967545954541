import Slider from 'react-slick';
import { useState, useRef, useEffect } from 'react';
import { Box, CollectibleCard, CollectibleSkeleton, EmptyBox } from '@components';
import { ReactComponent as ArrowLeftIcon } from '@assets/svg/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '@assets/svg/arrow-right.svg';

const PatronsSlider = ({ collectibles, loading }) => {
  const slideFeaturedRef = useRef();
  const slideFeaturedSettings = {
    dots: true,
    focusOnSelect: false,
    arrows: false,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    customPaging: function (i) {
      return <div></div>;
    },
    dotsClass: 'slick-custom-pagging',
    className: 'collectible-slider-test',
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const useWindowWide = (size) => {
    const [width, setWidth] = useState(0);

    useEffect(() => {
      function handleResize() {
        setWidth(window.innerWidth);
      }

      window.addEventListener('resize', handleResize);

      handleResize();

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [setWidth]);

    return width < size;
  };

  const showArrow = useWindowWide(870);

  const RenderArrows = ({ refVal }) => {
    return (
      <div className="slider-arrow">
        <Box className="arrow-btn slide-arrow-prev" onClick={() => refVal.current.slickPrev()}>
          <Box className="slide-arrow-prev-button">
            <ArrowLeftIcon />
          </Box>
        </Box>
        <Box className="arrow-btn slide-arrow-next" onClick={() => refVal.current.slickNext()}>
          <Box className="slide-arrow-next-button">
            <ArrowRightIcon />
          </Box>
        </Box>
      </div>
    );
  };

  return (
    <Box className="card-slider">
      {collectibles?.length > 0 && !loading ? (
        <>
          {[2, 3].includes(collectibles?.length) && showArrow && (
            <RenderArrows refVal={slideFeaturedRef} />
          )}
          {collectibles?.length > 4 && <RenderArrows refVal={slideFeaturedRef} />}
          <Box className="card-slider__slider">
            <Slider {...slideFeaturedSettings} ref={slideFeaturedRef}>
              {collectibles &&
                collectibles.map((collectible) => (
                  <Box key={collectible.id} className="card-slider__wrapper">
                    <CollectibleCard collectible={collectible} showPrice={false}></CollectibleCard>
                  </Box>
                ))}
            </Slider>
          </Box>
        </>
      ) : (
        <>
          {loading ? (
            <Box className="grid grid-cols-3 gap-5">
              <CollectibleSkeleton />
              <CollectibleSkeleton />
              <CollectibleSkeleton />
            </Box>
          ) : (
            <EmptyBox title="No Artworks Found" />
          )}
        </>
      )}
    </Box>
  );
};

export default PatronsSlider;
