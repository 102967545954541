import cx from 'classnames';
import { Grid, SkeletonLoading } from '@components';

function RelatedProfileGrid({ loading, children, className, ...props }) {
  return (
    <>
      <Grid className={cx('related-profile-grid', className)}>
        {loading && (
          <>
            <SkeletonLoading className="related-profile-grid__skeleton" />
            <SkeletonLoading className="related-profile-grid__skeleton" />
            <SkeletonLoading className="related-profile-grid__skeleton" />
            <SkeletonLoading className="related-profile-grid__skeleton" />
            <SkeletonLoading className="related-profile-grid__skeleton" />
            <SkeletonLoading className="related-profile-grid__skeleton" />
            <SkeletonLoading className="related-profile-grid__skeleton" />
            <SkeletonLoading className="related-profile-grid__skeleton" />
          </>
        )}
        {children}
      </Grid>
    </>
  );
}

export default RelatedProfileGrid;
