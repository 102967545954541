import React from 'react';

import { Box } from '@components';

import { getGalleryImages } from '@pages/print-collectible/fansinc/print-collectible-gallery/utils';
import '@pages/print-collectible/fansinc/print-collectible-gallery/style.css';

const GalleryImageBox = ({ imageUrl }) => {
  return (
    <Box className="print-collectible-gallery__image-box">
      <img src={imageUrl} alt="" />
    </Box>
  );
};

function PrintCollectibleGallery() {
  const galleryImages = getGalleryImages({ lowRes: false });
  return (
    <Box className="print-collectible-gallery">
      <Box className="print-collectible-gallery__inner">
        {galleryImages.map((galleryImage, idx) => (
          <GalleryImageBox key={idx} imageUrl={galleryImage} />
        ))}
      </Box>
    </Box>
  );
}

PrintCollectibleGallery.propTypes = {};

export default PrintCollectibleGallery;
