import { Storage } from 'aws-amplify';
import { createAssetUrl } from '@libs/utils/media';

export const fetchImage = async ({ image, setImage, size = 600 }) => {
  if (image && !image.startsWith('http')) {
    try {
      const s3Key = createAssetUrl(image, size);
      if (s3Key.startsWith('http')) {
        setImage(s3Key);
      } else {
        const result = await Storage.get(s3Key, {
          contentType: 'image/png'
        });
        setImage(result);
      }
    } catch (error) {
      console.error(error);
    }
  }
};

export const fetchAssets = async ({ assets, setAssets, SIZE = 600 }) => {
  if (assets && !assets.startsWith('http')) {
    try {
      const s3Key = createAssetUrl(assets);
      if (s3Key.startsWith('http')) {
        setAssets(s3Key);
      } else {
        const result = await Storage.get(s3Key, {
          contentType: 'image/png'
        });
        setAssets(result);
      }
    } catch (error) {
      console.error(error);
    }
  }
};
