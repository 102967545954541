import React, { useState, useEffect, useContext, createContext } from 'react';

import { useAuth } from '@libs/contexts/auth';
import gql from '@libs/utils/gql';
import { onPublishedNotification } from '@graphql/subscriptions';
import { searchNotifications } from '@libs/custom-queries/notification';

const initialContext = {
  notifications: [],
  clearNotification: () => {},
  setNotificationByType: () => {}
};

const NotificationContext = createContext(initialContext);

const deletedType = [
  'COLLECTIBLE_SOLD',
  'COLLECTIBLE_OFFER_MADE',
  'COLLECTIBLE_OFFER_RECEIVED',
  'COLLECTIBLE_BOUGHT',
  'COLLECTIBLE_OUTBID',
  'COLLECTIBLE_PUBLISHED',
  'COLLECTIBLE_RELEASE_STARTED',
  'USER_FOLLOWED',
  'COLLECTIBLE_OFFER_ACCEPTED',
  'COLLECTIBLE_BID_MADE',
  'COLLECTIBLE_BID_RECEIVED',
  'COLLECTIBLE_BID_DID_NOT_WON',
  'COLLECTIBLE_BID_WON',
  'COLLECTIBLE_SUBMITTED_ADMIN',
  'PAYMENT_FAILED_BUYER',
  'PURCHASE_CONFIRMED_BUYER',
  'USER_USERNAME_UPDATED_USER',
  'USER_VERIFIED_USER',
  'PRINT_PURCHASE_PAYMENT_CONFIRMED',
  'PRINT_ORDER_PAYMENT_CONFIRMED_ADMIN',
  'COLLECTIBLE_RESERVED',
  'WITHDRAW_REQUESTED_ADMIN'
];

export const NotificationProvider = ({ children }) => {
  const { user } = useAuth();
  const [notifications, setNotifications] = useState([]);
  const [unread, setUnread] = useState(0);

  useEffect(() => {
    let subscription;
    const getNotif = async () => {
      const params = {
        sort: {
          direction: 'desc',
          field: 'createdAt'
        },
        filter: {
          userID: { eq: user?.id },
          not: { or: deletedType.map((d) => ({ searchableType: { eq: d } })) }
        }
      };

      const { data: result } = await gql(searchNotifications, params, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });

      if (result?.searchNotifications?.items?.length) {
        const notifications = result.searchNotifications.items.map((notification) => {
          notification.event = JSON.parse(notification.event);
          return notification;
        });
        setNotifications(notifications);
      }

      if (!subscription) {
        const payload = { owner: user?.id };
        subscription = gql(onPublishedNotification, payload, {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }).subscribe({
          next: ({ value }) => {
            try {
              const {
                data: { onPublishedNotification },
                errors
              } = value;

              if (errors) {
                let error = new Error('Notification error', errors);
                errors.forEach((e) => (error.stack += '\n' + e.message));
                throw error;
              }

              const validNotif = !deletedType.includes(onPublishedNotification.type);

              if (validNotif) {
                setNotifications((prev) => {
                  return [
                    {
                      ...onPublishedNotification,
                      event: JSON.parse(onPublishedNotification.event)
                    },
                    ...prev
                  ];
                });
                getNotif();
              }
            } catch (e) {
              console.error(e);
            }
          },
          error: (error) => console.warn(error)
        });
      }
    };

    if (user) {
      getNotif();

      return () => {
        if (subscription) subscription.unsubscribe();
      };
    }
  }, [user]);

  useEffect(() => {
    if (notifications) {
      const countUnread = notifications.filter((n) => n.read === 'FALSE').length;
      setUnread(countUnread);
    }
  }, [notifications]);

  const clearNotification = (id) => {
    setNotifications((prev) => prev.filter((notification) => notification.id !== id));
  };

  const getNotificationByType = (type) => {
    return notifications.find((notification) => notification.type === type);
  };

  const readAllNotifications = () => {
    const markAllAsRead = notifications.map((n) => {
      if (n.read === 'FALSE' && n.searchableRead === 'FALSE') {
        const updatedNotif = { ...n, read: 'TRUE', searchableRead: 'TRUE' };
        return updatedNotif;
      }

      return n;
    });
    setNotifications(markAllAsRead);
  };

  const readNotification = (id) => {
    const updatedNotification = notifications.map((n) => {
      if (n.id === id) {
        const updatedNotif = { ...n, read: 'TRUE', searchableRead: 'TRUE' };
        return updatedNotif;
      }

      return n;
    });
    setNotifications(updatedNotification);
  };

  // add more methods here as necessary

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        unread,
        clearNotification,
        getNotificationByType,
        readAllNotifications,
        readNotification
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => useContext(NotificationContext);
