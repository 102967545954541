import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, Link, Text } from '@components';
import { getUserName } from '@libs/utils/user';

const CardUser = ({ user, className, exchange, ...props }) => {
  const { t } = useTranslation();

  return (
    <Box className={cx('card-user', className)} {...props}>
      <Box className="card-user__text">
        <Box className="card-user__index">
          {exchange?.serialIndex === 1
            ? t('tierType.digitalOriginal')
            : t('tierType.numberedEdition')}{' '}
          {exchange?.serialIndex !== 1 && '#' + exchange?.serialIndex}
        </Box>
        <Link to={`/${user?.username}`}>
          <Box className="card-user__role">{t('components.cardUser.ownedBy')}</Box>
          <Text className="card-user__name">
            {getUserName(user?.firstName)} {user?.lastName}
          </Text>
          <Box className="card-user__link">fans.inc/{getUserName(user?.username)}</Box>
        </Link>
      </Box>
      <Avatar className="card-user__avatar" user={user} size="xl" />
    </Box>
  );
};

export default CardUser;
