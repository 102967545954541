import exchangeBaseQuery from './exchangeBaseQuery';

export const exchangeByCollectibleIDAndCurrentOwnerID = /* GraphQL */ `
  query exchangeByCollectibleAndCurrentOwner(
    $collectibleID: ID
    $currentOwnerID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExchangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    exchangeByCollectibleAndCurrentOwner(
      collectibleID: $collectibleID
      currentOwnerID: $currentOwnerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items ${exchangeBaseQuery}
      nextToken
      startedAt
    }
  }
`;
