import { useEffect, useState } from 'react';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { useCollectibleLike } from '@libs/hooks/collectible-like';
import { Box } from '@components';
import { isNewCollectibleLike } from '@libs/utils/collectible';
import { useAuth } from '@libs/contexts/auth';

const CollectibleLike = ({ collectibleID, ...props }) => {
  const { collectibleLikes, updateCollectibleLikes, collectibleLikesLoading } = useAuth();
  const [isLiked, setIsLiked] = useState(collectibleLikes?.includes(collectibleID));
  const { onLike, loading: likeLoading } = useCollectibleLike(collectibleID, false);
  const newCollectibleLike = isNewCollectibleLike();
  const likeCollectible = async () => {
    if (!likeLoading) {
      setIsLiked(!isLiked);
      if (isLiked) {
        const newCollectibleLikes = collectibleLikes?.filter((x) => x !== collectibleID);
        updateCollectibleLikes(newCollectibleLikes);
      } else {
        updateCollectibleLikes(collectibleLikes.concat(collectibleID));
      }
      await onLike({
        collectibleID: collectibleID,
        onVisit: 'FALSE'
      });
    }
  };
  useEffect(() => {
    setIsLiked(collectibleLikes?.includes(collectibleID));
  }, [collectibleLikes]);
  return (
    newCollectibleLike &&
    !collectibleLikesLoading && (
      <Box onClick={() => likeCollectible()} className="cursor-pointer">
        {isLiked ? (
          <AiFillHeart className="text-red-300" size="20" />
        ) : (
          <AiOutlineHeart className="text-primary" size="20" />
        )}
      </Box>
    )
  );
};

export default CollectibleLike;
