import cx from 'classnames';

const TabButton = ({ buttons, changeTab, activeTab, center, className, childrenButton }) => {
  const defaultStyle = {
    'text-center': center
  };
  return (
    <div className={cx('tab-buttons', defaultStyle, className)}>
      {buttons.map((button, i) => {
        return (
          <button
            type="button"
            key={i}
            className={cx('tab-button', button === activeTab ? 'active' : '')}
            onClick={() => changeTab(button)}
          >
            {button}
          </button>
        );
      })}
      {childrenButton && childrenButton}
    </div>
  );
};

export default TabButton;
