import { Route, Redirect } from 'react-router-dom';

import { useAuth } from '@libs/contexts/auth';

const landingRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useAuth();
  const protectedEnv = ['sandbox', 'prod'];
  return (
    <Route
      {...rest}
      render={(props) => {
        if (protectedEnv.includes(process.env.REACT_APP_AMPLIFY_ENV)) {
          if (isAuthenticated) {
            return <Component {...props} />;
          } else {
            if (process.env.REACT_APP_AMPLIFY_ENV === 'prod') {
              return <Component {...props} />;
            }
            return <Redirect to="/login" />;
          }
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};

export default landingRoute;
