import React from 'react';
import cx from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import { TabButton } from '@components';

const getFirstTruthyChild = (children) => {
  const childrenArray = React.Children.toArray(children);
  if (childrenArray.length) {
    return childrenArray[0];
  }
  return null;
};

const Tabs = ({
  center,
  className,
  classNameChild,
  classNameButton,
  children,
  childrenButton,
  directTo,
  full = false
}) => {
  const [activeTab, setActiveTab] = useState(getFirstTruthyChild(children)?.props?.label);
  const changeTab = useCallback((tab) => {
    setActiveTab(tab);
  }, []);

  useEffect(() => {
    if (React.Children.count(children) && !directTo)
      setActiveTab(getFirstTruthyChild(children)?.props?.label);
    if (directTo) {
      setActiveTab(directTo);
    }
  }, [children, directTo]);

  let content;
  let buttons = [];

  return (
    <div className={cx(className, full ? 'tab-full' : '')}>
      {React.Children.map(children, (child) => {
        if (child) {
          buttons.push(child.props.label);
          if (child.props.label === activeTab) content = child.props.children;
        }
      })}
      <div>
        <TabButton
          className={classNameButton}
          center={center}
          activeTab={activeTab}
          buttons={buttons}
          changeTab={changeTab}
          childrenButton={childrenButton}
        />
      </div>
      <div className={cx(classNameChild)}>{content}</div>
    </div>
  );
};

export default Tabs;
