import cx from 'classnames';
import { useState } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaVideo } from 'react-icons/fa';
import { useVlag } from '@vincere/react-vlag';
import { IoCloseOutline } from 'react-icons/io5';
import {
  Avatar,
  Box,
  Button,
  Container,
  FollowButton,
  Heading,
  Image,
  Link,
  Modal,
  Text,
  SocialMedia,
  ProfileVideo,
  ShareLink,
  UpdateAvatar
} from '@components';
import { useAuth } from '@libs/contexts/auth';
import { isNewCollectibleLike } from '@libs/utils/collectible';
import Login from '@pages/auth/components/login';
import { useChannelByOwner } from '@libs/hooks/discourse';
import { BsFillCameraFill } from 'react-icons/bs';
import { getUserName } from '@libs/utils/user';

const ProfileHeader = ({
  release,
  user,
  loading,
  bannerColor,
  className,
  onHandleFollow,
  followLoading,
  isFollow,
  customFollow,
  refetchData,
  ...props
}) => {
  const { t } = useTranslation();
  const params = useParams();
  const username = params.username.toLowerCase();
  const { data: channels } = useChannelByOwner(user?.id);
  const defaultChannels = channels?.filter((x) => !x?.removable && x?.collectibles?.length > 0)[0];
  const ff = useVlag();
  const { path } = useRouteMatch();
  const { user: auth } = useAuth();
  const [authModal, setAuthModal] = useState(false);
  const [openVideo, setOpenVideo] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const newCollectibleLike = isNewCollectibleLike();

  // Profile menu
  const isActive = (menuPath) => {
    return menuPath === path ? 'active' : '';
  };

  const setAuthModalOpen = () => {
    setAuthModal(true);
  };

  const setAuthModalClose = () => {
    setAuthModal(false);
  };

  const bannerColorClass = {
    fern: 'profile-header__banner--fern',
    sapphireBlue: 'profile-header__banner--sapphireBlue',
    blue: 'profile-header__banner--blue',
    berryRed: 'profile-header__banner--berryRed',
    bloodRed: 'profile-header__banner--bloodRed',
    pineGreen: 'profile-header__banner--pineGreen',
    fernGreen: 'profile-header__banner--fernGreen',
    purple: 'profile-header__banner--purple',
    violet: 'profile-header__banner--violet',
    brown: 'profile-header__banner--brown',
    sandBrown: 'profile-header__banner--sandBrown',
    gold: 'profile-header__banner--gold',
    gray: 'profile-header__banner--gray'
  };

  return (
    <>
      <Box className="profile-header">
        <Box className="profile-header__top">
          <Box className={cx('profile-header__banner', bannerColorClass[bannerColor])}>
            {!loading && (
              <Image
                size={1920}
                className="profile-header__banner__image"
                src={user?.media?.coverUrl}
                fallback={`https://d2znm6dqlk1icp.cloudfront.net/public/covers/FANS-default-cover.jpg`}
                alt={t('components.profileHeader.coverAlt', {
                  name: `${user?.firstName} ${user?.lastName}`
                })}
              />
            )}
          </Box>
          <Box className="profile-header__content">
            <Container>
              <Box
                className="profile-header__avatar"
                onMouseEnter={() => setShowEdit(true)}
                onMouseLeave={() => setShowEdit(false)}
              >
                <Avatar
                  loading={loading}
                  user={user}
                  size="md"
                  showVerified
                  className="profile-header__avatar__image"
                />
                {user?.id === auth?.id && (
                  <Box
                    style={{ display: showEdit ? 'flex' : 'none' }}
                    onClick={() => setOpen(true)}
                    className="profile-header__avatar__upload"
                  >
                    <BsFillCameraFill className="profile-header__avatar__upload-icon" />
                  </Box>
                )}

                {user?.media?.avatarVideoUrl && (
                  <Box onClick={() => setOpenVideo(true)} className="profile-header__avatar__icon">
                    <FaVideo
                      className="profile-header__avatar__icon_shadow cursor-pointer"
                      size={20}
                    />
                  </Box>
                )}
                <ProfileVideo user={user} isOpen={openVideo} onClose={() => setOpenVideo(false)} />
              </Box>
              <Box className="profile-header__content__detail">
                <Box className="profile-header__content__detail__left">
                  <Heading
                    as="h1"
                    className="profile-header__content__detail__name mb-1"
                    loading={loading}
                    skeletonStyle="w-1/2 mb-1"
                  >
                    {user?.displayName || getUserName(user?.firstName) + ' ' + user?.lastName}
                  </Heading>
                  <Text
                    as="span"
                    className="profile-header2__user__handle mr-2 inline-block"
                    loading={loading}
                    skeletonStyle="w-1/4 -mb-4"
                  >
                    {`fans.inc/${getUserName(user?.username)}`}
                  </Text>
                  {!loading && user?.creatorCategory && user?.creatorCategory !== 'FAN' ? (
                    <Text as="span" className="profile-header__avatar__category" loading={loading}>
                      {user?.creatorCategory}
                    </Text>
                  ) : (
                    <></>
                  )}
                </Box>
                <Box className="profile-header__content__detail__right">
                  <SocialMedia
                    socialMedia={user?.links}
                    darkMode
                    className="profile-header__content__detail__right__social-media"
                  />
                  {auth?.username === user?.username && auth?.verified === 'TRUE' ? (
                    <Link to="/manage-collectibles">
                      <Button className="mr-2">
                        {t('components.profileHeader.manageCollectibles')}
                      </Button>
                    </Link>
                  ) : (
                    <></>
                  )}
                  {auth?.username ? (
                    <>
                      {auth?.username !== user?.username ? null : (
                        <Link to="/settings">
                          <Button className="mr-2">
                            {t('components.profileHeader.editProfile')}
                          </Button>
                        </Link>
                      )}
                    </>
                  ) : null}

                  {auth && auth?.username !== user?.username ? (
                    customFollow ? (
                      <Button
                        className="follow-button"
                        default={!isFollow}
                        outline={isFollow}
                        onClick={onHandleFollow}
                        loading={followLoading}
                        disabled={followLoading}
                      >
                        {isFollow
                          ? t('components.profileHeader.subscribed')
                          : t('components.profileHeader.subscribeForUpdates')}
                      </Button>
                    ) : (
                      <FollowButton user={user} />
                    )
                  ) : null}
                  {auth ? null : (
                    <Button onClick={setAuthModalOpen}>
                      {t('components.profileHeader.subscribeForUpdates')}
                    </Button>
                  )}
                </Box>
                {user?.verified === 'TRUE' ? null : (
                  <Modal
                    className="auth-modal"
                    size="md"
                    isOpen={authModal}
                    onClose={setAuthModalClose}
                    verticalCenter
                  >
                    <Box className="auth-modal-container">
                      <Box className="auth-modal-container-close">
                        <IoCloseOutline onClick={setAuthModalClose} size={32} color="gray" />
                      </Box>
                      <Box className="auth-modal-box">
                        <Login onSuccess={setAuthModalClose} />
                      </Box>
                    </Box>
                  </Modal>
                )}
              </Box>
            </Container>
          </Box>
        </Box>
        <Box className="profile-header__nav">
          <Container>
            <Box className="profile-header__nav__wrapper">
              <ul className="profile-header__nav__menu">
                {!loading &&
                  (user?.verified == 'TRUE' ? (
                    <>
                      <li className={isActive('/:username')}>
                        <Link to={'/' + username}>{t('components.profileHeader.linkHome')}</Link>
                      </li>
                      <li className={isActive('/:username/collectibles')}>
                        <Link to={'/' + username + '/collectibles'}>
                          {t('components.profileHeader.linkCollectibles')}
                        </Link>
                      </li>
                      {user?.verified === 'TRUE' && defaultChannels && (
                        <li className={isActive('/:username/fanclub/:channelID')}>
                          <Link to={'/' + username + '/fanclub/' + defaultChannels?.id}>
                            {t('components.profileHeader.fanClub')}
                          </Link>
                        </li>
                      )}
                    </>
                  ) : (
                    <li className={isActive('/:username')}>
                      <Link to={'/' + username}>
                        {t('components.profileHeader.linkCollectibles')}
                      </Link>
                    </li>
                  ))}

                {!loading && user?.verified !== 'TRUE' && (
                  <li className={isActive('/:username/sold')}>
                    <Link to={'/' + username + '/sold'}>
                      {t('components.profileHeader.linkSold')}
                    </Link>
                  </li>
                )}
                {!loading && user?.verified !== 'TRUE' && newCollectibleLike && (
                  <li className={isActive('/:username/liked')}>
                    <Link to={'/' + username + '/liked'}>
                      {t('components.profileHeader.linkLiked')}
                    </Link>
                  </li>
                )}
              </ul>
              <Box>
                <ShareLink right className="profile-header__nav__share" />
              </Box>
            </Box>
          </Container>
        </Box>
        <UpdateAvatar open={open} setOpen={setOpen} refetch={refetchData} />
      </Box>
    </>
  );
};

ProfileHeader.defaultProps = {
  bannerColor: '',
  customFollow: false
};

export default ProfileHeader;
