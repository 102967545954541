import { Box, Avatar, Separator, Media, FollowButton, SkeletonLoading, Link } from '@components';
import { useTranslation } from 'react-i18next';
import { useCollectibleByCreators } from '@libs/hooks/collectible';
import { useLocale } from '@libs/contexts/locale';
import { ReactComponent as ArrowRightBlackIcon } from '@assets/svg/arrow-right-black.svg';

function ArtistCard({ user, homePage }) {
  const { t } = useTranslation();
  const { getCurrencyFormatter } = useLocale();
  const currencyFormatter = getCurrencyFormatter();
  const { total, data, loading } = useCollectibleByCreators(user?.id, 3);
  return (
    <Link className="artist-card" to={`/${user?.username}`}>
      <Box className="artist-card__profile">
        <Box className="artist-card__profile__name">
          <Box>
            <Avatar user={user} size="xs-40" />
          </Box>
          <Box className="ml-4">{user?.displayName}</Box>
        </Box>
        <Box className="artist-card__profile__plus">
          {homePage ? (
            <ArrowRightBlackIcon className="artist-card__profile__arrow" />
          ) : (
            <FollowButton type="icon" user={user} />
          )}
        </Box>
      </Box>
      <Box className="artist-card__bio">{user?.bio}</Box>
      <Separator className="mt-4" />
      <Box className="artist-card__detail">
        <Box className="artist-card__detail__field">
          <Box className="artist-card__detail__field__name">
            {t('p.components.artistCard.artWorks')}
          </Box>
          {loading ? (
            <SkeletonLoading style={{ height: '27px' }} className="w-5" />
          ) : (
            <Box className="artist-card__detail__field__value">{total}</Box>
          )}
        </Box>
        <Box className="artist-card__detail__field">
          <Box className="artist-card__detail__field__name">
            {t('p.components.artistCard.artWorksSold')}
          </Box>
          {loading ? (
            <SkeletonLoading style={{ height: '27px' }} className="w-5" />
          ) : (
            <Box className="artist-card__detail__field__value">{user?.totalSales}</Box>
          )}
        </Box>
        <Box className="artist-card__detail__field">
          <Box className="artist-card__detail__field__name">
            {t('p.components.artistCard.tradeVolume')}
          </Box>
          {loading ? (
            <SkeletonLoading style={{ height: '27px' }} className="w-12" />
          ) : (
            <Box className="artist-card__detail__field__value">
              {currencyFormatter.format(Number(user?.totalSalesVolume))}
            </Box>
          )}
        </Box>
      </Box>
      <Separator className="mt-4" />
      {data?.length > 0 ? (
        <Box className="artist-card__collectible">
          {data?.map((collectible) => (
            <Link
              key={collectible?.id}
              to={`/${collectible?.creator?.username}/${collectible?.slug}`}
            >
              <Media collectible={collectible} thumbnail />
            </Link>
          ))}
        </Box>
      ) : (
        <>
          {loading ? (
            <Box className="artist-card__collectible">
              <SkeletonLoading style={{ height: '110px' }} />
              <SkeletonLoading style={{ height: '110px' }} />
              <SkeletonLoading style={{ height: '110px' }} />
            </Box>
          ) : (
            <Box className="artist-card__collectible__empty">
              <Box>
                <Box className="artist-card__collectible__empty__title">
                  {t('p.pages.profile.museum.empty.title')}
                </Box>
                <Box className="artist-card__collectible__empty__description">
                  {t('p.pages.profile.museum.empty.description')}
                </Box>
              </Box>
            </Box>
          )}
        </>
      )}
    </Link>
  );
}

export default ArtistCard;
