export const collectibleCardFragment = `
fragment CollectibleCardPublic on Collectible {
  id
  userID
  type
  category
  title
  description
  slug
  assetUrl
  mediaThumbnail
  status
  streamExternal
  streamUrl {
    spotify
    appleMusic
    youtube
  }
  releasedAt
  createdAt
  updatedAt
  maxNumberedEditions
  searchableType
  searchableFeatured
  searchablefeaturedInProfile
  searchableCategory
  searchableStatus
  searchableLowestPrice
  searchableHighestPrice
  searchableHighestBidPrice
  searchableGenre
  owner
  promoEnabled
  releases {
    releaseDate
    tierType
    releaseCount
    listingType
    startingPrice
    finalPrice
    preReleaseAvailability
    saleEndDate
  }
}
`;
