import { React } from 'react';
import FansincCheckout from './fansinc';
import PatronsCheckout from './patrons';

function Checkout() {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsCheckout />;
  } else {
    return <FansincCheckout />;
  }
}
export default Checkout;
