import { useState, useEffect, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Link, SkeletonLoading } from '@components';
import { LISTING_TYPES, getNftUrl, NFT_MARKETPLACES } from '@libs/utils/exchange';
import { checkCollectibleObjectType } from '@libs/utils/object';

const LoadingCollectibleCardOptionsMenu = forwardRef(function LoadingCollectibleCardOptions(
  props,
  ref
) {
  return (
    <div ref={ref} className="options-menu">
      <div className="options-menu__dropdown-toggle"></div>
      <ul className="options-menu__dropdown">
        <li>
          <SkeletonLoading className="h-8" />
        </li>
      </ul>
    </div>
  );
});

const CollectibleCardOptionsMenu = forwardRef(function CollectibleCardOptions(
  { exchange, setOpenOptionsMenu },
  ref
) {
  const { t } = useTranslation();
  const [openSeaUrl, setOpenSeaUrl] = useState(null);

  useEffect(() => {
    // Check collectible object type in order to
    // easier debug process while migrating release to exchange
    const dtype = checkCollectibleObjectType(exchange);
    if (dtype !== 'EXCHANGE') {
      console.error('Data type is not supported. Type:', dtype);
    }

    if (exchange && exchange.currentListingType === LISTING_TYPES.NFT) {
      setOpenSeaUrl(getNftUrl({ exchange, marketplace: NFT_MARKETPLACES.OPENSEA_TESTNETS_MUBAI }));
    }
  }, [exchange]);

  if (!exchange) {
    return <LoadingCollectibleCardOptionsMenu ref={ref} />;
  }

  const ViewOnOpenSeaLink = () => {
    if (exchange.currentListingType === LISTING_TYPES.NFT) {
      return (
        <Link to={openSeaUrl} target="_blank">
          <span onClick={() => setOpenOptionsMenu(false)}>
            {t('components.collectibleCard.ownerMenu.viewOnOpenSea')}
          </span>
        </Link>
      );
    }
    return null;
  };

  const PrintItLink = () => (
    <Link to={`/print/${exchange?.creator?.username}/${exchange?.slug}/${exchange?.serialIndex}`}>
      <span onClick={() => setOpenOptionsMenu(false)}>
        {t('components.collectibleCard.ownerMenu.printIt')}
      </span>
    </Link>
  );

  const MintItLink = () => {
    if (exchange.currentListingType !== LISTING_TYPES.NFT) {
      return (
        <Link
          to={`/mint/${exchange?.creator?.username}/${exchange?.slug}/${exchange?.serialIndex}`}
        >
          <span onClick={() => setOpenOptionsMenu(false)}>
            {t('components.collectibleCard.ownerMenu.mintIt')}
          </span>
        </Link>
      );
    }
    return null;
  };

  const FlexItLink = () => (
    <Link to={`/flex/${exchange?.creator?.username}/${exchange?.slug}/${exchange?.serialIndex}`}>
      <span onClick={() => setOpenOptionsMenu(false)}>
        {t('components.collectibleCard.ownerMenu.flexIt')}
      </span>
    </Link>
  );

  const SellItLink = () => {
    if (exchange.currentListingType !== LISTING_TYPES.NFT) {
      return (
        <Link
          to={`/sell/${exchange?.creator?.username}/${exchange?.slug}/${exchange?.serialIndex}`}
        >
          <span onClick={() => setOpenOptionsMenu(false)}>
            {t('components.collectibleCard.ownerMenu.sellIt')}
          </span>
        </Link>
      );
    }
    return null;
  };

  return (
    <div ref={ref} className="options-menu">
      <div className="options-menu__dropdown-toggle"></div>
      <ul className="options-menu__dropdown">
        <li>
          <ViewOnOpenSeaLink />
          <PrintItLink />
          <MintItLink />
          <FlexItLink />
          <SellItLink />
        </li>
      </ul>
    </div>
  );
});

export default CollectibleCardOptionsMenu;
