import { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Alert, Button, Box, Text } from '@components';

import useQuery from '@libs/utils/query';
import { useUser } from '@libs/hooks/user';
import { useAuth } from '@libs/contexts/auth';
import useToast from '@libs/utils/toast';

function AuthActivate() {
  const { t } = useTranslation();
  const query = useQuery();
  const toast = useToast();

  const { error, resendSignUp, resendLoading, checkConfirmedEmail } = useUser();

  const onResend = useCallback(
    () => resendSignUp(decodeURIComponent(query.get('email'))),
    [query.get('email')]
  ); // eslint-disable-line

  const { isAuthenticated } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated) {
      toast(t('auth.activate.alreadyActive'), 'success');
      history.push('/marketplace');
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!query.get('email')) {
      history.push('/login');
    } else {
      checkConfirmedEmail(decodeURIComponent(query.get('email')));
    }
  }, [query]);

  return (
    <div className="auth auth__verification flex items-center">
      <Box>
        <Box className="auth__title uppercase font-bold">{t('auth.activate.heading')}</Box>
        <Text center className="auth__instruction">
          {t('auth.activate.instruction')}
        </Text>
        <div className="auth__form">
          {error && (
            <Alert danger className="mb-4">
              {error}
            </Alert>
          )}
        </div>
        <Button fullWidth onClick={onResend} loading={resendLoading}>
          {t('auth.activate.resend')}
        </Button>
      </Box>
      <Helmet>
        <title>{t('auth.activate.pageTitle')}</title>
        <meta property="og:image" content="https://cdn.fans.inc/public/webapp/og-image.png" />
      </Helmet>
    </div>
  );
}

export default AuthActivate;
