import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import gql from '@libs/utils/gql';

import { generateNFTImageCard } from '@graphql/mutations';
import { Heading, Text, Image, SkeletonLoading } from '@components';
import { exchangePropType } from '@libs/prop-types';

const GeneratingNftImageError = () => {
  const { t } = useTranslation();
  return (
    <>
      <Heading as="h3" className="mb-3">
        {t('components.nftMinterModal.imageCardMissing.heading')}
      </Heading>
      <Text>{t('components.nftMinterModal.imageCardMissing.message')}</Text>
    </>
  );
};

const GeneratingNftSkeletonLoading = () => <SkeletonLoading className="w-full h-96" />;

const NFTImageCard = ({ exchange, setIsNftImageCardReady = () => {} }) => {
  const [isNftImageCardAvailable, setIsNftImageCardAvailable] = useState(true);
  const [isGeneratingNFTImageCard, setIsGeneratingNFTImageCard] = useState(false);
  const [generateImageAttempts, setGenerateImageAttempts] = useState(0);

  useEffect(() => {
    if (isNftImageCardAvailable && !isGeneratingNFTImageCard) {
      setIsNftImageCardReady(true);
      return;
    }
    setIsNftImageCardReady(false);
  }, [isNftImageCardAvailable, isGeneratingNFTImageCard, setIsNftImageCardReady]);

  const resetState = () => {
    setIsNftImageCardAvailable(true);
    setIsGeneratingNFTImageCard(false);
    setGenerateImageAttempts(0);
  };

  useEffect(resetState, [exchange]);

  const onImageError = async () => {
    setIsGeneratingNFTImageCard(true);

    if (generateImageAttempts > 0) {
      setIsNftImageCardAvailable(false);
      return;
    }
    try {
      setGenerateImageAttempts((prev) => prev + 1);
      await gql(
        generateNFTImageCard,
        { input: { exchangeID: exchange.id } },
        {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }
      );
    } catch (e) {
      console.error(e);
      setIsNftImageCardAvailable(false);
    } finally {
      setIsGeneratingNFTImageCard(false);
    }
  };

  if (!isNftImageCardAvailable) {
    return <GeneratingNftImageError />;
  }

  if (isGeneratingNFTImageCard) {
    return <GeneratingNftSkeletonLoading />;
  }

  return (
    <Image
      src={`nft_card/${exchange.collectible.id}_${exchange.serialIndex}`}
      style={{ width: '100%' }}
      onError={onImageError}
    />
  );
};

NFTImageCard.propTypes = {
  exchange: exchangePropType,
  setIsNftCardImageReady: PropTypes.func
};

export default NFTImageCard;
