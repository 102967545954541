import FansincOfferModal from './fansinc';
import PatronsOfferModal from './patrons';

const OfferModal = ({ exchangeOffer, exchangeIntent, isOpen, setIsOpen, onFinish }) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return (
      <PatronsOfferModal
        exchangeOffer={exchangeOffer}
        exchangeIntent={exchangeIntent}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onFinish={onFinish}
      />
    );
  } else {
    return (
      <FansincOfferModal
        exchangeOffer={exchangeOffer}
        exchangeIntent={exchangeIntent}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onFinish={onFinish}
      />
    );
  }
};

export default OfferModal;
