import FansincCollectible from './fansinc';
import PatronsCollectible from './patrons';

function Collectible({ setGalleryLogo }) {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsCollectible setGalleryLogo={setGalleryLogo} />;
  } else {
    return <FansincCollectible />;
  }
}

export default Collectible;
