import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Box, Container } from '@components';

import AboutHero from './components/about-hero';
import AboutJumbotron from './components/about-jumbotron';
import CommunityOwner from './components/community-owner';
import BecomePatrons from './components/become-patrons';
import MeetOurTeam from './components/meet-our-team';
import { kFormatter } from './utils';
import { useCreators, useCollectors } from '@libs/hooks/user';
import { useCollection } from '@libs/hooks/collection';
import { useMarketplace } from '@libs/hooks/marketplace';

function About() {
  const { t } = useTranslation();
  const { total: artistCount } = useCreators();
  const { total: collectorCount } = useCollectors();
  const { total: museumCount } = useCollection();
  const { total: artCount } = useMarketplace();
  const dataHero = [
    { number: kFormatter(artistCount), name: 'artists' },
    { number: kFormatter(artCount), name: 'art pieces' },
    { number: kFormatter(collectorCount), name: 'collectors' },
    { number: kFormatter(museumCount), name: 'museums' }
  ];
  const dataJumbotron = [
    {
      number: '01',
      title: t('p.about.jumbotron.data.collect.title'),
      description: t('p.about.jumbotron.data.collect.description')
    },
    {
      number: '02',
      title: t('p.about.jumbotron.data.contribute.title'),
      description: t('p.about.jumbotron.data.contribute.description')
    },
    {
      number: '03',
      title: t('p.about.jumbotron.data.trade.title'),
      description: t('p.about.jumbotron.data.trade.description')
    }
  ];

  return (
    <>
      <Box>
        <AboutHero
          title={t('p.about.hero.title')}
          description={t('p.about.hero.description')}
          data={dataHero}
        />
        <CommunityOwner
          title={t('p.about.community.owner.title')}
          description={t('p.about.community.owner.description')}
        />
        <BecomePatrons
          title={t('p.about.become.owner.title')}
          description={t('p.about.become.owner.description')}
          secondDescription={t('p.about.become.owner.secondDescription')}
        />
        <AboutJumbotron title={t('p.about.jumbotron.title')} data={dataJumbotron} />
        {/* hide for now */}
        {/* <MeetOurTeam
          title={t('p.about.meet.title')}
          description={t('p.about.meet.description')}
          secondDescription={t('p.about.meet.secondDescription')}
        /> */}
      </Box>
      <Helmet>
        <title>{t('p.about.title')}</title>
      </Helmet>
    </>
  );
}

export default About;
