import { useState, useEffect } from 'react';

const flattenObject = (obj) => Object.keys(obj).map((key) => ({ key, value: obj[key] }));

export const useOptions = ({ options, flattenOptions }) => {
  const [options_, setOptions_] = useState([]);

  useEffect(() => {
    if (flattenOptions) {
      setOptions_(flattenObject(options));
      return;
    }
    setOptions_(options);
  }, [options, flattenOptions]);

  return options_;
};
