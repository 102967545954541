import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Box, Col, Grid, Text, Price } from '@components';
import { useLocale } from '@libs/contexts/locale';

import '@components/order-summary/patrons/style.css';

function OrderSummary({ showVAT, price, marginSize = 'sm' }) {
  const { t } = useTranslation();
  const { calculateTax } = useLocale();

  const bottomMarginClass = {
    sm: 'bm-sm',
    md: 'bm-md'
  }[marginSize];

  return (
    <>
      <Box className="order-summary-patrons">
        {showVAT && (
          <>
            <Box className={`order-summary-patrons__wrapper ${bottomMarginClass}`}>
              <Grid column="2">
                <Col>
                  <Text className="order-summary-patrons__title">
                    {t('components.orderSummary.subtotal')}
                  </Text>
                </Col>
                <Col>
                  <Text className="order-summary-patrons__content__price">
                    <Price
                      amount={calculateTax(price).beforeTax}
                      showCurrency
                      currencyTextColor="black"
                      inline
                    />
                  </Text>
                </Col>
              </Grid>
            </Box>
            <Box className={`order-summary-patrons__wrapper ${bottomMarginClass}`}>
              <Grid column="2">
                <Col>
                  <Text className="order-summary-patrons__title">
                    {t('components.orderSummary.gstVat')}
                  </Text>
                </Col>
                <Col>
                  <Text className="order-summary-patrons__content__price">
                    <Price
                      amount={calculateTax(price).tax}
                      showCurrency
                      currencyTextColor="black"
                      inline
                    />
                  </Text>
                </Col>
              </Grid>
            </Box>
          </>
        )}
        <Box className={`order-summary-patrons__wrapper ${bottomMarginClass}`}>
          <Grid column="2">
            <Col>
              <Text className="order-summary-patrons__title text-primary text-lg font-bold">
                {t('components.orderSummary.total')}
              </Text>
            </Col>
            <Col>
              <Text className="order-summary-patrons__content__price text-primary text-lg">
                <Price
                  amount={calculateTax(price).afterTax}
                  showCurrency
                  currencyTextColor="black"
                  inline
                />
              </Text>
            </Col>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

OrderSummary.propTypes = {
  price: PropTypes.number
};

export default OrderSummary;
