import FansincFollowButton from './fansinc';
import PatronsFollowButton from './patrons';

const FollowButton = ({ user, type, subscribeLabel, unsubscribeLabel }) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return (
      <PatronsFollowButton
        user={user}
        subscribeLabel={subscribeLabel}
        unsubscribeLabel={unsubscribeLabel}
        type={type}
      />
    );
  } else {
    return (
      <FansincFollowButton
        user={user}
        subscribeLabel={subscribeLabel}
        unsubscribeLabel={unsubscribeLabel}
      />
    );
  }
};

export default FollowButton;
