import FansincLogin from './fansinc';
import PatronsLogin from './patrons';

function Login({ defaultEmail, redirect, onSuccess, title, showSocialLogin = true, signupUrl }) {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return (
      <PatronsLogin
        defaultEmail={defaultEmail}
        redirect={redirect}
        onSuccess={onSuccess}
        title={title}
        showSocialLogin={showSocialLogin}
        signupUrl={signupUrl}
      />
    );
  } else {
    return (
      <FansincLogin
        defaultEmail={defaultEmail}
        redirect={redirect}
        onSuccess={onSuccess}
        title={title}
        showSocialLogin={showSocialLogin}
        signupUrl={signupUrl}
      />
    );
  }
}

export default Login;
