import useImage from 'use-image';
import { categoryParser } from '@pages/collectible/fansinc/utils';

export const GetImage = (src) => {
  return useImage(src, 'Anonymous');
};

export const download = (uri, name) => {
  var link = document.createElement('a');
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const imageScale = (image, width, height) => {
  let scaleX = 1;
  let scaleY = 1;
  let offsetX = 0;
  let offsetY = 0;
  let imageOffsetY = 0;
  let imageOffsetX = 0;
  let isVertical = false;
  let imageWidth = image.width;
  let imageHeight = image.height;

  scaleX = width / image.width;
  scaleY = height / image.height;

  if (scaleY > scaleX) {
    offsetX = (image.width * Math.max(scaleX, scaleY) - width) / 1;
  } else {
    offsetY = (image.height * Math.max(scaleX, scaleY) - height) / 1;
  }

  if (image.width >= image.height) {
    isVertical = true;
  }

  if (isVertical) {
    do {
      imageHeight = imageHeight / 1.01;
      imageWidth = imageWidth / 1.01;
    } while (imageHeight > height - 130 || imageWidth > width - 14);

    imageOffsetY = (height - imageHeight - 80) / 2;
    imageOffsetX = (width - imageWidth) / 2;
  } else {
    do {
      imageHeight = imageHeight / 1.01;
      imageWidth = imageWidth / 1.01;
    } while (imageHeight > height - 130 || imageWidth > width - 14);
    imageOffsetY = (height - imageHeight - 80) / 2;
    imageOffsetX = (width - imageWidth) / 2;
  }

  return {
    scaleX,
    scaleY,
    offsetX,
    offsetY,
    width: imageWidth,
    height: imageHeight,
    imageOffsetY,
    imageOffsetX
  };
};

export const titleProps = (text, ratio, x, y) => {
  return {
    text: text.creator,
    fontFamily: "'Avenir Next Condensed'",
    fontStyle: '600',
    fontSize: 40 / ratio,
    x: x,
    y: y + (text.serialIndex ? 5 : 26)
  };
};

export const creatorEditionProps = (text, ratio, x, y) => {
  const creatorName = text.creator;
  const edition = '#' + text.serialIndex;
  const tier = text.serialIndex > 1 ? `Limited Edition ${edition}` : `Digital Original ${edition}`;
  return {
    text: (creatorName + ' / ' + tier).toUpperCase(),
    fontFamily: "'Avenir Next Condensed'",
    fontSize: 24 / ratio,
    x: x,
    y: y + 70 / ratio
  };
};

export const officialProps = (text, ratio, x, y) => {
  const edition = 'Official Limited Edition Digital Collectible ';
  const category = text.category !== 'OTHER' ? categoryParser(text.category) : '';

  return {
    text: (edition + category).toUpperCase(),
    fontFamily: "'Avenir Next Condensed'",
    fontSize: 24 / ratio,
    x: x,
    y: y + (text.serialIndex ? 100 : 98) / ratio
  };
};

export const urlProps = (url, ratio, x, y) => {
  const text = `fans.inc${url}`;
  return {
    text: text.toUpperCase(),
    fontFamily: "'Avenir Next Condensed'",
    rotation: 90,
    fontSize: 24 / ratio,
    x: x - 5,
    y: y
  };
};

function capitalize(str) {
  const lower = str?.toLowerCase();
  return str?.charAt(0).toUpperCase() + lower?.slice(1);
}

export const ownerProps = (text, ratio, x, y) => {
  const ownerText = `Owned by ${
    capitalize(text?.owner?.firstName) + ' ' + capitalize(text?.owner?.lastName)
  }`;
  return {
    text: ownerText,
    fontFamily: "'Avenir Next Condensed'",
    fontSize: 24 / ratio,
    x: x,
    y: y + 130 / ratio
  };
};
