import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import {
  Box,
  CollectibleCard,
  Container,
  CollectibleGrid,
  CollectibleSlider,
  Text
} from '@components';
import { useLatestCollectible } from '@libs/hooks/collectible';
import useAutoExternalSignIn from '@libs/hooks/external-signin';

function Releases() {
  useAutoExternalSignIn();
  const { t } = useTranslation();
  const { data: featuredCollectibles, loading: featuredCollectiblesLoading } =
    useLatestCollectible(5);
  const {
    data: collectibles,
    onUpdate,
    onSort,
    onPaginate,
    pageToken: nextCollectibleToken,
    loading: collectiblesLoading
  } = useLatestCollectible();
  const nextData = async () => {
    await onPaginate(nextCollectibleToken);
  };

  return (
    <>
      <Container className="overflow-hidden">
        <Box className="pt-16">
          <Text className="use-h6 home-title">{t('home.featuredCollectibles')}</Text>
        </Box>
        <Box className="pt-8">
          <CollectibleSlider
            collectibles={featuredCollectibles}
            loading={featuredCollectiblesLoading}
          />
        </Box>
      </Container>
      <Container size="xl" className="py-16">
        <CollectibleGrid
          sortable
          filterable
          showEmpty
          nextData={nextData}
          hasNoMore={!nextCollectibleToken}
          infiniteScroll={true}
          loading={collectiblesLoading}
          onChangeCategory={(value) => onUpdate(value)}
          onChangeSort={(value) => onSort(value)}
        >
          {collectibles &&
            collectibles.map((collectible) => (
              <CollectibleCard key={collectible.id} collectible={collectible}></CollectibleCard>
            ))}
        </CollectibleGrid>
      </Container>
      <Helmet>
        <title>{t('home.meta.releases')}</title>
        <meta property="og:title" content={`${t('home.meta.title')} - Fans.Inc`} />
        <meta property="og:description" content={t('home.meta.description')} />
        <meta property="og:image" content="https://cdn.fans.inc/public/webapp/og-image.png" />
      </Helmet>
    </>
  );
}

export default Releases;
