import FansincButton from './fansinc';
import PatronsButton from './patrons';

const Button = ({
  outline,
  whiteOutline,
  darkOutline,
  link,
  fullWidth,
  size,
  as,
  loading,
  children,
  className,
  success,
  warning,
  danger,
  info,
  form,
  selected,
  fullWhite,
  ...props
}) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return (
      <PatronsButton
        outline={outline}
        whiteOutline={whiteOutline}
        darkOutline={darkOutline}
        link={link}
        fullWidth={fullWidth}
        size={size}
        as={as}
        loading={loading}
        className={className}
        success={success}
        warning={warning}
        danger={danger}
        info={info}
        form={form}
        selected={selected}
        fullWhite={fullWhite}
        {...props}
      >
        {children}
      </PatronsButton>
    );
  } else {
    return (
      <FansincButton
        outline={outline}
        whiteOutline={whiteOutline}
        darkOutline={darkOutline}
        link={link}
        fullWidth={fullWidth}
        size={size}
        as={as}
        loading={loading}
        className={className}
        success={success}
        warning={warning}
        danger={danger}
        info={info}
        form={form}
        selected={selected}
        {...props}
      >
        {children}
      </FansincButton>
    );
  }
};

export default Button;
