import cx from 'classnames';
function Switch({ toggle, setToggle }) {
  const toggleStyle = {
    'transform translate-x-5': toggle,
    'bg-white': toggle,
    'bg-gray-400': !toggle
  };
  const toggleContainer = {
    'bg-green-800': toggle
  };
  return (
    //   Switch Container
    <div
      className={cx('switch', toggleContainer)}
      onClick={() => {
        setToggle(!toggle);
      }}
    >
      {/* Switch */}
      <div className={cx('switch__item', toggleStyle)}></div>
    </div>
  );
}

export default Switch;
