import FansincMarketplace from './fansinc';
import PatronsMarketplace from './patrons';

const Marketplace = () => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsMarketplace />;
  } else {
    return <FansincMarketplace />;
  }
};

export default Marketplace;
