import FansincCollectibleOwnerActions from './fansinc';
import PatronsCollectibleOwnerActions from './patrons';

const CollectibleOwnerActions = ({
  className,
  exchange,
  isCheckoutModal,
  collectible,
  isRelease = true,
  isOwner = false,
  showDescription = false
}) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return (
      <PatronsCollectibleOwnerActions
        className={className}
        exchange={exchange}
        isCheckoutModal={isCheckoutModal}
        collectible={collectible}
        isRelease={isRelease}
        isOwner={isOwner}
        showDescription={showDescription}
      />
    );
  } else {
    return (
      <FansincCollectibleOwnerActions
        className={className}
        exchange={exchange}
        isCheckoutModal={isCheckoutModal}
        collectible={collectible}
        isRelease={isRelease}
        isOwner={isOwner}
        showDescription={showDescription}
      />
    );
  }
};

export default CollectibleOwnerActions;
