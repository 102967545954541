import { useState, useEffect } from 'react';
import gql from '@libs/utils/gql';
import useToast from '@libs/utils/toast';
import { getWallet } from '@libs/custom-queries/wallet';
import { searchWalletHistories } from '@libs/custom-queries/wallet-history';

export const useWalletByOwner = (id) => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  const getData = async (id) => {
    setLoading(true);
    try {
      const { data: res } = await gql(
        getWallet,
        {
          id
        },
        {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }
      );

      if (res?.getWallet) {
        setData(res?.getWallet);
      } else {
        setData(null);
      }
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) getData(id);
  }, [id]); // eslint-disable-line

  return {
    data,
    loading,
    getData
  };
};

export const useWalletHistoryByReceiverOrSenderID = (id) => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  const getData = async (id) => {
    setLoading(true);
    try {
      const params = {
        filter: {
          or: [
            {
              senderID: {
                eq: id
              }
            },
            {
              receiverID: {
                eq: id
              }
            }
          ]
        },

        limit: 10,
        sort: { direction: 'desc', field: 'createdAt' }
      };
      const { data: res } = await gql(searchWalletHistories, params, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      if (res?.searchWalletHistories?.items) {
        setData(res?.searchWalletHistories?.items);
      } else {
        setData(null);
      }
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) getData(id);
  }, [id]); // eslint-disable-line

  return {
    data,
    loading
  };
};

export const useWalletHistoryByOwnerID = (id) => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [nextPageToken, setNextPageToken] = useState();

  const getData = async (id, nextToken) => {
    setLoading(true);
    try {
      const params = {
        filter: {
          owner: {
            eq: id
          },
          or: ['PROCESSED', 'PENDING'].map((d) => ({
            searchableStatus: { eq: d }
          })),
          not: {
            or: ['NFT_ROYALTY', 'NFT_MINT_FEE'].map((d) => ({
              searchableTransactionType: { eq: d }
            }))
          }
        },
        limit: 10,
        sort: { direction: 'desc', field: 'createdAt' }
      };
      if (nextToken) {
        params.nextToken = nextToken;
      }

      const { data: res } = await gql(searchWalletHistories, params, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      if (res?.searchWalletHistories?.items) {
        if (nextToken) setData(data.concat(res?.searchWalletHistories?.items));
        if (!nextToken) setData(res?.searchWalletHistories?.items);

        if (res?.searchWalletHistories?.items?.length < 10) {
          setNextPageToken();
        } else {
          setNextPageToken(res?.searchWalletHistories?.nextToken);
        }
      } else {
        setNextPageToken();
        setData(null);
      }
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) getData(id);
  }, [id]); // eslint-disable-line

  return {
    data,
    loading,
    nextPageToken,
    getData
  };
};

export const useWalletHistoryBySenderAndTransactionType = (senderID, transactionType) => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [nextPageToken, setNextPageToken] = useState();

  const getData = async (senderID, nextToken) => {
    setLoading(true);
    try {
      const params = {
        filter: {
          senderID: {
            eq: senderID
          },
          searchableTransactionType: {
            eq: transactionType
          },
          not: {
            or: ['NFT_ROYALTY', 'NFT_MINT_FEE'].map((d) => ({
              searchableTransactionType: { eq: d }
            }))
          }
        },
        limit: 10,
        sort: { direction: 'desc', field: 'createdAt' }
      };
      if (nextToken) {
        params.nextToken = nextToken;
      }
      const { data: res } = await gql(searchWalletHistories, params, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      if (res?.searchWalletHistories?.items) {
        if (nextToken) setData(data.concat(res?.searchWalletHistories?.items));
        if (!nextToken) setData(res?.searchWalletHistories?.items);

        if (res?.searchWalletHistories?.items?.length < 10) {
          setNextPageToken();
        } else {
          setNextPageToken(res?.searchWalletHistories?.nextToken);
        }
      } else {
        setNextPageToken();
        setData(null);
      }
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (senderID) getData(senderID);
  }, [senderID]); // eslint-disable-line

  return {
    data,
    loading,
    nextPageToken,
    getData
  };
};

export const useWalletHistoryByReceiverAndTransactionType = (receiverID, transactionType) => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [nextPageToken, setNextPageToken] = useState();

  const getData = async (receiverID, nextToken) => {
    setLoading(true);
    try {
      const params = {
        filter: {
          receiverID: {
            eq: receiverID
          },
          searchableTransactionType: {
            eq: transactionType
          },
          not: {
            or: ['NFT_ROYALTY', 'NFT_MINT_FEE'].map((d) => ({
              searchableTransactionType: { eq: d }
            }))
          }
        },
        limit: 10,
        sort: { direction: 'desc', field: 'createdAt' }
      };
      if (nextToken) {
        params.nextToken = nextToken;
      }
      const { data: res } = await gql(searchWalletHistories, params, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      if (res?.searchWalletHistories?.items) {
        if (nextToken) setData(data.concat(res?.searchWalletHistories?.items));
        if (!nextToken) setData(res?.searchWalletHistories?.items);

        if (res?.searchWalletHistories?.items?.length < 10) {
          setNextPageToken();
        } else {
          setNextPageToken(res?.searchWalletHistories?.nextToken);
        }
      } else {
        setNextPageToken();
        setData(null);
      }
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (receiverID) getData(receiverID);
  }, [receiverID]); // eslint-disable-line

  return {
    data,
    loading,
    nextPageToken,
    getData
  };
};
