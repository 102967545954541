import { useState, useEffect } from 'react';
import gql from '@libs/utils/gql';
import useToast from '@libs/utils/toast';
import { searchExchangesLite } from '@libs/custom-queries/exchange';
import { STATUS_TYPES, EXCHANGE_TYPES } from '@libs/utils/exchange';

export const useExchangeByUserSold = (userID, editionNumber = '') => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [pageToken, setPageToken] = useState();
  const [total, setTotal] = useState(0);

  const getData = async (userID, editionNumber = '', nextToken, reloadData) => {
    setLoading(true);
    if (!userID) {
      return;
    }
    try {
      const params = {
        filter: {
          searchableIsSold: { eq: 'TRUE' },
          currentOwnerID: { eq: userID },
          searchableStatus: { ne: STATUS_TYPES.TRANSFERRED_TO_NFT },
          searchableExchangeType: { eq: EXCHANGE_TYPES.LISTING }
        }
      };
      if (editionNumber) {
        params.filter = { serialIndex: { eq: editionNumber } };
      }
      if (nextToken) {
        params.nextToken = nextToken;
      }
      params.limit = 6;
      const { data: res } = await gql(searchExchangesLite, params);

      if (res?.searchExchanges?.total) {
        if (reloadData) {
          setData(res.searchExchanges.items);
          setPageToken(res.searchExchanges.nextToken);
        } else {
          setData(data.concat(res.searchExchanges.items));
          setPageToken(res.searchExchanges.nextToken);
        }
        setTotal(total + res.searchExchanges.total);
      } else {
        setPageToken(null);
      }
      setLoading(false);
    } catch (error) {
      const errorMessage = error?.errors[0]?.message;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getData(userID, editionNumber);
  }, [userID, editionNumber]); // eslint-disable-line

  return {
    loading,
    data,
    pageToken,
    total,
    getData
  };
};
