import cx from 'classnames';
import { useState, useRef, useEffect } from 'react';
import { Box, Button, Container, Link, SkeletonLoading, Separator } from '@components';
import Marquee from './marquee';
import FlexPhone from '@assets/img/patrons/flex-phone.png';
import FlexLargePhone from '@assets/img/patrons/flex-large-phone.png';
import { urlParser } from '@pages/profile/utils';
import FlexTablet from '@assets/img/patrons/flex-tablet.png';
import FlexWideTablet from '@assets/img/patrons/flex-wide-tablet.png';
import FlexDekstop from '@assets/img/patrons/flex-dekstop-monitor.png';
import FlexDekstopFoot from '@assets/img/patrons/flex-dekstop-foot.png';
import { ReactComponent as ArrowDown } from '@assets/svg/flex-arrow-down.svg';
import { useOutsider } from '@libs/utils/outsider';
import { useAuth } from '@libs/contexts/auth';
import { getGalleryImages } from './utils';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useExchangesBySlugAndSerialIndex } from '@libs/hooks/exchange';
import { EXCHANGE_TYPES, STATUS_TYPES } from '@libs/utils/exchange';
import FlexImage from './flex-image';
import gallery1 from '@assets/img/patrons/flex-gallery-1.png';
import gallery2 from '@assets/img/patrons/flex-gallery-2.png';
import gallery3 from '@assets/img/patrons/flex-gallery-3.png';

function PatronsFlex() {
  const type = [
    { value: 'smartphone', label: 'Smartphone (9:16)' },
    { value: 'large_smartphone', label: 'Large smartphone (9:19.5)' },
    { value: 'tablet', label: 'Tablet (4:3)' },
    { value: 'wide_tablet', label: 'Wide Tablet (16:10)' },
    { value: 'desktop', label: 'Desktop (16:9)' }
  ];
  const params = useParams();
  const [display, setDisplay] = useState('smartphone');
  const [displayLabel, setDisplayLabel] = useState('Smartphone (9:16)');
  const slug = params.slug;
  const mediaRef = useRef();
  const { user } = useAuth();

  const [isOpenSelect, setIsOpenSelect] = useState(false);
  const handleCloseSelect = () => setIsOpenSelect(false);
  const { ref: refStyle } = useOutsider(handleCloseSelect);

  const [isDownloadable, setDownloadable] = useState(false);
  const [_url, setUrl] = useState(null);
  const edition = params.edition;
  const { t } = useTranslation();
  const galleryImages = getGalleryImages();
  const { data: exchange, loading: exchangeLoading } = useExchangesBySlugAndSerialIndex({
    slug,
    serialIndex: edition || false,
    filter: {
      searchableIsSold: { eq: 'FALSE' },
      searchableExchangeType: { eq: EXCHANGE_TYPES.LISTING },
      searchableStatus: { ne: STATUS_TYPES.TRANSFERRED_TO_NFT }
    }
  });
  useEffect(() => {
    setDownloadable(user && edition && user && exchange?.currentOwnerID === user?.id);
    if (exchange) {
      let url = urlParser(exchange?.collectible, 'collectible');
      setUrl(url);
    }
    // eslint-disable-next-line
  }, [exchange, user]);

  return (
    <Box className="patrons-flex">
      <Box className="patrons-flex__top">
        <Box className="patrons-flex__top__left">
          <Box className="patrons-flex__top__left__content">
            <Box className="patrons-flex__top__left__content__title">
              {exchangeLoading ? (
                <SkeletonLoading style={{ height: '40px' }} />
              ) : (
                <>{exchange?.collectible?.title}</>
              )}
            </Box>
            <Box className="patrons-flex__top__left__content__form">
              <Box className="patrons-flex__top__left__content__form__label">
                {t('p.pages.flex.label')}
              </Box>
              <Box className="patrons-flex__top__left__content__select__wrapper">
                <Box
                  className="patrons-flex__top__left__content__select"
                  onClick={() => setIsOpenSelect(true)}
                >
                  <Box className="patrons-flex__top__left__content__select__text">
                    {displayLabel}
                  </Box>
                  <Box>
                    <ArrowDown />
                  </Box>
                </Box>
                {isOpenSelect && (
                  <Box className="select-multiple__dropdown overflow-hidden" ref={refStyle}>
                    <ul>
                      {type.map((t) => (
                        <>
                          <li
                            className={cx(display === t.value && 'active')}
                            key={t.value}
                            value={t.value}
                            onClick={() => {
                              setDisplay(t.value);
                              setDisplayLabel(t.label);
                              handleCloseSelect();
                            }}
                          >
                            {t.label}
                          </li>
                          <Separator />
                        </>
                      ))}
                    </ul>
                  </Box>
                )}
              </Box>
            </Box>
            <Box className="patrons-flex__top__left__content__description">
              {t('p.pages.flex.description')}
            </Box>
            {exchangeLoading ? (
              <SkeletonLoading style={{ height: '40px' }} />
            ) : isDownloadable ? (
              <Button
                onClick={() => mediaRef.current.onDownload()}
                className="patrons-flex__top__left__content__button"
                fullWidth
              >
                {t('p.pages.flex.download')}
              </Button>
            ) : (
              <Link to={_url}>
                <Button className="patrons-flex__top__left__content__button" fullWidth>
                  {t('p.pages.flex.getItNow')}
                </Button>
              </Link>
            )}
          </Box>
        </Box>
        <Box className="patrons-flex__top__right">
          <div className="absolute w-full -top-12">
            <Marquee title={t('p.pages.flex.shareIt')} />
          </div>
          <Box className="patrons-flex__top__right__content">
            {!exchangeLoading && (
              <>
                {display === 'smartphone' && (
                  <Box>
                    <Box className={cx(`patrons-flex__top__right__content__template`)}>
                      <img src={FlexPhone} alt="" />
                      <Box className="patrons-flex__top__right__content__template__wrapper">
                        <Box className="patrons-flex__top__right__content__template__image">
                          <Box
                            id="flex-it-main-image"
                            style={{ borderRadius: '36px' }}
                            className="w-full h-full overflow-hidden"
                          >
                            <FlexImage
                              exchange={exchange}
                              loading={exchangeLoading}
                              ref={mediaRef}
                              display={display}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
                {display === 'large_smartphone' && (
                  <Box className={cx(`patrons-flex__top__right__content__template`)}>
                    <img src={FlexLargePhone} alt="" />
                    <Box className="patrons-flex__top__right__content__template__wrapper">
                      <Box className="patrons-flex__top__right__content__template__image">
                        <Box
                          id="flex-it-main-image"
                          style={{ borderRadius: '14px' }}
                          className="w-full h-full overflow-hidden"
                        >
                          <FlexImage
                            exchange={exchange}
                            loading={exchangeLoading}
                            ref={mediaRef}
                            display={display}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
                {display === 'tablet' && (
                  <Box className="patrons-flex__top__right__content__template">
                    <img src={FlexTablet} alt="" />
                    <Box
                      style={{ padding: '18px 37px' }}
                      className="patrons-flex__top__right__content__template__wrapper"
                    >
                      <Box className="patrons-flex__top__right__content__template__image">
                        <Box id="flex-it-main-image" className="w-full h-full overflow-hidden">
                          <FlexImage
                            exchange={exchange}
                            loading={exchangeLoading}
                            ref={mediaRef}
                            display={display}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
                {display === 'wide_tablet' && (
                  <Box className="patrons-flex__top__right__content__template">
                    <img src={FlexWideTablet} alt="" />
                    <Box
                      style={{ padding: '20px' }}
                      className="patrons-flex__top__right__content__template__wrapper"
                    >
                      <Box className="patrons-flex__top__right__content__template__image">
                        <Box
                          id="flex-it-main-image"
                          style={{ borderRadius: '8px' }}
                          className="w-full h-full overflow-hidden"
                        >
                          <FlexImage
                            exchange={exchange}
                            loading={exchangeLoading}
                            ref={mediaRef}
                            display={display}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
                {display === 'desktop' && (
                  <>
                    <Box>
                      <Box className="patrons-flex__top__right__content__template">
                        <img src={FlexDekstop} alt="" />
                        <Box
                          style={{ padding: '6px' }}
                          className="patrons-flex__top__right__content__template__wrapper"
                        >
                          <Box className="patrons-flex__top__right__content__template__image">
                            <Box id="flex-it-main-image" className="w-full h-full overflow-hidden">
                              <FlexImage
                                exchange={exchange}
                                loading={exchangeLoading}
                                ref={mediaRef}
                                display={display}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <img className="z-50 relative" src={FlexDekstopFoot} alt="" />
                    </Box>
                  </>
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Box className="patrons-flex__gallery">
        <Box className="patrons-flex__gallery__inner">
          {galleryImages.map((galleryImage, idx) => (
            <Box key={idx} className="patrons-flex__gallery__inner__image-box">
              <img src={galleryImage} alt="" />
            </Box>
          ))}
        </Box>
      </Box>
      <Container className="patrons-flex__info">
        <Box className="patrons-flex__info__title">{t('p.pages.flex.info.title')}</Box>
        <Box className="patrons-flex__info__body">
          <Box className="patrons-flex__info__body__unit">
            <img src={gallery1} alt="" />
            <Box className="patrons-flex__info__body__unit__body">
              <Box className="patrons-flex__info__body__unit__wrapper">
                <Box className="patrons-flex__info__body__unit__body__title">
                  {t('p.pages.flex.info.share.title')}
                </Box>
                <Box className="patrons-flex__info__body__unit__body__description">
                  {t('p.pages.flex.info.share.description')}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="patrons-flex__info__body__unit">
            <img src={gallery2} alt="" />
            <Box className="patrons-flex__info__body__unit__body">
              <Box className="patrons-flex__info__body__unit__wrapper">
                <Box className="patrons-flex__info__body__unit__body__title">
                  {t('p.pages.flex.info.ownership.title')}
                </Box>
                <Box className="patrons-flex__info__body__unit__body__description">
                  {t('p.pages.flex.info.ownership.description')}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="patrons-flex__info__body__unit">
            <img src={gallery3} alt="" />
            <Box className="patrons-flex__info__body__unit__body">
              <Box className="patrons-flex__info__body__unit__wrapper">
                <Box className="patrons-flex__info__body__unit__body__title">
                  {t('p.pages.flex.info.metaverse.title')}
                </Box>
                <Box className="patrons-flex__info__body__unit__body__description">
                  {t('p.pages.flex.info.metaverse.description')}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
      <Container className="patrons-flex__bottom">
        <Box className="patrons-flex__bottom__body">
          <Box className="patrons-flex__bottom__body__left">
            <Box className="patrons-flex__bottom__body__left__title">
              {t('p.pages.flex.bottom.title')}
            </Box>
            <Box className="patrons-flex__bottom__body__left__description">
              {t('p.pages.flex.bottom.description')}
            </Box>
          </Box>
          <Link to="/marketplace">
            <Box className="patrons-flex__bottom__body__right">
              <Button>{t('p.pages.flex.bottom.button')}</Button>
            </Box>
          </Link>
        </Box>
      </Container>
    </Box>
  );
}

export default PatronsFlex;
