import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { add, format } from 'date-fns';
import PropTypes from 'prop-types';
import { Countdown } from '@components';

const CollectibleTimer = ({ releaseDate, label, className, showLabel, ...props }) => {
  const { t } = useTranslation();
  const [currentTime, setCurrentTime] = useState(new Date().getTime());
  const releaseDateSeconds = new Date(releaseDate).getTime();
  const nextTwoMonths = format(add(new Date(), { months: 2 }), 'T');

  useEffect(() => {
    let intervalId = null;
    if (releaseDate) {
      intervalId = setInterval(() => {
        const now = new Date().getTime();
        setCurrentTime(now);
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [releaseDate]);

  if (!releaseDate) {
    return null;
  }

  return (
    <div className={cx('collectible-timer use-h6')} {...props}>
      {releaseDateSeconds > currentTime ? (
        <div className="collectible-timer__label mr-2">
          {nextTwoMonths < releaseDateSeconds ? (
            <>
              {t('components.collectibleTimer.releaseDate')} {''}
              <div className="collectible-timer__counter">
                {t('components.collectibleTimer.tba')}
              </div>
            </>
          ) : (
            <>
              {showLabel && (label || t('components.collectibleTimer.offerEnds'))}
              <div className="collectible-timer__counter">
                <Countdown
                  time={releaseDateSeconds > currentTime ? releaseDate : releaseDate}
                  showSeconds={false}
                />
              </div>
            </>
          )}
        </div>
      ) : null}
    </div>
  );
};

CollectibleTimer.propTypes = {
  showLabel: PropTypes.bool
};

CollectibleTimer.defaultProps = {
  showLabel: true
};

export default CollectibleTimer;
