import cx from 'classnames';
import { useState, useEffect, useMemo } from 'react';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import { useTranslation, Trans } from 'react-i18next';
import { Box, Text } from '@components';

const Countdays = ({
  time,
  className,
  isRevert,
  showHour,
  showMinute,
  showSecond,
  showExpired,
  ...props
}) => {
  const { t } = useTranslation();
  const ONE_MINUTE = 60;
  const ONE_HOUR = 60 * 60;
  const ONE_DAY = 60 * 60 * 24;
  const ONE_MONTH = 60 * 60 * 24 * 30;
  const ONE_YEAR = 60 * 60 * 24 * 365;
  const [currentTime, setCurrentTime] = useState(new Date().getTime());

  const diffInSeconds = isRevert
    ? differenceInSeconds(new Date(time), currentTime)
    : differenceInSeconds(currentTime, new Date(time));

  const getCoundown = () => {
    let type = 'day';
    if (diffInSeconds <= 1) {
      return {
        time: showExpired ? '' : 0,
        type: showExpired ? 'expired' : type
      };
    }
    const time = Math.floor(diffInSeconds / ONE_DAY);

    if (time >= 365) {
      const time = Math.floor(diffInSeconds / ONE_YEAR);
      return {
        time,
        type: 'year'
      };
    }
    if (time >= 30) {
      const time = Math.floor(diffInSeconds / ONE_MONTH);
      return {
        time,
        type: 'month'
      };
    }
    if (time <= 0 && showHour) {
      const time = Math.floor(diffInSeconds / ONE_HOUR);
      if (time > 0) {
        return {
          time,
          type: 'hour'
        };
      }
    }

    if (time <= 0 && showMinute) {
      const time = Math.floor(diffInSeconds / ONE_MINUTE);
      if (time > 0) {
        return {
          time,
          type: 'minute'
        };
      }
    }

    if (time <= 0 && showSecond) {
      const time = Math.floor(diffInSeconds);
      if (time > 0) {
        return {
          time,
          type: 'second'
        };
      }
    }
    return {
      time,
      type: 'day'
    };
  };

  const countdays = useMemo(getCoundown, [currentTime]); // eslint-disable-line

  useEffect(() => {
    let intervalID = setInterval(() => {
      const now = new Date().getTime();
      setCurrentTime(now);
    }, 1000);
    return () => clearInterval(intervalID);
  }, []);

  return (
    <Box className={cx('countdays', className)} {...props}>
      <Text as="span" className="days">
        {isRevert ? (
          <Trans i18nKey={`countdays.${countdays?.type}${countdays?.time !== 1 ? 's' : ''}In`}>
            {countdays?.time.toString()}
          </Trans>
        ) : (
          <>
            {countdays?.time}
            {t(`countdays.${countdays?.type}${countdays?.time !== 1 ? 's' : ''}Ago`)}
          </>
        )}
      </Text>
    </Box>
  );
};

Countdays.defaultProps = {
  isRevert: false,
  showHour: false,
  showMinute: false,
  showSecond: false,
  showExpired: false
};

export default Countdays;
