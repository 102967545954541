export const LISTING_TYPES = {
  FIXED_PRICE: 'FIXED_PRICE',
  AUCTION: 'AUCTION',
  MAKE_AN_OFFER: 'MAKE_AN_OFFER',
  OFFER: 'OFFER',
  NFT: 'NFT'
}; // deprecated

export const TIER_TYPES = {
  DIGITAL_ORIGINAL: 'DIGITAL_ORIGINAL',
  NUMBERED_EDITION: 'NUMBERED_EDITION'
};

export const createExchangeURL = (exchange) =>
  `/${exchange?.creator?.username}/${exchange?.slug}/${exchange?.serialIndex}/${
    exchange?.currentOwner?.username ?? ''
  }`;

export const getConfirmationUrlFromRelease = (release) =>
  `/confirmation/${release?.slug}/${release?.id}`;

export const filterReleasesByTierType = (releases, tierType) =>
  releases.filter((release) => release.tierType === tierType);

export const getReleasesByTier = (releases) => {
  const releasesByTier = {};
  Object.keys(TIER_TYPES).forEach(
    (TIER_TYPE_KEY) =>
      (releasesByTier[TIER_TYPE_KEY] = filterReleasesByTierType(
        releases,
        TIER_TYPES[TIER_TYPE_KEY]
      ))
  );
  return releasesByTier;
};

export const getReleasesByTierType = (releases, tierType) =>
  releases.filter((release) => release?.tierType === tierType);

export const getAreAnyReleasesSold = (releases) => {
  const isSold = (release) => release?.lastPurchasedAt;
  return releases.some(isSold);
};

export const getAreAllReleasesSold = (releases) => {
  const isSold = (release) => release?.lastPurchasedAt;
  return releases.every(isSold);
};

export const getDoReleasesContainListingTypes = (releases, listingTypes) => {
  const releasesContainsListingType = (listingType) =>
    releases.some((release) => release?.listingType === listingType);
  return listingTypes.every(releasesContainsListingType);
};

export const getLowestPriceReleaseByListingType = (releases, listingType) => {
  const fixedPriceListings = releases.filter((release) => release?.listingType === listingType);
  return fixedPriceListings.reduce((prev, curr) =>
    prev.currentPrice < curr.currentPrice ? prev : curr
  );
};

const listingType = {
  FIXED_PRICE: 'Fixed price',
  AUCTION: 'Auction',
  MAKE_AN_OFFER: 'Open offers',
  OFFER: 'Offer'
};

const listingTranslation = {
  FIXED_PRICE: 'FixedPrice',
  AUCTION: 'Auction',
  MAKE_AN_OFFER: 'MakeAnOffer'
};

export const listingParser = (type) => {
  return listingType[type];
};

export const listingTranslationParser = (type) => {
  return listingTranslation[type];
};

export const getReleaseStatus = (release) => {
  if (new Date().getTime() < new Date(release.releaseDate).getTime()) {
    return 'preRelease';
  }

  if (
    new Date().getTime() > new Date(release.releaseDate).getTime() &&
    new Date().getTime() < new Date(release.saleEndDate).getTime()
  ) {
    return 'onSale';
  }

  if (new Date().getTime() > new Date(release.saleEndDate).getTime()) {
    if (release.saleEndDate) {
      return 'secondarySalesOnly';
    }
    return 'onSale';
  }
};
