import { Helmet } from 'react-helmet';
import cx from 'classnames';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BiPlus, BiMinus } from 'react-icons/bi';
import { Box, Container, Heading } from '@components';
import { faqsPatrons } from '@pages/single-page/utils';
import Fade from '@pages/single-page/components/fade';
import Hero from '@pages/single-page/components/hero';

function Faq() {
  const { t } = useTranslation();
  const [faqIndex, setFaqIndex] = useState(1);

  return (
    <Container size="lg" className="overflow-hidden relative font-Satoshi">
      <Fade className="-right-20 -top-40" />
      <Fade className="-left-80 bottom-0" />
      <Box className="single-page__header__faq">
        <Trans i18nKey="faq.heading"></Trans>
      </Box>
      <Box className="faq">
        {faqsPatrons.map((faq, idx) => (
          <Box
            className={cx('faq-item', faqIndex === idx ? 'faq-item-active' : '')}
            id={faq.alias}
            key={faq.alias}
          >
            <Box className="faq-heading" onClick={() => setFaqIndex(faqIndex === idx ? null : idx)}>
              {faq.title}{' '}
              <span className="faq-icon">{faqIndex === idx ? <BiMinus /> : <BiPlus />}</span>
            </Box>

            <div
              className="faq-content"
              dangerouslySetInnerHTML={{ __html: faq.description }}
            ></div>
          </Box>
        ))}
      </Box>
      <Helmet>
        <title>{t('faq.title')}</title>
        <meta property="og:image" content="https://cdn.fans.inc/public/webapp/og-image.png" />
      </Helmet>
    </Container>
  );
}

export default Faq;
