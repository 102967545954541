import { useTranslation } from 'react-i18next';
import { Box, Modal, Button } from '@components';
import Lottie from 'react-lottie';
import Success from '@lotties/Success';

const WithdrawalSuccess = ({ isOpen, onCancel }) => {
  const { t } = useTranslation();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Success
  };

  const submitModalActionClose = () => {
    onCancel();
  };

  return (
    <Modal showOnlyCloseIcon isOpen={isOpen} onClose={() => submitModalActionClose()}>
      <Box className="withdraw-success-modal">
        <Box className="withdraw-success-modal__icon">
          <Box className="withdraw-success-modal__icon__wrapper">
            <Lottie options={defaultOptions} />
          </Box>
        </Box>
        <Box className="withdraw-success-modal__title">
          {t('p.components.withdrawalModal.success.title')}
        </Box>
        <Box className="withdraw-success-modal__information">
          {t('p.components.withdrawalModal.success.information')}
        </Box>
      </Box>
    </Modal>
  );
};

export default WithdrawalSuccess;
