import { React } from 'react';
import FansincSell from './fansinc';
import PatronsSell from './patrons';

function Sell() {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsSell />;
  } else {
    return <FansincSell />;
  }
}
export default Sell;
