import FansincTable from './fansinc';
import PatronsTable from './patrons';

const Table = ({
  cols,
  rows,
  className,
  children,
  highlightRow,
  border = true,
  valign,
  disabledRows,
  ...props
}) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return (
      <PatronsTable
        cols={cols}
        rows={rows}
        className={className}
        highlightRow={highlightRow}
        border={border}
        valign={valign}
        disabledRows={disabledRows}
        {...props}
      >
        {children}
      </PatronsTable>
    );
  } else {
    return (
      <FansincTable
        cols={cols}
        rows={rows}
        className={className}
        highlightRow={highlightRow}
        border={border}
        valign={valign}
        disabledRows={disabledRows}
        {...props}
      >
        {children}
      </FansincTable>
    );
  }
};

export default Table;
