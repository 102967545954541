import { Box, Avatar, Countdays, EmptyBox } from '@components';
import { useLocale } from '@libs/contexts/locale';
import { useTranslation } from 'react-i18next';

function History({ exchanges, type }) {
  const { t } = useTranslation();
  const { getCurrencyFormatter } = useLocale();
  const currencyFormatter = getCurrencyFormatter();
  const listExchange = exchanges?.filter((x) => x?.creator?.id !== x?.currentOwner?.id);
  const Artists = ({ creator, title, description }) => {
    return (
      <Box className="collectible-unit__artists">
        <Box>
          <Avatar user={creator} size="xs-40" />
        </Box>
        <Box className="collectible-unit__artists__detail">
          <Box className="collectible-unit__artists__detail__name">{title}</Box>
          <Box className="collectible-unit__artists__detail__description">{description}</Box>
        </Box>
      </Box>
    );
  };
  const SaleHistory = ({ exchange }) => {
    const prevOwner = exchange?.previousExchange?.currentOwner;
    const owner = exchange?.currentOwner;
    return (
      <Box className="unit-transaction">
        <Box className="unit-transaction__body">
          <Box className="unit-transaction__body__to">
            <Artists
              creator={prevOwner}
              title="from"
              description={
                prevOwner?.displayName || prevOwner?.firstName + ' ' + prevOwner?.lastName
              }
            />
          </Box>
          <Box className="unit-transaction__body__from">
            <Artists
              creator={owner}
              title="to"
              description={owner?.displayName || owner?.firstName + ' ' + owner?.lastName}
            />
          </Box>
          <Box className="unit-transaction__body__field">
            <Box className="unit-transaction__body__field__name">
              {t('p.pages.release.tab.history.date')}
            </Box>
            <Box className="unit-transaction__body__field__value">
              <Countdays time={exchange?.createdAt} showHour showMinute showSecond />
            </Box>
          </Box>
          <Box className="unit-transaction__body__amount">
            <Box className="unit-transaction__body__field">
              <Box className="unit-transaction__body__field__name">
                {t('p.pages.release.tab.history.amount')}
              </Box>
              <Box className="unit-transaction__body__field__value">
                {currencyFormatter.format(Number(exchange?.startingPrice))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const OfferHistory = ({ exchange }) => {
    const patron = exchange?.patron;
    return (
      <Box className="unit-transaction">
        <Box className="unit-transaction__body">
          <Box className="unit-transaction__body__to">
            <Artists
              creator={patron}
              title="from"
              description={patron?.displayName || patron?.firstName + ' ' + patron?.lastName}
            />
          </Box>
          <Box className="unit-transaction__body__field">
            <Box className="unit-transaction__body__field__name">
              {t('p.pages.release.tab.history.date')}
            </Box>
            <Box className="unit-transaction__body__field__value">
              <Countdays time={exchange?.createdAt} showHour showMinute showSecond />
            </Box>
          </Box>
          <Box className="unit-transaction__body__field">
            <Box className="unit-transaction__body__field__name">
              {t('p.pages.release.tab.history.expiration')}
            </Box>
            <Box className="unit-transaction__body__field__value">
              <Countdays
                isRevert
                time={exchange?.expiryDate}
                showHour
                showMinute
                showSecond
                showExpired
              />
            </Box>
          </Box>
          <Box className="unit-transaction__body__amount">
            <Box className="unit-transaction__body__field">
              <Box className="unit-transaction__body__field__name">
                {t('p.pages.release.tab.history.amount')}
              </Box>
              <Box className="unit-transaction__body__field__value">
                {currencyFormatter.format(Number(exchange.offerPrice))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };
  return (
    <>
      {type === 'sale' ? (
        <>
          {listExchange?.map((exchange) => (
            <SaleHistory key={exchange?.id} exchange={exchange} />
          ))}
        </>
      ) : (
        <>
          {exchanges?.length > 0 ? (
            <>
              {exchanges?.map((exchange) => (
                <OfferHistory key={exchange?.id} exchange={exchange} />
              ))}
            </>
          ) : (
            <EmptyBox title="No offers yet." description="There are no offers for this unit." />
          )}
        </>
      )}
    </>
  );
}

export default History;
