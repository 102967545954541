import { Container } from '@components/index';
import JumbotronCard from './JumbotronCard';

export default function AboutJumbotron({ title, data }) {
  return (
    <div className="bg-primary about__jumbotron">
      <span className="text-center font-Satoshi flex justify-center text-3xl md:text-4xl font-bold pt-24 px-10 md:px-0 mb-10 md:mb-0 ">
        <div style={{ lineHeight: '54px' }} className="max-w-4xl">
          {title}
        </div>
      </span>
      <Container>
        <div className="w-full flex flex-col md:flex-row">
          {data.map((item, i) => (
            <JumbotronCard
              key={i}
              number={item.number}
              title={item.title}
              description={item.description}
            />
          ))}
        </div>
      </Container>
    </div>
  );
}
