import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import ReactPlayer from 'react-player/lazy';
import cx from 'classnames';
import { IoPlaySharp, IoVolumeHighOutline, IoVolumeMuteOutline } from 'react-icons/io5';
import { Storage } from 'aws-amplify';
import { Box, Heading, Logo, Modal, Text } from '@components';
import { createAssetUrl } from '@libs/utils/media';

const ProfileVideo = ({
  user,
  isOpen,
  onClose,
  className,
  children,
  autoplay,
  title,
  description,
  video: customVideo,
  footer,
  verticalCenter = true,
  ...props
}) => {
  const { t } = useTranslation();
  const [video, setVideo] = useState(null);
  const [play, setPlay] = useState(false);
  const [playerData, setPlayerData] = useState({
    volume: 0,
    muted: true
  });
  useEffect(() => {
    async function fetchAssets() {
      if (user?.media?.avatarVideoUrl && !user?.media?.avatarVideoUrl.startsWith('http')) {
        try {
          const s3Key = createAssetUrl(user?.media?.avatarVideoUrl, 600);
          if (s3Key.startsWith('http')) {
            setVideo(s3Key);
          } else {
            const result = await Storage.get(s3Key, { contentType: 'video/mp4' });
            setVideo(result);
          }
        } catch (error) {
          console.error(error);
        }
      }

      if (customVideo) {
        setVideo(customVideo);
      }
    }

    fetchAssets();
  }, [user?.media?.avatarVideoUrl, customVideo]);

  useEffect(() => {
    if (isOpen === true) {
      setPlay(true);
      setPlayerData({ ...playerData, muted: true, volume: 0 });
    }
  }, [isOpen]); // eslint-disable-line

  const handleMuteVolume = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (playerData.volume > 0) {
      setPlayerData({ ...playerData, muted: true, volume: 0 });
    } else {
      setPlayerData({ ...playerData, muted: false, volume: 1 });
    }
  };
  return (
    <Modal
      verticalCenter={verticalCenter}
      isOpen={isOpen}
      onClose={onClose}
      showOnlyCloseIcon
      className={cx('profile-video')}
      customWrapperStyle={cx('profile-video-modal', className)}
    >
      <Heading as="h4" className="profile-video-title">
        {title || t('components.profileVideo.title')} <br />
        {!title &&
          t('components.profileVideo.subtitle', {
            name: user?.displayName ? user?.displayName : `${user?.firstName} ${user?.lastName}`
          })}
      </Heading>
      <Text className="profile-video-description">
        {description ||
          t('components.profileVideo.description', {
            name: user?.displayName ? user?.displayName : `${user?.firstName}`
          })}
      </Text>
      <Box
        className="profile-video-wrapper"
        onClick={() => {
          setPlay(!play);
        }}
      >
        {play === false ? <IoPlaySharp size="30" className="profile-video-icon" /> : null}
        <ReactPlayer
          className="profile-video-item"
          width="100%"
          height="100%"
          playing={play}
          playsinline={true}
          url={video}
          muted={playerData.muted}
          volume={playerData.volume}
          loop={true}
          pip={false}
          config={{ file: { attributes: { disablepictureinpicture: 'true' } } }}
        />
        <Box
          className="profile-video-volume"
          onClickCapture={(e) => {
            e.stopPropagation();
            handleMuteVolume(e);
          }}
        >
          {playerData.volume === 0 ? <IoVolumeMuteOutline /> : <IoVolumeHighOutline />}
        </Box>
      </Box>

      {footer ? (
        footer
      ) : (
        <Box className="profile-video-footer">
          <Logo className="profile-video-footer-logo" />
          <Text className="profile-video-footer-description">
            {t('components.profileVideo.supportingIndependentArtists')}
          </Text>
        </Box>
      )}
    </Modal>
  );
};

ProfileVideo.defaultProps = {
  autoplay: true
};

export default ProfileVideo;
