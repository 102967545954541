import FansincPurchaseHistory from './fansinc';
import PatronsPurchaseHistory from './patrons';

const PurchaseHistoryTable = ({ histories, loading, handleNextPage, nextPage }) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return (
      <PatronsPurchaseHistory
        histories={histories}
        loading={loading}
        handleNextPage={handleNextPage}
        nextPage={nextPage}
      />
    );
  } else {
    return (
      <FansincPurchaseHistory
        histories={histories}
        loading={loading}
        handleNextPage={handleNextPage}
        nextPage={nextPage}
      />
    );
  }
};

export default PurchaseHistoryTable;
