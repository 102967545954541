import FansincReset from './fansinc';
import PatronsReset from './patrons';

function Auth() {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsReset />;
  } else {
    return <FansincReset />;
  }
}

export default Auth;
