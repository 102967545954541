import FansincLogin from './fansinc';
import PatronsLogin from './patrons';

const SocialLogin = ({ className }) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsLogin className={className} />;
  } else {
    return <FansincLogin className={className} />;
  }
};

export default SocialLogin;
