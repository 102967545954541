import cx from 'classnames';
import { useEffect, useState } from 'react';
import { BiQr } from 'react-icons/bi';
import { Box, Media, Text } from '@components';
import { ReactComponent as VIP } from '@assets/svg/vip-gold.svg';
import { ReactComponent as VIPBadge } from '@assets/svg/vip-badge.svg';

import { generateQR } from '@libs/utils/qrcode';

const MediaVIP = ({ collectible, url = null }) => {
  const [qr, setQr] = useState('');
  const [showQR, setShowQR] = useState(false);

  useEffect(async () => {
    const code = await generateQR(`${url}`);
    setQr(code);
  }, [collectible]);

  return (
    <>
      <Box className="relative">
        <Box className={cx('media__vip')}>
          <Box className={cx('media__vip__bg')}>
            <VIP />
          </Box>
          <Box className="media__vip__content">
            <Box className="media__vip__content__media">
              {showQR ? (
                <Box className="media__vip__content__qr" onClick={() => setShowQR(false)}>
                  <Box>
                    <img src={qr} alt="qr" />
                  </Box>
                </Box>
              ) : (
                <>
                  <Media
                    collectible={collectible}
                    thumbnail
                    className="media__vip__content__media__item"
                  />
                  <Box className="media__vip__content__overlay" onClick={() => setShowQR(true)}>
                    <Box>
                      <BiQr size={75} />
                      <Text className="media__vip__content__overlay__text">
                        Click to see the qr code Pass
                      </Text>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
            <Box className="media__vip__content-desc">
              <Text className="media__vip__content__title">{collectible?.title}</Text>
              <VIPBadge className="w-full" />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MediaVIP;
