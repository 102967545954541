import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Box, Container, Modal } from '@components';

import Content from '@components/nft-minter-modal/nft-minter-modal-content';
import '@components/nft-minter-modal/style.css';

function NFTMinterModal({ isOpen, setIsOpen, exchange, refreshReleases }) {
  const { t } = useTranslation();
  const [isCloseDisabled, setIsCloseDisabled] = useState(false);

  const onCloseModal = () => {
    if (isCloseDisabled) return;
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseModal}
      isCheckoutModal
      showCloseIcon={!isCloseDisabled}
      size="lg"
      className="nft-minter-modal"
      fullWidth
      heading={t('components.nftMinterModal.title')}
    >
      <Box className="relative">
        <Container>
          {!exchange ? null : (
            <Content
              exchange={exchange}
              setIsCloseDisabled={setIsCloseDisabled}
              refreshReleases={refreshReleases}
            />
          )}
        </Container>
      </Box>
    </Modal>
  );
}

NFTMinterModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  refreshReleases: PropTypes.func
};

export default NFTMinterModal;
