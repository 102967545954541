import { useEffect, useState } from 'react';
import gql from '@libs/utils/gql';
import useToast from '@libs/utils/toast';
import { quotesProdigi } from '@graphql/queries';
import { searchHistoryOrderPrints } from '@libs/custom-queries/order-print';
import { searchWalletHistories } from '@libs/custom-queries/wallet-history';
import handleGraphQLErrors from '@libs/utils/errors/graphql';
import {
  purchasePrintedCollectible,
  updatePrintMetadata,
  cancelPrintedCollectible
} from '@graphql/mutations';

export const useQuotesProdigi = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);

  const getData = async (params) => {
    setLoading(true);
    try {
      const { data: res } = await gql(
        quotesProdigi,
        {
          input: params
        },
        {
          authMode: 'API_KEY'
        }
      );
      setData(JSON.parse(res?.quotesProdigi));
    } catch (error) {
      console.error(error);
      const errorMessage = error?.errors[0]?.message;
      toast(errorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    data,
    getData
  };
};

export const useOrderHistoryByUserID = (userID) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [nextToken, setNextPageToken] = useState();
  const [data, setData] = useState([]);

  const getData = async (nextToken) => {
    setLoading(true);
    try {
      const params = {
        limit: 10,
        filter: {
          userID: { eq: userID }
        },
        sort: { direction: 'desc', field: 'createdAt' }
      };
      if (nextToken) {
        params.nextToken = nextToken;
      }
      const { data: res } = await gql(searchHistoryOrderPrints, params);
      if (res?.searchOrderPrints?.items?.length) {
        const orderItems = res?.searchOrderPrints?.items;
        let updatedItems = [];
        const { data: walletHistoriesRes } = await gql(
          searchWalletHistories,
          {
            filter: {
              or: orderItems.map((c) => ({ referenceID: { eq: c.id } }))
            }
          },
          {
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          }
        );
        const walletHistories = walletHistoriesRes.searchWalletHistories.items;

        if (walletHistories.length > 0) {
          for (const order of orderItems) {
            for (const history of walletHistories) {
              if (history.referenceID === order.id) {
                updatedItems.push({ ...order, invoiceID: history.invoiceID });
                break;
              }
            }
          }
          setData(data.concat(updatedItems));
        } else {
          setData(data.concat(orderItems));
        }

        if (res?.searchOrderPrints?.items?.length < 10) {
          setNextPageToken();
        } else {
          setNextPageToken(res?.searchOrderPrints?.nextToken);
        }
      } else {
        setNextPageToken();
      }
    } catch (error) {
      toast(error.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [userID]);

  return {
    loading,
    data,
    nextToken,
    getData
  };
};

export const useOrderPrintByID = (id) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const getData = async () => {
    setLoading(true);
    try {
      const params = {
        filter: {
          id: { eq: id }
        }
      };
      const { data: res } = await gql(searchHistoryOrderPrints, params);
      if (res?.searchOrderPrints?.items?.length) {
        setData(res?.searchOrderPrints?.items[0]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [id]);

  return {
    loading,
    data,
    getData
  };
};

export const usePurchasePrinted = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const onPurchase = async (input) => {
    setLoading(true);
    try {
      const { token, exchangeID, sku, copies, color } = input;
      const response = await gql(
        purchasePrintedCollectible,
        {
          input: { token, exchangeID, sku, copies, color }
        },
        {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }
      );
      return response;
    } catch (error) {
      const handledError = handleGraphQLErrors(error);
      toast(handledError?.messages[0], 'error');
      console.error(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const onUpdatePrintMetadata = async (input) => {
    setLoading(true);
    try {
      const { frame, url, exchangeID, cost, certificateUrl } = input;
      await gql(
        updatePrintMetadata,
        {
          input: { frame, url, exchangeID, cost, certificateUrl }
        },
        {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }
      );
    } catch (error) {
      toast(error.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    onPurchase,
    onUpdatePrintMetadata
  };
};

export const usePollOrderPrint = (orderID, interval = 5000, timeout = 20000) => {
  const [loading, setLoading] = useState(true);
  const [orderPrint, setOrderPrint] = useState(null);
  const [totalPollingTime, setTotalPollingTime] = useState(0);
  const [timeoutExceeded, setTimeoutExceeded] = useState(false);
  const getOrderPrintData = async (id) => {
    try {
      setLoading(true);
      const params = {
        filter: {
          id: { eq: id }
        }
      };
      const { data: res } = await gql(searchHistoryOrderPrints, params);
      if (res?.searchOrderPrints?.items?.length) {
        setOrderPrint(res?.searchOrderPrints?.items[0]);
        setTotalPollingTime((prev) => prev + interval);
        setLoading(false);
      } else {
        setOrderPrint(null);
        setTotalPollingTime((prev) => prev + interval);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    let intervalID = null;
    if (orderID) {
      intervalID = setInterval(() => {
        if (orderPrint) {
          clearInterval(intervalID);
        } else {
          if (totalPollingTime > timeout) {
            clearInterval(intervalID);
            setTimeoutExceeded(true);
            return;
          }
          getOrderPrintData(orderID);
        }
      }, interval);
    }
    return () => {
      intervalID && clearInterval(intervalID);
    };
    // eslint-disable-next-line
  }, [orderID, orderPrint, totalPollingTime]);

  return { orderPrint, timeoutExceeded, loading, getOrderPrintData };
};

export const useCancelOrderPrint = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const onCancel = async (orderID) => {
    setLoading(true);
    try {
      const response = await gql(
        cancelPrintedCollectible,
        {
          input: { prodigiOrderID: orderID }
        },
        {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }
      );
      const showResponse = JSON.parse(response?.data?.cancelPrintedCollectible);
      if (showResponse?.outcome === 'Cancelled') {
        toast(showResponse?.message, 'success');
      } else {
        toast(showResponse?.message, 'error');
      }
    } catch (error) {
      toast(error.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  return {
    loading,
    onCancel
  };
};
