import { useState, useEffect, useCallback } from 'react';
import gql from '@libs/utils/gql';
import useToast from '@libs/utils/toast';
import { useUserByUsername } from '@libs/hooks/user';
import { getFeaturedArtistByCreator, listFeaturedArtists } from '@graphql/queries';

export const useFeaturedArtisByCreator = (username) => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { data: user } = useUserByUsername(username);

  const params = { userID: user?.id };

  const getData = useCallback(
    async () => {
      setLoading(true);
      try {
        if (!user) {
          return;
        }

        const { data: res } = await gql(
          getFeaturedArtistByCreator,
          {
            input: { userID: user?.id, type: 'ARTIST' }
          },
          {
            authMode: 'API_KEY'
          }
        );

        const parsedData = res ? JSON.parse(res?.getFeaturedArtistByCreator) : null;

        if (parsedData) {
          const result = parsedData.body.data.sort((a, b) =>
            a.artistName.localeCompare(b.artistName)
          );
          setData(result);
        } else {
          setData([]);
        }
      } catch (error) {
        const errorMessage = error?.errors
          ? error?.errors[0]?.message
          : error.message ?? 'unknown error';
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [user, params] // eslint-disable-line
  );

  useEffect(() => {
    if (user) {
      getData();
    }
  }, [user]); // eslint-disable-line

  return {
    data,
    loading
  };
};

export const useFeaturedArtist = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [activeOptions, setActiveOptions] = useState([]);
  const [value, setValue] = useState([]);
  const getData = async () => {
    setLoading(true);
    try {
      const { data: res } = await gql(listFeaturedArtists);
      if (res?.listFeaturedArtists?.items) {
        setOptions(
          res?.listFeaturedArtists?.items
            .sort((a, b) => a.artistName.localeCompare(b.artistName))
            .map((x) => ({ label: x.artistName, value: x.id })) || []
        );
        setActiveOptions(
          res?.listFeaturedArtists?.items
            .sort((a, b) => a.artistName.localeCompare(b.artistName))
            .filter((x) => x?.collectibleCount > 0)
            .map((x) => ({ label: x.artistName, value: x.id })) || []
        );
      }
    } catch (error) {
      const errorMessage = error.errors[0]?.errorType;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line

  return {
    options,
    activeOptions,
    loading,
    value,
    onChange: (v) => setValue(v),
    getData
  };
};
