import { userPublicFragment } from '@libs/fragments/user';

const findByID = /* GraphQL */ `
  query FindByID($id: ID!) {
    getUser(id: $id) {
      ...UserPublicProfile
    }
  }

  ${userPublicFragment}
`;

export default findByID;
