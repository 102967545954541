import { React } from 'react';
import FansincFaq from './fansinc';
import PatronsFaq from './patrons';

function Faq() {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsFaq />;
  } else {
    return <FansincFaq />;
  }
}
export default Faq;
