import {
  Box,
  Table,
  Container,
  SkeletonLoading,
  ArtistProfile,
  Link,
  Button,
  ArtistCard
} from '@components';
import { useTranslation } from 'react-i18next';
import { useLocale } from '@libs/contexts/locale';
import CollectibleSlider from './collectibleSlider';
import { ReactComponent as ArrowRightBlackIcon } from '@assets/svg/arrow-right-black.svg';

function TopArtists({ topArtists, topArtistsLoading }) {
  const { t } = useTranslation();
  const { getCurrencyFormatter } = useLocale();
  const currencyFormatter = getCurrencyFormatter();
  const exchangeCols = [[], [], [], [], []];

  const exchangeRows = topArtists?.map((artist, index) => [
    [<Box className="font-bold" key={index}>{`#${index + 1}`}</Box>],
    [
      <ArtistProfile
        key={index}
        size="md"
        user={artist}
        title={artist?.displayName || artist?.firstName + ' ' + artist?.lastName}
      />
    ],
    [
      <Box key={index}>
        <Box className="capitalize text-base text-secondary w-44">
          {t('p.pages.landing.tradeVolume')}
        </Box>
        <Box className="text-xl font-bold">
          {currencyFormatter.format(Number(artist?.totalSalesVolume))}
        </Box>
      </Box>
    ],
    [
      <Box key={index}>
        <Box className="capitalize text-base text-secondary w-44">
          {t('p.pages.landing.artworksSold')}
        </Box>
        <Box className="text-xl font-bold">{artist?.totalSales}</Box>
      </Box>
    ],
    [
      <Box className="landing-card-slider py-5" key={index}>
        <Box className="landing-card-slider__wrapper">
          <CollectibleSlider profile={artist} />
        </Box>
      </Box>
    ]
  ]);
  return (
    <>
      {topArtists?.length > 0 ? (
        <Box className="landing-page__artists">
          <Container className="landing-page__artists__container">
            <Box className="landing-page__artists__body">
              <Box className="landing-page__artists__body__top">
                <Box className="landing-page__artists__body__top__title">
                  {t('p.pages.landing.artists.title')}
                </Box>
                <Link className="landing-page__artists__body__top__link" to="/artists">
                  <Box className="landing-page__artists__body__top__explore">
                    <Box className="landing-page__artists__body__top__explore__text pr-2">
                      {t('p.pages.landing.artists.exploreMore')}
                    </Box>
                    <ArrowRightBlackIcon />
                  </Box>
                </Link>
              </Box>
              <Box className="landing-page__artists__body__table">
                <Box className="block sm:hidden">
                  {topArtists?.map((x) => (
                    <Box key={x.id} className="bg-white mb-4">
                      <ArtistCard homePage user={x} />
                    </Box>
                  ))}
                </Box>
                <Box className="hidden sm:block">
                  <Table cols={exchangeCols} rows={exchangeRows} />
                </Box>
              </Box>
              <Link to="/artists">
                <Box className="landing-page__artists__body__bottom">
                  <Button>{t('p.pages.landing.artists.exploreMoreArtists')}</Button>
                </Box>
              </Link>
            </Box>
          </Container>
        </Box>
      ) : (
        <>{topArtistsLoading ? <SkeletonLoading className="w-full h-96" /> : <></>}</>
      )}
    </>
  );
}

export default TopArtists;
