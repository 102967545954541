import { forwardRef } from 'react';
import cx from 'classnames';
import { Box } from '@components';

const Select = forwardRef(function SelectPlain(
  {
    label,
    labelClassName,
    placeholder,
    placeholderHidden,
    children,
    disabled,
    errorMessage,
    fullWidth,
    helpMessage,
    options,
    size,
    value,
    ...props
  },
  ref
) {
  const defaultStyle = {
    'w-full': fullWidth,
    'border-gray-200': true,
    'text-gray-400': true,
    'border-red-700': !!errorMessage
  };

  const sizeMapper = {
    lg: 'select-lg',
    md: 'select-md',
    sm: 'select-sm'
  };

  return (
    <>
      {label && (
        <label
          className={cx('block mb-1 form-label', labelClassName)}
          htmlFor="forms-validationInputCode_error"
        >
          {label}
        </label>
      )}
      <Box className="select-wrapper">
        <select
          className={cx(defaultStyle, sizeMapper[size])}
          disabled={disabled}
          ref={ref}
          value={value}
          {...props}
        >
          {placeholder && (
            <option value="" hidden={placeholderHidden}>
              {placeholder}
            </option>
          )}
          {options &&
            options.map((o) => (
              <option key={o.value} value={o.value}>
                {o.label}
              </option>
            ))}
        </select>
      </Box>

      {errorMessage && <span className="field-help field-help--error">{errorMessage}</span>}
      {helpMessage && <span className="field-help">{helpMessage}</span>}
    </>
  );
});

Select.defaultProps = {
  size: 'md',
  placeholderHidden: false
};

export default Select;
