import cx from 'classnames';
import { Box, Text } from '@components';

const Alert = ({ info, success, danger, warning, heading, action, className, children }) => {
  const activeType = info
    ? 'info'
    : success
    ? 'success'
    : warning
    ? 'warning'
    : danger
    ? 'danger'
    : 'default';

  return (
    <div className={cx('alert', `alert--${activeType}`, className)}>
      {heading && <Text className="alert__heading">{heading}</Text>}
      <Box className="alert__content">{children}</Box>
      {action && <Box className="alert__action">{action}</Box>}
    </div>
  );
};

export default Alert;
