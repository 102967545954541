import { useState } from 'react';
import cx from 'classnames';
import { IoCloseOutline } from 'react-icons/io5';

import { Box, Modal, TierTiles } from '@components';
import { useAuth } from '@libs/contexts/auth';
import { createContent } from '@components/edition-card/utils';
import { useCurrentLiveTime, useTransformTierTilesData } from '@components/edition-card/hooks';
import { useReleasedDigitalOriginalExchange } from './hooks';
import { ReactComponent as SpinnerIcon } from '@assets/svg/spinner.svg';
import { TIER_TYPES } from '@libs/utils/exchange';
import Login from '@pages/auth/components/login';

const EditionCard = ({
  tier,
  tierIndex,
  className,
  collectible,
  onClick,
  onHandleFollow,
  onClickEdition,
  isActiveEdition,
  followLoading,
  isFollow,
  tierTilesData,
  isLoading,
  refetchTierTiles
}) => {
  const { user: auth } = useAuth();
  const [authModal, setAuthModal] = useState(false);
  const currentTime = useCurrentLiveTime();

  // these contain exchange for primary & secondary market eg exchangesData.secondaryMarket
  const { hasExchanges, exchangesData } = useTransformTierTilesData({
    collectible,
    tierTilesData,
    tier
  });

  // will return a digital original exchange if it has been released (otherwise returns null)
  const digitalOriginalExchange = useReleasedDigitalOriginalExchange({
    tier,
    exchangesData,
    currentTime
  });

  const authButtonProps = {
    loading: followLoading,
    isFollow,
    isAuthenticated: !!auth,
    onClick: (e) => {
      if (auth) {
        onHandleFollow(e);
      } else {
        setAuthModal(true);
      }
    }
  };

  if (!exchangesData || !hasExchanges) {
    return null;
  }

  const {
    title,
    value,
    button,
    hasExtraButton,
    onClick: clickContent
  } = createContent(
    tier,
    hasExchanges,
    exchangesData,
    currentTime,
    authButtonProps,
    digitalOriginalExchange,
    onClick,
    auth?.id,
    refetchTierTiles
  );

  const bgMapper = {
    DIGITAL_ORIGINAL: 'bg-digital-original edition-card__digital-original self-stretch',
    NUMBERED_EDITION: 'bg-dark edition-card__numbered-edition self-stretch'
  };
  const editionStyle = {
    'edition-card': true,
    'edition-card-active': isActiveEdition,
    'edition-card-loading': isLoading
  };

  return (
    <>
      <Box
        className={cx(
          editionStyle,
          className,
          bgMapper[tier?.tierType],
          hasExtraButton ? 'two-buttons' : ''
        )}
      >
        {!isLoading ? (
          <TierTiles
            tier={tier}
            tierIndex={tierIndex}
            title={title}
            value={value}
            button={button}
            onClick={clickContent ? clickContent : onClickEdition}
            collectible={collectible}
            currentTime={currentTime}
            hasExchanges={hasExchanges}
            hasExtraButton={hasExtraButton}
            exchangesData={exchangesData}
          />
        ) : (
          <SpinnerIcon
            style={{
              fontSize: 30,
              color: `${tier.tierType === TIER_TYPES.DIGITAL_ORIGINAL ? 'black' : ''}`
            }}
          />
        )}
      </Box>
      <Modal
        className="auth-modal"
        size="md"
        isOpen={authModal}
        onClose={() => setAuthModal(false)}
        verticalCenter
      >
        <Box className="auth-modal-container">
          <Box className="auth-modal-container-close">
            <IoCloseOutline onClick={() => setAuthModal(false)} size={32} color="gray" />
          </Box>
          <Box className="auth-modal-box">
            <Login onSuccess={() => setAuthModal(false)} />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

EditionCard.defaultProps = {
  onClickEdition: () => {},
  isActiveEdition: false
};

export default EditionCard;
