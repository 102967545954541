export const exchangePublicFragment = `
fragment ExchangePublic on Exchange {
    id
    exchangeType
    creatorID
    currentOwnerID
    serialIndex
    listingType
    releaseDate
    isReleased
    sellingPrice
    offerPrice
    startingPrice
    lastPrice
    auctionMinimumAllowedBidPrice
    bidPrice
    maxBidPrice
    expiryDate
    isSold
    patronID
    paymentProvider
    bitpayPaymentRequested
    paymentRequestExpiredAt
    createdAt
    userID
    currentListingType
    lastPurchasedAt
    slug
    tierType
    releaseCount
    previousPrice
    finalPrice
    currentPrice
    parentExchangeID
    searchableIsSold
    searchableHighestPrice
    owner
}
`;
