import { useRef, useEffect } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Slider from 'react-slick';
import { Helmet } from 'react-helmet';
import {
  Box,
  CollectibleCard,
  Container,
  Button,
  Avatar,
  CardSlider,
  CollectibleSkeleton,
  SkeletonLoading,
  Link,
  Text
} from '@components';
import { ReactComponent as ArrowLeftIcon } from '@assets/svg/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '@assets/svg/arrow-right.svg';
import { ReactComponent as Collect } from '@assets/svg/step-1.svg';
import { ReactComponent as Contribute } from '@assets/svg/step-2.svg';
import { ReactComponent as Trade } from '@assets/svg/step-3.svg';
import { faqsHomePatrons } from '@pages/single-page/utils';
import { useLatestCollectible } from '@libs/hooks/collectible';
import useAutoExternalSignIn from '@libs/hooks/external-signin';
import { useLocale } from '@libs/contexts/locale';
import { useCreatorsBySalesVolume, useUsersByCollectionValues } from '@libs/hooks/user';
import { BiPlus, BiMinus } from 'react-icons/bi';
import Explore from './components/explore';
import Collector from './components/collector';
import TopArtist from './components/topArtists';
import { ReactComponent as ArrowRightBlackIcon } from '@assets/svg/arrow-right-black.svg';

const PatronsHomePage = () => {
  useAutoExternalSignIn();
  const { t } = useTranslation();
  const [showNextArrow, setShowNextArrow] = useState(true);
  const [showPrevArrow, setShowPrevArrow] = useState(false);
  const { getCurrencyFormatter } = useLocale();
  const currencyFormatter = getCurrencyFormatter();
  const [faqIndex, setFaqIndex] = useState(1);
  const { data: topArtists, loading: topArtistsLoading } = useCreatorsBySalesVolume();
  const { data: topCollector, loading: topCollectorLoading } = useUsersByCollectionValues();
  const slideShowRef = useRef();
  const slideShowSettings = {
    dots: false,
    focusOnSelect: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'collectible-slider',
    variableWidth: false,
    afterChange: function (index) {
      if (index === slideShowRef.current.props.children.length - 1) {
        setTimeout(
          () => slideShowRef.current?.slickGoTo(0),
          slideShowRef.current.props.autoplaySpeed
        );
      }
    }
  };

  const slideCollectorRef = useRef();
  const slideCollectorSettings = {
    dots: true,
    focusOnSelect: false,
    arrows: false,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    customPaging: function (i) {
      return <></>;
    },
    dotsClass: 'slick-custom-pagging',
    className: 'collectible-slider',
    variableWidth: false,
    afterChange: function (index) {
      if (index * 3 > slideCollectorRef.current.props.children.length) {
        setShowNextArrow(false);
      } else {
        setShowNextArrow(true);
      }
      if (index === 0) {
        setShowPrevArrow(false);
      } else {
        setShowPrevArrow(true);
      }
    },
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          afterChange: function (index) {
            console.log(index);
            if (index * 2 > slideCollectorRef.current.props.children.length) {
              setShowNextArrow(false);
            } else {
              setShowNextArrow(true);
            }
            if (index === 0) {
              setShowPrevArrow(false);
            } else {
              setShowPrevArrow(true);
            }
          }
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          afterChange: function (index) {
            if (index === slideCollectorRef.current.props.children.length - 1) {
              setShowNextArrow(false);
            } else {
              setShowNextArrow(true);
            }
            if (index === 0) {
              setShowPrevArrow(false);
            } else {
              setShowPrevArrow(true);
            }
          }
        }
      }
    ]
  };

  const useWindowWide = (size) => {
    const [width, setWidth] = useState(0);

    useEffect(() => {
      function handleResize() {
        setWidth(window.innerWidth);
      }

      window.addEventListener('resize', handleResize);

      handleResize();

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [setWidth]);

    return width < size;
  };

  const showArrow = useWindowWide(840);

  const RenderArrows = ({ refVal }) => {
    return (
      <div className="slider-arrow">
        <Box className="arrow-btn slide-arrow-prev" onClick={() => refVal.current.slickPrev()}>
          <Box className="slide-arrow-prev-button">
            <ArrowLeftIcon />
          </Box>
        </Box>
        <Box className="slide-arrow-prev"></Box>
        <Box className="arrow-btn slide-arrow-next" onClick={() => refVal.current.slickNext()}>
          <Box className="slide-arrow-next-button">
            <ArrowRightIcon />
          </Box>
        </Box>
      </div>
    );
  };

  const Artists = ({ creator, title, description, size }) => {
    return (
      <Box className="header__container__middle__menu__dropdown__list__artists">
        <Box>
          <Avatar user={creator} size="xs-40" />
        </Box>
        <Box className="header__container__middle__menu__dropdown__list__artists__detail">
          <Box className="header__container__middle__menu__dropdown__list__artists__detail__name">
            {title}
          </Box>
          <Box className="header__container__middle__menu__dropdown__list__artists__detail__title">
            {description || t('components.header.legendaryArtists')}
          </Box>
        </Box>
      </Box>
    );
  };

  const { data: featuredCollectibles, loading: featuredCollectiblesLoading } =
    useLatestCollectible(5);

  const exchangeCols = [[], [], [], []];

  const exchangeRows = topArtists?.map((artist, index) => [
    [<Box className="font-bold" key={index}>{`#${index + 1}`}</Box>],
    [
      <Artists
        key={index}
        creator={artist}
        title={artist?.displayName || artist?.firstName + ' ' + artist?.lastName}
      />
    ],
    [
      <Box key={index}>
        <Box className="capitalize text-base">{t('p.pages.landing.tradeVolume')}</Box>
        <Box className="text-xl font-bold">
          {currencyFormatter.format(Number(artist?.totalSalesVolume))}
        </Box>
      </Box>
    ],
    [
      <Box key={index}>
        <Box className="capitalize text-base">{t('p.pages.landing.artworksSold')}</Box>
        <Box className="text-xl font-bold">{artist?.totalSales}</Box>
      </Box>
    ]
  ]);
  return (
    <>
      <Box className="landing-page">
        <Box className="landing-page__top">
          <Container className="landing-page__top__container">
            <Box className="landing-page__top__grid">
              <Box className="landing-page__top__grid__left">
                <Box className="landing-page__top__grid__left__body">
                  <Text as="h1" className="landing-page__top__grid__left__body__title">
                    {t('p.pages.landing.title')}
                  </Text>
                  <Box className="landing-page__top__grid__left__body__description">
                    <Box className="landing-page__top__grid__left__body__description__wrapper">
                      {t('p.pages.landing.subtitle')}
                    </Box>
                  </Box>
                  <Link to="/marketplace">
                    <Button className="landing-page__top__grid__left__body__button">
                      {t('p.pages.landing.button.startCollecting')}
                    </Button>
                  </Link>
                </Box>
              </Box>
              <Box className="landing-page__top__grid__right">
                {featuredCollectibles?.length && !featuredCollectiblesLoading ? (
                  <Slider {...slideShowSettings} ref={slideShowRef}>
                    {featuredCollectibles &&
                      featuredCollectibles.map((collectible) => (
                        <CollectibleCard
                          key={collectible.id}
                          collectible={collectible}
                          showPrice={false}
                        ></CollectibleCard>
                      ))}
                  </Slider>
                ) : (
                  <CollectibleSkeleton />
                )}
              </Box>
            </Box>
          </Container>
        </Box>
        <Box className="landing-page__featured">
          <Container className="landing-page__featured__container">
            <Box className="vertical-line">
              <Box className="line"></Box>
              <Box className="line"></Box>
              <Box className="line"></Box>
              <Box className="line"></Box>
              <Box className="line"></Box>
            </Box>
            <Box className="landing-page__featured__body">
              <Box className="landing-page__featured__body__title">
                {t('p.pages.landing.featuredArt')}
              </Box>
              <CardSlider
                collectibles={featuredCollectibles}
                loading={featuredCollectiblesLoading}
              />
            </Box>
          </Container>
        </Box>
        <Box className="landing-page__description">
          <Container className="landing-page__description__container">
            <Box className="landing-page__description__body">
              <Box className="landing-page__description__body__title">
                <Box className="landing-page__description__body__title__wrapper">
                  {t('p.pages.landing.description.title')}
                </Box>
              </Box>
              <Box className="landing-page__description__body__steps">
                <Box className="landing-page__description__body__steps__section">
                  <Box className="landing-page__description__body__steps__section__number">
                    {t('p.pages.landing.description.collect.number')}
                  </Box>
                  <Collect />
                  <Box className="landing-page__description__body__steps__section__title">
                    {t('p.pages.landing.description.collect.title')}
                  </Box>
                  <Box className="landing-page__description__body__steps__section__description">
                    {t('p.pages.landing.description.collect.description')}
                  </Box>
                </Box>
                <Box className="landing-page__description__body__steps__section__second">
                  <Box className="landing-page__description__body__steps__section__number">
                    {t('p.pages.landing.description.experience.number')}
                  </Box>
                  <Contribute />
                  <Box className="landing-page__description__body__steps__section__title">
                    {t('p.pages.landing.description.experience.title')}
                  </Box>
                  <Box className="landing-page__description__body__steps__section__description">
                    {t('p.pages.landing.description.experience.description')}
                  </Box>
                </Box>
                <Box className="landing-page__description__body__steps__section">
                  <Box className="landing-page__description__body__steps__section__number">
                    {t('p.pages.landing.description.trade.number')}
                  </Box>
                  <Trade />
                  <Box className="landing-page__description__body__steps__section__title">
                    {t('p.pages.landing.description.trade.title')}
                  </Box>
                  <Box className="landing-page__description__body__steps__section__description">
                    {t('p.pages.landing.description.trade.description')}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
        <Explore />
        <TopArtist topArtists={topArtists} topArtistsLoading={topArtistsLoading} />
        {topCollector?.length > 0 ? (
          <Box className="landing-page__collector">
            <Container className="landing-page__collector__container">
              <Box className="landing-page__collector__container__body">
                <Box className="landing-page__collector__container__body__top">
                  <Box className="landing-page__collector__container__body__top__title">
                    {t('p.pages.landing.collector.title')}
                  </Box>
                  <Link className="landing-page__artists__body__top__link" to="/collectors">
                    <Box className="landing-page__artists__body__top__explore">
                      <Box className="landing-page__artists__body__top__explore__text pr-2">
                        {t('p.pages.landing.artists.exploreMore')}
                      </Box>
                      <ArrowRightBlackIcon />
                    </Box>
                  </Link>
                </Box>
                <Box className="landing-page__collector__container__body__cards">
                  {[2, 3].includes(topCollector?.length) && showArrow && (
                    <RenderArrows refVal={slideCollectorRef} />
                  )}
                  {topCollector?.length > 4 && <RenderArrows refVal={slideCollectorRef} />}
                  <Box className="landing-page__collector__container__body__cards__slider">
                    <Slider {...slideCollectorSettings} ref={slideCollectorRef}>
                      {topCollector &&
                        topCollector?.map((collector) => (
                          <Box
                            key={collector.id}
                            className="landing-page__collector__container__body__cards__wrapper"
                          >
                            <Collector collector={collector} />
                          </Box>
                        ))}
                    </Slider>
                  </Box>
                </Box>
                <Link to="/collectors">
                  <Box className="landing-page__collector__container__body__bottom">
                    <Button>{t('p.pages.landing.collector.explore')}</Button>
                  </Box>
                </Link>
              </Box>
            </Container>
          </Box>
        ) : (
          <>{topCollectorLoading ? <SkeletonLoading className="w-full h-96 my-24" /> : <></>}</>
        )}
        <Box className="landing-page__faq">
          <Container className="landing-page__faq__container">
            <Box className="landing-page__faq__body">
              <Box className="landing-page__faq__body__left">
                <Box className="landing-page__faq__body__left__title">
                  {t('p.pages.landing.faq.title')}
                </Box>
                <Box className="landing-page__faq__body__left__description">
                  {t('p.pages.landing.faq.description')}
                </Box>
                <Link to="/faq">
                  <Box className="landing-page__faq__body__left__button">
                    <Button>{t('p.pages.landing.faq.viewMore')}</Button>
                  </Box>
                </Link>
              </Box>
              <Box className="landing-page__faq__body__right">
                {faqsHomePatrons.map((faq, idx) => (
                  <Box
                    className={cx('faq-item', faqIndex === idx ? 'faq-item-active' : '')}
                    id={faq.alias}
                    key={faq.alias}
                  >
                    <Box
                      className="font-semibold faq-heading text-xl"
                      onClick={() => setFaqIndex(faqIndex === idx ? null : idx)}
                    >
                      <Box className="faq-heading-number">{`0${idx + 1}`}</Box>
                      {faq.title.toLocaleLowerCase()}
                      <Box className="faq-icon">
                        {faqIndex === idx ? (
                          <BiMinus color="#FF4307" />
                        ) : (
                          <BiPlus color="#FF4307" />
                        )}
                      </Box>
                    </Box>

                    <div
                      className="faq-content"
                      dangerouslySetInnerHTML={{ __html: faq.description }}
                    ></div>
                  </Box>
                ))}
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
      <Helmet>
        <title>{t('p.head.meta.title')}</title>
        <meta name="description" content={t('p.head.meta.description')} />
        <meta property="og:title" content={t('p.head.meta.title')} />
        <meta property="og:description" content={t('p.head.meta.description')} />
        <meta property="og:image" content="https://cdn.patrons.art/public/webapp/og-image.png" />
      </Helmet>
    </>
  );
};

export default PatronsHomePage;
