import cx from 'classnames';
import { Storage } from 'aws-amplify';
import { useEffect, useState } from 'react';

const CoverImage = ({ src, preview, className }) => {
  const [image, setImage] = useState();

  useEffect(() => {
    async function fetchImage() {
      if (!src) {
        setImage();
      }
      setImage(src);
      if (src && !src.startsWith('http')) {
        try {
          const result = await Storage.get(src, { contentType: 'image/png' });
          setImage(result);
        } catch (error) {
          console.error(error);
        }
      }
      if (preview) {
        if (preview && !preview.startsWith('http')) {
          try {
            const result = await Storage.get(preview, { contentType: 'image/png' });
            setImage(result);
          } catch (error) {
            console.error(error);
          }
        }
      }
    }

    fetchImage();
  }, [src, preview]);

  let mediaStyle = { backgroundImage: `url(${image || src})` };

  return <div className={cx('absolute inset-0 bg-cover', className)} style={mediaStyle} />;
};

export default CoverImage;
