import { SkeletonLoading } from '@components';

export default function AboutDataFlexing({ number, info }) {
  return (
    <div className="flex flex-col justify-center lg:justify-start items-center lg:items-start w-full lg:w-1/4">
      <p className="about-page-number text-red-500 font-bold font-Satoshi text-center capitalize">
        {number ? <>{number}+</> : <SkeletonLoading className="h-12 w-20" />}
      </p>
      <p className="text-xl text-primary font-Satoshi text-center capitalize font-bold pt-4">
        {info}
      </p>
    </div>
  );
}
