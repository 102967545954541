import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import gql from '@libs/utils/gql';
import useToast from '@libs/utils/toast';
import { useUserByUsername } from '@libs/hooks/user';
import {
  collectibleBySlug,
  searchCollectibles,
  collectibleLikeByCollectible
} from '@libs/custom-queries/collectible';
import {
  getFeaturedArtistByCreator,
  getCollectibleDetail,
  listFeaturedArtistCollectibles,
  listFeaturedArtists
} from '@graphql/queries';
import {
  useCollectibleParams,
  useCollectibleParamsByOwner,
  listEras,
  listGenres
} from '@libs/hooks/collectible/utils';
import { claimCollectible } from '@graphql/mutations';

export const useLatestCollectible = (limit) => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { query: params, onUpdateCategory, onSortBy, onPaginate } = useCollectibleParams(limit);
  const [pageToken, setPageToken] = useState();

  const getData = useCallback(
    async (params) => {
      setLoading(true);
      try {
        const { data: res } = await gql(searchCollectibles, params);
        if (res?.searchCollectibles?.items?.length < 6) {
          setData(data.concat(res.searchCollectibles?.items));
          setPageToken(null);
        } else {
          setData(data.concat(res.searchCollectibles?.items));
          setPageToken(res.searchCollectibles?.nextToken);
        }
      } catch (error) {
        const errorMessage = error?.errors
          ? error?.errors[0]?.message
          : error.message ?? 'unknown error';
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [params] // eslint-disable-line
  );

  useEffect(() => {
    getData(params);
  }, [params]); // eslint-disable-line

  const onUpdate = (categories) => {
    setData([]);
    onUpdateCategory(categories);
  };

  const onSort = (sortBy) => {
    setData([]);
    onSortBy(sortBy);
  };

  return {
    data,
    loading,
    pageToken,
    onUpdate,
    onPaginate,
    onSort
  };
};

export const useSearchCollectiblesListByOwner = (username, type = 'ERA') => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { data: user } = useUserByUsername(username);
  const { query: params } = useCollectibleParamsByOwner(user?.id);

  const getData = useCallback(
    async (params) => {
      setLoading(true);
      let i = 0;
      try {
        if (!user) {
          return;
        }

        if (type === 'ERA') {
          let year = [];

          for (i = 0; i < listEras.length; i++) {
            params.filter = {
              ...params.filter,
              userID: { eq: user?.id },
              yearOfCreation: { gte: listEras[i].min, lte: listEras[i].max }
            };
            const { data: res } = await gql(searchCollectibles, params);
            if (res?.searchCollectibles?.items?.length > 0) {
              year.push(listEras[i].name);
            }
          }

          setData(year);
        } else {
          let genre = [];

          for (i = 0; i < listGenres.length; i++) {
            params.filter = {
              ...params.filter,
              userID: { eq: user?.id },
              searchableGenre: { eq: listGenres[i].value }
            };
            const { data: res } = await gql(searchCollectibles, params);
            if (res?.searchCollectibles?.items?.length > 0) {
              genre.push(listGenres[i]);
            }
          }

          setData(genre);
        }
      } catch (error) {
        const errorMessage = error?.errors
          ? error?.errors[0]?.message
          : error.message ?? 'unknown error';
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [user, params] // eslint-disable-line
  );

  useEffect(() => {
    if (user) {
      getData(params);
    }
  }, [user, params]); // eslint-disable-line

  return {
    data,
    loading
  };
};

export const useSearchCollectiblesByOwner = (username, limit, sortBy, category = null) => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [lastUsername, setLastUsername] = useState();

  const [data, setData] = useState([]);
  const [filter, setFilter] = useState([]);
  const [filterArtistName, setFilterArtistName] = useState([]);
  const { data: user } = useUserByUsername(username);
  const [pageToken, setPageToken] = useState();
  const [total, setTotal] = useState(0);
  const {
    query: params,
    onUpdateCategory,
    onUpdateGenre,
    onUpdateYearRange,
    onUpdateFeaturedArtist,
    onSortBy,
    onPaginate,
    eraParser,
    onUpdateMultipleCaseYearRange,
    onUpdateMultipleCaseFeaturedArtist
  } = useCollectibleParamsByOwner(user?.id, limit, sortBy);

  const getData = useCallback(
    async (params) => {
      setLoading(true);
      try {
        if (!user) {
          return;
        }

        if (category === null) {
          params.filter = {
            ...params.filter,
            searchableCategory: { ne: 'VIP_PASS' },
            userID: { eq: user?.id }
          };
        } else {
          params.filter = {
            ...params.filter,
            searchableCategory: { eq: category },
            userID: { eq: user?.id }
          };
        }
        if (limit) {
          params = {
            ...params,
            limit: 6
          };
        }

        const { data: res } = await gql(searchCollectibles, params);
        //Todo: move logic to the backend
        const { data: filter } = await gql(searchCollectibles, {
          ...params,
          nextToken: null,
          limit: undefined
        });
        setFilter(filter?.searchCollectibles?.items || []);
        const getArtistsIDParams = {
          limit: 10000,
          filter: {
            or: filter?.searchCollectibles?.items.map((f) => ({ collectibleID: { eq: f.id } }))
          }
        };
        if (filter?.searchCollectibles?.items?.length > 0) {
          const { data: artistID } = await gql(listFeaturedArtistCollectibles, getArtistsIDParams);
          const artistsNameParams = {
            limit: 10000,
            filter: {
              or: artistID?.listFeaturedArtistCollectibles?.items.map((a) => ({
                id: { eq: a.featuredArtistID }
              }))
            }
          };
          const { data: artistName } = await gql(listFeaturedArtists, artistsNameParams);
          setFilterArtistName(artistName?.listFeaturedArtists?.items);
        }
        //Todo: move logic to the backend
        const totalParam = params;
        totalParam.limit = undefined;
        totalParam.nextToken = null;
        const { data: totalRes } = await gql(searchCollectibles, totalParam);
        setTotal(totalRes?.searchCollectibles?.total ? totalRes?.searchCollectibles?.total : 0);

        if (lastUsername === username) {
          if (res?.searchCollectibles?.items?.length < 6) {
            setData(data.concat(res.searchCollectibles?.items));
            setPageToken(null);
          } else {
            setData(data.concat(res.searchCollectibles?.items));
            setPageToken(res.searchCollectibles?.nextToken);
          }
        } else {
          if (res?.searchCollectibles?.items?.length < 6) {
            setData(res.searchCollectibles?.items);
            setPageToken(null);
            setLastUsername(username);
          } else {
            setData(res.searchCollectibles?.items);
            setPageToken(res.searchCollectibles?.nextToken);
            setLastUsername(username);
          }
        }
      } catch (error) {
        const errorMessage = error?.errors
          ? error?.errors[0]?.message
          : error.message ?? 'unknown error';
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [user, params] // eslint-disable-line
  );

  useEffect(() => {
    if (user) {
      getData(params);
    }
  }, [user, params]); // eslint-disable-line

  const onUpdate = (categories) => {
    setData([]);
    onUpdateCategory(categories);
  };

  const onSort = (sortBy) => {
    setData([]);
    onSortBy(sortBy);
  };

  const onChangeGenre = (genre) => {
    setData([]);
    onUpdateGenre(genre);
  };

  const onChangeYearRange = (era) => {
    setData([]);
    const parsedEra = eraParser(era);
    if (parsedEra) {
      onUpdateYearRange(parsedEra.min, parsedEra.max);
    } else {
      onUpdateYearRange(null, null);
    }
  };

  const onChangeUpdateMultipleCaseYearRange = (era) => {
    setData([]);
    const parsedEra = eraParser(era);
    if (parsedEra) {
      onUpdateMultipleCaseYearRange(parsedEra.min, parsedEra.max);
    } else {
      onUpdateMultipleCaseYearRange(null, null);
    }
  };

  const onChangeFeaturedArtist = async (featuredArtistID) => {
    const { data: featuredArtistCollectible } = await gql(
      getFeaturedArtistByCreator,
      {
        input: { featuredArtistID: featuredArtistID, type: 'COLLECTIBLE' }
      },
      {
        authMode: 'API_KEY'
      }
    );
    let collectible = [];
    const parsedData = featuredArtistCollectible
      ? JSON.parse(featuredArtistCollectible?.getFeaturedArtistByCreator)
      : null;
    if (parsedData) {
      collectible = parsedData?.body?.data;
    }

    setData([]);
    onUpdateFeaturedArtist(collectible);
  };

  const onChangeMultipleCaseFeaturedArtist = async (featuredArtistID) => {
    if (featuredArtistID) {
      const { data: featuredArtistCollectible } = await gql(
        getFeaturedArtistByCreator,
        {
          input: { featuredArtistID: featuredArtistID, type: 'COLLECTIBLE' }
        },
        {
          authMode: 'API_KEY'
        }
      );
      let collectible = [];
      const parsedData = featuredArtistCollectible
        ? JSON.parse(featuredArtistCollectible?.getFeaturedArtistByCreator)
        : null;
      if (parsedData) {
        collectible = parsedData?.body?.data;
      }
      setData([]);
      onUpdateMultipleCaseFeaturedArtist(collectible);
    } else {
      setData([]);
      onUpdateMultipleCaseFeaturedArtist([]);
    }
  };

  return {
    data,
    loading,
    pageToken,
    filter,
    filterArtistName,
    onUpdate,
    onChangeGenre,
    onChangeYearRange,
    onChangeFeaturedArtist,
    onPaginate,
    onSort,
    onChangeMultipleCaseFeaturedArtist,
    onChangeUpdateMultipleCaseYearRange,
    total
  };
};

export const useSearchCollectiblesByOwnerAndCategory = (user, category) => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const getData = useCallback(
    async () => {
      setLoading(true);
      try {
        if (!user) {
          return;
        }
        const params = {
          filter: {
            userID: { eq: user?.id },
            searchableStatus: { eq: 'PUBLISHED' },
            searchableCategory: { eq: category }
          },
          sort: { direction: 'desc', field: 'createdAt' }
        };
        const { data: res } = await gql(searchCollectibles, params);
        setData(res.searchCollectibles?.items);
      } catch (error) {
        const errorMessage = error?.errors
          ? error?.errors[0]?.message
          : error.message ?? 'unknown error';
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [user] // eslint-disable-line
  );

  useEffect(() => {
    if (user) {
      getData();
    }
  }, [user]); // eslint-disable-line

  return {
    data,
    loading
  };
};

export const useSearchCollectibleByFeatured = (featured, limit = 12, sort = 'DESC') => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        const params = {
          limit,
          filter: {
            searchableStatus: { eq: 'PUBLISHED' },
            searchableFeatured: { eq: 'TRUE' }
          },
          sort: { direction: 'desc', field: 'createdAt' }
        };

        const { data: res } = await gql(searchCollectibles, params);
        if (res?.searchCollectibles?.items?.length) {
          setData(res.searchCollectibles.items);
        } else {
          setData([]);
        }
      } catch (error) {
        const errorMessage = error.errors[0]?.message;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
    getData();
  }, [featured, limit, sort]); // eslint-disable-line

  return {
    data,
    loading
  };
};

export const useAuthorCollectibles = ({ userID, collectibleID }) => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const getData = async () => {
    setLoading(true);
    try {
      const params = {
        limit: 3,
        filter: {
          searchableStatus: { eq: 'PUBLISHED' },
          searchableCategory: { ne: 'VIP_PASS' },
          userID: { eq: userID },
          id: { ne: collectibleID }
        },
        sort: { direction: 'desc', field: 'createdAt' }
      };

      const { data: res } = await gql(searchCollectibles, params);
      if (res?.searchCollectibles?.items?.length) {
        setData(res.searchCollectibles?.items);
      } else {
        setData([]);
      }
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userID) getData();
  }, [userID, collectibleID]); // eslint-disable-line

  return {
    data,
    loading
  };
};

export const usePrintableCollectibles = ({ userID, collectibleID }) => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const getData = async () => {
    setLoading(true);
    try {
      const params = {
        limit: 3,
        filter: {
          searchableStatus: { eq: 'PUBLISHED' },
          searchableCategory: { ne: 'VIP_PASS' },
          printable: { eq: 'TRUE' },
          id: { ne: collectibleID }
        },
        sort: { direction: 'desc', field: 'createdAt' }
      };

      const { data: res } = await gql(searchCollectibles, params);
      if (res?.searchCollectibles?.items?.length) {
        setData(res.searchCollectibles?.items);
      } else {
        setData([]);
      }
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userID) getData();
  }, [userID, collectibleID]); // eslint-disable-line

  return {
    data,
    loading
  };
};

export const useCollectiblesByOwnerFeatured = (user, limit) => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  useEffect(() => {
    async function getData() {
      setLoading(true);
      if (!user) {
        return;
      }
      try {
        const params = {
          limit: limit,
          filter: {
            searchableStatus: { eq: 'PUBLISHED' },
            searchableCategory: { ne: 'VIP_PASS' },
            userID: { eq: user?.id }
          },
          sort: { direction: 'desc', field: 'createdAt' }
        };
        const { data: res } = await gql(searchCollectibles, params);

        if (res?.searchCollectibles?.items?.length) {
          setData(res.searchCollectibles.items);
        } else {
          setData([]);
        }
      } catch (error) {
        const errorMessage = error.errors?.message;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    if (user) {
      getData();
    }
  }, [user, limit]); // eslint-disable-line

  return {
    data,
    loading
  };
};

export const useCollectiblesByCreatorFeatured = (user, limit) => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  useEffect(() => {
    async function getData() {
      setLoading(true);
      if (!user) {
        return;
      }
      try {
        const params = {
          limit: limit,
          filter: {
            searchableStatus: { eq: 'PUBLISHED' },
            searchableCategory: { ne: 'VIP_PASS' },
            searchablefeaturedInProfile: { eq: 'TRUE' },
            userID: { eq: user?.id }
          },
          sort: { direction: 'desc', field: 'createdAt' }
        };
        const { data: res } = await gql(searchCollectibles, params);

        if (res?.searchCollectibles?.items?.length) {
          setData(res.searchCollectibles.items);
        } else {
          setData([]);
        }
      } catch (error) {
        const errorMessage = error.errors?.message;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    if (user) {
      getData();
    }
  }, [user, limit]); // eslint-disable-line

  return {
    data,
    loading
  };
};

export const useCollectible = (slug, isDraft = false) => {
  const toast = useToast();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [claimLoading, setClaimLoading] = useState(false);
  const [claimSuccess, setClaimSuccess] = useState(false);
  const [data, setData] = useState();
  const [exchange, setExchange] = useState();

  const getData = async (slug) => {
    setLoading(true);
    try {
      const { data: res } = await gql(collectibleBySlug, {
        slug,
        filter: { status: { eq: isDraft ? 'DRAFT' : 'PUBLISHED' } }
      });

      if (res?.collectibleBySlug?.items?.length) {
        const sortCollectibles = res.collectibleBySlug.items.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        );
        setData(sortCollectibles[sortCollectibles.length - 1]);
      } else {
        setData(null);
      }
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onClaim = useCallback(
    async (payload) => {
      setClaimLoading(true);
      try {
        const { data: res } = await gql(
          claimCollectible,
          {
            input: {
              collectibleID: payload.collectibleID
            }
          },
          {
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          }
        );
        setClaimSuccess(true);
        const parsedData = res ? JSON.parse(res?.claimCollectible) : null;
        if (parsedData) {
          setExchange(parsedData?.body?.exchange);
        }
        toast(t('components.claimCollectibleCard.message.success'), 'success');
      } catch (error) {
        const errorMessage = error?.errors[0]?.message;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setClaimLoading(false);
      }
    },
    [toast]
  );

  useEffect(() => {
    if (slug) getData(slug);
  }, [slug]); // eslint-disable-line

  return {
    data,
    loading,
    onClaim,
    claimLoading,
    claimSuccess,
    exchange,
    refetch: () => slug && getData(slug)
  };
};

export const useCollectibleDetail = (collectibleID, type = 'DIGITAL_SUPPLY') => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  const getData = async (collectibleID) => {
    setLoading(true);

    try {
      const { data: res } = await gql(
        getCollectibleDetail,
        {
          input: { collectibleID: collectibleID, type: type }
        },
        {
          authMode: 'API_KEY'
        }
      );

      const parsedData = res ? JSON.parse(res?.getCollectibleDetail) : null;
      if (parsedData) {
        setData(parsedData?.body);
      } else {
        setData();
      }
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (collectibleID) getData(collectibleID);
  }, [collectibleID]); // eslint-disable-line

  return {
    data,
    loading
  };
};

export const useCollectiblesByIDs = (IDs) => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        const params = {
          filter: {
            searchableStatus: { eq: 'PUBLISHED' },
            or: IDs?.map((i) => ({ id: { eq: i } }))
          },
          sort: { direction: 'desc', field: 'createdAt' }
        };
        const { data: res } = await gql(searchCollectibles, params);

        if (res?.searchCollectibles?.items?.length) {
          setData(res.searchCollectibles.items);
        } else {
          setData([]);
        }
      } catch (error) {
        const errorMessage = error.errors?.message;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    if (IDs) {
      getData();
    }
  }, [IDs]); // eslint-disable-line

  return {
    data,
    loading
  };
};

export const useCollectibleLikesCountByCollectibleID = (collectibleID) => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(0);
  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        const { data: res } = await gql(collectibleLikeByCollectible, {
          collectibleID
        });

        if (res?.collectibleLikeByCollectible?.items?.length) {
          setData(res?.collectibleLikeByCollectible?.items?.length);
        } else {
          setData(0);
        }
      } catch (error) {
        const errorMessage = error.errors?.message;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    if (collectibleID) {
      getData();
    }
  }, [collectibleID]); // eslint-disable-line

  return {
    data,
    loading
  };
};

export const useCollectibleByOwners = (userID, limit = 6) => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        const params = {
          filter: {
            searchableStatus: { eq: 'PUBLISHED' },
            userID: { eq: userID }
          },
          limit: limit,
          sort: { direction: 'desc', field: 'createdAt' }
        };
        const { data: res } = await gql(searchCollectibles, {
          ...params
        });

        if (res?.searchCollectibles?.items?.length) {
          setData(res?.searchCollectibles?.items);
        } else {
          setData([]);
        }
      } catch (error) {
        const errorMessage = error.errors?.message;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    if (userID) {
      getData();
    }
  }, [userID]); // eslint-disable-line

  return {
    data,
    loading
  };
};

export const useCollectibleByCollection = (collectionID, limit = 10) => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        const params = {
          filter: {
            searchableStatus: { eq: 'PUBLISHED' },
            collectionID: { eq: collectionID }
          },
          limit: limit,
          sort: { direction: 'desc', field: 'createdAt' }
        };
        const { data: res } = await gql(searchCollectibles, {
          ...params
        });

        if (res?.searchCollectibles?.items?.length) {
          setData(res?.searchCollectibles?.items);
          setTotal(res?.searchCollectibles?.total);
        } else {
          setData([]);
        }
      } catch (error) {
        const errorMessage = error.errors?.message;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    if (collectionID) {
      getData();
    }
  }, [collectionID]); // eslint-disable-line

  return {
    data,
    loading,
    total
  };
};

export const useSearchCollectiblesByCollection = (collectionID, limit, sortBy, category = null) => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [lastUsername, setLastUsername] = useState();

  const [data, setData] = useState([]);
  const [filter, setFilter] = useState([]);
  const [filterArtistName, setFilterArtistName] = useState([]);
  const [pageToken, setPageToken] = useState();
  const [total, setTotal] = useState(0);
  const {
    query: params,
    onUpdateCategory,
    onUpdateGenre,
    onUpdateYearRange,
    onUpdateFeaturedArtist,
    onSortBy,
    onPaginate,
    onChangeTitle,
    eraParser,
    onChangeUser,
    onUpdateMultipleCaseYearRange,
    onUpdateMultipleCaseFeaturedArtist
  } = useCollectibleParamsByOwner(collectionID, limit, sortBy);

  const getData = useCallback(
    async (params) => {
      setLoading(true);
      try {
        if (!collectionID) {
          return;
        }

        if (category === null) {
          params.filter = {
            ...params.filter,
            searchableCategory: { ne: 'VIP_PASS' },
            collectionID: { eq: collectionID }
          };
        } else {
          params.filter = {
            ...params.filter,
            searchableCategory: { eq: category },
            collectionID: { eq: collectionID }
          };
        }
        if (limit) {
          params = {
            ...params,
            limit: 6
          };
        }

        const { data: res } = await gql(searchCollectibles, params);
        //Todo: move logic to the backend
        const { data: filter } = await gql(searchCollectibles, {
          ...params,
          nextToken: null,
          limit: undefined
        });
        setFilter(filter?.searchCollectibles?.items || []);
        const getArtistsIDParams = {
          limit: 10000,
          filter: {
            or: filter?.searchCollectibles?.items.map((f) => ({ collectibleID: { eq: f.id } }))
          }
        };
        if (filter?.searchCollectibles?.items?.length > 0) {
          const { data: artistID } = await gql(listFeaturedArtistCollectibles, getArtistsIDParams);
          const artistsNameParams = {
            limit: 10000,
            filter: {
              or: artistID?.listFeaturedArtistCollectibles?.items.map((a) => ({
                id: { eq: a.featuredArtistID }
              }))
            }
          };
          const { data: artistName } = await gql(listFeaturedArtists, artistsNameParams);
          setFilterArtistName(artistName?.listFeaturedArtists?.items);
        }
        //Todo: move logic to the backend
        const totalParam = params;
        totalParam.limit = undefined;
        totalParam.nextToken = null;
        const { data: totalRes } = await gql(searchCollectibles, totalParam);
        setTotal(totalRes?.searchCollectibles?.total ? totalRes?.searchCollectibles?.total : 0);

        if (lastUsername === collectionID) {
          if (res?.searchCollectibles?.items?.length < 6) {
            setData(data.concat(res.searchCollectibles?.items));
            setPageToken(null);
          } else {
            setData(data.concat(res.searchCollectibles?.items));
            setPageToken(res.searchCollectibles?.nextToken);
          }
        } else {
          if (res?.searchCollectibles?.items?.length < 6) {
            setData(res.searchCollectibles?.items);
            setPageToken(null);
            setLastUsername(collectionID);
          } else {
            setData(res.searchCollectibles?.items);
            setPageToken(res.searchCollectibles?.nextToken);
            setLastUsername(collectionID);
          }
        }
      } catch (error) {
        const errorMessage = error?.errors
          ? error?.errors[0]?.message
          : error.message ?? 'unknown error';
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [collectionID, params] // eslint-disable-line
  );

  useEffect(() => {
    if (collectionID) {
      getData(params);
    }
  }, [collectionID, params]); // eslint-disable-line

  const onUpdate = (categories) => {
    setData([]);
    onUpdateCategory(categories);
  };

  const onUpdateTitle = (title) => {
    setData([]);
    onChangeTitle(title);
  };

  const onSort = (sortBy) => {
    setData([]);
    onSortBy(sortBy);
  };

  const onUpdateUser = (userID) => {
    setData([]);
    onChangeUser(userID);
  };

  const onChangeGenre = (genre) => {
    setData([]);
    onUpdateGenre(genre);
  };

  const onChangeYearRange = (era) => {
    setData([]);
    const parsedEra = eraParser(era);
    if (parsedEra) {
      onUpdateYearRange(parsedEra.min, parsedEra.max);
    } else {
      onUpdateYearRange(null, null);
    }
  };

  const onChangeUpdateMultipleCaseYearRange = (era) => {
    setData([]);
    const parsedEra = eraParser(era);
    if (parsedEra) {
      onUpdateMultipleCaseYearRange(parsedEra.min, parsedEra.max);
    } else {
      onUpdateMultipleCaseYearRange(null, null);
    }
  };

  const onChangeFeaturedArtist = async (featuredArtistID) => {
    const { data: featuredArtistCollectible } = await gql(
      getFeaturedArtistByCreator,
      {
        input: { featuredArtistID: featuredArtistID, type: 'COLLECTIBLE' }
      },
      {
        authMode: 'API_KEY'
      }
    );
    let collectible = [];
    const parsedData = featuredArtistCollectible
      ? JSON.parse(featuredArtistCollectible?.getFeaturedArtistByCreator)
      : null;
    if (parsedData) {
      collectible = parsedData?.body?.data;
    }

    setData([]);
    onUpdateFeaturedArtist(collectible);
  };

  const onChangeMultipleCaseFeaturedArtist = async (featuredArtistID) => {
    if (featuredArtistID) {
      const { data: featuredArtistCollectible } = await gql(
        getFeaturedArtistByCreator,
        {
          input: { featuredArtistID: featuredArtistID, type: 'COLLECTIBLE' }
        },
        {
          authMode: 'API_KEY'
        }
      );
      let collectible = [];
      const parsedData = featuredArtistCollectible
        ? JSON.parse(featuredArtistCollectible?.getFeaturedArtistByCreator)
        : null;
      if (parsedData) {
        collectible = parsedData?.body?.data;
      }
      setData([]);
      onUpdateMultipleCaseFeaturedArtist(collectible);
    } else {
      setData([]);
      onUpdateMultipleCaseFeaturedArtist([]);
    }
  };

  return {
    data,
    loading,
    pageToken,
    filter,
    filterArtistName,
    onUpdate,
    onChangeGenre,
    onChangeYearRange,
    onChangeFeaturedArtist,
    onPaginate,
    onSort,
    onUpdateTitle,
    onUpdateUser,
    onChangeMultipleCaseFeaturedArtist,
    onChangeUpdateMultipleCaseYearRange,
    total
  };
};

export const useCollectibleByCreators = (userID, limit) => {
  const toast = useToast();
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const [total, setTotal] = useState(0);

  const getData = async () => {
    setLoading(true);
    try {
      const params = {
        limit: limit,
        filter: {
          searchableStatus: { eq: 'PUBLISHED' },
          searchableCategory: { ne: 'VIP_PASS' },
          userID: { eq: userID }
        },
        sort: { direction: 'desc', field: 'createdAt' }
      };

      const { data: res } = await gql(searchCollectibles, params);
      if (res?.searchCollectibles?.items?.length) {
        setData(res.searchCollectibles?.items);
        setTotal(res.searchCollectibles?.total);
      } else {
        setData([]);
      }
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userID && limit) {
      getData();
    }
  }, [userID, limit]);

  return {
    data,
    loading,
    total,
    getData
  };
};

export const useCollectibleById = (collectibleID) => {
  const toast = useToast();
  const [data, setData] = useState();
  const [loading, setLoading] = useState();

  const getData = async () => {
    setLoading(true);
    try {
      const params = {
        filter: {
          id: { eq: collectibleID }
        },
        sort: { direction: 'desc', field: 'createdAt' }
      };

      const { data: res } = await gql(searchCollectibles, params);
      if (res?.searchCollectibles?.items?.length) {
        setData(res.searchCollectibles?.items[0]);
      } else {
        setData([]);
      }
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (collectibleID) {
      getData();
    }
  }, [collectibleID]);

  return {
    data,
    loading,
    getData
  };
};
