import { useTranslation } from 'react-i18next';
import { Box, Container, Text, Link, Logo, SocialMedia } from '@components';
import config from '@/config';

function FooterLanding() {
  const { t } = useTranslation();
  const socialMedia = {
    facebook: config.facebookUrl,
    instagram: config.instagramUrl,
    twitter: config.twitterUrl
  };
  return (
    <footer className="footer-landing">
      <Container>
        <Box className="footer-landing__wrapper">
          <Box className="footer-landing__menu">
            <ul>
              <li>
                <Link to="/early-access/faq">{t('components.footer.menu.faq')}</Link>
              </li>
              <li>
                <Link to="/early-access/terms-and-conditions">
                  {t('components.footer.menu.terms')}
                </Link>
              </li>
              <li>
                <Link to="/early-access/privacy-policy">{t('components.footer.menu.privacy')}</Link>
              </li>
            </ul>
          </Box>
          <Text className="footer-landing__copyright">{t('components.footer.copyrightNew')}</Text>
        </Box>
      </Container>
    </footer>
  );
}

export default FooterLanding;
