import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useExchangeUrlParams } from '@libs/hooks/exchange';
import { useCollectible } from '@libs/hooks/collectible';
import { EXCHANGE_TYPES, STATUS_TYPES } from '@libs/utils/exchange';
import { useExchangeOwnership } from '@libs/hooks/exchange/useCollectibleOwnership';

const useExchangeFromExchangeUrlParams = () => {
  const params = useParams();
  const [slug, setSlug] = useState(null);
  const [edition, setEdition] = useState(null);
  const [exchange, setExchange] = useState(null);
  const [isLoadingExchange, setIsLoadingExchange] = useState(true);

  const { data: exchanges, loading: isLoadingExchanges } = useExchangeUrlParams({
    slug,
    exchangeType: EXCHANGE_TYPES.LISTING,
    serialIndex: edition
  });

  const { data: exchangesMinting, loading: isLoadingExchangesMinting } = useExchangeUrlParams({
    slug,
    exchangeType: EXCHANGE_TYPES.LISTING,
    serialIndex: edition,
    status: STATUS_TYPES.MINTING
  });

  useEffect(() => {
    if (params && params.edition) {
      setSlug(params.slug);
      setEdition(params.edition);
    }
  }, [params]);

  useEffect(() => {
    if (slug && edition && !isLoadingExchanges && !isLoadingExchangesMinting) {
      setExchange(exchanges?.[0] ?? exchangesMinting?.[0]);
      setIsLoadingExchange(false);
    }
  }, [slug, edition, isLoadingExchanges, isLoadingExchangesMinting, exchanges, exchangesMinting]);

  return { exchange, isLoadingExchange };
};

const useExchangeFromCollectibleUrlParams = () => {
  const params = useParams();
  const [slug, setSlug] = useState(null);
  const [isLoadingExchange, setIsLoadingExchange] = useState(true);
  const { data: collectible, loading: loadingCollectibe } = useCollectible(slug);
  const { data: exchange, loading: loadingExchange } = useExchangeOwnership(collectible?.id);

  useEffect(() => {
    if (params && !params.edition) {
      setSlug(params.slug);
    }
  }, [params]);

  useEffect(() => {
    if (slug && !loadingCollectibe && !loadingExchange) {
      setIsLoadingExchange(false);
    }
  }, [slug, loadingCollectibe, loadingExchange]);

  return { exchange, isLoadingExchange };
};

export const useExchangeFromUrlParams = () => {
  const [exchange, setExchange] = useState(null);
  // TODO: this should be replaced by item.TYPE - exchange needs TYPE in qgl query
  const [exchangeFrom, setExchangeFrom] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { exchange: exchangeFromExchange, isLoadingExchange: isLoadingExchangeFromExchange } =
    useExchangeFromExchangeUrlParams();
  const { exchange: exchangeFromCollectible, isLoadingExchange: isLoadingExchangeFromCollectible } =
    useExchangeFromCollectibleUrlParams();

  useEffect(() => {
    if (!isLoadingExchangeFromExchange) {
      setExchange(exchangeFromExchange);
      setExchangeFrom('exchange');
      setIsLoading(false);
    } else if (!isLoadingExchangeFromCollectible) {
      setExchange(exchangeFromCollectible ?? undefined);
      setExchangeFrom('collectible');
      setIsLoading(false);
    }
  }, [
    exchangeFromExchange,
    exchangeFromCollectible,
    isLoadingExchangeFromExchange,
    isLoadingExchangeFromCollectible
  ]);

  return { exchange, isLoading, exchangeFrom };
};
