import { React, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { ProtectedRoute, GuestRoute, FansRoute, LandingRoute } from '@components';
import Default from '@pages/default';
import ScrollTop from '@libs/utils/scrollTop';
import RouteCheck from '@libs/utils/route-check';
import Landing from '@pages/landing';
import Auth from '@pages/auth';
import ProfileTemplate from '@pages/profile/fansinc/profile-template';
import ProfileLogin from '@pages/profile/fansinc/auth/login';
import ProfileSignup from '@pages/profile/fansinc/auth/signup';
import ThisIsMeNow from '@pages/collectible/fansinc/custom/this-is-me-now';
import BetaFeatures from '@pages/beta-features';

import { useCookies } from 'react-cookie';
import TagManager from 'react-gtm-module';
import config from '@/config';
import ComingSoon from '@pages/coming-soon';

export default function App() {
  const [cookies] = useCookies([config.cookie.ALLOW_COOKIES]);

  const tagManagerArgs = {
    gtmId: config.gtmID
  };

  if (
    typeof cookies[config.cookie.ALLOW_COOKIES] !== 'undefined' &&
    cookies[config.cookie.ALLOW_COOKIES] === 'true'
  ) {
    TagManager.initialize(tagManagerArgs);
  }

  return (
    <>
      <ScrollTop />
      {/* Temporary use seamless-scroll-polyfill via script in index.html due to polyfill import error */}
      {/* <Polyfill /> */}
      <RouteCheck />
      <Switch>
        {/* Coming Soon pages */}
        {process.env.REACT_APP_NAME === 'patrons' ? (
          <LandingRoute exact path="/" component={ComingSoon} />
        ) : (
          <LandingRoute exact path="/" component={Landing} />
        )}
        {process.env.REACT_APP_NAME === 'patrons' && (
          <FansRoute exact path="/home" component={Default} />
        )}
        {process.env.REACT_APP_NAME === 'patrons' && (
          <LandingRoute exact path="/waitlist-signup/:id" component={ComingSoon} />
        )}
        {process.env.REACT_APP_NAME === 'patrons' && (
          <LandingRoute exact path="/waitlist/verified" component={ComingSoon} />
        )}
        {process.env.REACT_APP_NAME === 'patrons' && (
          <Route exact path="/collect-collectible/:id/" component={Default} />
        )}
        <LandingRoute exact path="/early-access/terms-and-conditions" component={Landing} />
        <LandingRoute exact path="/early-access/privacy-policy" component={Landing} />
        <LandingRoute exact path="/early-access/faq" component={Landing} />

        <FansRoute exact path="/marketplace" component={Default} />
        <FansRoute exact path="/releases" component={Default} />
        {process.env.REACT_APP_NAME === 'patrons' && (
          <FansRoute exact path="/artists" component={Default} />
        )}
        {process.env.REACT_APP_NAME === 'patrons' && (
          <FansRoute exact path="/collectors" component={Default} />
        )}
        {process.env.REACT_APP_NAME === 'patrons' && (
          <FansRoute exact path="/galleries" component={Default} />
        )}
        {process.env.REACT_APP_NAME === 'patrons' && (
          <FansRoute exact path="/collection/:slug" component={Default} />
        )}

        {/* Setting pages */}
        <ProtectedRoute exact path="/settings" component={Default} />
        <ProtectedRoute exact path="/settings/notifications" component={Default} />
        <ProtectedRoute exact path="/settings/subscriptions" component={Default} />
        <ProtectedRoute exact path="/settings/account" component={Default} />
        <ProtectedRoute exact path="/settings/offers-bids" component={Default} />
        <ProtectedRoute exact path="/settings/bids" component={Default} />
        <ProtectedRoute exact path="/settings/transactions" component={Default} />

        {/* Notifications */}
        <ProtectedRoute exact path="/notifications" component={Default} />

        {/* Informational pages */}
        <Route exact path="/about" component={Default} />
        <FansRoute exact path="/contact" component={Default} />
        <FansRoute exact path="/faq" component={Default} />
        <FansRoute exact path="/how-it-works" component={Default} />
        <FansRoute exact path="/privacy-policy" component={Default} />
        <FansRoute exact path="/terms-and-conditions" component={Default} />
        <FansRoute exact path="/404" component={Default} />
        <FansRoute exact path="/unsubscribe/:followeeID/:followerID" component={Default} />

        <Route exact path="/confirmation/:slug/:prevExchange" component={Default} />
        <Route exact path="/confirmation" component={Default} />
        <FansRoute exact path="/congratulation" component={Default} />
        <FansRoute exact path="/print" component={Default} />

        {/* Print collectible pages */}
        <FansRoute exact path="/print/:username/:slug/:edition" component={Default} />
        <FansRoute exact path="/print/:username/:slug" component={Default} />
        <Route exact path="/po-confirmation/:exchange" component={Default} />
        <FansRoute exact path="/po-congratulation" component={Default} />

        {/* Mint collectible pages */}
        <FansRoute exact path="/mint/:username/:slug/:edition" component={Default} />
        <FansRoute exact path="/mint/:username/:slug" component={Default} />

        {/* Profile pages */}
        <FansRoute exact path="/:username/collectibles" component={Default} />
        <FansRoute exact path="/:username/related" component={Default} />
        <FansRoute exact path="/:username/sold" component={Default} />
        <FansRoute exact path="/:username/liked" component={Default} />
        <FansRoute exact path="/:username/fanclub/:channelID" component={Default} />

        {/* Collectible & collection pages */}
        <GuestRoute exact path="/:username/login" component={ProfileLogin} />
        <GuestRoute exact path="/:username/signup" component={ProfileSignup} />
        <FansRoute exact path="/:username/:slug" component={Default} />
        <FansRoute exact path="/:username/:slug/checkout/:edition" component={Default} />
        {process.env.REACT_APP_NAME !== 'patrons' && (
          <FansRoute exact path="/flex/:username/:slug/:edition?" component={Default} />
        )}
        <FansRoute exact path="/sell/:username/:slug/:edition?" component={Default} />
        <FansRoute exact path="/bitpay-payment/:exchange" component={Default} />
        <FansRoute exact path="/:username/:slug/:edition/:owner?" component={Default} />
        <FansRoute exact path="/beta-features" component={BetaFeatures} />
        <Route exact path="/thisismenow" component={ThisIsMeNow} />
        {/* Auth pages */}
        {process.env.REACT_APP_NAME === 'patrons' && (
          <>
            <Route exact path="/login" component={Auth} />
            <GuestRoute exact path="/signup" component={Auth} />
            <Route exact path="/activate-user" component={Auth} />
            <GuestRoute exact path="/forgot-password" component={Auth} />
            <GuestRoute exact path="/reset-password" component={Auth} />
            <FansRoute exact path="/:username" component={Default} />
          </>
        )}
        {process.env.REACT_APP_NAME !== 'patrons' && (
          <>
            <Route exact path="/login" component={Default} />
            <GuestRoute exact path="/signup" component={Default} />
            <Route exact path="/activate-user" component={Default} />
            <GuestRoute exact path="/forgot-password" component={Default} />
            <GuestRoute exact path="/reset-password" component={Default} />
            <FansRoute exact path="/:username" component={Default} />
          </>
        )}
        <Route path="*" component={Default} />
      </Switch>
    </>
  );
}
