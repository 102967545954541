import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Box, NotFound as NotFoundComponent } from '@components';

function NotFound() {
  const { t } = useTranslation();
  return (
    <>
      <Box className="patrons_notfound_container">
        <NotFoundComponent />
      </Box>
      <Helmet>
        <title>{t('notFound.title')}</title>
        <meta property="og:image" content="https://cdn.fans.inc/public/webapp/og-image.png" />
      </Helmet>
    </>
  );
}

export default NotFound;
