import cx from 'classnames';

import { Box } from '@components';

const Table = ({
  cols,
  rows,
  className,
  children,
  highlightRow,
  border = true,
  valign,
  disabledRows,
  ...props
}) => {
  return (
    <Box className="table-responsive">
      <table className={cx('table', className, border ? 'table-border' : '')}>
        {cols && (
          <thead>
            <tr>
              {cols.map((col, i) => (
                <th key={i}>{col}</th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {rows.map((items, i) => (
            <tr
              key={i}
              style={{ opacity: disabledRows?.includes(i) ? 0.5 : 1 }}
              className={highlightRow.includes(i) ? 'active' : null}
            >
              {items.map((item, i) => (
                <td key={i} valign={valign}>
                  {item}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        {children}
      </table>
    </Box>
  );
};

Table.defaultProps = {
  highlightRow: []
};

export default Table;
