import { useRef } from 'react';
import { Box, Media, CollectibleSkeleton, Link } from 'components';
import Slider from 'react-slick';
import { useCollectiblesByCreatorFeatured } from '@libs/hooks/collectible';
import { ReactComponent as ArrowLeftIcon } from '@assets/svg/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '@assets/svg/arrow-right.svg';

function CollectibleSlider({ profile }) {
  const slideCollectorRef = useRef();
  const slideCollectorSettings = {
    dots: true,
    focusOnSelect: false,
    arrows: false,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    customPaging: function (i) {
      return <></>;
    },
    dotsClass: 'slick-custom-pagging',
    className: 'collectible-slider',
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const { data: featuredCollectibles, loading } = useCollectiblesByCreatorFeatured(profile, 10);
  const RenderArrows = ({ refVal }) => {
    return (
      <div className="slider-arrow">
        <Box className="arrow-btn slide-arrow-prev" onClick={() => refVal.current.slickPrev()}>
          <Box className="slide-arrow-prev-button">
            <ArrowLeftIcon />
          </Box>
        </Box>
        <Box className="slide-arrow-prev"></Box>
        <Box className="arrow-btn slide-arrow-next" onClick={() => refVal.current.slickNext()}>
          <Box className="slide-arrow-next-button">
            <ArrowRightIcon />
          </Box>
        </Box>
      </div>
    );
  };
  const CollectibleProfile = ({ collectible, profile }) => {
    return (
      <Link to={`/${profile?.username}/${collectible?.slug}`}>
        <Box className="collectible-profile">
          <Box className="collectible-profile__wrapper">
            <Media collectible={collectible} showPlayer thumbnail />
            <Box className="collectible-profile__wrapper__description">
              <Box className="collectible-profile__wrapper__description__title">
                {collectible?.title}
              </Box>
              <Box className="collectible-profile__wrapper__description__value">
                {collectible?.description}
              </Box>
            </Box>
          </Box>
        </Box>
      </Link>
    );
  };

  return (
    <Box className="profile-artist__cards">
      {featuredCollectibles?.length >= 3 && !loading ? (
        <>
          <RenderArrows refVal={slideCollectorRef} />
          <Box className="profile-artist__cards__slider">
            <Slider {...slideCollectorSettings} ref={slideCollectorRef}>
              {featuredCollectibles &&
                featuredCollectibles?.map((collectible) => (
                  <Box key={collectible.id} className="profile-artist__cards__wrapper">
                    <CollectibleProfile collectible={collectible} profile={profile} />
                  </Box>
                ))}
            </Slider>
          </Box>
        </>
      ) : (
        <>
          {loading ? (
            <Box className="grid grid-cols-3 gap-5">
              <CollectibleSkeleton />
              <CollectibleSkeleton />
              <CollectibleSkeleton />
            </Box>
          ) : (
            <Box></Box>
          )}
        </>
      )}
    </Box>
  );
}

export default CollectibleSlider;
