/* eslint-disable */

import React from 'react';
import { Auth, Hub } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { createContext, useState, useContext, useEffect, useCallback } from 'react';

import gql from '@libs/utils/gql';
import { useTokenManager } from '@libs/utils/token';
import { passwordVerifier } from '@graphql/mutations';
import { getUser, accountUserByUser } from '@graphql/queries';
import { getWallet } from '@libs/custom-queries/wallet';
import { Link } from '@components';
import { whitelistEmail } from '@libs/utils/email';
import { collectibleLikeByUserAndCollectible } from '@libs/custom-queries/collectible-like';
import useToast from '@libs/utils/toast';

const AuthContext = createContext({
  loading: false,
  isAuthenticated: false,
  user: null,
  wallet: null,
  authenticate: () => {},
  logout: () => {},
  error: null,
  success: null,
  resetMessage: () => {},
  setMessageStatus: () => {},
  federatedSignIn: () => {},
  collectibleLikes: [],
  collectibleLikesLoading: true,
  updateCollectibleLikes: () => {}
});

export const AuthProvider = ({ children }) => {
  const toast = useToast();
  const history = useHistory();
  const { tokenPrefix, tokenMaxAge, setTokenPrefix, removeTokenPrefix } = useTokenManager();

  const [loading, setLoading] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [user, setUser] = useState();
  const [wallet, setWallet] = useState();
  const [permission, setPermission] = useState();
  const [message, setMessage] = useState({ success: null, error: null });
  const [isAuthenticated, setAuth] = useState(!!localStorage.getItem(tokenPrefix));
  const [likeLoading, setLikeLoading] = useState(true);
  const [collectibleLikes, setCollectibleLikes] = useState([]);

  useEffect(() => {
    if (user) {
      setAuth(true);
    }
  }, [user]);

  const getCurrentUser = async (bypassCache = false) => {
    try {
      const { attributes } = await Auth.currentAuthenticatedUser({ bypassCache });
      const { data: res } = await gql(getUser, { id: attributes.sub });
      const { data: wallet } = await gql(
        getWallet,
        {
          id: attributes.sub
        },
        {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }
      );

      if (res?.getUser) {
        setUser({ ...res.getUser, newEmail: attributes['custom:new_email'] });
        await getAllCollectibleLike(res?.getUser?.id);
      }
      if (wallet?.getWallet) {
        setWallet(wallet?.getWallet);
      }
    } catch (error) {
      await logout();
    }
  };

  const refreshSession = async () => {
    try {
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      const { refreshToken } = authenticatedUser.getSignInUserSession();
      authenticatedUser.refreshSession(refreshToken, async (err, session) => {
        //bypassCache to get updated user attributes
        await getCurrentUser(true);
      });
    } catch (error) {
      await logout();
    }
  };

  const getPermission = async (accountID) => {
    try {
      if (accountID) {
        const { data: permissionRes } = await gql(accountUserByUser, {
          userID: user.id,
          accountID: { eq: user.accountID }
        });
        if (permissionRes?.accountUserByUser?.items?.length) {
          setPermission(permissionRes.accountUserByUser.items[0]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCollectibleLike = async (userID, nextToken) => {
    if (!userID) {
      return;
    }
    try {
      const params = {
        userID,
        sortDirection: 'DESC'
      };

      if (nextToken) {
        params.nextToken = nextToken;
      }
      const { data: res } = await gql(collectibleLikeByUserAndCollectible, params);
      if (res?.collectibleLikeByUserAndCollectible?.items) {
        return {
          data: res.collectibleLikeByUserAndCollectible.items,
          nextToken: res.collectibleLikeByUserAndCollectible.nextToken
        };
      }

      return {
        data: [],
        nextToken: null
      };
    } catch (error) {
      // const errorMessage = error?.errors[0]?.message;
      toast(error, 'error');
      console.error(error);
    }
  };

  const getAllCollectibleLike = async (userID) => {
    if (!userID) {
      return;
    }
    try {
      setLikeLoading(true);
      let nextTokenPage = null;

      do {
        const { data, nextToken } = await getCollectibleLike(userID);
        setCollectibleLikes(
          collectibleLikes
            .concat(data)
            .filter((c) => c?.collectibleID)
            .map((c) => c.collectibleID)
        );
        nextTokenPage = nextToken;
      } while (nextTokenPage);
    } catch (error) {
      console.error(error);
      const errorMessage = error?.message;
      toast(errorMessage, 'error');
    } finally {
      setLikeLoading(false);
    }
  };

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'cognitoHostedUI':
          setTokenPrefix(data.keyPrefix, data.username, false);
          setAuth(true);
          break;
        default:
      }
    });
    if (isAuthenticated) {
      getCurrentUser();
    }
  }, []);

  // Temporarily disable this automatic logout
  // Logout automatically once max age is reached
  // useEffect(() => {
  //   let tokenExpiry;
  //   if (tokenMaxAge) {
  //     tokenExpiry = setTimeout(() => {
  //       logout(true);
  //     }, +tokenMaxAge - Date.now());
  //   }

  //   return () => {
  //     if (tokenExpiry) clearTimeout(tokenExpiry);
  //   };
  // }, [tokenMaxAge]);

  useEffect(() => {
    getPermission(user?.accountID);
  }, [user]);

  const authenticate = useCallback(async ({ email, password, rememberMe }, redirect, onCloseCB) => {
    setLoading(true);
    resetMessage();

    try {
      const validateEmail = await whitelistEmail(email);
      if (!validateEmail) {
        setLoading(false);
        process.env.REACT_APP_NAME === 'patrons'
          ? setMessageStatus(
              'error',
              <Trans i18nKey="p.auth.whitelistEmail">
                <Link to="mailto:support@patrons.art" target="_blank" />
                {{ environment: process.env.REACT_APP_AMPLIFY_ENV }}
              </Trans>
            )
          : setMessageStatus(
              'error',
              <Trans i18nKey="auth.whitelistEmail">
                <Link to="mailto:support@fans.inc" target="_blank" />
                {{ environment: process.env.REACT_APP_AMPLIFY_ENV }}
              </Trans>
            );
        return;
      }
    } catch (error) {
      console.error(error);
    }

    try {
      const { keyPrefix, username, attributes } = await Auth.signIn(email, password);
      const { data: res } = await gql(getUser, { id: attributes.sub });
      const { data: wallet } = await gql(
        getWallet,
        {
          id: attributes.sub
        },
        {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }
      );

      if (res?.getUser && res?.getUser?.status === 'NOT_ACTIVE') {
        setMessageStatus('error', 'Account is not active');
        try {
          await Auth.signOut({ global });
        } catch (err) {
          console.error(err);
        } finally {
          setAuth(false);
          setUser(null);
          setPermission(null);
          removeTokenPrefix();
          setWallet(null);
          setCollectibleLikes([]);
        }
        return;
      }

      if (res?.getUser) {
        const user = res.getUser;
        // fansTokenPrefix is used for accessing the token
        setTokenPrefix(keyPrefix, username, rememberMe);
        setUser({ ...user, newEmail: attributes['custom:new_email'] });
        setAuth(true);

        if (user.accountID) {
          const { data: permissionRes } = await gql(accountUserByUser, {
            userID: user.id,
            accountID: { eq: user.accountID }
          });

          if (permissionRes.accountUserByUser.items?.length)
            setPermission(permissionRes.accountUserByUser.items[0]);
        }
        if (localStorage.getItem('collectibleCollected')) {
          history.push(`/collect-collectible/${localStorage.getItem('collectibleCollected')}`);
        }

        if (user?.id) {
          await getAllCollectibleLike(user?.id);
        }

        if (typeof redirect !== 'undefined' && !localStorage.getItem('collectibleCollected')) {
          history.push(redirect);
        } else {
          onCloseCB && onCloseCB();
        }
      } else {
        setMessageStatus('error', 'Something went wrong');
      }

      if (wallet?.getWallet) {
        setWallet(wallet?.getWallet);
      }
    } catch (error) {
      console.error(error);
      if (error.message === 'User is not confirmed.') {
        history.push(`/activate-user?email=${encodeURIComponent(email)}`);
      }
      setMessageStatus('error', error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const federatedSignIn = async (provider = 'Google') => {
    await Auth.federatedSignIn({ provider });
  };

  const verifyPassword = async (password) => {
    setVerifyLoading(true);
    try {
      const { data: res } = await gql(
        passwordVerifier,
        { input: { password } },
        {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }
      );

      return res?.passwordVerifier;
    } catch (error) {
      console.error(error);
    } finally {
      setVerifyLoading(false);
    }
  };

  const resetMessage = () => {
    setMessage({ success: null, error: null });
  };

  const setMessageStatus = (status, message) => {
    if (!['success', 'error'].includes(status)) {
      throw Error('Invalid message type');
    }

    setMessage({ [status]: message });
  };

  const logout = useCallback(async (global = false) => {
    try {
      await Auth.signOut({ global });
    } catch (err) {
      console.error(err);
    } finally {
      setAuth(false);
      setUser(null);
      setPermission(null);
      removeTokenPrefix();
      setWallet(null);
      setCollectibleLikes([]);
      history.replace('/marketplace');
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        wallet,
        permission,
        authenticate,
        loading,
        logout,
        error: message.error,
        success: message.success,
        resetMessage,
        setMessageStatus,
        getCurrentUser,
        verifyPassword,
        verifyLoading,
        federatedSignIn,
        refreshSession,
        collectibleLikes,
        collectibleLikesLoading: likeLoading,
        updateCollectibleLikes: setCollectibleLikes
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
