import { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import gql from '@libs/utils/gql';
import useToast from '@libs/utils/toast';
import { getFollower } from '@graphql/queries';
import { createFollower, deleteFollower, unsubscribeCreator } from '@graphql/mutations';
import { useAuth } from '@libs/contexts/auth';

export const useFollower = (followeeID, followee) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [isFollowing, setIsFollowing] = useState();
  const { user, isAuthenticated } = useAuth();

  useEffect(() => {
    async function getData() {
      setLoading(true);
      if (!user) {
        return;
      }

      if (!followeeID) {
        return;
      }

      try {
        const params = {
          followeeID,
          followerID: user.id
        };

        const { data: result } = await gql(getFollower, params, {
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });

        setIsFollowing(result?.getFollower?.followeeID ? true : false);
      } catch (error) {
        const errorMessage = error?.errors[0]?.message;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    if (isAuthenticated) {
      getData();
    }
  }, [user, followeeID, isAuthenticated]); // eslint-disable-line

  const onCreate = useCallback(
    async (payload) => {
      setLoading(true);

      try {
        await gql(
          createFollower,
          {
            input: {
              followeeID: payload.followeeID
            }
          },
          {
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          }
        );
        setIsFollowing(true);
        process.env.REACT_APP_NAME === 'patrons' &&
          toast(
            `You have been added to ${
              followee?.displayName || followee?.firstName + ' ' + followee?.lastName
            } waitlist`,
            'success'
          );
      } catch (error) {
        const errorMessage = error?.errors[0]?.message;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [toast]
  );

  const onDelete = useCallback(
    async (payload) => {
      setLoading(true);
      try {
        await gql(
          deleteFollower,
          {
            input: {
              followeeID: payload.followeeID
            }
          },
          {
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          }
        );
        setIsFollowing(false);
        process.env.REACT_APP_NAME === 'patrons' &&
          toast(
            `You have successfully unsubscribed ${
              followee?.displayName || followee?.firstName + ' ' + followee?.lastName
            }`,
            'success'
          );
      } catch (error) {
        const errorMessage = error?.errors[0]?.message;
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [toast]
  );

  return {
    isFollowing,
    loading,
    onCreate,
    onDelete
  };
};

export const useFollowerUnauthorized = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const getData = async (followeeID, followerID) => {
    setLoading(true);
    try {
      const { data: res } = await gql(getFollower, { followeeID, followerID });
      if (res?.getFollower) {
        return res?.getFollower;
      } else {
        return false;
      }
    } catch (error) {
      const errorMessage = error?.message;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onUpdateEmailNotification = async (payload) => {
    setLoading(true);
    try {
      await gql(unsubscribeCreator, {
        input: {
          followeeID: payload.followeeID,
          followerID: payload.followerID
        }
      });
    } catch (error) {
      const errorMessage = error?.errors[0]?.message;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    getData,
    onUpdateEmailNotification
  };
};
