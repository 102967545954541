import { useState, useCallback, useEffect, useRef } from 'react';
import {
  Container,
  Box,
  SkeletonLoading,
  Link,
  Button,
  Price,
  Text,
  CollectibleCard,
  Media,
  CollectibleSkeleton,
  Select
} from '@components';
import { Trans, useTranslation } from 'react-i18next';
import { useAuth } from '@libs/contexts/auth';
import { useItemFromUrlParams, useIsOwner } from '@pages/print-collectible/patrons/hooks';
import { useCollectionById } from '@libs/hooks/collection';
import { useOutsider } from '@libs/utils/outsider';
import { useQuotesProdigi } from '@libs/hooks/prodigi';
import listCountries from '@libs/json/prodigiCountries.json';
import { IoChevronDown } from 'react-icons/io5';
import { generateQR } from '@libs/utils/qrcode';
import { colorProdigiKeys, skuFrame } from '@pages/print-collectible/patrons/utils';
import PrintImage from './print-image/collectible';
import PrintBarcode from './print-barcode';
import { usePrintableCollectibles } from '@libs/hooks/collectible';
import { toPng } from 'html-to-image';
import { ReactComponent as CrossZoom } from '@assets/svg/patrons/cross-zoom.svg';
import ShowImage from './print-image/showImage';
import { createAssetUrl } from '@libs/utils/media';

function Print() {
  const { item, isLoadingItem, itemType } = useItemFromUrlParams();
  const { user } = useAuth();
  const { t } = useTranslation();
  const mediaRef = useRef();
  const barcodeRef = useRef();
  const [isOpenCountry, setOpenCountry] = useState();
  const [showZoom, setShowZoom] = useState(false);
  const [selectedFrame, setSelectedFrame] = useState();
  const [selectedSize, setSelectedSize] = useState();
  const [qrCode, setQrCode] = useState(null);
  const [selectedHeight, setSelectedHeight] = useState();
  const [selectedWidth, setSelectedWidth] = useState();
  const [barcodeUri, setBarcodeUri] = useState(null);
  const [mediaUri, setMediaUri] = useState(null);
  const [image, setImage] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState();
  const [quantity, setQuantity] = useState(1);
  const handleCloseCountry = () => setOpenCountry(false);
  const { ref: refCountry } = useOutsider(handleCloseCountry);
  const { data, loading: prodigiLoading, getData } = useQuotesProdigi();
  const { data: otherCollectibles, loading: otherCollectiblesLoading } = usePrintableCollectibles({
    userID: item?.userID,
    collectibleID: item?.id
  });
  const onUpdateQuantity = (tools) => {
    if (!isLoadingItem) {
      if (tools === 'add') {
        setQuantity(quantity + 1);
      } else {
        quantity > 1 && setQuantity(quantity - 1);
      }
    }
  };
  // useEffect(async () => {
  //   if (selectedSize && quantity && selectedCountry) {
  //     const payload = {
  //       sku: selectedSize,
  //       copies: quantity,
  //       color: 'White',
  //       country: selectedCountry
  //     };
  //     getData(payload);
  //   }
  // }, [selectedSize, quantity, selectedCountry]);
  const countries = data?.shippingCountries
    ?.map((country) => ({
      value: country,
      label: listCountries.find((e) => e.isoCode === country).name
    }))
    .sort(function (a, b) {
      return a.label.localeCompare(b.label);
    });
  const { data: collection, loading: collectionLoading } = useCollectionById(item?.collectionID);
  const handleOpenCountry = useCallback(
    (e) => {
      e.preventDefault();
      !prodigiLoading && setOpenCountry(!isOpenCountry);
    },
    [isOpenCountry, prodigiLoading]
  );

  useEffect(() => {
    if (listCountries.find((c) => c.isoCode === user?.address?.country)) {
      setSelectedCountry(user?.address?.country);
    } else {
      setSelectedCountry('AU');
    }
  }, [user]);
  useEffect(async () => {
    const qr = await generateQR(`${window.location.origin}`);
    setQrCode(qr);
  }, [item]);

  const downloadImage = useCallback(async () => {
    const link = document.createElement('a');
    link.download = 'html-to-img.png';
    link.href = barcodeUri;
    link.click();
  }, [barcodeUri]);

  useEffect(async () => {
    if (item) {
      const dataUrl = await toPng(barcodeRef.current);
      setBarcodeUri(dataUrl);
      setSelectedHeight(Number(item?.largeHeight));
      setSelectedWidth(Number(item?.largeWidth));
      setSelectedSize(item?.originalFrame);
    }
  }, [item]);

  const onSetLargeFrame = () => {
    setTimeout(() => {
      setSelectedHeight(Number(item?.largeHeight));
      setSelectedWidth(Number(item?.largeWidth));
      setSelectedSize(item?.originalFrame);
    }, 200);
  };

  const onSetSmallFrame = () => {
    setTimeout(() => {
      setSelectedHeight(Number(item?.smallHeight));
      setSelectedWidth(Number(item?.smallWidth));
      setSelectedSize(item?.smallFrame);
    }, 200);
  };

  // useEffect(async () => {
  //   if (item) {
  //     setSelectedHeight(Number(item?.largeHeight));
  //     setSelectedWidth(Number(item?.largeWidth));
  //   }
  // }, [item]);

  // useEffect(async () => {
  //   if (mediaRef.current) {
  //     const mediaUrl = await toPng(mediaRef.current);
  //     setMediaUri(mediaUrl);
  //   }
  // }, [mediaRef.current]);

  const mediaProps = {
    data: item,
    src: item?.assetUrl
  };

  useEffect(() => {
    async function fetchImage() {
      if (mediaProps.src && !mediaProps.src.startsWith('http')) {
        const s3Key = createAssetUrl(mediaProps.src, 800);
        if (s3Key.startsWith('http')) {
          setImage(s3Key);
        } else {
          const result = await Storage.get(s3Key, { contentType: 'image/png' });
          setImage(result);
        }
      } else {
        setImage(mediaProps.src);
      }
    }
    fetchImage();
    // eslint-disable-next-line
  }, [item]);

  useEffect(() => {
    async function getUri() {
      if (mediaRef?.current) {
        const uri = await mediaRef.current.onView();
        setMediaUri(uri);
      }
    }
    setTimeout(() => {
      getUri();
    }, 3000);
  }, [
    document.getElementById('print-it-main-image')?.offsetWidth,
    document.getElementById('print-it-main-image')?.offsetHeight,
    mediaRef.current
  ]);

  return (
    <Box className="print-collectible-patrons">
      <Container>
        <Box className="print-collectible-patrons__body">
          <Box className="print-collectible-patrons__body__left">
            <Box className="print-collectible-patrons__body__left__center">
              <Box
                onClick={() => setShowZoom(true)}
                className="print-collectible-patrons__body__left__wrapper"
              >
                <PrintImage
                  collectible={item}
                  height={selectedHeight}
                  width={selectedWidth}
                  loading={isLoadingItem}
                  ref={mediaRef}
                  setMediaUri={setMediaUri}
                />
                {mediaUri && <img src={mediaUri} alt="" />}
                {/* {item && (
                  <ShowImage
                    ref={mediaRef}
                    width={selectedWidth}
                    image={image}
                    height={selectedHeight}
                  />
                )} */}
              </Box>
            </Box>
            <Box className="print-collectible-patrons__body__left__center__barcode">
              <Box className="print-collectible-patrons__body__left__center__barcode__wrapper">
                {barcodeUri && <img src={barcodeUri} alt="" />}
              </Box>
            </Box>
            <Box className="print-collectible-patrons__body__left__center__barcode__download">
              <Box className="print-collectible-patrons__body__left__center__barcode__download__wrapper">
                <Box
                  id="barcode-main-image"
                  ref={barcodeRef}
                  className="print-collectible-patrons__body__left__wrapper__barcode__download"
                >
                  <Box className="print-collectible-patrons__body__left__wrapper__barcode__download__wrapper">
                    <Box className="print-collectible-patrons__body__left__wrapper__barcode__download__right">
                      <img src={qrCode} alt="" />
                    </Box>
                    <Box className="print-collectible-patrons__body__left__wrapper__barcode__download__left">
                      <Box className="print-collectible-patrons__body__left__wrapper__barcode__download__title">
                        {item?.collectible?.title}
                      </Box>
                      <Box className="print-collectible-patrons__body__left__wrapper__barcode__download__description">
                        <Trans i18nKey="p.pages.printCollectible.barcode.collectibleDescription">
                          <span className="print-collectible-patrons__body__left__wrapper__barcode__download__description__medium">
                            {{ creatorName: item?.creator?.displayName }}
                          </span>
                        </Trans>
                      </Box>
                      <Box className="print-collectible-patrons__body__left__wrapper__barcode__download__year">
                        <Trans i18nKey="p.pages.printCollectible.barcode.print">
                          {{ year: new Date().getFullYear() }}
                        </Trans>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="print-collectible-patrons__body__right">
            {isLoadingItem ? (
              <SkeletonLoading className="w-full h-10" />
            ) : (
              <Box className="print-collectible-patrons__body__right__title">{item?.title}</Box>
            )}
            {isLoadingItem ? (
              <SkeletonLoading className="w-full h-7 mt-2" />
            ) : (
              <Box className="print-collectible-patrons__body__right__creator">
                <Trans i18nKey="p.pages.printCollectible.creatorName">
                  <Link
                    to={`/${item?.creator?.username}`}
                    className="print-collectible-patrons__body__right__creator__name"
                  >
                    {{
                      creatorName:
                        item?.creator?.displayName ||
                        item?.creator?.firstName + ' ' + item?.creator?.lastName
                    }}
                  </Link>
                </Trans>
                {collection?.shortName && (
                  <Trans i18nKey="p.pages.printCollectible.collectionName">
                    <Link
                      to={`/collection/${collection?.slug}`}
                      className="print-collectible-patrons__body__right__creator__name"
                    >
                      {{
                        collectionName: collection?.shortName
                      }}
                    </Link>
                  </Trans>
                )}
              </Box>
            )}
            <Box className="print-collectible-patrons__body__right__size">
              {item?.smallPrint === 'TRUE' ? (
                <>
                  <Box className="print-collectible-patrons__body__right__size__title">
                    {t('p.pages.printCollectible.size.title')}
                  </Box>

                  <Box className="print-collectible-patrons__body__right__size__button">
                    <Button
                      darkOutline={selectedSize !== item?.originalFrame}
                      onClick={() => onSetLargeFrame()}
                      type="primary"
                      disabled={prodigiLoading}
                      fullWidth
                    >
                      {isLoadingItem ? (
                        <center>
                          <SkeletonLoading style={{ height: '24px', width: '140px' }} />
                        </center>
                      ) : (
                        <Trans i18nKey="p.pages.printCollectible.size.large">
                          {{
                            width: Math.floor(Number(item?.largeWidth)),
                            height: Math.floor(Number(item?.largeHeight))
                          }}
                        </Trans>
                      )}
                    </Button>
                    <Button
                      onClick={() => onSetSmallFrame()}
                      type="primary"
                      disabled={prodigiLoading}
                      darkOutline={selectedSize !== item?.smallFrame}
                      fullWidth
                    >
                      {isLoadingItem ? (
                        <center>
                          <SkeletonLoading style={{ height: '24px', width: '140px' }} />
                        </center>
                      ) : (
                        <Trans i18nKey="p.pages.printCollectible.size.small">
                          {{
                            width: Math.floor(Number(item?.smallWidth)),
                            height: Math.floor(Number(item?.smallHeight))
                          }}
                        </Trans>
                      )}
                    </Button>
                  </Box>
                </>
              ) : (
                <>
                  {isLoadingItem ? (
                    <SkeletonLoading className=" h-7 w-full" />
                  ) : (
                    <Box className="print-collectible-patrons__body__right__size__title">
                      <Trans i18nKey="p.pages.printCollectible.size.originalSize">
                        <span className="text-primary font-medium">
                          {{
                            size: `${Math.floor(Number(item?.largeWidth))}x${Math.floor(
                              Number(item?.largeHeight)
                            )} inches`
                          }}
                        </span>
                      </Trans>
                    </Box>
                  )}
                </>
              )}
            </Box>
            {/* <Box className="print-collectible-patrons__body__right__quantity">
              <Box className="print-collectible-patrons__body__right__quantity__title">
                {t('p.pages.printCollectible.quantity.title')}
              </Box>
              <Box className="print-collectible-patrons__body__right__quantity__tools">
                <Box
                  onClick={() => !prodigiLoading && onUpdateQuantity('min')}
                  className="print-collectible-patrons__body__right__quantity__tools__function pb-1"
                >
                  -
                </Box>
                <Box>{quantity}</Box>
                <Box
                  onClick={() => !prodigiLoading && onUpdateQuantity('add')}
                  className="print-collectible-patrons__body__right__quantity__tools__function"
                >
                  +
                </Box>
              </Box>
            </Box>
            <Box className="print-collectible-patrons__body__right__description">
              <Box className="print-collectible-patrons__body__right__description__price">
                {prodigiLoading || isLoadingItem || !countries ? (
                  <SkeletonLoading style={{ height: '62px' }} className="w-full" />
                ) : (
                  <>
                    <Price amount={data?.price} inline />
                    <Box className="print-collectible-patrons__body__right__description__price__vat">
                      {t('p.pages.printCollectible.vat')}
                    </Box>
                  </>
                )}
              </Box>
              {countries ? (
                <>
                  <Box className="print-collectible-patrons__body__right__description__shipping">
                    <Box>{t('printCollectiblePage.content.subHeading')}</Box>
                    <Select
                      className="print-collectible-patrons__body__right__description__shipping__list"
                      fullWidth
                      onChange={(e) => setSelectedCountry(e.target.value)}
                      options={countries}
                    />
                  </Box>
                </>
              ) : (
                <>
                  <SkeletonLoading className="h-6 w-1/2 mt-4" />
                </>
              )}
              <Link to={`/${item?.creator?.username}/${item?.slug}`}>
                <Button className="mt-4" type="primary" fullWidth>
                  <Box className="flex justify-center gap-1 whitespace-nowrap">
                    {t('p.pages.printCollectible.getItNow')}
                  </Box>
                </Button>
              </Link>
            </Box> */}
            <Box className="print-collectible-patrons__body__right__description">
              <div className="text-lg text-secondary font-normal">
                {t('p.pages.printCollectible.noAuth')}
              </div>
              <Link to={`/${item?.creator?.username}/${item?.slug}`}>
                <Button className="mt-4" type="primary" fullWidth>
                  <Box className="flex justify-center gap-1 whitespace-nowrap">
                    {t('p.pages.printCollectible.buyNow')}
                  </Box>
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>
      </Container>
      <Box className="print-collectible-patrons__bottom">
        <Container className="print-collectible-patrons__bottom__body">
          <Box className="print-collectible-patrons__bottom__body__head">
            <Box className="print-collectible-patrons__bottom__body__head__title">
              {t('p.pages.printCollectible.more')}
            </Box>
            <Button>{t('p.pages.printCollectible.view')}</Button>
          </Box>
          <Box className="print-collectible-patrons__bottom__body__collectible">
            {!otherCollectiblesLoading ? (
              <>
                {otherCollectibles?.map((collectible) => (
                  <CollectibleCard
                    key={collectible?.id}
                    collectible={collectible}
                    showPrice={false}
                  ></CollectibleCard>
                ))}
              </>
            ) : (
              <>
                <CollectibleSkeleton />
                <CollectibleSkeleton />
                <CollectibleSkeleton />
              </>
            )}
          </Box>
        </Container>
      </Box>
      {showZoom && (
        <Box className="print-collectible-patrons__zoom">
          <Box className="print-collectible-patrons__zoom__image">
            <Box className="print-collectible-patrons__zoom__image__media">
              {mediaUri && <img src={mediaUri} alt="" />}
            </Box>
            <Box className="print-collectible-patrons__zoom__image__close">
              <CrossZoom onClick={() => setShowZoom(false)} />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default Print;
