import { useState, useEffect, useCallback, useRef } from 'react';

import { useExchangeByID } from '@libs/hooks/exchange';
import { TIER_TYPES } from '@libs/utils/release';
import { isDigitalOriginalReleased } from './utils';

export const useCurrentLiveTime = () => {
  const currentTime = useRef(new Date().getTime());

  useEffect(() => {
    const intervalID = setInterval(() => {
      const now = new Date().getTime();
      currentTime.current = now;
    }, 1000);
    return () => {
      intervalID && clearInterval(intervalID);
    };
  }, []);

  return currentTime.current;
};

export const useExchangeManagement = () => {
  const get = (collectible, tierTiles, tierType) => {
    const idx = collectible?.releases.findIndex((r) => r.tierType === tierType);

    const result = {
      primaryMarket: null,
      secondaryMarket: null
    };

    Object.keys(tierTiles || []).forEach((market) => {
      result[market] = tierTiles[market].find((exc) => exc.collectibleReleaseIndex === idx);
    });

    return result;
  };

  const transform = useCallback((data) => {
    const hasExchanges = {
      primaryMarket: false,
      secondaryMarket: false
    };
    const exchangesData = {
      primaryMarket: null,
      secondaryMarket: {
        fixedPrice: null,
        offer: null,
        lowestPrice: null
      }
    };

    // primaryMarket
    if (data.primaryMarket?.exchange) {
      hasExchanges.primaryMarket = true;
      exchangesData.primaryMarket = {
        ...data.primaryMarket?.exchange,
        currentBidExchange: data.primaryMarket?.currentBid
      };
    }

    // secondaryMarket
    hasExchanges.secondaryMarket = {
      fixedPrice: data.secondaryMarket?.exchanges?.fixedPrice != null,
      offer: data.secondaryMarket?.exchanges?.offer != null
    };

    hasExchanges.secondaryMarket =
      hasExchanges.secondaryMarket.fixedPrice || hasExchanges.secondaryMarket.offer;

    exchangesData.secondaryMarket = {
      fixedPrice: data.secondaryMarket?.exchanges?.fixedPrice,
      offer: data.secondaryMarket?.exchanges?.offer
    };

    const secondaryMarketArray = [
      ...[exchangesData.secondaryMarket?.fixedPrice],
      ...[exchangesData.secondaryMarket?.offer]
    ].filter((x) => x);

    const lowestPrice = secondaryMarketArray?.length
      ? secondaryMarketArray.reduce((prev, curr) =>
          prev.sellingPrice < curr.sellingPrice ? prev : curr
        )
      : null;

    exchangesData.secondaryMarket.lowestPrice = lowestPrice || null;

    return { hasExchanges, exchangesData };
  }, []);

  return { get, transform };
};

export const useTransformTierTilesData = ({ collectible, tierTilesData, tier }) => {
  const { get: getExchange, transform: transformExchange } = useExchangeManagement();
  const [hasExchanges, setHasExchanges] = useState(null);
  const [exchangesData, setExchangesData] = useState(null);
  useEffect(() => {
    if (tier) {
      const exchangesForCollectibleReleaseIndex = getExchange(
        collectible,
        tierTilesData,
        tier.tierType
      );
      const transformedData = transformExchange(exchangesForCollectibleReleaseIndex);
      setHasExchanges(transformedData.hasExchanges);
      setExchangesData(transformedData.exchangesData);
    }
  }, [collectible, tierTilesData, tier]);

  return { hasExchanges, exchangesData };
};

export const useDigitalOriginalExchangeID = ({ tier, currentTime, exchangesData }) => {
  const [digitalOriginalExchangeID, setDigitalOriginalExchangeID] = useState(null); // if this is set we get exchange

  useEffect(() => {
    // interval check 1s
    if (
      tier.tierType === TIER_TYPES.DIGITAL_ORIGINAL &&
      isDigitalOriginalReleased(tier, currentTime)
    ) {
      setDigitalOriginalExchangeID(
        exchangesData?.primaryMarket?.id ?? exchangesData?.secondaryMarket?.lowestPrice?.id
      );
    }
  }, [tier, exchangesData, currentTime]); // eslint-disable-line

  return digitalOriginalExchangeID;
};

export const useReleasedDigitalOriginalExchange = ({ tier, exchangesData, currentTime }) => {
  const digitalOriginalExchangeID = useDigitalOriginalExchangeID({
    tier,
    exchangesData,
    currentTime
  });

  const { data: digitalOriginalExchange } = useExchangeByID(digitalOriginalExchangeID);

  return digitalOriginalExchange;
};
