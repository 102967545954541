import React from 'react';
import { Page, Text, View, Document, Image, Font } from '@react-pdf/renderer';
import { Logo } from '@assets/pdf';

import { TIER_TYPES } from '@libs/utils/release';
import AvenirMedium from '@assets/pdf/fonts/AvenirNextCondensed-Medium.ttf';
import AvenirNextBold from '@assets/pdf/fonts/AvenirNextCondensed-DemiBold.ttf';
import AvenirNext from '@assets/pdf/fonts/AvenirNextCondensed-Regular.ttf';
import { orientations, documentSizes, documentSizeKeys } from '@components/print-collectible/utils';
import documentStyles from '@components/print-collectible/document-styles';

Font.register({
  family: 'Avenir',
  fonts: [
    {
      fontWeight: 500,
      src: AvenirNext
    },
    {
      fontWeight: 700,
      src: AvenirNextBold
    },
    {
      fontWeight: 800,
      src: AvenirNextBold
    }
  ]
});

Font.register({
  family: 'AvenirMedium',
  fontWeight: 500,
  src: AvenirMedium
});

// Create Document Component
const LandscapeDocument = ({ collectible, exchange, qrCode, url, image, size }) => {
  let [tierType, serialIndex] = [TIER_TYPES.DIGITAL_ORIGINAL, 1];
  if (exchange) {
    [tierType, serialIndex] = [exchange.tierType, exchange.serialIndex];
  }
  const { title, creator } = collectible ?? exchange.collectible;

  const { firstName: creatorFirstName, lastName: creatorLastName, displayName } = creator;
  const creatorName = displayName ? displayName : `${creatorFirstName} ${creatorLastName}`;

  const styles = documentStyles({
    size: size ?? documentSizeKeys.A_ONE,
    orientation: orientations.LANDSCAPE
  });

  return (
    <Document>
      <Page
        style={styles.pageWrapper}
        size={documentSizes[size]}
        orientation={orientations.LANDSCAPE}
      >
        <View style={styles.page}>
          <View style={styles.pageContent}>
            <View style={styles.contentWrapper}>
              <View style={styles.imageWrapper}>
                <Text style={styles.url}>{`fans.inc${url}`}</Text>
                <Image src={image} style={styles.image} cache={false} crossorigin="anonymous" />
              </View>
              <View style={styles.row}>
                <View style={styles.firstCol}>
                  <Text style={styles.title}>{title}</Text>
                  <Text style={styles.sub}>{`${creatorName} / #${serialIndex} ${tierType.replace(
                    /_/g,
                    ' '
                  )}`}</Text>
                  <Text style={styles.sub}>
                    Official Limited Edition Digital Collectible Photograph
                  </Text>
                </View>
                <View style={styles.secondCol}>
                  <Image style={styles.qrCode} src={qrCode} cache={false} crossorigin="anonymous" />
                  <Logo style={styles.logo} color="black" />
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

// Create styles

export default LandscapeDocument;
