import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Heading, Container, Text, TeamCard, TeamGrid } from '@components';
import { teams } from '../../utils';

function About() {
  const { t } = useTranslation();

  return (
    <>
      <Box className="page-about">
        <Box className="hero">
          <Container>
            <Box className="page-about__hero">
              <Grid className="page-about__hero__grid">
                <Box className="page-about__hero__grid__left">
                  <Box>
                    <Heading as="h2" className="page-about__hero__title">
                      {t('about.heroTitle')}
                    </Heading>
                    <Box className="page-about__hero__description">
                      <Text as="p">{t('about.heroDescription')}</Text>
                      <Text as="p">{t('about.heroDescription2')}</Text>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Box>
          </Container>
          <img className="hero__image" src="https://i.imgur.com/24s7Cdv.png" alt="About Fans.Inc" />
        </Box>

        <Box className="page-about__team">
          <Container>
            <Heading as="h3" className="page-about__team__title">
              {t('about.meetOurTeam')}
            </Heading>
            <TeamGrid>
              {teams.map((user, idx) => (
                <TeamCard key={idx} user={user} />
              ))}
            </TeamGrid>
          </Container>
        </Box>
      </Box>
      <Helmet>
        <title>{t('about.title')}</title>
        <meta property="og:image" content="https://cdn.fans.inc/public/webapp/og-image.png" />
      </Helmet>
    </>
  );
}

export default About;
