import { useEffect, useState, forwardRef, useRef, useImperativeHandle } from 'react';
import { Storage } from 'aws-amplify';
import { createAssetUrl } from '@libs/utils/media';
import { generateQR } from '@libs/utils/qrcode';
import { urlParser } from '@pages/profile/utils';
import { SkeletonLoading } from '@components';
import { useCollectionById } from '@libs/hooks/collection';
import { useUploader } from '@libs/utils/uploader';
import { download } from './hooks';
import Template from './template';

const FlexImage = forwardRef(function FlexImagePlain({ exchange, loading, display }, ref) {
  const { onUploadMint } = useUploader();
  const [image, setImage] = useState(null);
  const [wrapperSize, setWrapperSize] = useState(1);
  const [wrapperHeightSize, setWrapperHeightSize] = useState(1);
  const { data: collection, loading: collectionLoading } = useCollectionById(
    exchange?.collectible?.collectionID
  );
  const stageRef = useRef(null);
  const mediaProps = {
    data: exchange,
    src: exchange?.collectible?.mediaThumbnail
  };
  const url = urlParser(exchange, 'exchange');

  useEffect(() => {
    async function fetchImage() {
      if (mediaProps.src && !mediaProps.src.startsWith('http')) {
        const s3Key = createAssetUrl(mediaProps.src, 800);
        if (s3Key.startsWith('http')) {
          setImage(s3Key);
        } else {
          const result = await Storage.get(s3Key, { contentType: 'image/png' });
          setImage(result);
        }
      } else {
        setImage(mediaProps.src);
      }
    }
    fetchImage();
    // eslint-disable-next-line
  }, [exchange, document.getElementById('mint-it-main-image')?.offsetWidth, document.getElementById('mint-it-main-image')?.offsetWidth]);

  const handleResize = () => {
    if (document.getElementById('mint-it-main-image')) {
      setWrapperSize(document.getElementById('mint-it-main-image').offsetWidth);
      setWrapperHeightSize(document.getElementById('mint-it-main-image').offsetWidth);
    }
  };

  window.addEventListener('resize', handleResize);

  useImperativeHandle(ref, () => ({
    async onDownload() {
      const uri = await stageRef.current.toDataURL({ pixelRatio: 8 });
      download(uri, mediaProps.data.collectible.title);
    },
    async onUpload() {
      const uri = stageRef.current.toDataURL({ pixelRatio: 8 });
      await onUploadMint(uri, `${exchange?.collectible.id}_${exchange?.serialIndex}`);
    }
  }));
  useEffect(() => {
    handleResize();
  }, [display]);

  return (
    <>
      {loading || image === null || collectionLoading ? (
        <SkeletonLoading style={{ height: wrapperHeightSize }} className="patrons-mint__loading" />
      ) : (
        <Template
          {...mediaProps}
          collectible={exchange?.collectible}
          collectibleImage={image}
          ref={stageRef}
          collectionName={collection?.shortName}
          wrapperSize={wrapperSize}
          wrapperHeightSize={wrapperHeightSize}
        />
      )}
    </>
  );
});

export default FlexImage;
