import { useState, useRef, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { Helmet } from 'react-helmet';
import useToast from '@libs/utils/toast';
import {
  Box,
  Container,
  Grid,
  Media,
  Separator,
  Button,
  Tabs,
  TabItem,
  Link,
  ShareLink,
  SkeletonLoading
} from '@components';
import {
  useCollectible,
  useCollectibleLikesCountByCollectibleID,
  useAuthorCollectibles,
  useCollectibleDetail
} from '@libs/hooks/collectible';
import { useHistory, useParams } from 'react-router-dom';
import { useAuth } from '@libs/contexts/auth';
import useQuery from '@libs/utils/query';
import { ReactComponent as EyeIcon } from '@assets/svg/eye.svg';
import { ReactComponent as HeartIcon } from '@assets/svg/heart.svg';
import { ReactComponent as ArrowUpRight } from '@assets/svg/arrow-up-right.svg';
import { ReactComponent as ArrowRight } from '@assets/svg/arrow-right-free-collectible.svg';

import { createAssetUrl } from '@libs/utils/media';

import { useCollectibleView } from '@libs/hooks/collectible-view';
import { collectibleTypeParser } from '@pages/collectible/patrons/utils';
import { useTierAvailability } from '@libs/hooks/exchange';
import PatronsCollectibleDetail from './components/PatronsCollectibleDetail';
import PatronsCollectibleTheArtist from './components/PatronsCollectibleTheArtist';
import PatronsCollectibleCommentary from './components/PatronsCollectibleCommentary';
import PatronsCollectibleMarketplace from './components/PatronsCollectibleMarketplace';
import PatronsCollectibleVTMS from './components/PatronsCollectibleVTMS';
import PatronsCollectibleMore from './components/PatronsCollectibleMore';
import { useLocale } from '@libs/contexts/locale';
import { useCollectionById } from '@libs/hooks/collection';
import { ReactComponent as ZoomIn } from '@assets/svg/zoom-in.svg';
import { ReactComponent as Star } from '@assets/svg/star.svg';
import { ReactComponent as FilledStar } from '@assets/svg/star-filled.svg';
import { ReactComponent as CrossZoom } from '@assets/svg/patrons/cross-zoom.svg';
import { ReactComponent as ArrowRightWhite } from '@assets/svg/arrow-right-white.svg';
import { useCollectibleLike } from '@libs/hooks/collectible-like';
import Certificate from '@assets/img/certificate.png';
import ReactTooltip from 'react-tooltip';

function Collectible({ setGalleryLogo }) {
  const { getCurrencyFormatter } = useLocale();
  const toast = useToast();
  const currencyFormatter = getCurrencyFormatter();
  const params = useParams();
  const { user } = useAuth();
  const history = useHistory();
  const marketplaceRef = useRef(null);
  const responsiveMarketplaceRef = useRef(null);
  const query = useQuery();
  const { onView } = useCollectibleView();
  const { t } = useTranslation();
  const slug = params.slug;
  const isDraft = query.get('draft') === '1';
  const show = query.get('show');
  const { data: collectible, loading: collectibleLoading } = useCollectible(slug, isDraft);
  const { data: collection, loading: collectionLoading } = useCollectionById(
    collectible?.collectionID
  );
  useEffect(() => {
    if (show === 'scmarketplace') {
      setTimeout(() => {
        marketplaceRef.current.scrollIntoView({
          behavior: 'smooth'
        });
        responsiveMarketplaceRef.current.scrollIntoView({
          behavior: 'smooth'
        });
      }, 1000);
    }
  }, [show]);

  const toMarketplace = () => {
    marketplaceRef.current.scrollIntoView({
      behavior: 'smooth'
    });
    responsiveMarketplaceRef.current.scrollIntoView({
      behavior: 'smooth'
    });
  };

  const [showMore, setShowMore] = useState(false);
  const [showZoom, setShowZoom] = useState(false);
  const imageRef = useRef();

  useEffect(() => {
    setGalleryLogo({
      isShowLogo: collection?.isShowLogo === 'TRUE' ? true : false,
      logo: collection?.isShowLogo === 'TRUE' ? collection?.logoUrl : collection?.shortName,
      name: collection?.name
    });
  }, [collection]);

  const collectibleID = collectible?.id;
  const { data: collectibleLikeCount, loading: collectibleLikeCountLoading } =
    useCollectibleLikesCountByCollectibleID(collectibleID);

  const [likeCount, setLikeCount] = useState();

  const { data: collectibleSupply } = useCollectibleDetail(collectible?.id);

  const { data: totalRelease, loading: tierLoading } = useTierAvailability({
    collectibleID: collectible?.id,
    creatorID: collectible?.userID
  });

  const { data: otherCollectibles, loading: otherCollectiblesLoading } = useAuthorCollectibles({
    userID: collectible?.userID,
    collectibleID: collectible?.id
  });

  const maxQuantity =
    collectible?.maxNumberedEditions + ' (' + collectibleSupply?.totalReleased + ' released)';

  const numberedEdition = collectible?.releases?.find((x) => x.tierType === 'NUMBERED_EDITION');
  const now = new Date().toISOString();

  const collectibleType = collectibleTypeParser[collectible?.category];
  useEffect(() => {
    if (collectible) {
      const payload = {
        collectibleID: collectible?.id,
        userID: user?.id
      };
      onView(payload);
    }
  }, [collectible]);

  useEffect(() => {
    setLikeCount(collectibleLikeCount);
  }, [collectibleLikeCount]);

  const onClickFavorite = () => {
    history.replace(`/${user?.username}/?default=favourite`);
  };

  const shortDescription = collectible?.description.substring(0, 160) + '... ';

  const { collectibleLikes, updateCollectibleLikes, collectibleLikesLoading } = useAuth();
  const [isLiked, setIsLiked] = useState(collectibleLikes?.includes(collectibleID));
  const { onLike, loading: likeLoading } = useCollectibleLike(collectibleID, false);
  const likeCollectible = async () => {
    if (!likeLoading) {
      setIsLiked(!isLiked);
      if (isLiked) {
        const newCollectibleLikes = collectibleLikes?.filter((x) => x !== collectibleID);
        setLikeCount(likeCount - 1);
        updateCollectibleLikes(newCollectibleLikes);
        toast(t('p.pages.collectible.free.successRemove'), 'success');
      } else {
        updateCollectibleLikes(collectibleLikes.concat(collectibleID));
        setLikeCount(likeCount + 1);
        toast(
          <Box>
            <Box>{t('p.pages.collectible.free.success')}</Box>
            <Box onClick={() => onClickFavorite()} className="font-bold flex items-center gap-1">
              {t('p.pages.collectible.checkHere')} <ArrowRight />
            </Box>
          </Box>,
          'success'
        );
      }
      await onLike({
        collectibleID: collectibleID,
        onVisit: 'FALSE'
      });
    }
  };
  useEffect(() => {
    setIsLiked(collectibleLikes?.includes(collectibleID));
  }, [collectibleLikes, collectibleID]);

  return (
    <>
      <Container className="collectible">
        <Grid id="collectibleContent" column="12">
          <Box className="collectible__left">
            <Box className="collectible__image">
              <Box ref={imageRef} className="collectible__image__wrapper">
                <Media
                  collectible={collectible}
                  full
                  showPlayer
                  size={1080}
                  showPlayerSlider
                  showExternalLink={collectible?.streamExternal === 'TRUE'}
                />
                {!collectibleLoading && imageRef?.current?.clientWidth > 0 && (
                  <Box onClick={() => setShowZoom(true)} className="collectible__image__zoom">
                    <ZoomIn />
                  </Box>
                )}
              </Box>
            </Box>
            {/* Tabs */}
            <Box ref={marketplaceRef} className="collectible__tab collectible__tab__sticky mt-12">
              <Tabs directTo={t('p.pages.collectible.tab.marketplace')} full>
                <TabItem label={t('p.pages.collectible.tab.marketplace')}>
                  <PatronsCollectibleMarketplace collectible={collectible} />
                </TabItem>
                <TabItem label={t('p.pages.collectible.tab.detail')}>
                  <PatronsCollectibleDetail collectible={collectible} maxQuantity={maxQuantity} />
                </TabItem>
                <TabItem label={t('p.pages.collectible.tab.theArtist')}>
                  <PatronsCollectibleTheArtist collectible={collectible} />
                </TabItem>
                <TabItem label={t('p.pages.collectible.tab.commentary')}>
                  <PatronsCollectibleCommentary user={user} collectible={collectible} />
                </TabItem>
              </Tabs>
            </Box>
            {/* End Tabs */}
          </Box>
          <Box className="collectible__detail">
            <Box className="collectible__detail__sticky">
              <Box className="collectible__detail__top">
                {collectibleLoading ? (
                  <SkeletonLoading className="w-16 h-5" />
                ) : (
                  <Box className="collectible__detail__top__field">
                    <EyeIcon className="collectible__detail__top__field__icon" />
                    <Box className="collectible__detail__top__field__text">
                      {collectible?.viewCount || 0}
                    </Box>
                  </Box>
                )}
                {/* {collectibleLikeCountLoading && !collectibleLikesLoading ? (
                  <SkeletonLoading className="w-10 h-5 ml-8" />
                ) : (
                  <Box className="collectible__detail__top__field ml-8">
                    <Box className="mr-1">
                      <Box onClick={() => likeCollectible()} className="cursor-pointer">
                        {isLiked ? (
                          <AiFillHeart className="text-red-300" size="20" />
                        ) : (
                          <AiOutlineHeart className="text-primary" size="20" />
                        )}
                      </Box>
                    </Box>
                    <Box className="collectible__detail__top__field__text">{likeCount}</Box>
                  </Box>
                )} */}
                <Box style={{ marginLeft: '30px' }} className="collectible__detail__top__field">
                  <ShareLink style={{ paddingTop: '3px' }} />
                </Box>
                <Box style={{ marginLeft: '30px' }} className="flex cursor-pointer">
                  {collectibleLikesLoading || collectibleLoading ? (
                    <SkeletonLoading className="w-20 h-5" />
                  ) : (
                    <>
                      {isLiked ? (
                        <>
                          <div
                            data-tip
                            data-for={`minimumTooltipliked`}
                            onClick={() => likeCollectible()}
                            className="flex cursor-pointer"
                          >
                            <FilledStar
                              size="20"
                              style={{ marginTop: '1px' }}
                              className="collectible__detail__top__field__icon"
                            />
                            <Box className="collectible__detail__top__field__text">
                              {t('p.pages.collectible.favourite')}
                            </Box>
                          </div>
                          <ReactTooltip
                            delayHide={300}
                            className="extraClass"
                            effect="solid"
                            id={`minimumTooltipliked`}
                            place="bottom"
                            type="dark"
                          >
                            <Box className="normal-case max-w-xs">
                              {t('p.pages.collectible.likedCollectible')}
                            </Box>
                            <Box
                              onClick={() => onClickFavorite()}
                              className="font-bold flex items-center gap-1"
                            >
                              {t('p.pages.collectible.checkHere')} <ArrowRight />
                            </Box>
                          </ReactTooltip>
                        </>
                      ) : (
                        <>
                          <div
                            onClick={() => likeCollectible()}
                            data-tip
                            data-for={`minimumTooltipunliked`}
                            className="flex cursor-pointer"
                          >
                            <Star
                              size="20"
                              style={{ marginTop: '1px' }}
                              className="collectible__detail__top__field__icon"
                            />
                            <Box className="collectible__detail__top__field__text">
                              {t('p.pages.collectible.favourite')}
                            </Box>
                          </div>
                          <ReactTooltip
                            id={`minimumTooltipunliked`}
                            place="bottom"
                            type="dark"
                            effect="solid"
                          >
                            <Box className="normal-case max-w-xs">
                              {t('p.pages.collectible.likeCollectible')}
                            </Box>
                          </ReactTooltip>
                        </>
                      )}
                    </>
                  )}
                </Box>
              </Box>
              {collectibleLoading ? (
                <SkeletonLoading className="w-full h-11 mt-5" />
              ) : (
                <Box className="collectible__detail__title">{collectible?.title}</Box>
              )}

              {collectibleLoading ? (
                <SkeletonLoading className="w-full h-7 mt-5" />
              ) : (
                <Box className="collectible__detail__creator">
                  <Trans i18nKey="p.pages.collectible.creatorName">
                    <Link
                      to={`/${collectible?.creator?.username}`}
                      className="collectible__detail__creator__name"
                    >
                      {{
                        creatorName:
                          collectible?.creator?.displayName ||
                          collectible?.creator?.firstName + ' ' + collectible?.creator?.lastName
                      }}
                    </Link>
                  </Trans>
                  {collection?.shortName && (
                    <Trans i18nKey="p.pages.collectible.collectionName">
                      <Link
                        to={`/collection/${collection?.slug}`}
                        className="collectible__detail__creator__name"
                      >
                        {{
                          collectionName: collection?.shortName
                        }}
                      </Link>
                    </Trans>
                  )}
                </Box>
              )}
              {/* {collectionLoading || collectibleLoading ? (
                <SkeletonLoading className="h-10 w-52 my-3" />
              ) : (
                <>
                  {collection?.collectionUrl && (
                    <Link target="_blank" to={collection?.collectionUrl}>
                      <Box className="collectible__detail__link">
                        <Box className="collectible__detail__link__content">
                          <Box>
                            <Trans i18nKey="p.pages.collectible.visitCollection">
                              {{ collectionName: collection?.shortName }}
                            </Trans>
                          </Box>
                          <ArrowUpRight className="ml-2" />
                        </Box>
                      </Box>
                    </Link>
                  )}
                </>
              )} */}
              {collectibleLoading ? (
                <SkeletonLoading style={{ height: '84px' }} className="w-full mt-5" />
              ) : (
                <Box className="collectible__detail__description">
                  {collectible?.description?.length > 160 ? (
                    <>
                      {showMore ? (
                        <>
                          {collectible?.description}
                          <span
                            onClick={() => setShowMore(false)}
                            className="collectible__detail__description__more ml-1"
                          >
                            {t('p.pages.collectible.collapse')}
                          </span>
                        </>
                      ) : (
                        <>
                          {shortDescription}
                          <span
                            onClick={() => setShowMore(true)}
                            className="collectible__detail__description__more"
                          >
                            {t('p.pages.collectible.readMore')}
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <>{collectible?.description}</>
                  )}
                </Box>
              )}
              <Box className="collectible__detail__price">
                <Box className="collectible__detail__price__edition">
                  {collectibleLoading ? (
                    <SkeletonLoading className="h-7 w-48" />
                  ) : (
                    <Trans i18nKey="p.pages.collectible.edition">
                      {{
                        total: collectible?.maxNumberedEditions
                      }}
                    </Trans>
                  )}
                </Box>
                <Box className="collectible__detail__price__detail">
                  <Box className="collectible__detail__price__detail__field">
                    <Box className="collectible__detail__price__detail__field__name">
                      {t('p.pages.collectible.editionsAvailable')}
                    </Box>
                    {collectibleLoading ? (
                      <SkeletonLoading className="h-7 w-8" />
                    ) : (
                      <Box className="collectible__detail__price__detail__field__value">
                        {totalRelease}/{collectible?.maxNumberedEditions}
                      </Box>
                    )}
                  </Box>
                  <Box className="collectible__detail__price__detail__field">
                    <Box className="collectible__detail__price__detail__field__name">
                      {t('p.pages.collectible.sold')}
                    </Box>
                    {collectibleLoading ? (
                      <SkeletonLoading className="h-7 w-8" />
                    ) : (
                      <Box className="collectible__detail__price__detail__field__value">
                        {collectible?.maxNumberedEditions - totalRelease}
                      </Box>
                    )}
                  </Box>
                  <Box className="collectible__detail__price__detail__field">
                    <Box className="collectible__detail__price__detail__field__name">
                      {t('p.pages.collectible.price')}
                    </Box>
                    {collectibleLoading ? (
                      <SkeletonLoading className="h-7 w-8" />
                    ) : (
                      <Box className="collectible__detail__price__detail__field__value">
                        {currencyFormatter.format(Number(numberedEdition?.startingPrice))}
                      </Box>
                    )}
                  </Box>
                </Box>
                {collectibleLoading || tierLoading ? (
                  <SkeletonLoading className="h-14 w-full mt-4" />
                ) : (
                  <>
                    {numberedEdition?.releaseDate < now ? (
                      <>
                        {totalRelease === 0 ? (
                          <Button onClick={() => toMarketplace()} fullWidth className="mt-4">
                            {t('p.pages.collectible.viewOnMarketplace')}
                          </Button>
                        ) : (
                          <Link to={`/${collectible?.creator?.username}/${slug}/checkout/ne`}>
                            <Button fullWidth className="mt-4">
                              {t('p.pages.collectible.collectNow')}
                            </Button>
                          </Link>
                        )}
                      </>
                    ) : (
                      <Button fullWidth className="mt-4">
                        {t('p.pages.collectible.tba')}
                      </Button>
                    )}
                  </>
                )}
              </Box>
              {collectible?.printable === 'TRUE' && (
                <Link to={`/print/${collectible?.creator?.username}/${collectible?.slug}`}>
                  <Box className="collectible__detail__printable">
                    <Box className="collectible__detail__printable__icon">
                      <img src={Certificate} alt="" />
                    </Box>
                    <Box className="collectible__detail__printable__text">
                      {t('p.pages.collectible.printable')}
                    </Box>
                    <Box className="collectible__detail__printable__arrow">
                      <ArrowRightWhite />
                    </Box>
                  </Box>
                </Link>
              )}
              <Box className="collectible__detail__vtms">
                <Box className="text-base md:text-lg font-bold cursor-pointer">
                  {t('p.pages.collectible.vtms')}
                </Box>
                <PatronsCollectibleVTMS collectible={collectible} collection={collection} />
              </Box>
            </Box>
          </Box>
          <Box ref={responsiveMarketplaceRef} className="collectible__tab collectible__tab__block">
            <Tabs directTo={t('p.pages.collectible.tab.marketplace')} full>
              <TabItem label={t('p.pages.collectible.tab.marketplace')}>
                <PatronsCollectibleMarketplace collectible={collectible} />
              </TabItem>
              <TabItem label={t('p.pages.collectible.tab.detail')}>
                <PatronsCollectibleDetail collectible={collectible} maxQuantity={maxQuantity} />
              </TabItem>
              <TabItem label={t('p.pages.collectible.tab.theArtist')}>
                <PatronsCollectibleTheArtist collectible={collectible} />
              </TabItem>
              <TabItem label={t('p.pages.collectible.tab.commentary')}>
                <PatronsCollectibleCommentary user={user} collectible={collectible} />
              </TabItem>
            </Tabs>
          </Box>
        </Grid>
        {otherCollectiblesLoading ? (
          <PatronsCollectibleMore
            collectibles={otherCollectibles}
            loading={otherCollectiblesLoading}
            creator={collectible?.creator}
          />
        ) : (
          <>
            {otherCollectibles?.length > 0 ? (
              <PatronsCollectibleMore
                collectibles={otherCollectibles}
                loading={otherCollectiblesLoading}
                creator={collectible?.creator}
              />
            ) : (
              <></>
            )}
          </>
        )}
        {showZoom && (
          <Box className="collectible__zoom">
            <Box className="collectible__zoom__image">
              <Box className="collectible__zoom__image__media">
                <Media
                  collectible={collectible}
                  full
                  showPlayer
                  size={1920}
                  showPlayerSlider
                  showExternalLink={collectible?.streamExternal === 'TRUE'}
                />
              </Box>
              <Box className="collectible__zoom__image__close">
                <CrossZoom onClick={() => setShowZoom(false)} />
              </Box>
            </Box>
          </Box>
        )}
      </Container>
      <Helmet>
        <link rel="canonical" href={'https://patrons.art' + window.location.pathname} />
        <meta name="URL" content={'https://patrons.art' + window.location.pathname} />
        <meta name="twitter:card" content="summary_large_image" />
        {collectible?.title ? (
          <title>
            {t('collectible.meta.title', {
              creator:
                collectible?.creator?.displayName ||
                `${collectible?.creator?.firstName} ${collectible?.creator?.lastName}`,
              title: collectible?.title,
              type: collectibleType
            })}
          </title>
        ) : null}
        {collectible?.metaDescription ? (
          <meta name="description" content={collectible?.metaDescription} />
        ) : (
          <meta
            name="description"
            content={t('collectible.meta.description', {
              type: collectibleType,
              creator:
                collectible?.creator?.displayName ||
                `${collectible?.creator?.firstName} ${collectible?.creator?.lastName}`,
              title: collectible?.title,
              maxNumbered: collectible?.maxNumberedEditions
            })}
          />
        )}
        {collectible?.metaDescription ? (
          <meta name="og:description" content={collectible?.metaDescription} />
        ) : (
          <meta
            name="og:description"
            content={t('collectible.meta.description', {
              type: collectibleType,
              creator:
                collectible?.creator?.displayName ||
                `${collectible?.creator?.firstName} ${collectible?.creator?.lastName}`,
              title: collectible?.title,
              maxNumbered: collectible?.maxNumberedEditions
            })}
          />
        )}
        <meta
          property="og:title"
          content={t('collectible.meta.title', {
            creator:
              collectible?.creator?.displayName ||
              `${collectible?.creator?.firstName} ${collectible?.creator?.lastName}`,
            title: collectible?.title,
            type: collectibleType
          })}
        />
        <meta property="og:url" content={'https://patrons.art' + window.location.pathname} />
        <meta
          property="og:image"
          content={
            collectible?.mediaThumbnail
              ? createAssetUrl(collectible?.mediaThumbnail, 800)
              : 'https://cdn.patrons.art/public/webapp/og-image.png'
          }
        />
      </Helmet>
    </>
  );
}

export default Collectible;
