import React from 'react';
import PropTypes from 'prop-types';

import { Box, Button } from '@components';

import { useOptions } from '@components/button-radio/fansinc/hooks';
import { getButtonStyle } from '@components/button-radio/fansinc/utils';
import '@components/button-radio/fansinc/style.css';

function ButtonRadio({
  options,
  selectedKey,
  setSelectedKey,
  flattenOptions = false,
  selected = 'default',
  unselected = 'whiteOutline',
  containerStyle = {},
  disabled,
  ...props
}) {
  const options_ = useOptions({ options, flattenOptions });

  return (
    <Box className="button-radio" style={containerStyle}>
      {options_.map((option) => (
        <Button
          disabled={disabled}
          key={option.key}
          onClick={() => setSelectedKey(option.key)}
          {...getButtonStyle({
            option,
            selectedKey,
            selectedButtonStyle: selected,
            unselectedButtonStyle: unselected
          })}
          {...props}
        >
          {option.value}
        </Button>
      ))}
    </Box>
  );
}

ButtonRadio.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.exact({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.any
    })
  ),
  flattenOptions: PropTypes.bool,
  selectedKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setSelectedKey: PropTypes.func
};

export default ButtonRadio;
