import { React } from 'react';
import FansincNotFound from './fansinc';
import PatronsNotFound from './patrons';

function NotFound() {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsNotFound />;
  } else {
    return <FansincNotFound />;
  }
}
export default NotFound;
