/* eslint-disable */

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { useLocale } from '@libs/contexts/locale';
import useToast from '@libs/utils/toast';
import { EXCHANGE_INTENTS } from '@libs/utils/exchange';
import {
  Modal,
  Heading,
  CollectibleCard,
  Text,
  Price,
  UserLink,
  Grid,
  Box,
  Col,
  Button,
  Link,
  Separator,
  Image,
  Avatar
} from '@components';
import { acceptOfferGql, cancelOfferGql } from '@components/offer-modal/patrons/utils';
import handleGraphQLErrors from '@libs/utils/errors/graphql';
import { ReactComponent as ExternalLink } from '@assets/svg/patrons/external-link.svg';

const { ACCEPT_OFFER, CANCEL_OFFER, REJECT_OFFER } = EXCHANGE_INTENTS;

const SummaryWrapper = ({ title, description }) => (
  <Box className="">
    <Grid column="2">
      <Col>
        <Text className="text-primary font-bold font-Satoshi text-base text-left capitalize">
          {title}
        </Text>
      </Col>
      <Col>
        <Box>{description}</Box>
      </Col>
    </Grid>
  </Box>
);

function OfferModal({ exchangeOffer, exchangeIntent, isOpen, setIsOpen, onFinish }) {
  const { t, i18n } = useTranslation();
  const { currencyCode, calculateFees, fees: defaultFee } = useLocale();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const {
    id: exchangeID,
    release,
    patron,
    currentOwner,
    offerPrice,
    creator,
    paymentProvider
  } = exchangeOffer;
  const { fee: fees } = creator;

  const currentOwnerFullName = currentOwner.displayName
    ? currentOwner.displayName
    : currentOwner.firstName + ' ' + currentOwner.lastName;

  const creatorFullName = creator.displayName
    ? creator.displayName
    : creator.firstName + ' ' + creator.lastName;

  const patronFullName = patron.displayName
    ? patron.displayName
    : patron.firstName + ' ' + patron.lastName;

  const modalTranslationKey = {
    [ACCEPT_OFFER]: 'p.components.offerModal.acceptOffer',
    [CANCEL_OFFER]: 'p.components.offerModal.retractOffer',
    [REJECT_OFFER]: 'p.components.offerModal.rejectOffer'
  };

  const userToFrom = {
    [ACCEPT_OFFER]: patron,
    [CANCEL_OFFER]: currentOwner,
    [REJECT_OFFER]: patron
  };

  const onCloseModal = () => {
    if (!isLoading) {
      setIsOpen(false);
    }
  };

  const onConfirmationButtonClick = async () => {
    setIsLoading(true);
    try {
      switch (exchangeIntent) {
        case ACCEPT_OFFER:
          await acceptOfferGql(exchangeID, paymentProvider);
          onFinish();
          break;

        case CANCEL_OFFER:
        case REJECT_OFFER:
          await cancelOfferGql(exchangeID);
          onFinish();
          break;

        default:
          break;
      }
    } catch (error) {
      const handledError = handleGraphQLErrors(error);
      const errorMessage = (
        <>
          Couldn't process offer:
          <div>{handledError?.messages[0] ?? 'Unknown Error'}</div>
        </>
      );
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsOpen(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseModal}
      showCloseIcon
      heading={t(`${modalTranslationKey[exchangeIntent]}.title`)}
    >
      <Box className="w-full h-fit">
        <Image className="rounded" size={540} src={exchangeOffer?.collectible?.mediaThumbnail} />
      </Box>
      {release && (
        <CollectibleCard
          collectible={release}
          type="release"
          hasCollectibleTimer={false}
          showOwnerInUrl={true}
        />
      )}
      <Box className="bg-gray-75 p-5 mt-7 rounded flex flex-col gap-y-4">
        <SummaryWrapper
          title={t(`${modalTranslationKey[exchangeIntent]}.summary.artwork`)}
          description={
            <Box className="flex flex-col">
              <Text className="font-Satoshi text-base lg:text-lg font-medium inline-flex justify-start items-center">
                {exchangeOffer?.collectible?.title}
                <Link
                  className="ml-2"
                  to={`/${creator.username}/${exchangeOffer?.collectible?.slug}`}
                >
                  <ExternalLink className="w-4 h-4" />
                </Link>
              </Text>
              <Text className="font-Satoshi text-sm font-normal text-secondary">
                by{' '}
                <Link to={`/${creator.username}`} className="font-medium">
                  {creatorFullName}
                </Link>
              </Text>
            </Box>
          }
        />
        <SummaryWrapper
          title={t(`${modalTranslationKey[exchangeIntent]}.summary.amount`)}
          description={<Price amount={offerPrice} />}
        />
        {exchangeIntent === CANCEL_OFFER ? (
          <SummaryWrapper
            title={t(`${modalTranslationKey[exchangeIntent]}.summary.boughtFrom`)}
            description={
              <Box className="flex flex-row justify-start items-center">
                <Avatar size="xs" className="border rounded-full mr-2" user={currentOwner} />
                <Box className="flex flex-col justify-center items-start">
                  <Text className="text-primary capitalize font-medium text-base lg:text-lg">
                    {currentOwnerFullName}
                  </Text>
                  <Link to={`/${currentOwner.username}`}>
                    <Text className="text-sm capitalize text-secondary font-Satoshi">
                      view profile
                    </Text>
                  </Link>
                </Box>
              </Box>
            }
          />
        ) : (
          <>
            <SummaryWrapper
              title={t(`${modalTranslationKey[exchangeIntent]}.summary.buyer`)}
              description={
                <Box className="flex flex-row justify-start items-center">
                  <Avatar size="xs" className="border rounded-full mr-2" user={patron} />
                  <Box className="flex flex-col justify-center items-start">
                    <Text className="text-primary capitalize font-medium text-base lg:text-lg">
                      {patronFullName}
                    </Text>
                    <Link to={`/${patron.username}`}>
                      <Text className="text-sm capitalize text-secondary font-Satoshi">
                        view profile
                      </Text>
                    </Link>
                  </Box>
                </Box>
              }
            />
            <SummaryWrapper
              title={t(`${modalTranslationKey[exchangeIntent]}.summary.CreatorsRoyaltyFee`)}
              description={
                <Text as={'span'}>{defaultFee.CREATORS_ROYALTY_FEE_PERCENTAGE * 100}%</Text>
              }
            />
            <SummaryWrapper
              title={t(`${modalTranslationKey[exchangeIntent]}.summary.totalReceived`)}
              description={
                <Price
                  amount={offerPrice - calculateFees(offerPrice, fees?.salesFee)?.royaltyFee}
                />
              }
            />
          </>
        )}
      </Box>
      <Box className="accept-offer-modal__buttons">
        <Button
          fullWidth
          onClick={onConfirmationButtonClick}
          loading={isLoading}
          disabled={isLoading}
        >
          {t(`${modalTranslationKey[exchangeIntent]}.buttons.confirm`)}
        </Button>
        <Box className="w-10"></Box>
        <Button fullWidth onClick={() => setIsOpen(false)} disabled={isLoading} outline={true}>
          {t(`${modalTranslationKey[exchangeIntent]}.buttons.cancel`)}
        </Button>
      </Box>
      {i18n.exists(`${modalTranslationKey[exchangeIntent]}.helperText`) && (
        <Box className="accept-offer-modal__helper-text text-base">
          <Text as="span">{t(`${modalTranslationKey[exchangeIntent]}.helperText`)}</Text>
        </Box>
      )}
    </Modal>
  );
}

OfferModal.propTypes = {};

export default OfferModal;
