import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import { Helmet } from 'react-helmet';
import AuthLogin from '@pages/auth/login';
import AuthRegister from '@pages/auth/register';
import AuthActivate from '@pages/auth/activate';
import AuthForgotPassword from '@pages/auth/forgot';
import AuthResetPassword from '@pages/auth/reset';

import { Box, GuestRoute, Text } from '@components';

function Auth() {
  const { path } = useRouteMatch();
  const { t } = useTranslation();
  return (
    <>
      <Box className="auth-section">
        <Box className="auth-container">
          <Box className="auth-box">
            <Switch>
              <Route exact path="/login" component={AuthLogin} />
              <GuestRoute exact path="/signup" component={AuthRegister} />
              <Route exact path="/activate-user" component={AuthActivate} />
              <GuestRoute exact path="/forgot-password" component={AuthForgotPassword} />
              <GuestRoute exact path="/reset-password" component={AuthResetPassword} />
            </Switch>
          </Box>
        </Box>
        <Box className="auth-image"></Box>
      </Box>
      <Helmet>
        <title>{t('p.head.meta.title')}</title>
        <meta name="description" content={t('p.head.meta.description')} />
        <meta property="og:title" content={t('p.head.meta.title')} />
        <meta property="og:description" content={t('p.head.meta.description')} />
        <meta property="og:image" content="https://cdn.patrons.art/public/webapp/og-image.png" />
      </Helmet>
    </>
  );
}

export default Auth;
