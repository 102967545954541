import { React } from 'react';
import FansincAbout from './fansinc';
import PatronsAbout from './patrons';

function About() {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsAbout />;
  } else {
    return <FansincAbout />;
  }
}
export default About;
