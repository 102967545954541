export const exchangeByNftTokenString = /* GraphQL */ `
  query ExchangeByNftTokenString(
    $nftTokenIDString: String
    $sortDirection: ModelSortDirection
    $filter: ModelExchangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    exchangeByNftTokenString(
      nftTokenIDString: $nftTokenIDString
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        status
        patronID
        paymentProvider
        bitpayPaymentRequested
        paymentRequestExpiredAt
        paymentReferenceID
        checkoutReceiptID
        createdAt
        updatedAt
        nftTransactionHash
        nftOwnerAddress
        nftContractAddress
        nftTokenID
        nftTokenIDString
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        order {
          tax
          platformFee
          salesFee
          royalty
          royaltyFee
          invoiceID
          checkoutReceiptID
          bitpayReceiptID
        }
        searchableExchangeType
        searchableIsSold
        searchableHighestPrice
        searchableStatus
        _version
        _deleted
        _lastChangedAt
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          streamExternal
          streamUrl {
            spotify
            appleMusic
            youtube
          }
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableHighestBidPrice
          searchableGenre
          _version
          _deleted
          _lastChangedAt
          owner
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          nftTokenIDString
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          searchableHighestPrice
          searchableStatus
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          status
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentRequestExpiredAt
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          nftTransactionHash
          nftOwnerAddress
          nftContractAddress
          nftTokenID
          nftTokenIDString
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          searchableExchangeType
          searchableIsSold
          searchableHighestPrice
          searchableStatus
          _version
          _deleted
          _lastChangedAt
          owner
        }
        creator {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        currentOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patron {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        previousOwner {
          id
          owner
          accountID
          editors
          email
          username
          firstName
          lastName
          displayName
          bio
          tags
          verified
          fanProfile
          creatorCategory
          emailVerified
          joinDate
          isHidden
          profileTemplate
          walletAddresses
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
