import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box, Heading, Text, Image } from '@components';
import ImageLoading from '@assets/loading.svg';

const Loading = ({ title, description }) => {
  const { t } = useTranslation();

  return (
    <Box align="center">
      <Image
        alt={t('information.loading')}
        src={ImageLoading}
        className={cx('image__information')}
      />
      <Heading bold as="h1" size="3xl" className="pb-2 text-primary">
        {title}
      </Heading>
      <Text className="text-primary">{description}</Text>
    </Box>
  );
};

Loading.defaultProps = {
  title: 'Loading'
};

export default Loading;
