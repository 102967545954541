import { useState, useEffect, useCallback } from 'react';
import gql from '@libs/utils/gql';
import { getDataForTierTiles } from '@graphql/queries';

export const useTierTilesData = (collectible) => {
  const [tierTilesData, setTierTilesData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const collectibleID = collectible?.id;
  const creatorID = collectible?.userID;
  const numberOfCollectibleReleases = collectible?.releases?.length;

  const getTierTilesDataGql = useCallback(async () => {
    try {
      setIsLoading(true);
      const {
        data: { getDataForTierTiles: res }
      } = await gql(
        getDataForTierTiles,
        {
          input: { collectibleID, creatorID, numberOfCollectibleReleases }
        },
        {
          authMode: 'API_KEY'
        }
      );

      const parsedData = res ? JSON.parse(res) : null;
      if (parsedData) {
        setTierTilesData(parsedData?.body?.lowestPriceExchanges);
      } else {
        setTierTilesData(null);
        setHasError(true);
      }
    } catch (error) {
      console.error(error);
      setHasError(true);
      setTierTilesData(null);
    } finally {
      setIsLoading(false);
    }
  }, [collectibleID, creatorID, numberOfCollectibleReleases]);

  useEffect(() => {
    if (collectibleID && creatorID && numberOfCollectibleReleases) {
      getTierTilesDataGql();
    }
  }, [collectibleID, creatorID, numberOfCollectibleReleases, getTierTilesDataGql]);

  return { tierTilesData, isLoading, hasError, getData: getTierTilesDataGql };
};
