import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  NotFound,
  SkeletonLoading,
  Button,
  Error,
  Link
} from '@components';
import { urlParser } from '@pages/profile/utils';
import { EXCHANGE_INTENTS } from '@libs/utils/exchange';
import { useExchangeByID } from '@libs/hooks/exchange';
import { useConfirmationQuery, useRefreshSession } from '@pages/confirmation/patrons/hooks';
import { getTitleAndDescription } from '@pages/confirmation/patrons/utils';
import { useLocale } from '@libs/contexts/locale';
import { usePollOrderPrint } from '@libs/hooks/prodigi';
import Marquee from './marquee';

const ErrorConfirmationContent = ({ title, description }) => (
  <Box className="confirmation justify-center items-center flex pt-0" align="center">
    <Error title={title} description={description} />
  </Box>
);

const LoadingConfirmationContent = ({ title }) => {
  const centerColClasses = 'flex w-full items-center';
  return (
    <>
      <Box className="w-full flex flex-col md:flex-row">
        <Box className="text-primary w-full md:w-1/2">
          <Box className="w-full p-10 md:p-20 flex justify-center items-center">
            <Box className="max-w-lg py-20 md:py-40">
              <Heading as="h3" className="text-primary text-4xl capitalize mb-7">
                {title}
              </Heading>
              <Box className="mb-5">
                {[...Array(3)].map((_, i) => (
                  <Box className="mb-2" key={`skel-${i}`}>
                    <SkeletonLoading className="my-1 h-3 w-full" />
                  </Box>
                ))}
                <Box className="mb-2">
                  <SkeletonLoading className="my-1 h-3 w-10/12" />
                </Box>
              </Box>
              <Box className="mb-5">
                {[...Array(2)].map((_, i) => (
                  <Box className="mb-2" key={`skel-${i}`}>
                    <SkeletonLoading className="my-1 h-3 w-full" />
                  </Box>
                ))}
                <Box className="mb-2">
                  <SkeletonLoading className="my-1 h-3 w-10/12" />
                </Box>
              </Box>
              <Box className="mb-2">
                <SkeletonLoading className="my-1 h-14 w-full" />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="w-full md:w-1/2 bg-gray-300 confirmation">
          <Box className="flex items-center justify-center h-full md:py-0 py-10">
            <Box className="w-60 md:w-96">
              <SkeletonLoading className="h-60 md:h-96 w-full" />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const PageNotFound = () => (
  <Box className="confirmation justify-center items-center flex pt-0" align="center">
    <NotFound
      title="Page not found"
      description="We’re sorry, the page you're looking for can't be found."
    />
  </Box>
);

function Confirmation() {
  const { t } = useTranslation();
  const history = useHistory();
  const { exchange: exchangeID } = useParams();
  const { data: exchange, loading: loadingExchange } = useExchangeByID(exchangeID);
  const { status, orderID, imageUrl, color } = useConfirmationQuery();
  const { orderPrint, timeoutExceeded: orderTimeout } = usePollOrderPrint(orderID, 2000);
  useRefreshSession();

  useEffect(() => {
    if (orderPrint && orderPrint?.orderStatus === 'InProgress') {
      history.push(
        `/po-congratulation/?eid=${orderPrint.exchangeID}&oid=${orderID}&img=${imageUrl}&clr=${color}`
      );
    }
  }, [orderPrint]); // eslint-disable-line

  const exchangeURL = window.location.hostname + urlParser(exchange, 'release', true);
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${exchangeURL}`;
  const { currencyCode, currencyFormatter } = useLocale();

  let { title, description, additionalDesc } = getTitleAndDescription({
    exchangeIntent: EXCHANGE_INTENTS.PRINT_ORDER_PURCHASE,
    exchange,
    status,
    timeoutExceeded: orderTimeout,
    t,
    currencyCode,
    currencyFormatter
  });
  const selectedFrame = color === 'White' ? '7px solid #E0E0E0' : '7px solid black';

  const hasError = status === 'fail' || orderTimeout;
  const exchangeNotFound = !orderID;

  if (hasError) {
    return <ErrorConfirmationContent title={title} description={description} />;
  }

  if (exchangeNotFound) {
    return <PageNotFound />;
  }

  const ConfirmationContainerInner = () => (
    <div>
      <Box className="w-full flex flex-col md:flex-row">
        <Box className="text-primary w-full md:w-1/2">
          <Box className="w-full flex justify-center items-center">
            <Box className="max-w-lg py-5 md:py-40 px-10">
              <Box className="block md:hidden mt-10">
                <img src={imageUrl} alt="" />
              </Box>
              <Heading
                as="h3"
                className="text-primary text-2xl md:text-4xl mt-8 md:mt-0 capitalize mb-7"
              >
                {title}
              </Heading>
              <Text as="p" className="text-base md:text-lg leading-7 mb-7">
                {description}
              </Text>
              {additionalDesc && (
                <Text as="p" className="text-base md:text-lg leading-7 mb-7">
                  {additionalDesc}
                </Text>
              )}
              <Link
                to={`/${exchange?.collectible?.creator?.username}/${exchange?.collectible?.slug}`}
              >
                <Button fullWidth>{t('confirmation.backToCollectible')}</Button>
              </Link>
            </Box>
          </Box>
        </Box>
        <Box className="text-primary w-full md:w-1/2 bg-gray-200 relative overflow-hidden confirmation hidden md:block">
          <div className="absolute w-full -top-12">
            <Marquee title="ORDER CONFIRMED" />
          </div>
          <Box className="flex justify-center items-center h-full">
            <Box className="w-full px-10 md:px-0 py-10 md:py-0 md:w-1/2 z-50">
              <Box className="relative">
                <img src={imageUrl} alt="" />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );

  return (
    <Box className="w-full">
      {exchange && !loadingExchange ? (
        <ConfirmationContainerInner />
      ) : (
        <LoadingConfirmationContent title={t('confirmation.loading')} />
      )}
    </Box>
  );
}

export default Confirmation;
