import cx from 'classnames';
import { Link, useLocation } from 'react-router-dom';

import '@components/link/style.css';

const FansLink = ({ target, to, className, underlined, children, ...props }) => {
  const location = useLocation();
  const destination = to ? to : '#';
  return (
    <>
      <Link
        to={{ pathname: destination, state: { prevPath: location.pathname } }}
        target={target}
        className={cx(
          className,
          underlined && 'link__underlined',
          underlined === 'white' && 'link__underlined__white'
        )}
        {...props}
      >
        {children}
      </Link>
    </>
  );
};

export default FansLink;
