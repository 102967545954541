import gql from '@libs/utils/gql';
import { listSettings } from '@libs/custom-queries/setting';

export const whitelistEmail = async (email) => {
  const protectedEnv = ['sandbox', 'prod', 'patrons'];
  if (protectedEnv.includes(process.env.REACT_APP_AMPLIFY_ENV)) {
    try {
      const validEmail = ['vinceredigital.com', 'fans.inc'];
      const { data: settings } = await gql(listSettings);

      const whitelisted_email = settings?.listSettings?.items[0]?.whitelisted_email;
      const emailTest = email.split('@');

      if (!(validEmail.includes(emailTest[1]) || whitelisted_email.includes(email))) {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  return true;
};
