import FansincLogo from './fansinc';
import PatronsLogo from './patrons';

const Logo = ({ className, width, height, footer }) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsLogo className={className} width={width} height={height} footer={footer} />;
  } else {
    return <FansincLogo className={className} width={width} height={height} />;
  }
};

export default Logo;
