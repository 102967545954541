import PropTypes from 'prop-types';

export const releasePropType = PropTypes.shape({
  id: PropTypes.string
  // TODO: populate according to API
});

export const exchangePropType = PropTypes.shape({
  id: PropTypes.string
  // TODO: populate according to API
});
