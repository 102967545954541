import { useCookies } from 'react-cookie';
import { useEffect, useState } from 'react';
import config from '@/config';

export const useVideoCookies = (user) => {
  const [cookies, setCookie] = useCookies([config.cookie.VIDEO_PREFIX + user?.id]);
  const [video, setVideo] = useState(true);

  const setVideoCookies = (user) => {
    const nextMonth = 3600 * 24 * 30;
    setCookie(config.cookie.VIDEO_PREFIX + user?.id, 1, {
      maxAge: nextMonth
    });
  };

  const isVideoPlayed = (user) => {
    if (cookies[config.cookie.VIDEO_PREFIX + user?.id]) {
      setVideo(true);
    } else {
      setVideo(false);
      setVideoCookies(user);
    }
  };

  useEffect(() => {
    if (user?.media?.avatarVideoUrl) {
      isVideoPlayed(user);
    }
  }, [user]); // eslint-disable-line

  return {
    video
  };
};
