import cx from 'classnames';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import { Trans } from 'react-i18next';
import {
  Box,
  Button,
  Modal,
  SkeletonLoading,
  Text,
  Price,
  CollectibleTooltip,
  Link,
  ViewOnOpenSea
} from '@components';
import Login from '@pages/auth/components/login';
import { LISTING_TYPES, EXCHANGE_INTENTS } from '@libs/utils/exchange';
import { useWalletAddress } from '@components/nft-address/patrons/hooks';
import { formatWalletAddress } from '@components/connect-wallet-button/patrons/utils';
import { ReactComponent as MetamaskIcon } from '@assets/svg/metamask.svg';
import { ReactComponent as CogIcon } from '@assets/svg/cog.svg';
import { copyToClipboard } from '@libs/utils/share';
import useToast from '@libs/utils/toast';

const PurchaseButton = ({ exchangeIntent, disabled, exchange }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [showOptionsTooltip, setShowOptionsTooltip] = useState(false);

  const redirectUrl = (exchangeIntent = null, isPlaceBid = false) => {
    return !isPlaceBid
      ? history.push(
          `/${exchange?.collectible?.creator?.username}/${exchange?.collectible?.slug}/checkout/${exchange?.id}?type=${exchangeIntent}`
        )
      : history.push(
          `/${exchange?.collectible?.creator?.username}/${exchange?.collectible?.slug}/checkout/do`
        );
  };

  switch (exchangeIntent) {
    case EXCHANGE_INTENTS.FIXED_PRICE_PURCHASE:
      return (
        <>
          {disabled ? (
            <Box className="relative">
              <>
                <Box
                  className="inline-block"
                  onMouseEnter={() => setShowOptionsTooltip(true)}
                  onMouseLeave={() => setShowOptionsTooltip(false)}
                >
                  <Box className="purchase-card__price__button__multiple">
                    <Button outline>{t('components.purchaseCard.buyNow')}</Button>
                    <Button outline>{t('components.purchaseCard.makeOffer')}</Button>
                  </Box>
                  <CollectibleTooltip
                    arrow="bottom"
                    onShow={showOptionsTooltip}
                    className="purchase-card__tooltip__multiple"
                  >
                    <Trans i18nKey="components.purchaseCard.toolTip">
                      <Link
                        to={`/${exchange.currentOwner.username}/collectibles?showListingModal=${exchange.id}`}
                      >
                        {{ link: 'here' }}
                      </Link>
                    </Trans>
                  </CollectibleTooltip>
                </Box>
              </>
            </Box>
          ) : (
            <Box className="purchase-card__price__button__multiple">
              <>
                <Button onClick={() => redirectUrl()}>{t('components.purchaseCard.buyNow')}</Button>
                <Button onClick={() => redirectUrl('MAKE_AN_OFFER')}>
                  {t('components.purchaseCard.makeOffer')}
                </Button>
              </>
            </Box>
          )}
        </>
      );
    case EXCHANGE_INTENTS.MAKE_OFFER:
      return (
        <>
          {disabled ? (
            <Box className="relative">
              <>
                <Box
                  className="inline-block"
                  onMouseEnter={() => setShowOptionsTooltip(true)}
                  onMouseLeave={() => setShowOptionsTooltip(false)}
                >
                  <Button outline>{t('components.purchaseCard.makeOffer')}</Button>
                  <CollectibleTooltip
                    arrow="bottom"
                    onShow={showOptionsTooltip}
                    className="purchase-card__tooltip"
                  >
                    <Trans i18nKey="components.purchaseCard.toolTip">
                      <Link
                        to={`/${exchange.currentOwner.username}/collectibles?showListingModal=${exchange.id}`}
                      >
                        {{ link: 'here' }}
                      </Link>
                    </Trans>
                  </CollectibleTooltip>
                </Box>
              </>
            </Box>
          ) : (
            <Button onClick={() => redirectUrl()}>{t('components.purchaseCard.makeOffer')}</Button>
          )}
        </>
      );
    case EXCHANGE_INTENTS.PLACE_BID:
      return (
        <>
          {disabled ? (
            <Box className="relative">
              <>
                <Box
                  className="inline-block"
                  onMouseEnter={() => setShowOptionsTooltip(true)}
                  onMouseLeave={() => setShowOptionsTooltip(false)}
                >
                  <Button outline>{t('components.purchaseCard.placeBid')}</Button>
                  <CollectibleTooltip
                    arrow="bottom"
                    onShow={showOptionsTooltip}
                    className="purchase-card__tooltip"
                  >
                    <Trans i18nKey="components.purchaseCard.toolTip">
                      <Link
                        to={`/${exchange.currentOwner.username}/collectibles?showListingModal=${exchange.id}`}
                      >
                        {{ link: 'here' }}
                      </Link>
                    </Trans>
                  </CollectibleTooltip>
                </Box>
              </>
            </Box>
          ) : (
            <Button onClick={() => redirectUrl(null, true)}>
              {t('components.purchaseCard.placeBid')}
            </Button>
          )}
        </>
      );
    default:
      return <ViewOnOpenSea exchange={exchange} isButton />;
  }
};

const PurchaseCard = ({
  exchange,
  currentBidExchange,
  disabled,
  onClickPurchase,
  className,
  minted,
  children,
  ...props
}) => {
  const { t } = useTranslation();
  const [authModal, setAuthModal] = useState(false);
  const isPrimaryMarket = exchange?.creatorID === exchange?.currentOwnerID;
  const walletAddress = useWalletAddress({ exchange });
  const toast = useToast();
  const copyClipboard = (event, address) => {
    event.stopPropagation();
    event.preventDefault();
    copyToClipboard(address);
    toast(t('components.purchaseCard.copyAddress'), undefined, 500);
  };

  const setAuthModalClose = () => {
    setAuthModal(false);
  };

  const listingTypeToDefaultExchangeIntent = {
    [LISTING_TYPES.FIXED_PRICE]: EXCHANGE_INTENTS.FIXED_PRICE_PURCHASE,
    [LISTING_TYPES.MAKE_AN_OFFER]: EXCHANGE_INTENTS.MAKE_OFFER,
    [LISTING_TYPES.AUCTION]: EXCHANGE_INTENTS.PLACE_BID
  };

  const getTitle = (listingType, isPrimaryMarket) => {
    if (listingType === LISTING_TYPES.MAKE_AN_OFFER && isPrimaryMarket) {
      return t('components.purchaseCard.minimumOffer');
    }
    const titleByListingType = {
      [LISTING_TYPES.FIXED_PRICE]: t('components.purchaseCard.price'),
      [LISTING_TYPES.MAKE_AN_OFFER]: t('components.purchaseCard.minimumOffer'),
      [LISTING_TYPES.AUCTION]: t('components.purchaseCard.currentBidPrice'),
      [LISTING_TYPES.NFT]: t('components.purchaseCard.minted')
    };
    return titleByListingType[listingType];
  };

  const getPrice = (listingType, isPrimaryMarket) => {
    if (listingType === LISTING_TYPES.MAKE_AN_OFFER && isPrimaryMarket) {
      return exchange.offerPrice;
    }
    const titleByListingType = {
      [LISTING_TYPES.FIXED_PRICE]: exchange.sellingPrice,
      [LISTING_TYPES.MAKE_AN_OFFER]: exchange.offerPrice,
      [LISTING_TYPES.AUCTION]: currentBidExchange?.bidPrice ?? exchange.currentPrice
    };
    return titleByListingType[listingType];
  };

  const getManageURL = () => {
    return `/sell/${exchange?.collectible?.creator?.username}/${exchange?.collectible?.slug}/${exchange?.serialIndex}`;
  };
  return (
    <Box className={cx('purchase-card', minted && 'purchase-card__minted', className)} {...props}>
      <Box className="purchase-card__price">
        {minted ? (
          <div
            style={{ paddingBlock: 10, cursor: 'pointer' }}
            className="purchase-card__price__address"
          >
            <div
              style={{ display: 'flex', alignItems: 'center' }}
              onClick={(e) => copyClipboard(e, walletAddress)}
            >
              <MetamaskIcon width={40} height={40} />
              <Text
                style={{ paddingLeft: 10, marginRight: 20 }}
                as="span"
                className="nft-address-key"
              >
                {walletAddress && formatWalletAddress(walletAddress)}
              </Text>
            </div>
            <PurchaseButton
              exchangeIntent={listingTypeToDefaultExchangeIntent[exchange?.currentListingType]}
              disabled={disabled}
              exchange={exchange}
            />
          </div>
        ) : (
          <Text className="purchase-card__price__item">
            {exchange ? (
              <>
                {!disabled && (
                  <Box>
                    <Text className="purchase-card__title">
                      {getTitle(exchange?.currentListingType, isPrimaryMarket)}
                    </Text>
                    <Price amount={getPrice(exchange?.currentListingType, isPrimaryMarket)} />
                  </Box>
                )}

                {exchange?.currentListingType !== LISTING_TYPES.AUCTION && (
                  <Box>
                    <Text className="purchase-card__title">
                      {!disabled
                        ? t('components.purchaseCard.lastSold')
                        : t('components.purchaseCard.lastBought')}
                    </Text>
                    <Price amount={exchange?.previousPrice} />
                  </Box>
                )}
              </>
            ) : (
              <SkeletonLoading className="w-1/2 h-8" />
            )}
          </Text>
        )}

        <Box
          className={`purchase-card__price__button ${
            minted && 'purchase-card__price__button__minted'
          }`}
        >
          {!disabled ? (
            <PurchaseButton
              exchangeIntent={listingTypeToDefaultExchangeIntent[exchange?.currentListingType]}
              disabled={disabled}
              exchange={exchange}
            />
          ) : (
            <Box className="flex items-center">
              <Link to={`/settings/offers-bids`}>
                <Button className="mr-3">{t('components.purchaseCard.seeOffers')}</Button>
              </Link>
              <Link to={getManageURL()}>
                <CogIcon />
              </Link>
            </Box>
          )}
        </Box>
      </Box>
      <Modal className="auth-modal" size="md" isOpen={authModal} onClose={setAuthModalClose}>
        <Box className="auth-modal-container">
          <Box className="auth-modal-container-close">
            <IoCloseOutline onClick={setAuthModalClose} size={32} color="gray" />
          </Box>
          <Box className="auth-modal-box">
            <Login />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

PurchaseCard.propTypes = {
  disabled: PropTypes.bool
};

export default PurchaseCard;
