import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Modal,
  Price,
  SkeletonLoading,
  Table,
  VerticalTable,
  PrintInvoice,
  Button
} from '@components';

const WalletHistoryTable = ({ histories, loading, handleNextPage, nextPage }) => {
  const { t } = useTranslation();
  const [history, setHistory] = useState([]);
  const [modalDetail, setModalDetail] = useState(false);
  const getHistoriesCols = () => {
    const historiesCols = [
      ['#'],
      [t('components.walletHistoryTable.column.date')],
      [t('components.walletHistoryTable.column.description')],
      [
        <Box key="amount" className="text-right">
          {t('components.walletHistoryTable.column.amount')}
        </Box>
      ],
      [
        <Box key="balance" className="text-right">
          {t('components.walletHistoryTable.column.balance')}
        </Box>
      ]
    ];
    return historiesCols;
  };

  const action = (i) => {
    setHistory(i);
    setModalDetail(true);
  };

  const historyRow = getHistoriesRows({
    histories,
    loading,
    action
  });

  return (
    <>
      <Table cols={getHistoriesCols()} rows={historyRow} className="wallet-history-table" />
      <ModalWalletHistoryTable
        isOpen={modalDetail}
        onClose={() => setModalDetail(false)}
        history={history}
      />
      {loading && <LoadingWalletHistoryTable />}
      {nextPage && (
        <Button onClick={handleNextPage} className="mt-2">
          {t('components.purchaseHistoryTable.loadMore')}
        </Button>
      )}
    </>
  );
};

const statusMapper = {
  PROCESSED: 'Completed',
  PENDING: 'Pending'
};

const getHistoriesRows = ({ loading, histories, action }) => {
  const [historyRows, setHistoryRows] = useState([]);

  useEffect(() => {
    if (!loading && history) {
      let historyRows_ = histories.map((history, historyIndex) => [
        [
          <Box
            onClick={() => action(history)}
            className="wallet-history-table-order"
            key={historyIndex}
          >
            {history?.invoiceID}
          </Box>
        ],
        [format(new Date(history?.createdAt), 'd/MM/yyyy')],
        [
          <Box className="wallet-history-description whitespace-normal" key={historyIndex}>
            {history?.description?.charAt(0) === 'S'
              ? history?.description
                  ?.replace('Sales of ', '')
                  .replace('Numbered Edition', 'Limited Edition')
              : history?.description?.replace('Numbered Edition', 'Limited Edition')}
          </Box>
        ],
        [
          <Box
            style={{ color: history?.credit !== '0' ? '#FB5758' : '#41D15A' }}
            key={historyIndex}
            className={`text-right`}
          >
            <Price
              inline
              key={history?.id}
              amount={history?.debit === '0' ? `${history?.credit}` : history?.debit}
              minus={history?.credit !== '0'}
            />
            <Box
              style={{ color: history.status === 'PROCESSED' ? '#41D15A' : '' }}
              className="wallet-history-table-status"
            >
              {statusMapper[history?.status]}
            </Box>
          </Box>
        ],
        [
          <Box key={historyIndex} className="text-right">
            <Price key={history?.id} amount={history?.balance} />
          </Box>
        ]
      ]);
      setHistoryRows(historyRows_);
    }
  }, [loading, history]);

  return historyRows;
};

const ModalWalletHistoryTable = ({ isOpen, history, onClose }) => {
  const { t } = useTranslation();
  let modalAttribute = [
    [t('components.walletHistoryTable.modal.column.transactionId'), history?.invoiceID],
    [
      t('components.walletHistoryTable.modal.column.date'),
      history?.createdAt ? format(new Date(history?.createdAt), 'd/MM/yyyy') : null
    ],
    [
      t('components.walletHistoryTable.modal.column.description'),
      history?.description?.charAt(0) === 'S'
        ? history?.description
            ?.replace('Sales of ', '')
            .replace('Numbered Edition', 'Limited Edition')
        : history?.description?.replace('Numbered Edition', 'Limited Edition')
    ],
    [
      t('components.walletHistoryTable.modal.column.amount'),
      <Price key={history?.id} amount={history?.debit === '0' ? history?.credit : history?.debit} />
    ]
  ];
  return (
    <Modal showCloseIcon isOpen={isOpen} onClose={onClose} heading={`#${history?.invoiceID}`}>
      <VerticalTable rows={modalAttribute} />
      <Box className="wallet-history-table-modal-button">
        <PrintInvoice history={history}></PrintInvoice>
      </Box>
    </Modal>
  );
};

const LoadingWalletHistoryTable = () => (
  <div className="my-5">
    <SkeletonLoading />
    <div className="my-2" />
    <SkeletonLoading />
  </div>
);

export default WalletHistoryTable;
