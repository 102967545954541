import FansincReleaseCheckout from './fansinc';
import PatronsReleaseCheckout from './patrons';

const Separator = ({
  exchange,
  className,
  editionBadgeProps,
  headingTop = false,
  headingRight = false,
  ...props
}) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return (
      <PatronsReleaseCheckout
        className={className}
        headingTop={headingTop}
        headingRight={headingRight}
        exchange={exchange}
        editionBadgeProps={editionBadgeProps}
        {...props}
      />
    );
  } else {
    return (
      <FansincReleaseCheckout
        className={className}
        headingTop={headingTop}
        headingRight={headingRight}
        exchange={exchange}
        editionBadgeProps={editionBadgeProps}
        {...props}
      />
    );
  }
};

export default Separator;
