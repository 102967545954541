import { Switch, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IoMenu } from 'react-icons/io5';
import { useCallback, useState } from 'react';

import SettingsProfile from '@pages/settings/patrons/profile';
import SettingsNotifications from '@pages/settings/patrons/notifications';
import SettingsAccount from '@pages/settings/patrons/account';
import SettingsSubscriptions from '@pages/settings/patrons/notifications';
import SettingsOffersBids from '@pages/settings/patrons/offers-bids';
import SettingsTransaction from '@pages/settings/patrons/transactions';
import { useOutsider } from '@libs/utils/outsider';
import { ReactComponent as UserLogo } from '@assets/svg/user-setting-icon.svg';
import { ReactComponent as LockLogo } from '@assets/svg/setting-lock-account.svg';
import { ReactComponent as WalletLogo } from '@assets/svg/wallet-setting-icon.svg';
import { ReactComponent as Arrow } from '@assets/svg/arrow-left-right.svg';
import { ReactComponent as Bell } from '@assets/svg/bell-setting-icon.svg';
import { Box, Button, Col, Container, Grid, Link, ProtectedRoute } from '@components';

function Settings() {
  const { path } = useRouteMatch();
  const { t } = useTranslation();
  const [isOpenMenu, setOpenMenu] = useState(false);

  const handleClose = () => setOpenMenu(false);
  const { ref } = useOutsider(handleClose);

  const isActive = (menuPath) => {
    return menuPath === path ? 'active' : '';
  };

  const handleOpenSidebarMenu = useCallback(
    (e) => {
      e.preventDefault();
      setOpenMenu(!isOpenMenu);
    },
    [isOpenMenu]
  );

  return (
    <>
      <Container className="page-settings">
        <Grid className="page-settings__wrapper">
          <Box className="page-settings__wrapper__left pt-0 lg:pt-10 lg:col-span-2 col-span-12">
            <Box className="settings-sidebar">
              <Link to="/settings" className={isActive('/settings')}>
                <Box className="flex items-center gap-4">
                  <UserLogo />
                  {t('settings.menu.profile')}
                </Box>
              </Link>
              <Link to="/settings/account" className={isActive('/settings/account')}>
                <Box className="flex items-center gap-4">
                  <LockLogo />
                  {t('settings.menu.account')}
                </Box>
              </Link>
              <Link to="/settings/transactions" className={isActive('/settings/transactions')}>
                <Box className="flex items-center gap-4">
                  <WalletLogo />
                  {t('settings.menu.transactions')}
                </Box>
              </Link>
              <Link to="/settings/offers-bids" className={isActive('/settings/offers-bids')}>
                <Box className="flex items-center gap-4">
                  <Arrow />
                  {t('settings.menu.offersBids')}
                </Box>
              </Link>
              <Link to="/settings/notifications" className={isActive('/settings/notifications')}>
                <Box className="flex items-center gap-4">
                  <Bell />
                  {t('settings.menu.notifications')}
                </Box>
              </Link>
            </Box>
            <Box className="settings-sidebar__mobile hidden" ref={ref}>
              <Button
                darkOutline
                onClick={handleOpenSidebarMenu}
                className="settings-sidebar__mobile__button"
              >
                <IoMenu />
              </Button>
              {isOpenMenu && (
                <Box className="settings-sidebar__mobile__menu" onClick={handleClose}>
                  <Link to="/settings" className={isActive('/settings')}>
                    <Box className="flex items-center gap-4">
                      <UserLogo />
                      {t('settings.menu.profile')}
                    </Box>
                  </Link>
                  <Link to="/settings/account" className={isActive('/settings/account')}>
                    <Box className="flex items-center gap-4">
                      <LockLogo />
                      {t('settings.menu.account')}
                    </Box>
                  </Link>
                  <Link to="/settings/transactions" className={isActive('/settings/transactions')}>
                    <Box className="flex items-center gap-4">
                      <WalletLogo />
                      {t('settings.menu.transactions')}
                    </Box>
                  </Link>
                  <Link to="/settings/offers-bids" className={isActive('/settings/offers-bids')}>
                    <Box className="flex items-center gap-4">
                      <Arrow />
                      {t('settings.menu.offersBids')}
                    </Box>
                  </Link>
                  <Link
                    to="/settings/notifications"
                    className={isActive('/settings/notifications')}
                  >
                    <Box className="flex items-center gap-4">
                      <Bell />
                      {t('settings.menu.notifications')}
                    </Box>
                  </Link>
                </Box>
              )}
            </Box>
            <Box className="settings-sidebar__flex" ref={ref}>
              <Box className="settings-sidebar__flex__menu">
                <Link to="/settings" className={isActive('/settings')}>
                  <Box className="flex items-center gap-4">
                    <UserLogo />
                    {t('settings.menu.profile')}
                  </Box>
                </Link>
                <Link to="/settings/account" className={isActive('/settings/account')}>
                  <Box className="flex items-center gap-4">
                    <LockLogo />
                    {t('settings.menu.account')}
                  </Box>
                </Link>
                <Link to="/settings/transactions" className={isActive('/settings/transactions')}>
                  <Box className="flex items-center gap-4">
                    <WalletLogo />
                    {t('settings.menu.transactions')}
                  </Box>
                </Link>
                <Link to="/settings/offers-bids" className={isActive('/settings/offers-bids')}>
                  <Box className="flex items-center gap-4">
                    <Arrow />
                    {t('settings.menu.offersBids')}
                  </Box>
                </Link>
                <Link to="/settings/notifications" className={isActive('/settings/notifications')}>
                  <Box className="flex items-center gap-4">
                    <Bell />
                    {t('settings.menu.notifications')}
                  </Box>
                </Link>
              </Box>
            </Box>
            <Box className="settings-sidebar__flex__separator"></Box>
          </Box>
          <Box className="pt-8 lg:pt-10 col-span-12 lg:col-span-6">
            <Box className="settings-content">
              <Switch>
                <ProtectedRoute exact path="/settings" component={SettingsProfile} />
                <ProtectedRoute
                  exact
                  path="/settings/notifications"
                  component={SettingsNotifications}
                />
                <ProtectedRoute
                  exact
                  path="/settings/subscriptions"
                  component={SettingsSubscriptions}
                />
                <ProtectedRoute exact path="/settings/account" component={SettingsAccount} />
                <ProtectedRoute exact path="/settings/offers-bids" component={SettingsOffersBids} />
                <ProtectedRoute
                  exact
                  path="/settings/transactions"
                  component={SettingsTransaction}
                />
              </Switch>
            </Box>
          </Box>
        </Grid>
      </Container>
    </>
  );
}

export default Settings;
