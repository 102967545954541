export { exchangeByCollectibleIDAndCurrentOwnerID } from '@libs/custom-queries/exchange/exchangeByCollectibleIDAndCurrentOwnerID';
export {
  searchExchanges,
  searchExchangesLite,
  searchExchangesCount,
  searchExchangesCheckout,
  searchExchangesHistory
} from '@libs/custom-queries/exchange/searchExchange';
export { exchangeByNftTokenString } from '@libs/custom-queries/exchange/exchangeByNftTokenString';
export { findUnitExchange } from '@libs/custom-queries/exchange/findUnitExchange';
import { userPublicFragment, creatorPublicFragment } from '@libs/fragments/user';

export const exchangeByID = /* GraphQL */ `
  query getExchange($id: ID!) {
    getExchange(id: $id) {
      id
      exchangeType
      owner
      creatorID
      currentOwnerID
      collectibleID
      serialIndex
      listingType
      startingPrice
      sellingPrice
      currentPrice
      finalPrice
      previousPrice
      auctionMinimumAllowedBidPrice
      offerPrice
      bidPrice
      currentListingType
      expiryDate
      isSold
      isReleased
      patronID
      nftOwnerAddress
      nftTokenIDString
      paymentReferenceID
      checkoutReceiptID
      tierType
      createdAt
      updatedAt
      paymentRequestExpiredAt
      paymentProvider
      bitpayPaymentRequested
      searchableHighestPrice
      previousExchange {
        listingType
      }
      parentExchange {
        expiryDate
        searchableIsSold
      }
      slug
      collectible {
        id
        accountID
        userID
        type
        category
        collectionID
        title
        slug
        description
        assetUrl
        mediaThumbnail
        featured
        createdAt
        updatedAt
        lastSoldAt
        owner
        maxNumberedEditions
        searchableLowestPrice
        searchableHighestPrice
        streamExternal
        streamUrl {
          spotify
          appleMusic
          youtube
        }
        creator {
          ...UserPublicProfile
        }
      }
      currentOwner {
        ...CreatorPublicProfile
      }
    }
  }

  ${userPublicFragment}
  ${creatorPublicFragment}
`;

export const exchangeByCollectiblePrimaryMarket = /* GraphQL */ `
  query ExchangeByCollectibleAndStartingPrice(
    $collectibleID: ID
    $startingPrice: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExchangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    exchangeByCollectibleAndStartingPrice(
      collectibleID: $collectibleID
      startingPrice: $startingPrice
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        currentOwnerID
        startingPrice
        collectibleReleaseIndex
      }
      nextToken
      startedAt
    }
  }
`;

export const exchangeByOwnerAndExchangeType = /* GraphQL */ `
  query ExchangeByOwnerAndExchangeType(
    $currentOwnerID: ID
    $exchangeType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExchangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    exchangeByOwnerAndExchangeType(
      currentOwnerID: $currentOwnerID
      exchangeType: $exchangeType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        exchangeType
        creatorID
        currentOwnerID
        collectibleID
        collectibleReleaseIndex
        serialIndex
        listingType
        releaseDate
        isReleased
        sellingPrice
        offerPrice
        startingPrice
        lastPrice
        auctionMinimumAllowedBidPrice
        isMinimumOfferVisible
        isAutoAcceptActive
        bidPrice
        maxBidPrice
        expiryDate
        isSold
        patronID
        paymentProvider
        bitpayPaymentRequested
        paymentReferenceID
        checkoutReceiptID
        createdAt
        updatedAt
        userID
        currentListingType
        lastPurchasedAt
        slug
        tierType
        releaseCount
        previousOwnerID
        previousPrice
        finalPrice
        currentPrice
        previousExchangeID
        parentExchangeID
        bitpayReceiptID
        _deleted
        collectible {
          id
          accountID
          userID
          type
          category
          genre
          title
          slug
          description
          insideStory
          assetUrl
          mediaThumbnail
          marketingText
          whatsIncludedOriginal
          whatsIncludedNumbered
          featured
          featuredInProfile
          status
          yearOfCreation
          streamExternal
          streamUrl {
            spotify
            appleMusic
            youtube
          }
          releasedAt
          createdAt
          updatedAt
          maxNumberedEditions
          metaDescription
          searchableType
          searchableFeatured
          searchablefeaturedInProfile
          searchableCategory
          searchableStatus
          searchableLowestPrice
          searchableHighestPrice
          searchableGenre
          owner
          creator {
            ...CreatorPublicProfile
          }
        }
        owner
        previousExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        parentExchange {
          id
          exchangeType
          creatorID
          currentOwnerID
          collectibleID
          collectibleReleaseIndex
          serialIndex
          listingType
          releaseDate
          isReleased
          sellingPrice
          offerPrice
          startingPrice
          lastPrice
          auctionMinimumAllowedBidPrice
          isMinimumOfferVisible
          isAutoAcceptActive
          bidPrice
          maxBidPrice
          expiryDate
          isSold
          patronID
          paymentProvider
          bitpayPaymentRequested
          paymentReferenceID
          checkoutReceiptID
          createdAt
          updatedAt
          userID
          currentListingType
          lastPurchasedAt
          slug
          tierType
          releaseCount
          previousOwnerID
          previousPrice
          finalPrice
          currentPrice
          previousExchangeID
          parentExchangeID
          bitpayReceiptID
          _version
          _deleted
          _lastChangedAt
          owner
        }
        creator {
          ...CreatorPublicProfile
        }
        currentOwner {
          ...CreatorPublicProfile
        }
        previousOwner {
          ...CreatorPublicProfile
        }
      }
      nextToken
      startedAt
    }
  }

  ${userPublicFragment}
`;
