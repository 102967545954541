import { Helmet } from 'react-helmet';
import { useTranslation, Trans } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { Box, Button, FormField, Heading, Input, Text, TextArea, Modal } from '@components';
import Lottie from 'react-lottie';
import Success from '@lotties/Success';
import Recaptcha from 'react-recaptcha';
import config from '@/config';
import { useInbox } from '@libs/hooks/inbox';

function Contact() {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState();
  const { onCreate, createLoading } = useInbox();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Success
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField
  } = useForm({
    mode: 'onBlur'
  });

  let recaptchaInstance;

  const executeCaptcha = function () {
    recaptchaInstance.execute();
  };

  const verifyCallback = async (response) => {
    const submitData = onSubmit(response);
    await handleSubmit(submitData)();
  };

  const supportEmail = 'support@patrons.art';
  const onSubmit = (captchaToken) => async (data) => {
    const response = await onCreate({ ...data, captchaToken });
    if (response) {
      setIsModalOpen(true);
      resetField('fullName');
      resetField('email');
      resetField('message');
    }
  };
  return (
    <>
      <Box className="flex flex-col md:flex-row">
        <Box className="hidden md:block md:w-1/2">
          <Box className="contact-image"></Box>
        </Box>
        <Box className="w-full md:w-1/2">
          <Box className="px-10 md:px-20 max-w-2xl flex justify-center flex-col my-10 md:my-0 contact-height">
            <Heading
              as="h3"
              className="text-primary text-4xl font-bold capitalize mb-7 tracking-wide leading-9 font-Satoshi"
            >
              {t('p.contact.heading')}
            </Heading>
            <Text className="test-base md:text-lg leading-5 md:leading-7 font-medium text-secondary mb-8">
              <Trans i18nKey="p.contact.description">
                <a className="font-bold text-primary" href={`mailto:${supportEmail}`}>
                  {{ supportEmail }}
                </a>
              </Trans>
            </Text>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormField>
                <Input
                  fullWidth
                  label={t('p.contact.fields.fullName.label')}
                  placeholder={t('p.contact.fields.fullName.placeholder')}
                  errorMessage={errors.fullName?.message}
                  {...register('fullName', {
                    required: { value: true, message: t('p.contact.fields.fullName.required') }
                  })}
                />
              </FormField>
              <FormField>
                <Input
                  fullWidth
                  label={t('p.contact.fields.email.label')}
                  placeholder={t('p.contact.fields.email.placeholder')}
                  as="email"
                  errorMessage={errors.email?.message}
                  {...register('email', {
                    required: { value: true, message: t('p.contact.fields.email.required') },
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: t('p.contact.fields.email.invalid')
                    }
                  })}
                />
              </FormField>
              <FormField>
                <TextArea
                  fullWidth
                  label={t('p.contact.fields.message.label')}
                  placeholder={t('p.contact.fields.message.placeholder')}
                  errorMessage={errors.message?.message}
                  {...register('message', {
                    required: { value: true, message: t('p.contact.fields.message.required') }
                  })}
                />
              </FormField>
              <Recaptcha
                ref={(e) => (recaptchaInstance = e)}
                sitekey={config.cookie.TOKEN_SITE_KEY_CAPTCHA_CONTACT}
                size="invisible"
                verifyCallback={verifyCallback}
              />
              <Button
                loading={createLoading}
                onClick={executeCaptcha}
                primary
                className="w-full"
                as="submit"
              >
                {t('p.contact.sendMessage')}
              </Button>
            </form>
          </Box>
        </Box>
        <Modal isOpen={isModalOpen} showOnlyCloseIcon onClose={() => setIsModalOpen(false)}>
          <Box className="contact-modal">
            <Box className="contact-modal__icon">
              <Box className="contact-modal__icon__wrapper">
                <Lottie options={defaultOptions} />
              </Box>
            </Box>
            <Box className="contact-modal__title">{t('p.contact.modal.title')}</Box>
            <Box className="flex items-center justify-center">
              <Box className="contact-modal__information">{t('p.contact.modal.description')}</Box>
            </Box>
          </Box>
        </Modal>
      </Box>
      <Helmet>
        <title>{t('contact.title')}</title>
        <meta property="og:image" content="https://cdn.fans.inc/public/webapp/og-image.png" />
      </Helmet>
    </>
  );
}

export default Contact;
