import cx from 'classnames';
import add from 'date-fns/add';
import format from 'date-fns/format';
import { useTranslation, Trans } from 'react-i18next';
import { useState, useEffect } from 'react';

import { Avatar, Box, EditionBadge, Text, SkeletonLoading } from '@components';
import { useTierAvailability } from '@libs/hooks/exchange';
import { TIER_TYPES } from '@libs/utils/exchange';
import { useUserByID } from '@libs/hooks/user';

const TierTiles = ({
  tier,
  tierIndex,
  collectible,
  title,
  value,
  button,
  onClick,
  hasExchanges,
  hasExtraButton,
  exchangesData
}) => {
  const { t } = useTranslation();
  const { data: totalRelease, loading } = useTierAvailability({
    collectibleID: collectible?.id,
    creatorID: collectible?.userID,
    serialIndex: tierIndex
  });

  const releaseDateSeconds = new Date(tier?.releaseDate).getTime();
  const twoMonthsFromNow = format(add(new Date(), { months: 2 }), 'T');
  const isReleaseDateWithinTwoMonths = twoMonthsFromNow >= releaseDateSeconds;
  const isReleaseDateBeyondTwoMonths = twoMonthsFromNow < releaseDateSeconds;
  const isCurrentlyReleased = true;
  const hasUnsoldReleases = totalRelease !== 0;
  // TODO: migrate all numbered edition logic to createContent
  const [count, setCount] = useState(0);
  const [auctionStyle, setAuctionStyle] = useState('edition-card__content__original-in');

  useEffect(() => {
    const id = setInterval(() => setCount((oldCount) => oldCount + 1), 1000);

    return () => {
      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    if (count > 0) {
      if (count % 3 === 0) {
        if (count % 2 !== 0) {
          setAuctionStyle('edition-card__content__original-in');
        } else {
          setAuctionStyle('edition-card__content__original-out');
        }
      }
    }
  }, [count]);

  switch (tier.tierType) {
    case TIER_TYPES.NUMBERED_EDITION:
      const hasSecondaryMarketSales = hasExchanges.secondaryMarket;

      return (
        <Box onClick={onClick}>
          <Box className="edition-card__content">
            <Text className="text-default edition-card__content__numbered__title">
              {t('components.editionCard.numberedEdition')}
            </Text>
            <Text className="edition-card__content__numbered__available">
              {!loading ? (
                hasUnsoldReleases ? (
                  isCurrentlyReleased ? (
                    <>
                      {totalRelease?.toString()}{' '}
                      <Text as="span" className="edition-card__content__numbered__available__of">
                        {t('components.editionCard.of')}
                      </Text>{' '}
                      {tier?.releaseCount?.toString()} <br />
                      <Trans i18nKey="components.editionCard.available"></Trans>
                    </>
                  ) : hasSecondaryMarketSales ? (
                    <Trans i18nKey="components.editionCard.primarySaleClosed" />
                  ) : (
                    <></>
                  )
                ) : (
                  <Trans
                    i18nKey={`components.editionCard.edition${
                      tier?.releaseCount !== 1 ? 's' : ''
                    }SoldOut`}
                  >
                    {tier?.releaseCount?.toString()}
                    <br />
                  </Trans>
                )
              ) : (
                <>
                  <SkeletonLoading className="w-6/12 h-6 mx-auto mb-1" />
                  <SkeletonLoading className="w-6/12 h-6 mx-auto" />
                </>
              )}
            </Text>
            <Box className="edition-card__content__numbered__information">
              <Text className="edition-card__content__numbered__desc">{title}</Text>
              {(isReleaseDateWithinTwoMonths && hasUnsoldReleases && isCurrentlyReleased) ||
              hasExtraButton ||
              totalRelease === tier?.releaseCount ||
              totalRelease === 0 ? (
                <Box className="edition-card__content__numbered__value">{value}</Box>
              ) : null}
            </Box>
          </Box>
          <Box className="edition-card__button">{button}</Box>
        </Box>
      );
    case TIER_TYPES.DIGITAL_ORIGINAL: {
      const hasSecondaryMarketSales = hasExchanges.secondaryMarket;
      const { data: user } = useUserByID(
        exchangesData?.secondaryMarket?.fixedPrice?.currentOwnerID ||
          exchangesData?.secondaryMarket?.offer?.currentOwnerID
      );
      return (
        <Box onClick={onClick}>
          <EditionBadge
            isSecondaryMarket={hasSecondaryMarketSales}
            tier={tier}
            className={cx('bg-none shadow-none', hasSecondaryMarketSales && '-mt-2')}
          />
          {hasSecondaryMarketSales && user && (
            <Box className="edition-card__owner">
              <Avatar className="edition-card__owner__avatar" user={user} size="xs" />
              <Box className="edition-card__owner__name">
                {user?.displayName || user?.firstName + ' ' + user?.lastName}
              </Box>
            </Box>
          )}
          <Box className="edition-card__content">
            <Text
              className={cx(
                'edition-card__content__original__title',
                isReleaseDateBeyondTwoMonths ? 'edition-card__content__original__title-soon' : '',
                hasExtraButton
                  ? 'edition-card__content__original__title-extra'
                  : 'edition-card__content__original__title-default',
                auctionStyle
              )}
            >
              {title}
            </Text>
            <Box className={cx('edition-card__content__original__value', auctionStyle)}>
              {value}
            </Box>
          </Box>
          <Box className="edition-card__button">{button}</Box>
        </Box>
      );
    }
    default:
      return null;
  }
};

export default TierTiles;
