import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Box,
  Avatar,
  Grid,
  FormField,
  Heading,
  Image,
  Post,
  Button,
  SkeletonLoading,
  CollectibleGrid,
  CollectibleCard,
  Link,
  TextArea
} from '@components';
import { formatDistanceToNowStrict, format } from 'date-fns';
import { Trans, useTranslation } from 'react-i18next';
import { useAuth } from '@libs/contexts/auth';
import { useChannelByOwner } from '@libs/hooks/discourse';
import { useHistory } from 'react-router-dom';
import { useDiscourse, useDiscourseFeatured } from '@libs/hooks/discourse';
import { useCollectiblesByIDs } from '@libs/hooks/collectible';
import { useForm } from 'react-hook-form';
import NotFound from '@pages/not-found';

function FanClub({ profile }) {
  const params = useParams();
  const history = useHistory();
  const username = params.username.toLowerCase();
  const channelID = params.channelID;
  const { user } = useAuth();
  const [channelSelected, setChannelSelected] = useState();
  const { data: collectibles, loading: collectibleLoading } = useCollectiblesByIDs(
    channelSelected?.collectibles
  );
  const { data: channels, getData: fetchChannel } = useChannelByOwner(profile?.id);
  const [discourseInput, setDiscourseInput] = useState({
    userID: '',
    channel: ''
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    resetField
  } = useForm({
    mode: 'onBlur',
    shouldFocusError: false
  });

  let listMembers = channelSelected?.members?.map((x) => x.userID);

  let isHaveAccess = listMembers?.includes(user?.id);

  const [dataError, setDataError] = useState(false);
  useEffect(() => {
    if (channels?.length > 0 && channelID && user) {
      const channel = channels?.filter((x) => x.id === channelID && x.collectibles.length > 0);
      if (!channel[0]) {
        setDataError(true);
      } else {
        setChannelSelected(channel[0]);
        setDiscourseInput({
          userID: user?.id,
          channel: channel[0]
        });
      }
    }
  }, [channelID, channels, user]);

  const {
    data,
    loading: postLoading,
    createPostLoading,
    nextPageToken,
    paginationLoading,
    getData,
    setLoading,
    createPost
  } = useDiscourse(discourseInput);

  const { data: featuredPost, loading: featuredLoading } = useDiscourseFeatured(discourseInput);

  const handlePost = useCallback(
    async (params) => {
      const now = new Date();
      const nowString = now.toString();
      const payload = {
        title: (user?.displayName || user?.firstName + ' ' + user?.lastName) + ' POST ' + nowString,
        raw: params.inputPost
      };
      await createPost(payload);
      resetField('inputPost');
      setTimeout(function () {
        getData();
        fetchChannel();
      }, 2000);
    },
    [discourseInput]
  ); // eslint-disable-line

  const { t } = useTranslation();
  const channel = ({ channel, isActive }) => {
    return (
      <>
        {channel?.collectibles?.length > 0 && (
          <Box
            onClick={() => {
              history.push(`/${username}/fanclub/${channel?.id}`);
            }}
            className={`fanclub-right-channel-detail${isActive ? '-active' : ''}`}
          >
            <Box className="fanclub-right-channel-detail-title-public">{channel.name}</Box>
            <Box className="fanclub-right-channel-detail-activity">
              <Trans i18nKey="profile.fanclub.channel.activity">
                {{
                  time:
                    channels &&
                    formatDistanceToNowStrict(new Date(channel?.lastActivityAt), {
                      addSuffix: true
                    })
                }}
              </Trans>
            </Box>
          </Box>
        )}
      </>
    );
  };

  if (dataError) {
    return <NotFound />;
  }

  return (
    <Container size="xl" className="profile__container">
      <Grid column="12" className="fanclub">
        {user ? (
          <>
            <Box className="col-span-12 xl:col-span-8 fanclub-left">
              {isHaveAccess ? (
                <>
                  <form onSubmit={handleSubmit(handlePost)} noValidate>
                    <Box className="fanclub-left-create-post">
                      <Avatar
                        user={user}
                        size="xs-40"
                        className="fanclub-left-create-post-avatar"
                      />
                      <Box className="fanclub-left-create-post-form">
                        <FormField>
                          <TextArea
                            style={{ height: 68, width: '100%' }}
                            fullwidth
                            placeholder={`${t('profile.fanclub.createPost.placeholder')} ${
                              user?.firstName
                            }...`}
                            errorMessage={errors?.inputPost?.message}
                            {...register('inputPost', {})}
                          />
                        </FormField>
                        <Button
                          loading={createPostLoading}
                          type="submit"
                          // onClick={() => handlePost()}
                        >
                          {t('profile.fanclub.createPost.button')}
                        </Button>
                      </Box>
                    </Box>
                  </form>
                  <Box className="fanclub-left-channel">
                    <Box>{channelSelected?.name}</Box>
                  </Box>
                  {postLoading || featuredLoading ? (
                    <>
                      <SkeletonLoading className="col-span-12 xl:col-span-8 h-24 mt-6" />
                    </>
                  ) : (
                    <>
                      <Box className="fanclub-left-post">
                        {featuredPost?.map((post) => (
                          <Post
                            user={profile}
                            postId={post.id}
                            post={post}
                            setLoadingPosts={setLoading}
                            channel={channelSelected}
                            fetchPosts={getData}
                            key={`post-${post.id}`}
                            featured
                          />
                        ))}
                        {data?.map((post) => (
                          <Post
                            user={profile}
                            postId={post.id}
                            post={post}
                            setLoadingPosts={setLoading}
                            channel={channelSelected}
                            fetchPosts={getData}
                            key={`post-${post.id}`}
                          />
                        ))}
                      </Box>
                      {nextPageToken && (
                        <div className="fanclub-left-loadMore">
                          <Button
                            loading={paginationLoading}
                            onClick={() => getData(nextPageToken)}
                          >
                            {t('profile.fanclub.loadMore')}
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {!collectibleLoading && collectibles.length > 0 ? (
                    <Box className="fanclub-left-collectibles">
                      <Box className="fanclub-left-collectibles-title">
                        <Trans i18nKey="profile.fanclub.collectibleTitle">
                          {{
                            displayName:
                              profile?.displayName || profile?.firstName + ' ' + profile?.lastName
                          }}
                          {{
                            channelName: channelSelected?.name
                          }}
                        </Trans>
                      </Box>
                    </Box>
                  ) : (
                    <SkeletonLoading className="h-28" />
                  )}
                  <CollectibleGrid loading={collectibleLoading}>
                    {collectibles &&
                      collectibles.map((collectible) => (
                        <CollectibleCard
                          key={collectible.id}
                          collectible={collectible}
                        ></CollectibleCard>
                      ))}
                  </CollectibleGrid>
                </>
              )}
            </Box>
            <Box className="col-span-12 xl:col-span-4 fanclub-right">
              <Box className="fanclub-right-profile">
                <Heading className="fanclub-right-profile-header">
                  {t('profile.fanclub.profile.welcomeBack')}
                </Heading>
                <Box className="fanclub-right-profile-detail">
                  <Avatar
                    user={user}
                    size="xs-40"
                    className="fanclub-right-profile-detail-avatar"
                  />
                  <Box className="fanclub-right-profile-detail-text">
                    <Box className="fanclub-right-profile-detail-text-name">
                      {user?.displayName || user?.firstName + ' ' + user?.lastName}
                    </Box>
                    <Box className="fanclub-right-profile-detail-text-date">
                      <Trans i18nKey="profile.fanclub.profile.date">
                        {{ date: user && format(new Date(user?.createdAt), 'd MMMM yyyy') }}
                      </Trans>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="fanclub-right-channel">
                <Box className="fanclub-right-channel-title">
                  {profile?.media?.fanclubLogoUrl ? (
                    <Image
                      className="fanclub-right-channel-logo"
                      src={profile?.media?.fanclubLogoUrl}
                    />
                  ) : (
                    profile && (
                      <Trans i18nKey="profile.fanclub.channel.title">
                        {{
                          name: profile?.displayName || profile?.firstName + ' ' + profile?.lastName
                        }}
                      </Trans>
                    )
                  )}
                </Box>
                {channels?.map((x, index) => (
                  <React.Fragment key={`channel-${x.id}-${index}`}>
                    {channel({
                      channel: x,
                      isActive: x?.id === channelSelected?.id
                    })}
                  </React.Fragment>
                ))}
                {/* <Box className="fanclub-right-channel-footer">
                    <Box className="fanclub-right-channel-footer-content">
                      <Box className="fanclub-right-channel-footer-content-text">
                        {t('profile.fanclub.channel.seeAllChannel')}
                      </Box>
                      <Box className="fanclub-right-channel-footer-content-icon">
                        <AiOutlineArrowRight />
                      </Box>
                    </Box>
                  </Box> */}
              </Box>
            </Box>
          </>
        ) : (
          <>
            {profile ? (
              <Box className="col-span-12 fanclub-login">
                <Box className="fanclub-login-wrapper">
                  <Box className="fanclub-login-wrapper-header">
                    <Trans i18nKey="profile.fanclub.login.header">
                      {{
                        displayName:
                          profile?.displayName || profile?.firstName + ' ' + profile?.lastName
                      }}
                    </Trans>
                  </Box>
                  <Box className="fanclub-login-wrapper-description">
                    <Trans i18nKey="profile.fanclub.login.description">
                      {{
                        displayName:
                          profile?.displayName || profile?.firstName + ' ' + profile?.lastName
                      }}
                    </Trans>
                  </Box>
                  <Link to="/login">
                    <Button className="fanclub-login-wrapper-button">
                      {t('profile.fanclub.login.login')}
                    </Button>
                  </Link>
                </Box>
              </Box>
            ) : (
              <>
                <SkeletonLoading className="col-span-12 xl:col-span-8 h-24" />
                <SkeletonLoading className="col-span-12 xl:col-span-4 h-24" />
              </>
            )}
          </>
        )}
      </Grid>
    </Container>
  );
}

export default FanClub;
