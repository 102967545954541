/* eslint-disable */

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

import { useLocale } from '@libs/contexts/locale';
import useToast from '@libs/utils/toast';
import { EXCHANGE_INTENTS } from '@libs/utils/exchange';
import {
  Modal,
  Heading,
  CollectibleCard,
  Text,
  Price,
  UserLink,
  Grid,
  Box,
  Col,
  Button,
  Link,
  Separator
} from '@components';

import { acceptOfferGql, cancelOfferGql } from '@components/offer-modal/patrons/utils';
import '@components/offer-modal/fansinc/style.css';
import handleGraphQLErrors from '@libs/utils/errors/graphql';

const { ACCEPT_OFFER, CANCEL_OFFER, REJECT_OFFER } = EXCHANGE_INTENTS;

const SummaryWrapper = ({ title, children }) => (
  <Box className="accept-offer-modal__summary__wrapper">
    <Grid column="2">
      <Col>
        <Text className="accept-offer-modal__summary__title">{title}</Text>
      </Col>
      <Col>
        <Box className="accept-offer-modal__summary__content">{children}</Box>
      </Col>
    </Grid>
  </Box>
);

function OfferModal({ exchangeOffer, exchangeIntent, isOpen, setIsOpen, onFinish }) {
  const { t, i18n } = useTranslation();
  const { currencyCode, calculateFees, fees: defaultFee } = useLocale();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const {
    id: exchangeID,
    release,
    patron,
    currentOwner,
    offerPrice,
    creator,
    paymentProvider
  } = exchangeOffer;
  const { fee: fees } = creator;

  const modalTranslationKey = {
    [ACCEPT_OFFER]: 'components.offerModal.acceptOffer',
    [CANCEL_OFFER]: 'components.offerModal.retractOffer',
    [REJECT_OFFER]: 'components.offerModal.rejectOffer'
  };

  const userToFrom = {
    [ACCEPT_OFFER]: patron,
    [CANCEL_OFFER]: currentOwner,
    [REJECT_OFFER]: patron
  };

  const onCloseModal = () => {
    if (!isLoading) {
      setIsOpen(false);
    }
  };

  const onConfirmationButtonClick = async () => {
    setIsLoading(true);
    try {
      switch (exchangeIntent) {
        case ACCEPT_OFFER:
          await acceptOfferGql(exchangeID, paymentProvider);
          onFinish();
          break;

        case CANCEL_OFFER:
        case REJECT_OFFER:
          await cancelOfferGql(exchangeID);
          onFinish();
          break;

        default:
          break;
      }
    } catch (error) {
      const handledError = handleGraphQLErrors(error);
      const errorMessage = (
        <>
          Couldn't process offer:
          <div>{handledError?.messages[0] ?? 'Unknown Error'}</div>
        </>
      );
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsOpen(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseModal}
      showCloseIcon
      heading={t(`${modalTranslationKey[exchangeIntent]}.title`)}
    >
      {release && (
        <CollectibleCard
          collectible={release}
          type="release"
          hasCollectibleTimer={false}
          showOwnerInUrl={true}
        />
      )}
      <Separator />
      <SummaryWrapper title={t(`${modalTranslationKey[exchangeIntent]}.summary.offerAmount`)}>
        <Text className="accept-offer-modal__summary__content__price">
          <Price amount={offerPrice} showCurrency currencyTextColor="grey" inline />
        </Text>
      </SummaryWrapper>
      <Separator />
      <SummaryWrapper title={t(`${modalTranslationKey[exchangeIntent]}.summary.userDetails`)}>
        <Box className="accept-offer-modal__summary__user">
          <UserLink user={userToFrom[exchangeIntent]} key="user-link-patron" />
        </Box>
      </SummaryWrapper>
      <Separator />
      {(exchangeIntent === ACCEPT_OFFER || exchangeIntent === REJECT_OFFER) && (
        <>
          <SummaryWrapper
            title={t(`${modalTranslationKey[exchangeIntent]}.summary.creatorsRoyaltyFee`)}
          >
            <Text as={'span'}>{defaultFee.CREATORS_ROYALTY_FEE_PERCENTAGE * 100}%</Text>
          </SummaryWrapper>
          <Separator />
          <SummaryWrapper title={t(`${modalTranslationKey[exchangeIntent]}.summary.totalReceived`)}>
            <Text className="accept-offer-modal__summary__content__price">
              <Price
                amount={offerPrice - calculateFees(offerPrice, fees?.salesFee)?.royaltyFee}
                showCurrency
                currencyTextColor="grey"
                inline
              />
            </Text>
          </SummaryWrapper>
          <Separator />
        </>
      )}
      <Box className="accept-offer-modal__buttons">
        <Button
          fullWidth
          onClick={onConfirmationButtonClick}
          loading={isLoading}
          disabled={isLoading}
          whiteOutline={
            (exchangeIntent === CANCEL_OFFER || exchangeIntent === REJECT_OFFER) && !isLoading
          }
        >
          {t(`${modalTranslationKey[exchangeIntent]}.buttons.confirm`)}
        </Button>
        <Box className="w-3"></Box>
        <Button
          fullWidth
          onClick={() => setIsOpen(false)}
          disabled={isLoading}
          whiteOutline={exchangeIntent === ACCEPT_OFFER}
        >
          {t(`${modalTranslationKey[exchangeIntent]}.buttons.cancel`)}
        </Button>
      </Box>
      {i18n.exists(`${modalTranslationKey[exchangeIntent]}.helperText`) && (
        <Box className="accept-offer-modal__helper-text">
          <Text as="span">{t(`${modalTranslationKey[exchangeIntent]}.helperText`)}</Text>
        </Box>
      )}
    </Modal>
  );
}

OfferModal.propTypes = {};

export default OfferModal;
