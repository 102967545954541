import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function RouteCheck() {
  const { pathname } = useLocation();

  useEffect(() => {
    const path = pathname.split('/');
    const body = document.querySelector('body');
    const html = document.querySelector('html');
    if (path) {
      if (path[1] === 'confirmation' || path[1] === 'congratulation') {
        if (process.env.REACT_APP_NAME !== 'patrons') {
          body?.classList?.add('page-confirmation');
        }
      } else {
        if (process.env.REACT_APP_NAME !== 'patrons') {
          body?.classList?.remove('page-confirmation');
        }
      }
      if (path[1] !== '/' && path[1] !== '/tonymott') {
        html?.classList?.remove('has-scroll-init');
        html?.classList?.remove('has-scroll-smooth');
      }
    }
  }, [pathname]);

  return null;
}
