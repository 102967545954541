import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Box, Container, Link, Logo } from '@components';

function HeaderLanding() {
  const { t } = useTranslation();
  const location = useLocation();
  const isIndex = location.pathname === '/';

  return (
    <header className="header-landing">
      <Container>
        <Box className="header-landing-row">
          <Box className="header-landing-col">
            <Link to="/">
              <Logo className="header-landing-logo" />
            </Link>
          </Box>
          <Box className="header-landing-col header-landing-menu">
            <ul>
              <li>
                <a href={isIndex ? '#upcomingRelease' : '/#upcomingRelease'} data-scroll-to>
                  {t('components.headerLanding.menu.upcomingReleases')}
                </a>
              </li>
              <li>
                <a href={isIndex ? '#forCreator' : '/#forCreator'} data-scroll-to>
                  {t('components.headerLanding.menu.forCreators')}
                </a>
              </li>
              <li>
                <a href={isIndex ? '#forFans' : '/#forFans'} data-scroll-to>
                  {t('components.headerLanding.menu.forFans')}
                </a>
              </li>
              <li>
                <a href={isIndex ? '#features' : '/#features'} data-scroll-to>
                  {t('components.headerLanding.menu.features')}
                </a>
              </li>
            </ul>
          </Box>
        </Box>
      </Container>
    </header>
  );
}

export default HeaderLanding;
