import { Trans } from 'react-i18next';

import { Link } from '@components';
import { CATEGORY_TYPES } from '@libs/utils/collectible';
import { TIER_TYPES } from '@libs/utils/release';
import { LISTING_TYPES, EXCHANGE_INTENTS } from '@libs/utils/exchange';

export const listingTypeToDefaultExchangeIntent = {
  [LISTING_TYPES.FIXED_PRICE]: EXCHANGE_INTENTS.FIXED_PRICE_PURCHASE,
  [LISTING_TYPES.MAKE_AN_OFFER]: EXCHANGE_INTENTS.MAKE_OFFER,
  [LISTING_TYPES.AUCTION]: EXCHANGE_INTENTS.PLACE_BID
};

export const termsLabel = (t, user, price) => ({
  [EXCHANGE_INTENTS.FIXED_PRICE_PURCHASE]: (
    <Trans key="fixedPricePurchase" i18nKey="checkout.checkoutTerms.fixedPrice">
      <Link target="_blank" to="https://www.fans.inc"></Link>
      <Link target="_blank" to="/terms-and-conditions"></Link>
    </Trans>
  ),
  [EXCHANGE_INTENTS.MAKE_OFFER]: (
    <Trans key="makeOffer" i18nKey="checkout.checkoutTerms.makeOffer" values={{ ...user, price }}>
      <Link target="_blank" to="https://www.fans.inc"></Link>
      <Link target="_blank" to="/terms-and-conditions"></Link>
      {user.firstName ? <span /> : <div className="inline-block w-8 bg-gray-500 h-2 mx-1" />}
      {user.lastName ? <span /> : <div className="inline-block w-8 bg-gray-500 h-2 mx-1" />}
      {user.email ? <span /> : <div className="inline-block w-12 bg-gray-500 h-2 mx-1" />}
    </Trans>
  ),
  [EXCHANGE_INTENTS.PLACE_BID]: (
    <Trans key="placeBid" i18nKey="checkout.checkoutTerms.auction" values={{ ...user, price }}>
      <Link target="_blank" to="https://www.fans.inc"></Link>
      <Link target="_blank" to="/terms-and-conditions"></Link>
      {user.firstName ? <span /> : <div className="inline-block w-8 bg-gray-500 h-2 mx-1" />}
      {user.lastName ? <span /> : <div className="inline-block w-8 bg-gray-500 h-2 mx-1" />}
      {user.email ? <span /> : <div className="inline-block w-12 bg-gray-500 h-2 mx-1" />}
    </Trans>
  )
});

export const summaryTitle = (t) => ({
  [EXCHANGE_INTENTS.FIXED_PRICE_PURCHASE]: t('checkout.orderSummary'),
  [EXCHANGE_INTENTS.MAKE_OFFER]: t('checkout.offerSummary'),
  [EXCHANGE_INTENTS.PLACE_BID]: t('checkout.bidSummary')
});

export const buttonText = (t) => ({
  [EXCHANGE_INTENTS.FIXED_PRICE_PURCHASE]: t('checkout.submit.checkout'),
  [EXCHANGE_INTENTS.MAKE_OFFER]: t('checkout.submit.makeOffer'),
  [EXCHANGE_INTENTS.PLACE_BID]: t('checkout.submit.placeBid')
});

export const getCollectibleDetailsFromExchange = (exchange) => {
  const {
    tierType,
    collectible: { category },
    collectible
  } = exchange;
  if (
    tierType === TIER_TYPES.DIGITAL_ORIGINAL &&
    collectible?.whatsIncludedOriginal !== null &&
    collectible?.whatsIncludedOriginal !== ''
  ) {
    return [
      <div
        key="digitalOriginal"
        dangerouslySetInnerHTML={{ __html: collectible?.whatsIncludedOriginal }}
      />
    ];
  }
  if (
    tierType === TIER_TYPES.NUMBERED_EDITION &&
    collectible?.whatsIncludedNumbered !== null &&
    collectible?.whatsIncludedNumbered !== ''
  ) {
    return [
      <div
        key="numberedEdition"
        dangerouslySetInnerHTML={{ __html: collectible?.whatsIncludedNumbered }}
      />
    ];
  }
  switch (category) {
    case CATEGORY_TYPES.ARTWORK: {
      return [
        <ul key="artWork" className="list-disc">
          <li>
            <Trans i18nKey="checkout.collectibleDetails.category.artwork.first" />
          </li>
          <li>
            <Trans i18nKey="checkout.collectibleDetails.category.artwork.second" />
          </li>
          {tierType === TIER_TYPES.DIGITAL_ORIGINAL ? (
            <li>
              <Trans i18nKey="checkout.collectibleDetails.category.artwork.third" />
            </li>
          ) : null}
        </ul>
      ];
    }
    case CATEGORY_TYPES.PHOTOGRAPH: {
      return [
        <ul key="photograph" className="list-disc">
          <li>
            <Trans i18nKey="checkout.collectibleDetails.category.photograph.first" />
          </li>
          <li>
            <Trans i18nKey="checkout.collectibleDetails.category.photograph.second" />
          </li>
          {tierType === TIER_TYPES.DIGITAL_ORIGINAL ? (
            <li>
              <Trans i18nKey="checkout.collectibleDetails.category.photograph.third" />
            </li>
          ) : null}
        </ul>
      ];
    }
    case CATEGORY_TYPES.SONG: {
      return [
        <ul key="song" className="list-disc">
          <li>
            <Trans i18nKey="checkout.collectibleDetails.category.song.first" />
          </li>
          <li>
            <Trans i18nKey="checkout.collectibleDetails.category.song.second" />
          </li>
          {tierType === TIER_TYPES.DIGITAL_ORIGINAL ? (
            <li>
              <Trans i18nKey="checkout.collectibleDetails.category.song.third" />
            </li>
          ) : null}
        </ul>
      ];
    }
    case CATEGORY_TYPES.SONG_LYRICS: {
      return [
        <ul key="songLyrics" className="list-disc">
          <li>
            <Trans i18nKey="checkout.collectibleDetails.category.songLyrics.first" />
          </li>
          <li>
            <Trans i18nKey="checkout.collectibleDetails.category.songLyrics.second" />
          </li>
          {tierType === TIER_TYPES.DIGITAL_ORIGINAL ? (
            <li>
              <Trans i18nKey="checkout.collectibleDetails.category.songLyrics.third" />
            </li>
          ) : null}
        </ul>
      ];
    }
    case CATEGORY_TYPES.VIDEO: {
      return [
        <ul key="video" className="list-disc">
          <li>
            <Trans i18nKey="checkout.collectibleDetails.category.video.first" />
          </li>
          <li>
            <Trans i18nKey="checkout.collectibleDetails.category.video.second" />
          </li>
          {tierType === TIER_TYPES.DIGITAL_ORIGINAL ? (
            <li>
              <Trans i18nKey="checkout.collectibleDetails.category.video.third" />
            </li>
          ) : null}
        </ul>
      ];
    }
    default:
      return [
        <ul key="default-list" className="list-disc">
          <li>
            <Trans i18nKey="checkout.collectibleDetails.category.other.first" />
          </li>
          <li>
            <Trans i18nKey="checkout.collectibleDetails.category.other.second" />
          </li>
          {tierType === TIER_TYPES.DIGITAL_ORIGINAL ? (
            <li>
              <Trans i18nKey="checkout.collectibleDetails.category.other.third" />
            </li>
          ) : null}
        </ul>
      ];
  }
};
