import { Trans } from 'react-i18next';
import { Link } from '@components';

const CollectibleMintedNotification = ({ notification, doer }) => {
  const collectible = notification?.event?.collectible;
  const exchange = notification?.event?.exchange;
  const creator = notification?.event?.creatorUser;
  return (
    <>
      <Link
        style={{ textDecoration: 'none' }}
        to={`/${creator.username}/${exchange.slug}/${exchange.serialIndex}`}
      >
        <div className="notification-menu-event-description">
          <Trans i18nKey={`notifications.${notification?.type}`}>
            {{ collectibleName: collectible.title }}
            {{ tier: doer.tier }}
          </Trans>
        </div>
      </Link>
    </>
  );
};

CollectibleMintedNotification.propTypes = {};

export default CollectibleMintedNotification;
