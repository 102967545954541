import { userPublicFragment, creatorPublicFragment } from '@libs/fragments/user';

const exchangeBaseQuery = /* GraphQL */ `
  {
    id
    exchangeType
    owner
    creatorID
    currentOwnerID
    currentOwner {
      ...CreatorPublicProfile
    }
    collectibleID
    serialIndex
    listingType
    startingPrice
    sellingPrice
    auctionMinimumAllowedBidPrice
    offerPrice
    bidPrice
    expiryDate
    isSold
    isReleased
    patronID
    paymentReferenceID
    checkoutReceiptID
    createdAt
    updatedAt
    slug
    tierType
    releaseCount
    releaseDate
    userID
    previousPrice
    currentPrice
    currentListingType
    fiatCurrencyCode
    nftOwnerAddress
    nftContractAddress
    nftTokenIDString
    nftERC20PaymentTokenAddress
    nftERC20PaymentTokenSymbol
    nftERC20PaymentTokenDecimals
    nftERC20PaymentAmount
    searchableHighestPrice
    status
    lastPurchasedAt
    collectible {
      id
      accountID
      userID
      type
      category
      title
      slug
      description
      assetUrl
      mediaThumbnail
      maxNumberedEditions
      featured
      createdAt
      updatedAt
      owner
      perksOriginal {
        fanClubAccess
        limitedPerks
        lifetimePerks
      }
      perksNumbered {
        fanClubAccess
        limitedPerks
        lifetimePerks
      }
      streamExternal
      streamUrl {
        spotify
        appleMusic
        youtube
      }
      creator {
        ...UserPublicProfile
      }
    }
  }

  ${userPublicFragment}
  ${creatorPublicFragment}
`;

export default exchangeBaseQuery;
