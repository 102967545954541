import { useState, useCallback } from 'react';
import cx from 'classnames';
import { useOutsider } from '@libs/utils/outsider';
import { ReactComponent as ArrowDownIcon } from '@assets/svg/arrow-down.svg';
import {
  Box,
  Container,
  Separator,
  ArtistCard,
  Button,
  Text,
  Input,
  Avatar,
  Link,
  SkeletonLoading,
  EmptyBox,
  ResponsiveModal,
  CustomDropdown
} from '@components';
import { ReactComponent as Search } from '@assets/svg/search-artists.svg';
import { useTranslation, Trans } from 'react-i18next';
import { ReactComponent as FilterIcon } from '@assets/svg/patrons/sliders-horizontal.svg';
import {
  useFilterCollectors,
  useUserByDisplayName,
  useCollectorsFilterName
} from '@libs/hooks/user';
import { genres } from '@libs/utils/options';
import debounce from 'lodash.debounce';
import CollectorCard from '@pages/landing/patrons/components/collector';

function Collectors() {
  const { t } = useTranslation();
  const [colsActive, setColsActive] = useState(3);
  const [openModal, setOpenModal] = useState(false);
  const {
    data,
    loading,
    total,
    onPaginate,
    nextPageToken,
    onSortBy,
    handleChangeName,
    onHandleReset
  } = useFilterCollectors(12);
  const onNextPage = () => {
    onPaginate(nextPageToken);
  };

  const { data: listFilter, getData, loading: displayNameLoading } = useCollectorsFilterName();
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);
  const { ref } = useOutsider(handleClose);
  const [nameFilter, setNameFilter] = useState('');

  const handleSearch = async (data) => {
    getData(data);
  };

  const onFilterName = (name) => {
    handleChangeName(name);
    setIsOpen(false);
  };

  //SORTING
  const [isOpenSorting, setOpenSorting] = useState(false);
  const handleCloseSort = () => setOpenSorting(false);
  const { ref: refSort } = useOutsider(handleCloseSort);
  const [sort, setSort] = useState('TITLE_ASC');
  const handleOpenSorting = useCallback(
    (e) => {
      e.preventDefault();
      setOpenSorting(!isOpenSorting);
    },
    [isOpenSorting]
  );

  const getSortLabel = () => {
    const filter = listSort.filter((list) => {
      return list.value === sort;
    });
    return filter[0].label;
  };

  const listSort = [
    {
      label: 'Name (A - Z)',
      value: 'TITLE_ASC'
    },
    {
      label: 'Name (Z - A)',
      value: 'TITLE_DESC'
    },
    {
      label: 'Artworks Purchased (0-9)',
      value: 'LOWEST_PURCHASE'
    },
    {
      label: 'Artworks Purchased (9-0)',
      value: 'HIGHEST_PURCHASE'
    },
    {
      label: 'Collection Value (0-9)',
      value: 'LOWEST_VALUE'
    },
    {
      label: 'Collection Value (9-0)',
      value: 'HIGHEST_VALUE'
    }
  ];
  const handleSortBy = (data) => {
    setSort(data);
    onSortBy(data);
  };

  const resetFilter = () => {
    setSort('CREATED_AT_DESC');
    onHandleReset();
  };

  const AvatarComponent = ({ user }) => {
    return (
      <Link to={`/${user?.username}`}>
        <Box className="artists-card-filter__profile">
          <Box className="artists-card-filter__profile__avatar">
            <Avatar user={user} size="xs-40" />
          </Box>
          <Box className="artists-card-filter__profile__name">
            {user?.firstName + ' ' + user?.lastName}
          </Box>
        </Box>
      </Link>
    );
  };

  const NameComponent = ({ name, filter, onChangeName }) => {
    const displayName = name.toUpperCase().split(filter.toUpperCase());
    const onFilterNameChange = () => {
      document.getElementById('input-filter').value = name;
    };
    return (
      <Box
        onClick={() => {
          onFilterNameChange();
          onChangeName(name);
        }}
        className="filter-displayname__top capitalize"
      >
        {displayName?.map((x, index) => {
          if (index === displayName.length - 1) {
            return <span>{x.toLowerCase()}</span>;
          }
          return (
            <>
              <span>{x.toLowerCase()}</span>
              <span className="text-primary">{filter.toLowerCase()}</span>
            </>
          );
        })}
      </Box>
    );
  };
  return (
    <Box className="collectors-page">
      <Container>
        <Box className="collectors-page__body">
          <Box className="collectors-page__body__title">{t('p.pages.collectors.title')}</Box>
          <Box className="collectors-page__body__filter">
            <Box ref={ref} className="collectors-page__body__filter__input">
              <Search />
              <Box className="marketplace-page__body__filter__input__wrapper">
                <Input
                  id="input-filter"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleChangeName(e.target.value);
                      setIsOpen(false);
                    }
                  }}
                  onChange={debounce((e) => {
                    if (e.target.value) {
                      setIsOpen(true);
                      handleSearch(e.target.value);
                      setNameFilter(e.target.value);
                    } else {
                      setNameFilter(e.target.value);
                      setIsOpen(false);
                    }
                  }, 1000)}
                  placeholder={t('p.pages.collectors.search')}
                />
              </Box>
              {isOpen && listFilter?.length > 0 && (
                <>
                  <Box className="collectors-page__body__filter__input__dropdown">
                    <Box>
                      {listFilter?.map((user) => (
                        <NameComponent
                          key={user?.id}
                          name={user?.firstName + ' ' + user?.lastName}
                          onChangeName={onFilterName}
                          setNameFilter={setNameFilter}
                          filter={nameFilter}
                        />
                      ))}
                      <Box className="collectors-page__body__filter__input__dropdown__collectors">
                        {t('p.pages.collectors.title')}
                      </Box>
                      {listFilter?.map((user) => (
                        <AvatarComponent key={user?.id} user={user} />
                      ))}
                    </Box>
                  </Box>
                </>
              )}
            </Box>
            <Box className="marketplace-page_search">
              <Box className="marketplace-page__search-sorting">
                <Text
                  className="marketplace-page__search-sorting-header"
                  onClick={handleOpenSorting}
                >
                  <Text as="span" className="marketplace-page__search-sorting-header__type">
                    {t('p.pages.artists.sort')}
                  </Text>
                  <Text as="span" className="marketplace-page__search-sorting-header__item">
                    {getSortLabel()}
                    <ArrowDownIcon />
                  </Text>
                </Text>
                {isOpenSorting && (
                  <Box className="select-multiple__dropdown overflow-hidden" ref={refSort}>
                    <ul>
                      {listSort &&
                        listSort.map((o) => (
                          <>
                            <li
                              className={cx(sort === o.value && 'active')}
                              key={o.value}
                              value={o.value}
                              onClick={() => {
                                setSort(o.value);
                                handleSortBy(o.value);
                                handleCloseSort();
                              }}
                            >
                              {o.label}
                            </li>
                            <Separator />
                          </>
                        ))}
                    </ul>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Separator className="mt-4 artist-page-separator" />
          <Box className="marketplace-page__body__result">
            <Box className="marketplace-page__body__result__count">
              {total === 1 ? (
                <Trans i18nKey="p.pages.marketplace.result">{{ total: total || 0 }}</Trans>
              ) : (
                <Trans i18nKey="p.pages.marketplace.results">{{ total: total || 0 }}</Trans>
              )}
            </Box>
            <Box className="marketplace-page__body__result__show">
              <Box
                onClick={() => setColsActive(2)}
                className="grid grid-cols-2 artists-pointer cursor-pointer"
              >
                {[1, 2, 3, 4].map((x) => (
                  <Box
                    key={x}
                    className={cx(colsActive === 2 ? 'point-filter-active' : 'point-filter')}
                  ></Box>
                ))}
              </Box>
              <Box
                onClick={() => setColsActive(3)}
                className="grid grid-cols-3 artists-pointer cursor-pointer"
              >
                {[1, 2, 3, 4, 5, 6].map((x) => (
                  <Box
                    key={x}
                    className={cx(colsActive === 3 ? 'point-filter-active' : 'point-filter')}
                  ></Box>
                ))}
              </Box>
              <Box className="responsive-filter" onClick={() => setOpenModal(true)}>
                <FilterIcon />
              </Box>
            </Box>
          </Box>
          {data?.length > 0 ? (
            <Box className={cx('collectors-page__body__cards', `grid grid-cols-${colsActive}`)}>
              {data?.map((user) => (
                <CollectorCard key={user?.id} collector={user} />
              ))}
            </Box>
          ) : (
            <>
              {loading ? (
                <Box className={cx('collectors-page__body__cards', `grid grid-cols-${colsActive}`)}>
                  <>
                    {[...Array(colsActive * 2)].map((x, index) => (
                      <SkeletonLoading key={index} className=" h-96" />
                    ))}
                  </>
                </Box>
              ) : (
                <Box className="mt-12">
                  <EmptyBox title="No Artists Found" />
                </Box>
              )}
            </>
          )}
          {nextPageToken && (
            <Button loading={loading} onClick={() => onNextPage()}>
              {t('p.pages.artists.loadMore')}
            </Button>
          )}
        </Box>
        <ResponsiveModal isOpen={openModal} onClose={() => setOpenModal(false)}>
          <Box className="flex items-center justify-between mb-2">
            <Box className="text-lg font-bold">{t('p.pages.collectors.filter')}</Box>
            <Box
              onClick={() => resetFilter()}
              className="text-sm text-secondary underline font-medium"
            >
              {t('p.pages.marketplace.reset')}
            </Box>
          </Box>
          <CustomDropdown value={sort} list={listSort} setValue={handleSortBy} label="Sort" />
          <Button className="mt-5" onClick={() => setOpenModal(false)} fullWidth>
            {t('p.pages.marketplace.close')}
          </Button>
        </ResponsiveModal>
      </Container>
    </Box>
  );
}

export default Collectors;
