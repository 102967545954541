import { useState, useEffect } from 'react';
import useQuery from '@libs/utils/query';
import { useAuth } from '@libs/contexts/auth';

export const useRefreshSession = () => {
  const { refreshSession } = useAuth();
  const [didRefreshSession, setDidRefreshSession] = useState(false);
  useEffect(() => {
    if (!didRefreshSession) {
      (async () => refreshSession())();
      setDidRefreshSession(true);
    }
  }, [didRefreshSession, refreshSession]);
};

export const useConfirmationQuery = () => {
  const query = useQuery();
  let status,
    referenceID,
    exchangeIntent,
    orderID,
    imageUrl,
    color,
    checkoutSessionID = null;
  if (query) {
    status = query.get('status');
    referenceID = query.get('eid');
    orderID = query.get('oid');
    imageUrl = query.get('img');
    color = query.get('clr');
    exchangeIntent = query.get('eint');
    checkoutSessionID = query.get('cko-session-id');
  }
  return { status, referenceID, orderID, exchangeIntent, checkoutSessionID, imageUrl, color };
};
