import { Trans } from 'react-i18next';
import cx from 'classnames';
import { Link } from '@components';
import { NFTAddress } from '../..';

export const formatWalletAddress = (walletAddress) =>
  String(walletAddress).substring(0, 6) + '...' + String(walletAddress).substring(38);

export const connectWallet = async (t, className = '') => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: 'eth_requestAccounts'
      });

      const obj = {
        status: (
          <>
            <NFTAddress
              address={addressArray[0]}
              icon="metamask"
              iconSize="lg"
              className={cx(className)}
              showStatus
            />
          </>
        ),
        address: addressArray[0]
      };
      return obj;
    } catch (err) {
      return {
        address: '',
        status: err.message
      };
    }
  } else {
    return {
      address: '',
      status: (
        <Trans i18nKey="components.connectWalletButton.statusHelper.installMetamask">
          <Link to="https://metamask.io/download.html" target="_blank" />
        </Trans>
      )
    };
  }
};

export const getCurrentWalletConnected = async (t, className = '') => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: 'eth_accounts'
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: (
            <NFTAddress
              address={addressArray[0]}
              icon="metamask"
              iconSize="lg"
              className={cx(className)}
              showStatus
            />
          )
        };
      } else {
        return {
          address: '',
          status: t('components.connectWalletButton.statusHelper.needsConnection')
        };
      }
    } catch (err) {
      return {
        address: '',
        status: err.message
      };
    }
  } else {
    return {
      address: '',
      status: (
        <Trans i18nKey="components.connectWalletButton.statusHelper.installMetamask">
          <Link to="https://metamask.io/download.html" target="_blank" />
        </Trans>
      )
    };
  }
};
