import FansincButtonRadio from './fansinc';
import PatronsButtonRadio from './patrons';

const ButtonRadio = ({
  options,
  className,
  selectedKey,
  setSelectedKey,
  flattenOptions = false,
  selected = 'default',
  unselected = 'whiteOutline',
  containerStyle = {},
  disabled,
  ...props
}) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return (
      <PatronsButtonRadio
        options={options}
        selectedKey={selectedKey}
        flattenOptions={flattenOptions}
        selected={selected}
        unselected={unselected}
        containerStyle={containerStyle}
        disabled={disabled}
        {...props}
      />
    );
  } else {
    return (
      <FansincButtonRadio
        options={options}
        className={className}
        selectedKey={selectedKey}
        flattenOptions={flattenOptions}
        selected={selected}
        unselected={unselected}
        containerStyle={containerStyle}
        disabled={disabled}
        {...props}
      />
    );
  }
};

export default ButtonRadio;
