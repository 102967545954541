import { PropTypes } from 'prop-types';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';

import { Box, Button, Container, Heading, Text } from '@components';
import { getFadeClass } from '@components/hero/utils';

import '@components/hero/style.css';

const Hero = ({
  heading,
  text,
  children,
  showBack,
  size,
  fadeLeft,
  fadeRight,
  fadeBottom,
  ...props
}) => {
  const { goBack } = useHistory();

  return (
    <Box
      className={cx('hero', getFadeClass({ className: 'hero', fadeLeft, fadeRight, fadeBottom }))}
      {...props}
    >
      {showBack && (
        <Box className="hero__back">
          <Container size="xl" className="print-collectible-page__back__container">
            <Button
              whiteOutline
              onClick={() => {
                goBack();
              }}
            >
              Back
            </Button>
          </Container>
        </Box>
      )}
      <Container className="hero__container" size={size}>
        <Heading as="h1" className="hero__container__heading">
          {heading}
        </Heading>
        {heading && text && <div className="h-5" />}
        <Text as="p" className="hero__container__text">
          {text}
        </Text>
        {(heading || text) && children && <div style={{ height: '1.25rem' }} />}
        <Box>{children}</Box>
      </Container>
    </Box>
  );
};

Hero.propTypes = {
  heading: PropTypes.string,
  showBack: PropTypes.bool,
  fadeLeft: PropTypes.bool,
  fadeRight: PropTypes.bool,
  fadeBottom: PropTypes.bool
};

Hero.defaultProps = {
  size: 'md',
  fadeLeft: false,
  fadeRight: false,
  fadeBottom: false
};

export default Hero;
