import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Container } from '@components';
import ReleaseSold from './components/sold';

function SoldCollectibles({ profile }) {
  const { t } = useTranslation();

  return (
    <>
      <Container size="xl" className="profile__container">
        <ReleaseSold userID={profile?.id} />
      </Container>
      <Helmet>
        <title>{t('profile.collectibles.title')}</title>
        <link rel="canonical" href={'https://fans.inc' + window.location.pathname} />
        <meta name="URL" content={'https://fans.inc' + window.location.pathname} />
      </Helmet>
    </>
  );
}

export default SoldCollectibles;
