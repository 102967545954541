import React from 'react';

import { Heading } from '@components';

function LandingGallerySectionHeading({ heading, ...props }) {
  return (
    <Heading as="h3" className="landing-gallery__section__content__title" {...props}>
      {heading}
    </Heading>
  );
}

LandingGallerySectionHeading.propTypes = {};

export default LandingGallerySectionHeading;
