import ReactPlayer from 'react-player/lazy';
import cx from 'classnames';
import { useContext, forwardRef } from 'react';
import { PlayerContext } from '@libs/contexts/player';

const Player = forwardRef(function PlayerPlain(
  {
    id,
    url,
    className,
    config,
    played,
    light,
    playIcon,
    setPlayed,
    onProgress,
    onDuration,
    onEnded,
    volume,
    muted,
    onBuffer,
    onBufferEnd,
    width,
    height,
    playsinline,
    onError,
    ...rest
  },
  ref
) {
  const { isPlaying, play } = useContext(PlayerContext);
  return (
    <ReactPlayer
      className={cx(className)}
      playsinline={playsinline}
      ref={ref}
      config={config}
      playing={isPlaying(id)}
      url={url}
      width={width ? width : played ? 0 : '100%'}
      height={height ? height : played ? 0 : '100%'}
      light={light}
      playIcon={playIcon}
      onClickPreview={(e) => {
        play(id);
        setPlayed(true);
        e.preventDefault();
      }}
      onProgress={onProgress}
      onDuration={onDuration}
      onEnded={onEnded}
      volume={volume}
      muted={muted}
      onBuffer={onBuffer}
      onBufferEnd={onBufferEnd}
      onError={onError && onError}
    />
  );
});

export default Player;
