import { useTranslation, Trans } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Recaptcha from 'react-recaptcha';
import debounce from 'lodash.debounce';
import React, { useState, useCallback, useEffect } from 'react';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Col,
  FormField,
  Grid,
  Input,
  Text,
  Heading,
  Modal,
  CollectibleCard,
  CollectibleOwnerActions
} from '@components';
import { ReactComponent as GoldChecked } from '@assets/svg/gold-checked.svg';
import { ReactComponent as OwnIt } from '@assets/svg/own-it.svg';
import { useUser } from '@libs/hooks/user';
import { useAuth } from '@libs/contexts/auth';
import { useCollectible } from '@libs/hooks/collectible';
import { useCollectibleOwnership } from '@libs/hooks/exchange';
import config from '@/config';
import { useExchangesForUnitPage } from '@pages/release/fansinc/hooks';
import { urlParser } from '@pages/profile/utils';

const ClaimCollectibleCard = ({ collectible, refetch }) => {
  const { t } = useTranslation();
  const [modalVideo, setModalVideo] = useState(false);
  const {
    exchange,
    onClaim: onClaimCollectible,
    claimLoading,
    claimSuccess
  } = useCollectible(collectible?.slug);
  const {
    authenticate,
    user,
    isAuthenticated,
    error: errorAuth,
    loading: authLoading,
    getCurrentUser
  } = useAuth();
  const {
    register,
    watch,
    formState: { errors },
    resetField,
    trigger,
    setFocus
  } = useForm({
    mode: 'onBlur',
    shouldFocusError: false
  });
  const {
    onCheckEmail,
    emailAvailability,
    availabilityLoading,
    signUp,
    loading: signUpLoading,
    error: errorSignup
  } = useUser();
  const [authProcess, setAuthProcess] = useState(false);
  const [isVerified, setIsVerified] = useState();
  const [captchaToken, setCaptchaToken] = useState();
  const firstName = watch('firstName');
  const lastName = watch('lastName');
  const email = watch('email');
  const password = watch('password');
  const exchangesForUnitPage = useExchangesForUnitPage({
    slug: collectible?.slug,
    serialIndex: exchange?.serialIndex
  });
  const { currentExchange, isLoadingCurrentExchange: isLoadingExchange } = exchangesForUnitPage;
  const url = urlParser(currentExchange, 'exchange');
  const { onChange: onEmailChange, ...rest } = register('email', {
    required: {
      value: true,
      message: t('components.claimCollectibleCard.fields.email.required')
    },
    pattern: {
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: t('components.claimCollectibleCard.fields.email.invalid')
    }
  });

  const handleEmailChange = useCallback(
    debounce((e) => {
      onEmailChange(e);
      onCheckEmail(e.target.value);
      trigger('password');
    }, 1000),
    []
  );

  const validateFormLogin = useCallback(async () => {
    if (emailAvailability) {
      trigger('email');
      trigger('password');
    }
    for (const error in errors) {
      if (['email', 'password'].includes(error)) {
        setFocus(error);
        return;
      }
    }
    await authenticate({ email, password });
    setAuthProcess(true);
  }, [email, password]);

  const validateFormSignup = useCallback(async () => {
    trigger('email');
    trigger('firstName');
    trigger('lastName');
    trigger('password');
    for (const error in errors) {
      if (['email', 'firstName', 'lastName', 'password'].includes(error)) {
        setFocus(error);
        return;
      }
    }
    if (isVerified) {
      await signUp({
        firstName: firstName,
        lastName: lastName,
        email: email,
        captchaToken: captchaToken,
        password: password,
        claimCollectible: collectible?.id
      });
      resetField('password');
    }
  }, [email, password, firstName, lastName, isVerified]);

  const handleClaimFreeCollectible = async () => {
    await onClaimCollectible({
      collectibleID: collectible.id
    });
  };

  const verifyCallback = async (response) => {
    if (response) {
      setCaptchaToken(response);
      setIsVerified(true);
    }
  };

  const { owned } = useCollectibleOwnership({
    collectibleID: collectible?.id,
    userID: user?.id
  });

  useEffect(async () => {
    if (authProcess) {
      getCurrentUser();
      if (user) {
        setAuthProcess(false);
        await handleClaimFreeCollectible();
      }
    }
  }, [user, authProcess]);

  useEffect(() => {
    if (claimSuccess) {
      setModalVideo(true);
    }
  }, [claimSuccess]);

  const handleCloseModal = async () => {
    setModalVideo(false);
    refetch && (await refetch());
  };

  return (
    <>
      <Box className="claim-collectible-card">
        {owned || claimSuccess ? (
          <>
            <GoldChecked className="claim-collectible-card__icon" />
            <Text className="claim-collectible-card__title text-center mb-2">
              {t('components.claimCollectibleCard.collectibleClaimed')}
            </Text>
            <Text className="claim-collectible-card__description text-center">
              <Trans i18nKey="components.claimCollectibleCard.congratulations">
                {collectible?.creator?.displayName ||
                  collectible?.creator?.firstName + ' ' + collectible?.creator?.lastName}
              </Trans>
            </Text>
          </>
        ) : (
          <>
            <Text className="claim-collectible-card__title">{collectible?.promoTitle}</Text>
            <Text className="claim-collectible-card__description">
              {collectible?.promoDescription}
            </Text>
            {user || isAuthenticated ? (
              <>
                <Box className="claim-collectible-card__user">
                  <Avatar
                    className="claim-collectible-card__user__avatar"
                    size="xs-40"
                    user={user}
                  />
                  <Box>
                    <Text className="claim-collectible-card__user__name">
                      {user?.firstName} {user?.lastName}
                    </Text>
                    <Text className="claim-collectible-card__user__link">
                      fans.inc/{user?.username}
                    </Text>
                  </Box>
                </Box>
                <Button
                  className="claim-collectible-card__button btn--lg"
                  loading={claimLoading}
                  onClick={() => handleClaimFreeCollectible()}
                >
                  {t('components.claimCollectibleCard.button.getYourSecretCollectible')}
                </Button>
              </>
            ) : (
              <>
                <FormField>
                  <Input
                    loading
                    as="email"
                    fullwidth
                    label={
                      <>
                        {t('components.claimCollectibleCard.fields.email.label')}{' '}
                        <span className="text-red-700">*</span>
                      </>
                    }
                    helpMessage={
                      availabilityLoading
                        ? t('components.claimCollectibleCard.fields.email.loading')
                        : ''
                    }
                    errorMessage={errors.email?.message}
                    {...rest}
                    onChange={handleEmailChange}
                    placeholder={t('components.claimCollectibleCard.fields.email.placeholder')}
                  />
                </FormField>
                {!emailAvailability ? (
                  <>
                    <Grid className="claim-collectible-card__column">
                      <Col>
                        <FormField>
                          <Input
                            as="text"
                            {...register('firstName', {
                              required: {
                                value: true,
                                message: t(
                                  'components.claimCollectibleCard.fields.firstName.required'
                                )
                              },
                              pattern: {
                                value: /^[a-z ,.'-]+$/i,
                                message: t(
                                  'components.claimCollectibleCard.fields.firstName.invalid'
                                )
                              },
                              minLength: {
                                value: 1,
                                message: t(
                                  'components.claimCollectibleCard.fields.firstName.minLength'
                                )
                              }
                            })}
                            errorMessage={errors.firstName?.message}
                            label={
                              <>
                                {t('components.claimCollectibleCard.fields.firstName.label')}{' '}
                                <span className="text-red-700">*</span>
                              </>
                            }
                            placeholder={t(
                              'components.claimCollectibleCard.fields.firstName.placeholder'
                            )}
                          />
                        </FormField>
                      </Col>
                      <Col>
                        <FormField>
                          <Input
                            as="text"
                            {...register('lastName', {
                              required: {
                                value: true,
                                message: t(
                                  'components.claimCollectibleCard.fields.lastName.required'
                                )
                              },
                              pattern: {
                                value: /^[a-z ,.'-]+$/i,
                                message: t(
                                  'components.claimCollectibleCard.fields.lastName.invalid'
                                )
                              },
                              minLength: {
                                value: 1,
                                message: t(
                                  'components.claimCollectibleCard.fields.lastName.minLength'
                                )
                              }
                            })}
                            errorMessage={errors.lastName?.message}
                            label={
                              <>
                                {t('components.claimCollectibleCard.fields.lastName.label')}{' '}
                                <span className="text-red-700">*</span>
                              </>
                            }
                            placeholder={t(
                              'components.claimCollectibleCard.fields.lastName.placeholder'
                            )}
                          />
                        </FormField>
                      </Col>
                    </Grid>
                    <FormField>
                      <Input
                        as="password"
                        fullwidth
                        label={
                          <>
                            {t('components.claimCollectibleCard.fields.password.label')}{' '}
                            <span className="text-red-700">*</span>
                          </>
                        }
                        errorMessage={errors.password?.message}
                        {...register('password', {
                          required: {
                            value: true,
                            message: t('components.claimCollectibleCard.fields.password.required')
                          },
                          pattern: {
                            value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                            message: t('components.claimCollectibleCard.fields.password.strength')
                          },
                          minLength: {
                            value: 8,
                            message: t('components.claimCollectibleCard.fields.password.minLength')
                          },
                          maxLength: {
                            value: 128,
                            message: t('components.claimCollectibleCard.fields.password.maxLength')
                          }
                        })}
                        placeholder={t(
                          'components.claimCollectibleCard.fields.password.placeholder'
                        )}
                      />
                    </FormField>
                    <Box className="mb-4">
                      <Box>
                        <Recaptcha
                          sitekey={config.cookie.TOKEN_SITE_KEY_CAPTCHA}
                          render="explicit"
                          theme="dark"
                          verifyCallback={verifyCallback}
                        />
                      </Box>
                    </Box>
                    {errorSignup && (
                      <Alert danger className="mb-4">
                        {errorSignup}
                      </Alert>
                    )}
                    <Button
                      loading={signUpLoading}
                      as="submit"
                      className="mr-3 claim-collectible-card__button"
                      onClick={validateFormSignup}
                    >
                      {t('components.claimCollectibleCard.button.getYourSecretCollectible')}
                    </Button>
                  </>
                ) : (
                  <>
                    <FormField>
                      <Input
                        as="password"
                        fullwidth
                        label={
                          <>
                            {t('components.claimCollectibleCard.fields.password.label')}{' '}
                            <span className="text-red-700">*</span>
                          </>
                        }
                        errorMessage={errors.password?.message}
                        {...register('password', {
                          required: {
                            value: true,
                            message: t('components.claimCollectibleCard.fields.password.required')
                          }
                        })}
                        placeholder={t(
                          'components.claimCollectibleCard.fields.password.placeholder'
                        )}
                      />
                    </FormField>
                    {errorAuth && (
                      <Alert danger className="mb-4">
                        {errorAuth}
                      </Alert>
                    )}
                    <Button
                      loading={authLoading}
                      as="submit"
                      className="mr-3 claim-collectible-card__button"
                      onClick={validateFormLogin}
                    >
                      {t('components.claimCollectibleCard.button.getYourSecretCollectible')}
                    </Button>
                  </>
                )}
              </>
            )}
          </>
        )}
      </Box>
      <Modal
        showCloseIcon
        size="lg"
        isOpen={modalVideo}
        customWrapperStyle="claim-collectible-card__modal"
        onClose={handleCloseModal}
      >
        <Grid className="claim-collectible-card__modal__grid">
          <Box>
            <CollectibleCard type="release" collectible={currentExchange} showButton={false} />
          </Box>
          <Box className="checkout__actions">
            <Heading className="claim-collectible-card__modal__title" as="h4">
              Congratulations {user?.firstName}
            </Heading>
            <Text className="claim-collectible-card__modal__description">
              Your’re the proud new owner of a limited edition #{currentExchange?.serialIndex} of{' '}
              {collectible?.title}
            </Text>
            <Link to={url}>
              <OwnIt className="claim-collectible-card__modal__own" />
            </Link>
            <CollectibleOwnerActions exchange={currentExchange} isCheckoutModal showDescription />
          </Box>
        </Grid>
      </Modal>
    </>
  );
};

export default ClaimCollectibleCard;
