import { useRef, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import useToast from '@libs/utils/toast';
import { useTranslation, Trans } from 'react-i18next';
import Recaptcha from 'react-recaptcha';
import {
  Alert,
  Button,
  FormField,
  Heading,
  Input,
  Link,
  SocialLogin,
  Text,
  Box
} from '@components';
import { useUser } from '@libs/hooks/user';
import config from '@/config';
function Signup({ showSocialLogin = true, loginUrl }) {
  const toast = useToast();
  const [isVerified, setIsVerified] = useState();
  const [captchaToken, setCaptchaToken] = useState();
  const verifyCallback = async (response) => {
    if (response) {
      setCaptchaToken(response);
      setIsVerified(true);
    }
  };
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    resetField
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur'
  });
  const { signUp, loading: formLoading, error } = useUser();
  const password = useRef({});
  password.current = watch('password', '');
  const onSubmit = useCallback(
    async (data) => {
      if (isVerified) {
        await signUp({
          firstName: data.firstName,
          lastName: data.lastName,
          username: data.username,
          email: data.email,
          captchaToken: captchaToken,
          password: data.confirm_password
        });
        resetField('password');
        resetField('confirm_password');
      } else {
        toast(t('auth.register.verify'), 'error');
      }
    },
    [isVerified]
  );
  const [firstNameLength, setFirstNameLength] = useState('0');
  const [lastNameLength, setLastNameLength] = useState('0');
  return (
    <Box className="auth">
      <Box className="auth__title">{t('p.auth.register.heading')}</Box>
      <Box className="auth__description">{t('p.auth.register.description')}</Box>
      {error && (
        <Alert danger className="mb-4 auth__error__whitelisted">
          {error}
        </Alert>
      )}
      {showSocialLogin && (
        <>
          <SocialLogin />
          <Box className="auth-or">
            <Box className="auth-or-line"></Box>
            <Text className="auth-or-text">{t('auth.OR')}</Text>
          </Box>
        </>
      )}
      <Box className="auth__form">
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Box className="auth__form__flex">
            <FormField>
              <Input
                fullWidth
                label={t('auth.fields.firstName.label')}
                placeholder={t('auth.fields.firstName.label')}
                errorMessage={errors.firstName?.message}
                {...register('firstName', {
                  required: { value: true, message: t('auth.fields.firstName.required') },
                  pattern: { value: /^[a-z ,.'-]+$/i, message: t('auth.fields.firstName.invalid') },
                  minLength: { value: 1, message: t('auth.fields.firstName.minLength') },
                  maxLength: {
                    value: 50,
                    message: t('auth.fields.firstName.maxLength', { length: firstNameLength })
                  }
                })}
                onChange={(e) => {
                  setFirstNameLength(e.target.value.length.toString());
                }}
              />
            </FormField>
            <FormField>
              <Input
                fullWidth
                label={t('auth.fields.lastName.label')}
                placeholder={t('auth.fields.lastName.label')}
                errorMessage={errors.lastName?.message}
                {...register('lastName', {
                  required: { value: true, message: t('auth.fields.lastName.required') },
                  pattern: { value: /^[a-z ,.'-]+$/i, message: t('auth.fields.lastName.invalid') },
                  minLength: { value: 1, message: t('auth.fields.lastName.minLength') },
                  maxLength: {
                    value: 50,
                    message: t('auth.fields.lastName.maxLength', { length: lastNameLength })
                  }
                })}
                onChange={(e) => {
                  setLastNameLength(e.target.value.length.toString());
                }}
              />
            </FormField>
          </Box>
          <FormField>
            <Input
              fullWidth
              label={t('auth.fields.email.label')}
              placeholder={t('auth.fields.email.label')}
              as="email"
              errorMessage={errors.email?.message}
              {...register('email', {
                required: { value: true, message: t('auth.fields.email.required') },
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: t('auth.fields.email.invalid')
                }
              })}
            />
          </FormField>
          <FormField>
            <Input
              fullWidth
              label={t('auth.fields.password.label')}
              placeholder={t('auth.fields.password.label')}
              as="password"
              errorMessage={errors.password?.message}
              {...register('password', {
                required: { value: true, message: t('auth.fields.password.required') },
                pattern: {
                  value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                  message: t('auth.fields.password.strength')
                },
                minLength: { value: 8, message: t('auth.fields.password.minLength') },
                maxLength: { value: 128, message: t('auth.fields.password.maxLength') }
              })}
            />
            <Text className="auth__form__password-requirement">
              {t('auth.fields.password.requirements')}
            </Text>
          </FormField>
          <FormField>
            <Input
              fullWidth
              label={t('auth.fields.confirmPassword.label')}
              placeholder={t('auth.fields.confirmPassword.label')}
              as="password"
              errorMessage={errors.confirm_password?.message}
              {...register('confirm_password', {
                required: { value: true, message: t('auth.fields.confirmPassword.required') },
                validate: (value) =>
                  value === password.current || t('auth.fields.confirmPassword.match')
              })}
            />
          </FormField>
          <Box className="auth__register__policy">
            <Text>
              <Trans i18nKey="p.auth.register.policy">
                <span className="auth__register__policy__bold"></span>
                <br />
                <Link target="_blank" to="/terms-and-conditions"></Link>
                <Link target="_blank" to="/privacy-policy"></Link>
              </Trans>
            </Text>
          </Box>
          <Box className="auth__recaptcha">
            <Box className="auth__recaptcha__body">
              <Recaptcha
                sitekey={config.cookie.TOKEN_SITE_KEY_CAPTCHA}
                render="explicit"
                verifyCallback={verifyCallback}
              />
            </Box>
          </Box>
          <Box className="mb-2">
            <Button
              id="signup-button"
              as="submit"
              primary
              fullWidth
              disabled={formLoading}
              loading={formLoading}
            >
              <span style={{ textTransform: 'initial' }}>{t('p.auth.register.createAccount')}</span>
            </Button>
          </Box>
          <div className="auth__register">
            <Text center className="auth__forgot__backLogin">
              <Trans i18nKey="p.auth.register.singin">
                <Link className="auth__forgot__backLogin__link" to="/login"></Link>
              </Trans>
            </Text>
          </div>
        </form>
      </Box>
    </Box>
  );
}
export default Signup;
