import useImage from 'use-image';

export const GetImage = (src) => {
  return useImage(src, 'Anonymous');
};

export const download = (uri, name) => {
  var link = document.createElement('a');
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const imageSize = (image, width, height) => {
  let imageWidth = image?.width;
  let imageHeight = image?.height;

  do {
    imageHeight = imageHeight / 1.01;
    imageWidth = imageWidth / 1.01;
  } while (imageHeight > height + 4 && imageWidth > width + 4);

  return {
    width: imageWidth,
    height: imageHeight
  };
};
