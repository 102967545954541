import React from 'react';
import { Page, Text, View, Document, Image, Font } from '@react-pdf/renderer';
import { format as formatDate } from 'date-fns';

import PatronsLogo from '@assets/img/patrons-logo.png';
import Helvetica from '@assets/pdf/fonts/Helvetica.ttf';
import HelveticaBold from '@assets/pdf/fonts/Helvetica-Bold.ttf';
import HelveticaItalic from '@assets/pdf/fonts/Helvetica-Italic.ttf';
import listCountries from '@libs/json/prodigiCountries.json';
import { styles, captilizeAllWords, printDescription, formatPrice } from './utils';

Font.register({
  family: 'Helvetica',
  fonts: [
    {
      fontWeight: 400,
      src: Helvetica,
      fontStyle: 'Regular'
    },
    {
      fontWeight: 400,
      src: HelveticaItalic,
      fontStyle: 'Italic'
    },
    {
      fontWeight: 700,
      src: HelveticaBold
    }
  ]
});

Font.register({
  family: 'HelveticaBold',
  fonts: [
    {
      fontWeight: 700,
      src: HelveticaBold
    }
  ]
});

const InvoicePrintDocument = ({ history }) => {
  const sender = history?.user;
  const senderName = sender ? sender?.firstName + ' ' + sender?.lastName : null;
  const senderEmail = sender ? sender?.email : null;
  const senderAddress = sender?.address?.line1 ? sender?.address?.line1 : null;
  const countryS = listCountries.filter((country) => country.isoCode === sender?.address?.country);
  const senderCountry = countryS[0]?.name?.toLowerCase();
  const senderCityStateCode =
    sender?.address?.city || sender?.address?.stateName || sender?.address?.postCode
      ? (sender?.address?.city ? sender?.address?.city + ', ' : null) +
        (sender?.address?.stateName ? sender?.address?.stateName + ', ' : null) +
        (sender?.address?.postCode ? sender?.address?.postCode : null)
      : 'No city address';

  const amount = history?.totalPrice;
  return (
    <Document>
      <Page style={styles.pageWrapper} wrap>
        <View style={styles.pageContent}>
          <View style={styles.headerWrapper}>
            <View>
              <Text style={styles.standardText}>From</Text>
              <>
                <Text style={styles.standardTitle}>Patrons.Art</Text>
                <Text style={styles.standardText}>85 William St</Text>
                <Text style={styles.standardText}>Darlinghurst NSW 2010</Text>
                <Text style={[styles.standardText, styles.capitalize]}>
                  {captilizeAllWords('australia')}
                </Text>
              </>
              <View style={styles.spacer}></View>
              <Text style={styles.standardText}>Bill to</Text>
              <>
                <Text style={styles.standardTitle}>{senderName}</Text>
                {senderEmail && <Text style={styles.standardText}>{senderEmail}</Text>}
                {senderAddress && <Text style={styles.standardText}>{senderAddress}</Text>}
                {senderCityStateCode && (
                  <Text style={styles.standardText}>{senderCityStateCode}</Text>
                )}
                {senderCountry && (
                  <Text style={[styles.standardText, styles.capitalize]}>
                    {captilizeAllWords(senderCountry)}
                  </Text>
                )}
              </>
            </View>
            <View style={styles.colRight}>
              <Image src={PatronsLogo} style={styles.logoImage} />
              <Text style={[styles.standardText, styles.standardTextMargin, styles.textRight]}>
                Invoice #
              </Text>
              <Text style={[styles.standardTitle, styles.textRight]}>{history.invoiceID}</Text>
              <Text style={[styles.standardText, styles.standardTextMargin, styles.textRight]}>
                Date
              </Text>
              <Text style={[styles.standardSubtitle, styles.textRight]}>
                {formatDate(new Date(history?.createdAt), 'd MMMM yyyy')}
              </Text>

              <Text
                style={[
                  styles.standardText,
                  styles.textItalic,
                  styles.textSecondary,
                  styles.my8,
                  styles.textRight
                ]}
              >
                Due on receipt
              </Text>
              <Text style={[styles.standardText, styles.standardTextMargin, styles.textRight]}>
                Payment method
              </Text>
              <Text style={[styles.standardSubtitle, styles.textRight]}>Checkout.com</Text>
            </View>
          </View>
          <View style={styles.bodyWrapper}>
            <View style={[styles.table, styles.tableHeadWrapper]}>
              <Text style={[styles.tableHead, styles.tableCol50, styles.standardText]}>
                Description
              </Text>
              <Text
                style={[
                  styles.tableHead,
                  styles.tableCol50,
                  styles.textAlignRight,
                  styles.standardText
                ]}
              >
                Amount
              </Text>
            </View>
            <View style={[styles.table, styles.tableHeadWrapper]}>
              <Text style={[styles.tableBody, styles.tableCol50, styles.standardText]}>
                <Text style={[styles.tableCol100, styles.displayBlock]}>
                  {printDescription(history)}
                </Text>
              </Text>
              <Text
                style={[
                  styles.tableBody,
                  styles.tableCol50,
                  styles.textAlignRight,
                  styles.standardText
                ]}
              >
                $ {formatPrice(amount - formatPrice(amount - amount / (1 + 0.1)))}
              </Text>
            </View>
            <>
              <View style={[styles.pb5]}></View>
              <View style={[styles.table]}>
                <Text
                  style={[
                    styles.tableBodySummary,
                    styles.tableCol80,
                    styles.textAlignRight,
                    styles.standardText
                  ]}
                >
                  Subtotal
                </Text>
                <Text
                  style={[
                    styles.tableBodySummary,
                    styles.tableCol20,
                    styles.textAlignRight,
                    styles.standardText
                  ]}
                >
                  ${' '}
                  {formatPrice(
                    formatPrice(Number(amount)) - formatPrice(amount - amount / (1 + 0.1))
                  )}
                </Text>
              </View>
              <View style={[styles.table]}>
                <Text
                  style={[
                    styles.tableBodySummary,
                    styles.tableCol80,
                    styles.textAlignRight,
                    styles.standardText
                  ]}
                >
                  VAT 10%
                </Text>
                <Text
                  style={[
                    styles.tableBodySummary,
                    styles.tableCol20,
                    styles.textAlignRight,
                    styles.standardText
                  ]}
                >
                  $ {formatPrice(amount - amount / (1 + 0.1))}
                </Text>
              </View>
              <View style={[styles.table]}>
                <Text
                  style={[
                    styles.tableBodySummary,
                    styles.tableCol80,
                    styles.textAlignRight,
                    styles.standardText
                  ]}
                >
                  Total
                </Text>
                <Text
                  style={[
                    styles.tableBodySummary,
                    styles.tableCol20,
                    styles.textAlignRight,
                    styles.standardText
                  ]}
                >
                  $ {formatPrice(Number(amount))}
                </Text>
              </View>
            </>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default InvoicePrintDocument;
