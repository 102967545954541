import cx from 'classnames';
import { useState, useRef, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Box, Button, Logo, FormField, Input } from '@components';
import { useWaitlist } from '@libs/hooks/email-waitlist';
import { useParams } from 'react-router-dom';

function Landing() {
  const { t } = useTranslation();
  const params = useParams();
  const id = params.id;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    resetField
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur'
  });

  const { updateLoading, onUpdateVerified } = useWaitlist();

  const password = useRef({});
  password.current = watch('password', '');
  const [interestedAs, setInterestedAs] = useState('collector');

  const onSubmit = useCallback(
    async (values) => {
      await onUpdateVerified({ ...values, interestedAs, id });
    },
    [interestedAs]
  );
  const [firstNameLength, setFirstNameLength] = useState('0');
  const [lastNameLength, setLastNameLength] = useState('0');
  return (
    <>
      <Box className="landing">
        <Box className="landing__content">
          <Box className="landing__content__logo">
            <Logo height="36" />
          </Box>
          <Box className="landing__content__signup">
            <Box>{t('p.home.meta.signup.description')}</Box>
            <Box className="landing__content__signup__form">
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Box className="landing__content__signup__form__flex">
                  <FormField>
                    <Input
                      fullWidth
                      label={t('p.auth.fields.firstName.label')}
                      placeholder={t('p.auth.fields.firstName.label')}
                      errorMessage={errors.firstName?.message}
                      {...register('firstName', {
                        required: { value: true, message: t('p.auth.fields.firstName.required') },
                        pattern: {
                          value: /^[a-z ,.'-]+$/i,
                          message: t('p.auth.fields.firstName.invalid')
                        },
                        minLength: { value: 1, message: t('p.auth.fields.firstName.minLength') },
                        maxLength: {
                          value: 50,
                          message: t('p.auth.fields.firstName.maxLength', {
                            length: firstNameLength
                          })
                        }
                      })}
                      onChange={(e) => {
                        setFirstNameLength(e.target.value.length.toString());
                      }}
                    />
                  </FormField>
                  <FormField>
                    <Input
                      fullWidth
                      label={t('p.auth.fields.lastName.label')}
                      placeholder={t('p.auth.fields.lastName.label')}
                      errorMessage={errors.lastName?.message}
                      {...register('surName', {
                        required: { value: true, message: t('p.auth.fields.lastName.required') },
                        pattern: {
                          value: /^[a-z ,.'-]+$/i,
                          message: t('p.auth.fields.lastName.invalid')
                        },
                        minLength: { value: 1, message: t('p.auth.fields.lastName.minLength') },
                        maxLength: {
                          value: 50,
                          message: t('p.auth.fields.lastName.maxLength', { length: lastNameLength })
                        }
                      })}
                      onChange={(e) => {
                        setLastNameLength(e.target.value.length.toString());
                      }}
                    />
                  </FormField>
                </Box>
                <FormField>
                  <Input
                    fullWidth
                    label={t('p.auth.fields.phone.label')}
                    placeholder={t('p.auth.fields.phone.placeholder')}
                    as="number"
                    errorMessage={errors.phone?.message}
                    {...register('phone', {
                      required: { value: true, message: t('p.auth.fields.phone.required') }
                    })}
                  />
                </FormField>
                <FormField>
                  <Input
                    fullWidth
                    label={t('p.auth.fields.password.label')}
                    placeholder={t('p.auth.fields.password.label')}
                    as="password"
                    errorMessage={errors.password?.message}
                    {...register('password', {
                      required: { value: true, message: t('p.auth.fields.password.required') },
                      pattern: {
                        value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                        message: t('p.auth.fields.password.strength')
                      },
                      minLength: { value: 8, message: t('p.auth.fields.password.minLength') },
                      maxLength: { value: 128, message: t('p.auth.fields.password.maxLength') }
                    })}
                  />
                </FormField>
                <FormField>
                  <Input
                    fullWidth
                    label={t('p.auth.fields.confirmPassword.label')}
                    placeholder={t('p.auth.fields.confirmPassword.label')}
                    as="password"
                    errorMessage={errors.confirm_password?.message}
                    {...register('confirm_password', {
                      required: {
                        value: true,
                        message: t('p.auth.fields.confirmPassword.required')
                      },
                      validate: (value) =>
                        value === password.current || t('p.auth.fields.confirmPassword.match')
                    })}
                  />
                </FormField>
                <FormField>
                  <Box className="text-base font-medium mb-1">
                    {t('p.auth.fields.interestedAs.label')}
                  </Box>
                  <Box className="landing__content__signup__form__interested">
                    <Box
                      className={cx(
                        'landing__content__signup__form__interested__option',
                        interestedAs === 'artist' ? 'interested__active' : ''
                      )}
                      onClick={() => setInterestedAs('artist')}
                    >
                      {t('p.auth.fields.interestedAs.artist')}
                    </Box>
                    <Box
                      className={cx(
                        'landing__content__signup__form__interested__option',
                        interestedAs === 'collector' ? 'interested__active' : ''
                      )}
                      onClick={() => setInterestedAs('collector')}
                    >
                      {t('p.auth.fields.interestedAs.collector')}
                    </Box>
                    <Box
                      className={cx(
                        'landing__content__signup__form__interested__option',
                        interestedAs === 'gallerist' ? 'interested__active' : ''
                      )}
                      onClick={() => setInterestedAs('gallerist')}
                    >
                      {t('p.auth.fields.interestedAs.gallerist')}
                    </Box>
                    <Box
                      className={cx(
                        'landing__content__signup__form__interested__option',
                        interestedAs === 'other' ? 'interested__active' : ''
                      )}
                      onClick={() => setInterestedAs('other')}
                    >
                      {t('p.auth.fields.interestedAs.other')}
                    </Box>
                  </Box>
                </FormField>
                <Box className="mb-2">
                  <Button loading={updateLoading} id="signup-button" as="submit" primary fullWidth>
                    {t('p.auth.register.createAccount')}
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </Box>
        <Helmet>
          <title>{t('p.head.meta.title')}</title>
          <meta name="description" content={t('p.head.meta.description')} />
          <meta property="og:title" content={t('p.head.meta.title')} />
          <meta property="og:description" content={t('p.head.meta.description')} />
          <meta property="og:image" content="https://cdn.patrons.art/public/webapp/og-image.png" />
        </Helmet>
      </Box>
    </>
  );
}

export default Landing;
