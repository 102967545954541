import cx from 'classnames';
import PropTypes from 'prop-types';

import { Box } from '@components';
import { exchangePropType } from '@libs/prop-types';
import { useWalletAddress } from '@components/nft-address/patrons/hooks';
import { formatWalletAddress } from '@components/connect-wallet-button/patrons/utils';
import { ReactComponent as MetamaskLogoIcon } from '@assets/svg/metamask-logo.svg';
import { copyToClipboard } from '@libs/utils/share';
import { useTranslation } from 'react-i18next';
import useToast from '@libs/utils/toast';

const NFTAddress = ({
  exchange,
  address,
  icon,
  iconSize,
  className,
  showStatus = false,
  ...props
}) => {
  const { t } = useTranslation();
  const walletAddress = useWalletAddress({ exchange, address });
  const toast = useToast();
  const copyClipboard = (event) => {
    event.stopPropagation();
    event.preventDefault();
    copyToClipboard(walletAddress);
    toast(t('components.purchaseCard.copyAddress'), 'info', 500);
  };

  if (walletAddress) {
    return (
      <>
        {className === 'connect-wallet-button-mint' ? (
          <div className={cx('nft-address', 'box')} {...props} onClick={(e) => copyClipboard(e)}>
            <Box className="nft-address-page">
              <Box className="nft-address-page__body">
                <MetamaskLogoIcon width={50} height={50} />
                <Box className="nft-address-page__body__right">
                  {formatWalletAddress(walletAddress)}
                  <Box>{t('p.components.nftAddress.connected')}</Box>
                </Box>
              </Box>
            </Box>
          </div>
        ) : (
          <div
            className={cx('nft-address', 'box', className)}
            {...props}
            onClick={(e) => copyClipboard(e)}
          >
            <Box className="nft-address__status">
              <Box className="nft-address__status__top">
                {t('p.components.nftAddress.connectedTo')}
              </Box>
              <Box className="nft-address__status__wallet">
                <MetamaskLogoIcon width={20} height={20} />
                <Box className="nft-address__status__wallet__address">
                  {formatWalletAddress(walletAddress)}
                </Box>
              </Box>
            </Box>
          </div>
        )}
      </>
    );
  }

  return null;
};

NFTAddress.propTypes = {
  exchange: exchangePropType,
  className: PropTypes.string
};

export default NFTAddress;
