import { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import cx from 'classnames';
import {
  Box,
  Button,
  CollectibleCard,
  CollectibleGrid,
  CollectibleModal,
  CollectibleTimer,
  ClaimCollectibleCard,
  Container,
  EditionCard,
  EditionSecondary,
  Error,
  Grid,
  Heading,
  Media,
  MediaVinyl,
  MediaVIP,
  Modal,
  OfficialBadge,
  ProfileHeader,
  Separator,
  SkeletonLoading,
  TabItem,
  Table,
  Tabs,
  Text,
  CollectibleOwnerActions,
  ListingTableInfiniteScroll,
  Link
} from '@components';

import useQuery from '@libs/utils/query';
import { useAuth } from '@libs/contexts/auth';
import {
  useCollectible,
  useCollectibleDetail,
  useAuthorCollectibles
} from '@libs/hooks/collectible';
import { useChannelByOwner } from '@libs/hooks/discourse';
import { useFollower } from '@libs/hooks/follower';
import { useUserByUsername } from '@libs/hooks/user';
import { getNearestReleaseDate } from '@libs/utils/collectible';
import { useCollectibleOwnership, useSecondaryMarketplace } from '@libs/hooks/exchange';
import {
  getTierAttribute,
  collectibleTypeParser,
  genreParser
} from '@pages/collectible/fansinc/utils';
import { useTierTilesData } from '@pages/collectible/fansinc/hooks';
import { FORMAT_TYPE, mediaChecker } from '@libs/utils/media';
import { TIER_TYPES } from '@libs/utils/exchange';
import { createAssetUrl } from '@libs/utils/media';
import { ReactComponent as LockIcon } from '@assets/svg/lock.svg';
import NotFound from '@pages/not-found';

function Collectible() {
  const params = useParams();
  const username = params.username?.toLowerCase();
  const query = useQuery();
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useAuth();

  const slug = params.slug;
  const isDraft = query.get('draft') === '1';

  /* Page states */
  const [isCollectibleModalOpen, setCollectibleModalOpen] = useState(false);
  const [unfollowModal, setUnfollowModal] = useState(false);
  const [unfollowType, setUnfollowType] = useState('user');
  const [activeTabTier, setActiveTabTier] = useState(0);
  const [activeTab, setActiveTab] = useState(t('collectible.about'));

  const availableCollectiblesTableRef = useRef();
  const buyNowRef = useRef();

  /* Data Fetching Hooks */
  const { data: profile, loading: profileLoading } = useUserByUsername(username);
  const { data: collectible, loading: collectibleLoading, refetch } = useCollectible(slug, isDraft);
  const { data: channels } = useChannelByOwner(profile?.id);
  const { data: collectibleSupply, loading: collectibleSupplyLoading } = useCollectibleDetail(
    collectible?.id
  );
  const {
    tierTilesData,
    isLoading: isLoadingTierTilesData,
    hasError: tierTilesDataHasError,
    getData: refetchTierTiles
  } = useTierTilesData(collectible);
  const { owned } = useCollectibleOwnership({
    collectibleID: collectible?.id,
    userID: user?.id
  });
  const {
    data: secondaryExchanges,
    loading: isLoading2ndExchange,
    onSearchSerialIndex,
    hasMore,
    onNextPage: onLoadMore
  } = useSecondaryMarketplace({ collectibleID: collectible?.id, authorID: collectible?.userID });

  const { counter: secondaryExchangesCounter } = useSecondaryMarketplace({
    collectibleID: collectible?.id,
    authorID: collectible?.userID
  });

  const { data: otherCollectibles, loading: otherCollectiblesLoading } = useAuthorCollectibles({
    userID: collectible?.userID,
    collectibleID: collectible?.id
  });
  const {
    isFollowing,
    onCreate: onFollow,
    onDelete: onUnfollow,
    loading: followLoading
  } = useFollower(collectible?.userID);

  const [dataError, setDataError] = useState(false);
  useEffect(() => {
    let error = false;
    if (typeof profile === 'undefined' && !profileLoading) {
      error = true;
    }

    if (collectible === null && !collectibleLoading) {
      error = true;
    }
    setDataError(error);

    if (collectible?.creator?.username !== username && !collectibleLoading && !error) {
      history.push(`/${collectible?.creator?.username}/${slug}`);
    }
  }, [profileLoading, profile, collectibleLoading, collectible, history]);

  const owner = profile?.displayName || profile?.firstName + ' ' + profile?.lastName;
  let collectibleUrl =
    collectible?.creator?.username && collectible?.slug ? (
      `fans.inc/${collectible?.creator?.username}/${collectible?.slug}`
    ) : (
      <SkeletonLoading className="w-full h-4" />
    );

  const releaseDate = getNearestReleaseDate(
    collectible?.releases || collectible?.collectible?.releases
  );

  // We need to sort the releases by tier type first then release date. Digital Original should always be first
  // The numbered edition is sorted by release date. We need this because there is a chance that the NUMBERED EDITION
  // release date could be before the DIGITAL ORIGINAL
  collectible?.releases?.sort((a, b) =>
    a.tierType === 'DIGITAL_ORIGINAL' ? -1 : a.releaseDate > b.releaseDate ? 1 : -1
  );

  const collectibleType = collectibleTypeParser[collectible?.category];

  const collectibleAttribute = [
    [t('collectible.attributes.title'), collectible?.title],
    [
      t('collectible.attributes.creator'),
      collectible?.creator?.displayName ||
        collectible?.creator?.firstName + ' ' + collectible?.creator?.lastName
    ],
    [t('collectible.attributes.collectibleType'), collectibleType],
    [t('collectible.attributes.yearOfCreation'), collectible?.yearOfCreation || '-'],
    [t('collectible.attributes.relevantGenres'), genreParser(collectible?.genre)],
    [
      t('collectible.attributes.maxQuantity'),
      collectibleSupplyLoading ? (
        <SkeletonLoading />
      ) : (
        collectibleSupply?.totalSupply + ' (' + collectibleSupply?.totalReleased + ' released)'
      )
    ]
  ];

  const onClickEditionCard = (exchangeIntent, exchangeID) => {
    if (exchangeIntent === 'SCROLL_TO_COLLECTIBLES_TABLE') {
      if (availableCollectiblesTableRef?.current) {
        availableCollectiblesTableRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      return;
    }
    if (exchangeID) {
      history.replace(
        `/${collectible?.creator?.username}/${collectible?.slug}/checkout/${exchangeID}`
      );
    }
  };

  const onBuySecondaryMarket = (exchange) => {
    const isOwner = exchange?.currentOwnerID === user?.id;

    if (!isOwner) {
      const url = `/${exchange.collectible.creator.username}/${exchange.slug}/checkout/${exchange.id}`;
      history.push(url);
    } else {
      const url = `/${exchange.collectible.creator.username}/${exchange.slug}/${exchange.serialIndex}/${user.username}`;
      history.push(url);
    }
  };

  const handleFollow = useCallback(
    (data, e) => {
      e.preventDefault();
      if (isFollowing) {
        onUnfollow(data);
      } else {
        onFollow(data);
      }
    },
    [isFollowing, collectible?.userID] // eslint-disable-line
  );

  const setUnfollowModalOpen = (type) => {
    setUnfollowModal(true);
    setUnfollowType(type);
  };

  const setUnfollowModalClose = () => {
    setUnfollowModal(false);
  };

  const handleButtonInsideStory = () => {
    buyNowRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  if (dataError) {
    return <NotFound />;
  }

  const isNotVIP = collectible?.category !== 'VIP_PASS';

  return (
    <>
      <ProfileHeader
        user={profile}
        loading={profileLoading}
        isFollow={isFollowing}
        followLoading={followLoading}
        onHandleFollow={(e) => {
          isFollowing
            ? setUnfollowModalOpen('user')
            : handleFollow({ followeeID: collectible.userID }, e);
        }}
        customFollow
      />
      {collectible?.status === 'DRAFT' && collectible?.accountID !== user?.accountID ? (
        <Error
          title={t('collectible.errors.unauthorized')}
          description={t('collectible.errors.unauthorizedDesc')}
        />
      ) : (
        <>
          {mediaChecker(collectible?.assetUrl) === FORMAT_TYPE.IMAGE &&
          collectible?.category !== 'VIP_PASS' ? (
            <CollectibleModal
              collectible={collectible}
              onShow={isCollectibleModalOpen}
              onClose={() => {
                setCollectibleModalOpen(false);
              }}
            />
          ) : null}
          <Container className="collectible">
            <Grid className="collectible__grid">
              <Box className="collectible__media">
                <Box className={cx('collectible__media__content', !isNotVIP && 'hidden')}>
                  <Box>
                    {collectible?.category ? (
                      isNotVIP && <OfficialBadge type={collectible?.category} isImage />
                    ) : (
                      <SkeletonLoading className="w-full h-12" />
                    )}
                    <Heading className="collectible__content__title" as="h2">
                      {collectible?.title ? (
                        isNotVIP && collectible?.title
                      ) : (
                        <SkeletonLoading className="w-full h-10" />
                      )}
                    </Heading>
                    {isNotVIP && (
                      <Text as="Box" className="collectible__content__url">
                        {collectibleUrl}
                      </Text>
                    )}
                  </Box>
                </Box>
                <Box
                  className="cursor-pointer"
                  onClick={() => {
                    setCollectibleModalOpen(true);
                  }}
                >
                  {collectible?.streamExternal !== 'TRUE' && collectible?.category === 'SONG' ? (
                    <MediaVinyl collectible={collectible} />
                  ) : collectible?.category === 'VIP_PASS' ? (
                    <MediaVIP collectible={collectible} url={collectibleUrl} />
                  ) : (
                    <Media
                      collectible={collectible}
                      full
                      showPlayer
                      size={1080}
                      showPlayerSlider
                      showExternalLink={collectible?.streamExternal === 'TRUE'}
                    />
                  )}
                </Box>
                {collectible && (
                  <CollectibleOwnerActions isRelease={false} collectible={collectible} />
                )}
              </Box>
              <Box
                className={cx(
                  'collectible__content',
                  collectibleLoading && 'collectible__content-loading'
                )}
              >
                <div ref={buyNowRef}>
                  <Box className="flex items-center flex-wrap mb-2">
                    {collectibleLoading ? (
                      <>
                        <SkeletonLoading className="w-36 h-52 mr-2" />
                        <SkeletonLoading className="w-36 h-52" />
                      </>
                    ) : (
                      collectible?.promoEnabled !== 'TRUE' &&
                      collectible?.releases?.length > 0 &&
                      collectible?.releases
                        ?.sort((a, b) => a.tierType.localeCompare(b.tierType))
                        .map((tier, index) => {
                          return (
                            <EditionCard
                              refetchTierTiles={refetchTierTiles}
                              key={`${tier?.tierType}_${index}`}
                              tier={tier}
                              tierIndex={index}
                              collectible={collectible}
                              releaseDate={tier?.releaseDate}
                              className="mr-3"
                              isFollow={isFollowing}
                              followLoading={followLoading}
                              tierTilesData={tierTilesData}
                              onHandleFollow={(e) => {
                                isFollowing
                                  ? setUnfollowModalOpen('collectible')
                                  : handleFollow({ followeeID: collectible.userID }, e);
                              }}
                              onClick={(
                                exchangeIntent,
                                exchangeID = null,
                                currentBidExchange = null
                              ) =>
                                onClickEditionCard(exchangeIntent, exchangeID, currentBidExchange)
                              }
                              onClickEdition={() => {
                                setActiveTabTier(index);
                                setActiveTab(
                                  tier?.tierType === TIER_TYPES.DIGITAL_ORIGINAL
                                    ? t('collectible.original')
                                    : t('collectible.edition')
                                );
                              }}
                              isActiveEdition={index === activeTabTier ? true : false}
                              isLoading={isLoadingTierTilesData}
                              hasError={tierTilesDataHasError}
                            />
                          );
                        })
                    )}
                    {collectible && collectible?.promoEnabled !== 'TRUE' && (
                      <EditionSecondary
                        collectible={collectible}
                        tierTilesData={tierTilesData}
                        loading={collectibleLoading}
                        tier={collectible?.releases}
                        onClick={onClickEditionCard}
                      />
                    )}
                    {collectible && collectible?.promoEnabled === 'TRUE' && (
                      <ClaimCollectibleCard collectible={collectible} refetch={refetch} />
                    )}
                  </Box>
                </div>
                {collectibleLoading ? (
                  <SkeletonLoading className="w-full h-4 my-4" />
                ) : collectible?.marketingText ? (
                  <div
                    className="collectible-marketing text-justify mb-1"
                    dangerouslySetInnerHTML={{ __html: collectible?.marketingText }}
                  />
                ) : null}
                {collectibleLoading ? (
                  <SkeletonLoading className="w-full h-80" />
                ) : (
                  <Tabs
                    className="collectible__tab__wrapper"
                    classNameChild="collectible__tab"
                    classNameButton="collectible__tab-button"
                    directTo={activeTab}
                    full
                  >
                    <TabItem label={t('collectible.about')}>
                      {collectible?.description && (
                        <Box className="whitespace-pre-wrap text-justify pb-5">
                          {collectible?.description}
                        </Box>
                      )}
                      <Separator dark />
                      <Table className="collectible__attribute" rows={collectibleAttribute} />
                    </TabItem>
                    {collectible?.promoEnabled !== 'TRUE' && collectible?.releases?.length > 1 && (
                      <TabItem label={t('collectible.original')}>
                        <Box className="pb-5">
                          {t('collectible.expansionInformation.digitalOriginal')}
                        </Box>
                        <Separator dark />
                        {collectible && (
                          <Table
                            className="collectible__attribute"
                            rows={getTierAttribute(collectible, collectible?.releases[0], channels)}
                          />
                        )}
                      </TabItem>
                    )}

                    {collectible?.promoEnabled !== 'TRUE' && (
                      <TabItem label={t('collectible.edition')}>
                        <Box className="pb-5">
                          {t('collectible.expansionInformation.numberedEdition')}
                        </Box>
                        <Separator dark />
                        {collectible && (
                          <Table
                            className="collectible__attribute"
                            rows={getTierAttribute(
                              collectible,
                              collectible?.releases[collectible?.releases?.length - 1],
                              channels
                            )}
                          />
                        )}
                      </TabItem>
                    )}

                    {!collectibleLoading && collectible?.insideStory ? (
                      <TabItem label={t('collectible.insideStory.label')}>
                        {owned ? (
                          <div dangerouslySetInnerHTML={{ __html: collectible?.insideStory }} />
                        ) : (
                          <div className="collectible__disable-inside-story">
                            <div className="collectible__disable-inside-story__icon">
                              <LockIcon />
                            </div>
                            <p>
                              {t('collectible.insideStory.disabled', {
                                creator: `${profile?.firstName} ${profile?.lastName}`
                              })}
                            </p>
                            <Button
                              onClick={() => {
                                handleButtonInsideStory();
                              }}
                              className="collectible__disable-inside-story__button"
                            >
                              {t('collectible.button.getItNow')}
                            </Button>
                          </div>
                        )}
                      </TabItem>
                    ) : null}
                    {collectible?.faq?.length > 0 && (
                      <TabItem label={t('collectible.faq.title')}>
                        <Box className="collectible__attribute__faq">
                          {collectible?.faq?.map((faq) => (
                            <>
                              <Box className="collectible__attribute__faq__wrapper">
                                <Box className="collectible__attribute__faq__wrapper__key">
                                  {faq?.key < 10 ? `0${faq?.key}` : faq?.key}
                                </Box>
                                <Box className="pl-4 collectible__attribute__faq__wrapper__body">
                                  <Box className="collectible__attribute__faq__wrapper__body__title">
                                    {faq?.title}
                                  </Box>
                                  <Box className="collectible__attribute__faq__wrapper__body__content">
                                    {faq?.content}
                                  </Box>
                                </Box>
                              </Box>
                              <Separator dark className="mb-5" />
                            </>
                          ))}
                          <Box className="collectible__attribute__faq__contact">
                            <Trans i18nKey="collectible.faq.contact">
                              <Link
                                target="_blank"
                                className="collectible__attribute__faq__link"
                                to={'https://support.fans.inc'}
                              >
                                {{ supportFans: `support@fans.inc` }}
                              </Link>
                            </Trans>
                          </Box>
                        </Box>
                      </TabItem>
                    )}
                  </Tabs>
                )}
              </Box>
            </Grid>
          </Container>
          <Modal
            className="follow-modal"
            size="md"
            isOpen={unfollowModal}
            onClose={setUnfollowModalClose}
            verticalCenter
            heading={
              unfollowType === 'user'
                ? t('components.followButton.modal.heading', {
                    name: `${collectible?.creator?.firstName} ${collectible?.creator?.lastName}`
                  })
                : t('components.followButton.modal.headingCollectible')
            }
            closable
            action={
              <Button
                danger
                onClick={(e) => {
                  handleFollow({ followeeID: collectible.userID }, e);
                  setUnfollowModalClose();
                }}
                disabled={followLoading}
                loading={followLoading}
              >
                {t('components.followButton.modal.unsubscribe')}
              </Button>
            }
          >
            {unfollowType === 'user'
              ? t('components.followButton.modal.confirmation')
              : t('components.followButton.modal.confirmationCollectible')}
          </Modal>
          {collectible?.status === 'PUBLISHED' ? (
            <Container ref={availableCollectiblesTableRef}>
              <ListingTableInfiniteScroll
                show={secondaryExchangesCounter > 0}
                searchable
                sortable={false}
                title={t('collectible.browseSecondary')}
                data={secondaryExchanges}
                user={user}
                onSearch={(searchValue) => {
                  onSearchSerialIndex(searchValue);
                }}
                loading={isLoading2ndExchange}
                onNextPage={onLoadMore}
                onBuy={onBuySecondaryMarket}
                hasMore={hasMore}
              />
            </Container>
          ) : null}
          {otherCollectibles.length > 0 ? (
            <Container className="mt-14 pb-14">
              <CollectibleGrid
                ownerUsername={profile?.username}
                owner={owner}
                otherCollectibles
                loading={otherCollectiblesLoading}
                viewAll={false}
              >
                {otherCollectibles &&
                  otherCollectibles.map((collectible) => (
                    <CollectibleCard
                      key={collectible.id}
                      collectible={collectible}
                    ></CollectibleCard>
                  ))}
              </CollectibleGrid>
            </Container>
          ) : null}
        </>
      )}

      <Helmet>
        <link rel="canonical" href={'https://fans.inc' + window.location.pathname} />
        <meta name="URL" content={'https://fans.inc' + window.location.pathname} />
        <meta name="twitter:card" content="summary_large_image" />
        {collectible?.title ? (
          <title>
            {t('collectible.meta.title', {
              creator:
                collectible?.creator?.displayName ||
                `${collectible?.creator?.firstName} ${collectible?.creator?.lastName}`,
              title: collectible?.title,
              type: collectibleType
            })}
          </title>
        ) : null}
        {collectible?.metaDescription ? (
          <meta name="description" content={collectible?.metaDescription} />
        ) : (
          <meta
            name="description"
            content={t('collectible.meta.description', {
              type: collectibleType,
              creator:
                collectible?.creator?.displayName ||
                `${collectible?.creator?.firstName} ${collectible?.creator?.lastName}`,
              title: collectible?.title,
              maxNumbered: collectible?.maxNumberedEditions
            })}
          />
        )}
        {collectible?.metaDescription ? (
          <meta name="og:description" content={collectible?.metaDescription} />
        ) : (
          <meta
            name="og:description"
            content={t('collectible.meta.description', {
              type: collectibleType,
              creator:
                collectible?.creator?.displayName ||
                `${collectible?.creator?.firstName} ${collectible?.creator?.lastName}`,
              title: collectible?.title,
              maxNumbered: collectible?.maxNumberedEditions
            })}
          />
        )}
        <meta
          property="og:title"
          content={t('collectible.meta.title', {
            creator:
              collectible?.creator?.displayName ||
              `${collectible?.creator?.firstName} ${collectible?.creator?.lastName}`,
            title: collectible?.title,
            type: collectibleType
          })}
        />
        <meta property="og:url" content={'https://fans.inc' + window.location.pathname} />
        <meta
          property="og:image"
          content={
            collectible?.mediaThumbnail
              ? createAssetUrl(collectible?.mediaThumbnail, 800)
              : 'https://cdn.fans.inc/public/webapp/og-image.png'
          }
        />
      </Helmet>
    </>
  );
}

export default Collectible;
