import { Box, Text, Heading } from '@components';
import { useTranslation } from 'react-i18next';

function PrintCollectibleSummary({ exchange, selectedSize, selectedFrame, quantity, size }) {
  const {
    collectible: { title },
    serialIndex
  } = exchange;

  const { t } = useTranslation();

  const tierType = serialIndex > 1 ? 'Limited Edition' : 'Digital Original';

  return (
    <Box className="print-collectible-summary">
      <Box className="print-collectible-checkout-modal-patrons__grid__subheading">
        <Text as="span">{t('printCollectiblePage.checkoutModal.headings.orderSummary')}</Text>
      </Box>
      <Box className="print-collectible-summary__item">
        <Box className="print-collectible-summary__item__left">
          {t('printCollectiblePage.checkoutModal.summary.size')}
        </Box>
        <Box className="print-collectible-summary__item__right">{size}</Box>
      </Box>
      <Box className="print-collectible-summary__item">
        <Box className="print-collectible-summary__item__left">
          {t('printCollectiblePage.checkoutModal.summary.QTY')}
        </Box>
        <Box className="print-collectible-summary__item__right">{quantity}x</Box>
      </Box>
    </Box>
  );
}

export default PrintCollectibleSummary;
