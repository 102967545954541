import { useTranslation } from 'react-i18next';
import { useSearchCollectiblesByOwner } from '@libs/hooks/collectible';
import { Box, Button, CollectibleCard, CollectibleGrid, Text, Link } from '@components';
import { categoryParser } from '@pages/collectible/fansinc/utils';

const CollectibleCategory = ({ username, category, ...props }) => {
  const { t } = useTranslation();
  const { data: categoryCollectibles, loading: categoryCollectiblesLoading } =
    useSearchCollectiblesByOwner(username, false, null, category);
  const profileCollectibleLink = `/${username}/collectibles`;

  return (
    <Box className="mb-10">
      <Box className="collectible-grid__category">
        <Text className="collectible-grid__header__filter-by">
          <Text as="span" className="collectible-grid__header__filter-by__item">
            {categoryParser(category, true)}
          </Text>
        </Text>
        <Box className="collectible-grid__header__profile__button">
          <Link to={profileCollectibleLink}>
            <Button outline>{t('components.collectibleGrid.viewAll')}</Button>
          </Link>
        </Box>
      </Box>

      <CollectibleGrid loading={categoryCollectiblesLoading} showEmpty profile isSlider>
        {categoryCollectibles &&
          categoryCollectibles.map((collectible) => (
            <CollectibleCard
              key={`category-${collectible.id}`}
              collectible={collectible}
            ></CollectibleCard>
          ))}
      </CollectibleGrid>
    </Box>
  );
};

export default CollectibleCategory;
