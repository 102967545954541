import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import ReactPlayer from 'react-player/lazy';
import locomotiveScroll from 'locomotive-scroll';
import React, { useRef, useEffect } from 'react';
import {
  Box,
  Button,
  CollectibleCard,
  Container,
  Footer,
  Heading,
  Link,
  Logo,
  Text,
  CollectibleSkeleton
} from '@components';
import Diamond from '@assets/img/diamond.png';
import TonyMottPlaceholder from '@assets/img/tony-mott/placeholder-video.png';
import TonyMottPlaceholder2 from '@assets/img/tony-mott/placeholder-video-2.png';
import DigitalCollectible from '@assets/img/tony-mott/digital-collectible.png';
import { useCollectiblesByOwnerFeatured } from '@libs/hooks/collectible';
import { createAssetUrl } from '@libs/utils/media';
import { isNewCollectibleCard } from '@libs/utils/collectible';

function TonyMottTemplate({ profile, title, description, ...props }) {
  let container = useRef(null);
  const newCard = isNewCollectibleCard();

  useEffect(() => {
    new locomotiveScroll({
      el: container,
      smooth: true,
      lerp: 0.1,
      multiplier: 0.5
    });
  });

  const { t } = useTranslation();
  const { data: featuredCollectibles, loading: featuredCollectiblesLoading } =
    useCollectiblesByOwnerFeatured(profile, 3);
  const { data: collectibles, loading: collectiblesLoading } = useCollectiblesByOwnerFeatured(
    profile,
    4
  );

  const generateOfficialLimited = () => {
    let text = [];
    for (let i = 0; i < 20; i++) {
      text.push(
        <span className="template__badge__item">
          {t('template.tonyMott.officialLimitedEditionDigitalCollectible')}
        </span>
      );
    }
    text.join('');
    return text;
  };
  return (
    <Box data-load-container>
      <Box ref={(el) => (container = el)} data-scroll-container className="template template-tm">
        <Box>
          <Container>
            <Box className="template__header">
              <Link to="/marketplace">
                <Button link>{t('template.tonyMott.menu.home')}</Button>
              </Link>
              <Link to={`/${profile?.username}/collectibles`}>
                <Button outline>{t('template.tonyMott.menu.collectibles')}</Button>
              </Link>
            </Box>
            <Logo className="template__logo" />
            <Heading className="template__subtitle">{t('template.tonyMott.welcomeTo')}</Heading>
            <Heading as="h1" className="template__title">
              {t('template.tonyMott.legendaryRock')} <br />
              {t('template.tonyMott.photographer')}
            </Heading>
            <Heading className="template__subtitle">
              {t('template.tonyMott.officialDigitalCollectible')}
            </Heading>
            <Text className="template__description template__description-banner">
              {t('template.tonyMott.description')}
            </Text>
          </Container>
        </Box>
        <Box>
          <Box className="template__badge">
            <Box
              className="template__badge__wrapper"
              data-scroll
              data-scroll-speed="2"
              data-scroll-direction="horizontal"
            >
              <span className="template__badge__item">{generateOfficialLimited()}</span>
            </Box>
          </Box>
          <Box className="overflow-visible relative z-20">
            <Box className="template__slider-featured">
              {featuredCollectibles &&
                featuredCollectibles.map((collectible, idx) => (
                  <CollectibleCard
                    showButton={newCard === true}
                    showTopNavigation={false}
                    key={collectible.id}
                    collectible={collectible}
                    data-scroll
                    data-scroll-speed={idx % 2 === 0 ? '1.5' : '-1.5'}
                    data-scroll-direction="vertical"
                  ></CollectibleCard>
                ))}
              {featuredCollectiblesLoading && (
                <>
                  <CollectibleSkeleton />
                  <CollectibleSkeleton />
                  <CollectibleSkeleton />
                </>
              )}
            </Box>
          </Box>
          <Box className="template__badge template__badge-second">
            <Box
              className="template__badge__wrapper"
              data-scroll
              data-scroll-speed="-2"
              data-scroll-direction="horizontal"
            >
              <span className="template__badge__item">{generateOfficialLimited()}</span>
            </Box>
          </Box>
        </Box>
        <Box className="template__cta">
          <Container>
            <Box className="template__cta__wrapper">
              <Text className="template__cta__content">
                <span className="template__cta__content-red">.</span>
                {t('template.tonyMott.getTheWorld')}
              </Text>
              <Link to={`/${profile?.username}/collectibles`}>
                <Button>{t('template.tonyMott.button.goToMarketplace')}</Button>
              </Link>
            </Box>
          </Container>
        </Box>

        <Box className="template__about-tony relative z-10">
          <Container>
            <Box
              data-scroll
              data-scroll-speed="-1.5"
              data-scroll-direction="horizontal"
              className="relative z-20"
            >
              <Heading as="h2" className="template__title-section">
                {t('template.tonyMott.aboutTonyMottTitle')}
              </Heading>

              <Box className="template__about-tony-description">
                <Text className="template__description" as="p">
                  {t('template.tonyMott.aboutTonyMottDescription1')}
                </Text>
                <Text className="template__description" as="p">
                  {t('template.tonyMott.aboutTonyMottDescription2')}
                </Text>
              </Box>
            </Box>

            <Box
              data-scroll
              data-scroll-speed="2"
              data-scroll-direction="vertical"
              className="relative z-10"
            >
              <ReactPlayer
                className="template__about-tony-video"
                url="https://www.youtube.com/watch?v=qPBjkaD-rp0"
                light={TonyMottPlaceholder}
                playing
                controls
                width="100%"
                height="700"
              />
            </Box>

            <Box
              data-scroll
              data-scroll-speed="3"
              data-scroll-direction="horizontal"
              className="template__about-tony-description template__about-tony-description-last relative z-20"
            >
              <Text className="template__description" as="p">
                {t('template.tonyMott.aboutTonyMottDescription3')}
              </Text>
              <Text className="template__description" as="p">
                {t('template.tonyMott.aboutTonyMottDescription4')}
              </Text>
              <Text className="template__description" as="p">
                {t('template.tonyMott.aboutTonyMottDescription5')}
              </Text>
            </Box>
          </Container>
        </Box>

        <Box className="overflow-visible relative z-20">
          <Box className="template__slider-general overflow-visible">
            {collectibles &&
              collectibles.map((collectible, idx) => (
                <CollectibleCard
                  showButton={false}
                  showTopNavigation={false}
                  key={collectible.id}
                  collectible={collectible}
                  data-scroll
                  data-scroll-speed={idx % 2 === 0 ? '1' : '-1'}
                  data-scroll-direction="vertical"
                ></CollectibleCard>
              ))}
            {collectiblesLoading && (
              <>
                <CollectibleSkeleton />
                <CollectibleSkeleton />
                <CollectibleSkeleton />
                <CollectibleSkeleton />
              </>
            )}
          </Box>
        </Box>

        <Box className="template__cta relative z-10">
          <Container>
            <Box className="template__cta__wrapper">
              <Text className="template__cta__content">
                <span className="template__cta__content-red">.</span>
                {t('template.tonyMott.getTheWorld')}
              </Text>
              <Link to={`/${profile?.username}/collectibles`}>
                <Button>{t('template.tonyMott.button.goToMarketplace')}</Button>
              </Link>
            </Box>
          </Container>
        </Box>
        <Box>
          <ReactPlayer
            className="tony-mott-video"
            url="https://www.youtube.com/watch?v=Fl7nDPkxzw0"
            light={TonyMottPlaceholder2}
            playing
            controls
            width="100%"
            height="700"
          />
        </Box>

        <Box className="template__about template__about-fans">
          <Container>
            <Heading
              as="h2"
              className="template__title-section"
              data-scroll
              data-scroll-speed="0.5"
              data-scroll-direction="horizontal"
            >
              {t('template.tonyMott.aboutFansTitle')}
            </Heading>
            <Text
              className="template__description template__description-fans"
              data-scroll
              data-scroll-speed="-0.5"
              data-scroll-direction="horizontal"
            >
              {t('template.tonyMott.aboutFansDescription')}
            </Text>
          </Container>
        </Box>

        <Box className="template__about template__about-dc">
          <Container>
            <Box className="template__about-dc__wrapper">
              <img
                className="template__about-dc__image"
                src={DigitalCollectible}
                alt="Tony Mott"
                data-scroll
                data-scroll-speed="1"
                data-scroll-direction="horizontal"
              />
              <Box
                className="template__about-dc__content"
                data-scroll
                data-scroll-speed="-1"
                data-scroll-direction="horizontal"
              >
                <Heading as="h2" className="template__title-section template__about-dc__title">
                  {t('template.tonyMott.digitalCollectibleTitle')}
                </Heading>
                <Text className="template__about-dc__description">
                  {t('template.tonyMott.digitalCollectibleDescription')}
                </Text>
              </Box>
            </Box>
          </Container>
        </Box>
        <Box className="template__about template__about-do">
          <Container>
            <Box className="template__about-do__wrapper">
              <img
                className="template__about-do__image"
                src={Diamond}
                alt="Tony Mott"
                data-scroll
                data-scroll-speed="-1"
                data-scroll-direction="horizontal"
              />
              <Box
                className="template__about-do__content"
                data-scroll
                data-scroll-speed="1"
                data-scroll-direction="horizontal"
              >
                <Heading as="h2" className="template__title-section template__title-do">
                  {t('template.tonyMott.aboutDigitalOriginalTitle')}
                </Heading>
                <Text>{t('template.tonyMott.aboutDigitalOriginalDescription')}</Text>
              </Box>
            </Box>
          </Container>
        </Box>
        <Box className="template__faq">
          <Container>
            <Link to="/faq">
              <Button>{t('template.tonyMott.button.faqs')}</Button>
            </Link>
          </Container>
        </Box>
        <Box>
          <Footer />
        </Box>
      </Box>
      <Helmet>
        <link rel="canonical" href={'https://fans.inc' + window.location.pathname} />
        <title>{title()}</title>
        <meta name="URL" content={'https://fans.inc' + window.location.pathname} />
        <meta name="description" content={description()} />
        <meta property="og:title" content={title()} />
        <meta property="og:description" content={description()} />
        <meta property="og:url" content={'https://fans.inc' + window.location.pathname} />
        <meta
          property="og:image"
          content={
            profile?.media?.avatarUrl
              ? createAssetUrl(profile?.media?.avatarUrl, 400)
              : 'https://cdn.fans.inc/public/webapp/og-image.png'
          }
        />
      </Helmet>
    </Box>
  );
}

export default TonyMottTemplate;
