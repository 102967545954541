import FansincProfile from './fansinc';
import PatronsProfile from './patrons';

function Profile({ profile, profileLoading }) {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsProfile profile={profile} profileLoading={profileLoading} />;
  } else {
    return <FansincProfile profile={profile} profileLoading={profileLoading} />;
  }
}

export default Profile;
