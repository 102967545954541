import { useState, useEffect } from 'react';

import {
  getNftUrl,
  generateNFTTokenID,
  NFT_MARKETPLACES,
  LISTING_TYPES,
  STATUS_TYPES
} from '@libs/utils/exchange';
import { usePollExchangeByNftToken } from '@libs/hooks/exchange';

export const useOpenSeaUrl = ({ exchange, mintingFunctionStatus }) => {
  const [nftTokenID, setNftTokenID] = useState(null);
  const [openSeaUrl, setOpenSeaUrl] = useState(null);
  const { exchange: NftExchange } = usePollExchangeByNftToken(nftTokenID, 2000, 20000);

  useEffect(() => {
    if (!openSeaUrl) {
      if (exchange.listingType === LISTING_TYPES.NFT) {
        setOpenSeaUrl(
          getNftUrl({ exchange, marketplace: NFT_MARKETPLACES.OPENSEA_TESTNETS_MUBAI })
        );
      } else if (
        exchange.status === STATUS_TYPES.TRANSFERRED_TO_NFT ||
        exchange.status === STATUS_TYPES.MINTING ||
        mintingFunctionStatus === 'success'
      ) {
        setNftTokenID(generateNFTTokenID({ exchange }));
      }
    }
  }, [exchange, mintingFunctionStatus]);

  useEffect(() => {
    if (NftExchange) {
      setOpenSeaUrl(
        getNftUrl({ exchange: NftExchange, marketplace: NFT_MARKETPLACES.OPENSEA_TESTNETS_MUBAI })
      );
    }
  }, [NftExchange]);

  return openSeaUrl;
};
