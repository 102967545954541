import { useState, useEffect } from 'react';
import useQuery from '@libs/utils/query';
import gql from '@libs/utils/gql';
import { exchangeByID } from '@libs/custom-queries/exchange';
import { useAuth } from '@libs/contexts/auth';

export const useRefreshSession = () => {
  const { refreshSession } = useAuth();
  const [didRefreshSession, setDidRefreshSession] = useState(false);
  useEffect(() => {
    if (!didRefreshSession) {
      (async () => refreshSession())();
      setDidRefreshSession(true);
    }
  }, [didRefreshSession, refreshSession]);
};

export const useConfirmationQuery = () => {
  const query = useQuery();
  let status,
    exchangeID,
    exchangeIntent,
    checkoutSessionID = null;
  if (query) {
    status = query.get('status');
    exchangeID = query.get('eid');
    exchangeIntent = query.get('eint');
    checkoutSessionID = query.get('cko-session-id');
  }
  return { status, exchangeID, exchangeIntent, checkoutSessionID };
};

export const usePollExchange = (exchangeID, interval = 5000, timeout = 20000) => {
  const [exchange, setExchange] = useState(null);
  const [totalPollingTime, setTotalPollingTime] = useState(0);
  const [timeoutExceeded, setTimeoutExceeded] = useState(false);

  const getExchangeData = async (id) => {
    try {
      const { data: res } = await gql(exchangeByID, { id });
      if (res?.getExchange) {
        setExchange(res.getExchange);
      } else {
        setExchange(null);
        setTotalPollingTime((prev) => prev + interval);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    let intervalID = null;
    if (exchangeID) {
      intervalID = setInterval(() => {
        if (exchange) {
          clearInterval(intervalID);
        } else {
          if (totalPollingTime > timeout) {
            clearInterval(intervalID);
            setTimeoutExceeded(true);
            return;
          }
          getExchangeData(exchangeID);
        }
      }, interval);
    }
    return () => {
      intervalID && clearInterval(intervalID);
    };
  }, [exchangeID, exchange, totalPollingTime]);

  return { exchange, timeoutExceeded };
};
