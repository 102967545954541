function handleNonNullableTypeError(error) {
  const { path } = error;

  let itemIndex = null;
  if (path[1] === 'items') {
    itemIndex = path[2];
  }

  const nullFieldName = path[path.length - 1];

  const message = `${nullFieldName} is null ${
    itemIndex != null ? '(at index ' + itemIndex + ' in query)' : ''
  }`;

  return { isFatal: false, message };
}

export default handleNonNullableTypeError;
