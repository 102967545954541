import cx from 'classnames';
import { Box, Image } from '@components';
import { useOutsider } from '@libs/utils/outsider';
import { FORMAT_TYPE, mediaChecker } from '@libs/utils/media';

function CollectibleModal({ className, collectible, onShow, onClose, children, ...props }) {
  const handleCloseModal = () => onClose();
  const { ref: refModal } = useOutsider(handleCloseModal);
  const isImage =
    mediaChecker(collectible?.assetUrl) !== FORMAT_TYPE.AUDIO &&
    mediaChecker(collectible?.assetUrl) !== FORMAT_TYPE.VIDEO;

  const defaultStyle = {
    'collectible-modal': true,
    'collectible-modal-show': onShow && isImage
  };

  return (
    <Box className={cx(defaultStyle)}>
      <Box className="collectible-modal__wrapper">
        <Box className="collectible-modal__wrapper__content" ref={refModal}>
          <Image className="collectible-modal__image" size={1920} src={collectible?.assetUrl} />
        </Box>
      </Box>
    </Box>
  );
}

CollectibleModal.defaultProps = {
  onClose: () => {},
  onShow: false
};

export default CollectibleModal;
