import { Logo } from '@components';

const SuspenseLoading = () => {
  return (
    <div className="suspense-loading">
      <Logo className="suspense-loading__logo" width="209" height="59" />
    </div>
  );
};

export default SuspenseLoading;
