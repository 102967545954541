import { userPublicFragment, creatorPublicFragment } from '@libs/fragments/user';

export const collectibleBySlug = /* GraphQL */ `
  query CollectibleBySlug(
    $slug: String
    $sortDirection: ModelSortDirection
    $filter: ModelCollectibleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    collectibleBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        title
        category
        userID
        accountID
        slug
        description
        viewCount
        metaDescription
        insideStory
        collectionID
        assetUrl
        printable
        width
        height
        smallWidth
        smallHeight
        largeWidth
        largeHeight
        originalFrame
        smallFrame
        smallPrint
        mediaThumbnail
        perksOriginal {
          fanClubAccess
          limitedPerks
          lifetimePerks
        }
        perksNumbered {
          fanClubAccess
          limitedPerks
          lifetimePerks
        }
        maxNumberedEditions
        marketingText
        genre
        featured
        promoEnabled
        promoTitle
        promoDescription
        faq {
          key
          title
          content
        }
        createdAt
        updatedAt
        status
        yearOfCreation
        streamExternal
        streamUrl {
          spotify
          appleMusic
          youtube
        }
        releases {
          tierType
          releaseDate
          releaseCount
          listingType
          startingPrice
          finalPrice
          preReleaseAvailability
          saleEndDate
        }
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableHighestBidPrice
        creator {
          ...CreatorPublicProfile
          displayName
          links {
            facebook
            youtube
            instagram
            twitter
            spotify
            soundcloud
            website
            tiktok
            snapchat
            appleMusic
            clubhouse
          }
          bio
        }
      }
    }
  }

  ${creatorPublicFragment}
`;

export const searchCollectibles = /* GraphQL */ `
  query SearchCollectibles(
    $filter: SearchableCollectibleFilterInput
    $sort: SearchableCollectibleSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCollectibles(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        accountID
        userID
        type
        category
        title
        slug
        description
        assetUrl
        mediaThumbnail
        featured
        featuredInProfile
        status
        marketingText
        createdAt
        updatedAt
        promoEnabled
        releases {
          tierType
          releaseDate
          releaseCount
          soldCount
          listingType
          startingPrice
          finalPrice
          saleEndDate
          preReleaseAvailability
          availableForPreRelease
          setStartingPrice
          setFinalPrice
          isReleased
        }
        maxNumberedEditions
        yearOfCreation
        streamExternal
        streamUrl {
          spotify
          appleMusic
          youtube
        }
        lastSoldAt
        searchableType
        searchableFeatured
        searchablefeaturedInProfile
        searchableCategory
        searchableStatus
        searchableLowestPrice
        searchableHighestPrice
        searchableHighestBidPrice
        _version
        _deleted
        _lastChangedAt
        owner
        creator {
          ...UserPublicProfile
        }
      }
      nextToken
      total
    }
  }

  ${userPublicFragment}
`;

export const collectibleLikeByCollectible = /* GraphQL */ `
  query CollectibleLikeByCollectible(
    $collectibleID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCollectibleLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    collectibleLikeByCollectible(
      collectibleID: $collectibleID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
    }
  }
`;
