import FansincExchangesTable from './fansinc';
import PatronsExchangesTable from './patrons';

const ExchangesTable = ({
  listingTranslationKey,
  isUserPage,
  exchanges,
  listingType,
  isLoading,
  tableType,
  className,
  isActionTable,
  actions,
  isExchangeStatsVisible,
  refetch,
  isOwner,
  ...props
}) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return (
      <PatronsExchangesTable
        listingTranslationKey={listingTranslationKey}
        isUserPage={isUserPage}
        exchanges={exchanges}
        listingType={listingType}
        isLoading={isLoading}
        tableType={tableType}
        className={className}
        isActionTable={isActionTable}
        actions={actions}
        isExchangeStatsVisible={isExchangeStatsVisible}
        refetch={refetch}
        isOwner={isOwner}
        {...props}
      />
    );
  } else {
    return (
      <FansincExchangesTable
        listingTranslationKey={listingTranslationKey}
        isUserPage={isUserPage}
        exchanges={exchanges}
        listingType={listingType}
        isLoading={isLoading}
        tableType={tableType}
        className={className}
        isActionTable={isActionTable}
        actions={actions}
        isExchangeStatsVisible={isExchangeStatsVisible}
        refetch={refetch}
        isOwner={isOwner}
        {...props}
      />
    );
  }
};

export default ExchangesTable;
