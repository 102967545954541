import { PropTypes } from 'prop-types';
import { Box, Text, EditionBadge } from '@components';
import { TIER_TYPES } from '@libs/utils/release';

const SerialItem = ({ listing, badge, children, squareBadge, ...props }) => {
  return (
    <Box className="flex items-center serial-item">
      {badge && <EditionBadge tier={listing} showEdition white size="base" square={squareBadge} />}
      {listing?.tierType === TIER_TYPES.NUMBERED_EDITION ? (
        <Text as="span">#{listing.serialIndex}</Text>
      ) : null}
    </Box>
  );
};

SerialItem.propTypes = {
  badge: PropTypes.bool,
  squareBadge: PropTypes.bool
};

SerialItem.defaultProps = {
  badge: true,
  squareBadge: false
};

export default SerialItem;
