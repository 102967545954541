import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useAuth } from '@libs/contexts/auth';
import { BsArrowRightShort } from 'react-icons/bs';
import { ReactComponent as CrossIcon } from '@assets/svg/cross-megamenu.svg';
import { useOutsider } from '@libs/utils/outsider';
import ReactTooltip from 'react-tooltip';
import { motion } from 'framer-motion/dist/framer-motion';
import {
  Button,
  Box,
  Container,
  Link,
  Logo,
  NotificationMenu,
  UserMenu,
  Avatar,
  ArtistProfile,
  Image
} from '@components';
import { useCreators } from '@libs/hooks/user';
import { AiOutlineMenu } from 'react-icons/ai';

const PatronsHeader = ({ galleryLogo }) => {
  const { t } = useTranslation();
  const { isAuthenticated, user } = useAuth();
  const { path } = useRouteMatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isHoverGalleries, setIsHoverGalleries] = useState(false);
  const [isHoverExplore, setIsHoverExplore] = useState(false);
  const [isHoverArtists, setIsHoverArtists] = useState(false);
  const [isHoverCollectors, setIsHoverCollectors] = useState(false);
  const [isHoverFaq, setIsHoverFaq] = useState(false);
  const handleClose = () => setIsOpen(false);
  const { ref } = useOutsider(handleClose);
  const { data, loading } = useCreators();
  const isActive = (menuPath) => {
    return menuPath === path ? 'active' : '';
  };
  const [isMenuActive, setIsMenuActive] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  });

  const isSticky = (e) => {
    const header = document.querySelector('.header');
    const scrollTop = window.scrollY;
    if (scrollTop >= 200) {
      header?.classList?.remove('header--scroll');
      header?.classList?.add('header--fixed');
    } else {
      header?.classList?.remove('header--fixed');
      header?.classList?.add('header--scroll');
    }
  };

  const subDotAnimate = {
    enter: {
      opacity: 1,
      rotateX: 0,
      transition: {
        duration: 0.4
      },
      display: 'block'
    },
    exit: {
      opacity: 0,
      rotateX: 0,
      transition: {
        duration: 0.4
      },
      transitionEnd: {
        display: 'none'
      }
    }
  };

  const subMenuAnimate = {
    enter: {
      opacity: 1,
      rotateX: 0,
      transition: {
        duration: 0.4
      },
      display: 'block'
    },
    exit: {
      opacity: 0,
      rotateX: 50,
      transition: {
        duration: 0.4
      },
      transitionEnd: {
        display: 'none'
      }
    }
  };

  const Artists = ({ creator }) => {
    return (
      <Box className="header__container__middle__menu__dropdown__list__artists">
        <Box>
          <Avatar user={creator} size="xs-40" />
        </Box>
        <Box className="header__container__middle__menu__dropdown__list__artists__detail">
          <Box className="header__container__middle__menu__dropdown__list__artists__detail__name">
            {creator?.displayName || creator?.firstName + ' ' + creator?.lastName}
          </Box>
          <Box className="header__container__middle__menu__dropdown__list__artists__detail__title">
            {t('p.components.header.legendaryArtists')}
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <header className={cx('header')}>
      <Container className="header__container">
        <Box className="header__container__left">
          <Box className="header__logo">
            <Link to="/home">
              <Logo height="36" />
            </Link>
            {(path === '/collection/:slug' ||
              path === '/:username/:slug' ||
              path === '/:username/:slug/:edition/:owner?') &&
              galleryLogo?.logo && (
                <Box className="header__logo__gallery">
                  {galleryLogo?.isShowLogo ? (
                    <Image
                      data-tip
                      data-for="minimumTooltip"
                      className="h-9"
                      size="full"
                      src={galleryLogo?.logo}
                    />
                  ) : (
                    <Box
                      data-tip
                      data-for="minimumTooltip"
                      className="header__logo__gallery__text cursor-pointer"
                    >
                      {galleryLogo?.logo}
                    </Box>
                  )}
                  <ReactTooltip id="minimumTooltip" place="right" type="dark">
                    <Box className="normal-case max-w-xs">{galleryLogo?.name}</Box>
                  </ReactTooltip>
                </Box>
              )}
          </Box>
        </Box>
        <Box className="header__container__middle">
          <Link to="/marketplace">
            <motion.div
              onHoverStart={() => setIsHoverExplore(!isHoverExplore)}
              onHoverEnd={() => setIsHoverExplore(!isHoverExplore)}
              className="header__container__middle__menu"
            >
              <Box>{t('p.components.header.explore')}</Box>
              <motion.div
                initial="exit"
                animate={path === '/marketplace' || isHoverExplore ? 'enter' : 'exit'}
                variants={subDotAnimate}
                className="dot"
              ></motion.div>
            </motion.div>
          </Link>
          <Box className="header__container__middle__menu">
            <Link to="/artists">
              <motion.div
                onHoverStart={() => setIsHoverArtists(!isHoverArtists)}
                onHoverEnd={() => setIsHoverArtists(!isHoverArtists)}
                className="header__container__middle__menu"
              >
                <Box>{t('p.components.header.artists')}</Box>
                <motion.div
                  initial="exit"
                  animate={path === '/artists' || isHoverArtists || isOpen ? 'enter' : 'exit'}
                  variants={subDotAnimate}
                  className="dot"
                ></motion.div>
              </motion.div>
            </Link>
            {/* <motion.div
              onHoverStart={() => setIsHoverArtists(!isHoverArtists)}
              onHoverEnd={() => setIsHoverArtists(!isHoverArtists)}
              ref={ref}
              onClick={() => setIsOpen(!isOpen)}
            >
              {t('p.components.header.artists')}
            </motion.div>
            <motion.div
              initial="exit"
              animate={path === '/artists' || isHoverArtists || isOpen ? 'enter' : 'exit'}
              variants={subDotAnimate}
              className="dot"
            ></motion.div>
            <>
              <motion.div
                initial="exit"
                animate={isOpen ? 'enter' : 'exit'}
                variants={subMenuAnimate}
              >
                <Box className="header__container__middle__menu__dropdown">
                  <Box className="header__container__middle__menu__dropdown__list">
                    {data?.map((creator, index) => (
                      <Link key={index} to={`/${creator?.username}`}>
                        <ArtistProfile
                          user={creator}
                          bottomDescription={t('p.components.header.legendaryArtists')}
                          size="md"
                        />
                      </Link>
                    ))}
                  </Box>
                  <Link to="/artists">
                    <Box className="header__container__middle__menu__dropdown__viewall">
                      {t('p.components.header.viewAllArtists')} <BsArrowRightShort size={30} />
                    </Box>
                  </Link>
                </Box>
              </motion.div>
            </> */}
          </Box>
          <Link to="/galleries">
            <motion.div
              onHoverStart={() => setIsHoverGalleries(!isHoverGalleries)}
              onHoverEnd={() => setIsHoverGalleries(!isHoverGalleries)}
              className="header__container__middle__menu"
            >
              <Box>{t('p.components.header.galleries')}</Box>
              <motion.div
                initial="exit"
                animate={path === '/galleries' || isHoverGalleries ? 'enter' : 'exit'}
                variants={subDotAnimate}
                className="dot"
              ></motion.div>
            </motion.div>
          </Link>
          <Link to="/collectors">
            <motion.div
              onHoverStart={() => setIsHoverCollectors(!isHoverCollectors)}
              onHoverEnd={() => setIsHoverCollectors(!isHoverCollectors)}
              className="header__container__middle__menu"
            >
              <Box>{t('p.components.header.collectors')}</Box>
              <motion.div
                initial="exit"
                animate={path === '/collectors' || isHoverCollectors ? 'enter' : 'exit'}
                variants={subDotAnimate}
                className="dot"
              ></motion.div>
            </motion.div>
          </Link>
          <Link to="/faq">
            <motion.div
              onHoverStart={() => setIsHoverFaq(!isHoverFaq)}
              onHoverEnd={() => setIsHoverFaq(!isHoverFaq)}
              className="header__container__middle__menu"
            >
              <Box>{t('p.components.header.faq')}</Box>
              <motion.div
                initial="exit"
                animate={path === '/faq' || isHoverFaq ? 'enter' : 'exit'}
                variants={subDotAnimate}
                className="dot"
              ></motion.div>
            </motion.div>
          </Link>
        </Box>
        <Box className="header__container__right">
          {!isAuthenticated ? (
            <>
              <Link to="/login">
                <Button
                  darkOutline
                  size="xs"
                  className="header__container__right__login text-sm md:text-xs"
                >
                  {t('p.components.header.signin')}
                </Button>
              </Link>
              <Link to="/signup">
                <Button size="xs" className="header__container__right__login hidden md:block">
                  {t('p.components.header.signUp')}
                </Button>
              </Link>
              <span
                className="ml-4 cursor-pointer"
                onClick={() => {
                  setIsMenuActive(true);
                  document.body.style.overflowY = 'hidden';
                }}
              >
                <AiOutlineMenu size={25} color="rgba(43, 43, 43, 1)" />
              </span>
            </>
          ) : (
            <>
              <NotificationMenu className="header__container__right__notification" />
              <UserMenu size="md" />
              <span
                className="ml-4 cursor-pointer"
                onClick={() => {
                  setIsMenuActive(true);
                  document.body.style.overflowY = 'hidden';
                }}
              >
                <AiOutlineMenu size={25} color="rgba(43, 43, 43, 1)" />
              </span>
            </>
          )}
        </Box>
      </Container>
      <Box className={cx(isMenuActive ? 'mega-menu-active' : 'mega-menu')}>
        <Box className="mega-menu-active__top">
          <CrossIcon
            onClick={() => {
              setIsMenuActive(false);
              document.body.style.overflowY = 'auto';
            }}
            className="ml-auto cursor-pointer"
          />
        </Box>
        <Box className="mega-menu-active__middle">
          <Box className="mega-menu-active__middle__wrapper">
            <Box className="mega-menu-active__middle__wrapper__mobile">
              <div
                onClick={() => {
                  setIsMenuActive(false);
                  document.body.style.overflowY = 'auto';
                }}
              >
                <Link to="/marketplace">
                  <Box className="mega-menu-active__middle__wrapper__mobile__link">
                    {t('p.components.header.explore')}
                  </Box>
                </Link>
              </div>
              <div
                onClick={() => {
                  setIsMenuActive(false);
                  document.body.style.overflowY = 'auto';
                }}
              >
                <Link to="/artists">
                  {' '}
                  <Box className="mega-menu-active__middle__wrapper__mobile__link">
                    {t('p.components.header.artists')}
                  </Box>
                </Link>
              </div>
              <div
                onClick={() => {
                  setIsMenuActive(false);
                  document.body.style.overflowY = 'auto';
                }}
              >
                <Link to="/galleries">
                  <Box className="mega-menu-active__middle__wrapper__mobile__link">
                    {t('p.components.header.galleries')}
                  </Box>
                </Link>
              </div>
              <div
                onClick={() => {
                  setIsMenuActive(false);
                  document.body.style.overflowY = 'auto';
                }}
              >
                <Link to="/collectors">
                  <Box className="mega-menu-active__middle__wrapper__mobile__link">
                    {t('p.components.header.collectors')}
                  </Box>
                </Link>
              </div>
              <div
                onClick={() => {
                  setIsMenuActive(false);
                  document.body.style.overflowY = 'auto';
                }}
              >
                <Link to="/faq">
                  <Box className="mega-menu-active__middle__wrapper__mobile__link">
                    {t('p.components.header.faq')}
                  </Box>
                </Link>
              </div>
            </Box>
            <div
              onClick={() => {
                setIsMenuActive(false);
                document.body.style.overflowY = 'auto';
              }}
            >
              <Link to="/about">
                <Box className="mega-menu-active__middle__wrapper__link">
                  {t('p.components.header.aboutUs')}
                </Box>
              </Link>
            </div>
            <div
              onClick={() => {
                setIsMenuActive(false);
                document.body.style.overflowY = 'auto';
              }}
            >
              <Link to="/how-it-works">
                {' '}
                <Box className="mega-menu-active__middle__wrapper__link">
                  {t('p.components.header.howItWorks')}
                </Box>
              </Link>
            </div>
            <div
              onClick={() => {
                setIsMenuActive(false);
                document.body.style.overflowY = 'auto';
              }}
            >
              <Link to="/contact">
                <Box className="mega-menu-active__middle__wrapper__link">
                  {t('p.components.header.contactUs')}
                </Box>
              </Link>
            </div>
          </Box>
        </Box>
        <Box className="mega-menu-active__bottom">
          <button
            onClick={() => {
              setIsMenuActive(false);
              document.body.style.overflowY = 'auto';
            }}
          >
            <Link to="/privacy-policy">
              <Box className="mega-menu-active__bottom__link">
                {t('p.components.header.privacyPolicy')}
              </Box>
            </Link>
          </button>
          <button
            onClick={() => {
              setIsMenuActive(false);
              document.body.style.overflowY = 'auto';
            }}
          >
            <Link to="/terms-and-conditions">
              <Box className="mega-menu-active__bottom__link ml-7">
                {t('p.components.header.termsConditions')}
              </Box>
            </Link>
          </button>
        </Box>
      </Box>
      <Box
        className={cx(isMenuActive ? 'block' : 'hidden', 'mega-menu-wrapper')}
        onClick={() => {
          setIsMenuActive(false);
          document.body.style.overflowY = 'auto';
        }}
      ></Box>
    </header>
  );
};

export default PatronsHeader;
