import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';

import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormField,
  Heading,
  Input,
  Link,
  SocialLogin,
  Text
} from '@components';

import { useAuth } from '@libs/contexts/auth';

function Login({ defaultEmail, redirect, onSuccess, title, showSocialLogin = true, signupUrl }) {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    setFocus
  } = useForm({
    mode: 'onBlur'
  });

  const { resetMessage, loading: formLoading, error, success, authenticate } = useAuth();

  useEffect(() => {
    setFocus('email');
    resetMessage('error');
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (values) => {
    await authenticate(values, redirect, onSuccess);
    resetField('password');
  };

  return (
    <div className="auth">
      {title ? (
        title
      ) : (
        <Heading as="h3" className="auth__title">
          {t('auth.login.heading')}
        </Heading>
      )}
      {showSocialLogin && (
        <>
          <SocialLogin />
          <Text className="auth-or">{t('auth.OR')}</Text>
        </>
      )}
      <div className="auth__form">
        {error && (
          <Alert danger className="mb-4">
            {error === 'User does not exist.' ? (
              <Trans i18nKey="auth.alert.notExist">
                <Link to={signupUrl ? signupUrl : '/signup'}></Link>
              </Trans>
            ) : (
              error
            )}
          </Alert>
        )}
        {success && (
          <Alert success className="mb-4">
            {success}
          </Alert>
        )}
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <FormField>
            <Input
              fullWidth
              label={t('auth.fields.email.label')}
              as="email"
              size="md"
              defaultValue={defaultEmail}
              errorMessage={errors.email?.message}
              {...register('email', {
                required: { value: true, message: t('auth.fields.email.required') },
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: t('auth.fields.email.invalid')
                }
              })}
            />
          </FormField>
          <FormField>
            <Input
              fullWidth
              label={t('auth.fields.password.label')}
              as="password"
              size="md"
              errorMessage={errors.password?.message}
              {...register('password', {
                required: { value: true, message: t('auth.fields.password.required') }
              })}
            />
          </FormField>

          <Box className="mb-5 auth__checkbox-remember">
            <Checkbox type="checkbox" {...register('rememberMe')}>
              {t('auth.login.rememberMe')}
            </Checkbox>
            <Link className="text-xs" to="/forgot-password">
              {t('auth.login.forgotPassword')}
            </Link>
          </Box>

          <div className="mb-4">
            <Button
              primary
              fullWidth
              id="login-button"
              as="submit"
              disabled={formLoading}
              loading={formLoading}
            >
              {t('auth.login.submit')}
            </Button>
          </div>
        </form>
      </div>
      <div className="auth__register">
        <Text center className="mt-4 text-xs">
          <Trans i18nKey="auth.login.noAccount">
            <Link to={signupUrl ? signupUrl : '/signup'}></Link>
          </Trans>
        </Text>
      </div>
    </div>
  );
}

Login.defaultProps = {
  onSuccess: () => {},
  defaultEmail: ''
};

export default Login;
