import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, Container, RelatedProfileGrid, RelatedProfileCard, Text } from '@components';

import { useUserByUsername, useRelatedCreators } from '@libs/hooks/user';

function RelatedProfile() {
  const params = useParams();
  const username = params.username.toLowerCase();
  const { t } = useTranslation();
  const { data: profile } = useUserByUsername(username);
  const { data: relatedProfiles, loading: relatedProfilesLoading } = useRelatedCreators(
    username,
    12
  );
  const title = () => {
    if (profile) {
      if (profile?.firstName && profile?.lastName) {
        return profile?.firstName + ' ' + profile?.lastName;
      } else {
        return profile?.username;
      }
    }
  };
  return (
    <>
      <Box className="related-profile">
        <Container size="xl" className="py-11">
          <Text className="use-h6 related-profile__title">
            {t('relatedProfile.fansAlsoViewed')}
          </Text>
          <RelatedProfileGrid loading={relatedProfilesLoading}>
            {relatedProfiles &&
              relatedProfiles?.map((creator) => (
                <RelatedProfileCard
                  key={creator.id}
                  user={creator}
                  className="creator-card--item"
                  loading={relatedProfilesLoading.toString()}
                />
              ))}
          </RelatedProfileGrid>
        </Container>
      </Box>
      <Helmet>
        <title>{title()}</title>
        <meta property="og:image" content="https://cdn.fans.inc/public/webapp/og-image.png" />
      </Helmet>
    </>
  );
}

export default RelatedProfile;
