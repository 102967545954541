import { useSearchCollectiblesByOwnerAndCategory } from '@libs/hooks/collectible';
import {
  CollectibleCard,
  CollectibleGrid,
  Box,
  Button,
  Link,
  Text,
  Container,
  SkeletonLoading
} from '@components';
import { useTranslation } from 'react-i18next';

const CollectibleGroup = ({ username, user, ...props }) => {
  const { t } = useTranslation();

  const { data: songCollectibles, loading: songCollectiblesLoading } =
    useSearchCollectiblesByOwnerAndCategory(user, 'SONG');

  const { data: videoCollectibles, loading: videoCollectiblesLoading } =
    useSearchCollectiblesByOwnerAndCategory(user, 'VIDEO');

  const { data: lyricsCollectibles, loading: lyricsCollectiblesLoading } =
    useSearchCollectiblesByOwnerAndCategory(user, 'SONG_LYRICS');

  const { data: photoCollectibles, loading: photoCollectiblesLoading } =
    useSearchCollectiblesByOwnerAndCategory(user, 'PHOTOGRAPH');

  const { data: artworkCollectibles, loading: artworkCollectiblesLoading } =
    useSearchCollectiblesByOwnerAndCategory(user, 'ARTWORK');

  const { data: posterCollectibles, loading: posterCollectiblesLoading } =
    useSearchCollectiblesByOwnerAndCategory(user, 'FILM_POSTER');

  const { data: vipCollectibles, loading: vipCollectiblesLoading } =
    useSearchCollectiblesByOwnerAndCategory(user, 'VIP_PASS');

  const profileCollectibleLink = `/${username}/collectibles`;

  return (
    <>
      {songCollectiblesLoading ? (
        <Container size="xl">
          <Box>
            <SkeletonLoading className="w-44 h-8" />
          </Box>
          <CollectibleGrid loading={songCollectiblesLoading} />
        </Container>
      ) : null}
      {songCollectibles.length > 0 && (
        <Container size="xl">
          <Box className="flex items-center justify-between">
            <Text className="use-h6 text-3xl">{t('profile.collectibles.category.songs')}</Text>
            <Link to={profileCollectibleLink}>
              <Button outline>{t('components.collectibleGrid.viewAll')}</Button>
            </Link>
          </Box>
          <CollectibleGrid
            loading={songCollectiblesLoading}
            showEmpty
            profile
            infiniteScroll={false}
          >
            {songCollectibles &&
              songCollectibles.map((collectible) => (
                <CollectibleCard
                  key={`songs-${collectible.id}`}
                  collectible={collectible}
                ></CollectibleCard>
              ))}
          </CollectibleGrid>
        </Container>
      )}
      {videoCollectibles.length > 0 && (
        <Container size="xl">
          <Box className="flex items-center justify-between">
            <Text className="use-h6 text-3xl">{t('profile.collectibles.category.videos')}</Text>
            <Link to={profileCollectibleLink}>
              <Button outline>{t('components.collectibleGrid.viewAll')}</Button>
            </Link>
          </Box>
          <CollectibleGrid
            loading={videoCollectiblesLoading}
            showEmpty
            profile
            infiniteScroll={false}
          >
            {videoCollectibles &&
              videoCollectibles.map((collectible) => (
                <CollectibleCard
                  key={`videos-${collectible.id}`}
                  collectible={collectible}
                ></CollectibleCard>
              ))}
          </CollectibleGrid>
        </Container>
      )}
      {lyricsCollectibles.length > 0 && (
        <Container className="pt-14" size="xl">
          <Box className="flex items-center justify-between">
            <Text className="use-h6 text-3xl">{t('profile.collectibles.category.lyrics')}</Text>
            <Link to={profileCollectibleLink}>
              <Button outline>{t('components.collectibleGrid.viewAll')}</Button>
            </Link>
          </Box>
          <CollectibleGrid
            loading={lyricsCollectiblesLoading}
            showEmpty
            profile
            infiniteScroll={false}
          >
            {lyricsCollectibles &&
              lyricsCollectibles.map((collectible) => (
                <CollectibleCard
                  key={`lyrics-${collectible.id}`}
                  collectible={collectible}
                ></CollectibleCard>
              ))}
          </CollectibleGrid>
        </Container>
      )}

      {photoCollectibles.length > 0 && (
        <Container className="pt-14" size="xl">
          <Box className="flex items-center justify-between">
            <Text className="use-h6 text-3xl">
              {t('profile.collectibles.category.photographs')}
            </Text>
            <Link to={profileCollectibleLink}>
              <Button outline>{t('components.collectibleGrid.viewAll')}</Button>
            </Link>
          </Box>
          <CollectibleGrid
            loading={photoCollectiblesLoading}
            showEmpty
            profile
            infiniteScroll={false}
          >
            {photoCollectibles &&
              photoCollectibles.map((collectible) => (
                <CollectibleCard
                  key={`photographs-${collectible.id}`}
                  collectible={collectible}
                ></CollectibleCard>
              ))}
          </CollectibleGrid>
        </Container>
      )}

      {artworkCollectibles.length > 0 && (
        <Container className="pt-14" size="xl">
          <Box className="flex items-center justify-between">
            <Text className="use-h6 text-3xl">{t('profile.collectibles.category.artworks')}</Text>
            <Link to={profileCollectibleLink}>
              <Button outline>{t('components.collectibleGrid.viewAll')}</Button>
            </Link>
          </Box>
          <CollectibleGrid
            loading={artworkCollectiblesLoading}
            showEmpty
            profile
            infiniteScroll={false}
          >
            {artworkCollectibles &&
              artworkCollectibles.map((collectible) => (
                <CollectibleCard
                  key={`artworks-${collectible.id}`}
                  collectible={collectible}
                ></CollectibleCard>
              ))}
          </CollectibleGrid>
        </Container>
      )}

      {posterCollectibles.length > 0 && (
        <Container className="pt-14" size="xl">
          <Box className="flex items-center justify-between">
            <Text className="use-h6 text-3xl">{t('profile.collectibles.category.posters')}</Text>
            <Link to={profileCollectibleLink}>
              <Button outline>{t('components.collectibleGrid.viewAll')}</Button>
            </Link>
          </Box>
          <CollectibleGrid
            loading={posterCollectiblesLoading}
            showEmpty
            profile
            infiniteScroll={false}
          >
            {posterCollectibles &&
              posterCollectibles.map((collectible) => (
                <CollectibleCard
                  key={`posters-${collectible.id}`}
                  collectible={collectible}
                ></CollectibleCard>
              ))}
          </CollectibleGrid>
        </Container>
      )}

      {vipCollectibles.length > 0 && (
        <Container className="pt-14 pb-8" size="xl">
          <Box className="flex items-center justify-between">
            <Text className="use-h6 text-3xl">{t('profile.collectibles.category.vip')}</Text>
          </Box>
          <CollectibleGrid
            loading={vipCollectiblesLoading}
            showEmpty
            profile
            infiniteScroll={false}
          >
            {vipCollectibles &&
              vipCollectibles.map((collectible) => (
                <CollectibleCard
                  key={`vip-${collectible.id}`}
                  collectible={collectible}
                ></CollectibleCard>
              ))}
          </CollectibleGrid>
        </Container>
      )}
    </>
  );
};

export default CollectibleGroup;
