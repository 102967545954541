const reservedWords = [
  'login',
  'signup',
  'marketplace',
  'releases',
  'activate-user',
  'forgot-password',
  'reset-password',
  'settings',
  'notifications',
  'contact',
  'faq',
  'how-it-works',
  'privacy-policy',
  'terms-and-conditions',
  'checkout',
  'print',
  'mint',
  'flex',
  'sell',
  'beta-features',
  '404',
  'unsubscribe',
  'confirmation',
  'congratulation',
  'print',
  'po-confirmation',
  'po-congratulation',
  'bitpay-payment'
];

const checkWord = (e) => reservedWords.includes(e);
export default checkWord;
