import cx from 'classnames';
import { useState, useCallback, useEffect } from 'react';
import { Box, Container, CardSlider, Separator, Text, Link, Button } from '@components';
import { useOutsider } from '@libs/utils/outsider';
import { ReactComponent as ArrowDownIcon } from '@assets/svg/arrow-down.svg';
import { ReactComponent as ArrowRightBlackIcon } from '@assets/svg/arrow-right-black.svg';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { genres } from '@libs/utils/options';
import useQuery from '@libs/utils/query';
import { useMarketplace } from '@libs/hooks/marketplace';
import { useFeaturedArtist } from '@libs/hooks/featured-artist';

function ExploreCollectibles() {
  const { t } = useTranslation();
  const { activeOptions: artists } = useFeaturedArtist();
  const [count, setCount] = useState(0);
  const query = useQuery();
  const history = useHistory();
  const { data: collectibles, loading, onChangeFilter } = useMarketplace(10);

  //FILTER STYLE
  const listStyle = [
    {
      label: 'ALL',
      value: ''
    },
    ...genres
  ];
  const [isOpenStyle, setOpenStyle] = useState(false);
  const [styleName, setStyleName] = useState('All');
  const handleCloseStyle = () => setOpenStyle(false);
  const { ref: refStyle } = useOutsider(handleCloseStyle);
  const [queryCount, setQueryCount] = useState(0);
  const [style, setStyle] = useState('');
  const handleOpenStyle = useCallback(
    (e) => {
      e.preventDefault();
      setOpenStyle(!isOpenStyle);
    },
    [isOpenStyle]
  );

  //FILTER ARTIST
  const listArtist = [
    {
      label: 'ALL',
      value: ''
    },
    ...artists
  ];
  const [isOpenArtist, setOpenArtist] = useState(false);
  const [artistName, setArtistName] = useState('All');
  const handleCloseArtist = () => setOpenArtist(false);
  const { ref: refArtist } = useOutsider(handleCloseArtist);
  const [artist, setArtist] = useState('');
  const handleOpenArtist = useCallback(
    (e) => {
      e.preventDefault();
      setOpenArtist(!isOpenArtist);
    },
    [isOpenArtist]
  );

  useEffect(() => {
    if (count !== 0) {
      history.replace(`?style=${style}&artist=${artist}`);
    } else {
      setCount(count + 1);
    }
  }, [style, artist]);

  useEffect(() => {
    const title = query.get('title');
    const category = query.get('category');
    const genre = query.get('style');
    const era = query.get('era');
    const artist = query.get('artist');
    const sort = query.get('sort');
    const price_from = query.get('price_from');
    const price_to = query.get('price_to');

    const currentCategories = category ? category.split(',') : [];
    const currentGenre = genre ? genre.split(',') : [];
    const currentEra = era ? era.split(',') : [];
    const currentArtist = artist ? artist.split(',') : [];

    if (
      queryCount > 0 ||
      (title && title.length > 0) ||
      currentCategories.length > 0 ||
      currentGenre.length > 0 ||
      currentEra.length > 0 ||
      (sort && sort.length > 0)
    ) {
      onChangeFilter(
        title,
        currentCategories,
        currentGenre,
        currentEra,
        currentArtist,
        sort,
        price_from,
        price_to
      );
    } else {
      setQueryCount(queryCount + 1);
    }
  }, [query]);
  return (
    <>
      <Box className="landing-page__explore">
        <Container className="landing-page__explore__container">
          <Box className="landing-page__explore__container__body">
            <Box className="landing-page__explore__container__body__top">
              <Box className="landing-page__explore__container__body__top__title">
                {t('p.pages.landing.explore.title')}
              </Box>
              <Box className="landing-page__explore__container__body__top__filter">
                <Box className="marketplace-page_search">
                  <Box className="marketplace-page__search-sorting">
                    <Text
                      className="marketplace-page__search-sorting-header"
                      onClick={handleOpenStyle}
                    >
                      <Text as="span" className="marketplace-page__search-sorting-header__type">
                        {t('p.pages.marketplace.style')}
                      </Text>
                      <Text as="span" className="marketplace-page__search-sorting-header__item">
                        {styleName}
                        <ArrowDownIcon />
                      </Text>
                    </Text>
                    {isOpenStyle && (
                      <Box className="select-multiple__dropdown overflow-hidden" ref={refStyle}>
                        <ul>
                          {genres &&
                            listStyle &&
                            listStyle.map((s) => (
                              <>
                                <li
                                  className={cx(style === s.value && 'active')}
                                  key={s.value}
                                  value={s.value}
                                  onClick={() => {
                                    setStyle(s.value);
                                    setStyleName(s.label);
                                    handleCloseStyle();
                                  }}
                                >
                                  {s.label}
                                </li>
                                <Separator />
                              </>
                            ))}
                        </ul>
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box className="marketplace-page_search">
                  <Box className="marketplace-page__search-sorting">
                    <Text
                      className="marketplace-page__search-sorting-header"
                      onClick={handleOpenArtist}
                    >
                      <Text as="span" className="marketplace-page__search-sorting-header__type">
                        {t('p.pages.marketplace.artist')}
                      </Text>
                      <Text as="span" className="marketplace-page__search-sorting-header__item">
                        {artistName}
                        <ArrowDownIcon />
                      </Text>
                    </Text>
                    {isOpenArtist && (
                      <Box className="select-multiple__dropdown overflow-hidden" ref={refArtist}>
                        <ul>
                          {artists &&
                            listArtist &&
                            listArtist.map((a) => (
                              <>
                                <li
                                  className={cx(artist === a.value && 'active')}
                                  key={a.value}
                                  value={a.value}
                                  onClick={() => {
                                    setArtist(a.value);
                                    setArtistName(a.label);
                                    handleCloseArtist();
                                  }}
                                >
                                  {a.label}
                                </li>
                                <Separator />
                              </>
                            ))}
                        </ul>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
              <Link className="landing-page__explore__container__body__top__link" to="/marketplace">
                <Box className="landing-page__explore__container__body__top__explore">
                  <Box className="landing-page__explore__container__body__top__explore__text pr-2">
                    {t('p.pages.landing.explore.exploreMore')}
                  </Box>
                  <ArrowRightBlackIcon />
                </Box>
              </Link>
            </Box>
            <CardSlider collectibles={collectibles} loading={loading} />
            <Link to="/marketplace">
              <Box className="landing-page__explore__container__body__bottom">
                <Button>{t('p.pages.landing.explore.exploreMore')}</Button>
              </Box>
            </Link>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default ExploreCollectibles;
