import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import Artwork from '@assets/svg/official/artwork.svg';
import Photograph from '@assets/svg/official/photograph.svg';
import Song from '@assets/svg/official/song.svg';
import SongLyric from '@assets/svg/official/song_lyric.svg';
import Video from '@assets/svg/official/video.svg';
import VipPass from '@assets/svg/official/vip_pass.svg';
import Other from '@assets/svg/official/other.svg';

const OfficialBadge = ({ type, className, isImage }) => {
  const { t } = useTranslation();

  const mapType = {
    ARTWORK: 'Artwork',
    PHOTOGRAPH: 'Photograph',
    FILM_POSTER: 'Film poster',
    SONG: 'Song',
    SONG_LYRICS: 'Song lyrics',
    VIDEO: 'Video',
    VIP_PASS: 'VIP Pass',
    OTHER: ''
  };

  const mapImageType = {
    ARTWORK: Artwork,
    PHOTOGRAPH: Photograph,
    SONG: Song,
    SONG_LYRICS: SongLyric,
    VIDEO: Video,
    VIP_PASS: VipPass,
    OTHER: Other
  };

  return (
    <>
      {isImage ? (
        <div className={cx('official-badge-image', className)}>
          <img src={mapImageType[type]} alt={mapType[type]} />
        </div>
      ) : (
        <div className={cx('official-badge', className)}>
          {t('components.officialBadge.label', { type: mapType[type] })}
        </div>
      )}
    </>
  );
};

OfficialBadge.defaultProps = {
  type: 'OTHER',
  isImage: false
};

export default OfficialBadge;
