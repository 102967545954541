import { RiTruckLine } from 'react-icons/ri';
import { FaShippingFast } from 'react-icons/fa';

import { Box, Text } from '@components';
import { Price } from '@components/';

export const itemTypes = {
  COLLECTIBLE: 'collectible',
  EXCHANGE: 'exchange'
};

export const sizeKeys = { A_TWO: 'A_TWO', A_ONE: 'A_ONE' };
export const frameKeys = { BLACK: 'BLACK', WHITE: 'WHITE' };
export const shippingKeys = { REGULAR: 'REGULAR', EXPRESS: 'EXPRESS' };

export const sizePrices = { A_TWO: 89, A_ONE: 119 };
export const framePrices = { NO_FRAME: 0, BLACK: 70, WHITE: 70 };
export const shippingPrices = { REGULAR: 0, EXPRESS: 48 };

// export const sizes = { A_TWO: '40x60cm / 16x24"', A_ONE: '60x80cm / 24x32"' };
export const framedSizes = { FRAMED_SMALL: '40cm x 60cm', FRAMED_BIG: '60cm x 80cm' };
export const skuFrame = {
  A4: 'FRA-BOX-LPP-MOUNT1-GLA-20X30',
  SMALL: 'FRA-BOX-LPP-MOUNT1-GLA-40X60',
  SMALLSQUARE: 'FRA-BOX-LPP-MOUNT1-GLA-60X60',
  BIG: 'FRA-BOX-LPP-MOUNT1-GLA-60X80',
  BIGSQUARE: 'FRA-BOX-LPP-MOUNT1-GLA-90X90'
};
export const skuFrameTest = {
  SMALL: 'GLOBAL-CAN-16X20',
  BIG: 'GLOBAL-CAN-24X32',
  FRAMED_SMALL: 'FRA-BOX-HGE-MOUNT2-GLA-A2',
  FRAMED_BIG: 'FRA-BOX-LPP-MOUNT1-GLA-60x80'
};
export const colorProdigiKeys = { NO_FRAME: 'White', BLACK: 'Black', WHITE: 'White' };
export const sizes = {
  A4: '20X30cm / 08X12"',
  SMALL: '40X60cm / 16X24"',
  SMALLSQUARE: '60X60cm / 24x24"',
  BIG: '60X80cm / 24X32"',
  BIGSQUARE: '90X90cm / 36x36"'
};
export const frames = { BLACK: 'Black', WHITE: 'White' };
export const shipping = {
  REGULAR: (
    <Box className="flex justify-between">
      <Box className="flex">
        <RiTruckLine className="mr-2 h-6" />
        <Text as="span">5 Days Free Shipping</Text>
      </Box>
      <Price amount={shippingPrices.REGULAR} />
    </Box>
  ),
  EXPRESS: (
    <Box className="flex justify-between">
      <Box className="flex">
        <FaShippingFast className="mr-2 h-6" />
        <Text as="span">48 Hours Express Shipping</Text>
      </Box>
      <Price amount={shippingPrices.EXPRESS} />
    </Box>
  )
};

export const imageScale = (collectibleImage, width, height) => {
  let widthFrame = width;
  let heightFrame = height;
  let widthTamp = width;
  let heightTamp = height;
  let isVertical = false;
  let imageWidth = collectibleImage ? collectibleImage.width : width;
  let imageHeight = collectibleImage ? collectibleImage.height : height;

  if (imageWidth >= imageHeight) {
    isVertical = true;
  }

  if (widthFrame <= 160 && heightFrame <= 160) {
    widthTamp = width * 2;
    heightTamp = height * 2;
  }

  if (widthFrame > 350 || heightFrame > 350) {
    widthTamp = width / 2;
    heightTamp = height / 2;
  }

  if (isVertical) {
    widthFrame = heightTamp;
    heightFrame = widthTamp;
  } else {
    widthFrame = widthTamp;
    heightFrame = heightTamp;
  }

  return {
    heightFrame,
    widthFrame,
    imageWidth,
    imageHeight
  };
};

export const isLocalhost = () => {
  if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
    return true;
  }
  return false;
};
