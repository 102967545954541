import { useState, useEffect } from 'react';

import gql from '@libs/utils/gql';
import useToast from '@libs/utils/toast';
import { searchExchangesLite } from '@libs/custom-queries/exchange';
import { EXCHANGE_TYPES, STATUS_TYPES } from '@libs/utils/exchange';

export const useCollectibleOwnership = ({ collectibleID, userID }) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [owned, setCollectibleOwnership] = useState(false);

  const fetchData = async ({ collectibleID, userID }) => {
    setLoading(true);
    try {
      const params = {
        filter: {
          collectibleID: { eq: collectibleID },
          searchableIsSold: { eq: 'FALSE' },
          currentOwnerID: { eq: userID },
          searchableStatus: { ne: STATUS_TYPES.TRANSFERRED_TO_NFT },
          searchableExchangeType: { eq: EXCHANGE_TYPES.LISTING }
        }
      };

      const { data: res } = await gql(searchExchangesLite, params);
      if (res?.searchExchanges?.total > 0) {
        setCollectibleOwnership(true);
      }
    } catch (error) {
      console.error(error);
      const errorMessage = error?.errors[0]?.message;
      toast(errorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (collectibleID && userID) fetchData({ collectibleID, userID });
  }, [collectibleID, userID]);

  return {
    loading,
    owned
  };
};

export const useExchangeOwnership = (collectibleID) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const fetchData = async ({ collectibleID, userID }) => {
    setLoading(true);
    try {
      const params = {
        filter: {
          collectibleID: { eq: collectibleID },
          searchableIsSold: { eq: 'FALSE' },
          currentOwnerID: { eq: userID },
          searchableStatus: { ne: STATUS_TYPES.TRANSFERRED_TO_NFT },
          searchableExchangeType: { eq: EXCHANGE_TYPES.LISTING }
        }
      };

      const { data: res } = await gql(searchExchangesLite, params);

      if (res?.searchExchanges?.total > 0) {
        setData(res?.searchExchanges?.items[0]);
      } else {
        setData(null);
      }
    } catch (error) {
      console.error(error);
      const errorMessage = error?.errors[0]?.message;
      toast(errorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (collectibleID) fetchData(collectibleID);
  }, [collectibleID]);

  return {
    loading,
    data
  };
};
