import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Container, Box, Price, Text } from '@components';

function ExchangesStats({ listExchange, listingTranslationKey, tableType }) {
  const { t } = useTranslation();
  const [highestExchangePrice, setHighestExchangePrice] = useState(null);
  const [averageExchangePrice, setAverageExchangePrice] = useState(null);

  useEffect(() => {
    if (listExchange && listExchange?.length > 0) {
      switch (tableType) {
        case 'saleHistory':
          {
            const exchangePrices = listExchange
              .map((exchange) => parseFloat(exchange?.startingPrice))
              .filter((price) => !isNaN(price));

            const averageExchangePrice_ =
              exchangePrices.reduce((prev, curr) => prev + curr, 0) / exchangePrices.length;
            const highestExchangePrice_ = Math.max(...exchangePrices);
            setAverageExchangePrice(averageExchangePrice_);
            setHighestExchangePrice(highestExchangePrice_);
          }
          break;
        case 'offerHistory':
          {
            const exchangePrices = listExchange
              .map((exchange) => parseFloat(exchange?.offerPrice))
              .filter((price) => !isNaN(price));

            const averageExchangePrice_ =
              exchangePrices.reduce((prev, curr) => prev + curr, 0) / exchangePrices.length;
            const highestExchangePrice_ = Math.max(...exchangePrices);
            setAverageExchangePrice(averageExchangePrice_);
            setHighestExchangePrice(highestExchangePrice_);
          }
          break;
        case 'bidHistory':
          {
            const exchangePrices = listExchange
              .map((exchange) => parseFloat(exchange?.bidPrice))
              .filter((price) => !isNaN(price));

            const averageExchangePrice_ =
              exchangePrices.reduce((prev, curr) => prev + curr, 0) / exchangePrices.length;
            const highestExchangePrice_ = Math.max(...exchangePrices);
            setAverageExchangePrice(averageExchangePrice_);
            setHighestExchangePrice(highestExchangePrice_);
          }
          break;
        default:
          break;
      }
    }
  }, [listExchange]);

  return (
    <Container size="full" className="exchange-history-table__header">
      <Box className="block sm:flex mb-8">
        {highestExchangePrice && (
          <Box className="exchange-history-table__stats">
            {t(`p.components.exchangesTable.highest${listingTranslationKey}`)}{' '}
            <Text as="span" className="exchange-history-table__stats__price">
              <Price amount={highestExchangePrice} inline />
            </Text>
          </Box>
        )}
        {averageExchangePrice && (
          <Box className="exchange-history-table__stats">
            {t(`p.components.exchangesTable.average${listingTranslationKey}`)}{' '}
            <Text as="span" className="exchange-history-table__stats__price">
              <Price amount={averageExchangePrice} inline />
            </Text>
          </Box>
        )}
      </Box>
    </Container>
  );
}

export default ExchangesStats;
