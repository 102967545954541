import { useState } from 'react';
import { Box, Avatar, Modal, Button } from '@components';
import { formatDistanceToNowStrict } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { FiMoreVertical } from 'react-icons/fi';
import cx from 'classnames';
import { useOutsider } from '@libs/utils/outsider';
import { useReplyPost } from '@libs/hooks/discourse';
import { useAuth } from '@libs/contexts/auth';

const ChildComment = ({ comment, setCommentFieldOpen, commentFieldOpen, deleteCountComment }) => {
  const { t } = useTranslation();
  const commentAuthor = comment?.user;
  const { user: userLogin } = useAuth();
  const { deleteComment, deleteLoading } = useReplyPost(comment?.id);
  const [hidden, setHidden] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleClose = () => setIsOpen(false);
  const { ref } = useOutsider(handleClose);
  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const handleDeleteComment = async () => {
    setShowDeleteModal(false);
    deleteCountComment();
    setHidden(true);
    await deleteComment(userLogin?.id);
  };
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Box className={cx(hidden && 'hidden', 'comment')}>
      <Box className="comment-left">
        <Avatar user={commentAuthor} size="xs-40" className="post-header-avatar" />
      </Box>
      <Box className="comment-right">
        <Box className="comment-right-body">
          <Box className="comment-right-body-header">
            <Box className="comment-right-body-header-name">
              {commentAuthor?.displayName ||
                commentAuthor?.firstName + ' ' + commentAuthor?.lastName}
            </Box>
            <Box className="comment-right-body-header-time">
              {comment &&
                formatDistanceToNowStrict(new Date(comment?.createdAt), { addSuffix: true })}
            </Box>
            {comment?.id && (
              <Box ref={ref} className="comment-right-body-header-tools">
                <Box className="comment-right-body-header-tools-icon">
                  <FiMoreVertical
                    onClick={() => setIsOpen(!isOpen)}
                    style={{ cursor: 'pointer' }}
                  />
                </Box>
                {isOpen && (
                  <>
                    <ul className="comment-right-body-header-tools-dropdown">
                      <li
                        onClick={() => {
                          setCommentFieldOpen(!commentFieldOpen);
                          setIsOpen(false);
                        }}
                      >
                        {t('components.post.comment.reply')}
                      </li>
                      {userLogin?.id === comment?.userID && (
                        <li
                          onClick={() => {
                            setShowDeleteModal(true);
                            setIsOpen(false);
                          }}
                        >
                          {t('components.post.comment.delete')}
                        </li>
                      )}
                    </ul>
                  </>
                )}
              </Box>
            )}
          </Box>
          <Box
            dangerouslySetInnerHTML={{
              __html: comment?.content
            }}
            className="comment-right-body-content"
          ></Box>
        </Box>
      </Box>
      <Modal
        heading={t(['components.post.comment.deleteComment'])}
        isOpen={showDeleteModal}
        onClose={() => handleCloseDeleteModal()}
      >
        {t('components.post.comment.deleteCommentDescription')}
        <Box className="post-modal-delete-button">
          <Button
            onClick={() => handleCloseDeleteModal()}
            className="post-modal-delete-button-cancel"
            outline
          >
            {t('components.post.comment.cancel')}
          </Button>
          <Button loading={deleteLoading} onClick={() => handleDeleteComment()}>
            {t('components.post.comment.delete')}
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default ChildComment;
