import FansincFooter from './fansinc';
import PatronsFooter from './patrons';

const FooterComingSoon = ({ className, ...props }) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsFooter className={className} {...props} />;
  } else {
    return <FansincFooter className={className} {...props} />;
  }
};

export default FooterComingSoon;
