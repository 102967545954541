export const paymentMapper = {
  CHECKOUT: 'Checkout.com',
  BITPAY: 'BitPay',
  PAYPAL: 'PayPal',
  'Fans.Inc account balance': 'Fans.Inc account balance'
};

export const profileMapper = {
  jenniferlopez: {
    logo: 'https://cdn.fans.inc/public/login/logo/jlo-logo.png',
    bg: 'https://cdn.fans.inc/public/login/background/jlo-bg.png'
  }
};

export const customCollectibleMapper = {
  thisismenow: {
    creator: {
      headerLogo: 'https://cdn.fans.inc/public/artists/jenniferlopez/header-logo.png',
      logo: 'https://cdn.fans.inc/public/artists/jenniferlopez/logo.png',
      hero: 'https://cdn.fans.inc/public/artists/jenniferlopez/hero-1.png',
      footer: 'https://cdn.fans.inc/public/artists/jenniferlopez/footer.png',
      openingVideo:
        'https://cdn.fans.inc/public/avatars/videos/9bafbb2f-573c-4691-9ff2-564c6a120dcd_TudoQ1L_XVoDkEq_TdyE4.mp4'
    }
  }
};

export const WITHDRAW_MAPPER = {
  PENDING: 'Pending',
  FAILED: 'Failed',
  SUCCESS: 'Success'
};
