import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Image, SkeletonLoading } from '@components';
import AvatarIcon from '@assets/svg/avatar.svg';

const FansincAvatar = ({ user, loading, size, showVerified, className, preview, ...props }) => {
  const { t } = useTranslation();

  const defaultClass = {
    avatar: true,
    'has-picture': user?.media?.avatarUrl ? true : false
  };

  const sizeClass = {
    xs: 'avatar--xs',
    'xs-40': 'avatar--xs-40',
    'sm-16': 'avatar--sm-16',
    sm: 'avatar--sm',
    md: 'avatar--md',
    lg: 'avatar--lg',
    xl: 'avatar--xl',
    full: 'avatar--full'
  };

  const imageSizeMap = {
    xs: 48,
    'xs-40': 200,
    'sm-16': 200,
    sm: 200,
    md: 200,
    lg: 400,
    xl: 400,
    full: 400
  };

  const defaultShadow = {
    'shadow-default': size === 'lg' || size === 'xl',
    'shadow-secondary':
      size === 'xs' ||
      size === 'xs-40' ||
      size === 'sm-16' ||
      size === 'sm' ||
      size === 'md' ||
      size === 'full'
  };

  return (
    <>
      <div className={cx(defaultClass, sizeClass[size], defaultShadow, className)} {...props}>
        {loading && <SkeletonLoading className="avatar__skeleton" />}
        {showVerified && user?.verified === 'TRUE' ? (
          <span className="avatar__verified">{t('components.avatar.verified_creator')}</span>
        ) : (
          <></>
        )}
        <div className="avatar__wrapper">
          {preview || user?.media?.avatarUrl ? (
            <div className="avatar__image">
              <div>
                <Image
                  size={imageSizeMap[size]}
                  src={preview || user?.media?.avatarUrl}
                  alt={user?.firstName}
                />
              </div>
            </div>
          ) : (
            <div className="avatar__initial">
              <img src={AvatarIcon} alt={user?.firstName + ' ' + user?.lastName} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

FansincAvatar.defaultProps = {
  size: 'sm',
  showVerified: false
};

export default FansincAvatar;
