import { creatorPublicFragment } from '@libs/fragments/user';

export const searchDiscoursePosts = /* GraphQL */ `
  query SearchDiscoursePosts(
    $filter: SearchableDiscoursePostFilterInput
    $sort: SearchableDiscoursePostSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchDiscoursePosts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        parentID
        createdAt
        replies {
          items {
            id
            parentPostID
            postID
            post {
              id
              likedBy
              parentID
              replies {
                items {
                  id
                  parentPostID
                  postID
                  post {
                    parentID
                    likedBy
                    content
                    createdAt
                    id
                    channelID
                    topicID
                    user {
                      ...CreatorPublicProfile
                    }
                    userID
                  }
                }
              }
              content
              user {
                ...CreatorPublicProfile
              }
              channelID
              topicID
              createdAt
              userID
            }
          }
        }
        channelID
        userID
        topicID
        content
        likedBy
        user {
          ...CreatorPublicProfile
        }
      }
      nextToken
    }
  }
  ${creatorPublicFragment}
`;
