import { useState, useEffect } from 'react';
import gql from '@libs/utils/gql';
import useToast from '@libs/utils/toast';
import { useTranslation } from 'react-i18next';
import { searchDiscoursePosts } from 'libs/custom-queries/post';

import { channelByOwnerAndCreatedAt } from 'libs/custom-queries/channel';
import {
  discourseCreatePost,
  discourseReplyPost,
  discourseReportPost,
  discourseCreateChannel,
  discourseDelete,
  discourseLikePost,
  discourseUnlikePost,
  discourseUpdate,
  collectibleCreateComment,
  collectibleReplyComment,
  collectibleLikeComment,
  collectibleUnlikeComment
} from '@graphql/mutations';

import { onDiscourseUpdated, onCommentaryUpdated } from '@graphql/subscriptions';

export const useDiscourse = ({ userID, channel }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [createPostLoading, setCreatePostLoading] = useState(false);
  const [createChannelLoading, setCreateChannelLoading] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [deleteChannelLoading, setDeleteChannelLoading] = useState(false);
  const [updateChannelLoading, setUpdateChannelLoading] = useState(false);
  const [nextPageToken, setNextPageToken] = useState();
  const [data, setData] = useState([]);

  const getData = async (nextToken, subscribeLoad = false) => {
    if (!subscribeLoad) {
      nextToken ? setPaginationLoading(true) : setLoading(true);
    }

    try {
      const params = {
        filter: {
          channelID: { eq: channel?.id },
          parentID: { exists: false },
          searchableFeatured: { ne: 'TRUE' }
        },
        limit: 10
      };
      if (nextToken) {
        params.nextToken = nextToken;
      }
      const { data: res } = await gql(searchDiscoursePosts, params);

      if (res?.searchDiscoursePosts?.items) {
        if (nextToken) {
          setData(data.concat(res?.searchDiscoursePosts?.items));
        } else {
          setData(res?.searchDiscoursePosts?.items);
        }
        setNextPageToken(res?.searchDiscoursePosts?.nextToken);
      } else {
        setNextPageToken(null);
        setData(null);
      }
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
      setPaginationLoading(false);
    }
  };

  const createPost = async (post) => {
    setCreatePostLoading(true);
    try {
      const payload = {
        input: {
          raw: post.raw,
          title: post.title,
          category: channel.id,
          userID: userID
        }
      };
      await gql(discourseCreatePost, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setCreatePostLoading(false);
    }
  };

  const createChannel = async (channel) => {
    setCreateChannelLoading(true);
    try {
      const payload = {
        input: {
          name: channel.name,
          parentCategoryId: channel.parentCategoryId,
          ownerID: channel.ownerID
        }
      };
      await gql(discourseCreateChannel, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      return true;
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setCreateChannelLoading(false);
    }
  };

  const deleteChannel = async (id) => {
    setDeleteChannelLoading(true);
    try {
      const payload = {
        input: {
          id: id,
          mode: 'CHANNEL',
          userID: userID
        }
      };
      await gql(discourseDelete, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      toast(t('discourse.successDeleteChannel'), 'success');
      return true;
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setDeleteChannelLoading(false);
    }
  };

  const updateChannel = async (data) => {
    setUpdateChannelLoading(true);
    try {
      const payload = {
        input: {
          id: data.id,
          name: data.name,
          userID: userID
        }
      };
      await gql(discourseUpdate, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      toast(t('discourse.successUpdateChannel'), 'success');
      return true;
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setUpdateChannelLoading(false);
    }
  };

  useEffect(() => {
    if (userID && channel) {
      getData();
    }
  }, [userID, channel]); // eslint-disable-line

  useEffect(() => {
    if (userID && channel) {
      const payload = {};
      const subscription = gql(onDiscourseUpdated, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      }).subscribe({
        next: ({ value: { data, errors } }) => {
          setTimeout(function () {
            getData(null, true);
          }, 1000);
        },
        error: (error) => console.warn(error)
      });

      return () => {
        if (subscription) subscription.unsubscribe();
      };
    }
  }, [userID, channel]); // eslint-disable-line

  return {
    data,
    loading,
    createPostLoading,
    createChannelLoading,
    deleteChannelLoading,
    updateChannelLoading,
    paginationLoading,
    nextPageToken,
    setLoading,
    getData,
    createPost,
    createChannel,
    deleteChannel,
    updateChannel
  };
};

export const useDiscourseFeatured = ({ userID, channel }) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const getData = async () => {
    try {
      const params = {
        filter: {
          channelID: { eq: channel?.id },
          parentID: { exists: false },
          searchableFeatured: { eq: 'TRUE' }
        }
      };
      const { data: res } = await gql(searchDiscoursePosts, params);

      if (res?.searchDiscoursePosts?.items) {
        setData(res?.searchDiscoursePosts?.items);
      } else {
        setData(null);
      }
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    }
  };

  useEffect(() => {
    async function handleGetData() {
      await getData();
    }
    if (userID && channel) {
      setLoading(true);
      handleGetData();
      setLoading(false);
    }
  }, [userID, channel]); // eslint-disable-line

  useEffect(() => {
    if (userID && channel) {
      const payload = {};
      const subscription = gql(onDiscourseUpdated, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      }).subscribe({
        next: ({ value: { data, errors } }) => {
          setTimeout(function () {
            getData();
          }, 1000);
        },
        error: (error) => console.warn(error)
      });

      return () => {
        if (subscription) subscription.unsubscribe();
      };
    }
  }, [userID, channel]); // eslint-disable-line

  return {
    data,
    loading,
    setLoading,
    getData
  };
};

export const usePost = (topicID, userID) => {
  const toast = useToast();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [likeLoading, setLikeLoading] = useState(false);
  const [reportLoading, setReportLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [data, setData] = useState();

  const getData = async () => {
    if (topicID && userID) {
      setLoading(true);
      try {
        const params = {
          filter: { id: { eq: topicID } }
        };
        const { data: res } = await gql(searchDiscoursePosts, params);

        if (res?.searchDiscoursePosts?.items[0]) {
          setData(res?.searchDiscoursePosts?.items[0]);
        } else {
          setData(null);
        }
      } catch (error) {
        const errorMessage = error?.errors
          ? error?.errors[0]?.message
          : error.message ?? 'unknown error';
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const updatePost = async (data) => {
    setUpdateLoading(true);
    try {
      const payload = {
        input: {
          id: data.id,
          raw: data.raw,
          category: data.channelID,
          userID: data.userID
        }
      };
      await gql(discourseUpdate, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      toast(t('discourse.successUpdatePost'), 'success');
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setUpdateLoading(false);
    }
  };

  const deletePost = async () => {
    setDeleteLoading(true);
    try {
      const payload = {
        input: {
          id: topicID,
          mode: 'POST',
          userID
        }
      };
      await gql(discourseDelete, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      toast(t('discourse.successDeletePost'), 'success');
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setDeleteLoading(false);
    }
  };

  const likePost = async (postID) => {
    setLikeLoading(true);
    try {
      const payload = {
        input: {
          id: postID,
          userID: userID
        }
      };
      await gql(discourseLikePost, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLikeLoading(false);
    }
  };

  const reportPost = async (postID, reason, callback) => {
    setReportLoading(true);
    try {
      const payload = {
        input: {
          postID: postID,
          userID: userID,
          reason: reason
        }
      };
      await gql(discourseReportPost, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      callback && callback();
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setReportLoading(false);
    }
  };

  const unlikePost = async (postID) => {
    setLikeLoading(true);
    try {
      const payload = {
        input: {
          id: postID,
          userID: userID
        }
      };
      await gql(discourseUnlikePost, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLikeLoading(false);
    }
  };

  return {
    data,
    loading,
    deleteLoading,
    updateLoading,
    likeLoading,
    reportLoading,
    getData,
    updatePost,
    deletePost,
    likePost,
    reportPost,
    unlikePost
  };
};

export const useReplyPost = (topicID) => {
  const toast = useToast();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [replyLoading, setReplyLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [data, setData] = useState();

  const getData = async () => {
    if (topicID) {
      setLoading(true);
      try {
        const params = {
          filter: { parentID: { eq: topicID } },
          sort: { direction: 'asc', field: 'createdAt' }
        };
        const { data: res } = await gql(searchDiscoursePosts, params);

        if (res?.searchDiscoursePosts?.items) {
          setData(res?.searchDiscoursePosts?.items);
        } else {
          setData([]);
        }
      } catch (error) {
        const errorMessage = error?.errors
          ? error?.errors[0]?.message
          : error.message ?? 'unknown error';
        toast(errorMessage, 'error');
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const replyPost = async (post) => {
    setReplyLoading(true);
    try {
      const payload = {
        input: {
          raw: post.raw,
          topicId: post.topicID,
          replyToPostNumber: post.postNumber,
          userID: post.userID,
          category: post.category,
          parentID: post.parentID
        }
      };
      await gql(discourseReplyPost, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setReplyLoading(false);
    }
  };

  const deleteComment = async () => {
    setDeleteLoading(true);
    try {
      const payload = {
        input: {
          id: topicID,
          mode: 'REPLY'
        }
      };
      await gql(discourseDelete, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setDeleteLoading(false);
    }
  };

  useEffect(() => {
    if (topicID) {
      const payload = {};
      const subscription = gql(onDiscourseUpdated, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      }).subscribe({
        next: ({ value: { data, errors } }) => {
          setTimeout(function () {
            getData();
          }, 1000);
        },
        error: (error) => console.warn(error)
      });

      return () => {
        if (subscription) subscription.unsubscribe();
      };
    }
  }, [topicID]); // eslint-disable-line

  return {
    data,
    loading,
    deleteLoading,
    replyLoading,
    getData,
    replyPost,
    deleteComment
  };
};

export const useChannelByOwner = (ownerID) => {
  const toast = useToast();
  const [data, setData] = useState();
  const [loading, setLoading] = useState();

  const getData = async () => {
    try {
      const params = {
        owner: ownerID,
        sortDirection: 'DESC'
      };
      const { data: res } = await gql(channelByOwnerAndCreatedAt, params);
      if (res?.channelByOwnerAndCreatedAt?.items?.length) {
        setData(res?.channelByOwnerAndCreatedAt?.items);
      } else {
        setData([]);
      }
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (ownerID) {
      getData();
    }
  }, [ownerID]); // eslint-disable-line

  return {
    loading,
    data,
    getData
  };
};

export const useCommentCollectible = ({ collectibleID }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const [createCommentLoading, setCreateCommentLoading] = useState(false);

  const getData = async (loading = false) => {
    loading && setLoading(true);
    try {
      const params = {
        filter: { channelID: { eq: collectibleID }, parentID: { eq: collectibleID } },
        sort: { direction: 'asc', field: 'createdAt' }
      };
      const { data: res } = await gql(searchDiscoursePosts, params);

      if (res?.searchDiscoursePosts?.items) {
        setData(res?.searchDiscoursePosts?.items);
      } else {
        setData([]);
      }
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      loading && setLoading(false);
    }
  };

  const createComment = async (post) => {
    setCreateCommentLoading(true);
    try {
      const payload = {
        input: {
          raw: post.raw,
          category: collectibleID
        }
      };
      await gql(collectibleCreateComment, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setCreateCommentLoading(false);
    }
  };

  useEffect(() => {
    if (collectibleID) {
      const payload = {};
      const subscription = gql(onCommentaryUpdated, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      }).subscribe({
        next: ({ value: { data, errors } }) => {
          setTimeout(function () {
            getData();
          }, 1000);
        },
        error: (error) => console.warn(error)
      });

      return () => {
        if (subscription) subscription.unsubscribe();
      };
    }
  }, [collectibleID]); // eslint-disable-line

  useEffect(() => {
    if (collectibleID) {
      getData(true);
    }
  }, [collectibleID]);

  return {
    loading,
    createCommentLoading,
    data,
    getData,
    createComment
  };
};

export const useReplyCommentCollectible = ({ commentID, collectibleID }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const [likeLoading, setLikeLoading] = useState();
  const [createReplyLoading, setReplyLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    try {
      const params = {
        filter: { parentID: { eq: commentID } },
        sort: { direction: 'asc', field: 'createdAt' }
      };
      const { data: res } = await gql(searchDiscoursePosts, params);

      if (res?.searchDiscoursePosts?.items) {
        setData(res?.searchDiscoursePosts?.items);
      } else {
        setData([]);
      }
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const createReplyComment = async (post) => {
    setReplyLoading(true);
    try {
      const payload = {
        input: {
          raw: post.raw,
          parentID: commentID,
          category: collectibleID
        }
      };
      await gql(collectibleReplyComment, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setReplyLoading(false);
    }
  };

  const likeComment = async (commentID) => {
    setLikeLoading(true);
    console.log(commentID);
    try {
      const payload = {
        input: {
          id: commentID
        }
      };
      await gql(collectibleLikeComment, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLikeLoading(false);
    }
  };

  const unlikeComment = async (commentID) => {
    setLikeLoading(true);
    try {
      const payload = {
        input: {
          id: commentID
        }
      };
      await gql(collectibleUnlikeComment, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
    } catch (error) {
      const errorMessage = error?.errors
        ? error?.errors[0]?.message
        : error.message ?? 'unknown error';
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLikeLoading(false);
    }
  };

  useEffect(() => {
    if (commentID && collectibleID) {
      const payload = {};
      const subscription = gql(onCommentaryUpdated, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      }).subscribe({
        next: ({ value: { data, errors } }) => {
          setTimeout(function () {
            console.log('test');
            getData();
          }, 1000);
        },
        error: (error) => console.warn(error)
      });

      return () => {
        if (subscription) subscription.unsubscribe();
      };
    }
  }, [commentID, collectibleID]); // eslint-disable-line

  useEffect(() => {
    if (commentID && collectibleID) {
      getData();
    }
  }, [commentID, collectibleID]);

  return {
    loading,
    createReplyLoading,
    data,
    likeLoading,
    createReplyComment,
    likeComment,
    unlikeComment,
    getData
  };
};
