import FansincSocialMedia from './fansinc';
import PatronsSocialMedia from './patrons';

const SocialMedia = ({ className, ...props }) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return <PatronsSocialMedia className={className} {...props} />;
  } else {
    return <FansincSocialMedia className={className} {...props} />;
  }
};

export default SocialMedia;
