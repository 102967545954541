import { Box, Container } from 'components';
import { useTranslation } from 'react-i18next';

function PrintCollectible() {
  const { t } = useTranslation();
  return (
    <Container className="print-collectible">
      <Container size="md">
        <Box className="print-collectible__title">{t('printCollectible.title')}</Box>
      </Container>
    </Container>
  );
}

export default PrintCollectible;
