import { Features } from '@vincere/react-vlag';
import { Helmet } from 'react-helmet';
import { Container, Heading } from '@components';

const BetaFeatures = () => {
  return (
    <>
      <Container className="beta-features">
        <Heading className="text-center" as="h1">
          Beta Features
        </Heading>
        <Features />
      </Container>
      <Helmet>
        <title>Beta Features</title>
        <meta property="og:image" content="https://cdn.fans.inc/public/webapp/og-image.png" />
      </Helmet>
    </>
  );
};

export default BetaFeatures;
