import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';

import { useAuth } from '@libs/contexts/auth';
import {
  Avatar,
  Box,
  Grid,
  Checkbox,
  FormField,
  Text,
  Link,
  ConnectWalletButton,
  MintNFTButton,
  NFTImageCard
} from '@components';

function NFTMinterModalContent({ exchange, setIsCloseDisabled, refreshReleases }) {
  const { t } = useTranslation();
  const { user: auth } = useAuth();
  const [isNftImageCardReady, setIsNftImageCardReady] = useState(false);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [walletAddress, setWalletAddress] = useState('');
  const [hasWalletError, setHasWalletError] = useState(false);
  const setWalletAddressCallback = useCallback(setWalletAddress, [setWalletAddress]);
  const setIsSubmittingFormCallback = useCallback(setIsSubmittingForm, [setIsSubmittingForm]);
  const setIsNftImageCardReadyCallback = useCallback(setIsNftImageCardReady, [
    setIsNftImageCardReady
  ]);
  const setHasWalletErrorCallback = useCallback(setHasWalletError, [setHasWalletError]);

  const {
    register,
    watch,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    shouldFocusError: false
  });

  const terms = watch('terms');

  useEffect(() => {
    setIsCloseDisabled(isSubmittingForm);
  }, [isSubmittingForm, setIsCloseDisabled]);

  const validateForm = useCallback(() => {
    const validationConditions = [terms, isNftImageCardReady];
    return validationConditions.every((x) => x);
  }, [isNftImageCardReady, terms]);

  if (!exchange) {
    return null;
  }

  return (
    <form
      noValidate
      className={`nft-minter-modal__form ${
        isSubmittingForm ? 'nft-minter-modal__form__disabled' : ''
      }`}
    >
      <Grid className="nft-minter-modal__grid" id="nft-minter-modal">
        <Box className="nft-minter-modal__left nft-minter-modal__form">
          <Box className="nft-minter-modal__owner">
            <Text className="nft-minter-modal__owner-title">
              {t('components.nftMinterModal.ownerDetails')}
            </Text>
            {auth ? (
              <Box className="nft-minter-modal__owner-detail">
                <Avatar user={auth} size="sm" className="nft-minter-modal__owner-detail__avatar" />
                <Box className="nft-minter-modal__owner-detail__info">
                  <Text className="nft-minter-modal__owner-detail__info-name">
                    {auth?.firstName} {auth?.lastName}{' '}
                  </Text>
                  <Text className="nft-minter-modal__owner-detail__info-link">
                    fans.inc/{auth?.username}
                  </Text>
                </Box>
              </Box>
            ) : (
              <Text className="mb-4"> {t('components.nftMinterModal.notLoggedIn')}</Text>
            )}
          </Box>
          <ConnectWalletButton
            walletAddress={walletAddress}
            setWalletAddress={setWalletAddressCallback}
            setHasError={setHasWalletErrorCallback}
            disableSubmit={
              (process.env.REACT_APP_AMPLIFY_ENV === 'sandbox' &&
                exchange?.creator?.username !== 'colincolorful') ||
              ['staging', 'prod'].includes(process.env.REACT_APP_AMPLIFY_ENV)
            }
          />
        </Box>
        <Box className="nft-minter-modal__middle"></Box>
        <Box className="nft-minter-modal__right">
          <Box className="nft-minter-modal__release">
            <Box className={`release-checkout__media mb-5}`}>
              <NFTImageCard
                exchange={exchange}
                setIsNftImageCardReady={setIsNftImageCardReadyCallback}
              />
            </Box>
          </Box>
        </Box>
      </Grid>

      <Box className="nft-minter-modal__submit">
        <FormField className="mt-5">
          <Checkbox
            className="checkout_frame__checkbox"
            {...register('terms', {
              required: {
                value: true,
                message: t('components.nftMinterModal.fields.terms.required')
              }
            })}
            errorMessage={errors.terms?.message}
          >
            <Trans i18nKey="checkout.checkoutTerms.makeOffer" values={{ ...auth }}>
              <Link target="_blank" to="https://www.fans.inc"></Link>
              <Link target="_blank" to="/terms-and-conditions"></Link>
              {auth.firstName ? (
                <span />
              ) : (
                <div className="inline-block w-8 bg-gray-500 h-2 mx-1" />
              )}
              {auth.lastName ? <span /> : <div className="inline-block w-8 bg-gray-500 h-2 mx-1" />}
              {auth.email ? <span /> : <div className="inline-block w-12 bg-gray-500 h-2 mx-1" />}
            </Trans>
          </Checkbox>
        </FormField>
        <MintNFTButton
          fullWidth
          exchange={exchange}
          walletAddress={walletAddress}
          validation={validateForm}
          setIsMinting={setIsSubmittingFormCallback}
          refreshReleases={refreshReleases}
          hasWalletError={hasWalletError}
          disableSubmit={
            (process.env.REACT_APP_AMPLIFY_ENV === 'sandbox' &&
              exchange?.creator?.username !== 'colincolorful') ||
            ['staging', 'prod'].includes(process.env.REACT_APP_AMPLIFY_ENV)
          }
        />
      </Box>
    </form>
  );
}

NFTMinterModalContent.propTypes = {
  exchange: PropTypes.object,
  setIsCloseDisabled: PropTypes.func,
  refreshReleases: PropTypes.func
};

export default NFTMinterModalContent;
