import { useState, useEffect } from 'react';
import gql from '@libs/utils/gql';
import useToast from '@libs/utils/toast';
import {
  exchangeByID,
  exchangeByOwnerAndExchangeType,
  searchExchanges,
  searchExchangesCheckout,
  searchExchangesCount,
  searchExchangesHistory,
  findUnitExchange
} from '@libs/custom-queries/exchange';
import { getDataForTierTiles } from '@graphql/queries';
import { onProcessPaymentV1 } from '@graphql/subscriptions';
import { useAuth } from '@libs/contexts/auth';
import { EXCHANGE_TYPES, STATUS_TYPES } from '@libs/utils/exchange';

export { useCollectibleOwnership } from '@libs/hooks/exchange/useCollectibleOwnership';
export { useSecondaryMarketplace } from '@libs/hooks/exchange/useSecondaryMarketplace';
export { usePollExchange } from '@libs/hooks/exchange/usePollExchange';
export { usePollExchangeByNftToken } from '@libs/hooks/exchange/usePollExchangeByNftToken';
export { useExchangesBySlugAndSerialIndex } from '@libs/hooks/exchange/useExchangesBySlugAndSerialIndex';
export { useExchangesByCurrentOwner } from '@libs/hooks/exchange/useExchangesByCurrentOwner';

export const useIsExchangeOwner = (exchange) => {
  const { user } = useAuth();
  return exchange?.currentOwnerID === user?.id;
};

export const useExchangeByID = (id) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [isDataCleared, setIsDataCleared] = useState(false);

  const getData = async (id) => {
    setLoading(true);
    try {
      const { data: res } = await gql(exchangeByID, { id });
      if (res?.getExchange) {
        setData(res.getExchange);
      } else {
        setData(null);
      }
    } catch (error) {
      const errorMessage = error?.message;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      getData(id);
    } else {
      setLoading(false);
    }
  }, [id]); // eslint-disable-line

  const clearData = () => {
    setData(null);
    setIsDataCleared(true);
  };

  if (isDataCleared && data == null && id) {
    getData(id);
    setIsDataCleared(false);
  }

  const refreshData = () => {
    if (id) {
      setLoading(true);
      getData(id);
    } else {
      setLoading(false);
    }
  };

  return {
    loading,
    data,
    clearData,
    refreshData
  };
};

export const useExchangeByPatronAndExpiryDate = (
  patronID,
  expiryDate,
  exchangeType,
  isSold,
  limit = 10,
  sortBy
) => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [pageToken, setPageToken] = useState();

  async function getData(nextToken) {
    if (!patronID || !expiryDate || !exchangeType) {
      return;
    }

    try {
      setLoading(true);
      const params = {
        limit,
        sort: { direction: 'desc', field: sortBy ? sortBy : 'createdAt' },
        filter: {
          ...(isSold ? { searchableIsSold: { eq: isSold } } : {}),
          expiryDate: { gte: expiryDate },
          searchableExchangeType: { eq: exchangeType },
          patronID: { eq: patronID }
        }
      };

      if (nextToken) {
        params.nextToken = nextToken;
      }

      const { data: res } = await gql(searchExchanges, params);

      if (res?.searchExchanges?.items?.length) {
        if (data.length > 0 && nextToken) {
          setData(data.concat(res.searchExchanges.items));
        } else {
          setData(res.searchExchanges.items);
        }

        setPageToken(res.searchExchanges?.nextToken);
      } else {
        if (!nextToken) {
          setData([]);
        }

        setPageToken(null);
      }
    } catch (error) {
      toast(error.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, [patronID, limit]); // eslint-disable-line

  useEffect(() => {
    const payload = {};
    const subscription = gql(onProcessPaymentV1, payload, {
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    }).subscribe({
      next: () => {
        getData();
      },
      error: (error) => {
        console.warn(error);
        setData([]);
      }
    });

    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, []); // eslint-disable-line

  return {
    loading,
    data,
    pageToken,
    getData
  };
};

export const useExchangeByOwnerAndExpiryDate = (
  ownerID,
  expiryDate,
  exchangeType,
  isSold,
  limit = 10
) => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [pageToken, setPageToken] = useState();

  async function getData(nextToken) {
    if (!ownerID || !expiryDate || !exchangeType || !isSold) {
      return;
    }

    setLoading(true);
    try {
      const params = {
        limit,
        sort: { direction: 'desc', field: 'createdAt' },
        filter: {
          searchableIsSold: { eq: isSold },
          expiryDate: { gte: expiryDate },
          searchableExchangeType: { eq: exchangeType },
          currentOwnerID: { eq: ownerID },
          searchableStatus: { eq: STATUS_TYPES.ACTIVE }
        }
      };

      if (nextToken) {
        params.nextToken = nextToken;
      }

      const { data: res } = await gql(searchExchanges, params);

      if (res?.searchExchanges?.items?.length) {
        if (data.length > 0 && nextToken) {
          setData(data.concat(res.searchExchanges.items));
        } else {
          setData(res.searchExchanges.items);
        }

        if (res.searchExchanges?.nextToken) setPageToken(res.searchExchanges?.nextToken);
      } else {
        if (!nextToken) {
          setData([]);
        }

        setPageToken(null);
      }
    } catch (error) {
      toast(error.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, [ownerID, limit]); // eslint-disable-line

  useEffect(() => {
    const payload = {};
    const subscription = gql(onProcessPaymentV1, payload, {
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    }).subscribe({
      next: () => {
        getData();
      },
      error: (error) => {
        console.warn(error);
        setData([]);
      }
    });

    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, []); // eslint-disable-line

  return {
    loading,
    data,
    pageToken,
    getData
  };
};

export const useTierAvailability = ({ collectibleID, creatorID, serialIndex }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(0);

  const getData = async (collectibleID, creatorID) => {
    setLoading(true);
    try {
      const params = {
        filter: {
          collectibleID: { eq: collectibleID },
          currentOwnerID: { eq: creatorID },
          collectibleReleaseIndex: { eq: serialIndex },
          searchableStatus: { eq: STATUS_TYPES.ACTIVE },
          searchableExchangeType: { ne: EXCHANGE_TYPES.LISTING_IN_PROCESS }
        }
      };
      const { data: res } = await gql(searchExchangesCount, params);

      if (res?.searchExchanges?.total) {
        setData(res.searchExchanges.total);
      } else {
        setData(0);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (collectibleID && creatorID) {
      getData(collectibleID, creatorID);
    }
  }, [collectibleID, creatorID]); // eslint-disable-line

  return {
    loading,
    data
  };
};

export const useCollectibleAvailability = ({ collectibleID, creatorID }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(0);

  const getData = async (collectibleID, creatorID) => {
    setLoading(true);
    try {
      const params = {
        filter: {
          collectibleID: { eq: collectibleID },
          currentOwnerID: { ne: creatorID },
          searchableStatus: { eq: STATUS_TYPES.ACTIVE },
          searchableExchangeType: { eq: EXCHANGE_TYPES.LISTING }
        }
      };
      const { data: res } = await gql(searchExchangesCount, params);

      if (res?.searchExchanges?.total) {
        setData(res.searchExchanges.total);
      } else {
        setData(0);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (collectibleID && creatorID) {
      getData(collectibleID, creatorID);
    }
  }, [collectibleID, creatorID]); // eslint-disable-line

  return {
    loading,
    data
  };
};

export const useExchangeByOwnerAndExchangeType = (
  currentOwnerID,
  exchangeType,
  isSold = null,
  limit = 10
) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pageToken, setPageToken] = useState();

  async function getData(nextToken) {
    if (!currentOwnerID) {
      return;
    }

    setLoading(true);
    try {
      const params = {
        currentOwnerID,
        exchangeType: { eq: exchangeType },
        sortDirection: 'DESC',
        limit
      };

      if (isSold !== null) {
        params.filter = { isSold: { eq: 'TRUE' } };
      }

      if (nextToken) {
        params.nextToken = nextToken;
      }

      let gqlQuery = exchangeByOwnerAndExchangeType;

      const { data: res } = await gql(gqlQuery, params);

      if (res?.exchangeByOwnerAndExchangeType?.items?.length) {
        if (data.length > 0 && nextToken) {
          setData(data.concat(res.exchangeByOwnerAndExchangeType.items));
        } else {
          setData(res.exchangeByOwnerAndExchangeType.items);
        }

        setPageToken(res.exchangeByOwnerAndExchangeType?.nextToken);
      } else {
        if (!nextToken) {
          setData([]);
        }

        setPageToken(null);
      }
    } catch (error) {
      toast(error.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, [currentOwnerID, limit]); // eslint-disable-line

  useEffect(() => {
    const payload = {};
    const subscription = gql(onProcessPaymentV1, payload, {
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    }).subscribe({
      next: () => {
        getData();
      },
      error: (error) => {
        console.warn(error);
        setData([]);
      }
    });

    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, []); // eslint-disable-line

  return {
    loading,
    data,
    pageToken,
    getData
  };
};

const getTierTileData = async (collectible) => {
  const collectibleID = collectible?.id;
  const creatorID = collectible?.userID;
  const numberOfCollectibleReleases = collectible?.releases?.length;
  const {
    data: { getDataForTierTiles: res }
  } = await gql(
    getDataForTierTiles,
    {
      input: { collectibleID, creatorID, numberOfCollectibleReleases }
    },
    {
      authMode: 'API_KEY'
    }
  );
  const parsedData = res ? JSON.parse(res) : null;
  return parsedData;
};

export const useExchangeByExchangeIDCheckout = (id, slug, collectible) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const getDataExchange = async (id) => {
    const params = {
      filter: {
        id: { eq: id },
        searchableStatus: { eq: 'ACTIVE' },
        searchableExchangeType: { eq: 'LISTING' },
        searchableIsSold: { eq: 'FALSE' }
      }
    };

    const { data: res } = await gql(searchExchangesCheckout, params);
    if (res?.searchExchanges?.items?.length) {
      setData(res.searchExchanges.items[0]);
    } else {
      setData([]);
    }
  };

  async function getData(nextToken) {
    if (!id || !slug || !collectible) {
      return;
    }

    if (collectible === '404') {
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      if (['ne', 'do'].includes(id)) {
        const tierTiles = await getTierTileData(collectible);
        if (id === 'ne') {
          await getDataExchange(
            tierTiles.body.lowestPriceExchanges.primaryMarket[collectible?.releases?.length - 1]
              .exchange.id
          );
        } else {
          await getDataExchange(tierTiles.body.lowestPriceExchanges.primaryMarket[0].exchange.id);
        }
      } else {
        await getDataExchange(id);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, [id, slug, collectible]);

  return {
    loading,
    data
  };
};

export const useSellHistory = ({ slug, exchangeType, serialIndex }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const getData = async () => {
    setLoading(true);
    try {
      const params = {
        filter: {
          slug: { eq: slug },
          serialIndex: { eq: serialIndex },
          searchableExchangeType: { eq: exchangeType }
        },
        sort: { direction: 'asc', field: 'createdAt' }
      };
      const { data: res } = await gql(findUnitExchange, params);

      if (res?.searchExchanges?.items?.length) {
        setData(res?.searchExchanges?.items);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const clearData = () => {
    setData([]);
  };

  useEffect(() => {
    if (slug && exchangeType && serialIndex) {
      getData();
    }
  }, [slug, exchangeType, serialIndex]); // eslint-disable-line
  return {
    data,
    loading,
    clearData
  };
};

export const useTransactionHistory = ({ parentExchangeID, expiryDate, exchangeType }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const getData = async () => {
    setLoading(true);
    try {
      const params = {
        filter: {
          parentExchangeID: { eq: parentExchangeID },
          searchableExchangeType: { eq: exchangeType },
          expiryDate: { gt: expiryDate }
        },
        sort: { direction: 'desc', field: 'createdAt' }
      };
      const { data: res } = await gql(searchExchangesHistory, params);

      if (res?.searchExchanges?.items?.length) {
        setData(res?.searchExchanges?.items);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const clearData = () => {
    setData([]);
  };

  useEffect(() => {
    if (parentExchangeID && exchangeType && exchangeType) {
      getData();
    }
  }, [parentExchangeID, exchangeType, exchangeType]); // eslint-disable-line
  return {
    data,
    loading,
    refetch: getData,
    clearData
  };
};

export const useExchangeUrlParams = ({ slug, exchangeType, serialIndex, status }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const getData = async () => {
    setLoading(true);
    try {
      const params = {
        filter: {
          slug: { eq: slug },
          serialIndex: { eq: serialIndex },
          searchableExchangeType: { eq: exchangeType },
          searchableIsSold: { eq: 'FALSE' }
        }
      };

      if (status) {
        params.filter = {
          ...params.filter,
          searchableStatus: status
        };
      }
      const { data: res } = await gql(findUnitExchange, params);

      if (res?.searchExchanges?.items?.length) {
        setData(res?.searchExchanges?.items);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const clearData = () => {
    setData([]);
  };

  useEffect(() => {
    if (slug && exchangeType && serialIndex) {
      getData();
    }
  }, [slug, exchangeType, serialIndex]); // eslint-disable-line
  return {
    data,
    loading,
    clearData
  };
};
