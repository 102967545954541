import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  CollectibleCardUser,
  CollectibleTier,
  CollectibleCardTimer,
  Media,
  Link,
  Text,
  Separator,
  Price,
  Countdown
} from '@components';

export const getButton = (showButton, type, url, releaseDateSeconds, currentTime) => {
  const { t } = useTranslation();
  return (
    showButton && (
      <>
        {type === 'collectible' && (
          <Link to={url}>
            {releaseDateSeconds > currentTime ? (
              <Button className="collectible-card-new__button" fullWidth>
                {t('components.collectibleCard.notifyMe')}
              </Button>
            ) : (
              <Button className="collectible-card-new__button" fullWidth>
                {t('components.collectibleCard.getItNow')}
              </Button>
            )}
          </Link>
        )}
        {type === 'release' && (
          <Link to={url}>
            <Button className="collectible-card-new__button" fullWidth>
              {t('components.collectibleCard.getItNow')}
            </Button>
          </Link>
        )}
      </>
    )
  );
};

export const getPriceRelease = (
  type,
  data,
  collectible,
  auctionEndDate,
  releaseDate,
  releaseDateSeconds,
  currentTime,
  isAuctionSold
) => {
  const { t } = useTranslation();
  return releaseDateSeconds > currentTime ? (
    <>
      <CollectibleCardTimer
        releaseDate={releaseDate}
        currentTime={currentTime}
        releaseDateSeconds={releaseDateSeconds}
      />
    </>
  ) : (
    <>
      <Box className="collectible-card-new__price">
        <Box className="collectible-card-new__price__item">
          {t('components.collectibleCard.from')}
          <Price
            amount={
              type === 'collectible'
                ? collectible?.searchableLowestPrice
                : collectible?.collectible?.searchableLowestPrice
            }
          />
        </Box>
        {type === 'collectible' ? (
          <Box className="collectible-card-new__price__item">
            {Number(data?.searchableHighestPrice) >= Number(data?.searchableHighestBidPrice)
              ? t('components.collectibleCard.highest')
              : t('components.collectibleCard.currentBid')}
            <Price
              amount={
                Number(data?.searchableHighestPrice) >= Number(data?.searchableHighestBidPrice)
                  ? Number(data?.searchableHighestPrice)
                  : Number(data?.searchableHighestBidPrice)
              }
            />
          </Box>
        ) : (
          <Box className="collectible-card-new__price__item">
            {t('components.collectibleCard.highest')}
            <Price amount={collectible?.searchableHighestPrice || 0} />
          </Box>
        )}

        <Box className="collectible-card-new__price__item">
          {type === 'release' && (
            <>
              <Text>{t('components.collectibleCard.owner')}</Text>
              <CollectibleCardUser user={collectible?.currentOwner} type="FAN" />
            </>
          )}
          {type !== 'release' && auctionEndDate !== null && auctionEndDate > currentTime && (
            <Box className="collectible-card-new__auction">
              <Text>{t('components.collectibleCard.auctionEnd')}</Text>
              <Countdown
                time={auctionEndDate}
                showSeparator
                showDay={false}
                showIndicator={false}
                showOnlyDay
              />
            </Box>
          )}

          {type !== 'release' &&
            auctionEndDate !== null &&
            auctionEndDate < currentTime &&
            isAuctionSold && (
              <Box className="collectible-card-new__auction">
                <Text>{t('components.collectibleCard.lastSold')}</Text>
                <Countdown
                  time={auctionEndDate}
                  showSeparator
                  showDay={true}
                  showIndicator={false}
                  afterSaleEnd={true}
                  showOnlyDay
                />
              </Box>
            )}
        </Box>
      </Box>
    </>
  );
};
