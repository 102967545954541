import { useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container, Button, Image, SkeletonLoading } from '@components';
import { useTranslation, Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Collectible from './components/collectible';
import CollectibleSlider from './components/collectible-slider';
import { useCollectionBySlug, useCollectionById } from '@libs/hooks/collection';
import GalleryCover from '@assets/img/patrons/patrons-gallery-cover.png';
import GalleryProfile from '@assets/img/patrons/patrons-gallery-profile.png';

function Profile({ setGalleryLogo }) {
  const { t } = useTranslation();
  const collectibleRef = useRef(null);
  const params = useParams();
  const onScrollToCollectible = () => {
    collectibleRef.current.scrollIntoView({
      behavior: 'smooth'
    });
  };
  const { data, loading } = useCollectionBySlug(params.slug);
  const { collectionStash } = useCollectionById(data?.id);

  useEffect(() => {
    setGalleryLogo({
      isShowLogo: data?.isShowLogo === 'TRUE' ? true : false,
      logo: data?.isShowLogo === 'TRUE' ? data?.logoUrl : data?.shortName,
      name: data?.name
    });
  }, [data]);

  return (
    <>
      <Box className="profile-museum">
        <Container>
          <Box className="profile-museum__top">
            <Box className="profile-museum__top__grid">
              <Box className="profile-museum__top__grid__left">
                {loading ? (
                  <SkeletonLoading className=" w-10/12 h-40" />
                ) : (
                  <Box className="profile-museum__top__grid__left__title">{data?.name}</Box>
                )}
                <Box className="profile-museum__top__grid__left__description">
                  <Box className="profile-museum__top__grid__left__description__wrapper">
                    <Box className="profile-museum__top__grid__left__description__wrapper__text">
                      {loading ? (
                        <SkeletonLoading style={{ width: '456px' }} className="h-28" />
                      ) : (
                        <Trans i18nKey="p.pages.profile.museum.description">
                          {{
                            museumName: data?.name
                          }}
                        </Trans>
                      )}
                    </Box>
                    <Button onClick={() => onScrollToCollectible()} className="mt-10">
                      {t('p.pages.profile.museum.startCollecting')}
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Box className="profile-museum__top__grid__right">
                {loading ? (
                  <SkeletonLoading style={{ width: '515px', height: '643px' }} />
                ) : (
                  <>
                    {data?.avatarUrl ? (
                      <Image src={data?.avatarUrl} size="full" />
                    ) : (
                      <img src={GalleryProfile} alt="" />
                    )}
                  </>
                )}
              </Box>
            </Box>
            <Box className="profile-museum__top__detail">
              <Box className="profile-museum__top__detail__field">
                {collectionStash ? (
                  <Box className="profile-museum__top__detail__field__value">
                    {collectionStash?.collectibles}
                  </Box>
                ) : (
                  <SkeletonLoading className="h-12 w-7 mr-4" />
                )}
                <Box className="profile-museum__top__detail__field__name mr-10">
                  {t('p.pages.profile.museum.artworks')}
                </Box>
              </Box>
              <Box className="profile-museum__top__detail__field">
                {collectionStash ? (
                  <Box className="profile-museum__top__detail__field__value">
                    {collectionStash?.artists}
                  </Box>
                ) : (
                  <SkeletonLoading className="h-12 w-7 mr-4" />
                )}
                <Box className="profile-museum__top__detail__field__name mr-10">
                  {t('p.pages.profile.museum.artists')}
                </Box>
              </Box>
              <Box className="profile-museum__top__detail__field">
                {collectionStash ? (
                  <Box className="profile-museum__top__detail__field__value">
                    {collectionStash?.visitors}
                  </Box>
                ) : (
                  <SkeletonLoading className="h-12 w-7 mr-4" />
                )}
                <Box className="profile-museum__top__detail__field__name">
                  {t('p.pages.profile.museum.visitors')}
                </Box>
              </Box>
            </Box>
            <Box className="profile-museum__top__image">
              {loading ? (
                <SkeletonLoading style={{ width: '332px', height: '445px' }} />
              ) : (
                <>
                  {data?.coverUrl ? (
                    <Image src={data?.coverUrl} size="full" />
                  ) : (
                    <img src={GalleryCover} alt="" />
                  )}
                </>
              )}
            </Box>
          </Box>
          <CollectibleSlider collection={data} />
          <Box ref={collectibleRef} className="profile-museum__collectible">
            <Collectible collection={data} />
          </Box>
        </Container>
      </Box>
      <Helmet>
        <title>{data?.name}</title>
        <meta name="description" content={data?.name} />
      </Helmet>
    </>
  );
}

export default Profile;
