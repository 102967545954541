import React, { useState, useEffect } from 'react';
import { pdf } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import { Button, SkeletonLoading } from '@components';

import useToast from '@libs/utils/toast';
import InvoiceWalletDocument from './document/wallet-history';
import InvoicePrintDocument from './document/print';
import { useAccount } from '@libs/hooks/account';
import { useExchangeByID } from '@libs/hooks/exchange';

const PrintButton = ({ history, amount, type, children }) => {
  const { t } = useTranslation();
  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const [docUrl, setDocUrl] = useState(false);
  const { data: account, loading: accountLoading } = useAccount(
    history?.receiver?.accountID || '1'
  );
  const { data: exchange, loading: exchangeLoading } = useExchangeByID(history?.exchangeID);

  useEffect(async () => {
    try {
      setLoading(true);
      const PrintDoc =
        type === 'wallet_history' ? (
          <InvoiceWalletDocument
            history={history}
            account={account}
            exchange={exchange}
            amount={amount}
          />
        ) : (
          <InvoicePrintDocument history={history} />
        );
      const blobProvider = await pdf(PrintDoc).toBlob();
      const docUrl = URL.createObjectURL(blobProvider);
      setDocUrl(docUrl);
      setLoading(false);
    } catch (error) {
      toast(t('components.printInvoice.error'), 'error');
      console.error(error);
    }
  }, [history, account, exchange]);

  const openInvoice = async (isSales = false) => {
    if (isSales) {
      const print = (
        <InvoiceWalletDocument
          history={history}
          account={account}
          exchange={exchange}
          amount={amount}
        />
      );
      const printProvider = await pdf(print).toBlob();
      window.open(URL.createObjectURL(printProvider), '__blank');
    } else {
      window.open(docUrl, '__blank');
    }
  };

  const btnDefault =
    history?.transactionType === 'SALES' ? (
      exchange &&
      ((exchange?.creatorID === exchange?.currentOwnerID && account !== null) ||
        exchange?.creatorID !== exchange?.currentOwnerID) ? (
        <>
          <Button
            disabled={loading}
            onClick={() => {
              openInvoice(true);
            }}
          >
            {t('components.printInvoice.downloadInvoice')}
          </Button>
        </>
      ) : (
        <SkeletonLoading className="w-full h-9 mx-auto" style={{ maxWidth: '170px' }} />
      )
    ) : !accountLoading && !exchangeLoading ? (
      <Button
        disabled={loading}
        onClick={() => {
          openInvoice();
        }}
      >
        {t('components.printInvoice.downloadInvoice')}
      </Button>
    ) : (
      <SkeletonLoading className="w-full h-9 mx-auto" style={{ maxWidth: '170px' }} />
    );
  const btnCustom =
    !accountLoading && !exchangeLoading ? (
      <div onClick={() => openInvoice()}>{children}</div>
    ) : null;
  const btnPrint = children ? btnCustom : btnDefault;

  return btnPrint;
};

const PrintInvoice = ({ history, children, type = 'wallet_history' }) => {
  return (
    <PrintButton history={history} type={type}>
      {children}
    </PrintButton>
  );
};

export default PrintInvoice;
