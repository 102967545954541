import { useCookies } from 'react-cookie';
import { ReactComponent as Artwork } from '@assets/svg/artwork.svg';
import { ReactComponent as Photograph } from '@assets/svg/photograph.svg';
import { ReactComponent as Song } from '@assets/svg/song.svg';
import { ReactComponent as Lyrics } from '@assets/svg/lyrics.svg';
import { ReactComponent as Video } from '@assets/svg/video.svg';

export const CATEGORY_TYPES = {
  PHOTOGRAPH: 'PHOTOGRAPH',
  ARTWORK: 'ARTWORK',
  SONG: 'SONG',
  SONG_LYRICS: 'SONG_LYRICS',
  VIDEO: 'VIDEO'
};

export const getNearestReleaseDate = (releases = []) => {
  let sorted = releases
    ?.sort((a, b) => new Date(a.releaseDate) - new Date(b.releaseDate))
    .filter((tier) => {
      const release = new Date(tier.releaseDate);
      return Date.now() < release.getTime();
    });

  if (sorted?.length && sorted?.length === releases?.length) {
    return sorted[0].releaseDate;
  } else {
    sorted = releases
      ?.sort((a, b) => new Date(a.releaseDate) - new Date(b.releaseDate))
      .filter((tier) => {
        const release = new Date(tier.releaseDate);
        return Date.now() > release.getTime();
      });

    return sorted?.length && sorted[0].releaseDate;
  }
};

export const getCategoryIcon = (category) => {
  let icons = null;
  switch (category) {
    case 'ARTWORK':
      icons = <Artwork />;
      break;
    case 'PHOTOGRAPH':
      icons = <Photograph />;
      break;
    case 'SONG':
      icons = <Song />;
      break;
    case 'SONG_LYRICS':
      icons = <Lyrics />;
      break;
    case 'VIDEO':
      icons = <Video />;
      break;
    default:
      break;
  }

  return icons;
};

export const getCollectibleReleaseIndexFromCollectibleAndTierType = (collectible, tierType) => {
  return collectible?.releases?.findIndex((x) => x.tierType === tierType);
};

export const isNewCollectibleCard = () => {
  return true;
};

export const isNewCollectibleSlider = () => {
  return true;
};

export const isNewCollectibleLike = () => {
  return true;
};
