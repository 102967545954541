import FansincPurchaseCard from './fansinc';
import PatronsPurchaseCard from './patrons';

const PurchaseCard = ({
  exchange,
  currentBidExchange,
  disabled,
  onClickPurchase,
  className,
  minted,
  children,
  ...props
}) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return (
      <PatronsPurchaseCard
        exchange={exchange}
        currentBidExchange={currentBidExchange}
        disabled={disabled}
        onClickPurchase={onClickPurchase}
        className={className}
        minted={minted}
        {...props}
      >
        {children}
      </PatronsPurchaseCard>
    );
  } else {
    return (
      <FansincPurchaseCard
        exchange={exchange}
        currentBidExchange={currentBidExchange}
        disabled={disabled}
        onClickPurchase={onClickPurchase}
        className={className}
        minted={minted}
        {...props}
      >
        {children}
      </FansincPurchaseCard>
    );
  }
};

export default PurchaseCard;
