import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { Button, Box, Text, Modal } from '@components';
import { exchangePropType } from '@libs/prop-types';
import { mintNFT } from '@components/mint-nft-button/utils';
import '@components/mint-nft-button/style.css';
function MintNFTButton({
  mediaRef,
  exchange,
  walletAddress,
  fullWidth = false,
  validation = () => true,
  setIsMinting: setIsMinting_,
  onSuccess = () => {},
  hasWalletError = false,
  className,
  setStatus: setMintingStatus,
  setUrl: setMintingUrl,
  isReady,
  disableSubmit = false
}) {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(true);
  const [isMinting, setIsMinting] = useState(false);
  const [hasMinted, setHasMinted] = useState(false);
  const [showSandboxModal, setShowSandboxModal] = useState(false);
  const [status, setStatus] = useState('');

  useEffect(() => setIsMinting_(isMinting), [isMinting, setIsMinting_]);

  useEffect(() => {
    if (walletAddress === '' || exchange == null || hasWalletError || !isReady) {
      setIsDisabled(true);
      return;
    }
    setIsDisabled(false);
  }, [walletAddress, exchange, hasWalletError, isReady]);

  const onClick = async () => {
    if (disableSubmit) {
      setShowSandboxModal(true);
      return;
    }
    if (!validation()) {
      return;
    }
    setMintingStatus('loading');
    if (process.env.REACT_APP_NAME === 'patrons') {
      await mediaRef?.current?.onUpload();
    }
    const {
      status: status_,
      success,
      url
    } = await mintNFT({
      exchange,
      walletAddress,
      setIsMinting
    });
    setHasMinted(success);
    setStatus(status_);
    if (success) {
      onSuccess();
      setMintingStatus('success');
      setMintingUrl(url);
    } else {
      setMintingStatus('error');
    }
  };

  return (
    <Box className={cx('mint-nft-button', className)}>
      {!hasMinted && (
        <Button
          onClick={onClick}
          loading={isMinting || !isReady}
          disabled={isDisabled}
          fullWidth={fullWidth}
        >
          {t('components.nftMinterModal.submit')}
        </Button>
      )}
      <Box className="mint-nft-button__status">
        <Text>{status}</Text>
      </Box>
      <Modal
        heading={t(['components.nftMinterModal.sandbox.title'])}
        isOpen={showSandboxModal}
        showCloseIcon
        size="md"
        onClose={() => setShowSandboxModal()}
      >
        <Box>{t('components.nftMinterModal.sandbox.description')}</Box>
        <Box className="post-modal-edit-button">
          <Button onClick={() => setShowSandboxModal(false)} outline>
            {t('components.nftMinterModal.sandbox.cancel')}
          </Button>
        </Box>
      </Modal>
    </Box>
  );
}

MintNFTButton.propTypes = {
  exchange: exchangePropType,
  walletAddress: PropTypes.string,
  fullWidth: PropTypes.bool,
  validation: PropTypes.func,
  setIsMinting: PropTypes.func,
  refreshReleases: PropTypes.func
};

MintNFTButton.defaultProps = {
  setStatus: () => {},
  setUrl: () => {}
};

export default MintNFTButton;
