import FansincShareLink from './fansinc';
import PatronsShareLink from './patrons';

const ShareLink = ({ className, children, right = false, ...props }) => {
  if (process.env.REACT_APP_NAME === 'patrons') {
    return (
      <PatronsShareLink className={className} right={right} {...props}>
        {children}
      </PatronsShareLink>
    );
  } else {
    return (
      <FansincShareLink className={className} right={right} {...props}>
        {children}
      </FansincShareLink>
    );
  }
};

export default ShareLink;
