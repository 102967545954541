import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { Alert, Modal, Box, Button, Text, FormField, Input } from '@components';
import { useAuth } from '@libs/contexts/auth';

const PasswordVerifier = ({ isOpen, onClose, onVerified }) => {
  const { t } = useTranslation();
  const { verifyPassword, verifyLoading } = useAuth();
  const [passwordVerified, setPasswordVerified] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();

  const onSubmit = async (values) => {
    try {
      const verified = await verifyPassword(values.password);
      onVerified(verified);
      setPasswordVerified(verified);

      if (verified) {
        onClose();
        reset();
      }
    } catch (error) {
      onVerified(false);
    }
  };

  return (
    <Modal
      verticalCenter
      isOpen={isOpen}
      onClose={onClose}
      heading={t('components.passwordVerifier.label')}
    >
      <Text className="mb-4">{t('components.passwordVerifier.description')}</Text>
      <Box>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          {passwordVerified !== null && passwordVerified === false && (
            <Alert danger className="mb-4">
              {t('components.passwordVerifier.incorrect')}
            </Alert>
          )}
          <FormField>
            <Input
              fullWidth
              as="password"
              size="md"
              errorMessage={errors.password?.message}
              {...register('password', {
                required: { value: true, message: t('auth.fields.password.required') }
              })}
            />
          </FormField>
          <Button as="submit" primary fullWidth loading={verifyLoading} disabled={verifyLoading}>
            {t('components.passwordVerifier.confirm')}
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default PasswordVerifier;
