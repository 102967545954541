import { useState } from 'react';
import gql from '@libs/utils/gql';
import { useHistory } from 'react-router-dom';
import { createWaitlist, updateVerifiedWaitlist } from '@graphql/mutations';
import useToast from '@libs/utils/toast';
import { useTranslation } from 'react-i18next';
import {
  emailWaitlistByEmailAndVerified,
  searchEmailWaitlists
} from '@libs/custom-queries/emailWaitlist';

export const useWaitlist = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [createLoading, setCreateLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const toast = useToast();

  const onCreate = async (email) => {
    setCreateLoading(true);

    try {
      const { data: res } = await gql(emailWaitlistByEmailAndVerified, email);
      if (res?.emailWaitlistByEmailAndVerified?.items?.length > 0) {
        toast('Email has been registered', 'error');
        return false;
      }
      await gql(createWaitlist, {
        input: {
          ...email
        }
      });
      return true;
    } catch (error) {
      const errorMessage = error?.errors[0]?.message;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setCreateLoading(false);
    }
  };

  const onUpdateVerified = async (payload) => {
    setUpdateLoading(true);
    try {
      const { data: res } = await gql(searchEmailWaitlists, {
        filter: {
          id: { eq: payload.id }
        }
      });
      if (res?.searchEmailWaitlists?.items?.length > 0) {
        if (res?.searchEmailWaitlists?.items[0]?.verified === 'TRUE') {
          toast('Email already verified', 'error');
        } else {
          await gql(updateVerifiedWaitlist, {
            input: {
              id: payload?.id,
              email: res?.searchEmailWaitlists?.items[0]?.email,
              firstName: payload?.firstName,
              surName: payload?.surName,
              phone: payload?.phone,
              interestedAs: payload?.interestedAs,
              password: payload?.password
            }
          });
          history.push('/waitlist/verified');
        }
      }
    } catch (error) {
      const errorMessage = error?.errors[0]?.message;
      toast(errorMessage, 'error');
      console.error(error);
    } finally {
      setUpdateLoading(false);
    }
  };

  return {
    createLoading,
    updateLoading,
    onCreate,
    onUpdateVerified
  };
};
