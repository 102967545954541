import { Link } from 'react-router-dom';
import { Box, Logo } from '@components';

const FooterCollab = ({ image }) => {
  return (
    <Box className="footer-collab">
      <Box className="footer-collab__logo">
        <img src={image} alt="Footer collab" />
      </Box>
      <span className="footer-collab__with">on</span>
      <Box className="footer-collab__fans">
        <Link to="/marketplace">
          <Logo />
        </Link>
      </Box>
    </Box>
  );
};

export default FooterCollab;
