import cx from 'classnames';
import { Box, Link } from '@components';
import { FiPrinter } from 'react-icons/fi';
import { useTranslation, Trans } from 'react-i18next';
import { ReactComponent as Leaf } from '@assets/svg/leaf.svg';
import { ReactComponent as Money } from '@assets/svg/money-tools.svg';
import { BiShareAlt } from 'react-icons/bi';

function CollectibleOwnerActions({
  className,
  exchange,
  isCheckoutModal,
  collectible,
  isRelease = true,
  isOwner = false,
  showDescription = false
}) {
  const { t } = useTranslation();

  const classDescription = showDescription ? 'collectible-owner-actions-show-description' : '';

  const unitPath = `${exchange?.collectible?.creator?.username}/${exchange?.slug}/${exchange?.serialIndex}`;
  const galleryPath = `${collectible?.creator?.username}/${collectible?.slug}`;
  const isOwnedUnit = isRelease && isOwner;
  const path = isOwnedUnit ? unitPath : galleryPath;

  return (
    <Box className={cx('collectible-owner-actions', className, classDescription)}>
      <Link
        className="collectible-owner-actions-item"
        target={isCheckoutModal && `"_blank"`}
        to={isRelease ? `/print/${unitPath}` : `/print/${galleryPath}`}
      >
        <Box className="collectible-owner-actions-item-wrapper">
          <Box className="collectible-owner-actions-item-icon">
            <FiPrinter size={15} />
          </Box>
          <Box className="collectible-owner-actions-item-text">
            {t('components.collectibleOwnerActions.printIt')}
          </Box>
        </Box>
        <Box className="collectible-owner-actions-item-description">
          <Trans i18nKey="components.collectibleOwnerActions.printItDescriptionItem">
            <u></u>
          </Trans>
        </Box>
      </Link>
      <Link
        className="collectible-owner-actions-item"
        target={isCheckoutModal && `"_blank"`}
        to={isRelease ? `/mint/${unitPath}` : `/mint/${galleryPath}`}
      >
        <Box className="collectible-owner-actions-item-wrapper">
          <Box className="collectible-owner-actions-item-icon">
            <Leaf />
          </Box>
          <Box className="collectible-owner-actions-item-text">
            {t('components.collectibleOwnerActions.mintIt')}
          </Box>
        </Box>
        <Box className="collectible-owner-actions-item-description">
          <Trans i18nKey="components.collectibleOwnerActions.mintItDescriptionItem">
            <u></u>
          </Trans>
        </Box>
      </Link>
      <Link
        className="collectible-owner-actions-item"
        target={isCheckoutModal && `"_blank"`}
        to={isRelease ? `/flex/${unitPath}` : `/flex/${galleryPath}`}
      >
        <Box className="collectible-owner-actions-item-wrapper">
          <Box className="collectible-owner-actions-item-icon">
            <BiShareAlt />
          </Box>
          <Box className="collectible-owner-actions-item-text">
            {t('components.collectibleOwnerActions.flexIt')}
          </Box>
        </Box>
        <Box className="collectible-owner-actions-item-description">
          <Trans i18nKey="components.collectibleOwnerActions.flexItDescriptionItem">
            <u></u>
          </Trans>
        </Box>
      </Link>
      <Link
        className="collectible-owner-actions-item"
        target={isCheckoutModal && `"_blank"`}
        to={isRelease ? `/sell/${unitPath}` : `/sell/${galleryPath}`}
      >
        <Box className="collectible-owner-actions-item-wrapper">
          <Box className="collectible-owner-actions-item-icon">
            <Money />
          </Box>
          <Box className="collectible-owner-actions-item-text">
            {t('components.collectibleOwnerActions.sellIt')}
          </Box>
        </Box>
        <Box className="collectible-owner-actions-item-description">
          <Trans i18nKey="components.collectibleOwnerActions.sellItDescriptionItem">
            <u></u>
          </Trans>
        </Box>
      </Link>
    </Box>
  );
}

export default CollectibleOwnerActions;
