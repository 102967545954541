import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const BitpayFrame = ({ iframeURL, closeIframe }) => {
  const history = useHistory();
  const [redirectTo, setRedirectTo] = useState();

  useEffect(() => {
    if (redirectTo) {
      const url = new URL(redirectTo);
      if (url.search) history.replace(url.pathname + url.search);
      closeIframe && closeIframe();
    }
  }, [redirectTo, closeIframe]); // eslint-disable-line

  return (
    <>
      {iframeURL && (
        <iframe
          src={iframeURL}
          title="checkout-bitpay-iframe"
          sandbox="allow-top-navigation allow-scripts allow-same-origin allow-forms"
          style={{
            minHeight: '100vh',
            width: '100vw',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 1000,
            backgroundColor: 'rgba(255,255,255,0.9)'
          }}
          onLoad={(e) => {
            try {
              e.target.contentWindow?.location?.href &&
                setRedirectTo(e.target.contentWindow.location.href);
            } catch (e) {
              console.warn(e);
            }
          }}
        />
      )}
    </>
  );
};

export default BitpayFrame;
