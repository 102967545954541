import { useEffect, useState } from 'react';

function useCheckoutScript() {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.checkout.com/js/framesv2.min.js';
    script.async = true;
    document.body.appendChild(script);
    const scriptLoaded = () => setIsLoaded(true);
    script.addEventListener('load', scriptLoaded);
    return () => {
      document.body.removeChild(script);
      script.removeEventListener('load', scriptLoaded);
    };
  }, []);
  return isLoaded;
}

export default useCheckoutScript;
