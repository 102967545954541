import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  LandingGallery,
  LandingGallerySection,
  LandingGallerySectionHeading,
  LandingGalleryCta,
  Text
} from '@components';

import MintImage1 from '@assets/img/mint-it/mint-1.png';
import MintImage2 from '@assets/img/mint-it/mint-2.png';
import MintImage3 from '@assets/img/mint-it/mint-3.png';
function MintGallery({ exchange, setIsNftImageCardReady }) {
  const { t } = useTranslation();

  return (
    <LandingGallery>
      <LandingGallerySection
        image={<img className="sell-landing-image" src={MintImage1} alt="sell it" />}
      >
        <LandingGallerySectionHeading heading={t('mint.landingGallery.sections.one.heading')} />
        <Text>{t('mint.landingGallery.sections.one.text')}</Text>
      </LandingGallerySection>
      <LandingGallerySection
        image={<img className="sell-landing-image" src={MintImage2} alt="sell it" />}
        fadeLeft
        reverse
      >
        <LandingGallerySectionHeading heading={t('mint.landingGallery.sections.two.heading')} />
        <Text>{t('mint.landingGallery.sections.two.text')}</Text>
      </LandingGallerySection>
      <LandingGallerySection
        image={<img className="sell-landing-image" src={MintImage3} alt="sell it" />}
      >
        <LandingGallerySectionHeading heading={t('mint.landingGallery.sections.three.heading')} />
        <Text>{t('mint.landingGallery.sections.three.text')}</Text>
      </LandingGallerySection>
      <LandingGalleryCta
        heading={t('mint.landingGallery.cta.heading')}
        text={t('mint.landingGallery.cta.text')}
        link="/marketplace"
        buttonText={t('mint.landingGallery.cta.buttonText')}
        fadeRight
      />
    </LandingGallery>
  );
}

MintGallery.propTypes = {};

export default MintGallery;
