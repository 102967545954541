import { useRef, useState } from 'react';
import { Storage } from 'aws-amplify';
import { useTranslation } from 'react-i18next';

import { resizeImage } from '@graphql/mutations';
import useToast from '@libs/utils/toast';
import gql from '@libs/utils/gql';

export const useUploader = (callback) => {
  const { t } = useTranslation();
  const inputRef = useRef();
  const toast = useToast();
  const [s3, setS3] = useState();
  const [fileUrl, setFileUrl] = useState();
  const [preview, setPreview] = useState();
  const [loading, setLoading] = useState();

  const resValidation = (file, minWidth = 0, minHeight = 0) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = () => {
        reader.abort();
        reject(new Error('Problem parsing input file.'));
      };

      reader.readAsDataURL(file);
      reader.addEventListener('load', (event) => {
        const _loadedImageUrl = event.target.result;
        const image = document.createElement('img');
        image.src = _loadedImageUrl;
        image.addEventListener('load', () => {
          const { width, height } = image;
          resolve(width > minWidth && height > minHeight);
        });
      });
    });
  };

  const beforeUpload = async (file, type) => {
    const fileFormat = file.name.split('.').pop();
    const isJFIF = fileFormat === 'jfif';

    let allowed = ['image/jpeg', 'image/png', 'image/webp'];
    const isAllowed = allowed.includes(file.type) && !isJFIF;

    if (!isAllowed) {
      toast('Invalid file format.');
      return isAllowed;
    }

    const isLt5M = file.size / 1024 / 1024 < 100;
    if (!isLt5M) {
      toast('The file exceeds the maximum file size of 100 MB.');
      return isLt5M;
    }

    let minWidth = 400,
      minHeight = process.env.REACT_APP_NAME === 'patrons' ? 120 : 40,
      resValidationError = t('settings.profile.fields.cover.size');

    if (type === 'avatar') {
      minWidth = 128;
      minHeight = 128;
      resValidationError = t('settings.profile.fields.avatar.size');
    }

    const isResValid = await resValidation(file, minWidth, minHeight);
    if (!isResValid) {
      toast(resValidationError, 'error');
      return isResValid;
    }

    return isAllowed && isLt5M && isResValid;
  };

  const onUpload = async (e, type) => {
    setLoading(true);
    setPreview();
    setS3();
    setFileUrl();
    const file = e.target.files[0];
    try {
      const validation = await beforeUpload(file, type);
      if (!validation) {
        return;
      }
      // Set preview image in Base64
      const reader = new FileReader();
      reader.onloadend = () => {};
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);

      // Upload to S3
      const res = await Storage.put(`temps/${file.name}`, file);
      setS3(res);

      const signedURL = await Storage.get(res.key);
      setFileUrl(signedURL);

      if (callback) {
        callback({
          s3: res,
          fileUrl: signedURL
        });
      }
    } catch (error) {
      toast(error.toString());
      console.error('Error uploading file: ', error);
    } finally {
      setLoading(false);
    }
  };

  const onClick = () => {
    inputRef.current.click();
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = window.atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });
    return blob;
  };

  const onUploadPrinted = async (file, fileName) => {
    setLoading(true);
    setPreview();
    setS3();
    setFileUrl();
    try {
      var buf = dataURItoBlob(file);
      await Storage.put(`collectibles/printed/${fileName}.png`, buf, {
        contentType: 'image/png',
        level: 'public'
      });
    } catch (error) {
      toast(error.toString());
      console.error('Error uploading file: ', error);
    } finally {
      setLoading(false);
    }
  };

  const onUploadMint = async (file, fileName) => {
    setLoading(true);
    setPreview();
    setS3();
    setFileUrl();
    try {
      var buf = dataURItoBlob(file);
      await Storage.put(`nft_card/${fileName}`, buf, {
        contentType: 'image/png',
        level: 'public'
      });
    } catch (error) {
      toast(error.toString());
      console.error('Error uploading file: ', error);
    } finally {
      setLoading(false);
    }
  };

  return {
    inputRef,
    preview,
    result: {
      s3,
      fileUrl
    },
    loading,
    onUpload,
    onUploadPrinted,
    onUploadMint,
    onClick
  };
};

export const useImageResizer = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const onResize = async ({ key, width, height, x, y, folder }) => {
    setLoading(true);
    try {
      const payload = {
        input: {
          key,
          width,
          height,
          x,
          y,
          folder
        }
      };

      const resize = await gql(resizeImage, payload, {
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });

      return resize.data?.resizeImage;
    } catch (error) {
      toast(error.toString());
      console.error('Error resizing file: ', error);
    } finally {
      setLoading(false);
    }
  };

  return {
    onResize,
    loading
  };
};
