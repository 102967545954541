import { useState } from 'react';
import { Box } from '@components';
import { ReactComponent as ArrowDownIcon } from '@assets/svg/arrow-down.svg';
import { ReactComponent as ArrowUpIcon } from '@assets/svg/arrow-up.svg';
import { motion } from 'framer-motion/dist/framer-motion';

function CustomDropdown({ setValue, value, label, list }) {
  const subMenuAnimate = {
    enter: {
      height: '8rem',
      transition: {
        duration: '0.5'
      }
    },
    exit: {
      height: '0rem',
      transition: {
        duration: '0.5'
      }
    }
  };
  const childMenuAnimate = {
    enter: {
      opacity: 1,
      transition: {
        delay: 0.7,
        duration: 0.5
      },
      display: 'block'
    },
    exit: {
      opacity: 0,
      transitionEnd: {
        display: 'none'
      }
    }
  };
  const [isOpen, setOpen] = useState(false);
  const getLabel = () => {
    const filter = list.filter((list) => {
      return list.value === value;
    });
    return filter[0].label;
  };
  return (
    <Box className="custom-dropdown">
      <Box onClick={() => setOpen(!isOpen)} className="custom-dropdown-header">
        <Box className="custom-dropdown-header-label">{label}</Box>
        <Box className="custom-dropdown-header-value">
          {getLabel()} {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </Box>
      </Box>
      <Box className="custom-dropdown-list">
        <motion.div
          className=""
          initial="exit"
          animate={isOpen ? 'enter' : 'exit'}
          variants={subMenuAnimate}
        >
          <motion.div
            className="overflow-y-auto relative max-h-32 z-10 pt-5"
            initial="exit"
            animate={isOpen ? 'enter' : 'exit'}
            variants={childMenuAnimate}
          >
            {list?.map((x, i) => (
              <Box onClick={() => setValue(x.value)} key={i} className="custom-dropdown-list-item">
                <Box className="custom-dropdown-list-item-label">{x.label}</Box>
              </Box>
            ))}
          </motion.div>
        </motion.div>
      </Box>
    </Box>
  );
}

export default CustomDropdown;
